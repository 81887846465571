import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { storage } from 'src/_firebase/firebase';
import '../home.css';
import StyledComparisonTableBody from './components/StyledComparisonTableBody';
import StyledComparisonTableHead from './components/StyledComparisonTableHead';
import useHorizontalScrollEvent from './components/useHorizontalScrollEvent';

const ComparisonTable = ({ list, open, orgsPictures, setOrgsPictures }) => {
  ComparisonTable.propTypes = {
    list: PropTypes.object,
    open: PropTypes.bool,
    orgsPictures: PropTypes.array,
    setOrgsPictures: PropTypes.func
  };
  const [doneRetrievingOrgsPictures, setDoneRetrievingOrgsPictures] =
    useState(false);

  const [userscrolledhorizontally, setuserscrolledhorizontally] =
    useState(false);
  const handleScroll = useHorizontalScrollEvent((e) => {
    if (e.target.scrollLeft !== 0) {
      setuserscrolledhorizontally(true);
    } else {
      setuserscrolledhorizontally(false);
    }
  });

  const handleRetrieveOrgsPictures = async () => {
    const tempPicturesData = list.docs.map(async (docId) => {
      const orgPicsStorageRef = ref(storage, `orgs/${docId}/orgPictures`);
      return listAll(orgPicsStorageRef).then(async (listResult) => {
        if (listResult.items.length > 0) {
          const picsPromises = listResult.items.map(async (orgPic) =>
            getDownloadURL(orgPic)
              .then((picUrl) => ({ name: orgPic.name, url: picUrl }))
              .catch((err) => console.log(err))
          );
          const orgList = Promise.all(picsPromises)
            .then((val) => ({ docId, pictures: val }))
            .catch((err) => {
              // tempPicturesData.push({ docId, pictures: [] });
              console.log(err);
              return { docId, pictures: [] };
            });
          return orgList;
        }
        return { docId, pictures: [] };
      });
    });
    return tempPicturesData;
  };

  useEffect(async () => {
    setDoneRetrievingOrgsPictures(false);
    if (open && list && !orgsPictures) {
      await handleRetrieveOrgsPictures(list)
        .then(async (res) => {
          Promise.all(res)
            .then((val) => {
              setOrgsPictures(val);
              setDoneRetrievingOrgsPictures(true);
            })
            .catch((err) => {
              setOrgsPictures([]);
              setDoneRetrievingOrgsPictures(true);
              console.log(err);
            });
        })
        .catch((err) => {
          setOrgsPictures([]);
          setDoneRetrievingOrgsPictures(true);
          console.log(err);
        });
    }
  }, [open, list]);
  return (
    <TableContainer
      sx={{ overflow: 'auto' }}
      onScroll={handleScroll}
      component={Paper}
    >
      <Table aria-label="customized table">
        <StyledComparisonTableHead
          userscrolledhorizontally={userscrolledhorizontally}
          list={list}
        />
        <StyledComparisonTableBody
          userscrolledhorizontally={userscrolledhorizontally}
          list={list}
          orgsPictures={orgsPictures}
        />
      </Table>
    </TableContainer>
  );
};
export default ComparisonTable;
