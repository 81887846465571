import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import theme from 'src/theme';

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'lengthOfList'
})(({ lengthOfList, userscrolledhorizontally }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    borderRight: '1px solid black',
    width: lengthOfList ? `${100 / lengthOfList}vw !important` : '100px',
    minWidth: 'fit-content',
    '&:first-of-type': {
      position: 'sticky',
      zIndex: 1000,
      right: 0,
      backgroundColor: userscrolledhorizontally
        ? theme.palette.text.secondary
        : theme.palette.text.secondary,
      boxShadow: userscrolledhorizontally ? theme.shadows[4] : null,
      transition: 'all 0.1s linear',
      width: '50px'
    },
    maxWidth: '400px',
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderRight: `1px dashed ${theme.palette.action.disabled}`,
    width: lengthOfList ? `${100 / lengthOfList}vw !important` : '200px',
    minWidth: '200px !important',
    '&:first-of-type': {
      position: 'sticky',
      right: 0,
      backgroundColor: userscrolledhorizontally
        ? theme.palette.text.secondary
        : 'initial',
      boxShadow: userscrolledhorizontally ? theme.shadows[4] : null,
      color: userscrolledhorizontally ? theme.palette.common.white : null,
      transition: 'all 0.2s linear',
      zIndex: userscrolledhorizontally ? 9 : 'inherit',
      textAlign: 'center',
      width: 'fit-content !important'
    },
    maxWidth: '300px'
  }
}));

export default StyledTableCell;
