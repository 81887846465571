import { Box, Container, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultCounterCard from '../DefaultCounterCard/DefaultCounterCard';

function Counters() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage.counters'
  });

  return (
    <Box component="section" mt={4} py={6}>
      <Container>
        <Grid container item xs={12} sx={{ mx: 'auto' }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={860}
              suffix="+"
              title={t('successfulMatching')}
              description={t('successfulMatchingDescription')}
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex" justifyContent="center">
            <Divider
              orientation="vertical"
              sx={{ display: { xs: 'none', md: 'block' }, mx: 0 }}
            />
            <DefaultCounterCard
              count={45}
              suffix="+"
              title={t('partners')}
              description={t('partnersDescription')}
            />
            <Divider
              orientation="vertical"
              sx={{ display: { xs: 'none', md: 'block' }, ml: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={15}
              title={t('professionalStaff')}
              description={t('professionalStaffDesc')}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Counters;
