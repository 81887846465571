import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';
import QuestionSlide from '../../sharedPartials/QuestionSlide';
import getQuestionData from '../getQuestionData';

const CyberSecurity = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
  // setNewCoursewareView
}) => {
  CyberSecurity.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    globalCoursewares: PropTypes.array,
    handleSetViewToSignCourseware: PropTypes.func
    // setNewCoursewareView
  };

  return (
    <div
      className="theme-font-palatino theme-color-silver-blue mgdalorFont"
      style={{ width: '100%', height: '100%' }}
    >
      <div className="reveal cyber">
        <div className="slides">
          <section
            data-background-image="https://images.unsplash.com/photo-1518655048521-f130df041f66?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjIwNzA2fQ"
            data-background-position="50% 0%"
            data-auto-animate=""
            data-auto-animate-easing="linear"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 919, left: 49, top: 108, height: 'auto' }}
              data-name="text-4beff2"
              data-origin-id="c5dbd006bd52794aa6fbda92d946ba7f"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  transform: 'rotate(0deg)',
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 26,
                    textAlign: 'left',
                    fontSize: '120%',
                    lineHeight: '1.04'
                  }}
                  data-has-line-height=""
                >
                  <h1 style={{ textAlign: 'right' }}>אבטחת מידע</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 941, left: 33, top: 511, height: 'auto' }}
              data-name="text-c5c5c4"
              data-origin-id="5bab1c3ada8188296d4dc782cda65bb6"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)',
                  fontSize: '120%',
                  padding: 40
                }}
              >
                <p>ע&quot;י צוות התוכן של מגדלור</p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-0acffb"
              style={{
                width: 273,
                height: '127.753px',
                left: '239.13px',
                top: 288,
                minWidth: 1,
                minHeight: 1
              }}
              data-origin-id="c31878c2d892d18921f5e8c067482a82"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  alt="cyber"
                  style={{ left: 0, top: '-97px', width: 273, height: 273 }}
                  data-natural-width={1920}
                  data-natural-height={1920}
                  data-crop-x={0}
                  data-crop-y="0.355"
                  data-crop-width={1}
                  data-crop-height="0.467959"
                  data-lazy-loaded=""
                  data-src="https://images.unsplash.com/photo-1512486130939-2c4f79935e4f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjIwNzA2fQ"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-6c9650"
              style={{
                width: '209.333px',
                height: 277,
                left: '268.833px',
                top: '393.833px',
                minWidth: 1,
                minHeight: 1
              }}
              data-origin-id="e4b580e61e9dc2772232ba07eb844b44"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(90deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 11 }}>
                  <img
                    alt="cyber"
                    style={{ left: 0, top: '-37px', width: 209, height: 314 }}
                    data-natural-width={1920}
                    data-natural-height={2880}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y="0.117834"
                    data-crop-width={1}
                    data-crop-height="0.882166"
                    data-src="https://images.unsplash.com/photo-1492107376256-4026437926cd?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjIwNzA2fQ"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-f63f33"
              style={{
                width: '191.777px',
                height: 349,
                left: 32,
                top: 288,
                minWidth: 1,
                minHeight: 1
              }}
              data-origin-id="80f7269279021ca106a8a05354108367"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  alt="cyber"
                  style={{ left: '-132px', top: 0, width: 620, height: 349 }}
                  data-natural-width={1920}
                  data-natural-height={1080}
                  data-lazy-loaded=""
                  data-crop-x="0.212903"
                  data-crop-y={0}
                  data-crop-width="0.309096"
                  data-crop-height={1}
                  data-src="https://images.unsplash.com/photo-1480944657103-7fed22359e1d?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjIwNzA2fQ"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-2a38eb"
              style={{
                width: 480,
                height: '340.667px',
                left: 32,
                top: '-62px',
                minWidth: 1,
                minHeight: 1
              }}
              data-origin-id="adcb9bc3ec3bada5b2f96941b37c48bd"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  alt="cyber"
                  data-natural-width={1920}
                  data-natural-height={1280}
                  style={{ left: 0, top: 0, width: 511, height: 341 }}
                  data-lazy-loaded=""
                  data-crop-x={0}
                  data-crop-y={0}
                  data-crop-width="0.939335"
                  data-crop-height={1}
                  data-src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1920&fit=max&ixid=eyJhcHBfaWQiOjIwNzA2fQ"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 413,
                left: '545.396px',
                top: 135,
                height: 'auto'
              }}
              data-name="text-4beff2"
              data-origin-id="cdf41569d458e907bd4e001f084c15dc"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 14,
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 14,
                    color: 'rgb(255, 255, 255)',
                    transitionDuration: '0.6s',
                    transitionDelay: '0.3s',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 16,
                    textAlign: 'center',
                    lineHeight: '1.17'
                  }}
                  data-animation-type="slide-up"
                  data-has-line-height=""
                >
                  <p>כיצד נלמד</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '479.87px',
                left: '511.961px',
                top: '216.5px',
                height: 'auto'
              }}
              data-name="text-811b48"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  textAlign: 'left',
                  fontSize: '84%',
                  padding: 30
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  ההתקדמות היא באמצעות החצים &lt; או &gt; במצגת.
                  <br type="_moz" />
                </p>
                <p style={{ textAlign: 'right' }}>
                  כשמגיעים לשקופית שמכילה שאלה, אי אפשר להתקדם עד שעונים נכון
                  עליה
                  <br type="_moz" />
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 413,
                left: '545.396px',
                top: 135,
                height: 'auto'
              }}
              data-name="text-4467f4"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 16,
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 16,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 16,
                    textAlign: 'center',
                    lineHeight: '1.17'
                  }}
                  data-has-line-height=""
                >
                  <p>כיצד נלמד</p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-46c974"
              style={{ height: 'auto' }}
              data-origin-id="a538824844986d8a46983b157c72fa56"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '458.25px 388px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '374.324px',
                    height: 600,
                    left: '466.926px',
                    top: 88
                  }}
                  data-name="shape-c138f8"
                  data-origin-id="85c9fd850906e4b0e0970c3852f490b0"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 11,
                      filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="rect"
                      data-shape-fill-color="rgb(9, 47, 84)"
                      data-shape-stretch="true"
                      style={{ zIndex: 11 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 374 600"
                      >
                        <rect
                          width="374.324"
                          height={600}
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgb(9, 47, 84)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '374.324px',
                    left: '466.926px',
                    top: '248.5px',
                    height: 'auto'
                  }}
                  data-name="text-c29943"
                  data-origin-id="7829823dab2e2d52b32e1c073b741c83"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 12,
                      color: 'rgb(234, 234, 234)',
                      padding: 25,
                      lineHeight: '1.17'
                    }}
                    data-has-line-height=""
                  >
                    <p style={{ textAlign: 'justify' }}>
                      תורת ההגנה למגזר הבריאות מספקת לארגוני הבריאות, בתי חולים,
                      לקופות החולים ולשרשרת האספקה של מוסדות אלה, הנחיות כיצד
                      לייצר מסגרת הגנה על כלל רכיבי הרציפות התפקודית של הארגון.
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  data-name="shape-6be669"
                  style={{
                    width: '44.6214px',
                    height: 54,
                    left: 480,
                    top: 117
                  }}
                  data-origin-id="69faf1a38602c6c8fef9154e6d2e744e"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-plus-circle"
                    data-shape-fill-color="#ededed"
                    data-shape-stretch="false"
                    style={{ zIndex: 13 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="#ededed"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '374.324px',
                    left: '75.25px',
                    top: '248.5px',
                    height: 'auto'
                  }}
                  data-name="text-a95cf0"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 14,
                      color: 'rgb(234, 234, 234)',
                      padding: 25,
                      lineHeight: '1.17'
                    }}
                    data-has-line-height=""
                  >
                    <p style={{ textAlign: 'justify' }}>
                      מטרתה של התורה גם לסייע למוסדות אלה להגן על סודיות,
                      אמינות, וזמינות של המידע שנמצא ברשותם.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-4b4a7e"
              style={{ height: 'auto' }}
              data-origin-id="a7ff3a30ef15c3e5f8ada6102d5c4ad4"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '148.5px 144px 0px', zIndex: 15 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 54, height: 54, left: '121.5px', top: 117 }}
                  data-name="shape-4a931b"
                  data-origin-id="1be71190d8622788719c2314604ae694"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 16, opacity: '0.38' }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-minus-circle"
                      data-shape-fill-color="#000000"
                      data-shape-stretch="true"
                      style={{ zIndex: 16 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 32 32"
                      >
                        <g
                          className="shape-element"
                          fill="#000000"
                          data-viewbox="0 0 32 32"
                        >
                          <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-16v-4h16v4z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 512, left: 224, top: 0, height: 'auto' }}
              data-name="text-4467f4"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 17,
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 17,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 16,
                    textAlign: 'center',
                    lineHeight: '1.17'
                  }}
                  data-has-line-height=""
                >
                  <h3>
                    <span style={{ fontSize: '0.7em' }}>
                      תורת הגנה למגזר הבריאות
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '80.5px',
                left: '75.25px',
                top: 604
              }}
              data-name="text-2a999b"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 18, color: 'rgb(32, 18, 77)' }}
              >
                <p>
                  <span style={{ fontSize: '0.7em' }}>המשך..</span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-46c974"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '500.5px 405.5px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '398.12px',
                    height: 457,
                    left: '89.88px',
                    top: 177
                  }}
                  data-name="shape-c138f8"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 15,
                      filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="rect"
                      data-shape-fill-color="rgb(9, 47, 84)"
                      data-shape-stretch="true"
                      style={{ zIndex: 15 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 398 457"
                      >
                        <rect
                          width="398.12"
                          height={457}
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgb(9, 47, 84)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '398.12px',
                    left: '89.88px',
                    top: '276.5px',
                    height: 'auto'
                  }}
                  data-name="text-c29943"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 16,
                      color: 'rgb(234, 234, 234)',
                      padding: 25,
                      lineHeight: '1.17'
                    }}
                    data-has-line-height=""
                  >
                    <p style={{ textAlign: 'justify' }}>
                      מטרתה של התורה גם לסייע למוסדות אלה להגן על סודיות,
                      אמינות, וזמינות של המידע שנמצא ברשותם.
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  data-name="shape-6be669"
                  style={{
                    width: '47.458px',
                    height: 54,
                    left: '106.73px',
                    top: '186.5px'
                  }}
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-plus-circle"
                    data-shape-fill-color="#ededed"
                    data-shape-stretch="true"
                    style={{ zIndex: 17 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="#ededed"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '398.12px',
                    left: 513,
                    top: '276.5px',
                    height: 'auto'
                  }}
                  data-name="text-a95cf0"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 18,
                      color: 'rgb(234, 234, 234)',
                      padding: 25,
                      lineHeight: '1.17'
                    }}
                    data-has-line-height=""
                  >
                    <p style={{ textAlign: 'justify' }}>
                      תורת ההגנה למגזר הבריאות מספקת לארגוני הבריאות, בתי חולים,
                      לקופות החולים ולשרשרת האספקה של מוסדות אלה הנחיות כיצד
                      לייצר מסגרת הגנה על כלל רכיבי הרציפות התפקודית של הארגון.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-4b4a7e"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '575px 211.5px 0px', zIndex: 19 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 54, height: 54, left: 548, top: '184.5px' }}
                  data-name="shape-4a931b"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 21, opacity: '0.38' }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-minus-circle"
                      data-shape-fill-color="#000000"
                      data-shape-stretch="true"
                      style={{ zIndex: 21 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 32 32"
                      >
                        <g
                          className="shape-element"
                          fill="#000000"
                          data-viewbox="0 0 32 32"
                        >
                          <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-16v-4h16v4z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 512, left: 232, top: 0, height: 'auto' }}
              data-name="text-4467f4"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 22,
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 22,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 16,
                    textAlign: 'center',
                    lineHeight: '1.17'
                  }}
                  data-has-line-height=""
                >
                  <h3>
                    <span style={{ fontSize: '0.7em' }}>
                      תורת הגנה למגזר הבריאות
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 722, left: 156, top: '77.5px', height: 'auto' }}
              data-name="text-4beff2"
              data-origin-id="4eea89d7e3c9f9aa610b3c9399b0ace1"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 16,
                    textAlign: 'center',
                    lineHeight: '1.17'
                  }}
                  data-has-line-height=""
                >
                  <h2>
                    <span style={{ fontSize: '0.7em' }}>
                      מהפכה טכנולוגית במגזר הבריאות
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 202, top: '204.5px' }}
              data-name="text-bc51c5"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <p style={{ textAlign: 'justify' }}>
                  בעשורים האחרונים, המהפכה הטכנולוגית השפיעה רבות על מגזר
                  הבריאות, כשהיא נוגעת כמעט בכל תחומי העשייה המקצועיים, החל
                  מהאופן בו נשמר ומנוהל מידע רפואי רגיש, אופן זרימת המידע בין
                  המכשור הרפואי הייעודי ובין הצוותים הרפואיים ומערכת המידע, ובין
                  המוסדות הרפואיים לצורך שיתוף מידע רפואי רלוונטי ביניהם ובאופן
                  מקוון מול המטופלים. מהפכה זו מובילה לשיפור בריאות הציבור,
                  שיפור איכות החיים, שיפור השירות, ולצמיחת מערכת הבריאות.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 42, height: 43, left: 830, top: 219 }}
              data-name="shape-50675d"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-globe"
                data-shape-fill-color="rgb(255, 60, 112)"
                data-shape-stretch="true"
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 2 30 30"
                >
                  <g
                    className="shape-element"
                    fill="rgb(255, 60, 112)"
                    data-viewbox="0 2 30 30"
                  >
                    <path d="M15 2c-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15s-6.716-15-15-15zM23.487 22c0.268-1.264 0.437-2.606 0.492-4h3.983c-0.104 1.381-0.426 2.722-0.959 4h-3.516zM6.513 12c-0.268 1.264-0.437 2.606-0.492 4h-3.983c0.104-1.381 0.426-2.722 0.959-4h3.516zM21.439 12c0.3 1.28 0.481 2.62 0.54 4h-5.979v-4h5.439zM16 10v-5.854c0.456 0.133 0.908 0.355 1.351 0.668 0.831 0.586 1.625 1.488 2.298 2.609 0.465 0.775 0.867 1.638 1.203 2.578h-4.852zM10.351 7.422c0.673-1.121 1.467-2.023 2.298-2.609 0.443-0.313 0.895-0.535 1.351-0.668v5.854h-4.852c0.336-0.94 0.738-1.803 1.203-2.578zM14 12v4h-5.979c0.059-1.38 0.24-2.72 0.54-4h5.439zM2.997 22c-0.533-1.278-0.854-2.619-0.959-4h3.983c0.055 1.394 0.224 2.736 0.492 4h-3.516zM8.021 18h5.979v4h-5.439c-0.3-1.28-0.481-2.62-0.54-4zM14 24v5.854c-0.456-0.133-0.908-0.355-1.351-0.668-0.831-0.586-1.625-1.488-2.298-2.609-0.465-0.775-0.867-1.638-1.203-2.578h4.852zM19.649 26.578c-0.673 1.121-1.467 2.023-2.298 2.609-0.443 0.312-0.895 0.535-1.351 0.668v-5.854h4.852c-0.336 0.94-0.738 1.802-1.203 2.578zM16 22v-4h5.979c-0.059 1.38-0.24 2.72-0.54 4h-5.439zM23.98 16c-0.055-1.394-0.224-2.736-0.492-4h3.516c0.533 1.278 0.855 2.619 0.959 4h-3.983zM25.958 10h-2.997c-0.582-1.836-1.387-3.447-2.354-4.732 1.329 0.636 2.533 1.488 3.585 2.54 0.671 0.671 1.261 1.404 1.766 2.192zM5.808 7.808c1.052-1.052 2.256-1.904 3.585-2.54-0.967 1.285-1.771 2.896-2.354 4.732h-2.997c0.504-0.788 1.094-1.521 1.766-2.192zM4.042 24h2.997c0.583 1.836 1.387 3.447 2.354 4.732-1.329-0.636-2.533-1.488-3.585-2.54-0.671-0.671-1.261-1.404-1.766-2.192zM24.192 26.192c-1.052 1.052-2.256 1.904-3.585 2.54 0.967-1.285 1.771-2.896 2.354-4.732h2.997c-0.504 0.788-1.094 1.521-1.766 2.192z" />
                  </g>
                </svg>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 722, left: 153, top: '82.5px', height: 'auto' }}
              data-name="text-4beff2"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 16,
                    textAlign: 'center',
                    lineHeight: '1.17'
                  }}
                  data-has-line-height=""
                >
                  <h2>
                    <span style={{ fontSize: '0.7em' }}>
                      מהפכה טכנולוגית במגזר הבריאות
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-0b9946"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '482.839px 315px 0px', zIndex: 12 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '631.236px',
                    left: '167.221px',
                    top: '252.5px',
                    height: 'auto'
                  }}
                  data-name="text-a770d7"
                  data-origin-id="9cf0f7e397733d621cc775552ab29e85"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 13,
                      textAlign: 'justify',
                      color: 'rgb(34, 34, 34)',
                      fontSize: '90%',
                      padding: 10
                    }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      מהפכת הבריאות הדיגיטלית טומנת בחובה גם סיכון למתקפות סייבר
                      שיכולות להביא לגניבת מידע אישי ורפואי רגיש ופגיעה בפרטיות
                      המטופלים.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-3584fe"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480.31px 491.5px 0px', zIndex: 14 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '626.177px',
                    left: '167.221px',
                    top: 429,
                    height: 'auto'
                  }}
                  data-origin-id="18f428b01c01f71e38d5ad713627c1ae"
                  data-name="text-ecfa17"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 15,
                      textAlign: 'justify',
                      color: 'rgb(34, 34, 34)',
                      fontSize: '90%',
                      padding: 10
                    }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      חדירה למערכות טכנולוגיות במגזר הרפואי יכולה להוביל לפגיעה
                      מהותית באספקת שירות רפואי חיוני, ולזליגת מידע רפואי רגיש.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 42,
                height: 43,
                left: '808.78px',
                top: '448.5px'
              }}
              data-name="shape-9d3733"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-globe"
                data-shape-fill-color="rgb(255, 60, 112)"
                data-shape-stretch="true"
                style={{ zIndex: 16 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 2 30 30"
                >
                  <g
                    className="shape-element"
                    fill="rgb(255, 60, 112)"
                    data-viewbox="0 2 30 30"
                  >
                    <path d="M15 2c-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15s-6.716-15-15-15zM23.487 22c0.268-1.264 0.437-2.606 0.492-4h3.983c-0.104 1.381-0.426 2.722-0.959 4h-3.516zM6.513 12c-0.268 1.264-0.437 2.606-0.492 4h-3.983c0.104-1.381 0.426-2.722 0.959-4h3.516zM21.439 12c0.3 1.28 0.481 2.62 0.54 4h-5.979v-4h5.439zM16 10v-5.854c0.456 0.133 0.908 0.355 1.351 0.668 0.831 0.586 1.625 1.488 2.298 2.609 0.465 0.775 0.867 1.638 1.203 2.578h-4.852zM10.351 7.422c0.673-1.121 1.467-2.023 2.298-2.609 0.443-0.313 0.895-0.535 1.351-0.668v5.854h-4.852c0.336-0.94 0.738-1.803 1.203-2.578zM14 12v4h-5.979c0.059-1.38 0.24-2.72 0.54-4h5.439zM2.997 22c-0.533-1.278-0.854-2.619-0.959-4h3.983c0.055 1.394 0.224 2.736 0.492 4h-3.516zM8.021 18h5.979v4h-5.439c-0.3-1.28-0.481-2.62-0.54-4zM14 24v5.854c-0.456-0.133-0.908-0.355-1.351-0.668-0.831-0.586-1.625-1.488-2.298-2.609-0.465-0.775-0.867-1.638-1.203-2.578h4.852zM19.649 26.578c-0.673 1.121-1.467 2.023-2.298 2.609-0.443 0.312-0.895 0.535-1.351 0.668v-5.854h4.852c-0.336 0.94-0.738 1.802-1.203 2.578zM16 22v-4h5.979c-0.059 1.38-0.24 2.72-0.54 4h-5.439zM23.98 16c-0.055-1.394-0.224-2.736-0.492-4h3.516c0.533 1.278 0.855 2.619 0.959 4h-3.983zM25.958 10h-2.997c-0.582-1.836-1.387-3.447-2.354-4.732 1.329 0.636 2.533 1.488 3.585 2.54 0.671 0.671 1.261 1.404 1.766 2.192zM5.808 7.808c1.052-1.052 2.256-1.904 3.585-2.54-0.967 1.285-1.771 2.896-2.354 4.732h-2.997c0.504-0.788 1.094-1.521 1.766-2.192zM4.042 24h2.997c0.583 1.836 1.387 3.447 2.354 4.732-1.329-0.636-2.533-1.488-3.585-2.54-0.671-0.671-1.261-1.404-1.766-2.192zM24.192 26.192c-1.052 1.052-2.256 1.904-3.585 2.54 0.967-1.285 1.771-2.896 2.354-4.732h2.997c-0.504 0.788-1.094 1.521-1.766 2.192z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 42, height: 43, left: '808.78px', top: 272 }}
              data-name="shape-50675d"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-globe"
                data-shape-fill-color="rgb(255, 60, 112)"
                data-shape-stretch="true"
                style={{ zIndex: 17 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 2 30 30"
                >
                  <g
                    className="shape-element"
                    fill="rgb(255, 60, 112)"
                    data-viewbox="0 2 30 30"
                  >
                    <path d="M15 2c-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15s-6.716-15-15-15zM23.487 22c0.268-1.264 0.437-2.606 0.492-4h3.983c-0.104 1.381-0.426 2.722-0.959 4h-3.516zM6.513 12c-0.268 1.264-0.437 2.606-0.492 4h-3.983c0.104-1.381 0.426-2.722 0.959-4h3.516zM21.439 12c0.3 1.28 0.481 2.62 0.54 4h-5.979v-4h5.439zM16 10v-5.854c0.456 0.133 0.908 0.355 1.351 0.668 0.831 0.586 1.625 1.488 2.298 2.609 0.465 0.775 0.867 1.638 1.203 2.578h-4.852zM10.351 7.422c0.673-1.121 1.467-2.023 2.298-2.609 0.443-0.313 0.895-0.535 1.351-0.668v5.854h-4.852c0.336-0.94 0.738-1.803 1.203-2.578zM14 12v4h-5.979c0.059-1.38 0.24-2.72 0.54-4h5.439zM2.997 22c-0.533-1.278-0.854-2.619-0.959-4h3.983c0.055 1.394 0.224 2.736 0.492 4h-3.516zM8.021 18h5.979v4h-5.439c-0.3-1.28-0.481-2.62-0.54-4zM14 24v5.854c-0.456-0.133-0.908-0.355-1.351-0.668-0.831-0.586-1.625-1.488-2.298-2.609-0.465-0.775-0.867-1.638-1.203-2.578h4.852zM19.649 26.578c-0.673 1.121-1.467 2.023-2.298 2.609-0.443 0.312-0.895 0.535-1.351 0.668v-5.854h4.852c-0.336 0.94-0.738 1.802-1.203 2.578zM16 22v-4h5.979c-0.059 1.38-0.24 2.72-0.54 4h-5.439zM23.98 16c-0.055-1.394-0.224-2.736-0.492-4h3.516c0.533 1.278 0.855 2.619 0.959 4h-3.983zM25.958 10h-2.997c-0.582-1.836-1.387-3.447-2.354-4.732 1.329 0.636 2.533 1.488 3.585 2.54 0.671 0.671 1.261 1.404 1.766 2.192zM5.808 7.808c1.052-1.052 2.256-1.904 3.585-2.54-0.967 1.285-1.771 2.896-2.354 4.732h-2.997c0.504-0.788 1.094-1.521 1.766-2.192zM4.042 24h2.997c0.583 1.836 1.387 3.447 2.354 4.732-1.329-0.636-2.533-1.488-3.585-2.54-0.671-0.671-1.261-1.404-1.766-2.192zM24.192 26.192c-1.052 1.052-2.256 1.904-3.585 2.54 0.967-1.285 1.771-2.896 2.354-4.732h2.997c-0.504 0.788-1.094 1.521-1.766 2.192z" />
                  </g>
                </svg>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 800, left: 80, top: '67.5px', height: 'auto' }}
              data-name="text-c29943"
              data-origin-id="07fa5b8629e1ca213f57abd4370cb213"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 10,
                  color: 'rgb(7, 47, 84)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h3>
                  <span style={{ fontSize: '0.7em' }}>
                    למערכת הבריאות קיימים איומים מובהקים בתחום ההגנה על המידע
                    הכוללים, בין השאר:
                  </span>
                </h3>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
              data-origin-id="290e38b2c0f495b99556fdd91f6d05fd"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '199px 410px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 238, height: 350, left: 80, top: 235 }}
                  data-name="shape-e1e4ba"
                  data-origin-id="b2ae3a8b1fcc17319a1d403abac71c77"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 12,
                      filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="rect"
                      data-shape-fill-color="rgb(9, 47, 84)"
                      data-shape-stretch="true"
                      style={{ zIndex: 12 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 238 350"
                      >
                        <rect
                          width={238}
                          height={350}
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgb(9, 47, 84)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{ width: 238, left: 80, top: 378, height: 'auto' }}
                  data-name="text-96a5c5"
                  data-origin-id="5e04a41351cd092aaf1c21a2dc69b03a"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 13,
                      textAlign: 'left',
                      fontSize: '75%',
                      paddingTop: 0,
                      color: 'rgb(255, 255, 255)',
                      padding: 20,
                      marginTop: -10
                    }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <strong>
                        שיבוש מידע - הנזק אשר יכול להיגרם משינוי מידע בתיקו
                        הקליני של מטופל עולה לעיתים על הנזק מגניבתו, ויכול להוות
                        בסיס להחלטות רפואיות שגויות.
                      </strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 70, height: 70, left: 222, top: 270 }}
                  data-name="shape-4a0161"
                  data-origin-id="a57e78e920761616580250819e90f70c"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 14 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 70 70"
                    >
                      <ellipse
                        rx={35}
                        ry={35}
                        cx={35}
                        cy={35}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  data-name="shape-6493da"
                  style={{ width: 40, height: 40, left: 237, top: 285 }}
                  data-origin-id="82b3b8a6a4092ef13cffb4b4ff905721"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-bolt"
                    data-shape-fill-color="rgb(0, 0, 0)"
                    data-shape-stretch="false"
                    style={{ zIndex: 15 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(0, 0, 0)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M32 0l-24 16 6 4-14 12 24-12-6-4z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-98fd7b"
              data-origin-id="bad0e00759c548829e58d6c408166362"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '483.5px 410px 0px', zIndex: 16 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 238, height: 350, left: 361, top: 235 }}
                  data-name="shape-2010af"
                  data-origin-id="921454afeab06e942033b55c87e6d602"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 17,
                      filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="rect"
                      data-shape-fill-color="rgb(9, 47, 84)"
                      data-shape-stretch="true"
                      style={{ zIndex: 17 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 238 350"
                      >
                        <rect
                          width={238}
                          height={350}
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgb(9, 47, 84)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{ width: 238, left: 368, top: 378, height: 'auto' }}
                  data-name="text-12f94a"
                  data-origin-id="232a5725a60d9efb75ef3732b446d80c"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 18,
                      textAlign: 'left',
                      fontSize: '75%',
                      paddingTop: 0,
                      color: 'rgb(255, 255, 255)',
                      padding: 20,
                      marginTop: -10
                    }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <strong>
                        גניבת מידע - מידע רפואי הינו מידע אישי רגיש ולגניבתו
                        עשויות להיות השלכות קשות ברמה האישית וברמת האמון במוסדות
                        הבריאות במדינה.&nbsp;
                      </strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 70, height: 70, left: 503, top: 270 }}
                  data-name="shape-340ede"
                  data-origin-id="da7d691b69f5116a44cd58218d865520"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 70 70"
                    >
                      <ellipse
                        rx={35}
                        ry={35}
                        cx={35}
                        cy={35}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 40, height: 40, left: 518, top: 285 }}
                  data-name="shape-f58319"
                  data-origin-id="68bfd427f0b8b9b7fd559f7762bfdd77"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-home"
                    data-shape-fill-color="rgb(0, 0, 0)"
                    data-shape-stretch="false"
                    style={{ zIndex: 20 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(0, 0, 0)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0l-16 16h4v16h24v-16h4l-16-16zM24 28h-6v-6h-4v6h-6v-14.344l8-5.656 8 5.656v14.344z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-194a16"
              data-origin-id="fe50404ef274423d71f2bc517871f942"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '761px 410px 0px', zIndex: 21 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 238, height: 350, left: 642, top: 235 }}
                  data-name="shape-6651bb"
                  data-origin-id="48466e56e5042a5ac6701e093cb5cd39"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 22,
                      filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="rect"
                      data-shape-fill-color="rgb(9, 47, 84)"
                      data-shape-stretch="true"
                      style={{ zIndex: 22 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 238 350"
                      >
                        <rect
                          width={238}
                          height={350}
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgb(9, 47, 84)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 70, height: 70, left: 784, top: 270 }}
                  data-name="shape-4c0dbf"
                  data-origin-id="50c83b162e63825dc36012749233f634"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 23 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 70 70"
                    >
                      <ellipse
                        rx={35}
                        ry={35}
                        cx={35}
                        cy={35}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 40, height: 40, left: 799, top: 285 }}
                  data-name="shape-98d483"
                  data-origin-id="e046412281c4968ccf48def6823870bd"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-earth"
                    data-shape-fill-color="rgb(0, 0, 0)"
                    data-shape-stretch="false"
                    style={{ zIndex: 24 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="-9.5367431640625e-7 -9.5367431640625e-7 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(0, 0, 0)"
                        data-viewbox="-9.5367431640625e-7 -9.5367431640625e-7 32 32"
                      >
                        <path d="M27.314 4.686c3.022 3.022 4.686 7.040 4.686 11.314s-1.664 8.292-4.686 11.314c-3.022 3.022-7.040 4.686-11.314 4.686s-8.292-1.664-11.314-4.686c-3.022-3.022-4.686-7.040-4.686-11.314s1.664-8.292 4.686-11.314c3.022-3.022 7.040-4.686 11.314-4.686s8.292 1.664 11.314 4.686zM25.899 25.9c1.971-1.971 3.281-4.425 3.821-7.096-0.421 0.62-0.824 0.85-1.073-0.538-0.257-2.262-2.335-0.817-3.641-1.621-1.375 0.927-4.466-1.802-3.941 1.276 0.81 1.388 4.375-1.858 2.598 1.079-1.134 2.050-4.145 6.592-3.753 8.946 0.049 3.43-3.504 0.715-4.729-0.422-0.824-2.279-0.281-6.262-2.434-7.378-2.338-0.102-4.344-0.314-5.25-2.927-0.545-1.87 0.58-4.653 2.584-5.083 2.933-1.843 3.98 2.158 6.731 2.232 0.854-0.894 3.182-1.178 3.375-2.18-1.805-0.318 2.29-1.517-0.173-2.199-1.358 0.16-2.234 1.409-1.512 2.467-2.632 0.614-2.717-3.809-5.247-2.414-0.064 2.206-4.132 0.715-1.407 0.268 0.936-0.409-1.527-1.594-0.196-1.379 0.654-0.036 2.854-0.807 2.259-1.325 1.225-0.761 2.255 1.822 3.454-0.059 0.866-1.446-0.363-1.713-1.448-0.98-0.612-0.685 1.080-2.165 2.573-2.804 0.497-0.213 0.973-0.329 1.336-0.296 0.752 0.868 2.142 1.019 2.215-0.104-1.862-0.892-3.915-1.363-6.040-1.363-3.051 0-5.952 0.969-8.353 2.762 0.645 0.296 1.012 0.664 0.39 1.134-0.483 1.439-2.443 3.371-4.163 3.098-0.893 1.54-1.482 3.238-1.733 5.017 1.441 0.477 1.773 1.42 1.464 1.736-0.734 0.64-1.185 1.548-1.418 2.541 0.469 2.87 1.818 5.515 3.915 7.612 2.644 2.644 6.16 4.1 9.899 4.1s7.255-1.456 9.899-4.1z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{ width: 238, left: 642, top: 384, height: 'auto' }}
                  data-name="text-723a81"
                  data-origin-id="6361a2bd076030f64e2dc0fe976968da"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 25,
                      textAlign: 'left',
                      fontSize: '75%',
                      paddingTop: 0,
                      color: 'rgb(255, 255, 255)',
                      padding: 20,
                      marginTop: -10
                    }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <strong>
                        &nbsp;מניעת שירות (DENIAL OF SERVICE) - דבר אשר עשוי
                        לפגוע במתן שירות רפואי חיוני בשגרה ובמיוחד בחירום.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-9673a5"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '777.5px 72.75px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 132,
                    left: 781,
                    top: '40.5px',
                    height: 'auto'
                  }}
                  data-name="text-c29943"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 11,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%',
                      lineHeight: '1.17'
                    }}
                    data-has-line-height=""
                  >
                    <p>שאלה</p>
                  </div>
                </div>
              </div>
            </div>
            <QuestionSlide
              key="first-cyber-question"
              handleCorrectAnswer={handleCorrectAnswer}
              handleWrongAnswer={handleWrongAnswer}
              {...getQuestionData(globalCoursewares, selectedCourseware, 7)}
              selectedCourseware={selectedCourseware}
            />
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 799, left: '106.5px', top: 50, height: 'auto' }}
              data-name="text-4beff2"
              data-origin-id="f717ee72142968d377a83cdbec090de9"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter: 'drop-shadow(rgb(8, 121, 228) 8px 8px 0px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgb(9, 47, 84)',
                    padding: 16,
                    // textAlign: 'left',
                    lineHeight: '1.17'
                  }}
                  data-has-line-height=""
                >
                  <h2>עקרונות יסוד לניהול הגנת הסייבר</h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 750, left: 131, top: '189.5px' }}
              data-name="text-eca287"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, marginTop: '12px' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  הארגון הרפואי ינהל את סיכוני הסייבר שלו בראיה הוליסטית כחלק
                  מההערכות שלו לפעילות חריגה
                  <strong> (כגון שעת חירום) </strong>
                  ובמסגרת תפיסת ניהול סיכונים והדרישה לקיום תהליכי המשכיות,
                  תהליכי טיפול רפואי, שמיושמת בכל מוסד רפואי.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 750,
                left: 139,
                top: '393.287px'
              }}
              data-name="text-29256f"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <p style={{ textAlign: 'justify' }}>
                  תפיסת הגנת המוסד הרפואי תתבסס על איום הייחוס כפי שנקבע על ידי
                  המשרד, בהתאם לאיום יחוס זה, הארגון יקח בחשבון כי לכל הפחות
                  נכסים אלה יהוו היעדים לתקיפתו
                  <br />
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="line"
              data-name="line-238933"
              style={{
                width: 'auto',
                height: 'auto',
                left: 84,
                top: '360.25px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-line-x1={0}
                  data-line-y1={0}
                  data-line-x2={876}
                  data-line-y2={0}
                  data-line-color="#0000ff"
                  data-line-start-type="line-arrow"
                  data-line-end-type="none"
                  style={{ zIndex: 10 }}
                  data-line-width="2px"
                  data-line-style="solid"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="xMidYMid"
                    width={876}
                    height={1}
                    viewBox="0 0 876 1"
                  >
                    <line
                      stroke="rgba(0,0,0,0)"
                      strokeWidth={15}
                      x1={2}
                      y1={0}
                      x2={876}
                      y2={0}
                    />
                    <line
                      className="line-element"
                      stroke="#0000ff"
                      strokeWidth={2}
                      x1={2}
                      y1={0}
                      x2={876}
                      y2={0}
                    />
                    <path
                      style={{ fill: 'rgba(0,0,0,0)' }}
                      stroke="#0000ff"
                      strokeWidth={2}
                      transform="translate(2,0) rotate(90)"
                      d="M -6 -6 L 0 0 L 6 -6"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-2d96a5"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '245.167px 250.5px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 24, height: 24, left: 233, top: '348.25px' }}
                  data-name="shape-05b447"
                  data-origin-id="1113bc543beb1c059fddf53378b78fa9"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 13,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="diamond"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 13 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 24 24"
                      >
                        <polygon
                          points="24,12 12,24 0,12 12,0"
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="line"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    minWidth: 0,
                    minHeight: 0,
                    left: 245,
                    top: '265.75px'
                  }}
                  data-name="line-aee647"
                  data-origin-id="336624cbeb5723556362e0cbd42315d5"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 12,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-line-x1={0}
                      data-line-y1="94.5"
                      data-line-x2={0}
                      data-line-y2={0}
                      data-line-color="#0000ff"
                      data-line-start-type="none"
                      data-line-end-type="none"
                      style={{ zIndex: 12 }}
                      data-line-width="2px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="xMidYMid"
                        width={1}
                        height="94.5"
                        viewBox="0 0 1 94.5"
                      >
                        <line
                          stroke="rgba(0,0,0,0)"
                          strokeWidth={15}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                        <line
                          className="line-element"
                          stroke="#0000ff"
                          strokeWidth={2}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '145.5px',
                    top: '128.75px',
                    height: 'auto'
                  }}
                  data-name="text-2a78c2"
                  data-origin-id="d528423291fe1a0a3dbf43319c1d2d8c"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 14, fontSize: '70%' }}
                  >
                    <h2>
                      <strong>5</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '145.167px',
                    top: 205,
                    height: 'auto'
                  }}
                  data-name="text-4e1d6f"
                  data-origin-id="ccfc8a8478aac9837f7c27bccb523655"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 15, textAlign: 'center', fontSize: '60%' }}
                  >
                    <p style={{ textAlign: 'center' }}>
                      מערכות חדרי ניתוח, הרדמה, דימות
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-866a7e"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '389.001px 460px 0px', zIndex: 16 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 24,
                    height: 24,
                    left: '377.167px',
                    top: '348.25px'
                  }}
                  data-name="shape-238df7"
                  data-origin-id="3df40ac941faefbe6095b899d942a574"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 18,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="diamond"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 18 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 24 24"
                      >
                        <polygon
                          points="24,12 12,24 0,12 12,0"
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="line"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    minWidth: 0,
                    minHeight: 0,
                    left: '389.167px',
                    top: '360.25px'
                  }}
                  data-name="line-dff621"
                  data-origin-id="3a8fc3807009ae0262c71026b4418e45"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 17,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-line-x1={0}
                      data-line-y1="94.5"
                      data-line-x2={0}
                      data-line-y2={0}
                      data-line-color="#0000ff"
                      data-line-start-type="none"
                      data-line-end-type="none"
                      style={{ zIndex: 17 }}
                      data-line-width="2px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="xMidYMid"
                        width={1}
                        height="94.5"
                        viewBox="0 0 1 94.5"
                      >
                        <line
                          stroke="rgba(0,0,0,0)"
                          strokeWidth={15}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                        <line
                          className="line-element"
                          stroke="#0000ff"
                          strokeWidth={2}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '289.167px',
                    top: '468.75px',
                    height: 'auto'
                  }}
                  data-name="text-77a191"
                  data-origin-id="9b88db29d15c3160e4997f8473403c1a"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 20, fontSize: '70%' }}
                  >
                    <h2>
                      <strong>4</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '289.167px',
                    top: '524.75px',
                    height: 'auto'
                  }}
                  data-name="text-c80f6c"
                  data-origin-id="c4b5a005d6586e30988f8a905f55ec9a"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 19, textAlign: 'center', fontSize: '60%' }}
                  >
                    <p style={{ textAlign: 'center' }}>
                      מערכות בקרה דוגמת חשמל, חמצן וכיבוי אש
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-e39e51"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '699.834px 448px 0px', zIndex: 21 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 24, height: 24, left: 687, top: '348.25px' }}
                  data-name="shape-fabd4e"
                  data-origin-id="a9c5da750d97b029ee87b3b3f37dbfd8"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 23,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="diamond"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 23 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 24 24"
                      >
                        <polygon
                          points="24,12 12,24 0,12 12,0"
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="line"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    minWidth: 0,
                    minHeight: 0,
                    left: 699,
                    top: '360.25px'
                  }}
                  data-name="line-4540d1"
                  data-origin-id="90f0f65fdaad8ad896ed0c0ce2b3be62"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 22,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-line-x1={0}
                      data-line-y1="94.5"
                      data-line-x2={0}
                      data-line-y2={0}
                      data-line-color="#0000ff"
                      data-line-start-type="none"
                      data-line-end-type="none"
                      style={{ zIndex: 22 }}
                      data-line-width="2px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="xMidYMid"
                        width={1}
                        height="94.5"
                        viewBox="0 0 1 94.5"
                      >
                        <line
                          stroke="rgba(0,0,0,0)"
                          strokeWidth={15}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                        <line
                          className="line-element"
                          stroke="#0000ff"
                          strokeWidth={2}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: 600,
                    top: '468.75px',
                    height: 'auto'
                  }}
                  data-name="text-7e71af"
                  data-origin-id="9a3cd2d8b1ac1336fdf3c419079ea6b7"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 25, fontSize: '70%' }}
                  >
                    <h2>
                      <strong>2</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: 600,
                    top: '524.75px',
                    height: 'auto'
                  }}
                  data-name="text-9b7ec7"
                  data-origin-id="91ad97b5961afc338a1ce5d9a82caa4f"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 24, textAlign: 'center', fontSize: '60%' }}
                  >
                    <p style={{ textAlign: 'center' }}>מערכות תומכות החלטה</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-f747fd"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '837.001px 250.25px 0px',
                  zIndex: 26
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 24,
                    height: 24,
                    left: '827.001px',
                    top: '348.25px'
                  }}
                  data-name="shape-c906ee"
                  data-origin-id="db6eeac72cfa7a2703cefa6fd571cb60"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 28,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="diamond"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 28 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 24 24"
                      >
                        <polygon
                          points="24,12 12,24 0,12 12,0"
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="line"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    minWidth: 0,
                    minHeight: 0,
                    left: '839.001px',
                    top: '265.75px'
                  }}
                  data-name="line-a36683"
                  data-origin-id="45eb3735c1236c60de53cf851d3c027a"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 27,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-line-x1={0}
                      data-line-y1="94.5"
                      data-line-x2={0}
                      data-line-y2={0}
                      data-line-color="#0000ff"
                      data-line-start-type="none"
                      data-line-end-type="none"
                      style={{ zIndex: 27 }}
                      data-line-width="2px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="xMidYMid"
                        width={1}
                        height="94.5"
                        viewBox="0 0 1 94.5"
                      >
                        <line
                          stroke="rgba(0,0,0,0)"
                          strokeWidth={15}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                        <line
                          className="line-element"
                          stroke="#0000ff"
                          strokeWidth={2}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '737.167px',
                    top: '128.25px',
                    height: 'auto'
                  }}
                  data-name="text-4bda9b"
                  data-origin-id="e0f7eedbdcbb8204f8642145d7d8abfd"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 29, fontSize: '70%' }}
                  >
                    <h2>
                      <strong>1</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '737.167px',
                    top: 205,
                    height: 'auto'
                  }}
                  data-name="text-452c90"
                  data-origin-id="477dc23b7073ad234766ea052f9dfc64"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 30, textAlign: 'center', fontSize: '60%' }}
                  >
                    <p style={{ textAlign: 'center' }}>
                      תיק קליני ונתוני מעבדה
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: '188.834px', top: 34 }}
              data-name="text-53a4f6"
            >
              <div className="sl-block-content" style={{ zIndex: 31 }}>
                <p>
                  עקרונות יסוד לניהול הגנת הסייבר
                  <br />
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-d19a8d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '541.001px 250.375px 0px',
                  zIndex: 32
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 24, height: 24, left: 530, top: 348 }}
                  data-origin-id="db857e3c2fd0e4b8f75f3bc46928453a"
                  data-name="shape-3024fc"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 34,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="diamond"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 34 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 24 24"
                      >
                        <polygon
                          points="24,12 12,24 0,12 12,0"
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="line"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    minWidth: 0,
                    minHeight: 0,
                    left: 542,
                    top: '265.5px'
                  }}
                  data-origin-id="04a0df759d6c79abce2a90e2315cae9c"
                  data-name="line-9a31db"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 33,
                      filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-line-x1={0}
                      data-line-y1="94.5"
                      data-line-x2={0}
                      data-line-y2={0}
                      data-line-color="#0000ff"
                      data-line-start-type="none"
                      data-line-end-type="none"
                      style={{ zIndex: 33 }}
                      data-line-width="2px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="xMidYMid"
                        width={1}
                        height="94.5"
                        viewBox="0 0 1 94.5"
                      >
                        <line
                          stroke="rgba(0,0,0,0)"
                          strokeWidth={15}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                        <line
                          className="line-element"
                          stroke="#0000ff"
                          strokeWidth={2}
                          x1={0}
                          y1="94.5"
                          x2={0}
                          y2={0}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '442.167px',
                    top: '128.75px',
                    height: 'auto'
                  }}
                  data-origin-id="402c0b5d6be74f86bad712e1d67c7191"
                  data-name="text-35a0bb"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 35, fontSize: '70%' }}
                  >
                    <h2>
                      <strong>3</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '199.667px',
                    left: '440.167px',
                    top: '204.5px',
                    height: 'auto'
                  }}
                  data-origin-id="a8c379dd5d86507be3955a44072f4dce"
                  data-name="text-26d3f4"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 36, textAlign: 'center', fontSize: '60%' }}
                  >
                    <p style={{ textAlign: 'center' }}>מכשור מעבדה</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="line"
              style={{
                width: 'auto',
                height: 'auto',
                minWidth: 0,
                minHeight: 0,
                left: '13.1237px',
                top: '360.25px'
              }}
              data-name="line-6c5a7b"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 37,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-line-x1="70.62073121735637"
                  data-line-y1={0}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color="#0000ff"
                  data-line-start-type="none"
                  data-line-end-type="none"
                  style={{ zIndex: 37 }}
                  data-line-width="2px"
                  data-line-style="dashed"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="xMidYMid"
                    width="70.62073121735637"
                    height={1}
                    viewBox="0 0 70.62073121735637 1"
                  >
                    <line
                      stroke="rgba(0,0,0,0)"
                      strokeWidth={15}
                      x1="70.62073121735637"
                      y1={0}
                      x2={0}
                      y2={0}
                    />
                    <line
                      className="line-element"
                      stroke="#0000ff"
                      strokeWidth={2}
                      strokeDasharray="5.385060934779697 5.385060934779697"
                      x1="70.62073121735637"
                      y1={0}
                      x2={0}
                      y2={0}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-image="https://images.unsplash.com/photo-1590172205836-40c14e6ac168?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDcwNnwwfDF8c2VhcmNofDF8fGVzY3xlbnwwfHx8fDE2MzgxODY4Njc&ixlib=rb-1.2.1&q=80&w=1920"
            data-background-opacity="0.1"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 800,
                left: 105,
                top: '103.251px',
                height: 'auto'
              }}
              data-name="text-b89361"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  transform: 'rotate(0deg)',
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 6px)'
                }}
              >
                <div
                  className="sl-block-content monospace-font"
                  style={{
                    zIndex: 10,
                    color: 'rgb(0, 0, 255)',
                    fontSize: '140%',
                    textAlign: 'left',
                    lineHeight: '1.04'
                  }}
                  data-has-line-height=""
                >
                  <h2 style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '0.9em' }}>
                      אסטרטגית הגנה ומסגרת לניהול סיכוני סייבר
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: 205,
                top: '333.689px'
              }}
              data-name="text-8327d9"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter:
                    'drop-shadow(rgb(234, 153, 153) 9px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{ zIndex: 11, color: 'rgb(0, 0, 0)' }}
                >
                  <p>
                    הארגון יקיים מערך הגנת סייבר המביא לידי ביטוי את העקרונות
                    הבאים:
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-image="https://images.unsplash.com/photo-1590172205836-40c14e6ac168?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDcwNnwwfDF8c2VhcmNofDF8fGVzY3xlbnwwfHx8fDE2MzgxODY4Njc&ixlib=rb-1.2.1&q=80&w=1920"
            data-background-opacity="0.1"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '215.37px',
                left: '6.378px',
                top: '21.5px',
                height: 'auto'
              }}
              data-name="text-3f789d"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'left',
                    fontSize: '44%',
                    color: 'rgb(0, 0, 255)',
                    padding: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0)'
                  }}
                >
                  <pre># עקרונות הגנת סייבר</pre>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="line"
              data-name="line-49cd6b"
              style={{ width: 'auto', height: 'auto', left: 188, top: 34 }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-line-x1={0}
                  data-line-y1="0.5"
                  data-line-x2="772.378264363198"
                  data-line-y2={0}
                  data-line-color="#0000ff"
                  data-line-start-type="none"
                  data-line-end-type="none"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="xMidYMid"
                    width="772.378264363198"
                    height={1}
                    viewBox="0 0 772.378264363198 1"
                  >
                    <line
                      stroke="rgba(0,0,0,0)"
                      strokeWidth={15}
                      x1={0}
                      y1="0.5"
                      x2="772.378264363198"
                      y2={0}
                    />
                    <line
                      className="line-element"
                      stroke="#0000ff"
                      strokeWidth={2}
                      x1={0}
                      y1="0.5"
                      x2="772.378264363198"
                      y2={0}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-ffdcda"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '497.54px 156.448px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '26.9682px',
                    height: '52.5111px',
                    left: '885.111px',
                    top: '130.193px'
                  }}
                  data-name="shape-cf0164"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 15,
                      opacity: '0.64',
                      filter:
                        'drop-shadow(rgb(255, 255, 255) 0px 0px 2px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-ribbon"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 15 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 16 32"
                      >
                        <g
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                          data-viewbox="0 0 16 32"
                        >
                          <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '755.685px',
                    left: 83,
                    top: '117.448px'
                  }}
                  data-name="text-030a63"
                >
                  <div className="sl-block-content" style={{ zIndex: 16 }}>
                    <p style={{ textAlign: 'justify' }}>
                      יצירת מערך הגנה משולב כח אדם, תהליכים וטכנולוגיות במספר
                      שכבות הגנה לטובת הקטנת משטח החשיפה לאיומי הסייבר.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-f329eb"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '497.54px 263.318px 0px',
                  zIndex: 17
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '26.9682px',
                    height: '52.5111px',
                    left: '885.111px',
                    top: '237.063px'
                  }}
                  data-name="shape-13fd02"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 20,
                      opacity: '0.64',
                      filter:
                        'drop-shadow(rgb(255, 255, 255) 0px 0px 2px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-ribbon"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 20 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 16 32"
                      >
                        <g
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                          data-viewbox="0 0 16 32"
                        >
                          <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '755.685px',
                    left: 83,
                    top: '224.318px'
                  }}
                  data-name="text-13d4ce"
                >
                  <div className="sl-block-content" style={{ zIndex: 21 }}>
                    <p style={{ textAlign: 'justify' }}>
                      מתן דגש לסיכוניו הייחודיים של הארגון על רקע מאפייניו של
                      מגזר הבריאות.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-0a4dad"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '497.54px 380.574px 0px',
                  zIndex: 22
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '26.9682px',
                    height: '52.5111px',
                    left: '885.111px',
                    top: '354.319px'
                  }}
                  data-name="shape-ac2655"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 25,
                      opacity: '0.64',
                      filter:
                        'drop-shadow(rgb(255, 255, 255) 0px 0px 2px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-ribbon"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 25 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 16 32"
                      >
                        <g
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                          data-viewbox="0 0 16 32"
                        >
                          <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '755.685px',
                    left: '83.0004px',
                    top: '341.574px'
                  }}
                  data-name="text-9f5d93"
                >
                  <div className="sl-block-content" style={{ zIndex: 26 }}>
                    <p style={{ textAlign: 'justify' }}>
                      הגנה על המידע הרפואי הרגיש המצוי בידי הארגון, מפני גניבה
                      או שיבוש (הנגשת שירותים מקוונים מאובטחים מרחוק).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-43d6bb"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '498.54px 489.444px 0px',
                  zIndex: 27
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '26.9682px',
                    height: '52.5111px',
                    left: '886.111px',
                    top: '463.189px'
                  }}
                  data-name="shape-bad92b"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 30,
                      opacity: '0.64',
                      filter:
                        'drop-shadow(rgb(255, 255, 255) 0px 0px 2px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-ribbon"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 30 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 16 32"
                      >
                        <g
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                          data-viewbox="0 0 16 32"
                        >
                          <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '755.685px',
                    left: 84,
                    top: '450.444px'
                  }}
                  data-name="text-93ea24"
                >
                  <div className="sl-block-content" style={{ zIndex: 31 }}>
                    <p style={{ textAlign: 'justify' }}>
                      שיתוף כלל הגורמים הרלוונטיים בארגון, ובכלל זה הצוותים
                      הרפואיים, בגיבוש וביישום הגנת הסייבר.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-362aa3"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '497.54px 596.444px 0px',
                  zIndex: 32
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '26.9682px',
                    height: '52.5111px',
                    left: '885.112px',
                    top: '570.189px'
                  }}
                  data-name="shape-243bde"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 35,
                      opacity: '0.64',
                      filter:
                        'drop-shadow(rgb(255, 255, 255) 0px 0px 2px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-ribbon"
                      data-shape-fill-color="rgb(0, 0, 255)"
                      data-shape-stretch="true"
                      style={{ zIndex: 35 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 16 32"
                      >
                        <g
                          className="shape-element"
                          fill="rgb(0, 0, 255)"
                          data-viewbox="0 0 16 32"
                        >
                          <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '755.685px',
                    left: '83.0004px',
                    top: '557.444px'
                  }}
                  data-name="text-8bd49d"
                >
                  <div className="sl-block-content" style={{ zIndex: 36 }}>
                    <p style={{ textAlign: 'justify' }}>
                      שימוש בכח אדם מיומן בנושא הגנת סייבר, ובכלל זה בדרך של
                      רכישת שירותי ייעוץ על ידי חברה חיצונית.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '662.503px',
                left: '257.247px',
                top: '174.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 3px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'left',
                    borderWidth: 1,
                    color: 'rgb(74, 134, 232)',
                    lineHeight: '1.56',
                    padding: 10
                  }}
                  data-has-line-height=""
                >
                  <ul style={{ textAlign: 'justify' }}>
                    <li>
                      <strong>
                        באחריות מנהל המוסד הרפואי לוודא רישום כלל מאגרי המידע
                        שבאחריותו.
                      </strong>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <strong>
                        העברת מידע רפואי אישי תעשה בכפוף לדרישות חוק זכויות
                        החולה וחוק הגנת הפרטיות.
                      </strong>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <strong>
                        אחסנת מידע רפואי אישי בפורטל אינטרנט מאובטח.
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '484.5px',
                left: '380.25px',
                top: '34.0679px',
                height: 'auto'
              }}
              data-name="text-6dae06"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 12,
                  color: 'rgb(0, 0, 0)',
                  fontSize: '90%',
                  lineHeight: '1.17',
                  padding: 10
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '1.2em' }}>הגנה על המידע</span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-dba733"
              style={{
                width: '266.352px',
                height: 700,
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.54' }}
              >
                <div className="sl-block-content" style={{ zIndex: 10 }}>
                  <img
                    alt="cyber"
                    style={{ left: 0, top: 0, width: 700, height: 700 }}
                    data-natural-width={1920}
                    data-natural-height={1921}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y={0}
                    data-crop-width="0.380701"
                    data-crop-height={1}
                    data-src="https://images.unsplash.com/photo-1571786256017-aee7a0c009b6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDcwNnwwfDF8c2VhcmNofDI3fHxEQVRBfGVufDB8fHx8MTY3OTk5OTQ1MQ&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '662.503px',
                left: '256.235px',
                top: '164.943px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 3px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    textAlign: 'left',
                    borderWidth: 1,
                    color: 'rgb(74, 134, 232)',
                    lineHeight: '1.56',
                    padding: 10
                  }}
                  data-has-line-height=""
                >
                  <ul style={{ textAlign: 'justify' }}>
                    <li>
                      <strong>
                        הפקה של דוחות בהם מצוי מידע פרטני מזוהה, דורשת הרשאה
                        מתאימה.
                      </strong>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <strong>
                        עובד לא ימסור מידע (מכל סוג) לאדם, לרבות לעובד אחר, בטרם
                        וידא את זהותו של מקבל המידע ואת הצורך שלשמו נמסר המידע.
                      </strong>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <strong>
                        מסירת המידע לאדם תתבצע באופן המבטיח את פרטיות המידע.
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-dba733"
              style={{
                width: '266.352px',
                height: 700,
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.54' }}
              >
                <div className="sl-block-content" style={{ zIndex: 11 }}>
                  <img
                    alt="cyber"
                    style={{ left: 0, top: 0, width: 700, height: 700 }}
                    data-natural-width={1920}
                    data-natural-height={1921}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y={0}
                    data-crop-width="0.380701"
                    data-crop-height={1}
                    data-src="https://images.unsplash.com/photo-1571786256017-aee7a0c009b6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDcwNnwwfDF8c2VhcmNofDI3fHxEQVRBfGVufDB8fHx8MTY3OTk5OTQ1MQ&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '484.5px',
                left: '380.25px',
                top: '34.0679px',
                height: 'auto'
              }}
              data-name="text-6dae06"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 12,
                  color: 'rgb(0, 0, 0)',
                  fontSize: '90%',
                  lineHeight: '1.17',
                  padding: 10
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '1.2em' }}>הגנה על המידע</span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '662.503px',
                left: '259.247px',
                top: '121.943px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 3px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    textAlign: 'left',
                    borderWidth: 1,
                    color: 'rgb(74, 134, 232)',
                    lineHeight: '1.56',
                    padding: 10
                  }}
                  data-has-line-height=""
                >
                  <ul style={{ textAlign: 'justify' }}>
                    <li>
                      <strong>
                        לוודא שהצד המקבל נוקט באמצעי אבטחת מידע מחמירים והמידע
                        מאובטח באופן שלזרים אין גישה אליו.
                      </strong>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <strong>
                        העברת מידע שיש בו פרטים של אדם פלוני אל מחוץ לארגון כולל
                        מידע למחקרים מחייב ביצוע פעולת התממה על פי קריטריונים
                        מתאימים.
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '484.5px',
                left: '380.25px',
                top: '34.0679px',
                height: 'auto'
              }}
              data-name="text-833a55"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  fontSize: '90%',
                  lineHeight: '1.17',
                  padding: 10
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '1.2em' }}>הגנה על המידע</span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-dba733"
              style={{
                width: '266.352px',
                height: 700,
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, opacity: '0.54' }}
              >
                <div className="sl-block-content" style={{ zIndex: 12 }}>
                  <img
                    alt="cyber"
                    style={{ left: 0, top: 0, width: 700, height: 700 }}
                    data-natural-width={1920}
                    data-natural-height={1921}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y={0}
                    data-crop-width="0.380701"
                    data-crop-height={1}
                    data-src="https://images.unsplash.com/photo-1571786256017-aee7a0c009b6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDcwNnwwfDF8c2VhcmNofDI3fHxEQVRBfGVufDB8fHx8MTY3OTk5OTQ1MQ&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-a672b5"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '487.175px 316.911px 0px',
                  zIndex: 10
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-038726"
                  style={{ width: 'auto', height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '870.091px 95.1609px 0px',
                      zIndex: 48
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      data-name="shape-bce093"
                      style={{
                        width: '148.676px',
                        height: '75.7092px',
                        left: '795.753px',
                        top: '57.3063px'
                      }}
                    >
                      <div
                        className="sl-block-style"
                        style={{ zIndex: 51, transform: 'rotate(45deg)' }}
                      >
                        <div
                          className="sl-block-content"
                          data-shape-type="triangle-up"
                          data-shape-fill-color="rgb(224, 102, 102)"
                          data-shape-stretch="true"
                          style={{ zIndex: 51 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="none"
                            viewBox="0 0 149 76"
                          >
                            <polygon
                              points="74.338,0 148.676,75.7092 0,75.7092"
                              className="shape-element"
                              fill="rgb(224, 102, 102)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 145,
                        left: '787.154px',
                        top: '91.2153px',
                        height: 'auto'
                      }}
                      data-name="text-7775b0"
                    >
                      <div
                        className="sl-block-style"
                        style={{ zIndex: 52, transform: 'rotate(45deg)' }}
                      >
                        <div
                          className="sl-block-content"
                          style={{
                            zIndex: 52,
                            textAlign: 'center',
                            fontSize: '50%',
                            color: 'rgb(255, 255, 255)'
                          }}
                        >
                          <p style={{ textAlign: 'center' }}>
                            <strong>חשוב!</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e7371e"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '457.184px 306.23px 0px',
                      zIndex: 34
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '862.475px',
                        height: 70,
                        left: '25.9469px',
                        top: '271.23px'
                      }}
                      data-name="shape-efc6d4"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(201, 218, 248)"
                        data-shape-stretch="true"
                        style={{ zIndex: 38 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 862 70"
                        >
                          <rect
                            width="862.475"
                            height={70}
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(201, 218, 248)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: '660.171px',
                        left: '167.446px',
                        top: '291.23px',
                        height: 'auto'
                      }}
                      data-name="text-f90b1e"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          zIndex: 39,
                          textAlign: 'left',
                          fontSize: '70%'
                        }}
                      >
                        <p>
                          מניעת גישה לתיק הרפואי שנמצא בדלפק האחיות על ידי נעילת
                          הארונות והמגירות.
                        </p>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 30,
                        height: 30,
                        left: '848.184px',
                        top: '291.23px'
                      }}
                      data-name="shape-e66425"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-checkmark-circle"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 40 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 32 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            data-viewbox="0 0 32 32"
                          >
                            <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-65dc6c"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '457.185px 376.23px 0px',
                      zIndex: 41
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '862.476px',
                        height: 70,
                        left: '25.9469px',
                        top: '341.23px'
                      }}
                      data-name="shape-993e11"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(109, 158, 235)"
                        data-shape-stretch="true"
                        style={{ zIndex: 45 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 862 70"
                        >
                          <rect
                            width="862.476"
                            height={70}
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(109, 158, 235)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: '783.392px',
                        left: '44.2255px',
                        top: '344.493px',
                        height: 'auto'
                      }}
                      data-name="text-9c6460"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          zIndex: 46,
                          textAlign: 'left',
                          fontSize: '70%'
                        }}
                      >
                        <p style={{ textAlign: 'right' }}>
                          בסיום השימוש במחשב ועזיבתך העמדה, יש להעביר את המחשב
                          למצב שינה למניעת גישה חופשית של אדם אחר למאגר הנתונים
                          שיש בו.
                        </p>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 30,
                        height: 30,
                        left: '848.184px',
                        top: '360.73px'
                      }}
                      data-name="shape-e1491c"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-checkmark-circle"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 47 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 32 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            data-viewbox="0 0 32 32"
                          >
                            <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-b478a4"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '457.184px 444.993px 0px',
                      zIndex: 53
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '862.475px',
                        height: 70,
                        left: '25.9469px',
                        top: '409.993px'
                      }}
                      data-name="shape-0869e2"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(201, 218, 248)"
                        data-shape-stretch="true"
                        style={{ zIndex: 57 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 862 70"
                        >
                          <rect
                            width="862.475"
                            height={70}
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(201, 218, 248)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: '667.667px',
                        left: '159.95px',
                        top: '423.493px',
                        height: 'auto'
                      }}
                      data-name="text-d15bcb"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          zIndex: 58,
                          textAlign: 'left',
                          fontSize: '70%'
                        }}
                      >
                        <p style={{ textAlign: 'right' }}>
                          אין למסור את הסיסמאות שניתנו לך על ידי הארגון לאחרים.
                        </p>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 30,
                        height: 30,
                        left: '848.184px',
                        top: '425.993px'
                      }}
                      data-name="shape-a9bfb7"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-checkmark-circle"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 59 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 32 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            data-viewbox="0 0 32 32"
                          >
                            <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-7cd5ea"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '456.164px 512.993px 0px',
                      zIndex: 60
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '862.476px',
                        height: 70,
                        left: '24.9264px',
                        top: '477.993px'
                      }}
                      data-name="shape-e52d25"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(109, 158, 235)"
                        data-shape-stretch="true"
                        style={{ zIndex: 64 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 862 70"
                        >
                          <rect
                            width="862.476"
                            height={70}
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(109, 158, 235)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: '783.392px',
                        left: '44.2255px',
                        top: '494.993px',
                        height: 'auto'
                      }}
                      data-name="text-b7173b"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          zIndex: 65,
                          textAlign: 'left',
                          fontSize: '70%'
                        }}
                      >
                        <p style={{ textAlign: 'right' }}>
                          אין להעביר מידע אודות מטופלים בצורה לא מאובטחת.
                        </p>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 30,
                        height: 30,
                        left: '848.184px',
                        top: '494.993px'
                      }}
                      data-name="shape-394c61"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-checkmark-circle"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 66 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 32 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            data-viewbox="0 0 32 32"
                          >
                            <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-257f95"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '457.184px 582.993px 0px',
                      zIndex: 67
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '862.475px',
                        height: 70,
                        left: '25.9469px',
                        top: '547.993px'
                      }}
                      data-name="shape-76b856"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(201, 218, 248)"
                        data-shape-stretch="true"
                        style={{ zIndex: 71 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 862 70"
                        >
                          <rect
                            width="862.475"
                            height={70}
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(201, 218, 248)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: '667.667px',
                        left: '159.95px',
                        top: '567.993px',
                        height: 'auto'
                      }}
                      data-name="text-bd0071"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          zIndex: 72,
                          textAlign: 'left',
                          fontSize: '70%'
                        }}
                      >
                        <p style={{ textAlign: 'right' }}>
                          יש להימנע לדבר על מטופלים במקומות ציבוריים כדוגמא:
                          המעלית או בהסעה.
                        </p>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 30,
                        height: 30,
                        left: '848.184px',
                        top: '568.993px'
                      }}
                      data-name="shape-eab8ee"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-checkmark-circle"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 73 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 32 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            data-viewbox="0 0 32 32"
                          >
                            <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 800,
                left: '93.7835px',
                top: '52.23px',
                height: 'auto'
              }}
              data-name="text-41d188"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 74,
                  color: 'rgb(0, 0, 255)',
                  fontSize: '90%',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2>חשוב מאוד!</h2>
              </div>
            </div>
          </section>
          <section
            data-background-image="https://images.unsplash.com/photo-1590172205836-40c14e6ac168?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMDcwNnwwfDF8c2VhcmNofDF8fGVzY3xlbnwwfHx8fDE2MzgxODY4Njc&ixlib=rb-1.2.1&q=80&w=1920"
            data-background-opacity="0.1"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 800, left: 80, top: '69.2505px', height: 'auto' }}
              data-name="text-b89361"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  transform: 'rotate(0deg)',
                  filter:
                    'drop-shadow(rgb(136, 136, 136) 0px 0px 6px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content monospace-font"
                  style={{
                    zIndex: 10,
                    color: 'rgb(0, 0, 255)',
                    fontSize: '140%',
                    textAlign: 'left',
                    lineHeight: '1.04'
                  }}
                  data-has-line-height=""
                >
                  <h2 style={{ textAlign: 'center' }}>סודיות רפואית</h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '767.336px',
                left: '96.332px',
                top: '224.161px'
              }}
              data-name="text-8327d9"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter:
                    'drop-shadow(rgb(217, 234, 211) 9px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{ zIndex: 11, color: 'rgb(0, 0, 0)' }}
                >
                  <ul style={{ textAlign: 'justify' }}>
                    <li>
                      סודיות רפואית היא החובה לשמור בסוד כל מידע שנמסר לרופא או
                      לעובדי בריאות אחרים על ידי הנבדק, או ביחס אליו.
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      חוק זכויות החולה בישראל מורה, כי כל מטפל רפואי או עובד
                      מוסד רפואי ישמרו בסוד כל מידע הנוגע למטופל, שהגיע אליהם
                      תוך כדי מילוי תפקידם או במהלך עבודתם, ואף מחייב נקיטת
                      צעדים כדי להבטיח שהסודיות אכן
                      <br />
                      תישמר הלכה למעשה.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 132, left: 752, top: '28.5px', height: 'auto' }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 10,
                  color: 'rgb(34, 34, 34)',
                  fontSize: '120%',
                  lineHeight: '1.17',
                  top: 4
                }}
                data-has-line-height=""
              >
                <p>שאלה</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '108.5px',
                left: 0,
                top: '15.5px',
                height: 'auto'
              }}
              data-name="text-3f789d"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 12,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 12,
                    textAlign: 'left',
                    fontSize: '44%',
                    color: 'rgb(0, 0, 255)',
                    padding: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0)'
                  }}
                >
                  <pre># שאלה</pre>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="line"
              data-name="line-49cd6b"
              style={{
                width: 'auto',
                height: 'auto',
                left: '108.5px',
                top: 32,
                marginTop: '4px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-line-x1={0}
                  data-line-y1={0}
                  data-line-x2="845.5"
                  data-line-y2={0}
                  data-line-color="#0000ff"
                  data-line-start-type="none"
                  data-line-end-type="none"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="xMidYMid"
                    width="845.5"
                    height={1}
                    viewBox="0 0 845.5 1"
                  >
                    <line
                      stroke="rgba(0,0,0,0)"
                      strokeWidth={15}
                      x1={0}
                      y1={0}
                      x2="845.5"
                      y2={0}
                    />
                    <line
                      className="line-element"
                      stroke="#0000ff"
                      strokeWidth={2}
                      x1={0}
                      y1={0}
                      x2="845.5"
                      y2={0}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <QuestionSlide
              key="second-cyber-question"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              {...getQuestionData(globalCoursewares, selectedCourseware, 17)}
            />
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 954, left: 6, top: 148, height: 'auto' }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(0, 0, 255)',
                  lineHeight: '1.17',
                  fontSize: '90%'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'center' }}>סיימת את הלומדה בהצלחה</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 160,
                left: '6.5px',
                top: '48.5px',
                height: 'auto'
              }}
              data-name="text-3f789d"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 12,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 12,
                    textAlign: 'left',
                    fontSize: '44%',
                    color: 'rgb(0, 0, 255)',
                    padding: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0)'
                  }}
                >
                  <pre># הצלחת</pre>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="line"
              data-name="line-49cd6b"
              style={{
                width: 'auto',
                height: 'auto',
                left: '166.5px',
                top: 61
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-line-x1={0}
                  data-line-y1={0}
                  data-line-x2={787}
                  data-line-y2="0.5"
                  data-line-color="#0000ff"
                  data-line-start-type="none"
                  data-line-end-type="none"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="xMidYMid"
                    width={787}
                    height={1}
                    viewBox="0 0 787 1"
                  >
                    <line
                      stroke="rgba(0,0,0,0)"
                      strokeWidth={15}
                      x1={0}
                      y1={0}
                      x2={787}
                      y2="0.5"
                    />
                    <line
                      className="line-element"
                      stroke="#0000ff"
                      strokeWidth={2}
                      x1={0}
                      y1={0}
                      x2={787}
                      y2="0.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '234.103px',
                height: '269.85px',
                left: '118.12px',
                top: '325.249px'
              }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(-45deg)' }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{
                    zIndex: 13,
                    transitionDuration: '1.5s',
                    transformOrigin: '100% 0% 0px',
                    transitionDelay: '0.5s'
                  }}
                  data-animation-type="scale-down"
                  data-animation-trigger="hover"
                  data-animation-trigger-id="self"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-63 -60 125 130"
                  >
                    <path
                      fill="rgb(193, 162, 170)"
                      d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 180, top: 294 }}
              data-name="text-f326ab"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <p>לסיום וחתימה על הלומדה לחץ למטה</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '234.103px',
                height: '269.85px',
                left: '724.12px',
                top: '153.075px'
              }}
              data-name="drawing-4514e5"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 15, transform: 'rotate(-171deg)' }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{
                    zIndex: 15,
                    transitionDelay: '0.9s',
                    transformOrigin: '0% 100% 0px',
                    transitionDuration: '0.6s'
                  }}
                  data-animation-type="scale-down"
                  data-animation-trigger="hover"
                  data-animation-trigger-id="self"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-63 -60 125 130"
                  >
                    <path
                      fill="rgb(193, 162, 170)"
                      d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 180, top: 340 }}
              data-name="button-f3216ab"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <Button
                  variant="contained"
                  onClick={() => handleSetViewToSignCourseware()}
                  // disabled={!didReachTheEnd}
                >
                  חתימה על סיום הלומדה
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CyberSecurity;
