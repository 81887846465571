import { Divider, Grid, Stack, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import committee from 'src/assets/mohcode/committee.webp';
import couple from 'src/assets/mohcode/couple.webp';
import doctor from 'src/assets/mohcode/doctor.svg';
import handShake from 'src/assets/mohcode/handShake.webp';
import home from 'src/assets/mohcode/home.webp';
import homeCartoon from 'src/assets/mohcode/homeCartoon.webp';
import id from 'src/assets/mohcode/id-card.svg';
import medical from 'src/assets/mohcode/medical.svg';
import oldLady from 'src/assets/mohcode/oldLady.webp';
import report from 'src/assets/mohcode/report.svg';
import request from 'src/assets/mohcode/request.svg';
import savings from 'src/assets/mohcode/savings.svg';
import social from 'src/assets/mohcode/social.svg';
import statements from 'src/assets/mohcode/statements.svg';
import team from 'src/assets/mohcode/team.webp';
import value from 'src/assets/mohcode/value.webp';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import DocumentsToAttach from './MohCodeSteps/DocumentsToAttach';
import LeftImgRightTextStep from './MohCodeSteps/LeftImgRightTextStep';
import RightImgLeftTextStep from './MohCodeSteps/RightImgLeftTextStep';
import StepImportantNote from './MohCodeSteps/StepImportantNote';
import './process.css';

const firstStepDocs = [
  {
    doc: 'notes-step1',
    id: uniqueId('to-attach'),
    img: request,
    description: (
      <>
        טופס פנייה ראשונית לסידור מוסדי - חתום ע&quot;י הפונה
        <Typography
          sx={{ mx: 1 }}
          href={routesConst.forms}
          component="a"
          variant="button"
        >
          להורדה.
        </Typography>
      </>
    ),
    title: 'טופס פנייה'
  },
  {
    doc: 'medical-info-step1',
    id: uniqueId('to-attach'),
    img: medical,
    description: 'חתום על ידי הרופא והאחות, מעודכן מהחודש האחרון.',
    title: 'טופס מידע רפואי - סיעודי - תפקודי'
  },
  {
    doc: 'social-step1',
    id: uniqueId('to-attach'),
    img: social,
    description: 'החתום ע"י עובד סוציאלי.',
    title: 'דו"ח סוציאלי'
  },
  {
    doc: 'id-step1',
    id: uniqueId('to-attach'),
    img: id,
    description: 'צילום תעודת זהות של המטופל, כולל ספח הכתובת.',
    title: 'צילום תעודת זהות'
  },
  {
    doc: 'medical-summary-step1',
    id: uniqueId('to-attach'),
    img: doctor,
    description:
      'מהמסגרת ממנה מופנה המטופל, לרבות סיכומי אשפוזים "מכתבי שחרור" מהשנה האחרונה.',
    title: 'מכתב סיכום רפואי'
  }
];
const secondsStepDocs = [
  {
    doc: 'notes-step2',
    id: uniqueId('to-attach'),
    img: request,
    description: (
      <>
        טופס פנייה ראשונית לסידור מוסדי - חתום ע&quot;י הפונה
        <Typography
          sx={{ mx: 1 }}
          href={routesConst.forms}
          component="a"
          variant="button"
        >
          להורדה.
        </Typography>
      </>
    ),
    title: 'שאלון הצהרה'
  },
  {
    doc: 'medical-info-step2',
    id: uniqueId('to-attach'),
    img: savings,
    description:
      'ריכוז מעודכן של כל חשבונות החסכונות והפיקדונות - כולל ניירות ערך.',
    title: 'ריכוז מעודכן של החסכונות'
  },
  {
    doc: 'statements-step2',
    id: uniqueId('to-attach'),
    img: report,
    description:
      'תדפיס מפורט של שלושה חודשים עבור כל חשבונות עובר ושב - עו"ש של המטופל בן/בת הזוג.',
    title: 'תדפיס עו"ש'
  },
  {
    doc: 'id-step2',
    id: uniqueId('to-attach'),
    img: id,
    description: 'של המטופלים ובני זוגם, כולל הספח.',
    title: 'צילום תעודת זהות'
  },
  {
    doc: 'medical-summary-step2',
    id: uniqueId('to-attach'),
    img: statements,
    description:
      'מסמכים המעידים על הכנסות שוטפות, הוצאות ונכסי מקרקעין של המטופל ובן/בת הזוג כמפורט בטופס ההצהרה.',
    title: 'הכנסות שוטפות'
  }
];

const secondStepDocsByPatient = [
  {
    doc: 'notes-step2',
    id: uniqueId('to-attach'),
    img: request,
    description: (
      <>
        לילדיהם הבגירים של המטופלים חתום ומלא
        <Typography
          sx={{ mx: 1 }}
          href={routesConst.forms}
          component="a"
          variant="button"
        >
          להורדה.
        </Typography>
      </>
    ),
    title: 'שאלון הצהרה'
  },
  {
    doc: 'medical-info-step2',
    id: uniqueId('to-attach'),
    img: report,
    description:
      "מסמכים המעידים על הכנסות שוטפות של ילדיהם הבגירים של המטופל (תלושי משכורת, שומת מס הכנסה וכד') ומסמכים המעידים על הוצאות כמפורט בטופס ההצהרה",
    title: 'הכנסות שוטפות של הילדים'
  },
  {
    doc: 'id-step2',
    id: uniqueId('to-attach'),
    img: id,
    description: 'כולל הספח.',
    title: 'צילומי תעודת זהות'
  }
];

const MohCodeProcess = () => (
  <Grid
    component="section"
    item
    container
    spacing={2}
    rowSpacing={6}
    sx={{ display: 'flex', justifyContent: 'center', m: 4, mt: 2 }}
    xs={12}
  >
    <Grid key="first-step-title" xs={12} item>
      <Stack sx={{ color: theme.palette.success.main }}>
        <Typography variant="h2">שלב 1</Typography>
        <Typography variant="body1">
          הגשת בקשה לבחינת סיווג המטופל והתאמתו לאשפוז סיעודי
        </Typography>
      </Stack>
      <Divider />
    </Grid>
    <RightImgLeftTextStep
      img={home}
      chipImg={homeCartoon}
      text="לצורך בחינת מצב רפואי - תפקודי של המטופל כמתאים לקבלת השירות, יתבצע ביקור אחות ולעיתים גם עובדת סוציאלית, במקום שהותו של המטופל. מועד הביקור יתואם מול המטפל העיקרי / בן משפחה."
      title="ביקור בית"
      titleColor="#244c8b"
    />
    <LeftImgRightTextStep
      img={oldLady}
      //   chipImg={board}
      chipImg={committee}
      text='וועדה גריאטרית תדון במצבו של המטופל ותקבע את סיווגו כ"סיעודי" או כ"תשוש נפש". בהתאם תיקבע המסגרת המתאימה למטופל. לחלופין, הוועדה יכולה לקבוע כי המטופל אינו מוגדר כ"סיעודי" או "תשוש נפש" כיוון שמצבו הרפואי או התפקודי מצריך פתרון שונה.'
      title="וועדת סיווג גריאטרית"
      titleColor="#f53939"
    />
    <Grid item xs={12}>
      <DocumentsToAttach key="first-step-docs" docs={firstStepDocs} />
    </Grid>
    <Grid item xs={12} sx={{ position: 'relative', pb: 3 }}>
      <StepImportantNote
        notesArr={[
          {
            description:
              'את המסמכים והטפסים המלאים יש להגיש למחלקה למחלות ממושכות בלשכת הבריאות במקום מגוריו של המטופל, על פי כתובתו בתעודת הזהות. ניתן להגיש בדואר אלקטרוני, פקס או הגשה פיזית בלשכות הבריאות.'
          },
          {
            description:
              'מי שהוגדר כ"סיעודי" או "תשוש נפש" ימשיך לשלב ב - חישוב גובה ההשתתפות הכספית.'
          }
        ]}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          overflow: 'hidden',
          lineHeight: '0',
          zIndex: 0
        }}
        className="custom-shape-divider-bottom-1702740912"
      >
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          style={{
            position: 'relative',
            display: 'block',
            width: 'calc(300% + 1.3px)',
            height: '153px'
          }}
          preserveAspectRatio="none"
        >
          <path
            d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
            className="shape-fill"
            fill="#74B1B742"
          />
        </svg>
      </div>
    </Grid>
    <Grid key="second-step-title" xs={12} item>
      <Stack sx={{ color: theme.palette.success.main }}>
        <Typography variant="h2">שלב 2</Typography>
        <Typography variant="body1">
          הגשת בקשה לחישוב סכום ההשתתפות הכספית של המטופל ובני משפחתו
        </Typography>
      </Stack>
      <Divider />
    </Grid>
    <RightImgLeftTextStep
      img={value}
      chipImg={handShake}
      text="גובה ההשתתפות של המטופל ובני משפחתו יחושב לאחר הגשת כל המסמכים הנדרשים, עד 13 ימי עבודה. • לאחר מכן, כל אחד מהמחויבים בתשלום יוזמן לחתום על כתב התחייבות כספית לתשלום חודשי. יש להצטייד בתעודת זהות במעמד החתימה. • תשלום ההשתתפות העצמית יוסדר באמצעות הוראת קבע. החשבון ישולם עד לתאריך 24 לחודש עבור החודש הקודם."
      title="גובה ההשתתפות"
      titleColor="#244c8b"
    />
    <LeftImgRightTextStep
      img={team}
      chipImg={couple}
      text="באפשרות המשפחה לבחור את המוסד המועדף עליה מבין המוסדות המאושרים על ידי משרד הבריאות. ניתן להיעזר במידע על המוסדות הנמצא באתר משרד הבריאות. ניתן לערער על החלטות שהתקבלו (סיווג תפקודי וגובה ההשתתפות) בפנייה ללשכת הבריאות."
      title="בחירת המוסד המועדף"
      titleColor="#f53939"
    />
    <Grid item xs={12}>
      <DocumentsToAttach
        listTitle='להגשה ע"י מטופל ובן/בת זוג'
        key="second-step-docs"
        docs={secondsStepDocs}
      />
    </Grid>
    <Grid item xs={12}>
      <DocumentsToAttach
        listTitle='להגשה ע"י ילדי המטופל'
        key="second-step-docs-by-patient"
        docs={secondStepDocsByPatient}
      />
    </Grid>
    <Grid item xs={12} sx={{ position: 'relative', pb: 3 }}>
      <StepImportantNote
        notesArr={[
          {
            description:
              'לידיעתכם, המוסד מחויב לספק את כל צורכי המטופל. נאסר על המוסד לדרוש ולגבות כספים כלשהם מהמטופלים המאושפזים במימון משרד הבריאות "קוד" או מבני משפחתם למעט שירותים שאנים שנמצאים בסל האשפוז.'
          },
          {
            description:
              'תקציר אופן החישוב נמצא באתר האינטרנט של משרד הבריאות בדף "אשפוז סיעודי ממושך", הפירוט המלא נמצא בחוזר מנכ"ל משרד הבריאות (מספר 8/2018).'
          }
        ]}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          overflow: 'hidden',
          lineHeight: '0',
          zIndex: 0,
          direction: 'ltr'
        }}
        className="custom-shape-divider-bottom-1702740912"
      >
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          style={{
            position: 'relative',
            display: 'block',
            width: 'calc(300% + 1.3px)',
            height: '153px'
          }}
          preserveAspectRatio="none"
        >
          <path
            d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
            className="shape-fill"
            fill="#fbcf3391"
          />
        </svg>
      </div>
    </Grid>
  </Grid>
);

export default MohCodeProcess;
