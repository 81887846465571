import { Avatar, Card, Grid, Typography } from '@mui/material';
import { isArray } from 'lodash';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cctvOptions from 'src/common/cctvOptions';

const OrgDetailsTableInOrgPage = ({ orgPageValuesToRender }) => {
  OrgDetailsTableInOrgPage.propTypes = {
    orgPageValuesToRender: PropTypes.array
  };
  const { t } = useTranslation('translation', { keyPrefix: 'orgPage' });
  const handleArrayOption = (name, values) => {
    if (name === 'cctv' && isArray(values)) {
      const labels = [];
      values.forEach((cctvOption) => {
        const index = cctvOptions.findIndex((el) => el.name === cctvOption);
        if (index > -1) {
          labels.push(cctvOptions[index].label);
        }
      });
      return labels;
    }
    return [t('orgDidntTellUs')];
  };

  return (
    <>
      {orgPageValuesToRender.map((el) => (
        <Grid key={el.label} item xs={12} sm={5.95}>
          <Card
            elevation={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '230px',
              border: 'unset',
              boxShadow: '2px 2px 20px rgba(2,2,2,0.1)',
              py: 2,
              '&:hover': {
                boxShadow: (theme) => theme.shadows[6]
              }
            }}
          >
            <Avatar
              sx={{
                border: el.border,
                bgcolor: (theme) => theme.palette.background.paper,
                p: 6,
                '&:hover': {
                  boxShadow: (theme) => theme.shadows[16]
                }
              }}
              variant="circular"
            >
              <Lottie
                style={{
                  width: '70% !important',
                  height: '120px',
                  minWidth: '70px'
                }}
                width="120px"
                height="120px"
                autoplay
                animationData={el.icon}
              />
            </Avatar>
            <Typography sx={{ pt: 2, pb: 1 }} variant="h6">
              {el.label}
            </Typography>
            {el.isArray ? (
              <Typography sx={{ fontWeight: 800 }} variant="h6">
                {handleArrayOption(el.name, el.value)
                  .map((option) => option)
                  .join(',')}
              </Typography>
            ) : (
              <Typography sx={{ fontWeight: 800 }} variant="h6">
                {el.description}
              </Typography>
            )}
          </Card>
        </Grid>
      ))}
    </>
  );
};
export default OrgDetailsTableInOrgPage;
