import { common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    transparent: {
      primary: 'rgba(0,0,0,0)'
    }
  },
  shadows,
  typography: {
    ...typography
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          '& > legend': {
            fontSize: '1.01em'
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          ':hover': {
            outline: '1px solid blue'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ':hover': {
            outline: '1px solid blue'
          }
        }
      }
    }
  }
});

export default theme;
