import { Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { darken, lighten } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import './styles.css';

const InternalOrgCommunityToolbar = ({
  handleOpenAddProduct,
  savedProducts,
  openSavedProductsList,
  handleOpenPurchasesDialog
}) => {
  InternalOrgCommunityToolbar.propTypes = {
    openSavedProductsList: PropTypes.func,
    handleOpenAddProduct: PropTypes.func,
    savedProducts: PropTypes.array,
    handleOpenPurchasesDialog: PropTypes.func
  };
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const getOrgName = () => {
    if (currentUser) {
      const { type } = currentUser;
      if (type === 'personal') {
        const index = currentUser.employee_work_places.findIndex(
          (el) => el.org_ref === selectedOrg
        );
        if (index > -1) {
          return currentUser.employee_work_places[index].org_name;
        }
        return '';
      }
      if (type === 'org') {
        return currentUser.org_name;
      }
    }
  };

  return (
    <Toolbar
      // component={Grid}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        px: '0px !important'
      }}
    >
      <Card
        sx={{
          backgroundColor: darken(theme.palette.secondary.light, 0.05),
          width: '100%',
          minHeight: 180,
          position: 'relative'
        }}
      >
        <CardContent sx={{ position: 'relative', zIndex: 5 }}>
          <Typography
            variant="h2"
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {`קהילת הארגון ${getOrgName() || ''}`}
          </Typography>
        </CardContent>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 0,
            bottom: 'calc(-25% - 32.5px)',
            right: '50%',
            borderRadius: '50%',
            backgroundColor: lighten(theme.palette.primary.light, 0.23),
            width: 150,
            height: 150
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: 0,
            bottom: 'calc(-25% - 75px)',
            right: 'calc(50% + 162.5px)',
            borderRadius: '60%',
            backgroundColor: lighten(theme.palette.primary.light, 0.23),
            width: 280,
            height: 280
          }}
        />
        <Box
          className="pattern-dashes"
          sx={{
            width: 80,
            height: 140,
            position: 'absolute',
            right: '-15px',
            top: '25%',
            zIndex: 1
          }}
        />

        <Box
          sx={{
            width: 160,
            height: 80,
            position: 'absolute',
            right: '50px',
            top: '40%',
            zIndex: 1,
            border: '3px solid black'
          }}
        >
          <Box
            sx={{
              position: 'reltative',
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              height: '100%',
              '&:after': {
                position: 'absolute',
                width: 30,
                height: 30,
                content: '""',
                top: 0,
                right: 0,
                backgroundColor: theme.palette.warning.main,
                clipPath: 'polygon(0.00% -5px, 90% -3.33%, 0px 100%)'
              },
              '&:before': {
                position: 'absolute',
                width: 30,
                height: 30,
                content: '""',
                top: 0,
                left: 0,
                backgroundColor: theme.palette.warning.main,
                clipPath: 'polygon(10% 0px, 113.33% 0%, 31px 90%)'
              }
            }}
          />
        </Box>
        <Box
          sx={{
            width: 80,
            height: 120,
            position: 'absolute',
            right: '25%',
            bottom: '-25px',
            zIndex: 1,
            border: '3px solid black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
            '&::after': {
              fontSize: '300px',
              height: 'fit-content',
              position: 'absolute',
              width: 'fit-content',
              bottom: '-65%',
              right: '-22%',
              content: '"*"',
              color: lighten(theme.palette.secondary.light, 0.1),
              lineHeight: '120px'
            }
          }}
        />
      </Card>
      {/* </Grid> */}
    </Toolbar>
  );
};
export default InternalOrgCommunityToolbar;
