import { addDoc, collection, doc, serverTimestamp, setDoc } from '@firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'src/_firebase/firebase';
import defaultProcesses from 'src/common/defaultOnBoardings';
import onBoardingProcessInit from 'src/components/Account/org/onBoardingProcessInit';
import jobsTitles from '../common/jobsTitles';

const handleAddDefaultOnBoardingProcessesPerJobForNewOrg = async (org_ref) => {
  // const orgDoc = doc(db, `orgs/${org_ref}`);
  // {type: "jobProcess", jobs: [{name: jobtitle}], process_name: "title"}
  const employeeProcessRef = collection(
    db,
    `/orgs/${org_ref}/onBoardingProcesses`
  );
  Object.keys(defaultProcesses).forEach((eachProcess) => {
    const jobLabelIndex = jobsTitles.findIndex((el) => el.name === eachProcess);
    let jobLabelToSave = eachProcess;
    if (jobLabelIndex > -1) {
      jobLabelToSave = jobsTitles[jobLabelIndex].label;
    }
    addDoc(employeeProcessRef, {
      type: 'jobProcess',
      jobs: [{ name: eachProcess }],
      process_name: `ברירת מחדל - ${jobLabelToSave || eachProcess}`,
      process: defaultProcesses[eachProcess]
        ? defaultProcesses[eachProcess]
        : onBoardingProcessInit
    }).catch((err) => {
      console.log(err);
    });
  });
};

const handleCreateNewOrg = async (values, event) => {
  event.preventDefault();
  const userDocRef = doc(db, 'users', values.user_doc_id);
  const orgsCollectionRef = doc(db, 'orgs', values.user_doc_id);
  const sendNewOrgJoinEmail = httpsCallable(functions, 'sendNewOrgJoinEmail');
  handleAddDefaultOnBoardingProcessesPerJobForNewOrg(values.user_doc_id);
  const { id, org_name, phone, email } = values;
  sendNewOrgJoinEmail({ org_id: id, org_name, phone, email });
  await setDoc(userDocRef, {
    ...values,
    type: 'org',
    created_at: serverTimestamp(),
    org_status: 'pending'
  })
    .then(async () => {
      await setDoc(
        orgsCollectionRef,
        { org_ref: values.user_doc_id },
        { merge: true }
      )
        .then((user) => {})
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default handleCreateNewOrg;
