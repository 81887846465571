import {
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListSubheader
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

const DocumentsToAttach = ({ docs, listTitle }) => {
  DocumentsToAttach.propTypes = {
    docs: PropTypes.array,
    listTitle: PropTypes.string
  };
  const [mouseOverDoc, setMouseOverDoc] = useState();

  const handleMouseLeave = () => {
    setMouseOverDoc();
  };
  const handleMouseEnter = (doc) => {
    setMouseOverDoc(doc);
  };
  return (
    <List sx={{ width: '100%', maxWidth: 900 }}>
      <ListSubheader sx={{ fontWeight: 'bold' }}>{listTitle}</ListSubheader>
      {docs.map((doc) => (
        <ListItemButton
          sx={{ cursor: 'default' }}
          onMouseLeave={() => handleMouseLeave()}
          onMouseEnter={() => handleMouseEnter(doc.doc)}
          key={doc.id}
        >
          <ListItemAvatar
            sx={{ transform: mouseOverDoc === doc.doc && 'scale(1.2)' }}
          >
            <img src={doc.img} alt="docs" />
          </ListItemAvatar>
          <ListItemText primary={doc.title} secondary={doc.description} />
        </ListItemButton>
      ))}
    </List>
  );
};
DocumentsToAttach.defaultProps = {
  listTitle: 'מסמכים שיש לצרף'
};
export default DocumentsToAttach;
