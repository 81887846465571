import {
  Box,
  Card,
  Grid,
  Hidden,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import theme from 'src/theme';
import './vision.css';

const OurVisionHeroSection = () => {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid
      item
      xs={12}
      sx={{
        minHeight: '100vh',
        // backgroundColor: theme.palette.primary.dark,
        backgroundImage: `linear-gradient(135deg, ${alpha(
          theme.palette.primary.dark,
          0.35
        )} 20%, ${alpha(theme.palette.primary.light, 0.23)} 100%)`,
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'center'
      }}
      container
      spacing={0}
    >
      {/* <Box sx={{ height: '100px', width: '100%', position: 'relative' }} /> */}
      <Grid
        sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
        item
        xs={12}
        md={7}
      >
        <Box
          sx={{
            py: 2,
            px: 4
          }}
        >
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100% - 80px)',
              mt: !matchMdDown ? -5 : 5
            }}
            gap={1}
          >
            <Box
              sx={{
                width: '100%',
                dispaly: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Card
                sx={{
                  //   background: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
                  borderRadius: 4,
                  maxWidth: 180,
                  height: 60,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  //   border: '2px solid #244c8b'
                  border: '2px solid tomato'
                }}
              >
                <img
                  alt="icon"
                  //   src=""
                  src="https://img.freepik.com/free-photo/anime-style-clouds_23-2151071681.jpg?t=st=1702753224~exp=1702756824~hmac=07574519739bb1a9605a294b2ed742b4dba71cd76c86fa4350d34f400afb1721&w=1060"
                  style={{
                    width: '100%',
                    height: 'calc(100% -24px)',
                    objectFit: 'cover',
                    objectPosition: 'top'
                  }}
                />
              </Card>
            </Box>
            <Typography
              variant="h1"
              sx={{
                fontSize: '3rem',
                color: theme.palette.primary.dark,
                textAlign: 'start',
                width: '100%',
                mt: 2
              }}
            >
              החזון של מגדלור
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.primary.dark,
                // width: '60vw',
                maxWidth: '650px',
                textAlign: 'justify',
                fontSize: '1.4rem'
              }}
            >
              כי אכפת לנו מהדור המבוגר ורוצים להחזיר על מה שעשה למעננו לאורך
              השנים הרבות, הקמנו את האתר הזה, דרכו נוכל להכשיר צוותים ולמקצע
              אותם על מנת להעלות את איכות הטיפול המוענק לגיל השלישי.
            </Typography>
          </Stack>
        </Box>
      </Grid>
      <Hidden mdDown>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: matchMdDown ? 'start' : 'center'
          }}
          item
          xs={12}
          md={5}
        >
          <img
            style={{
              width: '100%',
              maxWidth: '100%',
              // aspectRatio: '1 / 1'
              height: '100%'
            }}
            className="masked animated"
            src="https://static.vecteezy.com/system/resources/previews/007/395/184/non_2x/blue-sea-scenery-free-vector.jpg"
            alt=""
          />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: matchMdDown ? 'start' : 'center'
          }}
          item
          xs={12}
          md={4}
        >
          <img
            style={{
              width: '100%',
              maxWidth: matchMdDown ? '300px' : '600px',
              aspectRatio: '1 / 1'
            }}
            className="masked"
            src="https://static.vecteezy.com/system/resources/previews/007/395/184/non_2x/blue-sea-scenery-free-vector.jpg"
            alt=""
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default OurVisionHeroSection;
