import { Card, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

const OrgCommunityPostsTabs = ({ tabValue, handleChangeTabValue }) => {
  OrgCommunityPostsTabs.propTypes = {
    tabValue: PropTypes.any,
    handleChangeTabValue: PropTypes.func
  };

  return (
    <Tabs
      value={tabValue}
      onChange={(e, view) => {
        // console.log(view, ',', e.target.value);
        handleChangeTabValue(view);
      }}
      aria-label="basic tabs OrgCommunityPostsTabs"
      textColor="primary"
      indicatorColor="primary"
      component={Card}
      elevation={3}
    >
      <Tab value="regular" label="פרסומים כלליים" />
      <Tab value="signature_required" label="פרסומים לחתימה" />
    </Tabs>
  );
};
export default OrgCommunityPostsTabs;
