const calcNumberOfBedsInOrg = (numOfBeds) => {
  if (numOfBeds) {
    const keys = Object.keys(numOfBeds);
    // console.log(numOfBeds);
    if (keys.length > 0) {
      let sum = 0;
      keys.forEach((key) => {
        if (key !== 'last_update') {
          sum += Number(numOfBeds[key].amount || 0);
        }
      });
      return sum;
    }
    return 0;
  }
  return 0;
};

export default calcNumberOfBedsInOrg;
