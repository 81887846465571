import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MohCodeProcess from './MohCodeProcess';
import './process.css';

const CodeRecievingProcess = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mohCode'
  });

  return (
    <Grid container spacing={4}>
      <MohCodeProcess />
    </Grid>
  );
};

export default CodeRecievingProcess;
