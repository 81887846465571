import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();

const keywords = [
  { title: 'הערכה גריאטרית' },
  { title: 'הערכה סוציאלית' },
  { title: 'ייעוץ רפואי' },
  { title: 'השגחה פרטית' },
  { title: 'ביקורי בית' },
  { title: 'הערכה תפקודית' },
  { title: 'הערכה קוגניטיבית' },
  { title: 'בדיקת סיווג והתאמה למחלקה' }
];

const ServicesOfServiceProviderAutocomplete = ({ values, setValues }) => {
  ServicesOfServiceProviderAutocomplete.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func
  };
  return (
    <Autocomplete
      multiple
      value={values.serviceFields || []}
      options={keywords}
      id="checkboxes-ServicesOfServiceProviderAutocomplete"
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      freeSolo
      onChange={(event, newValue, r, details) => {
        console.log(newValue);
        if (typeof newValue === 'string') {
          setValues({ ...values, serviceFields: [newValue] });
        } else if (newValue) {
          if (
            newValue.length > 0 &&
            newValue[newValue.length - 1] &&
            newValue[newValue.length - 1].inputValue
          ) {
            const splitted = newValue.splice(0, newValue.length - 1);
            const last = {
              title: newValue[newValue.length - 1].inputValue
            };
            setValues({
              ...values,
              serviceFields: [...splitted, last]
            });
          } else {
            setValues({
              ...values,
              serviceFields: values.serviceFields ? newValue : [...newValue]
            });
          }
        }
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: inputValue
          });
        }

        return filtered;
      }}
      fullWidth
      limitTags={4}
      renderInput={(params) => (
        <TextField
          {...params}
          label="תחומי פעילות"
          placeholder="בחר את תחומי הפעילות"
        />
      )}
    />
  );
};
export default ServicesOfServiceProviderAutocomplete;
