import { collection, getDocs, orderBy, query } from '@firebase/firestore';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { db } from 'src/_firebase/firebase';
import blogBg from 'src/assets/blog/blog-bg.webp';
import ArticlePage from 'src/components/Blog/ArticlePage';
import ArticlePreviewCard from 'src/components/Blog/ArticlePreviewCard';
import theme from 'src/theme';

const Blog = () => {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [articles, setArticles] = useState();
  const [selectedArticle, setSelectedArticle] = useState();

  const handleRetrieveBlogs = () => {
    const blogsRef = collection(db, 'articles');
    const q = query(blogsRef, orderBy('order', 'asc'));
    getDocs(q)
      .then((blogsDocs) => {
        if (blogsDocs.empty) {
          setArticles([]);
        } else {
          const data = blogsDocs.docs.map((articleDoc) => ({
            ...articleDoc.data(),
            article_id: articleDoc.id
          }));
          setArticles(data);
        }
      })
      .catch((err) => {
        console.log('Error getting documents: ', err);
        setArticles([]);
      });
  };
  useEffect(() => {
    if (!articles) {
      handleRetrieveBlogs();
    }
  }, [articles]);

  const handleOpenSelectedArticle = (selectedArticleToPreview) => {
    setSelectedArticle(selectedArticleToPreview);
  };
  return (
    <>
      <Helmet>
        <title>מאמרים וכתבות</title>
      </Helmet>
      <Paper>
        <Grid
          sx={{ backgroundColor: theme.palette.background.default }}
          container
          spacing={2}
        >
          <Grid
            sx={{
              backgroundImage: `linear-gradient(to top, rgba(71, 170, 255, 0.81) 25%, rgba(116, 178, 255, ${
                matchMdDown ? 0.85 : 0.48
              }) 70%),
    url(${blogBg})`,
              pt: '100px',
              pl: !matchMdDown ? '40px !important' : '20px !important',
              pr: matchMdDown && '20px !important',
              pb: '40px !important',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
              // px: matchMdDown && '20px !important'
            }}
            item
            container
            spacing={2}
            xs={12}
          >
            <Grid
              item
              xs={12}
              sx={{
                pt: '125px !important',
                textAlign: matchMdDown && 'center'
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  fontSize: matchMdDown ? '24px' : '6vw',
                  fontWeight: 600
                }}
                variant="h1"
              >
                מאמרים וכתבות
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontSize: '6vw',
                  fontWeight: 600
                }}
                variant="h1"
              >
                של מגדלור
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontSize: '20px',
                  maxWidth: 600,
                  textAlign: 'justify',
                  px: 2
                }}
                variant="body1"
              >
                בחירת בית אבות היא החלטה משמעותית וחשובה לנו ולאהובים שלנו
                שיגורו בבית אבות שנראה המתאים ביותר, לכן החלטה זו מגיעה לאחר
                חשיבה רבה והשוואות מרובות בין הרמות השונות, האיכות, הניקיון,
                השירותים המסופקים ועוד. במאמרים שלנו, נסקור את הקריטריונים
                החשובים בבחירה של בית אבות, נפרט כיצד להבין את הצרכים האישיים
                ונתאר כיצד יכולים להשפיע על החיים היומיומיים בבית האבות.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} sx={{ mx: 2, py: 4 }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textAlign: 'center',
                  color: theme.palette.secondary.main
                }}
                variant="h1"
              >
                המאמרים שלנו
              </Typography>
            </Grid>
            {articles && articles.length > 0 ? (
              articles.map((article, i) => (
                <Grid
                  key={`article-grid-${article.article_id}`}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  xl={3}
                >
                  <ArticlePreviewCard
                    handleOpenSelectedArticle={handleOpenSelectedArticle}
                    i={i}
                    article={article}
                  />
                </Grid>
              ))
            ) : (
              <LinearProgress />
            )}
          </Grid>
        </Grid>
      </Paper>
      {Boolean(selectedArticle) && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(selectedArticle)}
          onClose={() => setSelectedArticle()}
        >
          <DialogTitle>{selectedArticle.title}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: '0px !important' }}>
            <ArticlePage article={selectedArticle} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Blog;
