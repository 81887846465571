import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';
import './magnifier.css'; // CSS for styling

// Create a context to manage the magnification state and functions
const MagnificationContext = createContext();

export const MagnificationProvider = ({ children }) => {
  MagnificationProvider.propTypes = {
    children: PropTypes.any
  };
  const [isMagnified, setMagnified] = useState(true);
  const [magnifiedPosition, setMagnifiedPosition] = useState({ x: 0, y: 0 });

  // Function to toggle magnification
  const toggleMagnification = () => {
    setMagnified((prevState) => !prevState);
  };

  // Function to update magnified position
  const updateMagnifiedPosition = (x, y) => {
    setMagnifiedPosition({ x, y });
  };
  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isMagnified,
      magnifiedPosition,
      toggleMagnification,
      updateMagnifiedPosition
    }),
    [isMagnified, magnifiedPosition]
  );

  return (
    <MagnificationContext.Provider value={contextValue}>
      {children}
    </MagnificationContext.Provider>
  );
};

// Custom hook to access the memoized magnification context in components
export function useMagnification() {
  return useContext(MagnificationContext);
}
