import { Skeleton } from '@mui/material';
import { workerClass } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropType from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, {
  FullscreenControl,
  Marker,
  NavigationControl,
  ScaleControl,
  useMap
} from 'react-map-gl';
import workerLoader from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import '../map.css';
import OrgPin from './OrgPin';

workerClass = workerLoader;

const MinimalOrgPageMap = ({
  selectedOrg,
  markerDraggable,
  handlePinDragEnd
}) => {
  MinimalOrgPageMap.propTypes = {
    selectedOrg: PropType.any,
    markerDraggable: PropType.bool,
    handlePinDragEnd: PropType.func
  };

  const mapRef = useRef(null);
  const { minimalMap, current, ...rest } = useMap({ id: 'minimalMap' });
  const [popupInfo, setPopupInfo] = useState(null);
  const [lng, setLng] = useState();
  const [lat, setLat] = useState();
  const [zoom, setZoom] = useState(12);
  const [mapCenter, setMapCenter] = useState([
    selectedOrg.lng,
    selectedOrg.lat
  ]);
  const { t } = useTranslation('translation', { keyPrefix: 'map' });
  const [bouncyPinLocation, setBouncyPinLocation] = useState();

  const onSelectCity = useCallback(
    (longitude, latitude) => {
      setBouncyPinLocation({ lng: longitude, lat: latitude });
      if (minimalMap) {
        minimalMap.flyTo({ center: [longitude, latitude], duration: 2000 });
      }
    },
    [lng, lat]
  );

  useEffect(() => {
    if (selectedOrg) {
      setLng(selectedOrg.lng);
      setLat(selectedOrg.lat);
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (lng && lat && rest) {
      onSelectCity(lng, lat);
      setPopupInfo(selectedOrg);
    }
  }, [lng, lat]);
  const onMarkerDragEnd = useCallback((event) => {
    const { lngLat } = event;
    setBouncyPinLocation({ lng: lngLat.lng, lat: lngLat.lat });
    handlePinDragEnd(lngLat);
  }, []);

  return (
    <>
      {bouncyPinLocation && bouncyPinLocation.lng && bouncyPinLocation.lat ? (
        <Map
          id="minimalMap"
          ref={mapRef}
          initialViewState={{
            longitude: lng || 35.11,
            latitude: lat || 32.71,
            zoom
          }}
          scrollZoom="true"
          // maxZoom={15}
          center={mapCenter}
          onZoomStart={(e) => {
            setMapCenter({
              lng: e.viewState.longitude,
              lat: e.viewState.latitude
            });
            setZoom(e.viewState.zoom.toFixed(2));
          }}
          maxBounds={[
            [34.0654333839, 29.5013261988],
            [36.056, 33.436]
          ]}
          style={{
            width: '100%',
            height: '100%',
            minHeight: '60vh'
          }}
          onClick={(e) => {
            // console.log(e.lngLat);
            if (markerDraggable) {
              setBouncyPinLocation({ lng: e.lngLat.lng, lat: e.lngLat.lat });
            }
          }}
          onDblClick={(e) => onMarkerDragEnd(e)}
          mapboxAccessToken={process.env.REACT_APP_MAPBOXGL_ACCESSTOKEN}
          mapStyle="mapbox://styles/moradabed/cldexw2ei001d01o92a1ijqd5"
        >
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />
          <Marker
            draggable={Boolean(markerDraggable)}
            onDragEnd={(e) => onMarkerDragEnd(e)}
            key="marker-new-org-location"
            longitude={bouncyPinLocation.lng}
            latitude={bouncyPinLocation.lat}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
            }}
          >
            <OrgPin size={30} />
          </Marker>
          {/* {popupInfo && (
          <Popup
            anchor="bottom"
            longitude={Number(popupInfo.lng)}
            latitude={Number(popupInfo.lat)}
            onClose={() => setPopupInfo(null)}
            closeOnClick="true"
          >
            <div>
              <a target="_new">Wikipedia</a>
            </div>
          </Popup>
        )} */}
        </Map>
      ) : (
        <Skeleton variant="rectangular" height={350} />
      )}
    </>
  );
};

export default MinimalOrgPageMap;
