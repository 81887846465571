const handleGTag = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(...args) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(args);
  }
  gtag('js', new Date());

  gtag('config', process.env.REACT_APP_GTAG);
  // console.log(1);
};

export default handleGTag;
