import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material';
import PropTypes from 'prop-types';
import jobsTitles from 'src/common/jobsTitles';
import theme from 'src/theme';
import checkLabelOfJobTitle from 'src/utils/checkLabelOfJobTitle';

const NewPostLabels = ({ newPostValues, setNewPostValues }) => {
  NewPostLabels.propTypes = {
    newPostValues: PropTypes.object,
    setNewPostValues: PropTypes.func
  };

  const handleChangeLabelsOfPractice = (event) => {
    const {
      target: { value }
    } = event;
    console.log(value);
    setNewPostValues({
      ...newPostValues,
      labels: typeof value === 'string' ? value.split(',') : value
    });
  };
  const handleSelectAll = (checked) => {
    let newSelectedLabels;
    if (!checked) {
      newSelectedLabels = jobsTitles.map((job) => job.name);
    } else {
      newSelectedLabels = [];
    }
    setNewPostValues({ ...newPostValues, labels: newSelectedLabels });
  };
  return (
    <FormControl sx={{ mt: 2, border: 'none' }} fullWidth>
      <InputLabel id="practice-label-multiple-chip-label">
        לאיזה קבוצה הפוסט מתאים?
      </InputLabel>
      <Select
        labelId="practice-label-multiple-chip-label"
        id="practice-label-multiple-chip"
        multiple
        value={
          newPostValues && newPostValues.labels ? newPostValues.labels : []
        }
        onChange={(e, child) => {
          if (child.props.value === 'all') {
            handleSelectAll(
              newPostValues.labels &&
                newPostValues.labels.length === jobsTitles.length
            );
          } else {
            handleChangeLabelsOfPractice(e, child);
          }
        }}
        fullWidth
        sx={{ maxWidth: 450 }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          anchorPosition: {
            top: 10,
            left: 0
          },
          PaperProps: {
            sx: {
              maxHeight: '450px'
            }
          }
        }}
        input={
          <OutlinedInput
            id="select-multiple-practice-label-chip"
            label="לאיזה קבוצה הפוסט מתאים?"
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.default,
              maxWidth: theme.breakpoints.values.md,
              borderRadius: 1,
              border: 'none'
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                variant="outlined"
                color="info"
                key={value}
                label={checkLabelOfJobTitle(value)}
              />
            ))}
          </Box>
        )}
      >
        <MenuItem key="menu-item-all" value="all">
          <Checkbox
            indeterminate={
              newPostValues &&
              newPostValues.labels &&
              newPostValues.labels.length > 0 &&
              newPostValues.labels.length < jobsTitles.length
            }
            checked={
              newPostValues &&
              newPostValues.labels &&
              newPostValues.labels.length === jobsTitles.length
            }
            value={Boolean(
              newPostValues &&
                newPostValues.labels &&
                newPostValues.labels.length === jobsTitles.length
            )}
          />
          <ListItemText primary="סמן הכל" />
        </MenuItem>
        {jobsTitles.map((name) => (
          <MenuItem key={`menu-item-${name.name}`} value={name.name}>
            <Checkbox
              checked={
                newPostValues && newPostValues.labels
                  ? newPostValues.labels.findIndex((el) => el === name.name) >
                    -1
                  : false
              }
            />
            <ListItemText primary={name.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NewPostLabels;
