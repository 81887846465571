import {
  ContactMailOutlined,
  DashboardOutlined,
  DiamondOutlined,
  ForumOutlined,
  GavelOutlined,
  Groups2Outlined,
  PersonOutlineOutlined,
  SchoolOutlined
} from '@mui/icons-material';
import i18next from 'i18next';
import routesConst from 'src/routesConst';

const renderOrgNavItems = () => [
  {
    href: `/${routesConst.app}/${routesConst.dashboard}`,
    icon: DashboardOutlined,
    title: i18next.t('appSidebar.mainPage'),
    id: 'mainPage'
  },
  {
    href: `/${routesConst.app}/${routesConst.practices}`,
    icon: GavelOutlined,
    title: i18next.t('appSidebar.practices'),
    id: 'practices'
  },
  {
    href: `/${routesConst.app}/${routesConst.coursewares}`,
    icon: SchoolOutlined,
    title: i18next.t('appSidebar.coursewares'),
    id: 'coursewares'
  },
  {
    href: `/${routesConst.app}/${routesConst.compensation}`,
    icon: DiamondOutlined,
    title: i18next.t('appSidebar.compensation'),
    id: 'compensation'
  },
  {
    href: `/${routesConst.app}/${routesConst.employees}`,
    icon: Groups2Outlined,
    title: i18next.t('appSidebar.employees'),
    id: 'employees'
  },
  {
    href: `/${routesConst.app}/${routesConst.org}/${routesConst.knowledgeCommunity}`,
    icon: ForumOutlined,
    title: i18next.t('appSidebar.knowledgeCommunity'),
    order: 7,
    id: 'knowledgeCommunity'
  },
  {
    href: `/${routesConst.app}/${routesConst.tickets}`,
    icon: ContactMailOutlined,
    title: i18next.t('appSidebar.tickets'),
    id: 'tickets'
  },
  {
    href: `/${routesConst.app}/${routesConst.org}/${routesConst.account}`,
    icon: PersonOutlineOutlined,
    title: i18next.t('appSidebar.account'),
    id: 'account'
  }
];
export default renderOrgNavItems;
