import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FirstStep from './OrgRegister/FirstStep';
import SecondStep from './OrgRegister/SecondStep';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const OrgRegisterForm = ({
  activeView,
  setActiveView,
  setPhoneAndEmail,
  phoneAndEmail,
  handleOpenVerifyCodeDialog,
  minimalMapAutoCompleteOpen,
  setMinimalMapAutoCompleteOpen
}) => {
  OrgRegisterForm.propTypes = {
    activeView: PropTypes.string,
    setActiveView: PropTypes.func,
    setPhoneAndEmail: PropTypes.func,
    phoneAndEmail: PropTypes.object,
    handleOpenVerifyCodeDialog: PropTypes.func,
    minimalMapAutoCompleteOpen: PropTypes.bool,
    setMinimalMapAutoCompleteOpen: PropTypes.func
  };
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  return (
    <>
      <Formik
        initialValues={{
          email: '',
          org_name: '',
          id: '',
          password: '',
          phone: '',
          policy: false,
          description: '',
          tags: [],
          available_funds: [],
          address: {
            city: '',
            street: '',
            number: '',
            zipCode: ''
          },
          number_of_beds_in_org: {},
          number_of_current_active_residents: 0,
          date_of_establishment: new Date(),
          _geoloc: {
            _long: 35.11,
            _lat: 32.71
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('validEmail'))
            .max(255)
            .required(t('emailRequired')),
          org_name: Yup.string().max(255).required(t('orgNameRequired')),
          id: Yup.string().max(255).required(t('identifierRequired')),
          phone: Yup.string()
            .required(t('phoneRequired'))
            .matches(phoneRegExp, t('validPhone')),
          password: Yup.string()
            .min(6, 'הסיסמה חייבת להיות מעל 6 אותיות')
            .max(100)
            .required(t('passwordRequired'))
            .matches(
              /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d@$#%^)(-_=+!%*?&]*$/,
              'הסיסמה יכולה להיות רק באנגלית, ומכילה לפחות אות אחת גדולה, אות קטנה ומספר אחד'
            )
            .matches(/[a-z]/, 'הסיסמה חייבת להכיל לפחות אות קטנה אחת')
            .matches(/[A-Z]/, 'הסיסמה חייבת להכיל לפחות אות גדולה אחת'),
          description: Yup.string()
            .max(2000, t('maxLength2000'))
            .min(20, t('minLength20'))
            .required(t('descriptionRequired')),
          policy: Yup.boolean().oneOf([true], t('policyChecked')),
          tags: Yup.array().required(t('tagsRequired')),
          number_of_beds_in_org: Yup.object().required(
            t('numberOfBedsIsRequired')
          ),
          date_of_establishment: Yup.date().required(
            t('dateOfEstablishmentRequired')
          )
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values,
          setValues
        }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
              // console.log(values);
            }}
          >
            {activeView === 'firstStep' ? (
              <FirstStep
                setActiveView={setActiveView}
                activeView={activeView}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                touched={touched}
                values={values}
                setValues={setValues}
              />
            ) : (
              <SecondStep
                setActiveView={setActiveView}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                touched={touched}
                values={values}
                setValues={setValues}
                handleOpenVerifyCodeDialog={handleOpenVerifyCodeDialog}
                minimalMapAutoCompleteOpen={minimalMapAutoCompleteOpen}
                setMinimalMapAutoCompleteOpen={setMinimalMapAutoCompleteOpen}
              />
            )}
          </form>
        )}
      </Formik>
      {/* {loading && (
        <Backdrop open={loading}>
          <CircularProgress />
        </Backdrop>
      )} */}
    </>
  );
};
export default OrgRegisterForm;
