import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';
import QuestionSlide from '../../sharedPartials/QuestionSlide';
import getQuestionData from '../getQuestionData';
import mainBg from './mainBg.webp';

/* eslint-disable */
const InfectionsPrevention = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
}) => {
  InfectionsPrevention.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    globalCoursewares: PropTypes.array,
    handleSetViewToSignCourseware: PropTypes.func
  };

  const sharedTitleSx = {
    color: 'black',
    transform: 'translateY(55px)'
  };

  return (
    <div
      className="theme-font-montserrat theme-color-cobalt-orange"
      style={{ width: '100%', height: '100%' }}
    >
      <div className="reveal infectionsPrevention">
        <div className="slides">
          <section
            data-background-image={mainBg}
            data-background-color="rgb(217,241,250)"
            data-background-size="initial"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '543.189px',
                left: '208.406px',
                top: '429.412px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content title-font"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  color: 'rgb(7, 55, 99)'
                }}
              >
                <p>מערך ההדרכה - מגדלור ייעוץ והכוונה</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 892,
                left: '34.0005px',
                top: 168,
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content title-font"
                style={{
                  zIndex: 11,
                  fontSize: '197%',
                  letterSpacing: '-0.11em',
                  color: 'rgb(28, 69, 135)'
                }}
                data-has-letter-spacing=""
              >
                <h1>מניעת זיהומים</h1>
              </div>
            </div>
          </section>
          <section
            data-background-image={mainBg}
            data-background-color="rgb(217,241,250)"
            data-background-size="initial"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '943.885px',
                height: '701.885px',
                left: '168.058px',
                top: '9.0575px'
              }}
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 944 702"
                >
                  <rect
                    width="943.885"
                    height="701.885"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="video"
              data-name="video-e10abe"
              style={{
                width: '571.663px',
                height: '644.464px',
                left: '354.168px',
                top: '37.768px'
              }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: 0 }}
              >
                <div
                  className="sl-block-content media-shadow"
                  style={{ zIndex: 11 }}
                  data-media-id={10476603}
                  data-video-thumb="https://s3.amazonaws.com/media-p.slid.es/videos/32388/JL4ctWi6/copywriter_thumb_00001.jpg"
                >
                  <video
                    playsInline=""
                    poster="https://s3.amazonaws.com/media-p.slid.es/videos/32388/JL4ctWi6/copywriter_thumb_00001.jpg"
                    data-autoplay=""
                    muted=""
                    loop="loop"
                    data-paused-by-reveal=""
                    data-lazy-loaded=""
                    data-src="https://s3.amazonaws.com/media-p.slid.es/videos/32388/JL4ctWi6/copywriter.mp4"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: '108.136px', height: 'auto' }}
              data-name="text-855285"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(7, 55, 99)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <strong>כיצד נלמד?</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: '285.136px', height: 'auto' }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(7, 55, 99)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>ההתקדמות היא באמצעות החצים &lt; או&nbsp; &gt;</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: '398.136px', height: 'auto' }}
              data-name="text-4bdc77"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(7, 55, 99)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    כשמגיעים לשקופית שמכילה שאלה, אי אפשר להתקדם עד שעונים נכון
                    עליה
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-background-size="initial"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: '132.5px', height: 'auto' }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(7, 55, 99)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span style={{ color: '#b22222' }}>
                    <strong>לומדה בנושא מניעת זיהומים</strong>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>&nbsp;</p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                אחד מעמודי התווך החשובים בנושא הבטחת איכות
                                הטיפול בחולה, מתמקד במניעה של זיהומים נרכשים
                                בבתי אבות.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                לא ניתן למנוע את כל מקרי הזיהומים הנרכשים, אך על
                                הצוות המטפל, להכיר ולהטמיע{' '}
                              </strong>
                            </span>
                            <span>
                              <strong>תוכניות</strong>
                            </span>
                            <span>
                              <strong>
                                {' '}
                                ואסטרטגיות למניעתם או/ו להפחתתם.{' '}
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(207, 226, 243)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '366.304px',
                left: '296.848px',
                top: '75.283px'
              }}
              data-name="text-bbdeca"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  color: 'rgb(28, 69, 135)',
                  fontSize: '120%'
                }}
              >
                <h3>
                  <span style={{ fontSize: '1.2em' }}>מה זה זיהום?</span>
                </h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: 180,
                top: '222.626px'
              }}
              data-name="text-280c52"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(28, 69, 135)',
                  lineHeight: '1.56'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li
                    className="fragment semi-fade-out"
                    data-fragment-index={0}
                  >
                    פגיעה בגוף עקב גורם זר כחיידק או נגיף.
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={0}
                    style={{ textAlign: 'justify' }}
                  >
                    זיהום או אילוח הוא נוכחות של חומר או גורם זר במקום בו אינו
                    נמצא בדרך כלל.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '565.62px',
                left: '214.38px',
                top: '438.626px'
              }}
              data-name="text-a2b4ea"
            >
              <div
                className="sl-block-content fragment fade-up"
                style={{ zIndex: 12, color: 'rgb(103, 78, 167)' }}
                data-fragment-index={1}
              >
                <p>
                  לזיהום יש בדרך כלל משמעויות שליליות, כמו
                  <br />
                  זיהום אוויר או זיהום רפואי בגוף האדם
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="#3f7892"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10958776/Screenshot_2023-11-29_141520.png"
            data-background-size="contain"
          ></section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-background-position="0% 0%"
            data-background-size="initial"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '642.962px',
                left: '158.52px',
                top: '94.6901px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content title-font"
                style={{
                  zIndex: 10,
                  letterSpacing: '-0.11em',
                  color: 'rgb(28, 69, 135)'
                }}
                data-has-letter-spacing=""
              >
                <h1>מה יכול לגרום למחלה?</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '645.858px',
                height: '145.762px',
                left: '157.071px',
                top: '246.152px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-256444"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-natural-width={509}
                  data-natural-height={141}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10958787/Screenshot_2023-11-29_141923.png"
                />
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-3e69e3"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '234.736px 469.223px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '124.564px',
                    left: '172.454px',
                    top: '491.914px'
                  }}
                  data-name="text-f9af73"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 13, color: 'rgb(103, 78, 167)' }}
                  >
                    <p style={{ textAlign: 'center' }}>פטריות</p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '33.7127px',
                    height: '84.3827px',
                    left: '217.88px',
                    top: '407.531px'
                  }}
                  data-name="shape-c95f2e"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="arrow-up"
                    data-shape-fill-color="rgba(0, 0, 0, 0)"
                    data-shape-stretch="true"
                    style={{ zIndex: 14 }}
                    data-shape-stroke-color="#1c4587"
                    data-shape-stroke-width="2px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 34 84"
                    >
                      <defs>
                        <clipPath id="shape-mask-1-1701260440943">
                          <polygon
                            points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                            fill="rgba(0, 0, 0, 0)"
                            stroke="#1c4587"
                            strokeWidth={4}
                          />
                        </clipPath>
                      </defs>
                      <polygon
                        points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                        className="shape-element"
                        fill="rgba(0, 0, 0, 0)"
                        stroke="#1c4587"
                        strokeWidth={4}
                        clipPath="url(#shape-mask-1-1701260440943)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-bf5f49"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '401px 469.223px 0px', zIndex: 15 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '124.564px',
                    left: '338.718px',
                    top: '491.914px'
                  }}
                  data-origin-id="8db44f79f7c111bde2aeaf4f06071dba"
                  data-name="text-c88ef2"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 16, color: 'rgb(103, 78, 167)' }}
                  >
                    <p style={{ textAlign: 'center' }}>טפילים</p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '33.7127px',
                    height: '84.3827px',
                    left: '384.144px',
                    top: '407.531px'
                  }}
                  data-origin-id="e36d95524ffd02fa469aa3f679ec3a16"
                  data-name="shape-2393ae"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="arrow-up"
                    data-shape-fill-color="rgba(0, 0, 0, 0)"
                    data-shape-stretch="true"
                    style={{ zIndex: 17 }}
                    data-shape-stroke-color="#1c4587"
                    data-shape-stroke-width="2px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 34 84"
                    >
                      <defs>
                        <clipPath id="shape-mask-2-1701260451135">
                          <polygon
                            points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                            fill="rgba(0, 0, 0, 0)"
                            stroke="#1c4587"
                            strokeWidth={4}
                          />
                        </clipPath>
                      </defs>
                      <polygon
                        points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                        className="shape-element"
                        fill="rgba(0, 0, 0, 0)"
                        stroke="#1c4587"
                        strokeWidth={4}
                        clipPath="url(#shape-mask-2-1701260451135)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-e8faaf"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '567.564px 469.223px 0px',
                  zIndex: 18
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '124.564px',
                    left: '505.282px',
                    top: '491.914px'
                  }}
                  data-origin-id="a6938ec6d93353d2330d2f62ea2425b2"
                  data-name="text-b2b45d"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 19, color: 'rgb(103, 78, 167)' }}
                  >
                    <p style={{ textAlign: 'center' }}>וירוסים</p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '33.7127px',
                    height: '84.3827px',
                    left: '550.708px',
                    top: '407.531px'
                  }}
                  data-origin-id="3935a744d194a3cc7696abfc3edea80b"
                  data-name="shape-b6a797"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="arrow-up"
                    data-shape-fill-color="rgba(0, 0, 0, 0)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                    data-shape-stroke-color="#1c4587"
                    data-shape-stroke-width="2px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 34 84"
                    >
                      <defs>
                        <clipPath id="shape-mask-3-1701260468289">
                          <polygon
                            points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                            fill="rgba(0, 0, 0, 0)"
                            stroke="#1c4587"
                            strokeWidth={4}
                          />
                        </clipPath>
                      </defs>
                      <polygon
                        points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                        className="shape-element"
                        fill="rgba(0, 0, 0, 0)"
                        stroke="#1c4587"
                        strokeWidth={4}
                        clipPath="url(#shape-mask-3-1701260468289)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-fab795"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '734.128px 469.223px 0px',
                  zIndex: 21
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '124.564px',
                    left: '671.846px',
                    top: '491.914px'
                  }}
                  data-origin-id="9e197bfcc273913591e04993b0f6e1f1"
                  data-name="text-08349f"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 22, color: 'rgb(103, 78, 167)' }}
                  >
                    <p style={{ textAlign: 'center' }}>חיידקים</p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '33.7127px',
                    height: '84.3827px',
                    left: '717.272px',
                    top: '407.531px'
                  }}
                  data-origin-id="2fa324f4c9bef97688ecd0993cfcca4c"
                  data-name="shape-8b758a"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="arrow-up"
                    data-shape-fill-color="rgba(0, 0, 0, 0)"
                    data-shape-stretch="true"
                    style={{ zIndex: 23 }}
                    data-shape-stroke-color="#1c4587"
                    data-shape-stroke-width="2px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 34 84"
                    >
                      <defs>
                        <clipPath id="shape-mask-4-1701260478763">
                          <polygon
                            points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                            fill="rgba(0, 0, 0, 0)"
                            stroke="#1c4587"
                            strokeWidth={4}
                          />
                        </clipPath>
                      </defs>
                      <polygon
                        points="16.85635,0 33.7127,42.19135 23.598889999999997,42.19135 23.598889999999997,84.3827 10.113809999999999,84.3827 10.113809999999999,42.19135 0,42.19135 16.85635,0"
                        className="shape-element"
                        fill="rgba(0, 0, 0, 0)"
                        stroke="#1c4587"
                        strokeWidth={4}
                        clipPath="url(#shape-mask-4-1701260478763)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-auto-animate=""
            data-background-image={mainBg}
            data-background-color="rgb(217,241,250)"
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f42613"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '95.7783px 501.99px 0px',
                  zIndex: 10
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '128.784px',
                    left: '204.082px',
                    top: '421.99px',
                    height: 'auto'
                  }}
                  data-name="text-2daff8"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 12,
                      fontSize: '70%',
                      color: 'rgb(32, 18, 77)'
                    }}
                  >
                    <h2>
                      <strong>נוזלי גוף</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '126.005px',
                    height: '120.5px',
                    left: '205.471px',
                    top: '421.99px'
                  }}
                  data-name="drawing-728825"
                >
                  <div
                    className="sl-block-content"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 11 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-63 -60 125 130"
                    >
                      <path
                        fill="rgb(255, 60, 112)"
                        d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-91d6cc"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{ transformOrigin: '721.92px 482.24px 0px', zIndex: 13 }}
                data-fragment-index={4}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 230,
                    left: '606.92px',
                    top: '421.99px',
                    height: 'auto'
                  }}
                  data-name="text-31b040"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 15,
                      fontSize: '70%',
                      color: 'rgb(7, 55, 99)'
                    }}
                  >
                    <h2>
                      <strong>סביבת המטופל</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '147.488px',
                    height: '130.502px',
                    left: '648.176px',
                    top: '416.989px'
                  }}
                  data-name="drawing-a004c2"
                >
                  <div
                    className="sl-block-content"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 14 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-85 -50 150 130"
                    >
                      <path
                        fill="rgb(242, 227, 93)"
                        d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-0b74a5"
            >
              <div
                className="sl-block-content sl-block-group-content fragment semi-fade-out"
                style={{
                  transformOrigin: '184.061px 149.334px 0px',
                  zIndex: 16
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '111.889px',
                    left: '128.116px',
                    top: '123.334px',
                    height: 'auto'
                  }}
                  data-name="text-9b139e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 18,
                      fontSize: '70%',
                      color: 'rgb(7, 55, 99)'
                    }}
                  >
                    <h2>
                      <strong>אוויר</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '140.121px',
                    height: '118.315px',
                    left: 114,
                    top: '90.176px'
                  }}
                  data-name="drawing-92b8e6"
                >
                  <div
                    className="sl-block-content"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 17 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-85 -50 150 130"
                    >
                      <path
                        fill="rgb(242, 227, 93)"
                        d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto', width: 'auto' }}
              data-name="group-18ff03"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '784.044px 188.52px 0px',
                  zIndex: 20
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '136.783px',
                    left: '715.652px',
                    top: '141.51px',
                    height: 'auto'
                  }}
                  data-name="text-08a0ec"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 22, transform: 'rotate(1deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 22,
                        fontSize: '70%',
                        color: 'rgb(28, 69, 135)'
                      }}
                    >
                      <h2>
                        <strong>בעלי חיים</strong>
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '124.247px',
                    height: '126.289px',
                    left: '721.92px',
                    top: '125.375px'
                  }}
                  data-name="drawing-4e6e21"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 21, transform: 'rotate(180deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                      data-timestamp={1638195786739}
                      style={{ zIndex: 21 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="-63 -60 125 130"
                      >
                        <path
                          fill="rgb(255, 60, 112)"
                          d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '634.977px',
                left: '79.778px',
                top: 313
              }}
              data-name="text-a0fc7c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 19, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 19,
                    color: 'rgb(152, 0, 0)',
                    fontSize: '150%'
                  }}
                >
                  <p style={{ textAlign: 'right' }}>
                    היכן מיקרואורגניזמים מסתתרים?
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 26 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 27 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-9a463b"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '519.613px 570.053px 0px',
                  zIndex: 28
                }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '106.969px',
                    height: '114.657px',
                    left: '471.402px',
                    top: '512.724px'
                  }}
                  data-name="drawing-91f9b0"
                >
                  <div
                    className="sl-block-content"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 29 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-63 -60 125 130"
                    >
                      <path
                        fill="rgb(109, 158, 235)"
                        d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '125.668px',
                    left: '456.779px',
                    top: '540.724px',
                    height: 'auto'
                  }}
                  data-name="text-58a9eb"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 30,
                      fontSize: '70%',
                      color: 'rgb(7, 55, 99)'
                    }}
                  >
                    <h2>
                      <strong>דקירה</strong>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fd4a46"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '456.779px 232.202px 0px',
                  zIndex: 23
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 200,
                    left: '356.779px',
                    top: '206.201px',
                    height: 'auto'
                  }}
                  data-name="text-d79ee2"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 25,
                      fontSize: '70%',
                      color: 'rgb(7, 55, 99)'
                    }}
                  >
                    <h2>
                      <strong>מגע</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '143.804px',
                    height: '87.365px',
                    left: '384.877px',
                    top: '188.519px'
                  }}
                  data-name="drawing-4a4692"
                >
                  <div
                    className="sl-block-content"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 24 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-63 -60 125 130"
                    >
                      <path
                        fill="rgb(255, 60, 112)"
                        d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '555.199px',
                left: '174.65px',
                top: '90.9142px'
              }}
              data-name="text-a0fc7c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    color: 'rgb(152, 0, 0)',
                    fontSize: '150%'
                  }}
                >
                  <p>מקורות זיהום אפשריים במוסד גריאטרי:</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '578.438px',
                left: '143.864px',
                top: '285.89px'
              }}
              data-name="text-bd3d74"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(166, 28, 0)',
                  fontSize: '120%'
                }}
              >
                <ul>
                  <li>אתרי גוף שונים של אותו מטופל.</li>
                  <li>מטופל למטופלים אחרים או לסביבה.</li>
                  <li>מטופל לצוות המטפל.</li>
                  <li>צוות מטפל למטופל.</li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '384.527px',
                left: '287.737px',
                top: '57.762px'
              }}
              data-name="text-a0fc7c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    color: 'rgb(152, 0, 0)',
                    fontSize: '150%'
                  }}
                >
                  <p>הם גרים איתנו</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '773.128px',
                left: '93.436px',
                top: '176.829px'
              }}
              data-name="text-0dc6b6"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(166, 28, 0)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '0.9em' }}>
                    Carbapenem Resistant Enterobacteriaceae - CRE
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '0.9em' }}>
                    (כולל KPS - קלבסיאלה Klebsiella)
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '0.9em' }}>
                    חיידקים השוכנים דרך קבע במעיים של בעלי חיים.
                  </span>
                  <br />
                  &nbsp;
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.0em' }}>
                    החיידק מסוגל לגרום לדלקת ראות, אך לרוב הוא מעורב בדלקת בדרכי
                    שתן או בזיהומי פצעים, אשר נרכשים בבתי חולים, בפרט אצל אנשים
                    עם מערכת חיסון מוחלשת או כושלת.
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '384.527px',
                left: '287.737px',
                top: '57.762px'
              }}
              data-name="text-a0fc7c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    color: 'rgb(152, 0, 0)',
                    fontSize: '150%'
                  }}
                >
                  <p>MRSA</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '773.128px',
                left: '93.4365px',
                top: '142.449px'
              }}
              data-name="text-0dc6b6"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(166, 28, 0)' }}
              >
                <p>
                  Methicillin Resistant Staphylococcus
                  <br />
                  Aureus
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '773.128px',
                left: '93.436px',
                top: '273.83px'
              }}
              data-name="text-db1a92"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(166, 28, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  חיידקי Staphylococcus שוכנים באופן טבעי על גבי העור של בני אדם
                  וכן ברקמות ריריות.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  MRSA אינו מהווה גורם סיכון משמעותי באוכלוסייה רגילה, אך הוא
                  גורם זיהום נפוץ בבתי חולים, ועמידותו בפני מרבית סוגי
                  האנטיביוטיקה הופכת אותו לאיום משמעותי על מאושפזים (בייחוד
                  מאושפזים בעלי מערכת חיסון מדוכאת) שעשויים להידבק בו במהלך
                  אשפוז בבית חולים.
                </p>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '522.047px',
                left: '218.977px',
                top: '58.9898px'
              }}
              data-name="text-a0fc7c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    color: 'rgb(152, 0, 0)',
                    fontSize: '150%'
                  }}
                >
                  <p>Clostridium difficile</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '773.128px',
                left: '93.436px',
                top: '117.99px'
              }}
              data-name="text-0dc6b6"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(166, 28, 0)' }}
              >
                <p>,anaerobic, Gram-positive</p>
                <p>spore-forming bacillus</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '460.164px',
                height: '401.419px',
                left: '249.919px',
                top: '228.665px'
              }}
              data-name="image-387bc1"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={282}
                  data-natural-height={246}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10958892/Screenshot_2023-11-29_145225.png"
                />
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '437.325px',
                left: '261.338px',
                top: '58.99px'
              }}
              data-name="text-a0fc7c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    color: 'rgb(152, 0, 0)',
                    fontSize: '150%'
                  }}
                >
                  <p>שני תנאים דרושים להתפתחות של CDI :</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '650.342px',
                left: '171.406px',
                top: '216.582px'
              }}
              data-name="text-245a9e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(166, 28, 0)' }}
              >
                <ol>
                  <li
                    className="fragment semi-fade-out"
                    data-fragment-index={0}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span style={{ fontSize: '1.0em' }}>
                        הדבקה ב C.DIFFICIL – על ידי אכילת ספורות המועברות בד"כ
                        מחולים אחרים. לרוב – על הידיים של הצוות הרפואי.
                      </span>
                      <br />
                      &nbsp;
                    </p>
                  </li>
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={0}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span style={{ fontSize: '1.0em' }}>
                        חשיפה לאנטיביוטיקה – כמעט כל האנטיביוטיקות
                        <br />
                        יכולות להיות אשמות אבל העיקריות – צפלוספורינים,
                        קלינדמיצין, קינולונים - להן הסיכון הגבוה ביותר.
                      </span>
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 13 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '650.342px',
                left: '154.829px',
                top: '118.026px'
              }}
              data-name="text-245a9e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(166, 28, 0)' }}
              >
                <ul>
                  <li
                    className="fragment semi-fade-out"
                    data-fragment-index={0}
                  >
                    <h3 style={{ textAlign: 'justify' }}>
                      <span style={{ fontSize: '0.7em' }}>
                        יש אנשים הנחשפים לשני התנאים הללו ויהפכו להיות נשאים
                        אסימפטומטיים. ואחרים – יפתחו CDI.
                      </span>
                    </h3>
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={0}
                  >
                    <h3 style={{ textAlign: 'justify' }}>
                      <span style={{ fontSize: '0.7em' }}>
                        המחלה נגרמת על ידי שני הטוקסינים של החיידק TOXIN .A +
                        TOXIN B
                      </span>
                    </h3>
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={0}
                  >
                    <h3>
                      <span style={{ fontSize: '0.7em' }}>
                        זני קלוסטרידיום שהם חסרי טוקסינים –{' '}
                        <strong>
                          <span style={{ color: '#ff0000' }}>
                            <u>אינם פתוגניים!</u>
                          </span>
                        </strong>
                      </span>
                    </h3>
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={0}
                  >
                    <h3>
                      <span style={{ fontSize: '0.7em', textAlign: 'justify' }}>
                        החולים נשארים בסיכון גבוה ל-CDI שלושה חודשים ויותר לאחר
                        הפסקת טיפול אנטיביוטי.
                      </span>
                    </h3>
                    <p>&nbsp;</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '437.325px',
                left: '261.338px',
                top: '58.99px'
              }}
              data-name="text-a0fc7c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    color: 'rgb(152, 0, 0)',
                    fontSize: '150%'
                  }}
                >
                  <p>אבחנת C. DIFFICILE</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '847.632px',
                left: '35.1619px',
                top: '193.5px'
              }}
              data-name="text-245a9e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(166, 28, 0)' }}
              >
                <ol>
                  <li className="fragment fade-out" data-fragment-index={0}>
                    <h3 style={{ textAlign: 'justify' }}>
                      <span style={{ fontSize: '1.0em' }}>
                        &nbsp;Gold standard -{' '}
                      </span>
                    </h3>
                    <h3 style={{ textAlign: 'justify' }}>
                      מקובלת לאבחון CDI באמצעות תרבית טוקסיגנית, שבה בשלב ראשון
                      מתורבת החיידק מדגימת הצואה, ולאחר מכן נבדקת נוכחות הרעלן
                      בחיידק.
                    </h3>
                    <h3 style={{ textAlign: 'justify' }}>
                      בדיקה זו דורשת מספר ימים והיא מסובכת לביצוע ולפיכך אינה
                      מעשית לשימוש אבחנתי יומיומי.
                    </h3>
                  </li>
                </ol>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '861.647px',
                left: '63.1921px',
                top: '193.5px'
              }}
              data-name="text-89ecc9"
            >
              <div
                className="sl-block-content fragment"
                style={{ zIndex: 14, color: 'rgb(166, 28, 0)' }}
                data-fragment-index={0}
              >
                <h3 style={{ textAlign: 'justify' }}>
                  2. אנטיגן ה- Glutamate .dehydrogenase (GDH)
                </h3>
                <h3 style={{ textAlign: 'justify' }}>
                  מיוצר בכמויות גדולות בחיידקי C. difficile בדיקת האנטגן בשיטת
                  EIA אינה מבדילה בין זנים המייצרים או חסרי רעלנים. לפיכך, השיטה
                  מתאימה לשימוש כבדיקת סקירה, המחייבת אימות המצאות הרעלן.
                </h3>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '650.342px',
                left: '154.829px',
                top: '132.136px'
              }}
              data-name="text-245a9e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(166, 28, 0)' }}
              >
                <ul>
                  <li>
                    <h3 style={{ textAlign: 'justify' }}>
                      <strong>
                        בדיקת נוכחות הרעלנים באמצעות שיטות אימונולוגיות שונות
                        CDTox A/B
                      </strong>
                      <br />
                      <span style={{ fontSize: '0.9em' }}>
                        בשימוש נרחב בשל היותה נוחה לשימוש: רגישות הבדיקה נחשבת
                        כיום כנמוכה מכדי לשמש כאמצעי אבחנתי יחיד.
                      </span>
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '650.342px',
                left: '154.829px',
                top: '132.136px'
              }}
              data-name="text-245a9e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(166, 28, 0)' }}
              >
                <ul>
                  <li className="visible">
                    <h3 style={{ textAlign: 'justify' }}>
                      <strong>
                        בדיקות PCR לנוכחות הגנים לרעלנים DTox PCR
                        <br />
                        קיימות מספר ערכות -
                      </strong>
                    </h3>
                    <ul>
                      <li className="visible" style={{ textAlign: 'justify' }}>
                        מסחריות לבדיקה זו אשר נמצאו כבעלות רגישות וסגוליות
                        מיטביים במחקרים שונים.
                      </li>
                      <li className="visible" style={{ textAlign: 'justify' }}>
                        בדיקה זו מתאימה, לכן גם כשימוש כאמצעי אבחנתי יחיד וגם
                        כבדיקת אימות.
                      </li>
                      <li className="visible" style={{ textAlign: 'justify' }}>
                        אבחנה בסיגמוידוסקופיה/קולונוסקופיה, אבחנה היסטופתולוגית.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '650.342px',
                left: '154.829px',
                top: '209.491px'
              }}
              data-name="text-245a9e"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(166, 28, 0)',
                  lineHeight: '1.43'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'justify' }}>
                  • בודקים C.DIFFICIL רק במטופלים עם שלשול.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  • אין לבדוק מטופלים אסימפטומטיים ואין לבצע TEST OF CURE בחולים
                  שהגיבו לטיפול.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  • העברת C.DIFFICIL על הידיים של אנשי הצוות - זו הדרך השכיחה
                  ביותר להדבקה.
                </p>
              </div>
            </div>
          </section>
          <section data-background-image={mainBg} data-background-color="null">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '733.768px',
                left: '113.116px',
                top: '208.388px'
              }}
            >
              <div
                className="sl-block-content slide-up-intro"
                style={{
                  zIndex: 10,
                  color: 'rgb(12, 52, 61)',
                  padding: 28,
                  border: '4px solid rgb(69, 129, 142)',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  fontSize: '80%'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                • שיתוף מדי חום - הידית יכולה להיות מזוהמת.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                • טיפול פה או סקשן כשידי המטפל או הציוד מזוהמים.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>• האכלה או מתן תרופות.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>• פרוצדורות חירום כמו החייאה.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                • חוסר היענות להנחיות שטיפת ידיים.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                • שיתוף ציוד בין החולים ללא חיטוי מתאים כמו כן
                                ניקיון סביבתי לא יעיל.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '638.564px',
                left: '160.718px',
                top: '101.689px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  fontSize: '126%',
                  zIndex: 12,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <strong>העברת </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </u>
                            <u>&nbsp;</u>
                            <u>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <strong>
                                        C.DIFFICIl<u> </u>דרך טיפול בחולים
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-background-color="null" data-background-image={mainBg}>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '249.356px',
                left: '355.322px',
                top: '100.274px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  fontSize: '126%',
                  zIndex: 10,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>גרדת</p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-2ec2d8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-out"
                style={{ transformOrigin: '480px 334.146px 0px', zIndex: 11 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '733.768px',
                    left: '113.116px',
                    top: '189.249px'
                  }}
                >
                  <div
                    className="sl-block-content slide-up-intro"
                    style={{
                      zIndex: 12,
                      color: 'rgb(12, 52, 61)',
                      padding: 28,
                      border: '4px solid rgb(69, 129, 142)',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      fontSize: '80%'
                    }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <strong>
                                    המחלה שכיחה במיוחד אצל אנשים החיים בתנאי
                                    תברואה לקויה.
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '733.768px',
                    left: '113.116px',
                    top: '318.042px'
                  }}
                  data-name="text-d1b5b9"
                >
                  <div
                    className="sl-block-content slide-up-intro"
                    style={{
                      zIndex: 13,
                      color: 'rgb(12, 52, 61)',
                      padding: 28,
                      border: '4px solid rgb(69, 129, 142)',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      fontSize: '80%'
                    }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <strong>המחלה נגרמת על ידי</strong>
                                </span>
                                <strong> </strong>
                                <span>
                                  <strong>Sarcoptes</strong>
                                </span>{' '}
                                <span>
                                  <strong>scabie</strong>
                                </span>
                                <span>
                                  <strong>
                                    {' '}
                                    קרצית החודרת לתוך העור ומטילה שם ביצים רבות.
                                    נוכחות הטפיל והביצים במחילות הדקות, שהוא
                                    חופר בעור גורמת לגרד עז המתגבר בלילה.
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-850a96"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-up"
                style={{ transformOrigin: '480px 350.646px 0px', zIndex: 14 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '733.768px',
                    left: '113.116px',
                    top: '184.249px'
                  }}
                  data-origin-id="fb0f10682b4fb4f239a6fd01ca04e1c6"
                  data-name="text-e7bc95"
                >
                  <div
                    className="sl-block-content slide-up-intro"
                    style={{
                      zIndex: 15,
                      color: 'rgb(12, 52, 61)',
                      padding: 28,
                      border: '4px solid rgb(69, 129, 142)',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      fontSize: '80%'
                    }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <strong>
                                    במקום החדירה לעור ניתן לראות קשריות אדומות
                                    קטנות הדומות לעקיצת יתוש.
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                    <p style={{ textAlign: 'right' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <strong>
                                    אורך הקרצית הבוגרת ממין נקבה הוא 0.3 עד 0.45
                                    מילימטר.
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '733.768px',
                    left: '113.116px',
                    top: '355.042px'
                  }}
                  data-origin-id="9f838e2269be292f4819d1ea167ffdfb"
                  data-name="text-2dfa3a"
                >
                  <div
                    className="sl-block-content slide-up-intro"
                    style={{
                      zIndex: 16,
                      color: 'rgb(12, 52, 61)',
                      padding: 28,
                      border: '4px solid rgb(69, 129, 142)',
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      fontSize: '80%'
                    }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <strong>
                                    הטפילים הגורמים לגרדת מעדיפים לחדור לאזורי
                                    הקפלים בעור ולמקומות בהם העור דק, לכן נגעי
                                    הגרדת מצויים בעיקר בין אצבעות הידיים, בקפלי
                                    המותניים, בשורשי כפות הידיים, בקפלי בתי שחי,
                                    סביב הפטמות ועל ראש הפין.
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* missing section */}
          <section
            data-background-color="null"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10958722/מניעת_זיהומים.jpg"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '249.356px',
                left: '355.322px',
                top: '109.977px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  fontSize: '150%',
                  zIndex: 10,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <p style={{ textAlign: 'justify' }}>כך זה נראה</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '378.815px',
                height: '248.717px',
                left: '149.363px',
                top: '235.642px'
              }}
              data-name="image-4bce02"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={495}
                  data-natural-height={325}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10978158/Screenshot_2023-12-06_143055.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '239.688px',
                height: '248.717px',
                left: '570.95px',
                top: '235.642px'
              }}
              data-name="image-b6e53d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-natural-width={216}
                  data-natural-height={318}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10978159/Screenshot_2023-12-06_143105.png"
                />
              </div>
            </div>
          </section>
          <section data-background-color="null" data-background-image={mainBg}>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '249.356px',
                left: '355.322px',
                top: '100.274px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  fontSize: '126%',
                  zIndex: 10,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>טיפול בגרדת</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '636.798px',
                left: '161.601px',
                top: '205.5px'
              }}
              data-name="text-cde212"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content fragment fade-out"
                  style={{ zIndex: 11, color: 'rgb(28, 69, 135)' }}
                  data-fragment-index={0}
                >
                  <p style={{ textAlign: 'justify' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  הטיפול בגרדת חייב להיות קפדני ולכלול גם את
                                  המטפלים שאינם מתגרדים. בשלבים הראשונים להדבקה
                                  בגרדת אין עדיין תחושת גרד, ולכן המטפלים שנדבקו
                                  ואינם מתגרדים עלולים בכל זאת להיות חולים בהמשך
                                  ולהדביק חזרה את המטופלים שכבר קיבלו טיפול.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '636.798px',
                left: '161.601px',
                top: '307.634px'
              }}
              data-name="text-785e86"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 14, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content fragment fade-left"
                  style={{ zIndex: 14, color: 'rgb(28, 69, 135)' }}
                  data-fragment-index={1}
                >
                  <p style={{ textAlign: 'justify' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  כדי למנוע הדבקה מביצים שהתפזרו, יש לכבס את כלי
                                  המיטה במים בטמפרטורה גבוהה, עד כדי הרתחה.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-9d68fa"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-out"
                style={{ transformOrigin: '480px 326px 0px', zIndex: 12 }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '636.798px',
                    left: '161.601px',
                    top: '245.5px'
                  }}
                  data-name="text-70710a"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 13, transform: 'rotate(0deg)' }}
                  >
                    <div
                      className="sl-block-content fragment fade-right"
                      style={{ zIndex: 13, color: 'rgb(28, 69, 135)' }}
                      data-fragment-index={0}
                    >
                      <p style={{ textAlign: 'justify' }}>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <strong>
                                      הטיפול כולל מריחת משחה (מכילה גופרית או
                                      מינון נמוך של חומר קוטל
                                    </strong>
                                  </span>
                                  <span>
                                    <strong>
                                      <a
                                        href="https://he.wikipedia.org/wiki/%D7%A7%D7%95%D7%98%D7%9C_%D7%97%D7%A8%D7%A7%D7%99%D7%9D"
                                        target="_blank"
                                      >
                                        {' '}
                                      </a>
                                    </strong>
                                  </span>
                                  <span>
                                    <strong>
                                      חרקים) על הגוף כולו, מכף רגל ועד הסנטר,
                                      וחזרה על הטיפול מדי כמה ימים, כדי לוודא
                                      שהטפילים הבוקעים מהביצים מומתים אף הם.{' '}
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(207, 226, 243)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '420.608px',
                left: '269.696px',
                top: '87.5363px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  fontSize: '138%',
                  zIndex: 10,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <strong>
                                        אמצעי הזהירות מתייחסים לחומרי הגוף וציוד
                                        שבא עמם במגע :{' '}
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '274.48px',
                left: '553.064px',
                top: '313.609px'
              }}
              data-name="text-94dc6b"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(166, 28, 0)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>דם ומוצריו</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>נוזלי גוף והפרשות</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>רקמות ריריות</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>עור לא שלם</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '328.785px',
                height: '285.9px',
                left: '217.514px',
                top: '293.795px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-77d0e6"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={322}
                  data-natural-height={280}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10965888/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section data-background-color="rgb(207, 226, 243)">
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 615,
                height: '411.678px',
                left: '209.959px',
                top: '173.998px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-b93cd9"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={611}
                  data-natural-height={409}
                  style={{
                    filter: 'invert(1)'
                  }}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10965900/pasted-from-clipboard-removebg-preview.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '412.866px',
                left: '273.567px',
                top: '66.0361px'
              }}
              data-name="text-621d79"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(28, 69, 135)' }}
              >
                <h2>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <strong>
                                        <span>
                                          <span>דרכי התמודדות</span>
                                        </span>
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '209.628px',
                height: 220,
                left: '572.336px',
                top: '173.998px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-300300"
            >
              <div
                className="sl-block-content fragment"
                style={{ zIndex: 12 }}
                data-maintain-ar="false"
                data-fragment-index={0}
              >
                <img
                  className=""
                  data-natural-width={170}
                  data-natural-height={220}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10965909/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '209.628px',
                height: '219.992px',
                left: '325.311px',
                top: '173.998px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-9d79f6"
            >
              <div
                className="sl-block-content fragment"
                style={{ zIndex: 14 }}
                data-maintain-ar="false"
                data-fragment-index={2}
              >
                <img
                  className=""
                  data-natural-width={1920}
                  data-natural-height={1433}
                  data-lazy-loaded=""
                  data-crop-x={0}
                  data-crop-y="0.059131"
                  data-crop-width={1}
                  data-crop-height="0.881737"
                  style={{ left: 0, top: '-15px', width: 210, height: 249 }}
                  data-src="https://images.unsplash.com/photo-1619691114448-d136c0890914?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDV8fGxhdGV4JTIwZ2xvdmVzfGVufDB8fHx8MTcwMTQyNjEzMHww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 210,
                height: 220,
                left: '452.606px',
                top: '265.232px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-9e264d"
            >
              <div
                className="sl-block-content fragment"
                style={{ zIndex: 13 }}
                data-maintain-ar="false"
                data-fragment-index={1}
              >
                <img
                  className=""
                  data-natural-width={265}
                  data-natural-height={194}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10965913/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 210,
                height: 220,
                left: '197.851px',
                top: '265.232px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-dd87f4"
            >
              <div
                className="sl-block-content fragment"
                style={{ zIndex: 15 }}
                data-maintain-ar="false"
                data-fragment-index={3}
              >
                <img
                  className=""
                  data-natural-width={257}
                  data-natural-height={170}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10965914/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '717.751px',
                height: '256.233px',
                left: '121.125px',
                top: '174.884px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-59ea63"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-lazy-loaded=""
                  data-natural-width={845}
                  data-natural-height={319}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10966217/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: '199.814px',
                top: '448.063px'
              }}
              data-name="text-9ab4c4"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                בנוסף לכך על פי הסיכון הצפוי למגע בדם, נוזלי
                                גוף, הפרשות ומחוללי זיהום נדרש שימוש במיגון אישי
                                למניעה של חשיפת העור, הריריות ובגדי המטפל
                                למחוללי הזיהום.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: 180,
                top: '77.6117px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, color: 'rgb(204, 0, 0)' }}
              >
                <p>
                  <strong>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  היגיינת ידיים היא מרכיב מרכזי באמצעי הזהירות
                                  השגרתיים.
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.776px',
                left: '141.08px',
                top: 184
              }}
              data-name="text-9ab4c4"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>
                                אמצעי בודד החשוב ביותר למניעת זיהומים, העברתם,
                                ובעיקר מניעת זיהומים צולבים בבית החולים.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>רחצת ידיים שגרתית עם מים וסבון.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>חיטוי ידיים עם סבון אנטי </strong>
                            </span>
                            <span>
                              <strong>ספטי</strong>
                            </span>
                            <span>
                              <strong>.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>חיטוי ללא מים, </strong>
                            </span>
                            <span>
                              <strong>hand rub</strong>
                            </span>
                            <span>
                              <strong> תמיסת אלכוהול 70% עם </strong>
                            </span>
                            <span>
                              <strong>כלורקסידין 0.5% (</strong>
                            </span>
                            <span>
                              <strong>ספטול</strong>
                            </span>
                            <span>
                              <strong>)</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>הספטול</strong>
                            </span>
                            <span>
                              <strong>
                                {' '}
                                טוב לשימוש כאשר אין לכלוך נראה. אין צורך בכיור
                                או מגבת, אפקטיבי יותר מסבון אנטיספטי.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '414.595px',
                left: '272.703px',
                top: '53.5515px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, color: 'rgb(204, 0, 0)' }}
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>היגיינת ידיים</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '414.595px',
                left: '272.703px',
                top: '53.5515px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(204, 0, 0)' }}
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>5 רגעים להיגיינת ידיים</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '806.4px',
                height: '430.718px',
                left: '76.8005px',
                top: '177.193px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-008ff3"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={674}
                  data-natural-height={360}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10966224/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-image={mainBg}
            data-background-color="rgb(217,241,250)"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 413,
                left: '47.3989px',
                top: '250.175px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span>
                    <strong>
                      <span
                        style={{
                          fontSize: '3rem'
                        }}
                      >
                        איך רוחצים ידיים נכון ?
                      </span>
                    </strong>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 451,
                height: 648,
                left: 480,
                top: 36,
                minWidth: 1,
                minHeight: 1
              }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.62' }}
              >
                <div className="sl-block-content" style={{ zIndex: 11 }}>
                  <img
                    className=""
                    data-natural-width={1920}
                    data-natural-height={2880}
                    data-crop-x={0}
                    data-crop-y="0.021064"
                    data-crop-width={1}
                    data-crop-height="0.957871"
                    style={{ left: 0, top: '-14px', width: 451, height: 677 }}
                    data-lazy-loaded=""
                    data-src="https://images.unsplash.com/photo-1556229167-7ed11195e641?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDEyfHx3YXNoJTIwaGFuZHN8ZW58MHx8fHwxNzAxNDM0NjE4fDA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '555.946px',
                left: '202.027px',
                top: '78.9233px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span>
                    <strong>
                      <span>איך רוחצים ידיים נכון ?</span>
                    </strong>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '762.76px',
                left: '98.62px',
                top: '204.5px'
              }}
              data-name="text-053f42"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                * הרטיבו את הידיים עם מים זורמים נקיים.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>* שימו סבון על הידיים.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                * שפשפו את הידיים היטב, כפות וגבות, בין האצבעות
                                ומתחת לציפורניים במשך 20 שניות.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>* שטפו את הידיים היטב.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                * הסירו את הסבון עם מים זורמים נקיים.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>* נגבו את הידיים עם מגבת נקייה.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '806.4px',
                height: '448.936px',
                left: '76.8px',
                top: '135.562px'
              }}
              data-name="image-c3d7d9"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={670}
                  data-natural-height={373}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10966242/1from-clipboard-removebg-preview.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '585.924px',
                height: '532.8px',
                left: '187.038px',
                top: '93.6px'
              }}
              data-name="image-ded60a"
            >
              <div
                className="sl-block-content fragment"
                style={{ zIndex: 11 }}
                data-fragment-index={1}
              >
                <img
                  className=""
                  data-natural-width={375}
                  data-natural-height={341}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10966240/2from-clipboard-removebg-preview.png"
                />
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '414.595px',
                left: '272.703px',
                top: '78.8147px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(204, 0, 0)' }}
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <strong>כפפות</strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.183px',
                top: '215.43px'
              }}
              data-name="text-9ec506"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'justify',
                    color: 'rgb(28, 69, 135)'
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    *
                    <strong>
                      יש לעטות כפפות בפעילות טיפולית בה צפוי מגע בעור לא שלם,
                      ריריות, הפרשות, דם או נוזלי גוף ו/או מגע בחפצים מזוהמים
                      מרכיבים אלה.
                    </strong>
                  </p>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  יש להשתמש בכפפות תקניות ובמידה המתאימה.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  כפפות אינן במקום היגיינת ידיים אלא בנוסף לכך.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  יש להסיר את הכפפות מיד לאחר המגע בחולה או
                                  סביבתו, להשליכן למיכל פסולת ולחטא ידיים מיד
                                  לאחר מכן.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '699.21px',
                left: '130.395px',
                top: '77.7366px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(204, 0, 0)' }}
              >
                <h1 style={{ textAlign: 'center' }}>הגנה על ריריות הפנים</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.183px',
                top: '215.43px'
              }}
              data-name="text-9ec506"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'justify',
                    color: 'rgb(28, 69, 135)'
                  }}
                >
                  <p>
                    יש להשתמש במיגון לריריות העיניים, האף והפה במהלך פעולות בהן
                    צפויה התזה או רסס של דם, נוזלי גוף או הפרשות.
                  </p>
                  <p>
                    <u>דוגמאות:</u> שאיפת הפרשות מדרכי הנשימה, החייאה, טיפול
                    בפצעים וכו'.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '373.442px',
                height: '196.999px',
                left: '293.28px',
                top: '410.816px'
              }}
              data-name="image-cacedf"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={436}
                  data-natural-height={230}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10978185/Screenshot_2023-12-06_143921-removebg-preview.png"
                />
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '551.313px',
                left: '204.344px',
                top: '78.8147px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(204, 0, 0)' }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span>
                    <strong>
                      <span>טיפול במטופל וסביבתו:</span>
                    </strong>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.183px',
                top: '202.055px'
              }}
              data-name="text-9ec506"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'justify',
                    color: 'rgb(28, 69, 135)'
                  }}
                >
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  מותר למטופל לאכול בחדר האוכל של מחלקה ולהשתתף
                                  בפעילות החברתית של המוסד.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.183px',
                top: '331.055px'
              }}
              data-name="text-e4defe"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content fragment"
                  style={{
                    zIndex: 12,
                    textAlign: 'justify',
                    color: 'rgb(204, 0, 0)'
                  }}
                  data-fragment-index={0}
                >
                  <p>
                    <span>
                      <strong>אין למנוע ממטופל לבצע טיפול פיזיותרפיה.</strong>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.183px',
                top: '420.055px'
              }}
              data-name="text-773dba"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content fragment"
                  style={{
                    zIndex: 13,
                    textAlign: 'justify',
                    color: 'rgb(39, 78, 19)'
                  }}
                  data-fragment-index={1}
                >
                  <p>
                    <span>
                      <strong>
                        מומלץ להקצות למטופל נשא לחיידק עמיד ציוד פיזיותרפיה אישי{' '}
                      </strong>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  במידת האפשר. בעת ביצוע טיפול פיזיותרפיה ישתמש
                                  הפיזיותרפיסט בחלוק וכפפות. יש להקפיד על ניקוי
                                  וחיטוי הסביבה והציוד לאחר טיפול פיזיותרפיה לפי
                                  הנחיות מפורטות בנהלי המוסד.{' '}
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '551.313px',
                left: '204.344px',
                top: '78.8147px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(204, 0, 0)' }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span>
                    <strong>
                      <span>במה מנקים ?</span>
                    </strong>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.183px',
                top: '202.055px'
              }}
              data-name="text-9ec506"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'justify',
                    color: 'rgb(28, 69, 135)'
                  }}
                >
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  יש לבצע ניקיון של ציוד ורהיטים הנמצאים בשימוש
                                  של המטופל : מיטה, כיור, אסלה, כיסא רחצה, ידית
                                  הדלת פעם ביום על ידי ניגוב במטלית ספוגה בתמיסת
                                  כלור פעיל בריכוז 1000חל"ם.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.182px',
                top: '429.424px'
              }}
              data-name="text-d16b11"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 12,
                    textAlign: 'justify',
                    color: 'rgb(28, 69, 135)'
                  }}
                >
                  <p>
                    <span>
                      *
                      <span>
                        <strong>
                          לקבלת תמיסת כלור בריכוז רצוי -
                          <u>
                            יש לדלל 1 ליטר תמיסה מרוכזת של נתרן תת כלורי (
                            אקונומיקה) ב 10 ליטר מים . ציוד דורש חיטוי
                          </u>
                        </strong>
                      </span>
                    </span>
                  </p>
                  <p>
                    *
                    <strong>
                      יש להשרות למשך 10 דקות בתמיסת כלור, לשטוף במים זורמים
                      ולייבש.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '551.313px',
                left: '204.344px',
                top: '78.8147px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(204, 0, 0)' }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span>
                    <strong>
                      <span>מה עושים אם נדקרנו ?</span>
                    </strong>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.636px',
                left: '107.182px',
                top: 228
              }}
              data-name="text-d16b11"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'justify',
                    color: 'rgb(28, 69, 135)'
                  }}
                >
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  {' '}
                                  הקז דם תוך לחיצה על מקום הדקירה או החתך, רחץ
                                  בתמיסת חיטוי.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  {' '}
                                  שטוף במים זורמים וחבוש לפי הצורך.
                                </strong>
                              </span>{' '}
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  {' '}
                                  הודע לממונה הישיר ע"מ שיילקח דם מהמקור.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              *
                              <span>
                                <strong>
                                  {' '}
                                  פנה למיון תוך ציון אירוע הדקירה. (קבל טופס 250
                                  מהמזכירות).
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '181.691px',
                height: '168.713px',
                left: '204.344px',
                top: 492,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-f770e0"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={154}
                  data-natural-height={143}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10966278/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '551.313px',
                left: '204.344px',
                top: '78.8147px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(7, 55, 99)' }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <strong>
                        <span>
                          הרציונל של חיסון
                          <br />
                          עובדי בריאות:
                        </span>
                      </strong>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '754.551px',
                left: '102.725px',
                top: '266.197px'
              }}
              data-name="text-2b28db"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(12, 52, 61)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                עובדי מערכת הבריאות חשופים להדבקה במחוללי מחלות
                                מדבקות קשות. הם גם עלולים להדביק במחוללים אלו את
                                מטופליהם.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '754.551px',
                left: '102.725px',
                top: '405.887px'
              }}
              data-name="text-952c5e"
            >
              <div
                className="sl-block-content fragment fade-down"
                style={{ zIndex: 12, color: 'rgb(12, 52, 61)' }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>לכן קובע משרד הבריאות כי</strong>
                            </span>{' '}
                            <span style={{ color: '#ff0000' }}>
                              <u>
                                <strong>זכותם</strong>
                              </u>{' '}
                            </span>
                            <span>
                              <strong>
                                של עובדי בריאות חדשים וותיקים להתחסן כנגד מחלות
                                קשות החשובות אשר כנגדן קיים חיסון בטוח ויעיל.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '322.459px',
                left: '318.771px',
                top: '117.452px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(7, 55, 99)' }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <u>
                    <span>
                      <span>
                        <strong>
                          <span>מי אנחנו ?</span>
                        </strong>
                      </span>
                    </span>
                  </u>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '754.551px',
                left: '102.725px',
                top: '306.678px'
              }}
              data-name="text-2b28db"
            >
              <div
                className="sl-block-content fragment fade-out"
                style={{ zIndex: 11, color: 'rgb(12, 52, 61)' }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <strong>
                              <span style={{ color: '#ff0000' }}>
                                <u>עובד בריאות </u>
                              </span>
                              - עובד במערכת הבריאות תושב המדינה או תייר העובד,
                              בתשלום או בהתנדבות כולל עובד קבלן ורופא עצמאי,
                              העשוי לבוא במגע פיזי עם חולים או עם נוזלי גופם או
                              עם הפרשותיהם.
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '754.551px',
                left: '102.725px',
                top: '306.678px'
              }}
              data-name="text-694cd4"
            >
              <div
                className="sl-block-content fragment fade-up"
                style={{ zIndex: 12, color: 'rgb(12, 52, 61)' }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <strong>
                              <span style={{ color: '#ff0000' }}>
                                <u>עובדי מינהל </u>
                              </span>
                            </strong>
                            <span>
                              <strong>
                                - עובד במוסד העלול להדבק ממטופלים במחלות העוברות
                                דרך האוויר, בין היתר, נכללים מזכירות רפואיות
                                המקבלות קהל במרפאות או עובדות במחלקות האשפוז,
                                רוקחים, עובדים סוציאליים, וכן רוב התעמולנים
                                הרפואיים ועובדי התחזוקה.
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '590.517px',
                left: '184.742px',
                top: '93.6749px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(225, 32, 32)' }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <u>
                    <span>
                      <span>
                        <strong>
                          <span>איזה חיסונים מקבלים ?</span>
                        </strong>
                      </span>
                    </span>
                  </u>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '430.588px',
                left: '324.149px',
                top: '290.331px'
              }}
              data-name="text-2b28db"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(12, 52, 61)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>שפעת עונתית.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>Tdap</strong>
                            </span>
                            <span>
                              <strong>&nbsp;&nbsp; - טטנוס, שעלת.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>MMR</strong>
                            </span>
                            <span>
                              <strong>&nbsp;&nbsp; - חצבת, חזרת, אדמת. </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>אבעבועות רוח.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '174.985px',
                left: '392.508px',
                top: '197.681px'
              }}
              data-name="text-1ba0cb"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, color: 'rgb(12, 52, 61)' }}
              >
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>
                                <span>
                                  <strong>עובדי </strong>
                                </span>
                              </span>
                            </u>
                            <u>
                              <span>
                                <span>
                                  <strong>מינהל</strong>
                                </span>
                              </span>
                            </u>
                            <u>
                              <span>
                                <span>
                                  <strong> :</strong>
                                </span>
                              </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(158, 191, 207)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '590.517px',
                left: '184.742px',
                top: '93.6749px'
              }}
              data-name="text-2af2e9"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(225, 32, 32)' }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  <u>
                    <span>
                      <span>
                        <strong>
                          <span>איזה חיסונים מקבלים ?</span>
                        </strong>
                      </span>
                    </span>
                  </u>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '430.588px',
                left: '324.149px',
                top: '290.331px'
              }}
              data-name="text-2b28db"
            >
              <div
                className="sl-block-content fragment fade-out"
                style={{ zIndex: 11, color: 'rgb(12, 52, 61)' }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>שפעת עונתית.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>Tdap</strong>
                            </span>
                            <span>
                              <strong>&nbsp;&nbsp; - טטנוס, שעלת.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>MMR</strong>
                            </span>
                            <span>
                              <strong>&nbsp;&nbsp; - חצבת, חזרת, אדמת. </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *
                            <span>
                              <strong>אבעבועות רוח.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '174.985px',
                left: '392.508px',
                top: '197.681px'
              }}
              data-name="text-1ba0cb"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, color: 'rgb(12, 52, 61)' }}
              >
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>
                                <span>
                                  <strong>עובדי </strong>
                                </span>
                              </span>
                              <strong>בריאות</strong>
                              <span>
                                <span>
                                  <strong>:</strong>
                                </span>
                              </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '377.09px',
                left: '377.647px',
                top: '244.514px'
              }}
              data-name="text-f7c2b6"
            >
              <div
                className="sl-block-content fragment fade-left"
                style={{ zIndex: 13, color: 'rgb(12, 52, 61)' }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ color: '#008000' }}>
                    <u>בנוסף:</u>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *<span>תבחין </span>
                            <span>טוברקולין</span>
                            <span> – </span>
                            <span>MANTU</span>.
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *<span>פוליו.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            *<span>HBV</span>
                            <span> – הפטיטיס מסוג </span>
                            <span>B</span>.{' '}
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '586.87px',
                left: '186.565px',
                top: '213.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <strong>
                          <span>נוהל פנימי לטיפול בדיירים עם בידוד מגע </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '803.836px',
                left: '78.082px',
                top: 249,
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(255, 0, 0)'
                }}
              >
                <h2 style={{ textAlign: 'justify' }}>
                  <u>
                    <span>
                      <strong>
                        <span>
                          על מנת למנוע העברה של חיידקים עמידים בין מטופלים, יש
                          להקפיד על&nbsp; ההנחיות הבאות:{' '}
                        </span>
                      </strong>
                    </span>
                  </u>
                </h2>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '803.836px',
                left: '78.082px',
                top: '110.796px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(255, 0, 0)',
                  lineHeight: '1.56'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment semi-fade-out"
                    data-fragment-index={0}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  הקפדה על היגיינת הידיים (חיטוי ו/או רחיצת
                                  הידיים) לפני ואחרי כל מגע עם חולה או ציוד.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={0}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  תדרוך החולה לגבי היגיינה אישית, כולל הקפדה על
                                  רחיצת הידיים במים ובסבון לפני אכילה ואחרי כל
                                  ביקור בשירותים.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={1}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  המטפל יקפיד על היגיינת ידיים בטרם כל טיפול,
                                  לפני לבישת כפפות ולאחר הסרתן.{' '}
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={2}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              י
                              <span>
                                <strong>
                                  ש להשתמש בכפפות חד-פעמיות, בכל מגע עם החולה
                                  וחפציו.{' '}
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-up"
                    data-fragment-index={3}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  נוהל זה מבוסס בעיקרו על נוהל משרד הבריאות.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '803.836px',
                left: '78.082px',
                top: 218,
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(91, 15, 0)',
                  lineHeight: '1.56'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li
                    className="fragment semi-fade-out"
                    data-fragment-index={0}
                    style={{ textAlign: 'right' }}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  יש לקבל מהאחות אחראית המחלקה /המשמרת את רשימת
                                  המטופלים הנמצאים בבידוד.{' '}
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={0}
                    style={{ textAlign: 'right' }}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  יש להקפיד על שימוש בכפפות בכל מגע עם המטופל
                                  וציודו.{' '}
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={1}
                    style={{ textAlign: 'right' }}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  שימוש בכפפות מהווה הגנה למטפל אך לא מונע העברת
                                  זיהומים במלואו. לכן, חובה לרחוץ ידיים לפני
                                  ואחרי כל הפעלה.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={2}
                    style={{ textAlign: 'right' }}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  חובה להחליף כפפות בין מטופל למטופל.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 300,
                left: 330,
                top: '119.239px'
              }}
              data-name="text-62f285"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <h3>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>
                                <span>
                                  <strong>הנחיות עבודה:</strong>
                                </span>
                              </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h3>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '803.836px',
                left: '78.082px',
                top: '186.586px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 10,
                  color: 'rgb(91, 15, 0)',
                  lineHeight: '1.56'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment semi-fade-out"
                    data-fragment-index={0}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  יש לתכנן את הפעילות ולקבץ דיירים בבידוד בתחילת
                                  יום עבודה.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={0}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  לפני תחילת ההפעלה עם המטופל אשר נמצא בבידוד,
                                  יש להכין את הציוד הנדרש, לפי תוכנית עבודה אתו
                                  על מנת למנוע נגיעה בציוד נקי.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={1}
                  >
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>
                                  את הציוד שהיה בשימוש בין מטופל למטופל, יש
                                  לנקות בכלור לפי מה שהוסבר &nbsp; קודם.
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 300,
                left: 330,
                top: '88.0314px'
              }}
              data-name="text-62f285"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <h3>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>
                                <span>
                                  <strong>הנחיות עבודה:</strong>
                                </span>
                              </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h3>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '481.734px',
                left: '239.133px',
                top: '56.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <u>
                    <span>
                      <span>
                        <strong>טיפול</strong>
                      </span>
                    </span>
                  </u>
                  <u> </u>
                  <u>
                    <span>
                      <span>
                        <strong>בפסולת חדה מזוהמת:</strong>
                      </span>
                    </span>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 16,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-aff125"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment semi-fade-out"
                style={{
                  transformOrigin: '521.972px 312.749px 0px',
                  zIndex: 12
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-3210dc"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '521.972px 312.749px 0px',
                      zIndex: 13
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '333.972px',
                        top: '207.834px'
                      }}
                      data-name="shape-e1e4ba"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(244, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 14 }}
                        data-shape-stroke-color="#cc4125"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-9-1701458397195">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(244, 204, 204)"
                                stroke="#cc4125"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(244, 204, 204)"
                            stroke="#cc4125"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-9-1701458397195)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: '329.223px',
                        left: '357.361px',
                        top: '220.749px',
                        height: 'auto'
                      }}
                      data-name="text-b45c7e"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 15,
                          color: 'rgb(0, 0, 0)',
                          lineHeight: '1.04',
                          padding: 20,
                          fontSize: '110%'
                        }}
                        data-has-line-height=""
                      >
                        <p style={{ textAlign: 'justify' }}>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    *
                                    <span>
                                      <strong>הצבת </strong>
                                    </span>
                                    <span>
                                      <strong>המיכלים</strong>
                                    </span>
                                    <span>
                                      <strong>
                                        {' '}
                                        בנקודות איסוף מוסכמות במחלקות,
                                        מרפאות&nbsp; ומכונים.
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-7f8ee7"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '477.415px 349.766px 0px',
                  zIndex: 17
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-origin-id="7b013bdb80dbbc7730ef7ad4632eecb6"
                  data-name="group-f2a5f5"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '477.415px 349.766px 0px',
                      zIndex: 18
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '289.415px',
                        top: '244.851px'
                      }}
                      data-origin-id="27e65c45f44abc0a9fb8569d938d1370"
                      data-name="shape-fea029"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(244, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 19 }}
                        data-shape-stroke-color="#cc4125"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-10-1701458397195">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(244, 204, 204)"
                                stroke="#cc4125"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(244, 204, 204)"
                            stroke="#cc4125"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-10-1701458397195)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: '300.915px',
                        top: '275.766px',
                        height: 'auto'
                      }}
                      data-origin-id="085572d5948610632fb1850e7d225dac"
                      data-name="text-22ec0d"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 20,
                          color: 'rgb(0, 0, 0)',
                          lineHeight: '1.04',
                          padding: 20,
                          fontSize: '110%'
                        }}
                        data-has-line-height=""
                      >
                        <p style={{ textAlign: 'justify' }}>
                          <span>
                            <strong>מילוי </strong>
                          </span>
                          <span>
                            <strong>המיכל</strong>
                          </span>
                          <span>
                            <strong>
                              {' '}
                              בחפצים חדים עד 3/4 (שלושת רבעים) מתכולת{' '}
                            </strong>
                          </span>
                          <span>
                            <strong>המיכל.</strong>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-9406c6"
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '438.029px 388.403px 0px',
                  zIndex: 21
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-origin-id="fad96782138dd456b759bf0ef36d84fd"
                  data-name="group-9ec7f5"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '438.029px 388.403px 0px',
                      zIndex: 22
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '250.029px',
                        top: '283.488px'
                      }}
                      data-origin-id="a3d57ba88cb260419577ba42159adb7a"
                      data-name="shape-21b134"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(244, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 23 }}
                        data-shape-stroke-color="#cc4125"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-11-1701458397195">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(244, 204, 204)"
                                stroke="#cc4125"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(244, 204, 204)"
                            stroke="#cc4125"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-11-1701458397195)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: '261.529px',
                        top: '314.404px',
                        height: 'auto'
                      }}
                      data-origin-id="7091cc90328c7338eae64f037988b5fb"
                      data-name="text-a60717"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 24,
                          color: 'rgb(0, 0, 0)',
                          lineHeight: '1.04',
                          padding: 20,
                          fontSize: '110%'
                        }}
                        data-has-line-height=""
                      >
                        <p style={{ textAlign: 'justify' }}>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    *
                                    <span>
                                      <strong>
                                        השלכת מזרקים עם המחטים – יש{' '}
                                      </strong>
                                    </span>
                                    <u>
                                      <span>
                                        <strong>להימנע</strong>
                                      </span>
                                    </u>
                                    <span>
                                      <strong> מהפרדה.</strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '624.547px',
                height: '433.641px',
                left: '167.727px',
                top: '132.946px'
              }}
              data-name="image-c768eb"
            >
              <div
                className="sl-block-content fragment fade-down"
                style={{ zIndex: 25 }}
                data-fragment-index={2}
              >
                <img
                  className=""
                  data-natural-width={723}
                  data-natural-height={502}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967326/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '481.734px',
                left: '239.133px',
                top: '56.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span>
                    <strong>איך נדע כי מטופל בבידוד מגע?</strong>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 180, top: 201 }}
              data-name="text-afc191"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(28, 69, 135)' }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>שלט.</strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>מדבקה אדומה (בבידוד מגע).</strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>סימן בתיק.</strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <strong>CRKP</strong>
                              </span>
                              <span>
                                <strong>- קיבוץ חולים בחדרים ייעודים.</strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '313.721px',
                height: '174.657px',
                left: '323.14px',
                top: '408.646px'
              }}
              data-name="image-3326b4"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={379}
                  data-natural-height={211}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967327/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '481.734px',
                left: '239.133px',
                top: '67.904px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span>עקרונות מניעת זיהומים</span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: 180,
                top: '157.904px'
              }}
              data-name="text-afc191"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(28, 69, 135)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                מניעת התפשטות זיהומים בבית חולים מושגת
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>
                                על יד שליטה באחד או יותר מהגורמים הבאים:
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '456.86px',
                left: '323.14px',
                top: '288.678px'
              }}
              data-name="text-83dade"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(28, 69, 135)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>* מחולל הזיהום: מיקרואורגניזם.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>* דרך העברת המחולל.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <strong>* רגישות המאכסן.</strong>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217,241,250)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '481.734px',
                left: '239.133px',
                top: '67.904px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span>אמצעי זהירות שגרתיים</span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: 180,
                top: '198.028px'
              }}
              data-name="text-afc191"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(28, 69, 135)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              אמצעים בסיסיים במניעת זיהומים, אשר נועדו לצמצם
                              סיכון הדבקה במיקרואורגניזמים שונים ממקורות ידועים
                              ובלתי ידועים.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '806.399px',
                height: '513.871px',
                left: '76.8005px',
                top: '131.3px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d1963f"
            >
              <div
                className="sl-block-content fragment fade-up"
                style={{ zIndex: 14 }}
                data-fragment-index={0}
              >
                <img
                  className=""
                  data-natural-width={725}
                  data-natural-height={462}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967332/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20.0558px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-first"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 47)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-48"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 48)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-49"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 49)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-50"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 50)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-51"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 51)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-52"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 52)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-53"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 53)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-54"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 54)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-55"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 55)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-56"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 56)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-57"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 57)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-58"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 58)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-59"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 59)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-60"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 60)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-61"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 61)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '175.604px',
                left: '747.368px',
                top: '20פס',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <p>
                  <u>
                    <strong>שאלה</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <QuestionSlide
              key="infectionsPrevention-62"
              handleCorrectAnswer={handleCorrectAnswer}
              selectedCourseware={selectedCourseware}
              handleWrongAnswer={handleWrongAnswer}
              titleCustomSx={sharedTitleSx}
              {...getQuestionData(globalCoursewares, selectedCourseware, 62)}
            />
          </section>
          <section
            data-background-color="rgb(244, 204, 204)"
            data-background-image={mainBg}
            data-background-opacity="0.67"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '343.53px',
                left: '308.235px',
                top: '62.4304px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)'
                }}
              >
                <h1>
                  <span>
                    <span>
                      <strong>לסיכום</strong>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 12,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '761.981px',
                left: '119.072px',
                top: '209.726px',
                textAlign: 'justify'
              }}
              data-name="text-59931e"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(12, 52, 61)',
                  textAlign: 'justify'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  שמירה על סביבה הגיינית היא האחריות של כולנו.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  לפי הערכת משרד הבריאות בישראל מתים בכל שנה מעל ל-4000 מטופלים
                  מזיהומים נרכשים בבתי חולים, במקרים רבים מחיידקים העמידים
                  לאנטיביוטיקה.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  זיהומים אלו עלולים לגרום גם לנכות, להארכת משך האשפוז ולאשפוזים
                  חוזרים.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  ניתן למנוע רבים מהמקרים על ידי הקפדה על היגיינת ידיים וסביבה
                  טיפולית הגיינית.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '331.051px',
                height: '720.319px',
                left: '628.949px',
                top: '-0.319px',
                minWidth: 1,
                minHeight: 1
              }}
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-natural-width={1920}
                  data-natural-height={2880}
                  data-crop-x={0}
                  data-crop-y="0.042129"
                  data-crop-width="0.907692"
                  data-crop-height="0.957871"
                  style={{ left: 0, top: '-32px', width: 365, height: 752 }}
                  data-lazy-loaded=""
                  data-src="https://images.unsplash.com/photo-1623638309606-e587fda3fb49?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDN8fGNvbmZldHRpfGVufDB8fHx8MTcwMTQ1ODg2NXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '546.502px',
                left: '43.2811px',
                top: '90.3744px'
              }}
              data-name="text-e1fe96"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ zIndex: 11, fontSize: '121%' }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                אנו מקווים שלומדה זו עזרה להבין ולהמחיש את הנושא
                                המאוד חשוב על מניעת זיהומים ותעזור לכם לאמץ את
                                צורת החשיבה במניעה ובטיפול הנכון בזיהומים.
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="group"
              style={{
                width: '100%',
                left: '-80px',
                top: '350.582px',
                height: 'auto',
                display: 'flex',
                justifyContent: 'center'
              }}
              // data-name="text-f38217"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 20,
                  textAlign: 'center',
                  color: 'rgb(0, 0, 255)',
                  lineHeight: '0.962',
                  letterSpacing: '-0.04em'
                  // fontSize: '97%'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleSetViewToSignCourseware()}
                  sx={{ minWidth: 120, fontSize: '2rem', maxWidth: 400 }}
                  fullWidth
                  // disabled={!didReachTheEnd}
                >
                  חתימה על סיום הלומדה
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default InfectionsPrevention;
