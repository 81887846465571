import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from '@firebase/firestore';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useEffect, useState } from 'react';
import Marquee from 'react-easy-marquee';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { db } from 'src/_firebase/firebase';
import mgdalor from 'src/assets/mgdalor.png';
import bgImage from 'src/assets/waves-white.webp';
import commonConfig from 'src/common/config';
import emailTemplates from 'src/common/emailsTemplates';
import routesConst from 'src/routesConst';
import theme from 'src/theme';

function TalkToUs() {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage.talkToUs'
  });
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState();
  const [emailWasSent, setEmailWasSent] = useState();
  const [numberIsNotValid, setNumberIsNotValid] = useState(false);
  const [partners, setPartners] = useState();
  const navigate = useNavigate();
  const [phoneNumberError, setPhoneNumberSubmitError] = useState();

  const handleSendConsultationEmail = (phoneNumber) => {
    if (phoneNumber && phoneNumber.length > 6 && phoneNumber !== '') {
      // console.log(phoneNumber);
      setLoading(true);
      const message = {
        subject: 'לקוח חדש השאיר פרטים',
        html: emailTemplates.newCustomerPhoneNumber(phoneNumber)
      };
      const mailRef = collection(db, 'mail');
      addDoc(mailRef, {
        to: commonConfig.supportMail,
        from: commonConfig.ticketsMail,
        message
      })
        .then(() => {
          setEmailWasSent('success');
          setPhone('');
        })
        .catch((err) => {
          console.log('err while sending email', err);
          setEmailWasSent('fail');
        })
        .finally(() => setLoading(false));
    } else {
      setPhoneNumberSubmitError(true);
    }
  };

  const retrieveAllPartners = async () => {
    const partnershipRef = collection(db, 'partnerships');
    const q = query(
      partnershipRef,
      where('partner', '==', true),
      where('org_status', '==', 'approved')
    );
    getDocs(q).then((activePartners) => {
      if (!activePartners.empty) {
        const partnersToRetrieve = activePartners.docs.map(
          async (activePartner) => {
            const usersCollectionRef = doc(db, `users/${activePartner.id}`);
            return getDoc(usersCollectionRef)
              .then((orgDoc) => {
                if (orgDoc.exists()) {
                  return { ...orgDoc.data(), ...activePartner.data() };
                }
              })
              .catch((err) => {
                console.log(err);
                return {};
              });
          }
        );
        Promise.all(partnersToRetrieve)
          .then((partnerships) => {
            // console.log(partnerships);
            setPartners([...partnerships, ...partnerships, ...partnerships]);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  useEffect(() => {
    retrieveAllPartners();
  }, []);
  return (
    <Box
      component="section"
      sx={{
        mt: matchMdDown ? 36 : 6,
        width: '100%',
        mb: 12,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        px: 0
      }}
    >
      <Card
        sx={{
          borderRadius: '50%',
          // border: `2px solid ${theme.palette.warning.light}`,
          // width: 'fit-content',
          marginLeft: 'auto',
          height: '120px',
          width: '120px',
          justifyContent: 'center',
          rotate: '-25deg',
          '&:hover': {
            boxShadow: theme.shadows[12]
          },
          translate: '+15% 50%',
          zIndex: 9,
          position: 'relative',
          userSelect: 'none'
        }}
        className="freeConsultCardWrap"
        elevation={8}
      >
        <CardContent
          className="freeConsultCard"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.default
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              color: theme.palette.warning.dark,
              fontSize: '1.5rem'
            }}
            fontWeight="bold"
          >
            {t('freeConsultation')}
          </Typography>
        </CardContent>
      </Card>
      <Box
        position="relative"
        sx={{
          overflow: 'hidden',
          backgroundColor: theme.palette.primary.dark
          // borderRadius: 2
        }}
      >
        <Box
          component="img"
          src={bgImage}
          loading="lazy"
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          height="120vh"
          zIndex={1}
          sx={{
            filter: 'opacity(0.4)'
          }}
        />
        <Container sx={{ position: 'relative', zIndex: 2, py: 12 }}>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent="center"
            mx="auto"
            textAlign="center"
            sx={{
              flexDirection: 'column',
              color: theme.palette.primary.contrastText,
              width: 'fit-content',
              borderRadius: 2,
              p: 3,
              backdropFilter: 'blur(5px)',
              boxShadow: theme.shadows[10]
            }}
          >
            <Typography sx={{ fontWeight: 800 }} variant="h1" mb={1}>
              {`${t('findingAHome')} ${t('thatIsRight')}`}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 600 }} mb={6}>
              {t('notSureYouFound')}
              <br />
              {t('tryContactingUs')}
            </Typography>
            <Grid
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
              spacing={2}
            >
              <Grid item xs={10} md={6}>
                <TextField
                  InputProps={{
                    sx: {
                      bgcolor: theme.palette.background.default
                    }
                  }}
                  sx={{ mb: 2 }}
                  placeholder={t('phone')}
                  fullWidth
                  value={phone}
                  id="talk-to-us-field"
                  type="tel"
                  tabIndex={-1}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  onChange={(e) => {
                    const replaced = e.target.value.replace(/[^\d]+/g, '');
                    setPhone(replaced);
                    setPhoneNumberSubmitError();
                  }}
                />
                {phoneNumberError && (
                  <Alert severity="error">{t('phoneIsNotValid')}</Alert>
                )}
                {emailWasSent === 'success' ? (
                  <Alert severity="success">{t('sentSuccessfully')}</Alert>
                ) : emailWasSent === 'fail' ? (
                  <Alert severity="warning">{t('tryAgainPlease')}</Alert>
                ) : null}
              </Grid>
              <Grid
                sx={{
                  minWidth: 'fit-content',
                  alignItems: 'center',
                  display: 'flex',
                  pt: 0,
                  height: '100%'
                }}
                item
                xs={5}
                md={3}
              >
                {loading ? (
                  <Button
                    endIcon={loading && <CircularProgress size="small" />}
                    variant="contained"
                    color="info"
                    size="large"
                    component="a"
                    onClick={() => handleSendConsultationEmail(phone)}
                    disabled={numberIsNotValid}
                    sx={{ width: 'fit-content', mx: 'auto' }}
                  >
                    {t('sending')}
                    <CircularProgress sx={{ fontSize: '0.8rem' }} />
                  </Button>
                ) : (
                  <Button
                    endIcon={loading && <CircularProgress size="small" />}
                    variant="contained"
                    color="info"
                    size="large"
                    component="a"
                    onClick={() => handleSendConsultationEmail(phone)}
                    disabled={numberIsNotValid}
                    sx={{ width: 'fit-content', mx: 'auto' }}
                  >
                    {t('sendNow')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {partners && partners.length > 0 ? (
        <Grid container item xs={12} mx="auto">
          <Box
            textAlign="center"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              gap: 4
            }}
          >
            <Typography
              sx={{ width: '50%', textAlign: 'center' }}
              variant="h3"
              mt={6}
              mb={3}
            >
              {t('someOfOurPartners')}
            </Typography>
            <Grid container spacing={3} justifyContent="center">
              <Marquee
                gradient={Boolean(false)}
                duration={25000}
                pauseOnHover
                style={{ gap: '12px', height: '400px' }}
                height="400px"
              >
                {partners.map((partner) => (
                  <Grid
                    sx={{ m: 2, height: '350px', width: '250px' }}
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    lg={5}
                  >
                    <Card
                      elevation={12}
                      sx={{
                        height: '100%',
                        width: '100%',
                        borderRadius: '30px 0'
                      }}
                    >
                      <CardMedia
                        sx={{ height: 120, backgroundSize: 'contain' }}
                        component="image"
                        image={partner.avatar || mgdalor}
                        alt={partner.org_name}
                      />
                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          cursor: 'pointer',
                          height: 'calc(100% - 120px)',
                          '&:hover': {
                            filter: 'drop-shadow(0 0 20px rgba(0,0,0,0.35))'
                          }
                        }}
                        onClick={() => {
                          navigate(
                            `${routesConst.org}/${partner.org_name}&uid=${partner.org_id}`
                          );
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            '&::after': {
                              border: `3px solid ${theme.palette.warning.dark}`
                            }
                          }}
                          variant="h3"
                        >
                          {partner.org_name}
                          <Divider
                            sx={{
                              width: '50px',
                              borderColor: theme.palette.warning.light,
                              height: '20px',
                              margin: 'auto',
                              borderBottomWidth: 'thick'
                            }}
                          />
                        </Typography>
                        <Button
                          sx={{ width: '100%' }}
                          color="warning"
                          variant="contained"
                          LinkComponent="a"
                          target="_blank"
                          href={`${routesConst.org}/${partner.org_name}&uid=${partner.org_id}`}
                        >
                          לפרטים &gt;&gt;
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Marquee>
            </Grid>
          </Box>
        </Grid>
      ) : null}
    </Box>
  );
}

export default TalkToUs;
