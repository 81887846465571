import { addDoc, collection } from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';
import commonConfig from 'src/common/config';
import emailTemplates from 'src/common/emailsTemplates';

const sendNewAssessmentRequestToMgdalor = async (
  providerValues,
  pathToRequest,
  requestValues
) => {
  const mailRef = collection(db, 'mail');

  const message = {
    subject: 'פנייה חדשה להערכה רפואית',
    html: emailTemplates.newAssessmentRequestEmailToMgdalor(
      providerValues,
      pathToRequest,
      requestValues
    )
  };
  addDoc(mailRef, {
    to: commonConfig.supportMail,
    from: 'הערכות רפואיות <support@mgdalor.com>',
    message
  }).catch((err) => {
    console.log('err while sendNewAssessmentRequestToMgdalor', err);
  });
};

export default sendNewAssessmentRequestToMgdalor;
