import { useRef } from 'react';

const useHorizontalScrollEvent = (callback) => {
  const positionRef = useRef(0);
  return (e) => {
    const x = e.currentTarget.scrollLeft;
    if (x !== positionRef.current) {
      positionRef.current = x;
      callback(e);
    }
  };
};

export default useHorizontalScrollEvent;
