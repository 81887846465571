import CyberSecurity from 'src/components/Coursewares/presentations/Cyber/CyberSecurity';
import FireAndElectericitySafety from 'src/components/Coursewares/presentations/FireAndElectericitySafety/FireAndElectericitySafety';
import MedicationTherapyManagement from 'src/components/Coursewares/presentations/MedicationTherapyManagement/MedicationTherapyManagement';
import PainCourseware from 'src/components/Coursewares/presentations/Pain/PainCourseware';
import PalliativeCare from 'src/components/Coursewares/presentations/PalliativeCare/PalliativeCare';
import PreventingConstipation from 'src/components/Coursewares/presentations/PreventingConstipation/PreventingConstipation';
import InfectionsPrevention from 'src/components/Coursewares/presentations/PreventingInfections/PreventingInfections';
import SafetyAndEmergency from 'src/components/Coursewares/presentations/SafetyAndEmergency/SafetyAndEmergency';
import SexualHarassment from 'src/components/Coursewares/presentations/SexualHarassment/SexualHarassment';
import TeethAndMouthHealth from 'src/components/Coursewares/presentations/TeethAndMouthHealth/TeethAndMouthHealth';
import Violence from 'src/components/Coursewares/presentations/Violence/Violence';
import Wounds from 'src/components/Coursewares/presentations/Wounds/Wounds';
import questions from './questions';

// const MedicationTherapyManagement = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/MedicationTherapyManagement/MedicationTherapyManagement'
//   )
// );
// const FireAndElectericitySafety = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/FireAndElectericitySafety/FireAndElectericitySafety'
//   )
// );
// const PreventingConstipation = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/PreventingConstipation/PreventingConstipation'
//   )
// );
// const InfectionsPrevention = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/PreventingInfections/PreventingInfections'
//   )
// );
// const TeethAndMouthHealth = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/TeethAndMouthHealth/TeethAndMouthHealth'
//   )
// );
// const SafetyAndEmergency = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/SafetyAndEmergency/SafetyAndEmergency'
//   )
// );
// const SexualHarassment = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/SexualHarassment/SexualHarassment'
//   )
// );
// const PalliativeCare = lazy(() =>
//   import(
//     'src/components/Coursewares/presentations/PalliativeCare/PalliativeCare'
//   )
// );
// const PainCourseware = lazy(() =>
//   import('src/components/Coursewares/presentations/Pain/PainCourseware')
// );
// const CyberSecurity = lazy(() =>
//   import('src/components/Coursewares/presentations/Cyber/CyberSecurity')
// );
// const Violence = lazy(() =>
//   import('src/components/Coursewares/presentations/Violence/Violence')
// );
// const Wounds = lazy(() =>
//   import('src/components/Coursewares/presentations/Wounds/Wounds')
// );
const globalCoursewaresSlides = [
  {
    courseware_id: 'TpaPcx55KVhKriT33dL5',
    courseware_name: 'cyber',
    title: 'cyber',
    titleLabel: 'coursewares.cyber',
    slides: CyberSecurity,
    order: 1,
    questionsSlides: questions.cyber,
    duration: 1
  },
  {
    courseware_id: 'BEqeE7CJ5omgqX93A3oU',
    courseware_name: 'violence',
    title: 'violence',
    titleLabel: 'coursewares.violence',
    slides: Violence,
    order: 2,
    duration: 1
  },
  {
    courseware_id: '0nzqiLbxux6WOilvNRnV',
    courseware_name: 'sexualHarassment',
    title: 'sexualHarassment',
    titleLabel: 'coursewares.sexualHarassment',
    slides: SexualHarassment,
    order: 3,
    questionsSlides: questions.sexualHarassment,
    duration: 1
  },
  {
    courseware_id: 'LQJWIG3RqN2k2CT6ztMI',
    courseware_name: 'fireAndElectericitySafety',
    title: 'fireAndElectericitySafety',
    titleLabel: 'coursewares.fireAndElectericitySafety',
    slides: FireAndElectericitySafety,
    order: 4,
    questionsSlides: questions.fireAndElectericitySafety,
    duration: 2.5
  },
  {
    courseware_id: 'bvC7m00c2WLXUy2uS0d2',
    courseware_name: 'safetyAndEmergency',
    title: 'safetyAndEmergency',
    titleLabel: 'coursewares.safetyAndEmergency',
    slides: SafetyAndEmergency,
    order: 5,
    questionsSlides: questions.safetyAndEmergency,
    duration: 2.5
  },
  {
    courseware_id: 'CQpoHAAbXW5kaDRCTNF1',
    courseware_name: 'medicationTherapyManagement',
    title: 'medicationTherapyManagement',
    titleLabel: 'coursewares.medicationTherapyManagement',
    slides: MedicationTherapyManagement,
    order: 6,
    questionsSlides: questions.medicationTherapyManagement,
    duration: 2.5
  },
  {
    courseware_id: 'wjfeG1IvyWufI97I7xUr',
    courseware_name: 'wounds',
    title: 'wounds',
    titleLabel: 'coursewares.wounds',
    slides: Wounds,
    order: 7,
    questionsSlides: questions.wounds,
    duration: 2
  },
  {
    courseware_id: 'LTXbdwPMToXXEuAQ5X3A',
    courseware_name: 'preventingConstipation',
    title: 'preventingConstipation',
    titleLabel: 'coursewares.preventingConstipation',
    slides: PreventingConstipation,
    order: 8,
    questionsSlides: questions.preventingConstipation,
    duration: 2
  },
  {
    courseware_id: '27fXx9tduPMZDHPfc5XM',
    courseware_name: 'pain',
    title: 'pain',
    titleLabel: 'coursewares.pain',
    slides: PainCourseware,
    order: 9,
    questionsSlides: questions.pain,
    duration: 2.5
  },
  {
    courseware_id: 'qmAYJO9lwFoDRVPrR1ji',
    courseware_name: 'teethAndMouthHealth',
    title: 'teethAndMouthHealth',
    titleLabel: 'coursewares.teethAndMouthHealth',
    slides: TeethAndMouthHealth,
    order: 10,
    questionsSlides: questions.teethAndMouthHealth,
    duration: 2
  },
  {
    courseware_id: 'pvRPkexG5aGgVCviaIiv',
    courseware_name: 'infectionsPrevention',
    title: 'infectionsPrevention',
    titleLabel: 'coursewares.infectionsPrevention',
    slides: InfectionsPrevention,
    order: 11,
    questionsSlides: questions.infectionsPrevention,
    duration: 3
  },
  {
    courseware_id: '2c9Zf42jN2E8mmgWtyD6',
    courseware_name: 'palliativeCare',
    title: 'palliativeCare',
    titleLabel: 'coursewares.palliativeCare',
    slides: PalliativeCare,
    order: 12,
    questionsSlides: questions.palliativeCare,
    duration: 3
  }
];

export default globalCoursewaresSlides;
