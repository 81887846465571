import { CallOutlined, WhatsApp } from '@mui/icons-material';
import {
  Box,
  Button,
  CardMedia,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material/';
import { useTranslation } from 'react-i18next';
import leaves from 'src/assets/home/leaves.svg';
import commonConfig from 'src/common/config';
import './home.css';

function HowDoesItWork() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage.howDoesItWorkSection'
  });
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="section"
      pt={6}
      // pb={12}
      mt={8}
      className="HowDoesItWorkSection"
      sx={{
        position: 'relative',
        zIndex: 1
      }}
    >
      {/* <Container> */}
      <CardMedia
        // component="img"
        alt="shapes"
        component="img"
        loading="lazy"
        image={leaves}
        sx={{
          position: 'absolute',
          width: '200px',
          height: '200px',
          // mx: 'auto',
          zIndex: 0,
          // top: '-30px',
          top: 0,
          left: 0,
          backgroundPositionX: matchDownMd ? '-60px' : '-20px',
          backgroundPositionY: '-60px',
          transform: 'rotate(90deg)',
          // filter: 'saturate(0)'
          filter: 'saturate(0) sepia(0.9) invert(0.7) contrast(3.3)',
          display: matchDownMd ? 'none' : 'initial'
          // backgroundBlendMode: 'soft-light'
        }}
      />
      <Grid
        sx={{ position: 'relative', zIndex: 2 }}
        container
        item
        xs={12}
        spacing={3}
        alignItems="center"
      >
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
            pb: 2,
            '&::after': {
              position: 'absolute',
              height: '4px',
              bgcolor: theme.palette.primary.dark,
              width: '60px',
              // marginLeft: '-45px',
              // left: '50%',
              mt: matchDownMd ? -12 : -4,
              content: "''"
            }
          }}
          item
          xs={12}
        >
          <Typography
            variant="h1"
            // className="subTitle"
            sx={{
              display: 'flex'
              // px: 0
            }}
          >
            {t('howDoesItWork')}
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 3, fontFamily: 'inherit', fontSize: '1.3rem', px: 3 }}
          >
            {t('howDoesItWorkDescription')}
            <br />
            {t('howDoesItWorkSecondaryLineDescription')}
          </Typography>
          <Stack direction="row" justifyContent="center">
            <Button
              LinkComponent="a"
              href={`https://api.whatsapp.com/send/?phone=${
                commonConfig.supportPhone
              }&text=${encodeURIComponent(
                'שלום, אשמח לקבל עזרה והמלצות אודות חיפוש בית אבות ודיור מוגן מתאים ומקצועי'
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsApp color="success" />
            </Button>
            <Button
              LinkComponent="a"
              href={`tel:+${commonConfig.supportPhone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CallOutlined />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HowDoesItWork;
