import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ServicesOfServiceProviderAutocomplete from './ServicesOfServiceProviderAutocomplete';

const PersonalAssessmentRequestDescription = ({
  handleChange,
  requestValues,
  setRequestValues
}) => {
  PersonalAssessmentRequestDescription.propTypes = {
    handleChange: PropTypes.func,
    requestValues: PropTypes.object,
    setRequestValues: PropTypes.func
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });
  return (
    <>
      <Grid item xs={12}>
        <ServicesOfServiceProviderAutocomplete
          setValues={setRequestValues}
          values={requestValues}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t('description')}
          name="description"
          placeholder="נא לתאר לנו את מצב המטופל ולהתייחס לתפקוד בהיבטים השונים (תפקודי, קוגניטיבי...)"
          required
          multiline
          id="personalAssessmentRequestDescription-description"
          rows={3}
          onChange={(e) => {
            handleChange(e);
          }}
          value={requestValues.description}
          variant="outlined"
        />
      </Grid>
    </>
  );
};

export default PersonalAssessmentRequestDescription;
