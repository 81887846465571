import {
  BadgeOutlined,
  CloudSyncOutlined,
  DriveFolderUploadOutlined
} from '@mui/icons-material';
import { Grid, Icon } from '@mui/material';
import './mgdalorSystemFeaturesCards.scss';

const MgdalorSystemFeaturesCards = () => (
  <div id="mgdalorSystemFeaturesCards">
    <section>
      <h2
        style={{
          paddingBottom: '12px'
          // color: '#8e44ec'
        }}
        className="radial line-2"
      >
        הכל תמיד
        <br />
        סביב הארגון שלך
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <div className="card">
            <div
              className="card-inner"
              style={{
                '--clr': '#000'
              }}
            >
              <div className="box">
                <div className="imgBox">
                  <img
                    alt="נהלים"
                    // src={org}
                    src="https://prismic-main.cdn.prismic.io/prismic-main/0979bd6c-1bee-4342-97d5-25cb6b97784f_tailwind-component-library.svg?fit=max&w=640"
                    // src="https://cdn.prod.website-files.com/6285e77eaf03d3b5e63ee110/6294c3fc9bef0b35eb540aec_Predicta%20mesh-p-800.jpeg"
                  />
                </div>
                <div className="icon">
                  <a className="iconBox" href="#">
                    <Icon>
                      <BadgeOutlined />
                    </Icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <h3>נהלים</h3>
            <p>
              Fill out the form and the algorithm will offer the right team of
              experts
            </p>
            <ul>
              <li
                className="branding"
                style={{
                  '--clr-tag': '#d3b19a'
                }}
              >
                branding
              </li>
              <li
                className="packaging"
                style={{
                  '--clr-tag': '#70b3b1'
                }}
              >
                packaging
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className="card first">
            <div
              className="card-inner"
              style={{
                '--clr': '#fff'
              }}
            >
              <div className="box">
                <div className="imgBox">
                  <img
                    alt="מחלקות"
                    // src="https://anymal.tv/images/posts/cspq/anymal_site-web_portfolio_cspq_0001_3_min.png"
                    // src="https://anymal.tv/images/posts/cspq/anymal_site-web_portfolio_cspq_0001_5_min.jpg"
                    src="https://prismic-main.cdn.prismic.io/prismic-main/86585d32-2dee-4088-a44a-40a553200546_atomic-design-system-example-tutorial.svg?fit=max&w=640"
                  />
                </div>
                <div className="icon">
                  <a className="iconBox" href="#">
                    <Icon fontSize="large">
                      <DriveFolderUploadOutlined />
                    </Icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <h3>מחלקות</h3>
            <p>
              Fill out the form and the algorithm will offer the right team of
              experts
            </p>
            <ul>
              <li
                className="branding"
                style={{
                  '--clr-tag': '#d3b19a'
                }}
              >
                branding
              </li>
              <li
                className="marketing"
                style={{
                  '--clr-tag': '#d05fa2'
                }}
              >
                marketing
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className="card">
            <div
              className="card-inner"
              style={{
                '--clr': '#fff'
              }}
            >
              <div className="box">
                <div className="imgBox">
                  <img
                    alt="סמכויות"
                    // src="https://imagedelivery.net/qc7VvyphMGWFiPVvTbB-ww/swapps.com/2019/01/bring-light-to-your-sites-with-google-lighthouse.jpg/w=1536,h=864"
                    // src="https://anymal.tv/images/posts/cspq/anymal_site-web_portfolio_cspq_0001_6_min.jpg"
                    // src="https://cdn.prod.website-files.com/6285e77eaf03d3b5e63ee110/62fba17cdbb3098eb68be874_atidot%20home.jpg"
                    // src="https://static.vecteezy.com/system/resources/previews/020/562/345/non_2x/sunset-with-retro-futuristic-wireframe-and-color-gradient-vector.jpg"
                    // src="https://img.freepik.com/premium-photo/poster-lighthouse-with-sunset-background_1144471-5520.jpg?w=740"
                    // src={futuristic}
                    src="https://prismic-main.cdn.prismic.io/prismic-main/f00737eb-43b8-4040-9203-a01a780295be_tailwind-vs-bootstrap.svg?fit=max&w=640"
                  />
                </div>
                <div className="icon">
                  <a className="iconBox" href="#">
                    <Icon fontSize="2rem">
                      <CloudSyncOutlined />
                    </Icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <h3>סמכויות</h3>
            <p>
              Fill out the form and the algorithm will offer the right team of
              experts
            </p>
            <ul>
              <li
                className="branding"
                style={{
                  '--clr-tag': '#d3b19a'
                }}
              >
                branding
              </li>
              <li
                className="packaging"
                style={{
                  '--clr-tag': '#70b3b1'
                }}
              >
                packaging
              </li>
              <li
                className="marketing"
                style={{
                  '--clr-tag': '#d05fa2'
                }}
              >
                marketing
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </section>
  </div>
);

export default MgdalorSystemFeaturesCards;
