import {
  ArrowCircleLeftOutlined,
  EditOutlined,
  HistoryEduOutlined,
  Person2Outlined,
  QuestionAnswerOutlined,
  SafetyCheckOutlined,
  StarOutlineOutlined,
  VolunteerActivismOutlined,
  WorkOutlineOutlined,
  WorkspacePremiumOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Grow,
  Icon,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
import personalAccImg from 'src/assets/usersPricingComponent/tab-3.png';
import orgAccImg from 'src/assets/usersPricingComponent/tab-4.png';
import familyAccImg from 'src/assets/usersPricingComponent/tab-5.webp';
import routesConst from 'src/routesConst';
import theme from 'src/theme';

const plans = [
  {
    id: 'personal-use',
    label: 'חשבון פרטי',
    cardTitle: 'לשימושכם הפרטי',
    cardSubtitle: 'אתה לא חייב להיות חלק מארגון כדי להתפתח',
    features: [
      'קורות חיים דיגיטליים',
      'לומדות כדי שתתפתח באופן עצמאי',
      'מבחר רב של משרות והגשת מועמדות בקלות'
    ],
    haveBtn: true,
    btn: (
      <Button
        LinkComponent="a"
        href={`${routesConst.register}?type=personal`}
        variant="contained"
        fullWidth
        sx={{ maxWidth: 200 }}
      >
        הצטרף עכשיו
      </Button>
    ),
    img: personalAccImg,
    sideIcons: [
      {
        id: 'Person2Outlined',
        icon: <Person2Outlined />
      },
      {
        id: 'SafetyCheckOutlined',
        icon: <SafetyCheckOutlined />
      },
      {
        id: 'HistoryEduOutlined',
        icon: <HistoryEduOutlined />
      }
    ]
  },
  {
    id: 'org-use',
    label: 'חשבון ארגוני',
    cardTitle: 'הכל במקום אחד',
    cardSubtitle: 'לנהל את כל הארגון, דרך מערכת אחת',
    features: [
      'לנהל את העובדים',
      'מעקב אחר נהלים וחתימות',
      'סמכויות בדיוק כמו שאתם מחליטים'
    ],
    haveBtn: true,
    btn: (
      <Button
        LinkComponent="a"
        href={`${routesConst.register}?type=org`}
        variant="contained"
        fullWidth
        sx={{ maxWidth: 200 }}
      >
        הקמת ארגון
      </Button>
    ),
    img: orgAccImg,
    sideIcons: [
      {
        id: 'WorkOutlineOutlined',
        icon: <WorkOutlineOutlined />
      },
      {
        id: 'EditOutlined',
        icon: <EditOutlined />
      },
      {
        id: 'WorkspacePremiumOutlined',
        icon: <WorkspacePremiumOutlined />
      }
    ]
  },
  {
    id: 'family-use',
    label: 'חשבון משפחות',
    cardTitle: 'שקיפות מלאה',
    cardSubtitle: 'תתעדכנו לגבי הפנייה שלכם בבתי האבות השונים',
    features: [
      'תקבלו ייעוץ מלא וחינמי',
      'מעקב אחר סטטוס הפנייה של האהוב עליכם',
      'מדרגים את בתי האבות שביקרתם בהם באופן אנונימי'
    ],
    haveBtn: true,
    btn: (
      <Button
        LinkComponent="a"
        href={routesConst.familyLogin}
        variant="contained"
        fullWidth
        sx={{ maxWidth: 200 }}
      >
        כניסה לאזור
      </Button>
    ),
    img: familyAccImg,
    sideIcons: [
      {
        id: 'QuestionAnswerOutlined',
        icon: <QuestionAnswerOutlined />
      },
      {
        id: 'VolunteerActivismOutlined',
        icon: <VolunteerActivismOutlined />
      },
      {
        id: 'StarsOutlined',
        icon: <StarOutlineOutlined />
      }
    ]
  }
];

const UsersPricingComponent = () => {
  // console.log(1);
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [planSelected, setPlanSelected] = useState(plans[0]);
  return (
    <Container sx={{ mt: 4, mb: 6 }} maxWidth="xl">
      <Grid sx={{ justifyContent: 'center', alignItems: 'center' }} container>
        <Grid item xs={12}>
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Chip
              label="נהנים ממגדלור"
              sx={{ width: 120, fontSize: '1.2rem' }}
            />
            <Typography sx={{ fontSize: '2rem' }}>מה אנחנו מציעים?</Typography>
          </Stack>
        </Grid>
        <Grid
          sx={{ justifyContent: 'center', alignItems: 'center', mx: 1 }}
          container
          item
          xs={12}
          sm={12}
          md={2}
        >
          {plans.map((plan) => (
            <Grid
              sx={{
                px: 1,
                my: 1,
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center'
              }}
              key={plan.id}
              item
              xs={12}
              sm={4}
              md={12}
            >
              <Card
                sx={{
                  minWidth: matchDownSm ? '100%' : 120,
                  width: '100%',
                  maxWidth: 220,
                  background:
                    planSelected.id === plan.id
                      ? 'linear-gradient(195deg, #49a3f1, #1A73E8)'
                      : alpha(theme.palette.info.light, 0.15),
                  borderRadius: 20,
                  cursor: 'pointer',
                  px: 1,
                  '&:hover': {
                    boxShadow: theme.shadows[2]
                  },
                  backgroundPosition:
                    planSelected.id === plan.id ? 'center' : 'left',
                  transition: 'all 0.3s'
                }}
                elevation={0}
                onClick={() => setPlanSelected(plan)}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Typography
                    sx={{
                      // fontSize: 'inherit',
                      color:
                        planSelected.id === plan.id
                          ? theme.palette.primary.contrastText
                          : 'inherit',
                      fontSize: '1.2rem'
                    }}
                  >
                    {plan.label}
                  </Typography>
                  {planSelected.id === plan.id && (
                    <Icon
                      sx={{
                        position: 'absolute',
                        right: 0,
                        color: theme.palette.primary.contrastText,
                        transform: matchDownMd && 'rotate(-45deg)'
                      }}
                    >
                      <ArrowCircleLeftOutlined />
                    </Icon>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid
          sx={{
            // justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            mx: 2,
            py: 2
          }}
          container
          item
          xs={12}
          sm={12}
          md={9}
          lg={7}
        >
          <Grid
            key="img-grid"
            sx={{
              zIndex: 2,
              position: 'relative',
              justifyContent: matchDownSm ? 'center' : 'flex-start',
              transform: matchDownSm ? 'translateX(20px)' : 'translateX(-20px)'
              // maxWidth: 'fit-content !important'
            }}
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Box
              key="img-bg-circle"
              sx={{
                width: '100%',
                height: '100%',
                maxWidth: matchDownSm ? 250 : 310,
                aspectRatio: '1',
                position: 'absolute',
                zIndex: 0,
                left: matchDownSm ? '4vw' : 0,
                top: 0,
                bottom: 0,
                background: '#fef0cd',
                borderRadius: '50%'
              }}
            />
            <img
              alt={planSelected.label}
              src={planSelected.img}
              loading={planSelected.id === 'personal-use' ? 'eager' : 'lazy'}
              style={{
                height: '100%',
                width: '100%',
                maxWidth: 300,
                aspectRatio: '1 / 1',
                zIndex: 0,
                position: 'relative',
                objectFit: 'contain'
              }}
            />
            {!matchDownSm &&
              planSelected.sideIcons &&
              planSelected.sideIcons.length > 0 && (
                <Box
                  key="3d icons"
                  sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    zIndex: 1,
                    left: matchDownMd ? 0 : '-30px',
                    top: 0,
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  {planSelected.sideIcons.map((sideIcon, i) => (
                    <Card
                      elevation={18}
                      key={sideIcon.id}
                      sx={{
                        p: 1,
                        borderRadius: '50%',
                        backgroundColor: theme.palette.background.default,
                        width: 64,
                        height: 64,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        transform:
                          i === 0
                            ? 'translate(-40px, -15px)'
                            : i === 1
                            ? 'translateX(-15px)'
                            : 'translate(-40px, 15px)'
                      }}
                    >
                      <Grow key={`grow-${sideIcon.id}`} in>
                        <Icon
                          sx={{
                            borderRadius: '50%',
                            boxShadow: theme.shadows[4],
                            backgroundColor: theme.palette.background.default,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          color="primary"
                        >
                          {sideIcon.icon}
                        </Icon>
                      </Grow>
                    </Card>
                  ))}
                </Box>
              )}
          </Grid>
          <Grid
            sx={{ zIndex: 2, position: 'relative' }}
            spacing={2}
            container
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Stack sx={{ width: '100%', ml: 2, mt: matchDownSm && 2 }}>
              <Stack sx={{ textAlign: matchDownSm && 'center' }}>
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 600 }}>
                  {planSelected.cardTitle}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    maxWidth: 320
                  }}
                >
                  {planSelected.cardSubtitle}
                </Typography>
              </Stack>
              <List
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  alignContent: 'flex-start',
                  flexDirection: 'column',
                  listStyle: 'initial'
                }}
              >
                {planSelected.features.map((item) => (
                  <ListItem
                    sx={{
                      display: 'list-item'
                    }}
                    key={item}
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </Stack>
            {planSelected.haveBtn && (
              <Grid
                sx={{ justifyContent: 'center', display: 'flex' }}
                item
                xs={12}
              >
                {planSelected.btn}
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: matchDownSm ? '3rem' : 300,
              backgroundColor: alpha(theme.palette.info.light, 0.1),
              position: 'absolute',
              zIndex: 0,
              top: 0
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UsersPricingComponent;
