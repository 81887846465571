import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import retrieveReports from 'src/utils/retrieveReports';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const reportsContext = createContext();

export const ReportsProvider = ({ children }) => {
  ReportsProvider.propTypes = {
    children: PropTypes.any
  };
  const [reports, setReports] = useState();
  const { currentUser } = useCurrentUserContext();

  const reportsData = useMemo(
    () => ({ reports, setReports }),
    [reports, setReports]
  );

  const handleRetrieveReports = async () =>
    retrieveReports(currentUser.user_doc_id)
      .then((res) => res)
      .catch((err) => {
        console.log('err while retrieving reports', err);
        return [];
      });

  useEffect(async () => {
    if (currentUser && currentUser.type === 'org') {
      handleRetrieveReports()
        .then((res) => setReports(res))
        .catch((err) => {
          console.log(err);
          setReports([]);
        });
    }
  }, [currentUser]);

  return (
    <reportsContext.Provider value={reportsData}>
      {children}
    </reportsContext.Provider>
  );
};
export const useReports = () => useContext(reportsContext);
