import { Avatar } from '@mui/material';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropType from 'prop-types';
import mgdalorPng from 'src/assets/mgdalor.png';
import './orgPin.scss';

const OrgAvatarPin = ({ src, isSelected }) => {
  OrgAvatarPin.propTypes = {
    src: PropType.any,
    isSelected: PropType.bool
  };
  return (
    <div
      style={{
        transform: isSelected && 'scale(1.5)',
        transition: 'all 0.5s'
      }}
      className="tooltip"
    >
      {/* <Tooltip title={`distance from your search ${src}`}> */}
      <Avatar
        className="org-avatar"
        // sizes="50px"
        variant="square"
        src={src || mgdalorPng}
        sx={{
          py: 1
        }}
      />
      {/* </Tooltip> */}
    </div>
  );
};

export default OrgAvatarPin;
