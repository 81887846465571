import moment from 'moment';

const checkIfSignedBeforeChange = (last_modified, signed_at) => {
  // console.log(last_modified);
  if (
    last_modified &&
    last_modified.seconds &&
    signed_at &&
    signed_at.seconds
  ) {
    const changeDate = moment(last_modified.seconds * 1000).isAfter(
      moment(signed_at.seconds * 1000)
    );
    return changeDate;
  }
  if (!last_modified && signed_at && signed_at.seconds) {
    return false;
  }
  if (last_modified && last_modified.seconds && !signed_at) {
    return false;
  }
};

export default checkIfSignedBeforeChange;
