const nursesDefaultOnboarding = [
  {
    id: 'major-task305',
    major_task: 'אדמניסטרציה',
    responsibilty_of: 'משאבי אנוש',
    sub_tasks: [
      {
        description: 'חוזה, סודיות, כרטיס עובד, כרטיס החתמת שעון',
        responsibiltyOf: 'משאבי אנוש',
        id: 'sub-task664'
      }
    ]
  },
  {
    id: 'major-task301',
    major_task: 'הכרת המבנה',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description:
          'סיור בבניין: דלפק, שעון נוכחות, לוח כיבוי אש, נטרול אזעקה אחורית, מטבח, חדר כביסה, מלתחות, מכון פיזיותרפיה, בית מרקחת',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task605'
      },
      {
        description:
          'סיור במחלקה: תחנת אחיות, שירותים לצוות, לאורחים, חדרי דיירים, מחסנים',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task604'
      }
    ]
  },
  {
    id: 'major-task302',
    major_task: 'תיאום ציפיות',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'ימי ושעות עבודה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task606'
      },
      {
        description: 'לבוש',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task607'
      }
    ]
  },
  {
    id: 'major-task303',
    major_task: 'דרכי תקשורת',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description:
          'טלפון אחראי מחלקה, מחלקה, דלפק, ודרכי קשר עם מחלקות אחרות',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task608'
      },
      {
        description: "השתתפות בישיבות צוות ובחת''ש",
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task609'
      }
    ]
  },
  {
    id: 'major-task311',
    major_task: 'נהלי עבודה',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'נוהל סידור עבודה ובקשות, נוהל החתמת שעון',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task610'
      },
      {
        description: 'נוהל קבלת משמרת',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task611'
      },
      {
        description: 'נוהל העברת משמרת',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task612'
      },
      {
        description: 'נוהל סודיות רפואית',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task613'
      },
      {
        description: 'נוהל שפה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task614'
      },
      {
        description: 'נוהל ענידת תג שם והופעה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task615'
      },
      {
        description: 'נוהל דיווח',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task616'
      },
      {
        description: 'נוהל שטיפת ידיים ומניעת זיהומים',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task617'
      },
      {
        description: 'נוהל שימוש והיענות לפעמונים',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task618'
      },
      {
        description: 'קריאת נהלים וחתימה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task619'
      }
    ]
  },
  {
    id: 'major-task304',
    major_task: 'הגדרת תפקיד העובד המקצועי + מיומנויות סיעודיות',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'היכרות עם הצוות ודיירי המחלקה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task620'
      },
      {
        description:
          'לקיחת סימנים חיוניים ל"ד, דופק, חום, סיטורציה, כאב, מדידת סוכר',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task621'
      },
      {
        description: 'חלוקת אוכל לפי כלכלות/רישום מזון בפרדיגמה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task622'
      },
      {
        description: 'קבלת דייר חדש',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task623'
      },
      {
        description: 'דיווח על קבלת דייר חדש ועל כל שינוי חריג במצבו',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task624'
      },
      {
        description: 'שליחה לבית חולים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task625'
      },
      {
        description: 'העברה של דייר ממחלקה למחלקה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task626'
      },
      {
        description: 'עבודה במשמרת ערב',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task627'
      },
      {
        description: 'עבודה במשמרת לילה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task628'
      },
      {
        description: 'טיפול בחולה בבידוד',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task629'
      },
      {
        description: 'דיווח לרופא על מצב מטופל',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task630'
      },
      {
        description: 'ניקוי וחיטוי ציוד רפואי',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task631'
      },
      {
        description: 'רישום ודיווח סיעודי',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task632'
      },
      {
        description: 'הוצאת מטופל לחופש',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task633'
      },
      {
        description: 'טיפול ודווח על חולה נפטר',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task634'
      },
      {
        description: 'טיפול באירוע נפילה/חריג',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task635'
      },
      {
        description: 'טיפול בגרדת',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task636'
      },
      {
        description: 'בדיקה ורענון עגלת החיאה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task637'
      },
      {
        description: 'טיפול בחולה עם גבס',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task638'
      },
      {
        description: 'מבנה תיק הדייר בפרדיגמה, בתיקיה ממוחשבת, ובניירת',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task639'
      },
      {
        description: 'תהליך הזמנת אמבולנסים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task640'
      },
      {
        description: 'ניהול קשר בונה ושוטף עם המשפחות',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task641'
      },
      {
        description: 'מניעת זיהומים והחלפת כפפות',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task642'
      },
      {
        description: 'גישה לקשישים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task643'
      },
      {
        description: 'שיתוף פעולה עם צוות רב מיקצועי',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task644'
      },
      {
        description: 'ישיבות צוות רב מקצועי',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task645'
      },
      {
        description: 'היכרות עם ארון תרופות/אחסון תרופות',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task646'
      },
      {
        description: 'חלוקת תרופות ממוחשבת',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task647'
      },
      {
        description: 'מתן זריקת אינסולין',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task648'
      },
      {
        description: 'אחסון ומתן נרקוטיקה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task649'
      },
      {
        description: 'חבישות פצע והטיפול בו/שינוי תנוחה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task650'
      },
      {
        description: 'טיפול בשיניים תותבות',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task651'
      },
      {
        description: 'טיפול פה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task652'
      },
      {
        description: 'הכנסה והוצאת קטטר קבוע/זמני',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task653'
      },
      {
        description: 'הכנסה והוצאת זונדה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task654'
      },
      {
        description: 'תכנית מאזן נוזלים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task655'
      },
      {
        description: 'הזלפת נוזלים IV/SC',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task656'
      },
      {
        description: 'חוקן/טיפול ביציאות',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task657'
      },
      {
        description: 'טיפול בגסטרוסטום',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task658'
      },
      {
        description: 'תהליך לקיחת בדיקות דם',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task659'
      },
      {
        description: 'שאיבת הפרשות מדרכי נשימה',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task660'
      },
      {
        description: 'ביצוע א.ק.ג',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task661'
      },
      {
        description: 'מתן חיסונים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task662'
      },
      {
        description: 'קבלת גישה והדרכה על פרדיגמה לפי טופס ייעודי',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task663'
      }
    ]
  },
  {
    id: 'major-task306',
    major_task: 'מפגש עם הצוות הרב מקצועי',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'היכרות והצגת התפקידים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task665'
      }
    ]
  },
  {
    id: 'major-task-59',
    major_task: 'הדרכות',
    responsibilty_of: 'task',
    sub_tasks: [
      {
        responsibiltyOf: 'task',
        id: 'sub-task-59',
        description: 'הדרכת נאמן נושא מניעת זיהומים'
      },
      {
        responsibiltyOf: 'task',
        id: 'sub-task-66',
        description: 'הדרכה בנושא מניעת אלימות'
      },
      {
        responsibiltyOf: 'task',
        id: 'sub-task-60',
        description: 'הדרכה בנושא ניהול סיכונים'
      }
    ]
  },
  {
    id: 'major-task-60',
    major_task: 'מניעת זיהומים',
    responsibilty_of: 'task',
    sub_tasks: [
      {
        responsibiltyOf: 'task',
        id: 'sub-task-601',
        description: 'התחייבות והצגת אישור חיסון - אישור ג'
      }
    ]
  },
  {
    id: 'major-task310',
    major_task: 'מבדקי ידע',
    responsibilty_of: 'הנהלת הסיעוד',
    sub_tasks: [
      {
        description: 'חשבון מקצועי',
        responsibiltyOf: 'הנהלת הסיעוד',
        id: 'sub-task670'
      },
      {
        description: 'נהלים פנימיים',
        responsibiltyOf: 'הנהלת הסיעוד',
        id: 'sub-task671'
      },
      {
        description: 'מתן OTC',
        responsibiltyOf: 'הנהלת הסיעוד',
        id: 'sub-task672'
      }
    ]
  }
];

export default nursesDefaultOnboarding;
