import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

const SidebarItemsContext = createContext();

export const SidebarItemsProvider = ({ children }) => {
  SidebarItemsProvider.propTypes = {
    children: PropTypes.any
  };
  const [sidebarItems, setSidebarItems] = useState();

  const currentSidebarItems = useMemo(
    () => ({ sidebarItems, setSidebarItems }),
    [sidebarItems, setSidebarItems]
  );

  return (
    <SidebarItemsContext.Provider value={currentSidebarItems}>
      {children}
    </SidebarItemsContext.Provider>
  );
};
export const useSidebarItems = () => useContext(SidebarItemsContext);
