import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

const DeleteCommunityPostDialog = ({
  open,
  onClose,
  postToDelete,
  handleDeletePost
}) => {
  DeleteCommunityPostDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    postToDelete: PropTypes.object,
    handleDeletePost: PropTypes.func
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>מחיקת פוסט</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1">
          אתה בטוח שהינך רוצה למחוק פוסט זה?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            console.log(postToDelete);
            handleDeletePost(postToDelete);
          }}
          variant="contained"
          color="error"
        >
          מחיקה
        </Button>
        <Button onClick={onClose} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCommunityPostDialog;
