import {
  Card,
  CardContent,
  Grid,
  Skeleton,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import lighthouse from 'src/assets/lighthouse.webp';
import theme from 'src/theme';

const AvailableOrgDepartments = ({ orgPageValues }) => {
  AvailableOrgDepartments.propTypes = {
    orgPageValues: PropTypes.object
  };

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // height: '100%',
        // maxWidth: '900px',
        px: 2,
        height: 'auto',
        alignItems: 'center',
        maxHeight: 'fit-content',
        backgroundColor: '#8eb59e',
        mt: matchDownMd && '20px'
      }}
      item
      xs={12}
      container
      spacing={3}
    >
      {orgPageValues && orgPageValues.tags && orgPageValues.tags.length > 0 ? (
        orgPageValues.tags.map((tag) => (
          <Grid
            item
            key={`${uniqueId(tag)}`}
            xs={12}
            sm={7}
            // md={5}
            sx={{
              height: '140px',
              // maxHeight: '140px',
              maxWidth: '470px !important'
            }}
          >
            <Card
              sx={{
                width: '100%',
                //   backgroundColor: '#8fa8a2',
                height: '100%',
                backgroundImage: `linear-gradient(to bottom, ${alpha(
                  theme.palette.grey[600],
                  0.5
                )} 15%, ${alpha(
                  theme.palette.primary.light,
                  0.8
                )}), url(${lighthouse})`,
                backgroundAttachment: 'fixed'
              }}
            >
              <CardContent
                sx={{
                  height: '100%',
                  color: theme.palette.primary.contrastText,
                  fontSize: '2rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                {useTranslation().t(`orgTags.${tag}`)}
              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <Skeleton width={70} variant="circular" />
      )}
    </Grid>
  );
};

export default AvailableOrgDepartments;
