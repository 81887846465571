const physicalTherapist = [
  {
    id: 'major-physicalTherapist-0',
    major_task: 'הכרת המבנה',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'משרדי עבודה, עמדת מחשב',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-0'
      },
      {
        description: 'חדר צוות, מטבחון',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-1'
      },
      {
        description: 'מחסני פיזיותרפיה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-2'
      },
      {
        description: 'סיור במחלקה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task604'
      },
      {
        description: 'סיור בבניין: דלפק, שעון נוכחות, לוח כיבוי אש',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task605'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-55',
    major_task: 'אדמניסטרציה',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'קבלת מפתחות גישה למוסד, למשרד ולמחסנים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-55'
      },
      {
        description:
          'מתן מספרי טלפונים ומיילים של אנשי קשר בקהילה ובמשרד הבריאות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-56'
      },
      {
        description: 'קבלת גשיה למערכת הממוחשבת',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-57'
      },
      {
        description: 'קבלת מדים לעבודה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-58'
      },
      {
        description: 'חוזה, סודיות, סלולרי, כרטיס עובד, כרטיס החתמת שעון',
        responsibiltyOf: 'משאבי אנוש',
        id: 'sub-task664'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-3',
    major_task: 'תיאום ציפיות',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'סדר יום עבודה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-3'
      },
      {
        description: 'הכרת המבנה הארגוני ותפקידי העובדים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-4'
      },
      {
        description: 'ימי ושעות עבודה, לבוש',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task606'
      },
      {
        description: "השתתפות בישיבות צוות ובחת''ש",
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task607'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-5',
    major_task: 'דרכי תקשורת',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'קבלת מספר נייד מהעבודה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-5'
      },
      {
        description: 'קבלת מייל מהעבודה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-6'
      },
      {
        description: 'טלפון מנהל ישיר, מחלקה, דלפק, ודרכי קשר עם מחלקות אחרות',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task608'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-7',
    major_task: 'נהלי עבודה',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'קריאת נהלי רוחב בתחום הפיזיותרפיה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-7'
      },
      {
        description: 'קריאה וחתימה על נהלים פנימיים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-8'
      },
      {
        description: 'נוהל חופשות והיעדרות מהעבודה, הודעה לממונה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-9'
      },
      {
        description: 'נוהל קבלת מטופל חדש',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-10'
      },
      {
        description: 'נוהל טיפול במטופל',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-11'
      },
      {
        description: 'נוהל תיעוד',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-12'
      },
      {
        description: 'נוהל עוזר פיזיותרפיה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-13'
      },
      {
        description: 'נוהל סידור עבודה ובקשות, נוהל החתמת שעון',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task610'
      },
      {
        description: 'נוהל סודיות רפואית',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task613'
      },
      {
        description: 'נוהל שפה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task614'
      },
      {
        description: 'נוהל ענידת תג שם והופעה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task615'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-14',
    major_task: 'כללי בטיחות',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'היכרות ועבודה עם דפנות המיטה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-14'
      },
      {
        description: 'היכרות ועבודה עם מיטה עולה ויורדת',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-15'
      },
      {
        description: 'שולחניות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-16'
      },
      {
        description: ' היכרות ותפעול כסא גלגלים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-17'
      },
      {
        description: 'הסבר על תמיכה לישיבה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-18'
      },
      {
        description: 'הסבר על מעבר למיטה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-19'
      },
      {
        description: 'הסבר על מעבר לכסא גלגלים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-20'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-21',
    major_task: 'הדרכה בסיסית למטופל',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'תנועתיות, תנוחה במיטה, שינוי תנוחה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-21'
      },
      {
        description: 'שימוש במשולש',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-22'
      },
      {
        description: 'שימוש בכסא גלגלים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-23'
      },
      {
        description: 'עקרונות הלבשה, מקלחת ושימוש בשירותים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-24'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-25',
    major_task: 'קבלת חולה חדש',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'בדיקה ותיעוד',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-25'
      },
      {
        description: 'הסבר למטופל ולמשפחה על תוצאות הבדיקה ותיאום ציפיות איתם',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-26'
      },
      {
        description: 'הסבר למטופל ולמשפחתו על מהלך הטיפולים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-27'
      },
      {
        description: 'הפעלה פסיבית במיטה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-28'
      },
      {
        description: 'הפעלה פסיבית בכסא',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-29'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-30',
    major_task: 'היכרות עם המכשירים במוסד ובחדר הפיזיותרפיה',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'מיטה חשמלית, מדרגות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-30'
      },
      {
        description: 'הליכונים, מקלות, עמודים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-31'
      },
      {
        description: 'אלקטרותרפיה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-32'
      },
      {
        description: 'כריות חימום, כריות ישיבה, שולחנות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-33'
      },
      {
        description: 'אמבטיית פראפין',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-34'
      },
      {
        description: 'מקבילים סולם',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-35'
      },
      {
        description: 'אופני כושר',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-36'
      },
      {
        description: 'כדורים וגלילים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-37'
      },
      {
        description: 'תפעול כרית דינאמית',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-38'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-39',
    major_task: 'הגדרת תפקיד העובד המקצועי + מיומנויות',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'מעקב הסתגלות המטופל במחלקה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-40'
      },
      {
        description: 'דיווח שוטף',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-41'
      },
      {
        description: 'ניהול קשר בונה ושוטף עם המשפחות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-42'
      },
      {
        description: 'השתתפות בישיבות צוות רב מקצועית',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-43'
      },
      {
        description: 'השתתתפות בישיבת צוות מחלקתית',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-44'
      },
      {
        description: 'השתתפות בוועדות מוסדיות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-45'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-46',
    major_task: 'תצפיות',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'תצפית על שינוי תנוחה',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-47'
      },
      {
        description: 'תצפית על השכבות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-48'
      },
      {
        description: 'תצפית על הולכות',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-49'
      },
      {
        description: 'תצפית על מעברים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-50'
      }
    ]
  },
  {
    id: 'major-physicalTherapist-51',
    major_task: 'הדרכות',
    responsibilty_of: 'physicalTherapist',
    sub_tasks: [
      {
        description: 'שימוש במנוף',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-52'
      },
      {
        description: 'ביצוע מעברים',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-53'
      },
      {
        description: 'שימוש מטפל באביזרי עזר',
        responsibiltyOf: 'physicalTherapist',
        id: 'sub-physicalTherapist-54'
      }
    ]
  },
  {
    id: 'major-task304',
    major_task: 'הגדרת תפקיד העובד המקצועי',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'היכרות עם הצוות ודיירי המחלקה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task620'
      }
    ]
  },
  {
    id: 'major-task306',
    major_task: 'מפגש עם הצוות הרב מקצועי',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'היכרות והצגת התפקידים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task665'
      }
    ]
  },
  {
    id: 'major-task307',
    major_task: 'מניעת אלימות',
    responsibilty_of: 'עו"ס המחלקה',
    sub_tasks: [
      {
        description: 'הדרכה בנושא מניעת אלימות',
        responsibiltyOf: 'עו"ס המחלקה',
        id: 'sub-task666'
      }
    ]
  },
  {
    id: 'major-task308',
    major_task: 'ניהול סיכונים',
    responsibilty_of: 'נאמן ניהול סיכונים',
    sub_tasks: [
      {
        description: 'הדרכה בטופס ייעודי',
        responsibiltyOf: 'נאמן ניהול סיכונים',
        id: 'sub-task667'
      }
    ]
  },
  {
    id: 'major-task309',
    major_task: 'מניעת זיהומים',
    responsibilty_of: 'נאמן מניעת זיהומים',
    sub_tasks: [
      {
        description: 'הדרכה בטופס ייעודי',
        responsibiltyOf: 'נאמן מניעת זיהומים',
        id: 'sub-task668'
      },
      {
        description: 'התחייבות והצגת אישור חיסון - אישור ג',
        responsibiltyOf: 'אחראי תחום חיסונים',
        id: 'sub-task669'
      }
    ]
  }
];

export default physicalTherapist;
