import { doc, getDoc } from '@firebase/firestore';
import { DiamondOutlined, PreviewOutlined } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { db, storage } from 'src/_firebase/firebase';
import cardPic from 'src/assets/moreToRead/process.webp';

const PurchaseListItemButton = ({ purchase, handleChangeView }) => {
  PurchaseListItemButton.propTypes = {
    purchase: PropTypes.object,
    handleChangeView: PropTypes.func
  };

  const [itemDetails, setItemDetails] = useState();

  const getPurchasedProductUrl = async (id) => {
    const storageRef = ref(storage, `marketplace/products/${id}`);
    const url = getDownloadURL(storageRef)
      .then((res) => res)
      .catch((err) => {
        console.log(err);
        // return err;
      });
    // console.log(url);
    return url;
  };

  const retrieveItemDetails = async (product_id) => {
    const itemRef = doc(db, `marketplace/${product_id}`);
    getDoc(itemRef).then(async (snap) => {
      if (!snap.exists()) {
        setItemDetails({});
      } else {
        const url = await getPurchasedProductUrl(product_id);
        // console.log(url);
        setItemDetails({ ...snap.data(), url });
      }
    });
  };

  const getPurchaseDate = () => {
    const { purchased_at } = purchase;
    if (purchased_at) {
      return moment(purchased_at.seconds * 1000).format('DD/MM/YYYY');
    }
    return '';
  };

  // console.log(purchase);

  useEffect(() => {
    if (purchase && !itemDetails) {
      retrieveItemDetails(purchase.product_id);
    }
  }, [purchase]);
  return (
    <Fragment key={`fragment-purchase-li-${purchase.product_id}`}>
      <ListItemText
        sx={{ flex: 1, mt: 1 }}
        secondary={`נרכש בתאריך ${getPurchaseDate()}`}
      />
      <ListItem
        key={`purchase-li-${purchase.product_id}`}
        secondaryAction={
          <Button
            variant="outlined"
            color="success"
            fullWidth
            endIcon={<PreviewOutlined />}
            sx={{ maxWidth: 160, zIndex: 3 }}
            disabled={!itemDetails}
            onClick={() => {
              if (itemDetails && itemDetails.url) {
                handleChangeView(itemDetails.url, itemDetails.product_name);
              }
            }}
          >
            צפיה
          </Button>
        }
        disablePadding
      >
        <ListItemButton
          role={undefined}
          key={`purchase-li-button-${purchase.product_id}`}
          disabled={!itemDetails}
          onClick={() => {
            if (itemDetails && itemDetails.url) {
              handleChangeView(itemDetails.url, itemDetails.product_name);
            }
          }}
        >
          <ListItemIcon>
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <DiamondOutlined
                color="info"
                edge="start"
                fontSize="small"
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': purchase.purchase_id }}
              />
              <Typography variant="body1">{purchase.buy_price}</Typography>
            </Stack>
          </ListItemIcon>
          <ListItemAvatar>
            <Avatar
              variant="rounded"
              src={
                itemDetails && itemDetails.preview
                  ? itemDetails.preview
                  : cardPic // TODO:: handle preview
              }
              sx={{ width: 62, height: 62 }}
            >
              {itemDetails ? itemDetails.product_name : 'item name'}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            id={purchase.product_id}
            primaryTypographyProps={{
              fontSize: '16px'
            }}
            primary={
              itemDetails ? (
                itemDetails.product_name
              ) : (
                <Skeleton
                  variant="text"
                  width="100%"
                  sx={{ maxWidth: 140, width: '70%' }}
                />
              )
            }
            secondary={
              itemDetails ? (
                itemDetails.description
              ) : (
                <Skeleton
                  variant="text"
                  width="100%"
                  sx={{ maxWidth: 200, zIndex: 1, width: '70%' }}
                />
              )
            }
            secondaryTypographyProps={{
              sx: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word'
              }
            }}
            sx={{
              px: 1
            }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
    </Fragment>
  );
};

export default PurchaseListItemButton;
