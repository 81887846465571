const doctorDefaultOnboarding = [
  {
    id: 'major-task305',
    major_task: 'אדמניסטרציה',
    responsibilty_of: 'משאבי אנוש',
    sub_tasks: [
      {
        description: 'חוזה, סודיות, סלולרי, כרטיס עובד, כרטיס החתמת שעון',
        responsibiltyOf: 'משאבי אנוש',
        id: 'sub-task664'
      }
    ]
  },
  {
    id: 'major-task301',
    major_task: 'הכרת המבנה',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description:
          'סיור במחלקה: תחנת רופאים, שירותים לצוות, לאורחים, חדרי דיירים, מחסנים',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task604'
      },
      {
        description:
          'סיור בבניין: דלפק, שעון נוכחות, לוח כיבוי אש, נטרול אזעקה אחורית, מטבח, חדר כביסה, מלתחות, מכון פיזיותרפיה, בית מרקחת',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task605'
      }
    ]
  },
  {
    id: 'major-task302',
    major_task: 'תיאום ציפיות',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'ימי ושעות עבודה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task606'
      },
      {
        description: 'לבוש',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task607'
      }
    ]
  },
  {
    id: 'major-task303',
    major_task: 'דרכי תקשורת',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'טלפון מנהל ישיר, מחלקה, דלפק, ודרכי קשר עם מחלקות אחרות',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task610'
      },
      {
        description: "השתתפות בישיבות צוות ובחת''ש",
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task609'
      }
    ]
  },
  {
    id: 'major-task306',
    major_task: 'מפגש עם הצוות הרב מקצועי',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'היכרות והצגת התפקידים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task665'
      }
    ]
  },
  {
    id: 'major-task309',
    major_task: 'מניעת זיהומים',
    responsibilty_of: 'נאמן מניעת זיהומים',
    sub_tasks: [
      {
        description: 'הדרכה נאמן נושא',
        responsibiltyOf: 'נאמן מניעת זיהומים',
        id: 'sub-task668'
      },
      {
        description: 'התחייבות והצגת אישור חיסון - אישור ג',
        responsibiltyOf: 'אחראי תחום חיסונים',
        id: 'sub-task669'
      }
    ]
  },
  {
    id: 'major-task311',
    major_task: 'נהלי עבודה',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'נוהל סידור עבודה ובקשות, נוהל החתמת שעון',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task610'
      },
      {
        description: 'נוהל סודיות רפואית',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task613'
      },
      {
        description: 'נוהל שפה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task614'
      },
      {
        description: 'נוהל ענידת תג שם והופעה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task615'
      }
    ]
  },
  {
    id: 'major-task304',
    major_task: 'הגדרת תפקיד העובד המקצועי',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'היכרות עם הצוות ודיירי המחלקה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task620'
      }
    ]
  },
  {
    id: 'major-task307',
    major_task: 'הדרכות',
    responsibilty_of: 'עו"ס המחלקה',
    sub_tasks: [
      {
        description: 'הדרכה בנושא מניעת אלימות',
        responsibiltyOf: 'עו"ס המחלקה',
        id: 'sub-task666'
      },
      {
        description: 'הדרכה בנושא ניהול סיכונים',
        responsibiltyOf: 'נאמן ניהול סיכונים',
        id: 'sub-task667'
      }
    ]
  }
];

export default doctorDefaultOnboarding;
