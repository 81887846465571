import { Card, CardContent, Grid, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import theme from 'src/theme';

const FixedGradientSection = () => (
  <Grid
    item
    xs={12}
    sx={{
      // height: '100%',
      minHeight: '100vh',
      // backgroundImage: `radial-gradient(${theme.palette.primary.light}, ${theme.palette.grey[200]} 90%)`,

      backgroundImage: `linear-gradient(to bottom, ${alpha(
        theme.palette.common.white,
        0.6
      )}, ${alpha(theme.palette.primary.light, 0.7)}),
    url(https://images.pexels.com/photos/12301178/pexels-photo-12301178.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,
      width: '100%',
      backgroundAttachment: 'fixed',
      display: 'flex',
      alignItems: 'center',
      p: 6
    }}
  >
    <Card
      elevation={0}
      sx={{
        backgroundColor: 'rgba(0,0,0,0)',
        height: '100%',
        m: 'auto',
        width: '100%',
        maxWidth: '1100px'
      }}
    >
      <CardContent
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: theme.palette.common.black,
            textAlign: 'center',
            fontSize: '2.5rem',
            fontWeight: 800,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: 10,
            p: 2,
            width: 'fit-content'
          }}
        >
          עובדי מגדלור ייעוץ והכוונה מתחייבים ל
        </Typography>
        <Grid
          container
          spacing={4}
          sx={{ justifyContent: 'center', alignItems: 'center', mt: 3 }}
        >
          {[
            {
              title: 'שקיפות',
              id: 'transparecy',
              icon: '',
              description: ''
            },
            {
              title: 'מקצועיות',
              id: 'proffesional',
              icon: '',
              description: ''
            },
            {
              title: 'איכות',
              id: 'quality',
              icon: '',
              description:
                'הצבת מדדי איכות וסטנדרטים חדשים שיטיבו עם תנאי החיים של הדיירים'
            },
            {
              title: 'אכפתיות ודאגה',
              id: 'caring',
              icon: '',
              description: 'כולל יחס אישי אנושי קשוב ותומך'
            },
            {
              title: 'יעילות והתייעלות',
              id: 'effecincy',
              icon: '',
              description: 'תמיד נשאף ללמוד ולהשתפר ולמצל את המשאבים בחוכמה'
            }
          ].map((vision) => (
            <Grid
              key={`${vision.id}-${uniqueId('vision')}`}
              item
              xs={12}
              sm={6}
              sx={{
                maxWidth: '500px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  // height: '100%',
                  bgcolor: 'transparent',
                  backgroundColor: alpha(theme.palette.primary.main, 0.5),
                  color: theme.palette.primary.contrastText,
                  // border: '1px solid #e3b0f0',
                  minHeight: 140,
                  maxWidth: '500px'
                }}
              >
                <CardContent>
                  <Typography variant="h3">{vision.title}</Typography>
                  <Typography variant="body1">{vision.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default FixedGradientSection;
