import {
  ContactPageOutlined,
  Diversity1Outlined,
  FavoriteBorderOutlined,
  LightbulbOutlined,
  ListAltOutlined,
  ThumbUpOffAltOutlined,
  VerticalShadesOutlined,
  WhatsApp
} from '@mui/icons-material';
import {
  Button,
  CardMedia,
  Grid,
  Icon,
  Slide,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import cardStep1 from 'src/assets/home/ParallaxSection/card-step-1.webp';
import cardStep2 from 'src/assets/home/ParallaxSection/card-step-2.webp';
import cardStep3 from 'src/assets/home/ParallaxSection/card-step-3.webp';
import cardStep4 from 'src/assets/home/ParallaxSection/card-step-4.webp';
import leaves from 'src/assets/home/leaves.svg';
import StepsCard from 'src/components/Home/StepsCard';
import theme from 'src/theme';
import './steps.scss';

const ParallaxSection = () => {
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage.howDoesItWorkSection'
  });

  const refs = [
    {
      content: 'content of first',
      id: uniqueId('numbers'),
      title: (
        <Typography gutterBottom variant="h5" component="span">
          {t('researchTogetherDescription')}
          <Typography
            className="highlight fontColor1"
            variant="h5"
            component="span"
          >
            {t('customized')}
          </Typography>
        </Typography>
      ),
      img: cardStep1,
      phaseNum: (
        <li>
          <div className="numberWrap">
            <div className="number fontColor1">1</div>
            <div className="coverWrap">
              <div className="numberCover" />
            </div>
          </div>
          <div className="content">
            <div className="icon iconSocial">
              <Icon className="numberIcon">
                <LightbulbOutlined htmlColor="#3498db" />
              </Icon>
            </div>
            <h2 className="fontColor1">{t('researchTogether')}</h2>
          </div>
        </li>
      ),
      btn: (
        <Button
          LinkComponent="a"
          href="/contact"
          startIcon={<ContactPageOutlined />}
        >
          השאר לנו פניה
        </Button>
      )
    },
    {
      // element: secondSection,
      // inView: secondSectionInView,
      id: uniqueId('numbers'),
      content: 'הערכה',
      title: (
        <Typography
          // sx={{ display: 'flex', flexDirection: 'row' }}
          gutterBottom
          variant="h5"
          component="span"
        >
          {t('recruitmentWhenMatchDescription')}
          <Typography
            className="highlight fontColor2"
            variant="h5"
            component="span"
          >
            {t('weDescribeAvailableFunds')}
          </Typography>
        </Typography>
      ),
      img: cardStep2,
      phaseNum: (
        <li>
          <div className="numberWrap">
            <div className="number fontColor2">2</div>
            <div className="coverWrap">
              <div className="numberCover" />
            </div>
          </div>
          <div className="content">
            <div className="icon iconSocial">
              <Icon className="numberIcon">
                <ListAltOutlined htmlColor="#2ecc71" />
              </Icon>
            </div>
            <h2 className="fontColor2">{t('recruitmentWhenMatch')}</h2>
          </div>
        </li>
      ),
      btn: (
        <Button
          LinkComponent="a"
          href={`https://api.whatsapp.com/send/?phone=972557111106&text=${encodeURIComponent(
            'שלום, אשמח לקבל עזרה והמלצות אודות חיפוש בית אבות ודיור מוגן מתאים ומקצועי'
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<WhatsApp style={{ color: 'rgb(36, 211, 101)' }} />}
        >
          שלח לנו עכשיו!
        </Button>
      )
    },
    {
      id: uniqueId('numbers'),
      content: 'בחר את המתאים ביותר',
      title: (
        <Typography
          // sx={{ display: 'flex', flexDirection: 'row' }}
          gutterBottom
          variant="h5"
          component="span"
        >
          {t('smartChoiceDescription')}
          <Typography
            className="highlight fontColor3"
            variant="h5"
            component="span"
          >
            {t('willGetASpecialDiscountForYou')}
          </Typography>
          <Typography variant="h5" component="span">
            {t('orASpecialGiftFromUs')}
          </Typography>
        </Typography>
      ),
      img: cardStep3,
      phaseNum: (
        <li>
          <div className="numberWrap">
            <div className="number  fontColor3">3</div>
            <div className="coverWrap">
              <div className="numberCover" />
            </div>
          </div>
          <div className="content">
            <div className="icon iconAirplane">
              <Icon className="numberIcon">
                <ThumbUpOffAltOutlined htmlColor="#9b59b6" />
              </Icon>
            </div>
            <h2 className="fontColor3">{t('smartChoice')}</h2>
          </div>
        </li>
      ),
      btn: (
        <Button
          LinkComponent="a"
          href="/compare"
          startIcon={<VerticalShadesOutlined />}
        >
          השווה בין בתי האבות
        </Button>
      )
    },
    {
      // element: fourthSection,
      // inView: fourthSectionInView,
      content: 'קבל את המקסימום',
      id: uniqueId('numbers'),
      title: (
        <Typography
          // sx={{ display: 'flex', flexDirection: 'row' }}
          gutterBottom
          variant="h5"
          component="span"
        >
          {/* {t('itDoesNotEndHereDescription')} */}
          <Typography
            className="highlight fontColor4"
            variant="h5"
            component="span"
          >
            {t('hereForYou')}
          </Typography>
          <Typography variant="h5" component="span">
            {t('toHelpUsAndOthers')}
          </Typography>
        </Typography>
      ),
      img: cardStep4,
      phaseNum: (
        <li>
          <div className="numberWrap">
            <div className="number  fontColor4">4</div>
            <div className="coverWrap">
              <div className="numberCover" />
            </div>
          </div>
          <div className="content">
            <div className="icon iconMap">
              <Icon className="numberIcon">
                <FavoriteBorderOutlined htmlColor="#f1c40f" />
              </Icon>
            </div>
            <h2 className="fontColor4">{t('itDoesNotEndHere')}</h2>
          </div>
        </li>
      ),
      btn: (
        <Button
          LinkComponent="a"
          href="/family-login"
          startIcon={<Diversity1Outlined />}
        >
          מדרגים את החוויה
        </Button>
      )
    }
  ];

  return (
    <Grid
      justifyContent="center"
      container
      spacing={2}
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1
      }}
      className="parallaxContainer"
      // ref={inViewRef}
    >
      <CardMedia
        alt="shapes"
        loading="lazy"
        component="img"
        image={leaves}
        sx={{
          position: 'absolute',
          width: '200px',
          height: '200px',
          // mx: 'auto',
          zIndex: 1,
          // top: '-30px',
          top: '50%',
          right: 0,
          // backgroundPositionX: '15px',
          backgroundPositionY: '-60px',
          transform: 'rotate(-90deg)',
          filter: 'invert(0.8) saturate(7)'
          // filter: 'saturate(0) sepia(0.9) invert(0.7)'
          // backgroundBlendMode: 'soft-light'
        }}
      />
      {refs.map((infoRef, i) => (
        <Grid
          item
          container
          spacing={1}
          key={infoRef.id}
          xs={12}
          sm={12}
          md={12}
          id={`section${i}`}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2
          }}
          className={`colorSection section${i}`}
        >
          <Slide in unmountOnExit={false} direction="left" timeout={1500}>
            <Grid
              sx={{ maxWidth: !matchDownMd ? '400px' : 'inherit' }}
              className="panel-header"
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
            >
              <Stack>{infoRef.phaseNum}</Stack>
            </Grid>
          </Slide>
          <Slide
            in
            // style={{ transitionDelay: `${i * 500}ms` }}
            unmountOnExit={false}
            direction="right"
            timeout={1500}
          >
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <StepsCard info={infoRef} key={`stepcard-${infoRef.id}`} />
            </Grid>
          </Slide>
        </Grid>
      ))}
      {/* <Box sx={{ position: 'absolute', zIndex: 0, backgroundColor: 'black', borderRadius: '' }} /> */}
    </Grid>
  );
};
export default ParallaxSection;
