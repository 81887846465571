const socialWorkerDefaultOnboarding = [
  {
    id: 'major-socialWorker-0',
    major_task: 'הכרת המבנה',
    responsibilty_of: 'socialWorker',
    sub_tasks: [
      {
        description: 'משרדי עבודה, עמדת מחשב ',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-0'
      },
      {
        description: 'חדר צוות, מטבחון',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-1'
      },
      {
        description: 'סיור במחלקה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task604'
      },
      {
        description: 'סיור בבניין: דלפק, שעון נוכחות, לוח כיבוי אש',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task605'
      }
    ]
  },
  {
    id: 'major-socialWorker-20',
    major_task: 'אדמניסטרציה',
    responsibilty_of: 'socialWorker',
    sub_tasks: [
      {
        description: 'קבלת מפתחות גישה למוסד ולמשרד',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-20'
      },
      {
        description: 'מתן מספרי טלפונים ומיילים של אנשי קשר בקהילה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-21'
      },
      {
        description: 'קבלת גשיה למערכת הממוחשבת',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-22'
      },
      {
        description: 'קבלת מדים לעבודה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-23'
      },
      {
        description: 'חוזה, סודיות, סלולרי, כרטיס עובד, כרטיס החתמת שעון',
        responsibiltyOf: 'משאבי אנוש',
        id: 'sub-task664'
      }
    ]
  },
  {
    id: 'major-socialWorker-2',
    major_task: 'תיאום ציפיות',
    responsibilty_of: 'socialWorker',
    sub_tasks: [
      {
        description: 'סדר יום עבודה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-2'
      },
      {
        description: 'הכרת המבנה הארגוני ותפקידי העובדים',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-3'
      },
      {
        description: 'ימי ושעות עבודה, לבוש',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task606'
      },
      {
        description: "השתתפות בישיבות צוות ובחת''ש",
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task607'
      }
    ]
  },
  {
    id: 'major-socialWorker-4',
    major_task: 'דרכי תקשורת',
    responsibilty_of: 'socialWorker',
    sub_tasks: [
      {
        description: 'קבלת מספר נייד מהעבודה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-4'
      },
      {
        description: 'קבלת מייל מהעבודה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-5'
      },
      {
        description: 'טלפון מנהל ישיר, מחלקה, דלפק, ודרכי קשר עם מחלקות אחרות',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task608'
      }
    ]
  },
  {
    id: 'major-socialWorker-6',
    major_task: 'נהלי עבודה',
    responsibilty_of: 'socialWorker',
    sub_tasks: [
      {
        description: 'קריאה וחתימה על נהלי רוחב בתחום השירות הוסציאלי',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-6'
      },
      {
        description: 'קריאה וחתימה על נהלים פנימיים',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-7'
      },
      {
        description: 'נוהל חופשות והיעדרות מהעבודה, הודעה לממונה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-8'
      },
      {
        description: 'נוהל סידור עבודה ובקשות, נוהל החתמת שעון',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task610'
      },
      {
        description: 'נוהל סודיות רפואית',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task613'
      },
      {
        description: 'נוהל שפה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task614'
      },
      {
        description: 'נוהל ענידת תג שם והופעה',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task615'
      }
    ]
  },
  {
    id: 'major-socialWorker-9',
    major_task: 'הגדרת תפקיד העובד המקצועי + מיומנויות',
    responsibilty_of: 'socialWorker',
    sub_tasks: [
      {
        description: 'ביצוע קבלה סוציאלית',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-9'
      },
      {
        description: 'מעקב הסתגלות המטופל במחלקה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-10'
      },
      {
        description: 'דיווח שוטף',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-11'
      },
      {
        description: 'ניהול קשר בונה ושוטף עם המשפחות',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-12'
      },
      {
        description: 'ניהול קשר בונה ושוטף עם הקהילה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-13'
      },
      {
        description: 'מינויי אפוטרופוס',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-14'
      },
      {
        description: 'מינויי מיופה כוח',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-15'
      },
      {
        description: 'ישיבות צוות- תכנון, זימון, הזנה',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-16'
      },
      {
        description: 'השתתתפות בישיבת צוות בכיר',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-17'
      },
      {
        description: 'השתתתפות בישיבת צוות מחלקתית',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-18'
      },
      {
        description: 'השתתפות בוועדות מוסדיות',
        responsibiltyOf: 'socialWorker',
        id: 'sub-socialWorker-19'
      }
    ]
  },
  {
    id: 'major-task304',
    major_task: 'הגדרת תפקיד העובד המקצועי',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'היכרות עם הצוות ודיירי המחלקה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task620'
      }
    ]
  },
  {
    id: 'major-task306',
    major_task: 'מפגש עם הצוות הרב מקצועי',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'היכרות והצגת התפקידים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task665'
      }
    ]
  },
  {
    id: 'major-task307',
    major_task: 'מניעת אלימות',
    responsibilty_of: 'עו"ס המחלקה',
    sub_tasks: [
      {
        description: 'הדרכה בנושא מניעת אלימות',
        responsibiltyOf: 'עו"ס המחלקה',
        id: 'sub-task666'
      }
    ]
  },
  {
    id: 'major-task308',
    major_task: 'ניהול סיכונים',
    responsibilty_of: 'נאמן ניהול סיכונים',
    sub_tasks: [
      {
        description: 'הדרכה בטופס ייעודי',
        responsibiltyOf: 'נאמן ניהול סיכונים',
        id: 'sub-task667'
      }
    ]
  },
  {
    id: 'major-task309',
    major_task: 'מניעת זיהומים',
    responsibilty_of: 'נאמן מניעת זיהומים',
    sub_tasks: [
      {
        description: 'הדרכה בטופס ייעודי',
        responsibiltyOf: 'נאמן מניעת זיהומים',
        id: 'sub-task668'
      },
      {
        description: 'התחייבות והצגת אישור חיסון - אישור ג',
        responsibiltyOf: 'אחראי תחום חיסונים',
        id: 'sub-task669'
      }
    ]
  }
];

export default socialWorkerDefaultOnboarding;
