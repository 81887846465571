import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';
import { uniqueId } from 'lodash';
import theme from 'src/theme';
import pin from './pin.svg';
import './vision.css';

const FixedGradientSuccessSection = () => {
  const matchBetweenMdSm = useMediaQuery(theme.breakpoints.between('md', 'sm'));
  return (
    <Grid
      item
      xs={12}
      sx={{
        // height: '100%',
        minHeight: '100vh',
        // backgroundImage: `linear-gradient(to bottom, ${alpha(
        //   theme.palette.primary.light,
        //   0.6
        // )} 60%, ${theme.palette.grey[200]})`,?
        width: '100%',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        p: 6
      }}
    >
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'rgba(0,0,0,0)',
          height: '100%',
          m: 'auto',
          width: '100%',
          maxWidth: '1100px'
        }}
      >
        <CardContent sx={{ width: '100%' }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: '2.5rem',
              fontWeight: 800,
              color: theme.palette.common.black,
              textAlign: 'center'
            }}
          >
            שואפים למצוינות
          </Typography>
          <Grid
            container
            spacing={4}
            sx={{ justifyContent: 'center', alignItems: 'center', mt: 3 }}
          >
            {[
              {
                title: 'מעודדים יציבות תעסוקתית',
                id: 'transparecy',
                color: 'pink',
                img: 'https://images.pexels.com/photos/684385/pexels-photo-684385.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                description:
                  'ככל שהעובדים נמצאים יותר זמן וצוברים וותק בארגון ולא נודדים ממקום למקום יקבלו ניקוד יותר גבוה'
              },
              {
                title: 'מעודדים השכלה',
                color: 'yellow',
                id: 'proffesional',
                img: 'https://images.pexels.com/photos/2293019/pexels-photo-2293019.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                description: 'ככל שתצבור יותר ידע ולמידה תקבל ניקוד יותר גבוה'
              },
              {
                title: 'מעודדים התקדמות',
                id: 'quality',
                color: 'beige',
                img: 'https://img.freepik.com/free-photo/man-climbing-stairs-heaven_1154-38.jpg?w=740&t=st=1685997831~exp=1685998431~hmac=1d360de427fcf0d2ad209ba40a6bed7fa59444d05671085a777b1da4566ce246',
                description:
                  'ככל שמאיישים תפקידים יותר גבוהים, כך צוברים ניקוד יותר גבוה'
              },
              {
                title: 'מעודדים הישגיות',
                color: 'aqua',
                id: 'caring',
                img: 'https://images.pexels.com/photos/3760069/pexels-photo-3760069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                description:
                  'ככל שתצבור יותר ניקוד, תדורג יותר גבוה בהשוואה לאחרים'
              }
            ].map((success) => (
              <Grid
                item
                key={`${success.id}-${uniqueId('success')}`}
                xs={12}
                sm={6}
                sx={{
                  maxWidth: '500px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  p: 2,
                  position: 'relative',
                  flexDirection: 'column'
                }}
              >
                <Box
                  key={`label-of-${success.id}`}
                  sx={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    backgroundColor: success.color,
                    zIndex: 0,
                    transform: 'translateY(40%)',
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'center'
                  }}
                >
                  {/* <Icon> */}
                  <img
                    src={pin}
                    alt="pin"
                    loading="lazy"
                    style={{
                      width: '32px',
                      height: '32px',
                      objectFit: 'contain',
                      transform: 'translate(15px,-10px)',
                      // backdropFilter: 'drop-shadow(2px 6px 10px grey)',
                    }}
                  />
                  {/* </Icon> */}
                </Box>
                <Card
                  sx={{
                    width: '100%',
                    // height: '100%',
                    bgcolor: 'transparent',
                    backgroundColor: success.color,
                    color: theme.palette.common.black,
                    // border: '1px solid #e3b0f0',
                    minHeight: matchBetweenMdSm ? 310 : 250,
                    maxWidth: '500px',
                    position: 'relative'
                  }}
                  className="successCard"
                  elevation={0}
                >
                  {/* <CardMedia
                    component="img"
                    src={success.img}
                    width="100%"
                    height={120}
                    alt={`${success.id}-${uniqueId('desc')}`}
                    className="successCardMedia"
                  /> */}
                  <CardContent
                    sx={{ justifyContent: 'center', textAlign: 'center' }}
                  >
                    <Typography variant="h3">{success.title}</Typography>
                    <Typography variant="body1">
                      {success.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FixedGradientSuccessSection;
