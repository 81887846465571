import {
  Box,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/styles/useTheme';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import lighthouseBg from 'src/assets/lighthouse.webp';
import HowDoesItWork from 'src/components/Home/HowDoesItWork';
import Information from 'src/components/Home/Information';
import ParallaxSection from 'src/components/Home/ParallaxSection';
import Services from 'src/components/Home/Services';
import TalkToUs from 'src/components/Home/TalkToUs';
import Testimonials from 'src/components/Home/Testimonials';
// import UsersPricingComponent from 'src/components/Home/UsersPricingComponent.1';
import UsersPricingComponent from 'src/components/Home/UsersPricingComponent';
import Counters from 'src/components/shared/Counters/Counters';
import SearchButtonGroup from 'src/components/shared/SearchButtonGroup/SearchButtonGroup';
import SearchLoader from 'src/components/shared/SearchLoader/SearchLoader';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import handleGTag from 'src/utils/handleGTag';
import '../components/Home/home.css';
import '../components/Home/home.scss';

function Home() {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('translation', { keyPrefix: 'mainPage' });
  const [mapShowing, setIsMapShowing] = useState(false);

  const {
    searchResults,
    setSliderDistanceValue,
    areResultsLoading,
    searchQuery,
    setSearchQuery
  } = useSearchData();
  const handleSliderValueChange = (e, newValue) => {
    setSliderDistanceValue(newValue);
  };

  const handleAddressChange = (e) => {
    setSearchQuery({ ...searchQuery, text: e.target.value });
  };

  // useEffect(() => {
  //   const searchField = document.getElementById('address-text-field');
  //   if (searchField) {
  //     searchField.addEventListener('keydown', (e) => {
  //       if (
  //         (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Next') &&
  //         e.target === this
  //       ) {
  //         console.log(e.key);
  //         e.preventDefault();
  //         const searchBtn = document.getElementById('search-btn');
  //         if (searchBtn) {
  //           searchBtn.focus();
  //         }
  //       }
  //     });
  //   }
  // }, []);

  return (
    <Paper
      sx={{
        overflowX: 'hidden',
        position: 'relative',
        backgroundAttachment: 'fixed'
        // bgcolor: 'def'
      }}
    >
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta
          name="description"
          content="מגדלור ייעוץ והכוונה - מנוע החיפוש וההשוואה של בתי האבות והדיור המוגן בישראל"
        />
        <meta name="robots" content="index, follow" />
        <meta name="revisit-after" content="1 days" />
        <meta name="googlebot" content="index, follow" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
      </Helmet>
      <Box
        minHeight="75vh"
        sx={{
          backgroundImage: `linear-gradient(to bottom, ${alpha(
            theme.palette.grey[600],
            0.1
          )} 15%, ${alpha(
            theme.palette.primary.light,
            0.1
          )}), url(${lighthouseBg})`,
          backgroundSize: 'cover',
          // backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          px: 0,
          backgroundOrigin: 'border-box',
          backgroundAttachment: 'fixed'
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            zIndex: 2,
            mb: '22px',
            mt: 12
          }}
          spacing={2}
        >
          {/* <Grid item xs={12} sx={{ flex: 1 }} /> */}
          <Grid
            item
            xs={12}
            sx={({ breakpoints }) => ({
              transform: 'translateY(30px)',
              mt: -3,
              [breakpoints.down('md')]: {
                transform: 'translateY(45px)'
              }
            })}
          >
            <SearchButtonGroup
              handleAddressChange={handleAddressChange}
              handleSliderValueChange={handleSliderValueChange}
              searchQuery={searchQuery}
              key="search-buttongroup-in-home"
            />
          </Grid>
        </Grid>
      </Box>
      <Card
        className="scrollContainer"
        sx={{
          // p: 2,
          mx: { xs: 2, lg: 2 },
          mt: -8,
          mb: 4,
          pt: !mapShowing ? 0 : 10,
          // backgroundColor: alpha(theme.palette.grey[200], 0.5),
          backgroundImage: `linear-gradient(to top, ${alpha(
            theme.palette.primary.light,
            0.5
          )} -10%, ${theme.palette.grey[200]} 20%)`,
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: theme.shadows[16],
          transition: 'all 500ms',
          pb: 8,
          zIndex: 1,
          position: 'relative'
        }}
      >
        <Grid
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            mt: 2,
            flexDirection: 'column'
          }}
          item
          xs={12}
          md={12}
        >
          <Typography
            variant="h1"
            color={theme.palette.primary.dark}
            // mt={}
            mb={1}
            sx={({ breakpoints }) => ({
              fontSize: '4rem',
              [breakpoints.down('md')]: {
                fontSize: '3rem'
              }
            })}
            className="hero-title mgdalor-main-title"
          >
            {t('heroSection.mgdalor')}
          </Typography>
          <Typography
            variant="h1"
            color={theme.palette.primary.main}
            textAlign="center"
            mb={1}
            sx={({ breakpoints }) => ({
              [breakpoints.down('md')]: {
                fontSize: '1.4rem'
              }
            })}
            className="hero-title mgdalor-sub-title"
          >
            {/* <Typography component="span"> */}
            {/* בתי האבות TODO :: */}
            {/* </Typography> */}
            {t('heroSection.mainTitle')}
            <Typography
              variant="span"
              textAlign="center"
              mb={1}
              sx={({ breakpoints }) => ({
                [breakpoints.down('md')]: {
                  fontSize: '1.4rem'
                },
                fontWeight: 600,
                color: theme.palette.error.light
              })}
              className={matchDownMd ? 'smHighlighted' : 'highlighted'}
            >
              {` ${t('heroSection.forRetirement')} `}
            </Typography>
            {` ${t('heroSection.inIsrael')}`}
          </Typography>
          <div className="chevronsContainer">
            <div className="chevron" />
            <div className="chevron" />
            <div className="chevron" />
          </div>
        </Grid>
        <Counters />
      </Card>
      <Services />
      <UsersPricingComponent />
      {areResultsLoading && (
        <SearchLoader
          key="loader-in-home-for-results"
          open={areResultsLoading}
        />
      )}
      <Box
        className="filledInfoSection"
        sx={{
          minHeight: '8rem',
          transform: 'rotate(180deg)'
        }}
        key="wavy-bg-box"
      />
      <Paper
        sx={{
          backgroundColor: '#3f51870d'
        }}
      >
        <Information />
        <HowDoesItWork />
        <ParallaxSection />
        <Testimonials />
        <TalkToUs />
      </Paper>
    </Paper>
  );
}

export default Home;
