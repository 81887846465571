import i18n from 'src/i18n';

const kosherOptions = [
  { name: 'kosher', label: i18n.t('kosherOptions.kosher') },
  { name: 'notKosher', label: i18n.t('kosherOptions.notKosher') },
  { name: 'rationalKosher', label: i18n.t('kosherOptions.rationalKosher') },
  { name: 'notSpecified', label: i18n.t('kosherOptions.notSpecified') }
];

export default kosherOptions;
