import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'src/theme';

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}));

export default StyledTableRow;
