import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc
} from '@firebase/firestore';
import {
  DeleteForeverOutlined,
  Image,
  InfoOutlined,
  VideoFile
} from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ref, uploadBytes } from 'firebase/storage';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db, storage } from 'src/_firebase/firebase';
import commonConfig from 'src/common/config';
import IOSSwitch from 'src/components/shared/IOSSwitch/IOSSwitch';
import UploadInput from 'src/components/shared/Upload/UploadInput';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import marketplaceCategories from './marketplaceCategories';

const bytesToMB = (bytes) =>
  `${(bytes / (1024 * 1024)).toFixed(bytes < 1024 * 1024 ? 3 : 1)}MB`;

const getImageAvatar = (file) => {
  switch (file.type.split('/')[0]) {
    case 'video':
      return <VideoFile />;
    default:
      return <Image />;
  }
};

const AddProductToMarketplaceDialog = ({
  open,
  onClose,
  setMarketplaceProducts,
  marketplaceProducts
}) => {
  AddProductToMarketplaceDialog.propTypes = {
    open: PropTypes.any,
    onClose: PropTypes.func,
    setMarketplaceProducts: PropTypes.func,
    marketplaceProducts: PropTypes.array
  };
  const { currentUser } = useCurrentUserContext();

  const [newProductValues, setNewProductValues] = useState({
    product_name: '',
    product_price: 0,
    published: false,
    description: '',
    category: []
  });
  const [isEditting, setIsEditting] = useState();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const { snackbarState, setSnackbarState } = useSnackbar();

  const handleChangeNewProductValues = (e) => {
    setNewProductValues({
      ...newProductValues,
      [e.target.name]: e.target.value
    });
    // console.log(e.target.name, e.target.value);
  };
  const handleChangePublishedStatus = (newStatus) => {
    setNewProductValues({ ...newProductValues, published: newStatus });
  };

  const uploadProductToMarketplace = async (product_id) => {
    const storageRef = ref(storage, `marketplace/products/${product_id}`);
    try {
      await uploadBytes(storageRef, uploadedFiles, {
        customMetadata: {
          seller_id: currentUser.user_doc_id
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseDialog = () => {
    onClose();
    setIsEditting();
  };
  const handleSaveNewProduct = async () => {
    let product_id;
    const marketplaceRef = collection(db, 'marketplace');
    addDoc(marketplaceRef, {
      ...newProductValues,
      seller_id: currentUser.user_doc_id,
      added_at: serverTimestamp()
    })
      .then(async (val) => {
        product_id = val.id;
        await uploadProductToMarketplace(val.id);
        const temp = [...marketplaceProducts];
        temp.unshift({
          ...newProductValues,
          seller_id: currentUser.user_doc_id,
          product_id
        });
        setMarketplaceProducts(temp);
        handleCloseDialog();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkIfSavingBtnDisabled = () => {
    const { product_name, product_price, description, category } =
      newProductValues;
    if (Object.keys(uploadedFiles).length === 0) {
      return true;
    }
    if (product_name === '' || product_price === 0 || description === '') {
      return true;
    }
    if (category.length === 0) {
      return true;
    }
    return false;
  };

  const handleSaveNewMarketplaceProductEdits = async () => {
    const { product_id } = open;
    if (product_id) {
      const productRef = doc(db, `marketplace/${product_id}`);
      const { product_price, description, product_name, category, published } =
        newProductValues;
      const dataToSave = {
        product_price,
        description,
        product_name,
        category,
        published
      };

      setDoc(productRef, dataToSave, {
        merge: true
      })
        .then(() => {
          const temp = [...marketplaceProducts];
          const index = temp.findIndex((el) => el.product_id === product_id);
          if (index > -1) {
            temp[index] = {
              ...temp[index],
              ...dataToSave
            };
            setMarketplaceProducts(temp);
            handleCloseDialog();
            setSnackbarState({
              ...snackbarState,
              open: true,
              msg: 'השינויים נשמרו בהצלחה',
              severity: 'success'
            });
          }
        })
        .catch((err) => {
          console.log('err while updating product doc', err);
          setSnackbarState({
            ...snackbarState,
            open: true,
            msg: 'לא הצלחנו לשמור את השינויים, נא לנסות שוב',
            severity: 'error'
          });
        });
    }
  };

  useEffect(() => {
    // console.log(open);
    if (typeof open === 'object') {
      setIsEditting(true);
      setNewProductValues(open);
    } else {
      setIsEditting(false);
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="md" open={Boolean(open)}>
      <DialogTitle>הוספת פריט חדש</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              sx={{
                width: 'fit-content',
                minWidth: '95px',
                alignItems: 'center'
              }}
            >
              <FormControlLabel
                checked={newProductValues.published}
                control={<IOSSwitch />}
                onChange={(_, checked) => handleChangePublishedStatus(checked)}
                sx={{ margin: 'auto' }}
              />
              <Typography variant="subtitle1">שיתוף הפריט</Typography>
            </Stack>
          </Grid>
          <Grid item container spacing={0} xs={12}>
            <Grid item xs={12} md={5.9}>
              <Autocomplete
                value={newProductValues.category}
                multiple
                aria-required
                fullWidth
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setNewProductValues({
                      ...newProductValues,
                      category: newValue
                    });
                  } else if (newValue && newValue.inputValue) {
                    setNewProductValues({
                      ...newProductValues,
                      category: [newValue.inputValue]
                    });
                  } else {
                    setNewProductValues({
                      ...newProductValues,
                      category: newValue
                    });
                    // setValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.title
                  );
                  if (inputValue !== '' && !isExisting) {
                    options.push({
                      inputValue,
                      value: `הוספת "${inputValue}"`,
                      label: inputValue
                    });
                  }
                  return options;
                }}
                selectOnFocus
                clearOnBlur
                disableCloseOnSelect
                handleHomeEndKeys
                id="free-solo-with-marketplace-categories"
                options={marketplaceCategories}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.label;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    required
                    helperText="בחר מהרשימה או הוסף קטגוריה מתאימה"
                    FormHelperTextProps={{
                      sx: {
                        fontSize: '14px'
                      }
                    }}
                    {...params}
                    label="קטגוריות"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              name="product_name"
              label="שם פריט"
              placeholder="השם שיוצג לרוכשים"
              onChange={handleChangeNewProductValues}
              value={newProductValues.product_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              name="product_price"
              // type="number"
              value={newProductValues.product_price}
              label="מחיר"
              helperText="עלות הפרסום הינה 20% מהמחיר שתקבע"
              InputProps={{
                endAdornment: (
                  <Tooltip title="המחיר שתקבל מכל רוכש הוא 80% מהמחיר שתזין בשדה זה">
                    <Icon>
                      <InfoOutlined />
                    </Icon>
                  </Tooltip>
                )
              }}
              placeholder="כמה יהלומים תרצה לבקש עבור הפריט"
              onChange={(e) => {
                const replaced = e.target.value.replace(/\D+/g, '');
                handleChangeNewProductValues({
                  target: {
                    value: Number(replaced),
                    name: 'product_price'
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="description"
              value={newProductValues.description}
              label="תיאור"
              rows={3}
              multiline
              // placeholder="כמה יהלומים תרצה לבקש עבור הפריט"
              onChange={handleChangeNewProductValues}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {uploadedFiles && uploadedFiles.id && (
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    {getImageAvatar(uploadedFiles)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={uploadedFiles.name}
                    secondaryTypographyProps={{
                      color:
                        uploadedFiles.size / (1024 * 1024) > 20
                          ? 'error'
                          : theme.palette.text.secondary
                    }}
                    secondary={
                      uploadedFiles.size / (1024 * 1024) <= 20
                        ? bytesToMB(uploadedFiles.size)
                        : 'נסה לדחוס את הקובץ לפני ההעלאה'
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      color="error"
                      onClick={() => setUploadedFiles([])}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
              {uploadedFiles.length}
            </Grid>
          )}
          <Grid item xs={12}>
            <UploadInput
              previewThumbs={false}
              multiple={false}
              secondary="נפח הקובץ יכול להיות עד 20MB"
              files={uploadedFiles}
              setFiles={setUploadedFiles}
              accept={commonConfig.marketplaceAcceptedTypes}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isEditting ? (
          <Button
            variant="contained"
            onClick={handleSaveNewMarketplaceProductEdits}
          >
            עריכת המוצר
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={checkIfSavingBtnDisabled()}
            onClick={() => {
              handleSaveNewProduct();
              setIsEditting();
            }}
          >
            הוספת פריט
          </Button>
        )}
        <Button variant="outlined" onClick={handleCloseDialog}>
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductToMarketplaceDialog;
