import { db } from 'src/_firebase/firebase';

const {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  getDoc
} = require('@firebase/firestore');
const { default: commonConfig } = require('src/common/config');

const handleCreateNewPersonalAccount = async (
  values,
  createUserWithEmailAndPassword,
  userType
) => {
  const collectionRef = collection(db, 'users');
  const userRef = doc(db, `users/${values.id}`);
  getDoc(userRef)
    .then((userDoc) => {
      if (userDoc.exists()) {
        return { exists: true, id: values.id };
      }
      const { password, ...rest } = values;
      return addDoc(collectionRef, {
        ...rest,
        type: userType,
        created_at: serverTimestamp()
      })
        .then(async () => {
          await createUserWithEmailAndPassword(
            `${values.id}@${commonConfig.emailAuthSuffix}`,
            password
          ) // sendNewPesonalAJoiningConfirmationEmail sends an email
            .catch((err) => {
              console.log('err creating in auth', err);
            });
        })
        .catch((err) => {
          console.log('err adding to db', err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default handleCreateNewPersonalAccount;
