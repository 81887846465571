import {
  collection,
  getDocs,
  limit,
  orderBy,
  query
} from '@firebase/firestore';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  Skeleton,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import getOrgRef from 'src/utils/getOrgRef';
import RecentUpdateItem from './RecentUpdateItem';

const NewPostsUpdatesCard = ({ handleViewCommunityAttachedFile }) => {
  NewPostsUpdatesCard.propTypes = {
    handleViewCommunityAttachedFile: PropTypes.func
  };
  const { selectedOrg } = useSelectedOrg();
  const { currentUser } = useCurrentUserContext();
  const [recentUpdates, setRecentUpdates] = useState();

  const handleRetrieveRecentUpdates = (org_ref) => {
    // console.log(1);
    const orgCommunityRef = collection(db, `orgs/${org_ref}/community`);
    const q = query(orgCommunityRef, orderBy('created_at', 'desc'), limit(5));
    getDocs(q)
      .then((snap) => {
        const items = snap.docs.map((item) => ({
          ...item.data(),
          post_id: item.id
        }));
        setRecentUpdates(items);
      })
      .catch((err) => {
        console.log(err);
        setRecentUpdates([]);
      });
  };
  useEffect(() => {
    if (!recentUpdates && selectedOrg && currentUser) {
      const org_ref = getOrgRef(currentUser, selectedOrg);
      handleRetrieveRecentUpdates(org_ref);
    }
  }, [recentUpdates, currentUser, selectedOrg]);
  return (
    <Card elevation={0}>
      <CardHeader
        title="פורסמו לאחרונה"
        titleTypographyProps={{
          fontSize: 16
        }}
      />
      <Divider />
      <CardContent>
        {recentUpdates && recentUpdates.length === 0 ? (
          <Typography>טרם נוספו עדכונים בארגון שלך</Typography>
        ) : recentUpdates && recentUpdates.length > 0 ? (
          <List>
            {recentUpdates.map((update, i) => (
              <RecentUpdateItem
                key={`${update.post_id}-${uniqueId('fragment')}`}
                handleViewCommunityAttachedFile={
                  handleViewCommunityAttachedFile
                }
                update={update}
                i={i}
                totalLength={5}
              />
            ))}
          </List>
        ) : (
          <List>
            {[1, 2, 3].map((skel) => (
              <ListItem key={`${skel}-listitem-skeleton`}>
                <Skeleton key={skel} variant="circular" />
                <Skeleton variant="text" width={120} height={24} />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default NewPostsUpdatesCard;
