import i18n from 'src/i18n';

const jobsTitles = [
  {
    label: i18n.t('positions.nurse'),
    name: 'nurse'
  },
  {
    label: i18n.t('positions.doctor'),
    name: 'doctor'
  },
  {
    label: i18n.t('positions.occupationalTherapist'),
    name: 'occupationalTherapist'
  },
  {
    label: i18n.t('positions.physicalTherapist'),
    name: 'physicalTherapist'
  },
  {
    label: i18n.t('positions.socialWorker'),
    name: 'socialWorker'
  },
  {
    label: i18n.t('positions.dietitian'),
    name: 'dietitian'
  },
  {
    label: i18n.t('positions.communicationalTherapy'),
    name: 'communicationalTherapy'
  },
  {
    label: i18n.t('positions.pharmacist'),
    name: 'pharmacist'
  },
  {
    label: i18n.t('positions.administrativeJob'),
    name: 'administrativeJob'
  },
  {
    label: i18n.t('positions.caregiver'),
    name: 'caregiver'
  },
  {
    label: i18n.t('positions.other'),
    name: 'other'
  }
];

export default jobsTitles;
