import { doc, getDoc } from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const getUserSignature = async (signaturesPath, user_doc_id) => {
  const signRef = doc(db, `${signaturesPath}/${user_doc_id}`);
  return getDoc(signRef)
    .then((userSign) => {
      if (userSign.exists()) {
        return { ...userSign.data() };
      }
      return {};
    })
    .catch((err) => {
      console.log('get user sign', err);
      return {};
    });
};

export default getUserSignature;
