import { Close } from '@mui/icons-material';
import {
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Skeleton
} from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fallbackImage1 from 'src/assets/Brilliant Solution For Your Home/1.png';
import fallbackImage2 from 'src/assets/Brilliant Solution For Your Home/2.png';
import theme from 'src/theme';
import Slider from '../shared/Slider/Slider';
import './style.css';

const fallbackImages = [
  { url: fallbackImage1, name: 'fallback1' },
  { url: fallbackImage2, name: 'fallback2' },
  { url: fallbackImage1, name: 'fallback3' }
];

const OrgPicsSliderShow = ({ orgPageValues }) => {
  OrgPicsSliderShow.propTypes = {
    orgPageValues: PropTypes.any
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'orgPage'
  });

  const [isFullScreenOrgImagesDialogOpen, setFullScreenOrgImagesDialogOpen] =
    useState({ state: false });

  const [mappedPics, setMappedPics] = useState();

  useEffect(() => {
    if (orgPageValues.org_pictures) {
      const mapped = [];
      orgPageValues.org_pictures.reverse().forEach((pic, i) => {
        if (i % 3 === 2) {
          if (mapped[0]) {
            mapped[0].push(pic);
          } else {
            mapped.push([pic]);
          }
          // mapped.push([pic]);
        } else if (i % 3 === 1) {
          if (mapped[1]) {
            mapped[1].push(pic);
          } else {
            mapped.push([pic]);
          }
        } else {
          if (mapped[2]) {
            mapped[2].push(pic);
          } else {
            mapped.push([pic]);
          }
        }
      });
      if (mapped.length === 1) {
        mapped.push([{ name: 'fallback1', url: fallbackImage1 }]);
        mapped.push([{ name: 'fallback2', url: fallbackImage2 }]);
      }
      if (mapped.length === 2) {
        mapped.push([{ name: 'fallback1', url: fallbackImage1 }]);
      }
      setMappedPics(mapped);
    }
  }, [orgPageValues]);

  const handleOpenFullScreenImage = (img, parentIndex, picIndex) => {
    const pictures = [...mappedPics[0], ...mappedPics[1], ...mappedPics[2]];
    setFullScreenOrgImagesDialogOpen({
      state: true,
      selectedImg: img,
      pictures
    });
  };

  const handleCloseFullScreenImages = () => {
    setFullScreenOrgImagesDialogOpen({ state: false });
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: '95%',
          minWidth: '200px',
          margin: 'auto',
          backgroundColor: 'rgba(0,0,0,0)',
          position: 'relative'
        }}
      >
        <Grid
          container
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mx: 'auto',
            py: 6
          }}
          spacing={3}
        >
          {mappedPics && mappedPics.length > 0 ? (
            mappedPics.map((setOfPics, parentIndex) => (
              <Grid
                key={`set-of-pics-${uniqueId('orgpics')}`}
                item
                xs={12}
                sm={6}
                lg={4}
                sx={{
                  height: '500px',
                  maxWidth: '360px',
                  maxHeight: '550px !important'
                }}
              >
                {setOfPics && setOfPics.length > 0 ? (
                  <Slider
                    key={uniqueId('org-slider-show')}
                    slides={setOfPics}
                    showNumbering={Boolean(false)}
                    sliderSx={{
                      borderRadius: '16em 16em 0 0',
                      minHeight: '350px !important',
                      boxShadow: theme.shadows[6],
                      maxHeight: '100%'
                    }}
                    singleSlideSx={{
                      position: 'relative',
                      height: '100%',
                      objectFit: 'fill',
                      maxWidth: '100% !important'
                    }}
                    slideContainerSx={{
                      height: '100%'
                    }}
                  />
                ) : (
                  <CardMedia
                    className="img"
                    style={{
                      height: '100%',
                      objectFit: 'auto'
                    }}
                    src={fallbackImage1}
                    component="img"
                    alt="fallback 1"
                  />
                )}
              </Grid>
            ))
          ) : mappedPics && mappedPics.length === 0 ? (
            <>
              {fallbackImages && fallbackImages.length > 0 ? (
                fallbackImages.map((pic) => (
                  <Grid
                    key={`set-of-pics-${uniqueId('orgpics')}`}
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{
                      height: '100%',
                      maxWidth: '360px',
                      maxHeight: '550px'
                    }}
                  >
                    <Slider
                      key={uniqueId('fallbackImages-slider-show')}
                      slides={[pic]}
                      showNumbering={Boolean(false)}
                      sliderSx={{
                        borderRadius: '16em 16em 0 0',
                        minHeight: '350px !important',
                        boxShadow: theme.shadows[6],
                        maxHeight: '100%'
                      }}
                      singleSlideSx={{
                        position: 'relative',
                        height: '100%',
                        objectFit: 'fill',
                        maxWidth: '100% !important'
                      }}
                      slideContainerSx={{
                        height: '100%'
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Skeleton />
              )}
            </>
          ) : (
            <Skeleton />
          )}
        </Grid>
      </Card>
      <Dialog
        keepMounted={Boolean(false)}
        open={isFullScreenOrgImagesDialogOpen.state}
        maxWidth="lg"
        fullWidth
        onClose={handleCloseFullScreenImages}
      >
        <DialogContent sx={{ width: '100%' }}>
          <IconButton onClick={handleCloseFullScreenImages}>
            <Close />
          </IconButton>
          {isFullScreenOrgImagesDialogOpen.state &&
          isFullScreenOrgImagesDialogOpen.pictures &&
          isFullScreenOrgImagesDialogOpen.pictures.length > 0 ? (
            <Slider
              key={uniqueId('fullscreen-org-slider-show')}
              slides={isFullScreenOrgImagesDialogOpen.pictures}
              showNumbering={Boolean(false)}
              sliderSx={{
                borderRadius: '16em 16em 0 0',
                minHeight: '100% !important',
                maxHeight: '100%'
              }}
              singleSlideSx={{
                position: 'relative',
                height: '100%',
                objectFit: 'cover',
                maxWidth: '100% !important'
              }}
              slideContainerSx={{
                height: '100%'
              }}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{ width: '100%', height: '100%' }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseFullScreenImages}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrgPicsSliderShow;
