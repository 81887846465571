const newCustomerPhoneNumber = (phone) => `
    <table
      style="
        background: #eee;
        padding: 40px;
        border: 1px solid #ddd;
        margin: 0 auto;
        font-family: calibri;
      "
    >
      <tr>
        <td>
          <table
            style="
              background: #fff;
              width: 100%;
              border: 1px solid #ccc;
              padding: 0;
              margin: 0;
              border-collapse: collapse;
              max-width: 100%;
              border-radius: 10px;
            "
          >
            <!-- Logo -->
            <tr>
              <td style="padding: 10px 30px; text-align: center; margin: 0;">
                <p>
                  <a href="#">
                    <img
                      src="https://www.mgdalor.com/static/media/mgdalor.10abff85f83dc4883341.png"
                      width="100"
                    />
                  </a>
                </p>
              </td>
            </tr>

            <!-- Welcome Salutation -->
            <tr>
              <td
                style="
                  padding: 10px 30px;
                  margin: 0;
                  font-size: 2.5em;
                  color: #4a7ba5;
                  text-align: center;
                "
              >
                פנייה חדשה במגדלור
              </td>
            </tr>
            <!-- User Msg -->
            <tr>
              <td style="padding: 10px 30px; margin: 0; text-align: right;">
                <p>שלום,</p>
                <p>לקוח חדש השאיר את פרטיו דרך האתר</p>
              </td>
            </tr>
            <tr>
              <td
                style="
                  padding: 10px 30px;
                  margin: 0;
                  background: #f36565;
                  color: #ffffff;
                "
              >
                <table style="width: 100%; padding: 10px; text-align: center;">
                  <tr>
                    <td colspan="2" style="font-size: 1.8em;">פרטי הפנייה</td>
                  </tr>
                  <tr style="margin-top: 15px;">
                    <td style="text-align: right;">
                      <p>${phone}</p>
                    </td>
                    <td style="font-size: 1.1em; color: black;">מספר טלפון</td>
                  </tr>
                  <tr style="margin-top: 15px;">
                    <td style="text-align: right;">
                      <p>אתר מגדלור</p>
                    </td>
                    <td style="font-size: 1.1em; color: black;">הפנייה נעשתה דרך</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style="
                  padding: 10px 30px;
                  margin: 0;
                  background: #555;
                  color: #fff;
                  border-top: 1px solid #ccc;
                "
              >
                <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p> -->
                <p>
                  נא ללחוץ על הלינק כדי
                  <a
                    href="https://www.mgdalor.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    style="color: #fff;"
                    >להיכנס</a
                  >
                  לחשבון שלך כניסה
                </p>
                <p style="margin: 50px 0; text-align: center;">
                  <a
                    style="display: inline-block; padding-right: 10px;"
                    href="https://www.facebook.com/mgdalor/"
                  >
                    <svg
                      style="width: 25px;"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                    >
                      <title>Facebook</title>
                      <path
                        style="fill: #fff;"
                        d="M45.353 0h-42.706a2.625 2.625 0 0 0-2.647 2.647v42.706a2.625 2.625 0 0 0 2.647 2.647h23.008v-18.569h-6.268v-7.241h6.229v-5.333c0-6.19 3.776-9.577 9.3-9.577a56.108 56.108 0 0 1 5.606.273v6.462h-3.851c-3 0-3.581 1.44-3.581 3.543v4.632h7.163l-.934 7.241h-6.229v18.569h12.263a2.625 2.625 0 0 0 2.647-2.647v-42.706a2.625 2.625 0 0 0-2.647-2.647z"
                      ></path>
                    </svg>
                  </a>
                  <a
                    style="display: inline-block; padding-right: 10px;"
                    href="https://twitter.com/"
                  >
                    <svg
                      style="width: 25px;"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                    >
                      <title>Twitter</title>
                      <path
                        style="fill: #fff;"
                        d="M48 9.117a19.935 19.935 0 0 1-5.658 1.55 9.855 9.855 0 0 0 4.324-5.441 19.789 19.789 0 0 1-6.234 2.374 9.866 9.866 0 0 0-17.045 6.739 9.238 9.238 0 0 0 .252 2.234 27.932 27.932 0 0 1-20.287-10.267 9.555 9.555 0 0 0-1.333 4.937 9.815 9.815 0 0 0 4.36 8.18 9.851 9.851 0 0 1-4.469-1.223v.108a9.858 9.858 0 0 0 7.89 9.656 10.248 10.248 0 0 1-2.595.36 9.932 9.932 0 0 1-1.838-.18 9.946 9.946 0 0 0 9.189 6.847 19.745 19.745 0 0 1-12.213 4.216 16.691 16.691 0 0 1-2.343-.144 28 28 0 0 0 15.1 4.437c18.09 0 28-14.991 28-28 0-.432 0-.865-.036-1.261a20.192 20.192 0 0 0 4.936-5.122z"
                      ></path>
                    </svg>
                  </a>
                  <a
                    style="display: inline-block; padding-right: 10px;"
                    href="https://www.instagram.com/"
                  >
                    <svg
                      style="width: 25px;"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                    >
                      <title>Instagram</title>
                      <path
                        style="fill: #fff;"
                        d="M24 4.324c6.408 0 7.167.024 9.7.14a13.28 13.28 0 0 1 4.456.826 7.436 7.436 0 0 1 2.76 1.8 7.436 7.436 0 0 1 1.8 2.76 13.28 13.28 0 0 1 .826 4.456c.115 2.531.14 3.29.14 9.7s-.024 7.167-.14 9.7a13.28 13.28 0 0 1-.826 4.456 7.948 7.948 0 0 1-4.555 4.555 13.28 13.28 0 0 1-4.456.826c-2.53.115-3.289.14-9.7.14s-7.168-.024-9.7-.14a13.28 13.28 0 0 1-4.456-.826 7.436 7.436 0 0 1-2.76-1.8 7.436 7.436 0 0 1-1.8-2.76 13.28 13.28 0 0 1-.825-4.457c-.115-2.531-.14-3.29-.14-9.7s.024-7.167.14-9.7a13.28 13.28 0 0 1 .826-4.456 7.436 7.436 0 0 1 1.8-2.76 7.436 7.436 0 0 1 2.76-1.8 13.28 13.28 0 0 1 4.45-.82c2.531-.115 3.29-.14 9.7-.14m0-4.324c-6.518 0-7.335.028-9.9.144a17.615 17.615 0 0 0-5.821 1.116 11.764 11.764 0 0 0-4.251 2.768 11.764 11.764 0 0 0-2.768 4.251 17.615 17.615 0 0 0-1.116 5.821c-.116 2.565-.144 3.382-.144 9.9s.028 7.335.144 9.9a17.616 17.616 0 0 0 1.116 5.821 11.765 11.765 0 0 0 2.768 4.251 11.764 11.764 0 0 0 4.251 2.768 17.615 17.615 0 0 0 5.821 1.116c2.565.116 3.382.144 9.9.144s7.335-.028 9.9-.144a17.616 17.616 0 0 0 5.826-1.116 12.272 12.272 0 0 0 7.019-7.019 17.616 17.616 0 0 0 1.111-5.821c.116-2.565.144-3.382.144-9.9s-.028-7.335-.144-9.9a17.616 17.616 0 0 0-1.116-5.821 11.764 11.764 0 0 0-2.768-4.251 11.764 11.764 0 0 0-4.251-2.768 17.616 17.616 0 0 0-5.821-1.116c-2.565-.116-3.382-.144-9.9-.144zm0 11.676a12.324 12.324 0 1 0 12.324 12.324 12.324 12.324 0 0 0-12.324-12.324zm0 20.324a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm12.811-23.691a2.88 2.88 0 1 0 2.88 2.88 2.88 2.88 0 0 0-2.88-2.88z"
                      ></path>
                    </svg>
                  </a>
                  <a
                    style="display: inline-block; padding-right: 10px;"
                    href="https://www.linkedin.com/"
                  >
                    <svg
                      style="width: 25px;"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                    >
                      <title>LinkedIn</title>
                      <path
                        style="fill: #fff;"
                        d="M0 0v48h48v-48h-48zm14.8 40.64h-6.92v-22.24h6.92v22.24zm-3.48-25.28a4 4 0 1 1 4-4 3.986 3.986 0 0 1-4 4zm22.44 25.28v-10.8c0-2.56-.04-5.88-3.6-5.88-3.6 0-4.16 2.8-4.16 5.72v11h-6.88v-22.28h6.64v3.04h.08a7.21 7.21 0 0 1 6.52-3.6c7 0 8.28 4.6 8.28 10.6v12.2h-6.88z"
                      ></path>
                    </svg>
                  </a>
                  <a
                    style="display: inline-block; padding-right: 10px;"
                    href="https://www.youtube.com/"
                    ><svg
                      style="width: 25px;"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 721"
                    >
                      <title>YouTube</title>
                      <path
                        style="fill: #fff;"
                        d="M1013,156.3c0,0-10-70.4-40.6-101.4C933.6,14.2,890,14,870.1,11.6C727.1,1.3,512.7,1.3,512.7,1.3h-0.4
        c0,0-214.4,0-357.4,10.3C135,14,91.4,14.2,52.6,54.9C22,85.9,12,156.3,12,156.3S1.8,238.9,1.8,321.6v77.5
        C1.8,481.8,12,564.4,12,564.4s10,70.4,40.6,101.4c38.9,40.7,89.9,39.4,112.6,43.7c81.7,7.8,347.3,10.3,347.3,10.3
        s214.6-0.3,357.6-10.7c20-2.4,63.5-2.6,102.3-43.3c30.6-31,40.6-101.4,40.6-101.4s10.2-82.7,10.2-165.3v-77.5
        C1023.2,238.9,1013,156.3,1013,156.3z M407,493l0-287l276,144L407,493z"
                      ></path>
                    </svg>
                  </a>
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    `;

export default newCustomerPhoneNumber;
