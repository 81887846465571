import { uniqueId } from 'lodash';
import i18n from 'src/i18n';

const programsData = [
  {
    branch: i18n.t('mohCode.tzefat'),
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '10:00',
          to: '14:00'
        }
      },
      {
        day: 'thursday',
        day_label: i18n.t('mohCode.thursday'),
        work_hours: {
          from: '10:00',
          to: '14:00'
        }
      }
    ],
    address: {
      street: 'החלוץ',
      num: '100',
      city: 'tzfat',
      city_label: i18n.t('mohCode.tzefat'),
      zip_code: 1311101,
      mail_box: 1133,
      fax: '026474848'
    },
    id: uniqueId('moh-branch'),
    email: 'zfat.mamash@moh.gov.il',
    district: 'north'
  },
  {
    branch: i18n.t('mohCode.acre'),
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '08:30',
          to: '13:00'
        }
      },
      {
        day: 'wednesday',
        day_label: i18n.t('mohCode.wednesday'),
        work_hours: {
          from: '08:30',
          to: '13:00'
        }
      }
    ],
    address: {
      street: 'נוי דור',
      num: '17',
      city: 'acre',
      city_label: i18n.t('mohCode.acre'),
      zip_code: '2412002',
      mail_box: '2086',
      fax: '026474847'
    },
    id: uniqueId('moh-branch'),
    email: 'acco.mamash@moh.gov.il',
    district: 'north'
  },
  {
    branch: i18n.t('mohCode.tveria'),
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '08:30',
          to: '12:30'
        }
      },
      {
        day: 'wednesday',
        day_label: i18n.t('mohCode.wednesday'),
        work_hours: {
          from: '08:30',
          to: '12:30'
        }
      }
    ],
    address: {
      street: 'אלחדיף',
      num: '40',
      city: 'tveria',
      city_label: i18n.t('mohCode.tveria'),
      zip_code: '1410001',
      mail_box: '63',
      fax: '026474849'
    },
    id: uniqueId('moh-branch'),
    email: 'tveria.mamash@moh.gov.il',
    district: 'north'
  },
  {
    branch: i18n.t('mohCode.nazareth'),
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '08:30',
          to: '12:00'
        }
      },
      {
        day: 'wednesday',
        day_label: i18n.t('mohCode.wednesday'),
        work_hours: {
          from: '08:30',
          to: '12:00'
        }
      }
    ],
    address: {
      street: '1100',
      num: '1',
      city: 'nazareth',
      city_label: i18n.t('mohCode.nazareth'),
      zip_code: '1612101',
      mail_box: '2188',
      fax: '026474846'
    },
    id: uniqueId('moh-branch'),
    email: 'nazareth.mamash@moh.gov.il',
    district: 'north'
  },
  {
    branch: i18n.t('mohCode.affula'),
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '12:30',
          to: '15:30'
        }
      },
      {
        day: 'thursday',
        day_label: i18n.t('mohCode.thursday'),
        work_hours: {
          from: '08:30',
          to: '12:00'
        }
      }
    ],
    address: {
      street: 'ירושלים',
      num: '4',
      city: 'affula',
      city_label: i18n.t('mohCode.affula'),
      zip_code: '1810001',
      mail_box: '24',
      fax: '026474845'
    },
    id: uniqueId('moh-branch'),
    email: 'afula.mamash@moh.gov.il',
    district: 'north'
  },
  {
    branch: i18n.t('mohCode.haifa'),
    district_branch: true,
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '12:30',
          to: '14:30'
        }
      },
      {
        day: 'thursday',
        day_label: i18n.t('mohCode.thursday'),
        work_hours: {
          from: '08:30',
          to: '12:30'
        }
      }
    ],
    address: {
      street: 'שד הפלי"ם',
      num: '15 א',
      city: 'haifa',
      city_label: i18n.t('mohCode.haifa'),
      zip_code: '3309519',
      fax: '026474818',
      description: "בניין ב' קומה 18"
    },
    id: uniqueId('moh-branch'),
    email: '​Geriatryhaifa@MOH.GOV.IL',
    district: 'north'
  },
  {
    branch: i18n.t('mohCode.hadera'),
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      },
      {
        day: 'tuesday',
        day_label: i18n.t('mohCode.tuesday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      }
    ],
    address: {
      mail_box: '214',
      street: 'הלל יפה',
      num: '13',
      city: 'hadera',
      city_label: i18n.t('mohCode.hadera'),
      zip_code: '3810102',
      description: 'קומה 5',
      fax: '026474818'
    },
    id: uniqueId('moh-branch'),
    email: '​Geriatryhadera@MOH.GOV.IL',
    district: 'north'
  },
  {
    branch: i18n.t('mohCode.netanya'),
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '13:00',
          to: '17:00'
        }
      },
      {
        day: 'thursday',
        day_label: i18n.t('mohCode.thursday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      }
    ],
    address: {
      street: 'שד ויצמן',
      num: '23',
      city: 'netanya',
      city_label: i18n.t('mohCode.netanya'),
      zip_code: '4225010',
      fax: '026474812'
    },
    id: uniqueId('moh-branch'),
    email: 'LbnetanyaGeriatr@moh.health.gov.il',
    district: 'center'
  },
  {
    branch: i18n.t('mohCode.petahTikva'),
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '08:00',
          to: '13:00'
        }
      },
      {
        day: 'thursday',
        day_label: i18n.t('mohCode.thursday'),
        work_hours: {
          from: '08:00',
          to: '13:00'
        }
      }
    ],
    address: {
      mail_box: '214',
      street: 'אחד העם',
      num: '31',
      city: 'petahTikva',
      city_label: i18n.t('mohCode.petahTikva'),
      zip_code: '4950708',
      fax: '026474814'
    },
    id: uniqueId('moh-branch'),
    email: 'LBPetachtikvaGeriatr@MOH.GOV.IL',
    district: 'center'
  },
  {
    branch: i18n.t('mohCode.ramla'),
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      },
      {
        day: 'tuesday',
        day_label: i18n.t('mohCode.tuesday'),
        work_hours: {
          from: '11:00',
          to: '15:00'
        }
      }
    ],
    address: {
      street: 'דני',
      num: '3',
      city: 'ramla',
      city_label: i18n.t('mohCode.ramla'),
      zip_code: '7240102',
      fax: '026474805'
    },
    id: uniqueId('moh-branch'),
    email: 'LBRamlaGeriatry@moh.gov.il',
    district: 'center'
  },
  {
    branch: i18n.t('mohCode.rehovot'),
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      },
      {
        day: 'wednesday',
        day_label: i18n.t('mohCode.wednesday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      }
    ],
    address: {
      street: 'אופנהיימר',
      num: '10',
      city: 'rehovot',
      city_label: i18n.t('mohCode.rehovot'),
      zip_code: '7670110',
      fax: '026474806'
    },
    id: uniqueId('moh-branch'),
    email: 'info-ishpuzim@rch.health.gov.il',
    district: 'center'
  },
  {
    branch: i18n.t('mohCode.telaviv'),
    district_branch: true,
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '08:00',
          to: '11:30'
        }
      },
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '08:00',
          to: '11:30'
        }
      },
      {
        day: 'wednesday',
        day_label: i18n.t('mohCode.wednesday'),
        work_hours: {
          from: '08:00',
          to: '11:30'
        }
      }
    ],
    address: {
      street: 'הארבעה',
      num: '12',
      city: 'telaviv',
      city_label: i18n.t('mohCode.telaviv'),
      zip_code: '20301',
      fax: '026474803',
      description: 'קומה 1, חדרים 126 - 128'
    },
    id: uniqueId('moh-branch'),
    email: 'ishpuzs.ta@MOH.health.gov.il',
    district: 'center'
  },
  {
    branch: i18n.t('mohCode.jerusalem'),
    district_branch: true,
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      },
      {
        day: 'tuesday',
        day_label: i18n.t('mohCode.tuesday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      }
    ],
    address: {
      street: 'יפו',
      num: '86',
      city: 'jerusalem',
      city_label: i18n.t('mohCode.jerusalem'),
      zip_code: '9434121',
      fax: '025310028'
    },
    id: uniqueId('moh-branch'),
    email: 'Lbjishpuz@MOH.GOV.IL',
    district: 'center'
  },
  {
    branch: i18n.t('mohCode.ashkelon'),
    district_branch: true,
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '08:00',
          to: '12:00'
        }
      }
    ],
    address: {
      street: 'ההסתדרות',
      num: '2',
      city: 'ashkelon',
      city_label: i18n.t('mohCode.ashkelon'),
      zip_code: '7830604',
      fax: '086745727',
      description:
        'המרכז הרפואי ע"ש ברזילי אשקלון, סמוך לכניסה הראשית, ליד מרפאות החוץ'
    },
    id: uniqueId('moh-branch'),
    district: 'south'
  },
  {
    branch: i18n.t('mohCode.beersheva'),
    district_branch: true,
    work_days: [
      {
        day: 'sunday',
        day_label: i18n.t('mohCode.sunday'),
        work_hours: {
          from: '08:30',
          to: '12:30'
        }
      },
      {
        day: 'tuesday',
        day_label: i18n.t('mohCode.tuesday'),
        work_hours: {
          from: '08:30',
          to: '12:30'
        }
      }
    ],
    address: {
      street: 'התקווה',
      num: '4',
      city: 'beersheva',
      city_label: i18n.t('mohCode.beersheva'),
      zip_code: '8400101',
      mail_box: '10050',
      fax: '025655921'
    },
    id: uniqueId('moh-branch'),
    email: 'bsheishpuz@bsh.health.gov.il',
    district: 'south'
  },
  {
    branch: i18n.t('mohCode.eilat'),
    work_days: [
      {
        day: 'monday',
        day_label: i18n.t('mohCode.monday'),
        work_hours: {
          from: '08:30',
          to: '13:00'
        }
      },
      {
        day: 'wednesday',
        day_label: i18n.t('mohCode.wednesday'),
        work_hours: {
          from: '08:30',
          to: '13:00'
        }
      }
    ],
    address: {
      street: 'שד התמרים',
      city: 'eilat',
      city_label: i18n.t('mohCode.eilat'),
      fax: '086372310'
    },
    id: uniqueId('moh-branch'),
    email: 'bsheishpuz@bsh.health.gov.il',
    district: 'south'
  }
];

export default programsData;
