import checkIfSignedBeforeChange from './checkIfSignedBeforeChange';

const calcOnBoardingProgress = (process) => {
  let progress = 0;
  if (!process) {
    progress = 0;
  } else {
    let sum = 0;
    let total = 0;
    process.forEach((task) => {
      task.sub_tasks.forEach((sub) => {
        total += 2;
        if (sub.passed) {
          if (sub.passed.responsible) {
            const responsibleSignedBeforeChange = checkIfSignedBeforeChange(
              sub.last_modified,
              sub.passed.responsible_signed_at
            );
            if (!responsibleSignedBeforeChange) {
              sum += 1;
            }
          }
          if (sub.passed.employee) {
            const employeeSignedBeforeChange = checkIfSignedBeforeChange(
              sub.last_modified,
              sub.passed.employee_signed_at
            );
            if (!employeeSignedBeforeChange) {
              sum += 1;
            }
          }
        }
      });
    });
    progress = (sum / total) * 100;
  }
  return progress;
};

export default calcOnBoardingProgress;
