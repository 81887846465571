import { Avatar, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import available_funds from 'src/common/availableFunds';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const FundsCompare = ({ StyledTableCell, list, userscrolledhorizontally }) => {
  FundsCompare.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.object,
    userscrolledhorizontally: PropTypes.bool
  };

  const [fundsSortedByOrder, setFundsSortedByOrder] = useState();

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  useEffect(() => {
    if (list && list.available_funds && list.available_funds.length > 0) {
      // console.log(list.available_funds);
      const fundsWithOrder = [];
      list.available_funds.forEach((orgFunds) => {
        const tempOrgFunds = [];
        orgFunds.forEach((orgFund) => {
          const index = available_funds.findIndex((el) => el.name === orgFund);
          if (index > -1) {
            tempOrgFunds.push({ ...available_funds[index] });
          }
        });
        fundsWithOrder.push(tempOrgFunds);
      });
      fundsWithOrder.sort((a, b) => a.order - b.order > 0);
      setFundsSortedByOrder(fundsWithOrder);
      // console.log(fundsWithOrder);
    }
  }, [list]);
  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="funds" />
        {t('availableFunds')}
      </StyledTableCell>
      {list.names && (
        <>
          {fundsSortedByOrder && fundsSortedByOrder.length > 0 ? (
            <>
              {fundsSortedByOrder.map((orgFunds, i) => (
                <StyledTableCell
                  key={`${uniqueId('orgFunds')}-funds`}
                  lengthOfList={list.names.length}
                  align="right"
                >
                  <Grid sx={{ justifyContent: 'center' }} container spacing={1}>
                    {orgFunds &&
                      orgFunds.length > 0 &&
                      orgFunds
                        .sort((a, b) => a.order - b.order)
                        .map((fund) => (
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            sx={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center'
                            }}
                            key={`${uniqueId(fund)}-fund`}
                          >
                            <Stack
                              gap={1}
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Avatar
                                variant="rounded"
                                sx={{
                                  boxShadow: (theme) => theme.shadows[8],
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main
                                }}
                                size="small"
                              >
                                {fund.icon}
                              </Avatar>
                              <Typography
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  fontSize: '75%'
                                }}
                                variant="caption"
                              >
                                {fund.label}
                              </Typography>
                            </Stack>
                          </Grid>
                        ))}
                  </Grid>
                </StyledTableCell>
              ))}
            </>
          ) : (
            <Stack direction="row" gap={1}>
              <Skeleton width={52} height={52} />
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default FundsCompare;
