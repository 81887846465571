import { collection, getDocs, query, where } from '@firebase/firestore';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { db } from 'src/_firebase/firebase';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import AssessmentRequestDialog from '../AssessmentRequestDialog';
import AssessmentProfessionalsContent from './AssessmentProfessionalsContent';
import AssessmentProfessionalsTableTabs from './AssessmentProfessionalsTableTabs';
import serviceProvidersTabsLabel from './serviceProvidersTabsLabel';

const AssessmentProfessionalsTable = () => {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState(0);
  const [serviceProviders, setServiceProviders] = useState();
  const [shownProvidersList, setShownProvidersList] = useState();
  const [isServiceRequestDialogOpen, setServiceRequestDialogOpen] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const getAllServiceProviders = async () => {
    const providersRef = collection(db, 'serviceProviders');
    const q = query(
      providersRef,
      where('published', '==', true),
      where('mgdalor_published', '==', true)
    );
    const providersDocs = await getDocs(q);
    if (providersDocs.empty) {
      setServiceProviders([]);
    } else {
      const providersData = providersDocs.docs.map((providerDoc) => ({
        ...providerDoc.data(),
        provider_id: providerDoc.id
      }));

      const filtered = providersData.filter(
        (el) => el.providerType === serviceProvidersTabsLabel[value].value
      );
      setServiceProviders(providersData);
      setShownProvidersList(filtered);
    }
  };

  useEffect(() => {
    if (!serviceProviders) {
      getAllServiceProviders();
    } else {
      const filtered = serviceProviders.filter(
        (el) => el.providerType === serviceProvidersTabsLabel[value].value
      );
      // console.log(filtered);
      setShownProvidersList(filtered);
    }
  }, [serviceProviders, value]);

  const handleCloseAssessmentRequestDialog = () => {
    setServiceRequestDialogOpen(false);
  };

  useEffect(() => {
    // console.log(location.pathname);
    if (searchParams) {
      // console.log(searchParams);
      const tabValue = searchParams.get('type');
      if (tabValue) {
        const index = serviceProvidersTabsLabel.findIndex(
          (el) => el.value === tabValue
        );
        if (index > -1) {
          setValue(index);
          const el = document.getElementById('serviceProviders');
          el.scrollIntoView({
            behavior: 'smooth'
          });
        }
        setSearchParams();
        window.history.pushState(null, 'מגדלור', [
          `/${routesConst.medicalAssessment}`
        ]);
      }
    }
  }, [searchParams]);

  return (
    <>
      <Paper>
        <Typography
          sx={{
            p: 2,
            color: theme.palette.primary.dark,
            fontSize: matchMdDown ? '7vw' : '3vw'
          }}
          variant="h1"
          id="serviceProviders"
        >
          איתור רופאים ונותני שירות
        </Typography>
        <AssessmentProfessionalsTableTabs
          setShownProvidersList={setShownProvidersList}
          serviceProviders={serviceProviders}
          value={value}
          setValue={setValue}
        />
        <AssessmentProfessionalsContent
          serviceProviders={serviceProviders}
          value={value}
          shownProvidersList={shownProvidersList}
          setServiceRequestDialogOpen={setServiceRequestDialogOpen}
        />
      </Paper>
      {isServiceRequestDialogOpen && (
        <AssessmentRequestDialog
          onClose={handleCloseAssessmentRequestDialog}
          open={isServiceRequestDialogOpen}
        />
      )}
    </>
  );
};

export default AssessmentProfessionalsTable;
