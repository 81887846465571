import { doc, getDoc, serverTimestamp, updateDoc } from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const getCurrentTimestamp = async (user_doc_id) => {
  const userRef = doc(db, `users/${user_doc_id}`);
  return updateDoc(userRef, { timestamp: serverTimestamp() }).then(async () =>
    getDoc(userRef)
      .then((user) => {
        if (user.exists()) {
          const { timestamp } = user.data();
          return timestamp;
        }
      })
      .catch((err) => console.log('err while get timestamp', err))
  );
};

export default getCurrentTimestamp;
