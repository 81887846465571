import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';
import QuestionSlide from '../../sharedPartials/QuestionSlide';
import getQuestionData from '../getQuestionData';
import intro from './intro.jpg';
import painRuler from './painRuler.png';
import qWound from './q-wound.png';
import skin from './skin.png';
import './wounds.css';

/* eslint-disable */
const Wounds = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
}) => {
  Wounds.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    globalCoursewares: PropTypes.array,
    handleSetViewToSignCourseware: PropTypes.func
  };

  return (
    <div
      className="theme-font-montserrat theme-color-sea-yellow"
      style={{ width: '100%', height: '100%' }}
    >
      <div className="reveal wounds">
        <div className="slides">
          <section data-background-image={intro}>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-d52fa8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 271px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 892,
                    left: 34,
                    top: '120.5px',
                    height: 'auto'
                  }}
                  data-name="text-0bb4dd"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 11,
                      fontSize: '197%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.22)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26
                    }}
                  >
                    <p>
                      <span style={{ fontSize: '1.2em' }}>לומדה בנושא</span>
                    </p>
                    <h1>פצעי לחץ</h1>
                    <p
                      style={{
                        fontSize: '0.65em',
                        textAlign: 'center',
                        width: '100%'
                      }}
                    >
                      מערך ההדרכה - מגדלור ייעוץ והכוונה
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-background-image={intro} data-background-opacity="0.63">
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-d52fa8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '472px 80.5px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 892,
                    left: 26,
                    top: '41.5px',
                    height: 'auto'
                  }}
                  data-name="text-0bb4dd"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 11,
                      fontSize: '197%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.22)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26
                    }}
                  >
                    <p>
                      <strong>כיצד נלמד ?</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-475d92"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 205px 0px', zIndex: 12 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 664,
                    left: 148,
                    top: '141.5px',
                    height: 'auto'
                  }}
                  data-origin-id="1ef25073858026c977dbcee1a661a21e"
                  data-name="text-830ef9"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 13,
                      fontSize: '161%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.22)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26
                    }}
                  >
                    <p>
                      ההתקדמות היא באמצעות החצים
                      <br />
                      &lt; או&nbsp; &gt;
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-b0c9cf"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 389.5px 0px', zIndex: 14 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 664,
                    left: 148,
                    top: '291.5px',
                    height: 'auto'
                  }}
                  data-origin-id="107ab5ee224b5c3baacce815c8390191"
                  data-name="text-3b1555"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 15,
                      fontSize: '161%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.22)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26,
                      padding: 3
                    }}
                  >
                    <p>
                      כשמגיעים לשקופית שיש בה שאלה, אי אפשר להתקדם עד שעונים
                      נכון על השאלה
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-image="https://images.unsplash.com/photo-1604079628040-94301bb21b91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDd8fGFic3RyYWN0fGVufDB8fHx8MTY5OTI3MDU2OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.72"
            data-background-position="50% 0%"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 853,
                left: '53.5px',
                top: '103.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content fragment fade-out"
                style={{
                  zIndex: 10,
                  fontSize: '190%',
                  textAlign: 'left',
                  borderWidth: 1
                }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  פצע לחץ הינו אחת הבעיות הרפואיות הנפוצות ביותר בקרב אנשים
                  הנמצאים במצבים פיזיים שתנועתם מוגבלת.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 853,
                left: '53.5px',
                top: '103.5px',
                height: 'auto'
              }}
              data-name="text-5aa492"
            >
              <div
                className="sl-block-content fragment current-visible"
                style={{
                  zIndex: 11,
                  fontSize: '190%',
                  textAlign: 'left',
                  borderWidth: 1
                }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  גורמים רבים יכולים להוביל להיווצרות פצעי לחץ, אך המשותף לכולם
                  הוא היווצרות מצב בו ישנו לחץ הגורם לאיסכמיה שמוביל לנמק.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 853,
                left: '53.5px',
                top: '103.5px',
                height: 'auto'
              }}
              data-name="text-2e153e"
            >
              <div
                className="sl-block-content fragment current-visible"
                style={{
                  zIndex: 12,
                  fontSize: '190%',
                  textAlign: 'left',
                  borderWidth: 1
                }}
                data-fragment-index={1}
              >
                <p style={{ textAlign: 'justify' }}>
                  הלחץ המופעל על העור, הרקמה הרכה, השריר או העצם, מקורו במשקלו
                  של האדם, וכאשר לחץ זה עובר את הלחץ התוך-נימי, נוצר מצב של
                  איסכמיה.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 853,
                left: '53.5px',
                top: '103.5px',
                height: 'auto'
              }}
              data-name="text-3ff37c"
            >
              <div
                className="sl-block-content fragment"
                style={{
                  zIndex: 13,
                  fontSize: '190%',
                  textAlign: 'left',
                  borderWidth: 1
                }}
                data-fragment-index={2}
              >
                <p style={{ textAlign: 'justify' }}>
                  רקמות גופנו מסוגלות לעמוד בלחץ גדול מאוד שמופעל עליהן לזמן
                  קצר, אך לחץ ממושך, גם אם הוא גבוה במעט מהלחץ התוך נימי, מוביל
                  לשרשרת תהליכים שסופה נמק.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-image="https://images.unsplash.com/photo-1604079628040-94301bb21b91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDd8fGFic3RyYWN0fGVufDB8fHx8MTY5OTI3MDU2OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-position="50% 0%"
            data-background-opacity="0.56"
            data-background-color="rgba(213, 166, 189, 0.58)"
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-b738ce"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '153.741px 484.036px 0px',
                  zIndex: 10
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '61.1563px',
                    height: '97.4954px',
                    left: '87.1821px',
                    top: '435.288px'
                  }}
                  data-name="drawing-e867f6"
                  data-origin-id="238cd57fbc4c6a049773662f01bf971e"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 11,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 20px) opacity(100%)',
                      transform: 'rotate(167deg)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                      data-timestamp={1638263356635}
                      style={{ zIndex: 11 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                      >
                        <path
                          fill="rgb(255, 255, 255)"
                          d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '61.1563px',
                    height: '97.4954px',
                    left: '160.586px',
                    top: '435.288px'
                  }}
                  data-name="drawing-36a8f7"
                  data-origin-id="f3fed2f223534e96e96b2d4e47e3e2c8"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 12,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 20px) opacity(100%)',
                      transform: 'rotate(169deg)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                      data-timestamp={1638263356635}
                      style={{ zIndex: 12 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                      >
                        <path
                          fill="rgb(255, 255, 255)"
                          d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 503,
                left: '228.5px',
                top: '59.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>חשוב לזכור</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '628.154px',
                left: '165.923px',
                top: '197.5px'
              }}
              data-name="text-3410a5"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  color: 'rgb(0, 0, 0)',
                  fontSize: '124%',
                  lineHeight: '1.378'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'justify' }}>
                  מניעת פצעי לחץ וטיפול בהם יכול לשפר באופן משמעותי תחלואה,
                  תמותה ואיכות החיים של המטופלים ואף להציל את חייהם.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.59)"
            data-background-image="https://images.unsplash.com/photo-1519751138087-5bf79df62d5b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE2fHxhYnN0cmFjdHxlbnwwfHx8fDE2OTkyNzA1Njl8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.36"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 647,
                left: '156.5px',
                top: '148.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  fontSize: '200%',
                  textAlign: 'left',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <h3 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '1.2em' }}>
                    בואו נראה איך מתפתח פצע לחץ
                  </span>
                </h3>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.59)"
            data-background-image="https://images.unsplash.com/photo-1519751138087-5bf79df62d5b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE2fHxhYnN0cmFjdHxlbnwwfHx8fDE2OTkyNzA1Njl8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.36"
          >
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '262.188px',
                height: '269.69px',
                left: '187.885px',
                top: '126.155px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-e27b5b"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 10 }}>
                  <img
                    className=""
                    data-natural-width={211}
                    data-natural-height={895}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y={0}
                    data-crop-width={1}
                    data-crop-height="0.2425"
                    style={{ left: 0, top: 0, width: 262, height: 1112 }}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895336/Schema_stades_escarres.svg.png"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 306,
                left: '497.116px',
                top: 149
              }}
              data-name="text-b49faf"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '0.9em' }}>
                    שכבת אפידירמיס: (שכבה עליונה)
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '0.9em' }}>
                    במצב של התפתחות של פצע לחץ יש מעורבות של אפידירמיס, אין
                    פגיעה בשלמות העור.
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 509,
                left: '225.5px',
                top: '45.31px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '120%',
                  textAlign: 'left',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <h3 style={{ textAlign: 'center' }}>דרגות פצעי לחץ</h3>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.59)"
            data-background-image="https://images.unsplash.com/photo-1519751138087-5bf79df62d5b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE2fHxhYnN0cmFjdHxlbnwwfHx8fDE2OTkyNzA1Njl8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.36"
          >
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '261.922px',
                height: '264.418px',
                left: '180.769px',
                top: '140.252px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-e27b5b"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 10 }}>
                  <img
                    className=""
                    data-natural-width={211}
                    data-natural-height={895}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y="0.248205"
                    data-crop-width={1}
                    data-crop-height="0.238"
                    style={{ left: 0, top: '-276px', width: 262, height: 1111 }}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895336/Schema_stades_escarres.svg.png"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 306,
                left: 480,
                top: '207.501px'
              }}
              data-name="text-b49faf"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>שכבת דירמיס: (האמצעית)</p>
                <p style={{ textAlign: 'justify' }}>
                  שלב שבו קיים איבוד חלקי של השכבה.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 509,
                left: '225.5px',
                top: '45.31px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '120%',
                  textAlign: 'left',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <h3 style={{ textAlign: 'center' }}>דרגות פצעי לחץ</h3>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.59)"
            data-background-image="https://images.unsplash.com/photo-1519751138087-5bf79df62d5b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE2fHxhYnN0cmFjdHxlbnwwfHx8fDE2OTkyNzA1Njl8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.36"
          >
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '261.923px',
                height: '264.418px',
                left: '188.769px',
                top: '131.252px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-e27b5b"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 10 }}>
                  <img
                    className=""
                    data-natural-width={211}
                    data-natural-height={895}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y="0.49843"
                    data-crop-width={1}
                    data-crop-height="0.238"
                    style={{ left: 0, top: '-554px', width: 262, height: 1111 }}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895336/Schema_stades_escarres.svg.png"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 306,
                left: 480,
                top: '207.501px'
              }}
              data-name="text-b49faf"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  איבוד רקמות משכבות עור עמוקות יותר עם איבוד שכבה תת עורית
                  (היפודירמיס)
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 509,
                left: '225.5px',
                top: '45.31px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '120%',
                  textAlign: 'left',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <h3 style={{ textAlign: 'center' }}>דרגות פצעי לחץ</h3>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.59)"
            data-background-image="https://images.unsplash.com/photo-1519751138087-5bf79df62d5b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE2fHxhYnN0cmFjdHxlbnwwfHx8fDE2OTkyNzA1Njl8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.36"
          >
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '261.923px',
                height: '264.418px',
                left: '184.769px',
                top: '137.791px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-e27b5b"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 10 }}>
                  <img
                    className=""
                    data-natural-width={211}
                    data-natural-height={895}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y="0.747755"
                    data-crop-width={1}
                    data-crop-height="0.238"
                    style={{ left: 0, top: '-831px', width: 262, height: 1111 }}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895336/Schema_stades_escarres.svg.png"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 306,
                left: 480,
                top: '216.501px'
              }}
              data-name="text-b49faf"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  קיימת מעורבות של כל שכבות העור במצב הזה וישנה מעורבות של שריר
                  או שריר ועצם.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 509,
                left: '225.5px',
                top: '45.31px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '120%',
                  textAlign: 'left',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <h3 style={{ textAlign: 'center' }}>דרגות פצעי לחץ</h3>
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 456, left: 252, top: '54.25px', height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '1.2em' }}>
                    שינויים פיזיולוגיים בגיל המבוגר
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 180, top: '190.25px' }}
              data-name="text-59ea69"
            >
              <div
                className="sl-block-content fragment fade-out"
                style={{ zIndex: 11 }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.4em' }}>
                    הגיל והשינויים הפיזיולוגיים הנלווים לו משפיעים על העור
                    המזדקן, ומעלים את הסיכון לפתח פצעי לחץ.
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 180, top: '196.75px' }}
              data-name="text-012162"
            >
              <div
                className="sl-block-content fragment current-visible"
                style={{ zIndex: 12, fontSize: '84%' }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.4em' }}>
                    השינויים הפיזיולוגיים הם: שינויים בעור הזקן, ירידה במערכת
                    החיסון והאטת ריפוי הפצעים, והיצרות כלי הדם בעור בשל התקדמות
                    טרשת עורקים.
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.4em' }}>
                    כמו כן, העור הזקן מאבד את גמישותו ונעשה אטרופי.
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 180, top: '204.25px' }}
              data-name="text-c5d01c"
            >
              <div
                className="sl-block-content fragment current-visible"
                style={{ zIndex: 13 }}
                data-fragment-index={1}
              >
                <p>
                  <span style={{ fontSize: '1.4em' }}>
                    מופיעה השטחה של החיבור בין שכבת עילית העור לשכבת העור
                    האמצעית.
                  </span>
                </p>
                <div
                  // className="sl-block"
                  data-block-type="image"
                  style={{
                    width: '323.604px',
                    height: '300.6px',
                    left: '305.198px',
                    top: '50.7px'
                  }}
                  data-name="image-e4e849"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 13, top: -50 }}
                  >
                    <img
                      className=""
                      data-natural-width={263}
                      data-natural-height={276}
                      data-lazy-loaded=""
                      data-src={skin}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 180, top: '196.75px' }}
              data-name="text-b2deb3"
            >
              <div
                className="sl-block-content fragment current-visible"
                style={{ zIndex: 14 }}
                data-fragment-index={2}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.4em' }}>
                    יש איבוד של הרקמה התת-עורית, פריפוזיה של כלי הדם לשכבת העור
                    האמצעית פוחת, קיימת האטה בחילוף התאים והתאים עצמם מזדקנים.
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-opacity="0.1"
            data-auto-animate=""
            data-background-position="50% 0%"
            data-background-size="initial"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 572, left: 194, top: '49.25px', height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>גורמים להתפתחות פצעי לחץ</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 616, left: '248.5px', top: 199, height: 'auto' }}
              data-name="text-13faec"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  fontSize: '112%',
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  מחלות מערכתיות פוגעות כל אחת באופן שונה:
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 789,
                left: '75.5px',
                top: '255.25px',
                height: 'auto'
              }}
              data-name="text-ca914d"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)'
                }}
              >
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    פגיעות תחושתיות מופיעות במחלות הסכרת, בשבץ מוחי ובטרשת נפוצה
                    - Multiple sclerosis.
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    פגיעה באספקת דם לאזור בשל מחלות כלי דם, הכוללות: פגיעות
                    עורקיות, פגיעות ורידיות או פגיעות לימפתיות - המקשות על
                    הניקוז.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 317,
                left: '321.5px',
                top: '123.5px',
                height: 'auto'
              }}
              data-name="text-9428b5"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  fontSize: '130%',
                  textAlign: 'left',
                  color: 'rgb(106, 168, 79)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    <u>מחלות מערכתיות:</u>
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 572, left: 194, top: '49.25px', height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>גורמים להתפתחות פצעי לחץ</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 616, left: '252.5px', top: 137, height: 'auto' }}
              data-name="text-13faec"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  fontSize: '112%',
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  מחלות מערכתיות פוגעות כל אחת באופן שונה:
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 789,
                left: '79.5px',
                top: '206.25px',
                height: 'auto'
              }}
              data-name="text-ca914d"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)'
                }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    מחלות נוירולוגיות הפוגעות במערכת העצבים המרכזית או במערכת
                    העצבים הפריפרית, הכוללות: מחלות שיטיון (דמנציה) שונות,
                    אירועים מוחיים, פרקינסון וטרשת נפוצה. הפגיעה של מחלות אלה
                    באה לידי ביטוי באי-שקט פסיכומוטורי, בחוסר תחושתי ומוטורי,
                    בעוויתיות וקונטרקטורות של מפרקי הגפיים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מחלות סופניות מסוג סרטן.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 572, left: 194, top: '49.25px', height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>גורמים להתפתחות פצעי לחץ</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 616, left: '252.5px', top: 137, height: 'auto' }}
              data-name="text-13faec"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  fontSize: '112%',
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  מחלות מערכתיות פוגעות כל אחת באופן שונה:
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 789,
                left: '79.5px',
                top: '206.25px',
                height: 'auto'
              }}
              data-name="text-ca914d"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'justify',
                  color: 'rgb(68, 68, 68)'
                }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>כשל חיסוני.</li>
                  <li style={{ textAlign: 'justify' }}>
                    מחלות הגורמות לבצקות, כגון: אי-ספיקת לב, כשל כרוני של הכליות
                    והכבד ותת פעילות של בלוטת התריס.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    כל אלו תורמים לעלייה בפגיעות העור ומשפיעים על שכבת העור
                    העומד תחת לחץ. הגברת פגיעות העור יכולה להיות קשורה גם בשימוש
                    בתרופות הגורמות לישנוניות ולאי-שקט.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 572, left: 194, top: '49.25px', height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>גורמים להתפתחות פצעי לחץ</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 789,
                left: '85.5px',
                top: '228.5px',
                height: 'auto'
              }}
              data-name="text-ca914d"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'right',
                  color: 'rgb(68, 68, 68)',
                  fontSize: '90%',
                  lineHeight: '1.417'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.2em' }}>
                    המצב התפקודי הוא תוצר של מחלות שסיבוכיהן מתבטאים בעלייה
                    בתלות ובתפקוד נמוך. הפגיעות התפקודיות העיקריות הן: חוסר
                    תנועה, חוסר שליטה על הסוגרים והדרדרות קוגניטיבית.
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 317,
                left: '321.5px',
                top: '119.5px',
                height: 'auto'
              }}
              data-name="text-9428b5"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '130%',
                  textAlign: 'left',
                  color: 'rgb(106, 168, 79)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    <u>מצב תפקודי:</u>
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 572, left: 194, top: '49.25px', height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>גורמים להתפתחות פצעי לחץ</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 317,
                left: '321.5px',
                top: '123.5px',
                height: 'auto'
              }}
              data-name="text-9428b5"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  fontSize: '130%',
                  textAlign: 'left',
                  color: 'rgb(106, 168, 79)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    <u>מצב תפקודי:</u>
                  </strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 665, left: 255, top: '186.5px', height: 'auto' }}
              data-name="text-8f5f96"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)',
                  fontSize: '100%',
                  lineHeight: '1.417'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    חוסר תנועה הוא הפגיעה התפקודית הקשה ביותר וגורם הסיכון
                    המשמעותי ביותר להופעת פצעי לחץ.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    חוסר תנועה ואי שינוי תנוחה גורם לקושי בתזוזה של האדם או של
                    האיבר והמשך הלחץ עליו. הסיבות לכך הן:
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 403, left: 482, top: '368.5px', height: 'auto' }}
              data-name="text-209dc9"
            >
              <div
                className="sl-block-content fragment"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)',
                  fontSize: '90%',
                  lineHeight: '1.417'
                }}
                data-has-line-height=""
                data-fragment-index={0}
              >
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    פגיעות במערכת העצבים המרכזית ובמערכת העצבים הפריפרית.
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    פגיעות בפרקים, בעצמות ובשרירים.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 329, left: 126, top: '368.5px', height: 'auto' }}
              data-name="text-d5bf2b"
            >
              <div
                className="sl-block-content fragment"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)',
                  fontSize: '90%',
                  lineHeight: '1.417'
                }}
                data-has-line-height=""
                data-fragment-index={1}
              >
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    הגבלות פיזיות (קשירות).
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    הגבלות כימיות (תרופות).
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 572, left: 194, top: '49.25px', height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>גורמים להתפתחות פצעי לחץ</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 789,
                left: '85.5px',
                top: '200.25px',
                height: 'auto'
              }}
              data-name="text-ca914d"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'left',
                  color: 'rgb(68, 68, 68)',
                  fontSize: '90%',
                  lineHeight: '1.417'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    המצב התזונתי קשור קשר הדוק עם פצעי לחץ בזקנים תשושים ופגיעים
                    הנמצאים במסגרות אשפוז בבתי חולים ובמחלקות לאשפוז ממושך.
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    הופעת פצע לחץ היא ביטוי של מצב קטבולי, המלווה באבדן של
                    חלבונים, בדלדול שרירים ובהרס רקמות.
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    האדם הקשיש נמצא בסיכון רב לתת-תזונה עקב פגיעות רבות יותר
                    הנובעות מבעיות גופניות מרובות, ממצבים חברתיים-כלכליים
                    ונפשיים, ועקב תרופות מרובות.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 317,
                left: '321.5px',
                top: '123.5px',
                height: 'auto'
              }}
              data-name="text-9428b5"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '130%',
                  textAlign: 'left',
                  color: 'rgb(106, 168, 79)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    <u>מצב תזונתי:</u>
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.58)"
            data-background-image="https://images.unsplash.com/photo-1604079628040-94301bb21b91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDd8fGFic3RyYWN0fGVufDB8fHx8MTY5OTI3MDU2OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-position="50% 0%"
            data-background-opacity="0.56"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 503,
                left: '228.5px',
                top: '67.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1
                  style={{
                    textAlign: 'center',
                    fontSize: '150%',
                    backgroundColor: 'limegreen',
                    border: '1px solid black',
                    borderRadius: '4px',
                    letterSpacing: '0.015rem'
                  }}
                >
                  איך מעריכים מצב תזונתי נכון?
                </h1>
                <h1 style={{ textAlign: 'center' }}>הערכת החולה</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '806.154px',
                left: '76.923px',
                top: '206.5px'
              }}
              data-name="text-3410a5"
            >
              <div
                className="sl-block-content fragment fade-out"
                style={{
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  lineHeight: '1.378'
                }}
                data-has-line-height=""
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  המצב החברתי ומשאבי המשפחה וכוחותיה הם חלק בלתי נפרד מההתמודדות
                  היום-יומית עם פצעי לחץ, במיוחד כשמדובר בפצעים כרוניים ובאפשרות
                  של חוסר ריפוי.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '806.154px',
                left: '76.923px',
                top: '206.5px'
              }}
              data-name="text-edffd9"
            >
              <div
                className="sl-block-content fragment"
                style={{
                  zIndex: 12,
                  color: 'rgb(0, 0, 0)',
                  lineHeight: '1.378'
                }}
                data-has-line-height=""
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  בדומה להתמודדות עם מחלה כרונית, הפצע יוצר תסכולים ובעיות
                  רגשיות אצל המטופל ומשפחתו. המטופל סובל מכאבים, מאי-נוחות
                  מתמשכת, מהתעסקות מרובה עם פצעים ומבידוד חברתי. המשפחה נאלצת
                  לדאוג לו לטיפול יום-יומי, נוצרים ריחות לא נעימים, ואשפוזים
                  תכופים הם עניין שבשגרה. כל אלה יחד גורמים לתסכול מתמשך.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.58)"
            data-background-image="https://images.unsplash.com/photo-1604079628040-94301bb21b91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDd8fGFic3RyYWN0fGVufDB8fHx8MTY5OTI3MDU2OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-position="50% 0%"
            data-background-opacity="0.56"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 254,
                left: '629.077px',
                top: '78.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  textAlign: 'left',
                  lineHeight: '1.04',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
              >
                <p>
                  <u>
                    <strong>הערכת החולה תכלול</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '595.154px',
                left: '287.923px',
                top: '166.5px'
              }}
              data-name="text-3410a5"
              data-auto-animate-easing="cubic-bezier(0.680, -0.550, 0.265, 1.550)"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  lineHeight: '1.378',
                  textAlign: 'right'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li>תיאור מרכיבי הכלכלה בימים האחרונים.</li>
                  <li>אובדן משקל בששת החודשים האחרונים.</li>
                  <li>בעיות לעיסה.</li>
                  <li>בעיות בליעה.</li>
                  <li>
                    תרופות: תרופות יכולות להוות בעיה, במיוחד אלו הפוגעות במערכת
                    העיכול, כגון תרופות הגורמות ליובש בפה, לחוסר תיאבון
                    ולעצירות.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.58)"
            data-background-image="https://images.unsplash.com/photo-1604079628040-94301bb21b91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDd8fGFic3RyYWN0fGVufDB8fHx8MTY5OTI3MDU2OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-position="50% 0%"
            data-background-opacity="0.56"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 254,
                left: '629.077px',
                top: '78.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  textAlign: 'left',
                  lineHeight: '1.04',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
              >
                <p>
                  <u>
                    <strong>הערכת החולה תכלול</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '523.154px',
                left: '359.923px',
                top: '166.5px'
              }}
              data-name="text-3410a5"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  lineHeight: '1.378',
                  textAlign: 'right'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li>בעיות עיכול וספיגה.</li>
                  <li>בחילות, הקאות, שלשולים ועצירות.</li>
                  <li>בדידות חברתית.</li>
                  <li>אובדן משאבים משפחתיים.</li>
                  <li>בעיות כלכליות.</li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.58)"
            data-background-image="https://images.unsplash.com/photo-1604079628040-94301bb21b91?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDd8fGFic3RyYWN0fGVufDB8fHx8MTY5OTI3MDU2OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-position="50% 0%"
            data-background-opacity="0.56"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 254,
                left: '629.077px',
                top: '78.5px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  textAlign: 'left',
                  lineHeight: '1.04',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
              >
                <p>
                  <u>
                    <strong>הערכת החולה תכלול</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '626.154px',
                left: '256.923px',
                top: '166.5px'
              }}
              data-name="text-3410a5"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  lineHeight: '1.378',
                  textAlign: 'right'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    הגבלות על הדיאטה, כגון הימנעות מביצים ובשר, הניתנות לעתים על
                    ידי מטפלים שונים, גורמות לחסר של חלבונים הדרושים לריפוי
                    הפצעים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    רקע רפואי – מחלות מערכתיות שונות, הפוגעות כל אחת במנגנון
                    אחר.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(213, 166, 189, 0.58)"
            // data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959074/Screenshot_2023-11-29_154730.png"
            // data-background-size="initial"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 331, left: 304, top: '24.5px' }}
              data-name="text-48a7a1"
            >
              <div
                className="sl-block-content"
                data-auto-animate-easing="step-end"
                style={{
                  zIndex: 12,
                  color: 'rgb(34, 34, 34)',
                  backgroundColor: 'rgb(255, 255, 255)'
                }}
              >
                <p>
                  <strong>אומדן סיעודי - תזונתי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '100%',
                height: '100%',
                // left: '305.198px',
                top: '-10px'
              }}
              data-name="image-e4e842"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={263}
                  data-natural-height={276}
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959074/Screenshot_2023-11-29_154730.png"
                />
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-auto-animate=""
            data-background-color="rgb(207, 226, 243)"
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 167, top: '71.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, color: 'rgb(0, 0, 0)' }}
              >
                <h3>מקומות שבהם נפוץ להופיע פצעי לחץ</h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '323.604px',
                height: '339.6px',
                left: '305.198px',
                top: '134.7px'
              }}
              data-name="image-e4e842"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={263}
                  data-natural-height={276}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895713/Screenshot_2023-11-06_151617.png"
                />
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(0, 0, 0)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              style={{
                marginTop: '82px',
                color: 'black'
              }}
            >
              <QuestionSlide
                key="wounds-first"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 22)}
              />
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(0, 0, 0)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              style={{
                marginTop: '82px',
                color: 'black'
              }}
            >
              <QuestionSlide
                key="wounds-second"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 23)}
              />
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 455,
                left: '252.5px',
                top: '78.5px'
              }}
              data-name="text-001eaa"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(152, 0, 0)' }}
              >
                <p>תשובה נכונה מדובר בדרגה - 2</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 663, left: '109.5px', top: 192 }}
              data-name="text-87e88e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(152, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  ירידה בעובי העור, יראה כפצע שטחי בעל גוון ורדרד.
                  <br />
                  מבטא מצב של פגיעה באפידירמיס ובדרמיס.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '380.296px',
                height: '320.6px',
                left: '299.789px',
                top: 270
              }}
              data-name="image-866263"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={577}
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  data-natural-height={520}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959227/stage2.png"
                />
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(168, 39, 107)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              style={{
                marginTop: '82px',
                color: 'black'
              }}
            >
              <QuestionSlide
                key="wounds-third"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 25)}
              />
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 13 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 455,
                left: '252.5px',
                top: '78.5px'
              }}
              data-name="text-001eaa"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(152, 0, 0)' }}
              >
                <p>תשובה נכונה מדובר בדרגה - 3</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 663, left: '106.5px', top: 155 }}
              data-name="text-87e88e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(152, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  אובדן עור מוחלט כמעט, פגיעה גם ברקמות עמוקות יותר עם זאת אין
                  חשיפה של גידים, עצמות או שרירים. ניתן לראות גלד שחור.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 230,
                height: 230,
                left: 323,
                top: 272,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-866263"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={300}
                  data-natural-height={300}
                  data-lazy-loaded=""
                  style={{}}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959234/stage3.png"
                />
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(168, 39, 107)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              style={{
                marginTop: '82px',
                color: 'black'
              }}
            >
              <QuestionSlide
                key="wounds-27"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 27)}
              />
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 13 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 455,
                left: '252.5px',
                top: '78.5px'
              }}
              data-name="text-001eaa"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(152, 0, 0)' }}
              >
                <p>תשובה נכונה מדובר בדרגה - 4</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 663, left: '106.5px', top: 155 }}
              data-name="text-87e88e"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(152, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  אובדן עור מוחלט עם חשיפה של גידים, עצמות או שרירים.
                  <br />
                  באזור הפצע יתפתח גלד והעצמות החשופות תעבורנה הרס.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 230,
                height: 230,
                left: 323,
                top: 278,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-866263"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={275}
                  data-natural-height={275}
                  data-lazy-loaded=""
                  style={{}}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959240/stage4.png"
                />
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(168, 39, 107)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '247.481px',
                height: '192.6px',
                left: '116.268px',
                top: '54.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-fcd1f4"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={239}
                  data-natural-height={186}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895913/Screenshot_2023-11-06_153802.png"
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '140px',
                color: 'black'
              }}
              className="customQuestionAside"
            >
              <QuestionSlide
                key="wounds-29"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 29)}
              />
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 13 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(168, 39, 107)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '325.353px',
                height: 192,
                left: '76.8683px',
                top: '54.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-fcd1f4"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={466}
                  data-natural-height={275}
                  data-lazy-loaded=""
                  style={{}}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959247/Screenshot_2023-11-29_164716.png"
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '140px',
                color: 'black'
              }}
              className="customQuestionAside"
            >
              <QuestionSlide
                key="wounds-30"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 30)}
              />
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 13 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(168, 39, 107)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '247.481px',
                height: '192.6px',
                left: '116.268px',
                top: '54.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-fcd1f4"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={201}
                  data-natural-height={168}
                  data-lazy-loaded=""
                  data-crop-x={0}
                  data-crop-y="0.034445"
                  data-crop-width={1}
                  data-crop-height="0.93111"
                  style={{ left: 0, top: '-7px', width: 247, height: 207 }}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895979/Screenshot_2023-11-06_154427.png"
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '142px',
                color: 'black'
              }}
              className="customQuestionAside"
            >
              <QuestionSlide
                key="wounds-31"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 31)}
              />
            </div>
          </section>
          <section
            data-auto-animate-easing="linear"
            data-background-color="rgb(207, 226, 243)"
            data-auto-animate=""
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 11 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 13 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 131, left: 786, top: '54.5px' }}
              data-name="text-3b5a73"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, color: 'rgb(168, 39, 107)' }}
              >
                <h3>שאלה</h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '275.942px',
                height: '192.914px',
                left: '102.21px',
                top: '54.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-fcd1f4"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={289}
                  data-natural-height={202}
                  data-lazy-loaded=""
                  data-crop-x={0}
                  data-crop-y={0}
                  data-crop-width="0.99979"
                  data-crop-height={1}
                  style={{ left: 0, top: 0, width: 276, height: 193 }}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10895983/Screenshot_2023-11-06_154500.png"
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '142px',
                color: 'black'
              }}
              className="customQuestionAside"
            >
              <QuestionSlide
                key="wounds-32"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 32)}
              />
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 243, left: '358.5px', top: 51, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>סולם נורטון</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '818.4px',
                height: '220.945px',
                left: '70.8px',
                top: '159.528px'
              }}
              data-name="image-034f74"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={726}
                  data-natural-height={196}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896050/Screenshot_2023-11-06_155525.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 243, left: '705.5px', top: 6, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '82px',
                color: 'black'
              }}
            >
              <QuestionSlide
                key="wounds-34"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 34)}
              />
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 544, left: 208, top: 48, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>איך אני מגדיר את הפצע?</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 402, left: 350, top: 180 }}
              data-name="text-20a04a"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, fontSize: '150%' }}
              >
                <ul>
                  <li
                    className="fragment semi-fade-out"
                    data-fragment-index={0}
                  >
                    מיקום
                  </li>
                  <li
                    className="fade-in-then-semi-out fragment"
                    data-fragment-index={0}
                  >
                    דרגת הפצע
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 400, left: 280, top: 37, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>איך אני מגדיר את הפצע?</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-57fef7"
            >
              <div
                className="sl-block-content sl-block-group-content fragment semi-fade-out"
                style={{ transformOrigin: '746px 353.087px 0px', zIndex: 11 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 200,
                    left: '644.991px',
                    top: '275.337px',
                    height: 'auto'
                  }}
                  data-name="text-ba9d0d"
                  data-origin-id="a0976ec5ae30713beb32d41287e19387"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 14 }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span style={{ color: '#ffd700' }}>
                        <strong>האם הפצע עם שכבת פברין</strong>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 210,
                    left: '639.991px',
                    top: '369.962px',
                    height: 'auto'
                  }}
                  data-name="text-920439"
                  data-origin-id="cdcde1bbde96bb408419e6e96150e73d"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 13, textAlign: 'left', fontSize: '65%' }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span style={{ color: '#000000' }}>
                        האם קייימת מעורבות של העצם
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 240,
                    height: '185.25px',
                    left: 626,
                    top: '260.462px'
                  }}
                  data-name="shape-85dd4f"
                  data-origin-id="76747f18eac97b3510f2f5d60972536b"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 12 }}
                    data-shape-stroke-color="#000000"
                    data-shape-stroke-width="3px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 240 185"
                    >
                      <defs>
                        <clipPath id="shape-mask-5-1701270252157">
                          <rect
                            width={240}
                            height="185.25"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={6}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={240}
                        height="185.25"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#000000"
                        strokeWidth={6}
                        clipPath="url(#shape-mask-5-1701270252157)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '234.545px',
                left: '589.496px',
                top: 114
              }}
              data-name="text-e3fdeb"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  color: 'rgb(103, 78, 167)',
                  fontSize: '120%'
                }}
              >
                <p>
                  <u>
                    <span style={{ color: '#800080' }}>
                      <strong>מיטת הפצע:</strong>
                    </span>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '618.341px',
                left: '176.204px',
                top: 161
              }}
              data-name="text-d65b47"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'justify' }}>
                  חשוב להסתכל על הצבע ואיך נראה הפצע, צריך לשים לב לדברים הבאים:
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-4d683c"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '470.001px 351.212px 0px',
                  zIndex: 17
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 200,
                    left: '369.496px',
                    top: '275.337px',
                    height: 'auto'
                  }}
                  data-origin-id="d7d11a8ef861f0603cfb6305a6b7b2ee"
                  data-name="text-cebcfd"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 19 }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span style={{ color: '#ffa07a' }}>
                        <strong>האם הפצע ורוד</strong>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 240,
                    height: '76.25px',
                    left: '350.505px',
                    top: '260.462px'
                  }}
                  data-origin-id="65d9d2f4537aa7fde02341f4d0a2290a"
                  data-name="shape-b2e0be"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 18 }}
                    data-shape-stroke-color="#000000"
                    data-shape-stroke-width="3px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 240 76"
                    >
                      <defs>
                        <clipPath id="shape-mask-6-1701270252157">
                          <rect
                            width={240}
                            height="76.25"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={6}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={240}
                        height="76.25"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#000000"
                        strokeWidth={6}
                        clipPath="url(#shape-mask-6-1701270252157)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 240,
                    height: '76.25px',
                    left: '349.496px',
                    top: '365.712px'
                  }}
                  data-name="shape-06895b"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                    data-shape-stroke-color="#000000"
                    data-shape-stroke-width="3px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 240 76"
                    >
                      <defs>
                        <clipPath id="shape-mask-7-1701270252157">
                          <rect
                            width={240}
                            height="76.25"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={6}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={240}
                        height="76.25"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#000000"
                        strokeWidth={6}
                        clipPath="url(#shape-mask-7-1701270252157)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 200,
                    left: '369.496px',
                    top: '381.962px',
                    height: 'auto'
                  }}
                  data-name="text-398d8a"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 21 }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span style={{ color: '#ff0000' }}>
                        <strong>האם</strong> הפצע אדום
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-805e94"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '193.991px 353.337px 0px',
                  zIndex: 22
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 200,
                    left: '92.982px',
                    top: '275.587px',
                    height: 'auto'
                  }}
                  data-origin-id="d7d11a8ef861f0603cfb6305a6b7b2ee"
                  data-name="text-a5473e"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 25 }}
                  >
                    <p style={{ textAlign: 'right' }}>
                      <span style={{ color: '#000000' }}>
                        <strong>האם הפצע נקרוטי</strong>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 210,
                    left: '87.982px',
                    top: '370.212px',
                    height: 'auto'
                  }}
                  data-origin-id="98cfb29339bca4837549f5e4ac15ac2b"
                  data-name="text-06fb45"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 24, textAlign: 'left', fontSize: '65%' }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span style={{ color: '#000000' }}>
                        האם קיימת מעורבות של שריר
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 240,
                    height: '185.25px',
                    left: '73.991px',
                    top: '260.712px'
                  }}
                  data-origin-id="65d9d2f4537aa7fde02341f4d0a2290a"
                  data-name="shape-c11aae"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 23 }}
                    data-shape-stroke-color="#000000"
                    data-shape-stroke-width="3px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 240 185"
                    >
                      <defs>
                        <clipPath id="shape-mask-8-1701270252157">
                          <rect
                            width={240}
                            height="185.25"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={6}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={240}
                        height="185.25"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#000000"
                        strokeWidth={6}
                        clipPath="url(#shape-mask-8-1701270252157)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 400, left: 280, top: 37, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>איך אני מגדיר את הפצע?</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '234.545px',
                left: '589.496px',
                top: 114
              }}
              data-name="text-e3fdeb"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(103, 78, 167)',
                  fontSize: '120%'
                }}
              >
                <p>
                  <u>
                    <span style={{ color: '#800080' }}>
                      <strong>גודל הפצע:</strong>
                    </span>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '618.341px',
                left: '176.204px',
                top: 161
              }}
              data-name="text-d65b47"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <p style={{ textAlign: 'justify' }}>
                  במדידת גודל הפצע מתייחסים ל:
                  <br />
                  אורך, רוחב, עומק, כיסים ותעלות.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 421,
                height: '170.779px',
                left: '269.5px',
                top: 270,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-9f64ba"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-natural-width={577}
                  data-natural-height={433}
                  data-crop-x={0}
                  data-crop-y={0}
                  data-crop-width={1}
                  data-crop-height="0.865538"
                  style={{ left: 0, top: 0, width: 421, height: 197 }}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959315/ruler.png"
                />
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 400, left: 280, top: 37, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>איך אני מגדיר את הפצע?</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '234.545px',
                left: '588.959px',
                top: '97.5px'
              }}
              data-name="text-e3fdeb"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(103, 78, 167)',
                  fontSize: '120%'
                }}
              >
                <p>
                  <u>
                    <span style={{ color: '#800080' }}>
                      <strong>שולי הפצע:</strong>
                    </span>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '488.341px',
                left: '308.826px',
                top: '153.5px'
              }}
              data-name="text-d65b47"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <p>הכוונה לראות את מצב העור מסביב הפצע</p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-origin-id="8ca3ffef2e7cc6809ecee1bd1f9374a3"
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 22,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-origin-id="fa6d66fc32fba264cb7d69fd3cc1cc72"
              data-name="group-d7a905"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '518.231px 367.251px 0px',
                  zIndex: 23
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-dc60f9"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '473.116px 375.001px 0px',
                      zIndex: 24
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '330.231px',
                        top: '262.336px'
                      }}
                      data-origin-id="aa5267110acb175c1d0b0dcbd5a8d906"
                      data-name="shape-b4a868"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 25 }}
                        data-shape-stroke-color="#000000"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-34-1701271427891">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(255, 255, 255)"
                                stroke="#000000"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-34-1701271427891)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: 342,
                        top: '301.836px',
                        height: 'auto'
                      }}
                      data-origin-id="708659dff7a4f7779b5ac09bb8e9cad0"
                      data-name="text-2dcc94"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 26,
                          color: 'rgb(0, 0, 0)',
                          fontSize: '140%',
                          lineHeight: '1.04',
                          padding: 20
                        }}
                        data-has-line-height=""
                      >
                        <h2 style={{ textAlign: 'center' }}>
                          <strong>אודם</strong>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
              data-origin-id="20a1bfe87a8caa54e0f59cea4750623d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 13,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-f9e25e"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '552.727px 336.994px 0px',
                  zIndex: 18
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-4cebe5"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '507.612px 344.744px 0px',
                      zIndex: 19
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '364.727px',
                        top: '232.079px'
                      }}
                      data-origin-id="fec84eb839633a246330568778fd8d71"
                      data-name="shape-bff75e"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 20 }}
                        data-shape-stroke-color="#000000"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-35-1701271427891">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(255, 255, 255)"
                                stroke="#000000"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-35-1701271427891)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: '376.496px',
                        top: '271.579px',
                        height: 'auto'
                      }}
                      data-origin-id="8ba4e03f345bd407fa1e700a0c998169"
                      data-name="text-44329f"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 21,
                          color: 'rgb(0, 0, 0)',
                          fontSize: '140%',
                          lineHeight: '1.04',
                          padding: 20
                        }}
                        data-has-line-height=""
                      >
                        <h2 style={{ textAlign: 'center' }}>
                          <strong>פריחה</strong>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-aff125"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment semi-fade-out"
                style={{
                  transformOrigin: '582.231px 309.665px 0px',
                  zIndex: 14
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-3210dc"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '537.116px 317.415px 0px',
                      zIndex: 15
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '394.231px',
                        top: '204.75px'
                      }}
                      data-name="shape-e1e4ba"
                      data-origin-id="7d8f3dd29723a0532238131edd39ec59"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 16 }}
                        data-shape-stroke-color="#000000"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-36-1701271427891">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(255, 255, 255)"
                                stroke="#000000"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-36-1701271427891)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: 406,
                        top: '244.25px',
                        height: 'auto'
                      }}
                      data-name="text-b45c7e"
                      data-origin-id="69fa6e7ca313c3b93faf7f220efaae8f"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 17,
                          color: 'rgb(0, 0, 0)',
                          fontSize: '140%',
                          lineHeight: '1.04',
                          padding: 20
                        }}
                        data-has-line-height=""
                      >
                        <h2 style={{ textAlign: 'right' }}>
                          <strong>שלפוחיות</strong>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-c29034"
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{ transformOrigin: '475px 401.652px 0px', zIndex: 27 }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-b13178"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '429.885px 409.402px 0px',
                      zIndex: 28
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: 294,
                        top: '296.737px'
                      }}
                      data-origin-id="10af4eb3ac7de6c33d1c3ade67140bdf"
                      data-name="shape-6269a9"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 29 }}
                        data-shape-stroke-color="#000000"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-37-1701271427891">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(255, 255, 255)"
                                stroke="#000000"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-37-1701271427891)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 390,
                        left: 280,
                        top: '343.152px',
                        height: 'auto'
                      }}
                      data-origin-id="ffeada623e5a7c0a814292d0eecfb3f6"
                      data-name="text-f0ac15"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 30,
                          color: 'rgb(0, 0, 0)',
                          fontSize: '130%',
                          lineHeight: '1.04',
                          padding: 20
                        }}
                        data-has-line-height=""
                      >
                        <h2 style={{ textAlign: 'center' }}>
                          <strong>גירוי מקומי</strong>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 400, left: 280, top: 37, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>איך אני מגדיר את הפצע?</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '90.545px',
                left: '734.126px',
                top: 92
              }}
              data-name="text-e3fdeb"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(103, 78, 167)',
                  fontSize: '120%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <u>
                    <strong>כאב:</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '689.341px',
                left: '141.33px',
                top: 147
              }}
              data-name="text-d65b47"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <p style={{ textAlign: 'justify' }}>
                  כאב הוא תחושת סבל ואי-נוחות, המצביעה על פגיעה ברקמות הגוף
                  וממוקמת באזור מסוים בו.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  לפי האגודה הבינלאומית למחקר כאב, כאב מוגדר כ"תחושה וחוויה
                  נפשית לא נעימה הקשורה בנזק ממשי או אפשרי לרקמה, או המתוארת
                  במונחים של נזק מסוג זה".
                </p>
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 400, left: 280, top: 37, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>איך אני מגדיר את הפצע?</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '262.545px',
                left: '562.126px',
                top: 92
              }}
              data-name="text-e3fdeb"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(103, 78, 167)',
                  fontSize: '120%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <u>
                    <strong>כאב:</strong>
                  </u>{' '}
                  (סרגל כאב)
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '464.999px',
                height: '350.766px',
                left: 293,
                top: '161.324px'
              }}
              data-name="image-01ed75"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  width: '100%',
                  height: '100%',
                  objectFit: 'fill'
                }}
              >
                <img
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  src={painRuler}
                />
                {/* <img
                  className=""
                  data-natural-width={2500}
                  data-natural-height={908}
                  data-crop-x={0}
                  data-crop-y="0.103188"
                  data-crop-width="0.576922"
                  data-crop-height="0"
                  style={{ left: 0, top: '-30px', width: '100%', height: 293 }}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10959455/painRuler.png"
                /> */}
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 400, left: 280, top: 37, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '120%',
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>איך אני מגדיר את הפצע?</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '234.545px',
                left: '588.959px',
                top: '97.5px'
              }}
              data-name="text-e3fdeb"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(103, 78, 167)',
                  fontSize: '120%'
                }}
              >
                <p>
                  <u>
                    <span style={{ color: '#800080' }}>
                      <strong>הפרשות:</strong>
                    </span>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '488.341px',
                left: '308.826px',
                top: '153.5px'
              }}
              data-name="text-d65b47"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <p>ברגע שיש הפרשות, יש להסתכל על</p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-8e3bca"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 32,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-d7a905"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{ transformOrigin: '455.5px 370.081px 0px', zIndex: 33 }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-dc60f9"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '455.5px 370.081px 0px',
                      zIndex: 37
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '267.5px',
                        top: '265.166px'
                      }}
                      data-name="shape-b4a868"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 40 }}
                        data-shape-stroke-color="#000000"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-42-1701272207019">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(255, 255, 255)"
                                stroke="#000000"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-42-1701272207019)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: '279.269px',
                        top: '304.666px',
                        height: 'auto'
                      }}
                      data-name="text-2dcc94"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 41,
                          color: 'rgb(0, 0, 0)',
                          fontSize: '140%',
                          lineHeight: '1.04',
                          padding: 20
                        }}
                        data-has-line-height=""
                      >
                        <h2 style={{ textAlign: 'center' }}>
                          <strong>ריח</strong>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '0px 0px 0px',
                  zIndex: 13,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-animation-type="slide-up"
              ></div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-f9e25e"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '481.996px 345.081px 0px',
                  zIndex: 23
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-4cebe5"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '481.996px 345.081px 0px',
                      zIndex: 27
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '293.996px',
                        top: '240.166px'
                      }}
                      data-name="shape-bff75e"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 30 }}
                        data-shape-stroke-color="#000000"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-43-1701272207020">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(255, 255, 255)"
                                stroke="#000000"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-43-1701272207020)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: '305.765px',
                        top: '279.666px',
                        height: 'auto'
                      }}
                      data-name="text-44329f"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 31,
                          color: 'rgb(0, 0, 0)',
                          fontSize: '140%',
                          lineHeight: '1.04',
                          padding: 20
                        }}
                        data-has-line-height=""
                      >
                        <h2 style={{ textAlign: 'center' }}>
                          <strong>כמות</strong>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-aff125"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment semi-fade-out"
                style={{ transformOrigin: '504.5px 321.665px 0px', zIndex: 14 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-3210dc"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '504.5px 321.665px 0px',
                      zIndex: 18
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 376,
                        height: '209.829px',
                        left: '316.5px',
                        top: '216.75px'
                      }}
                      data-name="shape-e1e4ba"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgb(255, 255, 255)"
                        data-shape-stretch="true"
                        style={{ zIndex: 21 }}
                        data-shape-stroke-color="#000000"
                        data-shape-stroke-width="4px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 376 210"
                        >
                          <defs>
                            <clipPath id="shape-mask-44-1701272207020">
                              <rect
                                width={376}
                                height="209.829"
                                rx={0}
                                ry={0}
                                fill="rgb(255, 255, 255)"
                                stroke="#000000"
                                strokeWidth={8}
                              />
                            </clipPath>
                          </defs>
                          <rect
                            width={376}
                            height="209.829"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgb(255, 255, 255)"
                            stroke="#000000"
                            strokeWidth={8}
                            clipPath="url(#shape-mask-44-1701272207020)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 353,
                        left: '328.269px',
                        top: '256.25px',
                        height: 'auto'
                      }}
                      data-name="text-b45c7e"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          textAlign: 'left',
                          zIndex: 22,
                          color: 'rgb(0, 0, 0)',
                          fontSize: '140%',
                          lineHeight: '1.04',
                          padding: 20
                        }}
                        data-has-line-height=""
                      >
                        <h2 style={{ textAlign: 'center' }}>
                          <strong>צבע</strong>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgba(213, 166, 189, 0.58)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 282, top: '168.25px' }}
              data-name="text-59ea69"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, lineHeight: '1.95' }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    רמת אלבומין : הנורמה –G/DL 3.50 - 5.20
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    Total Protein : הנורמה - G/DL 6.60 - 8.30
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    רמת המוגלובין : הנורמה – G/DL 13.50 - 18
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 654, left: 228, top: 83, height: 'auto' }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'center',
                  color: 'rgb(168, 39, 107)',
                  fontSize: '112%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <u>בדיקות מעבדה שיש לבצע כאשר מופיע פצע לחץ :</u>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 243, left: '705.5px', top: 6, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '82px',
                color: 'black'
              }}
            >
              <QuestionSlide
                key="wounds-44"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 43)}
              />
            </div>
          </section>
          <section
            data-background-color="rgb(234, 209, 220)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-opacity="0.1"
            data-auto-animate=""
            data-background-size="initial"
            data-background-position="50% 100%"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 330, left: 315, top: '70.5px' }}
              data-name="text-616d0f"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(0, 0, 0)' }}
              >
                <h2>
                  <strong>
                    <span style={{ fontSize: '1.2em' }}>סיבוכים</span>
                  </strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 403, left: 507, top: '159.5px', height: 'auto' }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)',
                  fontSize: '112%'
                }}
              >
                <p>
                  <u>הסיבוכים של פצעי לחץ הם:</u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 219, top: 262 }}
              data-name="text-f1b819"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'right',
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={0}
                  >
                    הארכת משך האשפוז וההחלמה.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 219, top: 262 }}
              data-name="text-30a823"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'right',
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <ul>
                  <li className="fragment fade-out" data-fragment-index={0}>
                    מקור כניסה לחיידקים וחדירת זיהומים.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 600, left: 219, top: '262.5px' }}
              data-name="text-2091ec"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'right',
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={1}
                  >
                    העלאת הסיכון לתמותה עד פי שניים תוך 30 יום משחרור החולים
                    מאשפוז.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 678, left: 141, top: 263 }}
              data-name="text-50f535"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  textAlign: 'right',
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={2}
                  >
                    ירידה באיכות החיים של המטופל עקב כאבים, אי-נוחות, הפרשות,
                    ריח לא נעים, בדידות, דיכאון וסדר יום המוכתב מהטיפול.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 678, left: 141, top: 262 }}
              data-name="text-b68b93"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  textAlign: 'right',
                  color: 'rgb(166, 28, 0)'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={3}
                  >
                    עלות הטיפול בפצעים היא גבוהה עקב הארכת ימי אשפוז בבתי חולים
                    כלליים ובמוסדות ועקב הטיפול המקומי.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(208, 224, 227)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 100%"
            data-background-opacity="0.1"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 330, left: 315, top: '70.5px' }}
              data-name="text-616d0f"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(0, 0, 255)' }}
              >
                <h2>
                  <strong>
                    <span style={{ fontSize: '1.2em' }}>טיפול</span>
                  </strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 573, left: 337, top: '159.5px', height: 'auto' }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'center',
                  color: 'rgb(0, 0, 255)',
                  fontSize: '112%'
                }}
              >
                <p>
                  <u>מטרות הטיפול בפצעי לחץ אצל קשישים הן:</u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 789, height: 281, left: '106.5px', top: 222 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(159, 168, 239, 0.59)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 281"
                >
                  <rect
                    width={789}
                    height={281}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(159, 168, 239, 0.59)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 747,
                left: '106.5px',
                top: '258.5px'
              }}
              data-name="text-899f2f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(60, 122, 35)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-out"
                    data-fragment-index={0}
                  >
                    מניעה: חשוב לנסות למנוע את הופעת הפצעים. למשל, לחולה לאחר
                    שבר צוואר ירך או לאחר דלקת ריאות יש לספק אביזרים למניעת לחץ
                    (מזרנים וכריות) ולמנוע ממנו שכיבה ממושכת במיטה בחוסר תנועה.
                    כמו כן, יש לתת משככי כאבים, לדאוג לישיבה נכונה בכורסא ולעזור
                    בשיקום.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 747,
                left: '106.5px',
                top: '258.5px'
              }}
              data-name="text-d1a96f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  color: 'rgb(60, 122, 35)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment current-visible"
                    data-fragment-index={0}
                  >
                    טיפול מקומי: כאשר מתפתח פצע לחץ, יש לטפל בו מקומית כדי
                    להביאו לריפוי מלא.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 747,
                left: '106.5px',
                top: '258.5px'
              }}
              data-name="text-fd4608"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  color: 'rgb(60, 122, 35)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment current-visible"
                    data-fragment-index={1}
                  >
                    טיפול מערכתי: בנוסף לטיפול בפצע עצמו, יש לייצב ולאזן מצבים
                    רפואיים המעכבים את הטיפול המקומי.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 747,
                left: '106.5px',
                top: '258.5px'
              }}
              data-name="text-0dc451"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  color: 'rgb(60, 122, 35)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment current-visible"
                    data-fragment-index={2}
                  >
                    שמירה על איכות החיים: אם הפצע אינו מתרפא ונעשה כרוני, יש
                    להשתדל לאפשר למטופל איכות חיים, תוך שליטה על תסמינים
                    שמפריעים לו ולמשפחתו ופוגעים בהם.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(208, 224, 227)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 100%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 839, height: 393, left: '64.5px', top: 110 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(159, 168, 239, 0.59)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="14px"
                  data-shape-stroke-color="#0000ff"
                  data-shape-stroke-width="2px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 839 393"
                  >
                    <defs>
                      <clipPath id="shape-mask-3-1699280010577">
                        <rect
                          width={839}
                          height={393}
                          rx={14}
                          ry={14}
                          fill="rgba(159, 168, 239, 0.59)"
                          stroke="#0000ff"
                          strokeWidth={4}
                        />
                      </clipPath>
                    </defs>
                    <rect
                      width={839}
                      height={393}
                      rx={14}
                      ry={14}
                      className="shape-element"
                      fill="rgba(159, 168, 239, 0.59)"
                      stroke="#0000ff"
                      strokeWidth={4}
                      clipPath="url(#shape-mask-3-1699280010577)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 747,
                left: '106.5px',
                top: '142.5px'
              }}
              data-name="text-899f2f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(0, 0, 0)',
                  textAlign: 'right',
                  fontSize: '105%'
                }}
              >
                <p className="fade-out" style={{ textAlign: 'justify' }}>
                  <strong>
                    יש החושבים כי הסיבה העיקרית לעלייה בשכיחות פצעי הלחץ היא
                    שהטיפול בחולה הזקן הסובל מפצעי לחץ מצריך גישה טיפולית
                    כוללנית, דהיינו, ראיית "החולה שמאחורי הפצע", ולא רק את הפצע.
                    מטרה זו ניתנת להשגה באמצעות הערכה של צוות רב-מקצועי שייתן
                    טיפול מקיף. טיפול זה יכלול: התערבויות מניעה, טיפול מערכתי
                    במחלות הרקע, טיפול מקומי בפצע והתייחסות לאיכות החיים של
                    החולה ושל משפחתו.
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-opacity="0.1"
            data-auto-animate=""
            data-background-size="initial"
            data-background-position="0% 0%"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 330, left: 315, top: '55.5px' }}
              data-name="text-616d0f"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(204, 0, 0)' }}
              >
                <h2>
                  <span style={{ fontSize: '1.2em' }}>
                    <strong>מניעה</strong>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 304,
                left: '591.5px',
                top: '137.5px',
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)',
                  fontSize: '112%'
                }}
              >
                <p>
                  <u>הטיפול המניעתי כולל:</u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 789, height: 202, left: '106.5px', top: 222 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 202"
                >
                  <rect
                    width={789}
                    height={202}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 747, left: '96.5px', top: 280 }}
              data-name="text-899f2f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    className="fade-out fragment"
                    data-fragment-index={0}
                    style={{ textAlign: 'justify' }}
                  >
                    חינוך והטמעת מודעות לשמירת היגיינה של העור וכף הרגל, להגנה
                    מפני חום וקור, ולהגנה מחבלות ומגופים זרים.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 747,
                left: '96.5px',
                top: '279.5px'
              }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={0}
                    style={{ textAlign: 'justify' }}
                  >
                    ביצוע של שינויי תנוחה בשכיבה ובישיבה לפי לוחות זמנים קבועים.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 747, left: '96.5px', top: 280 }}
              data-name="text-86f80d"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={1}
                    style={{ textAlign: 'justify' }}
                  >
                    הסרת לחצים מעל הפצעים ומתן ציוד להקלת הלחץ (מזרנים, משטחים,
                    מושבים, מגני עקב ומרפק).
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 747, left: '96.5px', top: 280 }}
              data-name="text-106f0e"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={2}
                    style={{ textAlign: 'justify' }}
                  >
                    שימון העור ומניעת יובש ורטיבות.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 747, left: '96.5px', top: 280 }}
              data-name="text-d6e8b8"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 17,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right'
                }}
              >
                <ul>
                  <li
                    className="fragment current-visible"
                    data-fragment-index={3}
                    style={{ textAlign: 'justify' }}
                  >
                    מתן תזונה עשירה בחלבון ובקלוריות.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 417, left: '271.5px', top: 44 }}
              data-name="text-616d0f"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(204, 0, 0)' }}
              >
                <h3 style={{ textAlign: 'right' }}>
                  <strong>
                    <span style={{ fontSize: '1.0em' }}>
                      אביזרים למניעת פצעי לחץ
                    </span>
                  </strong>
                </h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '605.5px',
                left: '177.25px',
                top: 142,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)',
                  fontSize: '112%',
                  backgroundColor: 'rgb(235, 210, 185)',
                  borderStyle: 'solid',
                  borderRadius: 17
                }}
              >
                <p>מנוע/משאבה למזרון אוויר למניעת פצעי לחץ</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 789, height: 364, left: '85.5px', top: 119 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 364"
                >
                  <rect
                    width={789}
                    height={364}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 747, left: '106.5px', top: 194 }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p className="visible" style={{ textAlign: 'justify' }}>
                  המנוע המנפח את המזרון ומזרים אוויר לסירוגין לאזורים שונים
                  במזרון. מילוי האוויר לסירוגין באזורים שונים גורם לשינוי תמידי
                  בנקודות הלחץ ומדמה שינויי תנוחה. הזרמת האוויר הינה איטית
                  וגורמת לתזוזה הדרגתית בלתי מורגשת שמאפשרת מנוחה רציפה.
                  <br />
                  פעולת המנוע שקטה. ניתן להתאים את לחץ האוויר למשקל הגוף בהתאם
                  להנחיות היצרן.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '605.5px',
                left: '177.25px',
                top: 111,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)',
                  fontSize: '112%',
                  backgroundColor: 'rgb(235, 210, 185)',
                  borderStyle: 'solid',
                  borderRadius: 17
                }}
              >
                <p>תכונות מזרן ויסקו למניעת פצעי לחץ</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 789, height: 364, left: '85.5px', top: 88 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 364"
                >
                  <rect
                    width={789}
                    height={364}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 747, left: '106.5px', top: 163 }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'justify',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p>
                  מזרון למניעת פצעי לחץ הינו בעל תכונות אלסטיות. גמישות זו יוצרת
                  התאמה מרבית ומאפשרת למזרון להתאים את עצמו לתנוחת השוכב ולמבנה
                  גופו. מזרון למניעת פצעי לחץ מעניק תמיכה מלאה לכל הגוף ובכך
                  מווסת ומפזר את משקל הגוף באופן שווה.
                </p>
                <p>
                  תכונה נוספת וחשובה היא שהמזרון עשוי חומר אנטי בקטריאלי ונושם,
                  עובדה זו שומרת על אוורור ויובש של המזרון, דבר החשוב מאוד
                  במניעה וטיפול בפצעי לחץ.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '605.5px',
                left: '177.25px',
                top: 111,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'center',
                  color: 'rgb(204, 0, 0)',
                  fontSize: '112%',
                  backgroundColor: 'rgb(235, 210, 185)',
                  borderStyle: 'solid',
                  borderRadius: 17
                }}
              >
                <p>מזרון אוויר – דינאמי</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 789, height: 329, left: '85.5px', top: 88 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 329"
                >
                  <rect
                    width={789}
                    height={329}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 747, left: '106.5px', top: 163 }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'justify',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p>
                  מזרון אוויר למניעת פצעי לחץ, הינו מזרון דינאמי המשנה באופן
                  תמידי את תנוחת הגוף ואת נקודות הלחץ.
                </p>
                <p>
                  הדינאמיות של המזרון ושינויי התנוחה הקטנים, משפיעים על זרימת
                  הדם. תכונה זו חשובה במניעת היווצרות פצעי לחץ.
                </p>
                <p>ניתן לווסת את לחץ האוויר ולהתאימו לצורכי המשתמש.</p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '194.5px',
                left: '368.75px',
                top: 138,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(204, 0, 0)',
                    fontSize: '112%',
                    backgroundColor: 'rgb(235, 210, 185)',
                    borderStyle: 'solid',
                    borderRadius: 17
                  }}
                >
                  <p>כרית ביצים</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 789, height: 351, left: '71.5px', top: 121 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 351"
                >
                  <rect
                    width={789}
                    height={351}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 697,
                left: '126.5px',
                top: '208.5px'
              }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'justify' }}>
                  עשוי גומות כמו תבנית ביצים ומכאן השם. כרית ביצים למניעת פצעי
                  לחץ היא ברמת טכנולוגיה נמוכה של פיזור המשקל ביחס לסוגי הכריות
                  השונות.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '234.381px',
                height: '173.3px',
                left: '328.869px',
                top: '340.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-2e4c03"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 12,
                  opacity: '0.85',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 4px 2px 4px) opacity(100%)'
                }}
              >
                <div className="sl-block-content" style={{ zIndex: 12 }}>
                  <img
                    className=""
                    data-natural-width={495}
                    data-natural-height={366}
                    data-lazy-loaded=""
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896204/%D7%9B%D7%A8%D7%99%D7%AA-%D7%91%D7%99%D7%A6%D7%99%D7%9D-removebg-preview.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '194.5px',
                left: '368.75px',
                top: 138,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(204, 0, 0)',
                    fontSize: '112%',
                    backgroundColor: 'rgb(235, 210, 185)',
                    borderStyle: 'solid',
                    borderRadius: 17
                  }}
                >
                  <p>כרית ספוג</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 789, height: 351, left: '71.5px', top: 121 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 351"
                >
                  <rect
                    width={789}
                    height={351}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 697,
                left: '126.5px',
                top: '208.5px'
              }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p>
                  מפזרת את הלחץ על פני הישבן והירכיים באופן כזה שלא ייווצר לחץ
                  גדול על אזור מסוים.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '248.76px',
                height: '118.78px',
                left: '350.62px',
                top: '373.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-2e4c03"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 12,
                  opacity: '0.85',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 4px 2px 4px) opacity(100%)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div className="sl-block-content" style={{ zIndex: 12 }}>
                  <img
                    className=""
                    data-natural-width={336}
                    data-natural-height={158}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y="0.001014"
                    data-crop-width="0.984816"
                    data-crop-height={1}
                    style={{ left: 0, top: 0, width: 253, height: 119 }}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896594/Screenshot_2023-11-06_171351-removebg-preview.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '279.5px',
                left: '340.25px',
                top: 140,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(204, 0, 0)',
                    fontSize: '112%',
                    backgroundColor: 'rgb(235, 210, 185)',
                    borderStyle: 'solid',
                    borderRadius: 17
                  }}
                >
                  <p>כרית אוויר – דינמית</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 789,
                height: 351,
                left: '71.5px',
                top: '123.5px'
              }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 789 351"
                >
                  <rect
                    width={789}
                    height={351}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 697,
                left: '126.5px',
                top: '208.5px'
              }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'right',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'justify' }}>
                  הכרית מיועדת לאנשים בסיכון גבוה לפצעי לחץ.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  כרית זאת, מאפשרת לנטרל נקודות לחץ במקום הפצע הקיים, בעזרת
                  טכנולוגיית נעילת תאי אוויר בודדים ומבנה תאי אוויר ייחודי.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '248.615px',
                height: '158.855px',
                left: '340.25px',
                top: '367.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-2e4c03"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 12,
                  opacity: '0.85',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 4px 2px 4px) opacity(100%)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div className="sl-block-content" style={{ zIndex: 12 }}>
                  <img
                    className=""
                    data-natural-width={461}
                    data-natural-height={369}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y="0.201732"
                    data-crop-width={1}
                    data-crop-height="0.798268"
                    style={{ left: 0, top: '-40px', width: 249, height: 199 }}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896754/Starlock_883_WEB_1_-removebg-preview.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '279.5px',
                left: '340.25px',
                top: 153,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(204, 0, 0)',
                    fontSize: '112%',
                    backgroundColor: 'rgb(235, 210, 185)',
                    borderStyle: 'solid',
                    borderRadius: 17
                  }}
                >
                  <p>מגן עקב</p>
                </div>
              </div>
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'justify',
                  lineHeight: '1.17',
                  right: '-75px',
                  top: '10px'
                }}
                data-has-line-height=""
              >
                <p
                  style={{
                    color: 'rgb(102, 0, 0)'
                  }}
                >
                  הוא אביזר למטופלים בעלי מוגבלות מוטוריות השוכבים בצורה ממושכת
                  למניעת היווצרות לחץ באזור העקבים.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 514, height: 284, left: 223, top: '122.5px' }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 514 284"
                >
                  <rect
                    width={514}
                    height={284}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '208.161px',
                height: '158.462px',
                left: 223,
                top: '218.769px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-2e4c03"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 12,
                  opacity: '0.85',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 4px 2px 4px) opacity(100%)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div className="sl-block-content" style={{ zIndex: 12 }}>
                  <img
                    className=""
                    data-natural-width={416}
                    data-natural-height={312}
                    data-lazy-loaded=""
                    data-crop-x={0}
                    data-crop-y="0.11828"
                    data-crop-width="0.839357"
                    data-crop-height="0.851946"
                    style={{ left: 0, top: '-22px', width: 248, height: 186 }}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896760/wipe_clean_heel_float_3__webp-removebg-preview.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '306.5px',
                left: '326.75px',
                top: 151,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(204, 0, 0)',
                    fontSize: '112%',
                    backgroundColor: 'rgb(235, 210, 185)',
                    borderStyle: 'solid',
                    borderRadius: 17
                  }}
                >
                  <p>משטח לשינוי תנוחה</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 733, height: 284, left: 118, top: '122.5px' }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 733 284"
                >
                  <rect
                    width={733}
                    height={284}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 697, left: 118, top: '211.5px' }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'justify',
                  lineHeight: '1.404'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    חוסך זמן עבודה, מאפשר ומקל על המטפל לעבוד.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מפחית סיכון לפגיעה תעסוקתית אצל המטפלים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מפחית סכנה מהמטופל - בשל הזזה והרמה בטוחים יותר.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '306.5px',
                left: '326.75px',
                top: 151,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(204, 0, 0)',
                    fontSize: '112%',
                    backgroundColor: 'rgb(235, 210, 185)',
                    borderStyle: 'solid',
                    borderRadius: 17
                  }}
                >
                  <p>הגנה לעור ע"י משחות</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 733, height: 284, left: 118, top: 128 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 733 284"
                >
                  <rect
                    width={733}
                    height={284}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 208,
                height: 208,
                left: '380.5px',
                top: '228.208px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-2e4c03"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 12,
                  opacity: '0.85',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 4px 2px 4px) opacity(100%)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div className="sl-block-content" style={{ zIndex: 12 }}>
                  <img
                    className=""
                    data-natural-width={300}
                    data-natural-height={300}
                    data-lazy-loaded=""
                    style={{}}
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896767/400x400-300x300-1-1-1-removebg-preview.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 733, height: 284, left: 118, top: 128 }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 733 284"
                >
                  <rect
                    width={733}
                    height={284}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 669, left: 150, top: 186 }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'justify',
                  lineHeight: '1.404'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'justify' }}>
                  טכנולוגיות נוספות מתקדמות כוללות מיטות העמדה המסוגלות להעביר
                  מטופלים ממצב שכיבה למצבי עמידה ומצבי ביניים ובכך להביא להפחתת
                  לחץ ושיפור תפקודים רבים כגון נשימה ומערכות כלי הדם.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(231, 124, 124, 0.49)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '306.5px',
                left: '326.75px',
                top: 144,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(204, 0, 0)',
                    fontSize: '112%',
                    backgroundColor: 'rgb(235, 210, 185)',
                    borderStyle: 'solid',
                    borderRadius: 17
                  }}
                >
                  <p>מערכת למיפוי לחצים</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 733, height: 284, left: 118, top: '122.5px' }}
              data-name="shape-b61e5f"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(213, 166, 189, 0.58)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="14px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 733 284"
                >
                  <rect
                    width={733}
                    height={284}
                    rx={14}
                    ry={14}
                    className="shape-element"
                    fill="rgba(213, 166, 189, 0.58)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 697, left: '131.5px', top: 188 }}
              data-name="text-a7a10a"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(102, 0, 0)',
                  textAlign: 'justify',
                  lineHeight: '1.274'
                }}
                data-has-line-height=""
              >
                <p>
                  קיים פיתוח ישראלי חדש למניעת פצעי לחץ אשר משתמש בטכנולוגיה
                  שמודדת את העומסים על רקמות הגוף ומעריכה את הסיכון לפצעים.
                </p>
                <p>
                  מערכת זו מיועדת לצורך מדידת הלחץ הנמצאים באזורים שונים בגוף.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '531.5px',
                left: '214.25px',
                top: '145.5px',
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(230, 145, 56)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(255, 242, 204, 0.62)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <h2>תוספי תזונה לחולים במצבים הדורשים תמיכה באיחוי פצעים</h2>
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '731.5px',
                left: '189.25px',
                top: 45,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    אליטרק הינו מזון ייעודי מלא ומאוזן, המבוסס על פיפטידים
                    וחומצות אמינו חופשיות ומועשר בארגינין ובגלוטמין.
                    <br />
                    מותאם לחולים במצב של הפרעה מטבולית ו/או הפרעה בתפקוד מערכת
                    העיכול.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '199.8px',
                height: '199.8px',
                left: '149.2px',
                top: 296,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-154723"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={225}
                  data-natural-height={225}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896786/images-removebg-preview.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '731.5px',
                left: '189.25px',
                top: 45,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    אבקת Abound להכנת משקה בטעם תפוז, המיועדת לחולים במצבים
                    הדורשים תמיכה באיחוי פצעים או בבניית מסת גוף כחוש.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '259.8px',
                height: '259.8px',
                left: '131.2px',
                top: 264,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-154723"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={370}
                  data-natural-height={370}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896791/10025-removebg-preview.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '731.5px',
                left: '189.25px',
                top: 45,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    פרטיב הינו מזון ייעודי, עשיר בחלבון מפורק חלקית ובארגינין
                    המותאם במיוחד לריפוי פצעים.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '259.8px',
                height: '259.8px',
                left: '98.2px',
                top: 264,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-154723"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={370}
                  data-natural-height={370}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896809/%D7%A4%D7%A8%D7%98%D7%99%D7%91-removebg-preview.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '731.5px',
                left: '189.25px',
                top: 45,
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    אנשור פלוס אדוונס הינו מזון ייעודי המכיל HMB, המותאם לצרכים
                    תזונתיים של מבוגרים הזקוקים להשלמת התזונה ו/או במקרים של
                    איבוד מסת שריר, כוח שריר וירידה בתפקוד. אנשור פלוס אדוונס
                    הינה פורמולה מאוזנת ועשירה בקלוריות, חלבון, HMB וויטמין D,
                    למתן בשתייה.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '200.8px',
                height: '200.8px',
                left: '125.2px',
                top: 308,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-154723"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={360}
                  data-natural-height={370}
                  data-lazy-loaded=""
                  data-crop-x={0}
                  data-crop-y="0.013514"
                  data-crop-width={1}
                  data-crop-height="0.972973"
                  style={{ left: 0, top: '-3px', width: 201, height: 206 }}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896830/ansure.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '578.5px',
                left: '312.25px',
                top: '98.5px',
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    איזי ווי הינו חלבון מי הגבינה מכיל Beta-Lactoglobulin.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '215.378px',
                height: '166.715px',
                left: '126.872px',
                top: '68.6425px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-154723"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={478}
                  data-natural-height={370}
                  data-lazy-loaded=""
                  style={{}}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896842/%D7%90%D7%99%D7%96%D7%99%D7%95%D7%95%D7%90%D7%99-1-%D7%A7_%D7%92-removebg-preview.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '578.5px',
                left: '312.25px',
                top: 298,
                height: 'auto'
              }}
              data-name="text-8818f5"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 12,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    איזי מילק הוא משקה חלבי, פרהביוטי, עשיר בקלוריות, חלבון,
                    סיבים תזונתיים ויטמינים ומינרלים.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '143.789px',
                height: '222.547px',
                left: '162.667px',
                top: 279,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-c114b0"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={206}
                  data-natural-height={370}
                  data-lazy-loaded=""
                  data-crop-x="0.001465"
                  data-crop-y="0.069767"
                  data-crop-width={1}
                  data-crop-height="0.861712"
                  style={{
                    left: '-0.211px',
                    top: '-18px',
                    width: 144,
                    height: 258
                  }}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10896887/%D7%90%D7%99%D7%96%D7%99%D7%9E%D7%99%D7%9C%D7%A7-%D7%A7%D7%98%D7%9F-removebg-preview.png"
                />
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '791.5px',
                left: '66.25px',
                top: '160.5px',
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content fragment fade-out"
                  style={{
                    zIndex: 10,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                  data-fragment-index={0}
                >
                  <p style={{ textAlign: 'justify' }}>
                    מאמר שהתקבל כסקירה בעיתון Drugs &amp; Aging היוצא בניו זילנד
                    מציג גישה לטיפול בפצעי הלחץ באוכלוסייה הזקנה והמוגבלת. המסר
                    הוא שהפצע אינו מנותק מהחולה. על כן, הרופא הבא לתת ההערכה של
                    פצעי הלחץ ולטפל בהם, לא צריך להתמקד בטיפול המקומי בפצע בלבד,
                    אלא עליו לנקוט בגישה כוללנית רב-מקצועית.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '791.5px',
                left: '66.25px',
                top: '160.5px',
                height: 'auto'
              }}
              data-name="text-54f7d3"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content fragment current-visible"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                  data-fragment-index={0}
                >
                  <p style={{ textAlign: 'justify' }}>
                    נוסף על כך, מאחר שרבים מהפצעים נעשים כרוניים ואינם מגיעים
                    לשלב הריפוי, הרי שמטרת הטיפול משתנה: מטיפול שמתמקד בפצע
                    המקומי עובר הטיפול להתמקד בשמירת איכות החיים של החולה, תוך
                    שליטה על תלונותיו ותוך מתן תמיכה למשפחתו בהתמודדותה
                    היום-יומית.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(252, 229, 205, 0.87)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="0% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '791.5px',
                left: '66.25px',
                top: '160.5px',
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    textAlign: 'center',
                    color: 'rgb(120, 63, 4)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    הטיפול בפצעים דורש התייחסות רב-מערכתית ורב-מקצועית, ולכן על
                    הצוות המטפל להיות רב-מקצועי, ולנקוט בראייה כוללנית באמצעות
                    מניעה, טיפול מערכתי ומקומי ודאגה לאיכות החיים של המטופל
                    ומשפחתו.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(217, 234, 211, 0.76)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-opacity="0.1"
            data-auto-animate=""
            data-background-size="initial"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '447.5px',
                left: '278.25px',
                top: '57.5px',
                height: 'auto'
              }}
              data-name="text-c25c3f"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 10,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <p>
                    <strong>
                      <span style={{ fontSize: '1.2em' }}>
                        הצוות הרב-מקצועי יכלול:
                      </span>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '777.5px',
                left: '113.25px',
                top: '164.5px',
                height: 'auto'
              }}
              data-name="text-15b523"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 11,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <ul>
                    <li
                      style={{ textAlign: 'justify' }}
                      className="fragment fade-out"
                      data-fragment-index={0}
                    >
                      אחיות – לטיפול מקומי בפצע, להטמעת תכניות מניעה ומתן מידע,
                      ולטיפול בחוסר שליטה במתן שתן.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '777.5px',
                left: '113.25px',
                top: '164.5px',
                height: 'auto'
              }}
              data-name="text-001924"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 12,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <ul>
                    <li
                      style={{ textAlign: 'justify' }}
                      className="fragment current-visible"
                      data-fragment-index={0}
                    >
                      מטפלים סיעודיים – לשינויי תנוחה, להורדות והושבות, לרחצה,
                      להחלפת חיתולים ולטיפול היגייני של העור.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '777.5px',
                left: '113.25px',
                top: 164,
                height: 'auto'
              }}
              data-name="text-971fbe"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 13,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <ul>
                    <li
                      className="fragment current-visible"
                      data-fragment-index={1}
                      style={{
                        textAlign: 'justify'
                      }}
                    >
                      מטפלים שיקומיים – לשיפור ניידות, למניעת קונטרקטורות,
                      להדרכת ישיבה ושכיבה נכונה ולמתן מידע על ציוד להורדת לחץ
                      (כריות, מזרנים, מגני עקבים ומרפקים).
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '777.5px',
                left: '113.25px',
                top: '164.5px',
                height: 'auto'
              }}
              data-name="text-c5da8c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 14, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 14,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <ul>
                    <li
                      className="fragment current-visible"
                      data-fragment-index={2}
                      style={{
                        textAlign: 'justify'
                      }}
                    >
                      רופאים – לניקוי (הטריות) של פצעים מזוהמים, לאיזון מחלות
                      רקע, לטיפול בזיהומים ולהזמנת יועצים רפואיים מתחום
                      הכירורגיה והפלסטיקה לפי הצורך.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '777.5px',
                left: '113.25px',
                top: '164.5px',
                height: 'auto'
              }}
              data-name="text-a0e848"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 15, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 15,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <ul>
                    <li
                      className="fragment current-visible"
                      data-fragment-index={3}
                    >
                      דיאטניות – להערכת המצב התזונתי של החולה ולהמלצות על תוספות
                      והעשרות של קלוריות וחלבונים.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '777.5px',
                left: '170.25px',
                top: '164.5px',
                height: 'auto'
              }}
              data-name="text-76ac35"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 16, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 16,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <ul>
                    <li
                      className="fragment current-visible"
                      data-fragment-index={4}
                    >
                      קלינאי תקשורת – להערכה של מנגנון הבליעה.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '777.5px',
                left: '113.25px',
                top: '164.5px',
                height: 'auto'
              }}
              data-name="text-1b246c"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 17, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 17,
                    textAlign: 'center',
                    color: 'rgb(39, 78, 19)',
                    fontSize: '112%',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderStyle: 'solid',
                    borderRadius: 17,
                    padding: 10
                  }}
                >
                  <ul>
                    <li
                      className="fragment current-visible"
                      data-fragment-index={5}
                    >
                      עובדים סוציאליים – להכוונה לרכישת ציוד, להכוונה למטפלים
                      סיעודיים, למציאת מסגרת אשפוז ולתמיכה במשפחה.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '723.457px',
                left: '118.272px',
                top: 37,
                height: 'auto'
              }}
              data-name="text-ed29ee"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 10,
                    color: 'rgb(68, 68, 68)',
                    fontSize: '90%'
                  }}
                >
                  <h1>טיפול במצבי רקע רפואיים</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '797.354px',
                left: '74.375px',
                top: '202.216px'
              }}
              data-name="text-06f938"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(34, 34, 34)',
                  textAlign: 'justify'
                }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    פתיחת כלי דם חסומים המונעים אספקת דם וחמצן.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    איזון מחלות הגורמות לבצקות בעור, כגון: אי-ספיקת לב, אי-ספיקת
                    כליות ותת-פעילות של בלוטת התריס.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מתן דם באנמיה וחמצן במחלות נשימתיות.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    העשרה תזונתית בקלוריות ובחלבונים במצבי תת-תזונה.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    טיפול אנטיביוטי בזיהומים הקשורים בפצע, ברקמות הרכות ובדם,
                    וטיפול בזיהומים שאינם קשורים בפצע.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '753.457px',
                left: '118.272px',
                top: 125,
                height: 'auto'
              }}
              data-name="text-9b2b9d"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 12,
                    color: 'rgb(68, 68, 68)',
                    fontSize: '90%'
                  }}
                >
                  <p>
                    <u>
                      תפקיד הטיפול המערכתי להביא לייצוב ולאיזון מצבים רפואיים
                      המעכבים את הריפוי המקומי. טיפול זה כולל:
                    </u>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '506.457px',
                left: '226.772px',
                top: 41,
                height: 'auto'
              }}
              data-name="text-ed29ee"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 10,
                    color: 'rgb(34, 34, 34)',
                    fontSize: '90%'
                  }}
                >
                  <h1 style={{ textAlign: 'center' }}>טיפול מקומי בפצע</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '721.457px',
                left: '119.272px',
                top: 156,
                height: 'auto'
              }}
              data-name="text-9b2b9d"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'justify',
                    zIndex: 12,
                    color: 'rgb(34, 34, 34)',
                    fontSize: '90%'
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    <u>
                      הטיפול המקומי בפצע מתבסס על חבישות אטומות מתקדמות שמאפשרות
                      טיפול לכמה ימים ללא צורך בהחלפה.
                    </u>
                  </p>
                  <p style={{ textAlign: 'justify' }}>
                    <u>
                      יש להשתמש בחבישות שמונעות דליפת ריח והפרשות ושאינן מכאיבות
                      בהסרתן.
                    </u>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '506.457px',
                left: '226.772px',
                top: 41,
                height: 'auto'
              }}
              data-name="text-ed29ee"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 10,
                    color: 'rgb(34, 34, 34)',
                    fontSize: '90%'
                  }}
                >
                  <h1 style={{ textAlign: 'center' }}>טיפול מקומי בפצע</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 332,
                left: '567.229px',
                top: '140.5px'
              }}
              data-name="text-22c0d1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(34, 34, 34)' }}
              >
                <p>
                  <u>
                    <strong>החבישות צריכות לאפשר:</strong>
                  </u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 332,
                left: '517.001px',
                top: '191.5px'
              }}
              data-name="text-416fac"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, color: 'rgb(34, 34, 34)' }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>ספיגת הפרשות.</li>
                  <li style={{ textAlign: 'justify' }}>
                    הגנה מפני חדירת זיהומים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    ביצוע הטריה וניקוי הפצעים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>מניעת כאב, סבל וריח.</li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 332, left: 132, top: '191.5px' }}
              data-name="text-1cd672"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, color: 'rgb(34, 34, 34)' }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    החלפת החבישה מדי יומיים- שלושה.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    עידוד צמיחת רקמת גרעון ואפיתליזציה לסגירת הפצע.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    שמירה על סביבה לחה כדי למנוע את התייבשות הפצע.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 545,
                left: '207.5px',
                top: '75.5px'
              }}
              data-name="text-3cebd2"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(7, 55, 99)' }}
              >
                <h2>שמירה על איכות החיים</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 754, left: 103, top: 212 }}
              data-name="text-fe8724"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <p>
                  <u>
                    כאשר הפצע הופך לכרוני, מטרת העל משתנה מריפוי הפצע לשליטה על
                    התסמינים ולהבטחת איכות חיים.
                  </u>
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 210, 233)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 324, left: 558, top: 79 }}
              data-name="text-fe8724"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(7, 55, 99)' }}
              >
                <p>
                  <u>מטרות הטיפול הן:</u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 590, left: 263, top: '170.5px' }}
              data-name="text-d8ebf6"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <ul>
                  <li>
                    החזרת סדר היום הקבוע.
                    <br type="_moz" />
                  </li>
                  <li>
                    עזרה בתפקודי היום-יום – ADL.
                    <br type="_moz" />
                  </li>
                  <li>
                    מניעת הופעת כאבים ואי-נוחות מהפצע.
                    <br type="_moz" />
                  </li>
                  <li>
                    הסרת ריח לא נעים, זיהום והפרשות.
                    <br type="_moz" />
                  </li>
                  <li>
                    מניעת בדידות חברתית, דיכאון וחוסר שינה.
                    <br type="_moz" />
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 234, 211)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-opacity="0.1"
            data-auto-animate=""
            data-background-size="initial"
            data-background-position="100% 100%"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 402, left: 480, top: 79 }}
              data-name="text-fe8724"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(7, 55, 99)' }}
              >
                <p>
                  <u>דילמות של סוף החיים כוללות:</u>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 627, left: 232, top: '167.5px' }}
              data-name="text-d8ebf6"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    החלטה על ביצוע קטיעה או על ביצוע הטריה כירורגית עמוקה.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    אשפוז במחלקה סיעודית או סיעודית מורכבת או הישארות בבית.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מינוי אפוטרופוס בהתאם למצבו הקוגניטיבי ולכשירותו השכלית של
                    המטופל, או מתן ייפוי-כוח בהתאם לצרכיו ולרצונו של המטופל.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgb(217, 234, 211)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="contain"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 402, left: 279, top: 56 }}
              data-name="text-fe8724"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, color: 'rgb(7, 55, 99)' }}
              >
                <h2>
                  <strong>טיפול במשפחה</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 627,
                left: '166.5px',
                top: '171.5px'
              }}
              data-name="text-d8ebf6"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(7, 55, 99)' }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    תיאום ציפיות ומתן מידע על אביזרי מניעה וטיפול.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    סיוע באמצעות מטפלים סיעודיים לעזרה ברחצה ובהלבשה.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    הצעה לאשפוז במחלקה לסיעוד מורכב, העוסקת בטיפול בפצעים
                    מזוהמים ובפצעים עמוקים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מתן תמיכה למשפחה בהתמודדות היום-יומית.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מינוי אפוטרופוס במקרים של חוסר שיפוט ושיטיון.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(217, 234, 211, 0.51)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 243, left: '707.5px', top: 28, height: 'auto' }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'center',
                  color: 'rgb(28, 69, 135)'
                }}
              >
                <p>שאלה</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '300px',
                height: '300.6px',
                left: '20.957px',
                top: '20.2px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-e27b5b"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 2, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 2 }}>
                  <img
                    className=""
                    data-natural-width={211}
                    data-natural-height={895}
                    data-lazy-loaded=""
                    style={{
                      zIndex: 2
                    }}
                    data-src={qWound}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: '82px',
                color: 'black'
              }}
              className="customQuestionAside"
            >
              <QuestionSlide
                key="wounds-76"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 75)}
              />
            </div>
          </section>
          <section
            data-background-color="rgba(217, 234, 211, 0.51)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h1>כל הכבוד!</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-checkmark-circle"
                data-shape-fill-color="rgb(34, 189, 72)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(34, 189, 72)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 647, left: 167, top: 206 }}
              data-name="text-ce60d4"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <h2>יש יותר מתשובה אחת נכונה, בוא נסביר קצת על המשחות.</h2>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(217, 234, 211, 0.51)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>DERMAGRAN GEL</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-checkmark-circle"
                data-shape-fill-color="rgb(34, 189, 72)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(34, 189, 72)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 727, left: 110, top: 210 }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.625' }}
                data-has-line-height=""
              >
                <ul>
                  <li>
                    מיועד לריכוך הרקמה הנקרוטית הקשה - הטרייה אוטוליטית וכן
                    לפצעים אשר להם הפרשה מועטה, על מנת לשמור על סביבה לחה.
                  </li>
                  <li>
                    הצמיגות של הג'ל יורדת מעט עם ההתחממות לטמפרטורת הגוף, יש
                    לשמור ולמנוע מסרציה של שולי הפצע.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(217, 234, 211, 0.51)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>DERMAGRAN GEL</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-checkmark-circle"
                data-shape-fill-color="rgb(34, 189, 72)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(34, 189, 72)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 727, left: '116.5px', top: 209 }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.43' }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ color: '#006400' }}>
                    <strong>החבישה מתאימה - </strong>
                  </span>
                  הפצע שראינו נראה מעט מזוהם, עם מעט הפרשה, עם שכבת פיברין.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  אנחנו צרכים לעשות הטריה אוטוליטית – להוריד את השכבה הצהובה.
                  מאוד חשוב לעשות הגנה מסביב לפצע כדוגמא: DERMAGRAN B, מגינית,
                  על מנת למנוע מסרציה של עור הבריא.
                </p>
              </div>
            </div>
          </section>{' '}
          <section
            data-background-color="rgba(217, 234, 211, 0.51)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>FLAMINAL</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-checkmark-circle"
                data-shape-fill-color="rgb(34, 189, 72)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(34, 189, 72)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 727, left: 103, top: '167.5px' }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.625' }}
                data-has-line-height=""
              >
                <ul>
                  <li>לטיפול בכוויות מדרגה שניה , פצעי לחץ ופצעים פתוחים.</li>
                  <li>
                    סדרת Flaminal יעילה לשימוש הן במרפאות ובבתי חולים והן בבית,
                    לטיפול בכוויות, חתכים ופצעים פתוחים בכל הדרגות ומיועדת.
                  </li>
                  <li>
                    Flaminal Hydro לטיפול בפצעים וכוויות עד רמת הפרשה בינונית​.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(217, 234, 211, 0.51)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>FLAMINAL</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-checkmark-circle"
                data-shape-fill-color="rgb(34, 189, 72)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(34, 189, 72)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 727,
                left: '116.5px',
                top: '173.5px'
              }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.43' }}
                data-has-line-height=""
              >
                <ul>
                  <li>Flaminal Forte לטיפול בפצעים וכוויות ברמת הפרשה גבוהה</li>
                  <li>אינו רעיל לתאים.</li>
                  <li>טיפול באופן יעיל וסלקטיבי בזיהום.</li>
                  <li>ריפוי מהיר.</li>
                  <li>אין התוויות נגד, ואין רגישות ידועה.</li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(217, 234, 211, 0.51)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>FLAMINAL</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-checkmark-circle"
                data-shape-fill-color="rgb(34, 189, 72)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(34, 189, 72)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM13.52 23.383l-7.362-7.363 2.828-2.828 4.533 4.535 9.617-9.617 2.828 2.828-12.444 12.445z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 727,
                left: '116.5px',
                top: '173.5px'
              }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.43' }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ color: '#008000' }}>
                    <strong>החבישה מתאימה לפצע – </strong>
                  </span>
                  הפצע שראינו נראה מעט מזוהם, עם מעט הפרשה, עם שכבת פיברין.
                </p>
                <p style={{ textAlign: 'right' }}>
                  אנחנו צרכים לעשות הטריה אוטוליטית – להוריד את השכבה הצהובה,
                  ניתן לעשות זאת גם עם FLAMINAL .
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(222, 197, 197, 0.82)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>PROMOGRAN</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-x-circle"
                data-shape-fill-color="rgb(224, 102, 102)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(224, 102, 102)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 727,
                left: '116.5px',
                top: '167.5px'
              }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.43' }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    PROMOGRAN: היא חבישה טיפולית מתקדמת לפצע שאינו מתקדם לריפוי
                    – "תקוע".
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    חבישה טיפולית נספגת המורכבת מ-45% תאית (ORC) ו-55% קולגן
                    טבעי, לעידוד של שגשוג תאים וסגירת הפצע.
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    על מנת לשמור על סביבת ריפוי פצע לחה, החבישה חייבת להיות
                    מכוסה בחבישה משנית אטומה למחצה או לא אטומה (TIELLE).
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(222, 197, 197, 0.82)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>PROMOGRAN</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-x-circle"
                data-shape-fill-color="rgb(224, 102, 102)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(224, 102, 102)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 727,
                left: '116.5px',
                top: '167.5px'
              }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.43' }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    החבישה הופכת לג'ל ונספגת בפצע.
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    כאשר החבישה נספגת מוסיפים מהחבישה הטיפולית כל מספר ימים
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    אנחנו צרכים לעשות הטריה אוטוליטית – להוריד את השכבה הצהובה.
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    <span style={{ color: '#b22222' }}>
                      <strong>החבישה אינה מתאימה </strong>
                    </span>
                    - הפצע שראינו נראה מעט מזוהם, עם מעט הפרשה, עם שכבת פיברין.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(222, 197, 197, 0.82)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>GRANUFLEX</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-x-circle"
                data-shape-fill-color="rgb(224, 102, 102)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(224, 102, 102)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 727,
                left: '116.5px',
                top: '167.5px'
              }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.43' }}
                data-has-line-height=""
              >
                <ul>
                  <li>
                    חבישה העשויה מג'לטין, פקטין וחומר הנקרא carboxymethyl מופיע
                    כיריעות דביקות, ג'ל למילוי חללים, וכן אבקות.
                  </li>
                  <li>
                    במגע עם הפצע וטמפרטורת הגוף, יוצר ג'ל מיוחד היוצר סביבה
                    התומכת בריפוי מהיר יותר.
                  </li>
                  <li>
                    מתאים בפצעים עם הפרשה מועטה עד בינונית ושאינם מזוהמים.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            data-background-color="rgba(222, 197, 197, 0.82)"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-size="initial"
            data-background-position="50% 0%"
            data-background-opacity="0.1"
            data-auto-animate=""
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: 522, left: 219, top: 54 }}
              data-name="text-1b6451"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <h2>GRANUFLEX</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 79, height: 79, left: 152, top: '88.5px' }}
              data-name="shape-a07dd8"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-x-circle"
                data-shape-fill-color="rgb(224, 102, 102)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(224, 102, 102)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 727,
                left: '116.5px',
                top: '193.5px'
              }}
              data-name="text-1fc9a1"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, lineHeight: '1.43' }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ color: '#b22222' }}>
                    <strong>החבישה אינה מתאימה </strong>
                  </span>
                  - הפצע שראינו נראה מעט מזוהם, עם מעט הפרשה, עם שכבת פיברין.
                </p>
                <p style={{ textAlign: 'right' }}>
                  אנחנו צרכים לעשות הטריה אוטוליטית – להוריד את השכבה הצהובה.
                </p>
              </div>
            </div>
          </section>
          <section data-background-image={intro} data-background-opacity="0.63">
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-d52fa8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '472px 80.5px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 892,
                    left: 26,
                    top: '41.5px',
                    height: 'auto'
                  }}
                  data-name="text-0bb4dd"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 12,
                      fontSize: '197%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.22)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26
                    }}
                  >
                    <p style={{ textAlign: 'center', color: 'red' }}>
                      <strong>סיכום</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-475d92"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 205px 0px', zIndex: 13 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 700,
                    left: 148,
                    top: '141.5px',
                    height: 'auto'
                  }}
                  data-name="text-830ef9"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 15,
                      fontSize: '121%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.42)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26,
                      padding: '12px'
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'justify',
                        fontWeight: 400
                      }}
                    >
                      חשוב לזכור שפצעי לחץ מתפתחים תוך זמן קצר אך לוקח לטפל בהם
                      חודשים ואף שנים.
                      <br />
                      פצעי לחץ מסכנים את חיי המרותקים למיטה ופוגעים קשות באיכות
                      חייהם. בזכות הרפואה המודרנית ניתן ואפשר למנוע את היווצרותם
                      ולטפל בהם ביעילות בטיפול רב מערכתי חדש.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-background-image={intro} data-background-opacity="0.63">
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-475d92"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 205px 0px', zIndex: 13 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 700,
                    left: 148,
                    top: '141.5px',
                    height: 'auto'
                  }}
                  data-name="text-830ef9"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 15,
                      fontSize: '121%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.42)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26,
                      padding: '24px'
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'justify',
                        fontWeight: 400
                      }}
                    >
                      אנו מקווים שלומדה זו עזרה להבין ולהמחיש את הנושא המאוד
                      חשוב על פצעי לחץ ותעזור לכם לאמץ את צורת החשיבה במניעה
                      ובטיפול הנכון בפצעים.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-background-image={intro} data-background-opacity="0.63">
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-d52fa8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '472px 80.5px 0px', zIndex: 10 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 892,
                    left: 26,
                    top: '41.5px',
                    height: 'auto'
                  }}
                  data-name="text-0bb4dd"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 12,
                      fontSize: '197%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.22)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26
                    }}
                  >
                    <p style={{ textAlign: 'center' }}>
                      <strong>כל הכבוד!</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-475d92"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 205px 0px', zIndex: 13 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 664,
                    left: 148,
                    top: '141.5px',
                    height: 'auto'
                  }}
                  data-name="text-830ef9"
                >
                  <div
                    className="sl-block-content title-font"
                    style={{
                      zIndex: 15,
                      fontSize: '161%',
                      color: 'rgb(0, 0, 0)',
                      backgroundColor: 'rgba(204, 204, 204, 0.22)',
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderRadius: 26
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 600
                      }}
                    >
                      סיימת את המצגת, אנו גאים בך!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: '100%', top: 350 }}
              data-name="button-f3216ab"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSetViewToSignCourseware()}
                  sx={{ minWidth: 160, fontSize: '1.15rem' }}
                  // disabled={!didReachTheEnd}
                >
                  חתימה על סיום הלומדה
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Wounds;
