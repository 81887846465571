const saveSearchToLS = (searchAddress, center, distanceRadius) => {
  const recentSearch = localStorage.getItem('search');
  if (recentSearch) {
    const parsed = JSON.parse(recentSearch);
    if (parsed.length < 10) {
      parsed.unshift({
        searchAddress,
        center,
        distanceRadius
      });
      localStorage.setItem('search', JSON.stringify(parsed));
    } else {
      const sliced = parsed.slice(0, 10);
      localStorage.setItem(
        'search',
        JSON.stringify([
          {
            searchAddress,
            center,
            distanceRadius
          },
          ...sliced
        ])
      );
    }
  } else {
    localStorage.setItem(
      'search',
      JSON.stringify([
        {
          searchAddress,
          center,
          distanceRadius
        }
      ])
    );
  }
};

export default saveSearchToLS;
