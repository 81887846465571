import './orgPin.scss';

const OrgBouncyPin = () => (
  <div className="container">
    <div className="marker" />
    <div className="pulse" />
  </div>
);

export default OrgBouncyPin;
