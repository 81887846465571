import { Button, Grid, Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import lottieInteraction from 'src/assets/lottie/empty-and-lost.json';

const EmptyState = ({
  actionFunc,
  secondary = 'תרצה שנוסיף יחד ?',
  ctaLabel,
  ctaIcon,
  primary
}) => {
  EmptyState.propTypes = {
    actionFunc: PropTypes.func,
    secondary: PropTypes.string,
    ctaLabel: PropTypes.string,
    primary: PropTypes.string,
    ctaIcon: PropTypes.node
  };
  EmptyState.defaultProps = {
    primary: 'אין יותר מדי כאן!'
  };
  return (
    <Grid
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        height: { xs: '80vh', md: '60vh' }
      }}
      container
    >
      <Grid item xs={12} md={5} lg={5}>
        <Stack gap={2} alignItems="center" direction="column">
          <Typography variant="h3">{primary || 'אין יותר מדי כאן!'}</Typography>
          <Typography>{secondary}</Typography>
          {actionFunc && (
            <Button
              onClick={() => {
                actionFunc();
              }}
              variant="contained"
              endIcon={ctaIcon && ctaIcon}
            >
              {ctaLabel}
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid
        sx={{
          justifyContent: 'center',
          display: 'flex',
          opacity: '0.8'
        }}
        item
        xs={12}
        md={7}
        lg={7}
      >
        <Lottie
          style={{
            width: '60%',
            backgroundColor: 'rgba(0,0,0,0)',
            fillOpacity: 0
          }}
          animationData={lottieInteraction}
          loop
          autoPlay={Boolean(true)}
        />
      </Grid>
    </Grid>
  );
};

export default EmptyState;
