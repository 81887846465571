const geocodeAddress = async (address) => {
  const apiKey = process.env.REACT_APP_GMAPS_APIKEY;
  const encodedSearch = encodeURIComponent(address);
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedSearch}&key=${apiKey}`;
  return fetch(url).then(async (res) => {
    const json = await res.json();
    if (json.status === 'OK') {
      if (json && json.results.length > 0) {
        const firstResult = json.results[0];
        const {
          geometry: {
            location: { lat, lng }
          }
        } = firstResult;
        const center = [lng, lat];
        return center;
      }
    }
  });
};

export default geocodeAddress;
