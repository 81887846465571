import PropType from 'prop-types';
import { useEffect, useRef, useState } from 'react';

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const lat = place.geometry.location.lat();
  const lng = place.geometry.location.lng();
  const address = {
    city: '',
    state: '',
    zip: '',
    country: '',
    lat,
    lng,
    plain() {
      const city = this.city ? `${this.city}, ` : '';
      const zip = this.zip ? `${this.zip}, ` : '';
      const state = this.state ? `${this.state}, ` : '';
      return city + zip + state + this.country;
    }
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const { types, long_name: value } = component;
    // const value = component.long_name;
    if (types.includes('locality')) {
      address.city = value;
    }

    if (types.includes('administrative_area_level_2')) {
      address.state = value;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }
  });

  return address;
};

const PlacesAutoComplete = ({ mapRef, setZoom }) => {
  PlacesAutoComplete.propTypes = {
    mapRef: PropType.object,
    setZoom: PropType.func
  };

  const searchInput = useRef(null);
  const [address, setAddress] = useState({});
  const apiKey = process.env.REACT_APP_GMAPS_APIKEY;
  const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
  const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };
  useEffect(() => {
    if (address && address.lat && address.lng) {
      // flyTo it
      mapRef.current.flyTo({
        center: [address.lng, address.lat],
        duration: 2000,
        zoom: 15
      });
    }
  }, [address]);

  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(['address_component', 'geometry']);
    autocomplete.addListener('place_changed', () =>
      onChangeAddress(autocomplete)
    );
  };
  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    searchInput.current.value = 'Getting your location...';
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        setAddress(_address);
        searchInput.current.value = _address.plain();
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  return (
    <input
      className="auto-complete-search"
      ref={searchInput}
      type="text"
      placeholder="חיפוש כתובת..."
    />
  );
};

export default PlacesAutoComplete;
