const calcOccupancyPercent = (total, number_of_beds_in_org) => {
//   const { number_of_beds_in_org } = currentUser;
  if (number_of_beds_in_org && Object.keys(number_of_beds_in_org).length > 0) {
    const tagsInOrg = Object.keys(number_of_beds_in_org);
    const sum = tagsInOrg.reduce(
      (acc, currTag) =>
        (acc += number_of_beds_in_org[currTag].vacant_beds || 0),
      0
    );
    return {
      total,
      total_vacant: sum,
      percent: (((total - sum) / total) * 100).toFixed(0)
    };
  }
  return {
    total,
    total_vacant: total,
    percent: 100
  };
};

export default calcOccupancyPercent;
