import { Backdrop, Stack } from '@mui/material';
import Lottie from 'lottie-react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import lighthouse from 'src/assets/services/lighthouse.json';
import './loader.scss';

const SearchLoader = ({ open }) => {
  SearchLoader.propTypes = {
    open: PropType.bool
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage'
  });
  return (
    <Backdrop
      open={Boolean(open)}
      sx={{ zIndex: 1700, justifyContent: 'center', alignItems: 'center' }}
    >
      <Stack alignItems="center" justifyContent="center">
        <Lottie
          style={{
            width: '120px !important',
            height: '100px !important',
            maxWidth: '150px',
            maxHeight: '150px'
          }}
          width="100px"
          autoplay
          animationData={lighthouse}
          loop
        />
        <div className="line">{t('justAMinute')}</div>
      </Stack>
    </Backdrop>
  );
};

export default SearchLoader;
