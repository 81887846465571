import i18n from 'src/i18n';

const cctvOptions = [
  {
    label: i18n.t('cctv.notAvailable'),
    name: 'notAvailable'
  },
  {
    label: i18n.t('cctv.inPublicAreas'),
    name: 'inPublicAreas'
  },
  {
    label: i18n.t('cctv.inRooms'),
    name: 'inRooms'
  }
];

export default cctvOptions;
