import { AddBoxOutlined, Biotech, SearchOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha, darken, lighten } from '@mui/material/styles';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import doctor from 'src/assets/medicalAssessment/doctor.png';
import medical from 'src/assets/medicalAssessment/medical.png';
import query from 'src/assets/medicalAssessment/query.png';
import AssessmentProfessionalsTable from 'src/components/MedicalAssessment/AssessmentProfessionalsTable';
import AssessmentRequestDialog from 'src/components/MedicalAssessment/AssessmentRequestDialog';
import 'src/components/MedicalAssessment/medicalAssessment.css';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import handleGTag from 'src/utils/handleGTag';

const commonOutlineSx = {
  content: '""',
  position: 'absolute',
  outline: `1px dotted ${theme.palette.primary.light}`,
  width: '95%',
  height: '100%',
  display: 'block',
  top: '-8px',
  right: '18px',
  borderRadius: 'inherit'
};
const commonAfterSx = {
  backgroundSize: '100%',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  content: '""',
  width: '80%',
  height: '80%',
  position: 'absolute',
  top: 'calc(150px / 2 - 25%)',
  right: '10%'
};
const commonBoxSx = {
  width: 160,
  aspectRatio: '1 / 1.35',
  backgroundColor: 'red',
  backgroundPosition: 'center',
  position: 'relative',
  transform: 'rotate(15deg)'
};
const MedicalAssessment = () => {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [isAssessmentRequestDialogOpen, setAssessmentRequestDialogOpen] =
    useState(false);

  const handleOpenLeaveAssessmentRequestDialog = () => {
    setAssessmentRequestDialogOpen(true);
  };
  const handleCloseLeaveAssessmentRequestDialog = () => {
    setAssessmentRequestDialogOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>הערכה רפואית</title>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="revisit-after" content="1 days" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
      </Helmet>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundImage: `radial-gradient(circle 350px at 80% 40%, ${alpha(
            theme.palette.primary.light,
            0.2
          )} 15%, ${alpha(theme.palette.secondary.light, 0.035)})`,
          p: 2,
          pt: 16
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            gap: 2
          }}
        >
          <Typography
            variant="h1"
            component="span"
            sx={{
              fontSize: matchMdDown ? '30px' : '3.5rem',
              maxWidth: 600,
              fontWeight: 600,
              textAlign: matchMdDown ? 'center' : 'justify'
            }}
          >
            זימון צוות המומחים
            <br />
            <Typography
              variant="inherit"
              component="span"
              fontSize="inherit"
              sx={{
                ml: !matchMdDown && 3
              }}
            >
              ויחידת המשך הטיפול
            </Typography>
            <br />
            <Typography
              sx={{
                color: theme.palette.secondary.light,
                ml: !matchMdDown && 5
              }}
              variant="inherit"
              component="span"
              fontSize="inherit"
            >
              של מגדלור ייעוץ והכוונה
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: matchMdDown ? '1.5em' : '2em',
              maxWidth: 700,
              color: 'text.secondary',
              mt: matchMdDown ? 4 : 2,
              textAlign: 'justify'
            }}
            component="span"
          >
            {/* תוכלו להתייעץ עם&nbsp; */}
            <Typography
              component="span"
              sx={{
                fontSize: 'inherit',
                fontWeight: 'inherit',
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.75),
                borderRadius: 2,
                px: 1,
                outline: `1px solid ${theme.palette.secondary.dark}`,
                whiteSpace: 'nowrap'
              }}
            >
              הרופאים והמומחים שלנו
            </Typography>
            , נמצאים כאן בשבילכם על מנת שתשאלו אותם כל השאלות, תוכלו לבקש הערכה
            גריאטרית, תפקודית וסוציאלית או השגחה פרטית מבלי שתצאו מהבית. אנחנו
            מגיעים אליכם ועוזרים לכם בכל מה שצריך.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            my: 3
          }}
        >
          <Box
            sx={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              maxWidth: 750,
              // boxShadow: `80px 0 80px 5px ${alpha(
              //   theme.palette.secondary.light,
              //   0.2
              // )}`,
              borderRadius: 4,
              position: 'relative'
            }}
          >
            <Icon
              color="info"
              sx={{
                position: 'absolute',
                left: '1vw',
                top: -34,
                filter: 'opacity(0.5)'
              }}
              fontSize="large"
            >
              <AddBoxOutlined fontSize="large" />
            </Icon>
            <Icon
              color="warning"
              sx={{
                position: 'absolute',
                right: '1vw',
                bottom: -40,
                filter: 'opacity(0.5)'
              }}
              fontSize="large"
            >
              <Biotech fontSize="large" />
            </Icon>
            <Card
              elevation={0}
              sx={{
                width: '100%',
                // borderRadius: 'inherit',
                background: 'transparent'
              }}
            >
              <CardContent
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: '12px !important',
                  width: 'calc(100% - 22px)'
                }}
              >
                {matchMdDown ? (
                  <IconButton
                    color="primary"
                    sx={{ outline: `1px solid ${theme.palette.primary.main}` }}
                  >
                    <SearchOutlined />
                  </IconButton>
                ) : (
                  <Button
                    fullWidth
                    sx={{
                      maxWidth: 180,
                      boxShadow: theme.shadows[0],
                      py: 1,
                      borderRadius: 3,
                      color: '#EBFBF6',
                      '&:hover': {
                        backgroundColor: `${darken('#23CBC3', 0.2)}`
                      }
                    }}
                    color="info"
                    variant="contained"
                    endIcon={<SearchOutlined />}
                    component="a"
                    href="#serviceProviders"
                  >
                    מצא נותן שירות
                  </Button>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ justifyContent: 'center', my: 4 }}
          spacing={2}
        >
          {/* <Hidden smDown> */}
          <Grid
            sx={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}
            item
            xs={5}
            sm={4}
            md={4}
            lg={3}
            className="ctaGridCon"
          >
            <Box
              sx={{
                ...commonBoxSx,
                background: alpha(theme.palette.error.light, 0.12),
                borderRadius: '7em 7em 0 0',
                '&:before': {
                  ...commonOutlineSx
                },
                '&:after': {
                  ...commonAfterSx,
                  backgroundImage: `url(${medical})`
                }
              }}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{
                maxWidth: 220,
                transform: 'translateY(-125%)'
              }}
              color="error"
              href={`${routesConst.login}`}
            >
              כניסת עובד/ארגון
            </Button>
          </Grid>
          {/* </Hidden> */}
          <Grid
            sx={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            item
            className="ctaGridCon"
            xs={12}
            sm={4}
            md={4}
            lg={3}
          >
            <Box
              sx={{
                ...commonBoxSx,
                background: alpha(theme.palette.secondary.light, 0.2),
                borderRadius: '1em',
                '&:before': {
                  ...commonOutlineSx
                },
                '&:after': {
                  ...commonAfterSx,
                  backgroundImage: `url(${query})`
                }
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ maxWidth: 220, transform: 'translateY(-100%)' }}
              onClick={() => handleOpenLeaveAssessmentRequestDialog()}
            >
              השארת פניית ייעוץ
            </Button>
          </Grid>
          <Grid
            sx={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '&:hover .MuiBox-root::before': {
                transform: 'rotate(0deg)'
              }
            }}
            className="ctaGridCon"
            item
            xs={12}
            sm={4}
            md={4}
            lg={3}
          >
            <Box
              sx={{
                ...commonBoxSx,
                borderRadius: '7em',
                background: alpha(theme.palette.primary.light, 0.5),
                '&:before': {
                  ...commonOutlineSx
                },
                '&:after': {
                  ...commonAfterSx,
                  backgroundImage: `url(${doctor})`
                }
              }}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ maxWidth: 220, transform: 'translateY(-100%)' }}
              color="info"
              href={`${routesConst.login}`}
            >
              כניסת צוות מייעץ
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AssessmentProfessionalsTable />
        </Grid>
      </Grid>
      <AssessmentRequestDialog
        open={isAssessmentRequestDialogOpen}
        onClose={handleCloseLeaveAssessmentRequestDialog}
      />
    </>
  );
};

export default MedicalAssessment;
