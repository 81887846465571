import { Grid } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ContactFormAndDetailsSection from 'src/components/ContactUs/ContactFormAndDetailsSection';
import FixedGradientSection from 'src/components/OurVision/FixedGradientSection';
import FixedGradientSuccessSection from 'src/components/OurVision/FixedGradientSuccessSection';
import OurAdvanteges from 'src/components/OurVision/OurAdvanteges';
import OurVisionHeroSection from 'src/components/OurVision/OurVisionHeroSection';

const OurVision = () => {
  const [yourMsgWasSent, setYourMsgWasSent] = useState();

  return (
    <>
      <Helmet>
        <title>החזון שלנו</title>
        <link rel="canonical" href="https://www.mgdalor.com/vision" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
      </Helmet>
      <Grid container>
        <OurVisionHeroSection />
        <OurAdvanteges />
        <FixedGradientSection />
        <FixedGradientSuccessSection />
        <Grid container item xs={12} sx={{ mt: 4 }}>
          <ContactFormAndDetailsSection
            setYourMsgWasSent={setYourMsgWasSent}
            yourMsgWasSent={yourMsgWasSent}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OurVision;
