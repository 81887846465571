/* eslint-disable react/jsx-no-duplicate-props */
import { Button, TextField } from '@mui/material';
import PropType from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import theme from 'src/theme';

const SearchTextField = ({ handleAddressChange }) => {
  SearchTextField.propTypes = {
    handleAddressChange: PropType.func
  };
  const { t } = useTranslation('translation', { keyPrefix: 'mainPage' });
  const { searchQuery } = useSearchData();
  const [didNotAdd, setDidNotAdd] = useState();
  // const handleSuggestions = async (e) => {
  //   const apiKey = process.env.REACT_APP_GMAPS_APIKEY;
  //   const encodedSearch = encodeURIComponent(e.target.value);
  //   // console.log(e.target.value);
  //   const url = `https://maps.googeapis.com/maps/api/place/autocomplete/json?input=${encodedSearch}&key=${apiKey}&radius=50000&language=he&components=country:il`;
  //   fetch(url)
  //     .then(async (res) => {
  //       // console.log(res);
  //       const parsed = await res.json();
  //       // console.log(parsed);
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <Button
      onClick={() => {
        document.querySelector('#address-text-field').focus();
      }}
      sx={{ flex: 0.45 }}
      disableRipple
    >
      <TextField
        onChange={(e) => {
          handleAddressChange(e);
          // handleSuggestions(e);
        }}
        inputMode="search"
        key="address-text-field"
        id="address-text-field"
        value={searchQuery.text}
        variant="standard"
        autoFocus={Boolean(theme.breakpoints.up('md'))}
        placeholder={t('egStreetCity')}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontSize: 18
          }
        }}
        inputProps={{
          enterKeyHint: 'done'
        }}
        // tabIndex={0}
        label={t('location')}
        InputLabelProps={{
          sx: {
            fontSize: 22,
            fontWeight: 600,
            letterSpacing: '0.066em'
          }
        }}
        sx={{
          width: '100%'
        }}
      />
    </Button>
  );
};

export default SearchTextField;
