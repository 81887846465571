import './ticket.css';

const CopounTicket = () => (
  <div className="coupon">
    {/* <div className="shinyLayer" /> */}
    <div className="copounLeft">
      <div>ממשפחת מגדלור באהבה</div>
    </div>
    <div className="copounCenter">
      <div>
        <h2>COPOUN</h2>
        <h3>מתנה</h3>
        <small
          style={{
            fontSize: '1rem'
          }}
        >
          אחרי הקליטה לבית אבות
        </small>
      </div>
    </div>
    <div className="copounRight">
      <div>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 503.000000 180.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              d="M0 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M140 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path d="M320 1195 l0 -445 40 0 40 0 0 445 0 445 -40 0 -40 0 0 -445z" />
            <path d="M500 1195 l0 -445 40 0 40 0 0 445 0 445 -40 0 -40 0 0 -445z" />
            <path d="M630 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path
              d="M860 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M990 1195 l0 -445 45 0 45 0 0 445 0 445 -45 0 -45 0 0 -445z" />
            <path d="M1130 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path
              d="M1350 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M1490 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path d="M1670 1195 l0 -445 40 0 40 0 0 445 0 445 -40 0 -40 0 0 -445z" />
            <path d="M1800 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path
              d="M1980 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M2120 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path d="M2340 1195 l0 -445 45 0 45 0 0 445 0 445 -45 0 -45 0 0 -445z" />
            <path
              d="M2480 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M2610 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path d="M2840 1195 l0 -445 40 0 40 0 0 445 0 445 -40 0 -40 0 0 -445z" />
            <path
              d="M2970 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M3110 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path d="M3330 1195 l0 -445 45 0 45 0 0 445 0 445 -45 0 -45 0 0 -445z" />
            <path
              d="M3470 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M3600 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path d="M3830 1195 l0 -445 40 0 40 0 0 445 0 445 -40 0 -40 0 0 -445z" />
            <path
              d="M3960 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path d="M4100 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path d="M4280 1195 l0 -445 40 0 40 0 0 445 0 445 -40 0 -40 0 0 -445z" />
            <path d="M4460 1195 l0 -445 40 0 40 0 0 445 0 445 -40 0 -40 0 0 -445z" />
            <path d="M4640 1195 l0 -445 65 0 65 0 0 445 0 445 -65 0 -65 0 0 -445z" />
            <path
              d="M4820 1195 c0 -438 0 -445 20 -445 20 0 20 7 20 445 0 438 0 445 -20
445 -20 0 -20 -7 -20 -445z"
            />
            <path
              d="M1640 600 c0 -18 7 -20 70 -20 39 0 70 -2 70 -5 0 -2 -22 -70 -50
-151 -27 -81 -50 -149 -50 -151 0 -2 9 -3 21 -1 17 2 30 28 71 148 27 80 52
157 55 173 l5 27 -96 0 c-89 0 -96 -1 -96 -20z"
            />
            <path
              d="M2183 594 c-64 -39 -51 -56 21 -28 14 5 16 -11 16 -145 0 -128 2
-151 15 -151 13 0 15 26 15 175 0 134 -3 175 -12 175 -7 -1 -32 -12 -55 -26z"
            />
            <path
              d="M2683 597 c-24 -11 -43 -27 -43 -35 0 -8 6 -12 13 -9 6 2 22 7 35 11
l22 7 0 -150 c0 -144 1 -151 20 -151 19 0 20 7 20 175 0 134 -3 175 -12 174
-7 0 -32 -10 -55 -22z"
            />
            <path
              d="M3173 594 c-64 -39 -51 -56 21 -28 14 5 16 -11 16 -145 0 -128 2
-151 15 -151 13 0 15 26 15 175 0 134 -3 175 -12 175 -7 -1 -32 -12 -55 -26z"
            />
            <path
              d="M3673 597 c-24 -11 -43 -27 -43 -35 0 -8 6 -12 13 -9 6 2 22 7 35 11
l22 7 0 -150 c0 -144 1 -151 20 -151 19 0 20 7 20 175 0 134 -3 175 -12 174
-7 0 -32 -10 -55 -22z"
            />
            <path
              d="M185 586 c-33 -33 -48 -98 -42 -178 6 -75 28 -121 64 -137 54 -25
105 -3 129 55 17 39 19 171 3 211 -28 74 -104 98 -154 49z m97 -23 c28 -25 23
-40 -39 -117 l-58 -71 -3 40 c-10 122 43 200 100 148z m38 -125 c0 -50 -23
-120 -46 -135 -22 -14 -26 -14 -49 1 -14 9 -25 23 -25 31 0 9 23 45 51 81 51
64 68 70 69 22z"
            />
            <path
              d="M657 528 c-3 -46 -2 -87 3 -92 5 -5 19 -2 34 8 35 22 69 20 94 -7 31
-33 30 -90 -3 -122 -30 -31 -59 -32 -89 -2 -19 19 -27 21 -39 12 -33 -24 28
-65 95 -65 21 0 40 10 64 34 28 28 34 42 34 75 0 82 -45 126 -119 119 l-41 -4
0 48 0 48 75 0 c60 0 75 3 75 15 0 12 -17 15 -89 15 l-88 0 -6 -82z"
            />
            <path
              d="M1156 579 c-3 -17 -6 -58 -6 -90 0 -63 8 -71 44 -44 66 50 142 -35
102 -113 -8 -16 -26 -32 -40 -36 -30 -7 -74 8 -82 29 -5 12 -9 13 -20 4 -21
-17 -17 -27 19 -49 38 -24 78 -25 117 -5 39 20 50 43 50 105 0 44 -4 56 -30
82 -25 25 -37 29 -72 27 -48 -3 -48 -4 -48 51 l0 40 70 0 c56 0 70 3 70 15 0
12 -16 15 -84 15 l-83 0 -7 -31z"
            />
            <path
              d="M4145 586 c-33 -33 -48 -98 -42 -178 6 -75 28 -121 64 -137 54 -25
105 -3 129 55 17 39 19 171 3 211 -28 74 -104 98 -154 49z m97 -23 c28 -25 23
-40 -39 -117 l-58 -71 -3 40 c-10 122 43 200 100 148z m38 -125 c0 -50 -23
-120 -46 -135 -22 -14 -26 -14 -49 1 -14 9 -25 23 -25 31 0 9 23 45 51 81 51
64 68 70 69 22z"
            />
            <path
              d="M4656 589 c-55 -43 -73 -206 -30 -277 45 -77 145 -64 174 23 32 98
-61 193 -138 139 -21 -14 -22 -14 -22 6 0 70 83 129 120 85 11 -14 14 -13 25
2 11 14 10 19 -4 30 -26 20 -95 16 -125 -8z m89 -154 c17 -16 25 -35 25 -60 0
-35 -35 -85 -59 -85 -25 0 -62 47 -68 86 -5 36 -2 43 22 62 36 28 50 28 80 -3z"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
);
export default CopounTicket;
