import {
  CardContent,
  LinearProgress,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import professional from 'src/assets/medicalAssessment/professional.svg';
import ExpandingSearchField from 'src/components/shared/ExpandingSearchField/ExpandingSearchField';
import DoctorsList from './DoctorsList';
import NursesList from './NursesList';
import SocialWorkersList from './SocialWorkersList';

const TabPanel = (props) => {
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AssessmentProfessionalsContent = ({
  value,
  serviceProviders,
  shownProvidersList,
  setServiceRequestDialogOpen
}) => {
  AssessmentProfessionalsContent.propTypes = {
    value: PropTypes.number,
    serviceProviders: PropTypes.array,
    shownProvidersList: PropTypes.array,
    setServiceRequestDialogOpen: PropTypes.func
  };
  const theme = useTheme();
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  // console.log(shownProvidersList);
  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, pt: 2 }}>
      <Toolbar>
        <CardContent
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            boxShadow: theme.shadows[3],
            backgroundImage: `linear-gradient(to right, ${alpha(
              theme.palette.background.paper,
              matchMdDown ? 0.6 : 0
            )} 15%, ${alpha(
              theme.palette.background.paper,
              matchMdDown ? 0.6 : 0
            )}),
    url(${professional})`,
            backgroundPosition: '0 top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '250px'
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            מה תרצה לחפש ?
          </Typography>
          <Box
            style={{
              display: 'flex',
              flex: 1,
              maxWidth: '750px !important',
              p: 3
            }}
          >
            <ExpandingSearchField placeholder="חפש לפי שם, תחום שירות או אזור" />
          </Box>
        </CardContent>
      </Toolbar>
      {!shownProvidersList ? (
        <LinearProgress />
      ) : shownProvidersList && shownProvidersList.length === 0 ? (
        <Typography>אנו מעדכנים את הרשימה בקרוב מאוד</Typography>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <DoctorsList
              setServiceRequestDialogOpen={setServiceRequestDialogOpen}
              providersList={shownProvidersList}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SocialWorkersList
              setServiceRequestDialogOpen={setServiceRequestDialogOpen}
              providersList={shownProvidersList}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <NursesList
              setServiceRequestDialogOpen={setServiceRequestDialogOpen}
              providersList={shownProvidersList}
            />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default AssessmentProfessionalsContent;
