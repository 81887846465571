import './scaleWithMgdalor.scss';

const ScaleWithMgdalor = () => (
  <>
    <div id="scaleWithMgdalor">
      <div
        aria-hidden="true"
        className="gradient-blob gradient-blob--has-loop-animation top-page-gradient-blob"
        style={{}}
      >
        <div className="gradient-blob__container">
          <div className="gradient-blob__inner">
            <img
              alt=""
              className="gradient-blob__image gradient-blob__image--default ls-is-cached lazyloaded"
              data-src="https://komplice.com/wp-content/themes/fatfish/img/gradient-general.png"
              src="https://komplice.com/wp-content/themes/fatfish/img/gradient-general.png"
            />
            <img
              alt=""
              className="gradient-blob__image gradient-blob__image--blue ls-is-cached lazyloaded"
              data-src="https://komplice.com/wp-content/themes/fatfish/img/gradient-blue.png"
              src="https://komplice.com/wp-content/themes/fatfish/img/gradient-blue.png"
            />
            <img
              alt=""
              className="gradient-blob__image gradient-blob__image--orange ls-is-cached lazyloaded"
              data-src="https://komplice.com/wp-content/themes/fatfish/img/gradient-orange.png"
              src="https://komplice.com/wp-content/themes/fatfish/img/gradient-orange.png"
            />
          </div>
        </div>
      </div>
      <div className="h-full pt-5">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-purple-900 rounded-lg overflow-hidden border border-purple-600">
              <div className="">
                <svg
                  className=""
                  version="1.1"
                  viewBox="0 0 1440 538"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    id="Page-1"
                    stroke="none"
                    strokeWidth={1}
                    transform="translate(0, 10)"
                  >
                    <g stroke="hsl(var(--brand))">
                      <g>
                        <circle fill="#9795F9" r={5}>
                          <animateMotion dur="10s" repeatCount="indefinite">
                            <mpath xlinkHref="#Path-3" />
                          </animateMotion>
                        </circle>
                        <circle fill="#9795F9" r={5}>
                          <animateMotion dur="14s" repeatCount="indefinite">
                            <mpath xlinkHref="#Path-4" />
                          </animateMotion>
                        </circle>
                        <circle fill="#9795F9" r={5}>
                          <animateMotion dur="12s" repeatCount="indefinite">
                            <mpath xlinkHref="#Path-5" />
                          </animateMotion>
                        </circle>
                        <circle fill="#9795F9" r={5}>
                          <animateMotion dur="12s" repeatCount="indefinite">
                            <mpath xlinkHref="#Path-6" />
                          </animateMotion>
                        </circle>
                        <circle fill="#9795F9" r={5}>
                          <animateMotion dur="16s" repeatCount="indefinite">
                            <mpath xlinkHref="#Path-8" />
                          </animateMotion>
                        </circle>
                        <circle fill="#9795F9" r={5}>
                          <animateMotion dur="14s" repeatCount="indefinite">
                            <mpath xlinkHref="#Path-9" />
                          </animateMotion>
                        </circle>
                        <circle fill="#9795F9" r={5}>
                          <animateMotion dur="10s" repeatCount="indefinite">
                            <mpath xlinkHref="#Path-10" />
                          </animateMotion>
                        </circle>
                        <path
                          d="M0,40.8273597 L184.412145,40.8273597 C191.039562,40.8273597 196.412145,46.1999427 196.412145,52.8273597 L196.412145,236.007509 C196.412145,242.634926 201.784728,248.007509 208.412145,248.007509 L481.772076,248.007509 C488.399493,248.007509 493.772076,253.380092 493.772076,260.007509 L493.772076,431.155365 C493.772076,437.782782 499.144659,443.155365 505.772076,443.155365 L794.554084,443.155365 C801.181501,443.155365 806.554084,437.782782 806.554084,431.155365 L806.554084,317.614634 C806.554084,310.987217 811.926667,305.614634 818.554084,305.614634 L1056.07641,305.614634 C1062.70383,305.614634 1068.07641,300.242051 1068.07641,293.614634 L1068.07641,101.916812 C1068.07641,95.2893945 1073.44899,89.9168115 1080.07641,89.9168115 L1265.74368,89.9168115 C1272.3711,89.9168115 1277.74368,95.2893945 1277.74368,101.916812 L1277.74368,504.088539 C1277.74368,510.715956 1283.11626,516.088539 1289.74368,516.088539 L1440,516.088539"
                          id="Path-2"
                        />
                        <path
                          d="M0,392.721523 L90.5,392.721523 C97.127417,392.721523 102.5,387.34894 102.5,380.721523 L102.5,142.709359 C102.5,136.081942 107.872583,130.709359 114.5,130.709359 L345.085993,130.709359 C351.71341,130.709359 357.085993,125.336776 357.085993,118.709359 L357.085993,99.5449229 C357.085993,92.9175059 362.458576,87.5449229 369.085993,87.5449229 L773.82,87.5449229 C780.447417,87.5449229 785.82,92.9175059 785.82,99.5449229 L785.82,178.870921 C785.82,185.498338 791.192583,190.870921 797.82,190.870921 L954,190.870921 C960.627417,190.870921 966,196.243504 966,202.870921 L966,380.721523 C966,387.34894 971.372583,392.721523 978,392.721523 L1170.12872,392.721523 C1176.75614,392.721523 1182.12872,387.34894 1182.12872,380.721523 L1182.12872,290.457949 C1182.12872,283.830532 1187.50131,278.457949 1194.12872,278.457949 L1440,278.457949"
                          id="Path-3"
                        />
                        <path
                          d="M0,-5.1159077e-13 L290,-5.15143483e-14 C296.627417,-2.33001413e-14 302,5.372583 302,12 L302,333.975151 C302,340.602568 307.372583,345.975151 314,345.975151 L635.138202,345.975151 C641.765619,345.975151 647.138202,351.347734 647.138202,357.975151 L647.138202,504.088539 C647.138202,510.715956 652.510785,516.088539 659.138202,516.088539 L870,516.088539 C876.627417,516.088539 882,510.715956 882,504.088539 L882,471.537721 C882,464.910304 887.372583,459.537721 894,459.537721 L1440,459.537721"
                          id="Path-4"
                        />
                        <path
                          d="M0,319.548279 L182.56841,319.548279 C189.195827,319.548279 194.56841,324.920862 194.56841,331.548279 L194.56841,460.232607 C194.56841,466.860024 199.940993,472.232607 206.56841,472.232607 L383.122636,472.232607 C389.750053,472.232607 395.122636,477.60519 395.122636,484.232607 L395.122636,504.088539 C395.122636,510.715956 400.495219,516.088539 407.122636,516.088539 L552.68,516.088539 C559.307417,516.088539 564.68,510.715956 564.68,504.088539 L564.68,12 C564.68,5.372583 570.052583,1.21743675e-15 576.68,0 L852.113195,-6.75015599e-14 C858.740612,-6.87189966e-14 864.113195,5.372583 864.113195,12 L864.113195,28.8273597 C864.113195,35.4547767 869.485778,40.8273597 876.113195,40.8273597 L988.36,40.8273597 C994.987417,40.8273597 1000.36,46.1999427 1000.36,52.8273597 L1000.36,143.177358 C1000.36,149.804775 1005.73258,155.177358 1012.36,155.177358 L1440,155.177358"
                          id="Path-5"
                        />
                        <path
                          d="M0,516.088539 L271.509432,516.088539 C278.136849,516.088539 283.509432,510.715956 283.509432,504.088539 L283.509432,425.886068 C283.509432,419.258651 288.882015,413.886068 295.509432,413.886068 L422.30201,413.886068 C428.929427,413.886068 434.30201,408.513485 434.30201,401.886068 L434.30201,0"
                          id="Path-6"
                        />
                        <path
                          d="M1440,0 L1185.05409,0 C1178.42667,1.21743675e-15 1173.05409,5.372583 1173.05409,12 L1173.05409,196.904957 C1173.05409,203.532374 1167.6815,208.904957 1161.05409,208.904957 L1130.77913,208.904957 C1124.15171,208.904957 1118.77913,214.27754 1118.77913,220.904957 L1118.77913,392.088539"
                          id="Path-8"
                        />
                        <path
                          d="M680,-5.68434189e-14 L680,273.291074 C680,279.918491 685.372583,285.291074 692,285.291074 L729.893495,285.291074 C736.520912,285.291074 741.893495,290.663657 741.893495,297.291074 L741.893495,516.088539"
                          id="Path-9"
                        />
                        <path
                          d="M926.521713,2.13162821e-13 L926.521713,122.925078 C926.521713,129.552495 921.14913,134.925078 914.521713,134.925078 L514.56823,134.925078 C507.940813,134.925078 502.56823,140.297661 502.56823,146.925078 L502.56823,183.326614 C502.56823,189.954031 497.195647,195.326614 490.56823,195.326614 L5.68434189e-14,195.326614"
                          id="Path-10"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            {/* Halo effect */}
            <svg
              className="for-now pointer-events-none absolute inset-0 c22q0 c93o0 crf3j will-change-transform"
              height={480}
              viewBox="0 0 480 480"
              width={480}
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="pulse-a"
                  x1="50%"
                  x2="50%"
                  y1="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="#A855F7" />
                  <stop offset="76.382%" stopColor="#FAF5FF" />
                  <stop offset="100%" stopColor="#6366F1" />
                </linearGradient>
              </defs>
              <g fillRule="evenodd">
                <path
                  className="c325x"
                  d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                  fill="url(#pulse-a)"
                  fillRule="evenodd"
                />
                <path
                  className="c325x c3hw7"
                  d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                  fill="url(#pulse-a)"
                  fillRule="evenodd"
                />
                <path
                  className="c325x cfhqf"
                  d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z"
                  fill="url(#pulse-a)"
                  fillRule="evenodd"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ScaleWithMgdalor;
