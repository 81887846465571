import { addDoc, collection, serverTimestamp } from '@firebase/firestore';
import { ArrowBack } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { db } from 'src/_firebase/firebase';
import theme from 'src/theme';
import CustomizedSnackBar from '../shared/snackbar/CustomizedSnackbar';
import AssessmentRequestForm from './AssessmentRequestForm';
import sendNewAssessmentRequestToMgdalor from './AsssessmentRequestSteps/sendNewAssessmentRequestToMgdalor';

const steps = ['פרטים אישיים', 'תיאור', 'סיום'];
const AssessmentRequestDialog = ({ open, onClose }) => {
  AssessmentRequestDialog.propTypes = {
    open: PropTypes.object,
    onClose: PropTypes.func
  };
  const snackbarInitState = {
    msg: '',
    open: false,
    severity: 'success'
  };

  const [snackbarState, setSnackbarState] = useState(snackbarInitState);
  const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [requestValues, setRequestValues] = useState({
    email: '',
    phone: '',
    description: '',
    patient_first_name: '',
    patient_last_name: ''
  });

  const closeSnackBar = () => {
    setSnackbarState(snackbarInitState);
  };

  const didNotFill = (val) => val === '';

  const checkIfBtnDisabled = () => {
    switch (activeStep) {
      case 0:
        if (
          didNotFill(requestValues.patient_first_name) ||
          didNotFill(requestValues.patient_last_name) ||
          didNotFill(requestValues.phone)
        ) {
          return true;
        }
        return false;
      case 1:
        if (didNotFill(requestValues.description)) {
          return true;
        }
        return false;
      default:
        break;
    }
  };
  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  // console.log(open);
  const handleSubmitRequestDetails = async () => {
    const { provider_id } = open;
    const providerRef = collection(
      db,
      `serviceProviders/${provider_id || 'mgdalor'}/assessmentRequests`
    );
    addDoc(providerRef, {
      ...requestValues,
      created_at: serverTimestamp(),
      provider_id: provider_id || 'mgdalor'
    })
      .then((res) => {
        setSnackbarState({
          ...snackbarState,
          open: true,
          msg: 'הפנייה נשלחה בהצלחה, אנו נצור קשר בהקדם האפשרי',
          severity: 'success'
        });
        onClose();
        sendNewAssessmentRequestToMgdalor(open, res.path, requestValues);
      })
      .catch((err) => {
        setSnackbarState({
          ...snackbarState,
          open: true,
          msg: 'לא הלצחנו לשלוח את הפנייה, נא לנסות שוב',
          severity: 'error'
        });
        console.log(err);
      });
  };
  return (
    <>
      <Dialog
        open={Boolean(open)}
        onClose={onClose}
        fullWidth={!matchSmDown}
        fullScreen={matchSmDown}
        maxWidth="lg"
      >
        <DialogTitle>השאר את פנייתך</DialogTitle>
        <Divider />
        <DialogContent>
          <AssessmentRequestForm
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={steps}
            requestValues={requestValues}
            setRequestValues={setRequestValues}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant={activeStep === steps.length - 1 ? 'contained' : 'outlined'}
            color="success"
            disabled={checkIfBtnDisabled()}
            onClick={
              activeStep < steps.length - 1
                ? handleNext
                : handleSubmitRequestDetails
            }
            endIcon={
              activeStep < steps.length - 1 && (
                <ArrowBack
                  sx={{
                    width: '18px'
                  }}
                />
              )
            }
          >
            {activeStep < steps.length - 1 ? 'המשך' : 'שליחת פנייה'}
          </Button>
          <Button onClick={() => onClose()}>ביטול</Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackBar
        msg={snackbarState.msg}
        open={snackbarState.open}
        severity={snackbarState.severity}
        setOpen={closeSnackBar}
        key="assessment-request-snackbar"
      />
    </>
  );
};

export default AssessmentRequestDialog;
