import { Card, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import mgdalorFull from 'src/assets/mgdalorFull.webp';
import theme from 'src/theme';
import './article.css';

const ArticlePreviewCard = ({ article, i, handleOpenSelectedArticle }) => {
  ArticlePreviewCard.propTypes = {
    article: PropTypes.any,
    i: PropTypes.number,
    handleOpenSelectedArticle: PropTypes.func
  };
  // const navigate = useNavigate();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.default,
        position: 'relative'
      }}
      elevation={0}
      component="li"
    >
      <a
        tabIndex={i + 1}
        // href={`/${routesConst.blog}/${article.title}`}
        onClick={() => {
          // navigate(`/${routesConst.blog}/${article.title}`, {
          //   replace: false
          // });
          // const stringified = JSON.stringify(article);
          // sessionStorage.setItem('article', stringified);
          handleOpenSelectedArticle(article);
        }}
        className="card"
      >
        <div
          className="card__image"
          style={{
            position: 'relative',
            width: '100%',
            height: 0,
            paddingTop: '56.2225%',
            paddingBottom: 0,
            boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
            // margin: 'auto',/
            marginTop: '1.6em',
            marginBottom: '0.9em',
            overflow: 'hidden',
            borderRadius: '8px',
            willChange: 'transform'
          }}
        >
          <iframe
            loading="lazy"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              border: 'none',
              padding: 0,
              margin: 0
            }}
            title="blog"
            // src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF6zOAL5yk&#x2F;zcNF9g1O4OlPPjNErkRgKA&#x2F;view?embed"
            // src="https://mgdalor.my.canva.site/article1"
            src={article.href}
            allowFullScreen
            allow="fullscreen"
          />
        </div>
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img className="card__thumb" src={mgdalorFull} alt={article} />
            <div className="card__header-text">
              <h3 className="card__title">{article.title}</h3>
              {article.labels && article.labels[0] && (
                <Chip
                  variant="filled"
                  color="secondary"
                  label={article.labels[0]}
                />
              )}
            </div>
          </div>
          <p className="card__description">{article.description}</p>
        </div>
      </a>
    </Card>
  );
};

export default ArticlePreviewCard;
