const dietitianDefaultOnboarding = [
  {
    id: 'major-task305',
    major_task: 'אדמניסטרציה',
    responsibilty_of: 'משאבי אנוש',
    sub_tasks: [
      {
        description: 'חוזה, סודיות, סלולרי, כרטיס עובד, כרטיס החתמת שעון.',
        responsibiltyOf: 'משאבי אנוש',
        id: 'sub-task664'
      }
    ]
  },
  {
    id: 'major-task301',
    major_task: 'הכרת המבנה',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'סיור במחלקה.',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task604'
      },
      {
        description: 'סיור בבניין: דלפק, שעון נוכחות, לוח כיבוי אש.',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task605'
      }
    ]
  },
  {
    id: 'major-task303',
    major_task: 'דרכי תקשורת',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'טלפון מנהל ישיר, מחלקה, דלפק, ודרכי קשר עם מחלקות אחרות.',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task608'
      }
    ]
  },
  {
    id: 'major-task302',
    major_task: 'תיאום ציפיות',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'ימי ושעות עבודה, לבוש.',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task606'
      },
      {
        description: "השתתפות בישיבות צוות ובחת''ש.",
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task607'
      }
    ]
  },
  {
    id: 'major-dietitian-8',
    major_task: 'נהלי עבודה',
    responsibilty_of: 'dietitian',
    sub_tasks: [
      {
        description: 'קריאת נהלי רוחב בתחום התזונה.',
        responsibiltyOf: 'dietitian',
        id: 'sub-dietitian-8'
      },
      {
        description: 'קריאה וחתימה על נהלים פנימיים.',
        responsibiltyOf: 'dietitian',
        id: 'sub-dietitian-9'
      },
      {
        description: 'נוהל חופשות והיעדרות מהעבודה, הודעה לממונה.',
        responsibiltyOf: 'dietitian',
        id: 'sub-dietitian-10'
      },
      {
        description: 'נוהל קבלת מטופל חדש.',
        responsibiltyOf: 'dietitian',
        id: 'sub-dietitian-11'
      },
      {
        description: 'נוהל טיפול במטופל.',
        responsibiltyOf: 'dietitian',
        id: 'sub-dietitian-12'
      },
      {
        description: 'נוהל תיעוד.',
        responsibiltyOf: 'dietitian',
        id: 'sub-dietitian-13'
      },
      {
        description: 'נוהל סידור עבודה ובקשות, נוהל החתמת שעון.',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task610'
      },
      {
        description: 'נוהל סודיות רפואית.',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task613'
      },
      {
        description: 'נוהל שפה.',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task614'
      },
      {
        description: 'נוהל ענידת תג שם והופעה.',
        responsibilty_of: 'אחות מחלקה',
        id: 'sub-task615'
      }
    ]
  },
  {
    id: 'major-task304',
    major_task: 'הגדרת תפקיד העובד המקצועי.',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'היכרות עם הצוות ודיירי המחלקה.',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task620'
      }
    ]
  },
  {
    id: 'major-task306',
    major_task: 'מפגש עם הצוות הרב מקצועי.',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'היכרות והצגת התפקידים.',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task665'
      }
    ]
  },
  {
    id: 'major-task308',
    major_task: 'הדרכות',
    responsibilty_of: 'נאמן ניהול סיכונים',
    sub_tasks: [
      {
        description: 'הדרכה בנושא מניעת אלימות.',
        responsibiltyOf: 'עו"ס המחלקה',
        id: 'sub-task666'
      },
      {
        description: 'הדרכה ניהול סיכונים.',
        responsibiltyOf: 'נאמן ניהול סיכונים',
        id: 'sub-task667'
      },
      {
        description: 'הדרכה בנושא מניעת זיהומים.',
        responsibiltyOf: 'נאמן מניעת זיהומים',
        id: 'sub-task668'
      }
    ]
  },
  {
    id: 'major-task309',
    major_task: 'מניעת זיהומים',
    responsibilty_of: 'נאמן מניעת זיהומים',
    sub_tasks: [
      {
        description: 'התחייבות והצגת אישור חיסון - אישור ג.',
        responsibiltyOf: 'אחראי תחום חיסונים',
        id: 'sub-task669'
      }
    ]
  }
];

export default dietitianDefaultOnboarding;
