import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ServiceProviderRow from './ServiceProviderRow';

const NursesList = ({ providersList, setServiceRequestDialogOpen }) => {
  NursesList.propTypes = {
    providersList: PropTypes.array,
    setServiceRequestDialogOpen: PropTypes.func
  };
  return (
    <Grid container spacing={2}>
      {providersList.map((provider) => (
        <Grid key={provider.provider_id} item xs={12}>
          <ServiceProviderRow
            key={`${provider.provider_id}-nurse-list-provider-row`}
            providerType="nurse"
            provider={provider}
            setServiceRequestDialogOpen={setServiceRequestDialogOpen}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default NursesList;
