import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

const GiftCard = ({
  handleVerifyGiftCardRequest,
  userDiamonds,
  coupon,
  couponImg
}) => {
  GiftCard.propTypes = {
    handleVerifyGiftCardRequest: PropTypes.func,
    userDiamonds: PropTypes.any,
    coupon: PropTypes.object,
    couponImg: PropTypes.any
  };

  return (
    <Card elevation={2}>
      <CardMedia
        image={couponImg}
        sx={{ height: 210, backgroundSize: '100% 100%' }}
      />
      <CardContent
        sx={{
          position: 'relative'
        }}
      >
        <Typography variant="body1" letterSpacing="0.0095em" fontWeight={600}>
          משתלם להמיר את היהלומים של מגדלור!
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack>
            <Typography variant="h3" fontSize="2.5rem">
              {`${coupon.value} ש"ח`}
            </Typography>
            <Typography color="text.secondary" variant="subtitle2">
              {`תמורת ${coupon.diamonds} יהלומים`}
            </Typography>
          </Stack>
          <Button
            sx={{ height: 'fit-content', py: 1 }}
            variant="contained"
            disabled={
              userDiamonds < coupon.diamonds || !handleVerifyGiftCardRequest
            }
            onClick={() => {
              if (handleVerifyGiftCardRequest) {
                handleVerifyGiftCardRequest(coupon);
              }
            }}
          >
            רכישת שובר
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default GiftCard;
