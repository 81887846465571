import { addDoc, collection, serverTimestamp } from '@firebase/firestore';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { db } from 'src/_firebase/firebase';
import promotion from 'src/assets/personalDashboard/promotion.png';
import commonConfig from 'src/common/config';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import GiftCard from './GiftCard';

const triggerRequestMail = async (currentUser, selectedCard) => {
  const message = {
    subject: 'בקשה לשובר מתנה',
    html: `
      <div style="direction:rtl;">
      <p>${currentUser.type === 'personal' ? 'העובד' : 'הארגון'}</p>
      <p>${currentUser.id}</p>
      <p>מבקש לקבל שובר מתנה בשווי ${selectedCard.value} בתמורה ל- ${
      selectedCard.diamonds
    }</p>
      <p style="color:red;text-align: center;">סך היהלומים שיש לו בחשבון כרגע הוא ${Math.floor(
        currentUser.diamonds
      )}</p>
      </div>
      `
  };
  const mailRef = collection(db, 'mail');
  await addDoc(mailRef, {
    to: commonConfig.supportMail,
    from: 'support@mgdalor.com',
    message
  }).catch((err) => console.log(err));
};
const VerifySelectedCardDialog = ({ selectedCard, onClose }) => {
  VerifySelectedCardDialog.propTypes = {
    selectedCard: PropTypes.object,
    onClose: PropTypes.func
  };
  const { currentUser } = useCurrentUserContext();
  const { setSnackbarState, snackbarState } = useSnackbar();

  const handleSendCouponRequest = () => {
    const { card_id, diamonds, value } = selectedCard;
    const dataToSave = {
      card_id,
      original_card_details: {
        diamonds,
        value
      },
      user_ref: currentUser.user_doc_id,
      created_at: serverTimestamp()
    };
    const newRedeemRequestRef = collection(
      db,
      `giftCards/${card_id}/redeemRequests`
    );
    addDoc(newRedeemRequestRef, dataToSave)
      .then(async () => {
        await triggerRequestMail(currentUser, selectedCard);
        onClose();
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'success',
          msg: 'הבקשה נוצרה בהצלחה'
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'error',
          msg: 'לא הצלחנו לצור את הבקשה, נא לנסות שוב'
        });
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={Boolean(selectedCard)}
      onClose={onClose}
    >
      <DialogTitle>בחירת שובר מתנה</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'center' }}
          spacing={2}
        >
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h4" color={theme.palette.success.main}>
              בחירה מצוינת 🎊
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ maxWidth: '500px !important' }}>
            <GiftCard
              coupon={selectedCard}
              userDiamonds={currentUser.diamonds}
              couponImg={selectedCard.image || promotion}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="justify">
              נהדר, אז השלב הוא שליחת בקשה למגדלור, לאחר אישור הבקשה ובתנאי
              שבחשבונך יהיה מספיק יהלומים, יישלח אלייך שובר מתנה במייל המעודכן
              במערכת.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={currentUser.diamonds < selectedCard.diamonds}
          onClick={handleSendCouponRequest}
          variant="contained"
          color="success"
        >
          שליחת בקשה
        </Button>
        <Button onClick={onClose} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifySelectedCardDialog;
