const isAuthorized = (currentUser, requiredPermission, selectedOrg, debug) => {
  if (currentUser && currentUser.type === 'org') {
    return true;
  }
  if (
    currentUser &&
    currentUser.employee_work_places &&
    currentUser.type === 'personal'
  ) {
    if (selectedOrg) {
      // check if he got the requiredPermission in his roles in the selected org
      const indexOfSelectedOrg = currentUser.employee_work_places.findIndex(
        (work) => work.org_ref === selectedOrg
      );
      if (indexOfSelectedOrg > -1) {
        // check if he got the right role
        if (debug) {
          console.log(currentUser.employee_work_places[indexOfSelectedOrg]);
        }
        const workPlaceSpecificDetails =
          currentUser.employee_work_places[indexOfSelectedOrg]
            .org_specific_details;
        if (
          workPlaceSpecificDetails.rbac &&
          workPlaceSpecificDetails.rbac.length > 0
        ) {
          // check if the one of the roles he got, allows he to perform the requiredPermission
          const someOfTheRulesGotTheRightPermission =
            workPlaceSpecificDetails.rbac.map((eachRbac) => {
              if (
                eachRbac.role &&
                eachRbac.role.permissions &&
                eachRbac.role.permissions.includes(requiredPermission)
              ) {
                return true;
              }
              return false;
            });
          if (someOfTheRulesGotTheRightPermission.some((el) => el)) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    }
    return false;
  }
};

export default isAuthorized;
