const diamonds = {
  friendReferral: 25,
  orgBedsDailyUpdate: 2,
  patientUpdateFirstStep: 20,
  patientUpdateSecondStep: 5,
  patientUpdateThirdStep: 2,
  coursewareDiamonds: 10
};

export default diamonds;
