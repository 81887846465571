import { addDoc, collection, doc, setDoc } from '@firebase/firestore';
import {
  InfoOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined
} from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  InputLabel,
  Link,
  ListItem,
  ListItemText,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { db, functions } from 'src/_firebase/firebase';
import orgsTags from 'src/common/orgsTags';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import { strengthColor, strengthIndicator } from 'src/utils/password-strength';
import regex from '../regex';

const FirstStep = ({
  values,
  setValues,
  setActiveView,
  handleBlur,
  handleChange,
  errors,
  touched,
  activeView
}) => {
  FirstStep.propTypes = {
    values: PropTypes.object,
    setValues: PropTypes.func,
    activeView: PropTypes.any,
    setActiveView: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object
  };
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();
  const [passwordShowing, setIsPasswordShowing] = useState(false);
  const [userAlreadyRegistered, setUserAlreadyRegistered] = useState(false);
  const [loadingToCheckId, setLoadingToCheckId] = useState(false);
  const [pass, setPass] = useState();
  const [isSelectTagsMenuOpen, setIsSelectTagsMenuOpen] = useState(false);

  const checkIfIdExistsAlready = async (id) => {
    setLoadingToCheckId(true);
    const checkIfIdExistsInAuth = httpsCallable(
      functions,
      'checkIfIdExistsInAuth'
    );
    checkIfIdExistsInAuth({ id })
      .then((res) => {
        setLoadingToCheckId(false);
        if (res.data.exists) {
          // then this user is already registered
          setUserAlreadyRegistered(true);
        } else {
          setUserAlreadyRegistered(false);
        }
      })
      .catch((err) => {
        setLoadingToCheckId(false);
        console.log(err);
      });
  };
  const changePassword = (value) => {
    setPass(value);
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp, theme.palette));
  };
  useEffect(() => {
    // whenever the view changes, update the strength
    if (pass) changePassword(pass);
  }, [activeView]);
  const handleShowPassword = () => {
    setIsPasswordShowing(!passwordShowing);
  };

  const handleSaveRecordOfOrgBeforeSecondStep = (firstPartOfValues) => {
    const { password, ...rest } = firstPartOfValues;
    const newOrgInUsersCollection = collection(db, 'users');
    if (!firstPartOfValues.user_doc_id) {
      addDoc(newOrgInUsersCollection, {
        unfinished_register: { ...rest, type: 'org' }
      })
        .then((res) => {
          setValues({
            ...firstPartOfValues,
            user_doc_id: res.id
          });
          setActiveView('secondStep');
        })
        .catch((err) => {
          console.log(err, 'err while adding to users');
        });
    } else {
      setDoc(
        doc(db, 'users', firstPartOfValues.user_doc_id),
        {
          unfinished_register: { ...rest, type: 'org' }
        },
        { merge: true }
      )
        .then(() => {
          setValues({
            ...firstPartOfValues
          });
          setActiveView('secondStep');
        })
        .catch((err) => {
          console.log(err, 'err while adding to users');
        });
    }
  };
  const handleCheckTagForNewOrg = (tagObj) => {
    if (values.tags) {
      const indexOfTag = values.tags.findIndex((el) => el === tagObj.name);
      const temp = { ...values };
      if (indexOfTag === -1) {
        temp.tags.push(tagObj.name);
      } else if (indexOfTag === 0) {
        delete temp.number_of_beds_in_org[tagObj.name];
        temp.tags.shift();
      } else if (indexOfTag === values.tags.length - 1) {
        delete temp.number_of_beds_in_org[tagObj.name];
        temp.tags.pop();
      } else {
        delete temp.number_of_beds_in_org[tagObj.name];
        temp.tags.splice(indexOfTag, 1);
      }
      setValues(temp);
    } else {
      setValues({ ...values, tags: [tagObj.name] });
    }
  };
  const handleOpenSelectTagsMenuOpen = (e) => {
    setIsSelectTagsMenuOpen(true);
  };

  const handleCloseSelectTagsMenuOpen = () => {
    // setAnchorEl();
    const selectedTagsWithZeroAmountOfBeds = values.tags.filter(
      (tag) =>
        !values.number_of_beds_in_org[tag] ||
        values.number_of_beds_in_org[tag].amount <= 0 ||
        (values.number_of_beds_in_org[tag] &&
          values.number_of_beds_in_org[tag].amount &&
          !isNumber(values.number_of_beds_in_org[tag].amount))
    );
    for (
      let index = 0;
      index < selectedTagsWithZeroAmountOfBeds.length;
      index++
    ) {
      handleCheckTagForNewOrg({
        name: selectedTagsWithZeroAmountOfBeds[index]
      });
    }
    setIsSelectTagsMenuOpen(false);
  };

  const handleChangeTagNumberOfBeds = (e, tagName) => {
    const temp = { ...values };
    // const isInt = isInteger(Number(e.target.value));
    const replaced = e.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');
    // console.log(replaced);
    if (replaced && replaced >= 0) {
      temp.number_of_beds_in_org = {
        ...temp.number_of_beds_in_org,
        [tagName]: {
          amount: Math.floor(replaced)
        }
      };
      // setValues(temp);
    } else if (replaced === '') {
      // console.log('empty');
      temp.number_of_beds_in_org = {
        ...temp.number_of_beds_in_org,
        [tagName]: {
          amount: ''
        }
      };
    }
    setValues(temp);
  };

  // console.log(errors);
  const handleCheckIfFirstStepButtonDisabled = () => {
    let disabled = true;
    if (values.user_doc_id) {
      // this means the user was already in phase two and came back
      disabled = false;
    } else if (
      !values.user_doc_id &&
      (!values.id ||
        !values.org_name ||
        !values.phone ||
        !values.password ||
        !values.email ||
        strength < 2 ||
        !values.policy ||
        userAlreadyRegistered)
    ) {
      // console.log(values.id);
      disabled = true;
    } else if (
      errors.password ||
      errors.org_name ||
      errors.id ||
      errors.phone ||
      errors.tags
    ) {
      console.log(errors);
      disabled = true;
    } else {
      disabled = false;
    }
    return disabled;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          error={Boolean(touched.org_name && errors.org_name)}
          fullWidth
          helperText={touched.org_name && errors.org_name}
          label={t('orgName')}
          margin="normal"
          name="org_name"
          required
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.org_name}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          error={Boolean(touched.id && errors.id) || userAlreadyRegistered}
          helperText={
            touched.id && !userAlreadyRegistered
              ? errors.id
              : touched.id && userAlreadyRegistered
              ? t('alreadyRegistered')
              : null
          }
          fullWidth
          label={t('orgId')}
          margin="normal"
          required
          name="id"
          onChange={(e) => {
            const replaced = e.target.value.replace(regex.id, '');
            handleChange({
              target: { name: 'id', value: replaced }
            });
          }}
          onBlur={(e) => {
            handleBlur(e);
            const replaced = e.target.value.replace(regex.id, '');
            if (e.target.value.length > 0 && replaced && replaced.length > 0) {
              checkIfIdExistsAlready(values.id);
            }
          }}
          value={values.id}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <Tooltip title='אפשר להתשמש רק במספרים, אותיות ו- " - , . , _ "'>
                <InfoOutlined />
              </Tooltip>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          error={Boolean(touched.phone && errors.phone)}
          fullWidth
          helperText={touched.phone && errors.phone}
          label={t('phone')}
          required
          margin="normal"
          name="phone"
          sx={{ mt: 0 }}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          type="phone"
          value={values.phone}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <FormControl fullWidth>
          <InputLabel id="tags-simple-select-label">
            {`${t('tags')} *`}
          </InputLabel>
          <Select
            labelId="tags-simple-select-label"
            id="tags-simple-select"
            value={values.tags}
            label={t('tags')}
            fullWidth
            required
            onOpen={handleOpenSelectTagsMenuOpen}
            name="tags"
            open={isSelectTagsMenuOpen}
            onClose={handleCloseSelectTagsMenuOpen}
            error={Boolean(touched.tags && errors.tags)}
            multiple
            renderValue={(selected) => {
              const newSelected = selected.map((tag) => {
                const index = orgsTags.findIndex((el) => el.name === tag);
                return orgsTags[index].label;
              });
              return newSelected.join(',');
            }}
          >
            {orgsTags.map((tag) => (
              <ListItem
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
                key={tag.name}
                value={tag.name}
              >
                <Stack direction="row">
                  <Checkbox
                    edge="start"
                    onChange={() => handleCheckTagForNewOrg(tag)}
                    checked={values.tags.indexOf(tag.name) > -1}
                  />
                  <ListItemText primary={tag.label} />
                </Stack>
                {values.tags.indexOf(tag.name) > -1 && (
                  <Grow in={values.tags.indexOf(tag.name) > -1}>
                    <TextField
                      placeholder={t('departmentTagPlaceholder')}
                      value={
                        values.number_of_beds_in_org &&
                        values.number_of_beds_in_org[tag.name] &&
                        values.number_of_beds_in_org[tag.name].amount
                      }
                      type="number"
                      onChange={(e) => handleChangeTagNumberOfBeds(e, tag.name)}
                      sx={{ zIndex: 10 }}
                      label={t('howMany')}
                    />
                  </Grow>
                )}
              </ListItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          label={t('email')}
          margin="normal"
          required
          name="email"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
          }}
          type="email"
          value={values.email}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          error={Boolean(touched.password && errors.password)}
          fullWidth
          helperText={touched.password && errors.password}
          label={t('password')}
          margin="normal"
          required
          name="password"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            changePassword(e.target.value);
          }}
          InputProps={{
            endAdornment: passwordShowing ? (
              <VisibilityOffOutlined
                sx={{ cursor: 'pointer' }}
                onClick={handleShowPassword}
              />
            ) : (
              <VisibilityOutlined
                sx={{ cursor: 'pointer', transition: 'ease-in 1s' }}
                onClick={handleShowPassword}
              />
            )
          }}
          type={passwordShowing ? 'text' : 'password'}
          value={values.password}
          variant="outlined"
        />
      </Grid>
      {strength !== 0 && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <Box
                  style={{ backgroundColor: level?.color, width: '100%' }}
                  sx={{ width: 85, height: 8, borderRadius: '7px' }}
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" fontSize="0.75rem">
                  {level?.label}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>
          {strength < 3 && values.password.length > 5 && (
            <FormHelperText error>{t('passwordIsWeak')}</FormHelperText>
          )}
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Checkbox
            checked={values.policy}
            name="policy"
            onChange={handleChange}
          />
          <Typography color="textSecondary" variant="body1">
            {t('iHaveReadThe')}
            &nbsp;
            <Link
              color="primary"
              component="a"
              href={`/${routesConst.privacy}`}
              underline="always"
              variant="h6"
            >
              {t('termsAndConditions')}
            </Link>
          </Typography>
        </Stack>
      </Grid>
      {Boolean(touched.policy && errors.policy) && (
        <FormHelperText error>{errors.policy}</FormHelperText>
      )}
      <Grid
        sx={{ display: 'flex', justifyContent: 'center' }}
        item
        xs={12}
        md={12}
      >
        <Button
          color="primary"
          fullWidth
          disabled={handleCheckIfFirstStepButtonDisabled()}
          size="large"
          type="button"
          onClick={() => {
            handleSaveRecordOfOrgBeforeSecondStep(values);
            // console.log(errors);
          }}
          variant="contained"
          sx={{ width: '50%' }}
        >
          {t('registerNow')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography color="textSecondary" variant="body1">
          {t('haveAnAccount')}
          &nbsp;
          <Link
            component={RouterLink}
            to={`/${routesConst.login}`}
            variant="h6"
          >
            {t('signIn')}
          </Link>
        </Typography>
      </Grid>
      <Backdrop open={loadingToCheckId}>
        <CircularProgress />
      </Backdrop>
    </Grid>
  );
};

export default FirstStep;
