import { Backdrop, CircularProgress } from '@mui/material';
import { Suspense } from 'react';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <Backdrop open={Boolean(true)}>
          <CircularProgress />
        </Backdrop>
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
