import canvasConfetti from 'canvas-confetti';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import theme from 'src/theme';

const Confetti = ({ active, customStyle }) => {
  Confetti.propTypes = {
    active: PropTypes.bool,
    customStyle: PropTypes.object
  };
  Confetti.defaultProps = {
    customStyle: {}
  };
  const canvasRef = useRef(null);

  useEffect(() => {
    if (active) {
      // Get the DOM node for the canvas element
      const canvasNode = canvasRef.current;

      // Configure the confetti options
      const confettiOptions = {
        particleCount: 100,
        spread: 160,
        origin: { y: 0.6 },
        colors: ['#ff0000', '#00ff00', '#0000ff', '#ffff00'] // Add your desired confetti colors here
      };

      // Fire the confetti animation
      canvasConfetti(confettiOptions);
    }
  }, [active]);

  return (
    <canvas
      style={{
        zIndex: theme.zIndex.modal + 10,
        ...customStyle
      }}
      ref={canvasRef}
      className="confetti-container"
    />
  );
};

export default Confetti;
