import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { functions } from 'src/_firebase/firebase';
import IntroCardForTodayClaim from 'src/components/PersonalCompensation/components/IntroCardForTodayClaim';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';

const VerifyPurchaseDialog = ({
  open,
  onClose,
  product,
  setMarketplaceProducts,
  marketplaceProducts
}) => {
  VerifyPurchaseDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    product: PropTypes.object,
    setMarketplaceProducts: PropTypes.func,
    marketplaceProducts: PropTypes.array
  };
  const { snackbarState, setSnackbarState } = useSnackbar();
  const [isLoading, setLoading] = useState();
  const { currentUser, setCurrentUser } = useCurrentUserContext();
  const purchaseDisabled = () =>
    !currentUser ||
    (currentUser &&
      Number(product.product_price) > Number(currentUser.diamonds));
  const verifyPurchase = async () => {
    // console.log(product, currentUser.user_doc_id);
    if (purchaseDisabled()) {
      setSnackbarState({
        ...snackbarState,
        open: true,
        severity: 'error',
        msg: 'אין ביתרתך מספיק יהלומים'
      });
    } else {
      setLoading(true);
      const performPurchaseRequest = httpsCallable(
        functions,
        'performPurchaseRequest'
      );
      const { first_name, last_name, id, user_doc_id, email } = currentUser;
      const res = await performPurchaseRequest({
        ...product,
        buyer_id: user_doc_id,
        buyer_nid: id,
        buyer_first_name: first_name,
        buyer_last_name: last_name,
        buyer_email: email
      });
      // console.log(res);
      setLoading(false);
      const { didExchange, didAddToPurchases, didSendEmail } = res.data;
      if (didExchange && didAddToPurchases && didSendEmail) {
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'success',
          msg: 'הרכישה בוצעה בהצלחה '
        });
        const temp = [...marketplaceProducts];
        const index = temp.findIndex(
          (el) => el.product_id === product.product_id
        );
        const tempUser = { ...currentUser };
        temp.diamonds -= product.product_price;
        setCurrentUser(tempUser);
        if (index > -1) {
          temp[index].num_of_purchases = temp[index].num_of_purchases
            ? temp[index].num_of_purchases + 1
            : 1;
          setMarketplaceProducts(temp);
        }
        onClose();
      } else {
        console.log('couldnt complete purchasing', Object.entries(res.data));
      }
    }
    // exchange diamonds
    // send link
    // increment purchase
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle>
          <Typography component="span" variant="inherit">
            רכישת
            <Typography fontWeight={600} component="span" variant="inherit">
              {` ${product.product_name}`}
            </Typography>
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid item xs={12} sx={{ maxWidth: '500px !important' }}>
              <IntroCardForTodayClaim showOnlyDiamonds={Boolean(true)} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                my: 2,
                fontSize: '18px'
              }}
            >
              <Typography
                sx={{
                  fontSize: 'inherit',
                  maxWidth: '600px',
                  textAlign: 'justify'
                }}
              >
                נא לשים לב, שלאחר הרכישה יתקבל מייל אישור עם קישור למוצר שהינך
                רוכש.
                <br />
                לכן מוצרים אשר נרכשו עם יהלומים לא ניתן לבקש החזר יהלומים או
                זיכוי עליהם.
              </Typography>
              <Typography sx={{ fontSize: 'inherit' }} fontWeight={600}>
                האם אתה מאשר את הרכישה?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={purchaseDisabled()}
            onClick={() => verifyPurchase()}
          >
            אישור רכישה
          </Button>
          <Button variant="outlined" onClick={onClose}>
            ביטול
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <Backdrop
          sx={{ zIndex: theme.zIndex.modal + 1 }}
          in={Boolean(isLoading)}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default VerifyPurchaseDialog;
