import jobsTitles from 'src/common/jobsTitles';

const checkLabelOfJobTitle = (name) => {
  const indexOfJob = jobsTitles.findIndex((el) => el.name === name);
  if (indexOfJob > -1) {
    return jobsTitles[indexOfJob].label;
  }
  return name;
};

export default checkLabelOfJobTitle;
