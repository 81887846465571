import commonConfig from 'src/common/config';
import i18n from 'src/i18n';
import getLastSearchFromLS from './getLastSearchFromLS';
import saveSearchToLS from './saveSearchToLS';
import handleAlgoliaGeodistanceSearch from './searchAlgoliaGeodistance';

const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

const handleSearchByRadius = async (
  searchQuery,
  mapCurrent,
  searchIndex,
  setSearchResults,
  distanceRadius,
  navigate,
  setResultsAreLoading,
  setGeocodingError,
  setAdvancedSettingsMounted,
  isInitialSearch
) => {
  if (!isInitialSearch) {
    setAdvancedSettingsMounted(false);
  }
  setResultsAreLoading(true);
  let addressToGeocode;
  // console.log(mapCurrent);
  if (window.location.pathname === '/results') {
    const searchFromStorage = getLastSearchFromLS();

    let searchText;
    if (searchQuery && searchQuery.text) {
      searchText = searchQuery.text;
    } else if (searchFromStorage && searchFromStorage.searchAddress) {
      searchText = searchFromStorage.searchAddress;
    } else {
      setResultsAreLoading(false);
    }
    addressToGeocode = searchText;
  } // TODO:: still needs fixing
  // console.log(searchQuery);
  if (
    !searchQuery ||
    (searchQuery && !searchQuery.text) ||
    (searchQuery && searchQuery.text === '')
  ) {
    // setGeocodingError(i18n.t('search.forBetterResultsSearchUsingStreetCity'));
    addressToGeocode = 'חיפה';
    console.log('searchQuery.text is empty');
  } else if (searchQuery && searchQuery.text) {
    addressToGeocode = searchQuery.text;
  } else {
    const lastSearch = getLastSearchFromLS();
    if (lastSearch) {
      addressToGeocode = lastSearch.searchAddress;
    }
  }

  if (addressToGeocode) {
    const apiKey = process.env.REACT_APP_GMAPS_APIKEY;
    const encodedSearch = encodeURIComponent(addressToGeocode);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedSearch}&key=${apiKey}`;
    fetch(url)
      .then(async (res) => {
        const json = await res.json();
        if (json.status === 'OK') {
          if (json && json.results.length > 0) {
            const firstResult = json.results[0];
            const {
              geometry: {
                location: { lat, lng }
              }
            } = firstResult;
            const center = [lng, lat];
            // perform geofire search using this geopoint and the distance from the user;
            // if the user wants up to 100km then this is what he gets if more then return unlimited
            handleAlgoliaGeodistanceSearch(
              center,
              distanceRadius < 100
                ? distanceRadius * 1000 * commonConfig.radiusMultiplyFactor
                : 99999999999,
              searchIndex,
              setSearchResults,
              navigate,
              setResultsAreLoading,
              mapCurrent,
              searchQuery
            )
              .then(() => {
                // navigate(`/${routesConst.results}`);
                saveSearchToLS(addressToGeocode, center, distanceRadius);
              })
              .catch((err) => {
                setResultsAreLoading(false);
                console.log(err);
              });
          } else {
            setGeocodingError(
              i18n.t('search.forBetterResultsSearchUsingStreetCity')
            );
            setResultsAreLoading(false);
          }
        } else {
          // console.log(json.status);
          if (setGeocodingError) {
            setGeocodingError(
              i18n.t('search.forBetterResultsSearchUsingStreetCity')
            );
          }
          setResultsAreLoading(false);
        }
      })
      .catch((err) => console.log('err while geocoding', err));
  } else {
    setResultsAreLoading(false);
    console.log('get the geolocation of the ip');
  }
};

export default handleSearchByRadius;
