import day1 from './images/day1.svg';
import day2 from './images/day2.svg';
import day3 from './images/day3.svg';
import day4 from './images/day4.svg';
import day5 from './images/day5.svg';
import day6 from './images/day6.svg';
import day7 from './images/day7.svg';

const dailyLoginTreasuresData = [
  {
    day: 1,
    diamonds: 5,
    img: day1
  },
  {
    day: 2,
    diamonds: 5,
    img: day2
  },
  {
    day: 3,
    diamonds: 5,
    img: day3
  },
  {
    day: 4,
    diamonds: 10,
    img: day4
  },
  {
    day: 5,
    diamonds: 10,
    img: day5
  },
  {
    day: 6,
    diamonds: 10,
    img: day6
  },
  {
    day: 7,
    diamonds: 25,
    img: day7
  }
];

export default dailyLoginTreasuresData;
