import {
  DevicesOutlined,
  Diversity2Outlined,
  JoinInnerOutlined,
  MoneyOutlined,
  VolunteerActivismOutlined
} from '@mui/icons-material';
import { Box, CardMedia, Container, Grid, useTheme } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import bgFront from 'src/assets/home/balloons.webp';
import flower from 'src/assets/home/flower.svg';
import DefaultInfoCard from '../shared/DefaultInfoCard/DefaultInfoCard';
import RotatingCardFront from '../shared/RotatingCard/RotatingCardFront';

function Information() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage.information'
  });
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        backgroundImage: 'linear-gradient(to left, #49a3f1, #1A73E8)',
        backgroundAttachment: 'fixed',
        position: 'relative',
        zIndex: 2
      }}
      pt={6}
      pb={12}
      mt={-4}
      className="informationSection"
    >
      <Container>
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={12} md={4}>
            {/* <RotatingCard> */}
            <div className="curve" />
            <RotatingCardFront
              image={bgFront}
              icon={<VolunteerActivismOutlined />}
              title={
                <>
                  {t('recieveWithLove')}
                  <br />
                  {t('thatYouDeserve')}
                </>
              }
              description={t('cardDescription')}
            />
          </Grid>
          <Grid
            className="curve"
            item
            container
            xs={12}
            md={8}
            // sx={{ ml: 'auto' }}
          >
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={
                    <Diversity2Outlined
                      htmlColor={theme.palette.primary.contrastText}
                    />
                  }
                  title={t('joinOurCommunity')}
                  description={t('joinOurCommunityDescription')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={
                    <JoinInnerOutlined
                      htmlColor={theme.palette.primary.contrastText}
                    />
                  }
                  title={t('isItSuitable')}
                  description={t('isItSuitableDescription')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={
                    <MoneyOutlined
                      htmlColor={theme.palette.primary.contrastText}
                    />
                  }
                  title={t('funding')}
                  description={t('fundingDescription')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={
                    <DevicesOutlined
                      htmlColor={theme.palette.primary.contrastText}
                    />
                  }
                  title={t('fullyResponsive')}
                  description={t('fullyResponsiveDescription')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <CardMedia
        component="img"
        src={flower}
        alt="shapes"
        sx={{
          position: 'absolute',
          width: '120px',
          height: '120px',
          // mx: 'auto',
          zIndex: 1,
          bottom: -20,
          right: 0,
          filter: 'brightness(2) invert(0)'
        }}
      />
    </Box>
  );
}

export default Information;
