import { Grid, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PricningCard from './PricningCard';

const PricesCardsInOrgPage = ({ orgPrices, tags }) => {
  PricesCardsInOrgPage.propTypes = {
    orgPrices: PropTypes.object,
    tags: PropTypes.array
  };
  console.log(Object.keys(orgPrices));
  const { t } = useTranslation('translation', { keyPrefix: 'orgPage' });

  return (
    <Grid
      sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
      container
      item
      xs={10}
      py={3}
      spacing={2}
    >
      {tags && orgPrices && Object.keys(orgPrices).length > 0 ? (
        tags.map((tag) => (
          <Fragment key={`${tag}-fragment`}>
            {/* {orgPrices[tag] ? ( */}
            <Grid key={tag} item xs={12} sm={5.95}>
              <PricningCard
                key={`${tag}-${uniqueId('pricing-card')}`}
                tag={tag}
                tagPrices={orgPrices[tag]}
              />
            </Grid>
            {/* ) : null} */}
          </Fragment>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography>{t('orgDidntTellUs')}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PricesCardsInOrgPage;
