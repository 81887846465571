import { doc, increment, updateDoc } from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const incrementUserDiamond = async (
  diamondsToIncrement,
  user_doc_id,
  currentUserDiamonds
) => {
  const userRef = doc(db, `users/${user_doc_id}`);
  //   increment();
  await updateDoc(userRef, {
    diamonds: increment(Number(diamondsToIncrement))
  }).catch((err) => {
    console.log(err);
  });
  //   .then(() => {
  //   const temp = { ...currentUser };
  //   temp.diamonds =
  //     !temp.diamonds || temp.diamonds === 0
  //       ? diamondsToIncrement
  //       : temp.diamonds + diamondsToIncrement;
  // setCurrentUser(temp);
  //     return temp;
  //   });
};

export default incrementUserDiamond;
