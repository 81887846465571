import { Backdrop, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import PropTypes from 'prop-types';
import '../home.css';
import StyledComparisonTableBody from './components/StyledComparisonTableBody';
import StyledComparisonTableHead from './components/StyledComparisonTableHead';

const ComparisonTableEmptyState = ({ loading }) => {
  ComparisonTableEmptyState.propTypes = {
    loading: PropTypes.any
  };
  return (
    <TableContainer sx={{ overflow: 'auto' }} component={Paper}>
      <Table
        sx={{
          position: 'relative'
        }}
        aria-label="customized table"
      >
        {loading && (
          <Backdrop
            open={loading}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.2)'
            }}
          >
            <CircularProgress />
          </Backdrop>
        )}
        <StyledComparisonTableHead
          userscrolledhorizontally={Boolean(false)}
          list={{ names: ['ארגונים שתבחר יופיעו כאן'] }}
        />
        <StyledComparisonTableBody />
      </Table>
    </TableContainer>
  );
};

export default ComparisonTableEmptyState;
