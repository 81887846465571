import {
  Avatar,
  Card,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Slide,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import blog from 'src/assets/moreToRead/blog.webp';
import insurance from 'src/assets/moreToRead/insurance.webp';
import process from 'src/assets/moreToRead/process.webp';
import professional from 'src/assets/moreToRead/professional.webp';
import routesConst from 'src/routesConst';

const ReadMoreSuggestions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });
  const list = [
    {
      title: t('recievingCodeProcess'),
      id: uniqueId('read-more'),
      img: process,
      route: routesConst.mohCode
    },
    {
      title: t('insurance'),
      id: uniqueId('read-more'),
      img: insurance
    },
    {
      title: t('blog'),
      id: uniqueId('read-more'),
      img: blog,
      route: routesConst.blog
    },
    {
      title: t('professionals'),
      id: uniqueId('read-more'),
      img: professional,
      route: routesConst.medicalAssessment
    }
  ];
  return (
    <Slide in direction="right">
      <List
        subheader={
          <>
            <Typography variant="h5">{t('wantToReadMore')}</Typography>
            <Divider />
          </>
        }
        sx={{
          my: 4
        }}
      >
        <Grid sx={{ mt: 2 }} container spacing={2}>
          {list.map((item) => (
            <Grid key={item.id} item xs={12} sm={6}>
              <Card>
                <ListItemButton href={item.route} component="a" sx={{ gap: 4 }}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: 70, height: 70 }}
                      variant="rounded"
                      alt={item.id}
                      src={item.img}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    // primaryTypographyProps={{
                    //   component: 'a',
                    //   href: ''
                    // }}
                    primary={item.title}
                    secondary={t('readMoreAboutThisInformation')}
                  />
                </ListItemButton>
              </Card>
            </Grid>
          ))}
        </Grid>
      </List>
    </Slide>
  );
};

export default ReadMoreSuggestions;
