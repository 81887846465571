import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Typography,
  useMediaQuery
} from '@mui/material';
import useTheme from '@mui/styles/useTheme';
import PropType from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { useLocation, useNavigate } from 'react-router';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import routesConst from 'src/routesConst';
import geocodeAddress from 'src/utils/geocodeAddress';
import handleSearchByRadius from 'src/utils/handleSearchByRadius';
import saveSearchToLS from 'src/utils/saveSearchToLS';
import AdvancedSearch from '../AdvancedSearch/AdvancedSearch';
import RadiusSlider from '../RadiusSlider/RadiusSlider';
import SearchIconButtons from '../SearchIconButtons/SearchIconButtons';
import SearchTextField from '../SearchTextField/SearchTextField';

const SearchButtonGroup = ({
  handleAddressChange,
  // searchQuery,
  handleSliderValueChange
}) => {
  SearchButtonGroup.propTypes = {
    handleAddressChange: PropType.func,
    handleSliderValueChange: PropType.func
    // searchQuery: PropType.string
  };
  const { mainMap } = useMap({ id: 'mainMap' });
  const theme = useTheme();

  const {
    searchIndex,
    setSearchResults,
    sliderDistanceValue,
    setResultsAreLoading,
    searchQuery,
    setSearchQuery
  } = useSearchData();

  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('translation', { keyPrefix: 'mainPage' });
  const navigate = useNavigate();
  const location = useLocation();

  const [geocodingError, setGeocodingError] = useState();
  const [isAdvancedSettingsExpanded, setAdvancedSettingsExpanded] =
    useState(false);
  const [isOpenAdvancedSearchMobileDialog, setOpenAdvancedSearchMobileDialog] =
    useState(false);
  const [collapsed, setCollapsed] = useState({
    departments: false,
    kosher: false,
    available_funds: false
  });

  const handleToggleTag = (val, fieldName, multipleOption = true) => {
    const temp = { ...searchQuery };
    if (multipleOption) {
      if (temp.query && temp.query[fieldName]) {
        const index = temp.query[fieldName].indexOf(val);
        if (index === -1) {
          temp.query[fieldName].push(val);
        } else if (index === 0) {
          temp.query[fieldName].shift();
        } else if (index === temp.query[fieldName].length - 1) {
          temp.query[fieldName].pop();
        } else {
          temp.query[fieldName].splice(index, 1);
        }
        // after changes, clean the query if there is no values in it
        if (temp.query[fieldName].length < 1) {
          delete temp.query[fieldName];
        }
      } else {
        temp.query = { ...temp.query, [fieldName]: [val] };
      }
    } else {
      // not multiple
      temp.query = { ...temp.query, [fieldName]: val };
    }
    setSearchQuery(temp);
  };

  const handleClearAllQueryParams = () => {
    const temp = { ...searchQuery };
    delete temp.query;
    setSearchQuery(temp);
  };

  const handleToggleCollapsed = (collapsableToToggle) => {
    // console.log(collapsableToToggle);
    setCollapsed({
      ...collapsed,
      [collapsableToToggle]: !collapsed[collapsableToToggle]
    });
  };

  const handleDeleteQueryPart = (keyToDelete) => {
    const temp = { ...searchQuery };
    delete temp.query[keyToDelete];
    setSearchQuery(temp);
  };
  const handleClosingMobileAdvancedSearchDialog = () => {
    setOpenAdvancedSearchMobileDialog(false);
  };

  const performSearch = async () => {
    if (location.pathname === '/') {
      if (matchDownSm) {
        setOpenAdvancedSearchMobileDialog(false);
      } else {
        setAdvancedSettingsExpanded(false);
      }
      await geocodeAddress(searchQuery.text)
        .then((resCenter) => {
          // console.log(resCenter);
          saveSearchToLS(searchQuery.text, resCenter, sliderDistanceValue);
          navigate(`/${routesConst.results}`, {
            state: {
              initialSearch: true
            }
          });
        })
        .catch((err) => console.log(err));
    } else {
      handleSearchByRadius(
        searchQuery,
        mainMap,
        searchIndex,
        setSearchResults,
        sliderDistanceValue,
        navigate,
        setResultsAreLoading,
        setGeocodingError,
        matchDownSm
          ? setOpenAdvancedSearchMobileDialog
          : setAdvancedSettingsExpanded
      ).catch((err) => {
        console.log('err ==>', err, ':', Object.entries(err));
      });
    }
  };

  return (
    <Card elevation={2} sx={{ width: { xs: '80vw', md: '70vw' } }}>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        sx={{
          borderRadius: 4,
          width: '100%',
          flex: '1 1 auto',
          minHeight: '95px'
        }}
        orientation={matchDownSm ? 'vertical' : 'horizontal'}
        className="buttonGroupContainer"
      >
        <SearchTextField
          handleAddressChange={handleAddressChange}
          searchQuery={searchQuery}
          key="search-text-field-in-home"
        />
        <RadiusSlider
          performSearch={performSearch}
          handleSliderValueChange={handleSliderValueChange}
          key="radius-slider-for-search-in-home"
        />
        <SearchIconButtons
          setAdvancedSettingsExpanded={setAdvancedSettingsExpanded}
          isAdvancedSettingsExpanded={isAdvancedSettingsExpanded}
          setOpenAdvancedSearchMobileDialog={setOpenAdvancedSearchMobileDialog}
          searchQuery={searchQuery}
          setGeocodingError={setGeocodingError}
          performSearch={performSearch}
          key="search-icons-in-home"
        />
      </ButtonGroup>
      <Collapse
        in={isAdvancedSettingsExpanded && !matchDownSm}
        timeout={{
          enter: 750,
          exit: 750
        }}
        easing={{
          enter: 'linear',
          exit: 'ease-in'
        }}
      >
        {isAdvancedSettingsExpanded && (
          <AdvancedSearch
            key="advanced-search-expanded-desktop"
            handleClearAllQueryParams={handleClearAllQueryParams}
            handleDeleteQueryPart={handleDeleteQueryPart}
            handleToggleCollapsed={handleToggleCollapsed}
            handleToggleTag={handleToggleTag}
            collapsed={collapsed}
            performSearch={performSearch}
          />
        )}
      </Collapse>
      {isOpenAdvancedSearchMobileDialog && (
        <Dialog
          onClose={handleClosingMobileAdvancedSearchDialog}
          open={isOpenAdvancedSearchMobileDialog}
          fullWidth
          maxWidth="md"
          sx={{ overflow: 'auto' }}
          scroll="paper"
        >
          <DialogContent sx={{ p: 0 }}>
            <AdvancedSearch
              key="advanced-search-mobile-dialog"
              handleClearAllQueryParams={handleClearAllQueryParams}
              handleDeleteQueryPart={handleDeleteQueryPart}
              handleToggleCollapsed={handleToggleCollapsed}
              handleToggleTag={handleToggleTag}
              collapsed={collapsed}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => performSearch()} variant="contained">
              {t('search')}
            </Button>
            <Button
              onClick={handleClosingMobileAdvancedSearchDialog}
              variant="outlined"
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {geocodingError && (
        <Grow in={geocodingError}>
          <CardActions>
            <Typography color="error">{geocodingError}</Typography>
          </CardActions>
        </Grow>
      )}
    </Card>
  );
};

export default SearchButtonGroup;
