import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import theme from 'src/theme';

const OurAdvanteges = () => (
  <Grid
    item
    xs={12}
    sx={{
      minHeight: '95vh',
      backgroundImage: `linear-gradient(to top, ${alpha(
        theme.palette.common.white,
        0.2
      )}, ${alpha('#f9edf7', 0.5)})`,
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      backgroundAttachment: 'fixed',
      p: 3
    }}
  >
    <Box
      sx={{
        bgcolor: 'rgba(0,0,0,0)',
        height: '100% !important',
        m: 'auto',
        width: '100%',
        maxWidth: '1100px'
      }}
    >
      <CardContent sx={{ width: '100%', height: '80%' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Card
            sx={{
              //   background: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
              borderRadius: 4,
              maxWidth: 'fit-content',
              height: 60,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              //   border: '2px solid #244c8b'
              border: '2px solid blue',
              position: 'relative'
              // px: 2
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: theme.palette.primary.main,
                textAlign: 'center',
                fontSize: '2.5rem',
                fontWeight: 800,
                position: 'relative',
                zIndex: 2,
                background: 'rgba(255,255,255,0.8)',
                borderRadius: 'inherit',
                px: 2,
                py: 1,
                width: '100%'
              }}
            >
              היתרונות שלנו במגדלור
            </Typography>
            <img
              alt="icon"
              //   src=""
              src="https://img.freepik.com/free-photo/anime-style-clouds_23-2151071681.jpg?t=st=1702753224~exp=1702756824~hmac=07574519739bb1a9605a294b2ed742b4dba71cd76c86fa4350d34f400afb1721&w=1060"
              style={{
                width: '100%',
                height: 'calc(100% -24px)',
                objectFit: 'cover',
                objectPosition: 'top',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                bottom: 0
              }}
            />
          </Card>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            height: '100%'
          }}
        >
          {[
            {
              title: 'מערכת כוללנית',
              id: 'transparecy',
              icon: '',
              description:
                'שמפגישה את אנשי המקצוע שנותנים את השירותים ולא רק האנשים שמקבלים את השירותים אשר כולם מדרגים ומדורגים'
            },
            {
              title: 'מערכת מהירה ויעילה',
              id: 'proffesional',
              icon: '',
              description: 'חוסכת זמן לכל החפץ בשירותיה'
            },
            {
              title: 'ראיה רוחבית ועמוקה',
              id: 'quality',
              icon: '',
              description:
                'מכירים את כולם ואפילו את הפרטים הכי קטנים שיש בכל ארגון'
            },
            {
              title: 'ניסיון ונוכחות בשטח',
              id: 'caring',
              icon: '',
              description: 'אין חכם כמו בעל ניסיון'
            }
          ].map((vision, index) => (
            <Grid
              item
              key={`${vision.id}-${uniqueId('adv')}`}
              xs={12}
              sm={6}
              sx={{
                maxWidth: '500px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                minHeight: 140,
                maxHeight: 200
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  // height: '100%',
                  bgcolor: 'rgba(0,0,0,0)',
                  color: theme.palette.common.black,
                  maxWidth: '500px',
                  p: 2
                }}
              >
                {/* <CardContent> */}
                <Stack
                  sx={{ alignItems: 'flex-start' }}
                  direction="row"
                  gap={2}
                >
                  <Card
                    elevation={2}
                    sx={{
                      height: 50,
                      width: 50,
                      p: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 0,
                      backgroundColor: `${alpha(
                        theme.palette.primary.contrastText,
                        0.7
                      )}`
                    }}
                  >
                    {/* <CardContent> */}
                    <Typography
                      sx={{
                        fontWeight: 800,
                        textAlign: 'center',
                        width: '100%',
                        height: '100%',
                        fontSize: '1.5rem',
                        lineHeight: 1,
                        color: theme.palette.primary.main
                      }}
                    >
                      {index + 1}
                    </Typography>
                    {/* </CardContent> */}
                  </Card>
                  <Stack>
                    <Typography
                      sx={{
                        mt: '-12px'
                      }}
                      variant="h3"
                    >
                      {vision.title}
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                      {vision.description}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Box>
  </Grid>
);

export default OurAdvanteges;
