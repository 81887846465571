import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';
import QuestionSlide from '../../sharedPartials/QuestionSlide';
import getQuestionData from '../getQuestionData';
import './fireAndElectericitySafety.css';

const FireAndElectericitySafety = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
}) => {
  FireAndElectericitySafety.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    globalCoursewares: PropTypes.array,
    handleSetViewToSignCourseware: PropTypes.func
  };

  return (
    <div
      className="theme-font-palatino theme-color-sand-blue"
      style={{ width: '100%', height: '100%', fontSize: '130%' }}
    >
      <div className="reveal fireAndElectericitySafety">
        <div className="slides">
          <section data-auto-animate="" data-auto-animate-duration="0.9">
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-fe7344"
              style={{
                width: '134.29px',
                height: '6.7849px',
                left: '130.82px',
                top: '432.113px'
              }}
              data-auto-animate-easing="ease"
              data-auto-animate-duration="0.4"
              data-locked="true"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, opacity: 0 }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(249, 255, 46)"
                  data-shape-stretch="true"
                  style={{ zIndex: 12 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 134 7"
                  >
                    <rect
                      width="134.29"
                      height="6.7849"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgb(249, 255, 46)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              data-name="text-27f395x"
              style={{ height: 'auto', width: 822, left: 167, top: '266.5px' }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  letterSpacing: '-0.04em',
                  fontSize: '120%'
                }}
                data-has-letter-spacing=""
              >
                <h1 style={{ color: '#111111', textAlign: 'center' }}>
                  <span style={{ fontSize: '1.2em' }}>בטיחות אש וחשמל</span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 638, height: 638, left: 578, top: '-236.387px' }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  transform: 'rotate(-15deg)',
                  opacity: '0.51'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 638 638"
                  >
                    <polygon
                      points="638,319 544.6,544.6 319,638 93.4,544.6 0,319 93.4,93.4 319,0 544.6,93.4"
                      className="shape-element"
                      fill="rgb(255, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 794,
                height: 794,
                left: '-359.18px',
                top: '165.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  transform: 'rotate(-30deg)',
                  opacity: '0.61'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(255, 255, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 794 794"
                  >
                    <polygon
                      points="794,397 397,794 0,397 397,0"
                      className="shape-element"
                      fill="rgb(255, 255, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 122, top: 109, height: 'auto' }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <strong>כיצד נלמד?</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 806,
                left: 122,
                top: '291.613px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    ההתקדמות היא באמצעות החצים &lt; או&nbsp; &gt; במצגת.
                  </strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 122, top: 425, height: 'auto' }}
              data-name="text-4bdc77"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    כשמגיעים לשקופית שיש בה שאלה, אי אפשר להתקדם עד שעונים נכון
                    על השאלה.
                  </strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 794,
                height: 794,
                left: '-359.18px',
                top: '165.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(255, 255, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 794 794"
                  >
                    <polygon
                      points="794,397 397,794 0,397 397,0"
                      className="shape-element"
                      fill="rgb(255, 255, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 638, height: 638, left: 578, top: '-236.387px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 638 638"
                  >
                    <polygon
                      points="638,319 544.6,544.6 319,638 93.4,544.6 0,319 93.4,93.4 319,0 544.6,93.4"
                      className="shape-element"
                      fill="rgb(255, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '90.1415px',
                top: '40.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '140%',
                  textAlign: 'center',
                  lineHeight: '1.04',
                  width: '100%',
                  letterSpacing: '-0.1em'
                  // color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
                // data-fragment-index={0}
              >
                <h1 style={{ textAlign: 'center' }}>
                  בטיחות אש עם מוצרי חשמל ביתיים
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: 175,
                top: '382.613px',
                height: 'auto',
                textAlign: 'center'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content fragment"
                style={{
                  zIndex: 13,
                  textAlign: 'center',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  width: '100%'
                }}
                data-fragment-index={1}
              >
                <p style={{ textAlign: 'justify' }}>
                  מרבית השריפות בבתים נובעות מתקלה כלשהיא בחיווט החשמל או משימוש
                  לא זהיר במכשירי חשמל.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 2, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(255, 255, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 2 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(255, 255, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 940, top: '-236.387px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(255, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(255, 255, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(255, 255, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 940, top: '-236.387px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(255, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: 175,
                top: '156.113px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  בעיות חשמל למיניהן ניתן לאתר לפני שתפרוץ שריפה ומישהו יפגע.
                  שימו לב לכל פעילות חשמלית לא רגילה, כמו:
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-80f23a"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '514.5px 95.113px 0px', zIndex: 13 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 609, height: 85, left: 210, top: '52.613px' }}
                  data-name="shape-0e2c71"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 16,
                      filter:
                        'drop-shadow(rgb(255, 217, 102) 8px -1px 16px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="rect"
                      data-shape-fill-color="rgb(241, 194, 50)"
                      data-shape-stretch="true"
                      style={{ zIndex: 16 }}
                      data-shape-corner-radius="14px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 609 85"
                      >
                        <rect
                          width={609}
                          height={85}
                          rx={14}
                          ry={14}
                          className="shape-element"
                          fill="rgb(241, 194, 50)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: 600,
                    left: 219,
                    top: '75.613px'
                  }}
                  data-name="text-46595b"
                >
                  <div className="sl-block-content" style={{ zIndex: 17 }}>
                    <h3>סימני האזהרה לבעיות חשמל</h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: 175,
                top: '312.113px',
                height: 'auto'
              }}
              data-name="text-9d25b9"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 18,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <ul
                  style={{
                    width: '80%'
                  }}
                >
                  <li
                    className="fragment"
                    data-fragment-index={0}
                    style={{ textAlign: 'right' }}
                  >
                    בזמן מגע במכשיר חשמלי מרגישים דקירה/עקצוץ קל.
                  </li>
                  <li
                    className="fragment"
                    data-fragment-index={1}
                    style={{ textAlign: 'right' }}
                  >
                    שינוי בצבע של שקע חשמלי.
                  </li>
                  <li
                    className="fragment"
                    data-fragment-index={2}
                    style={{ textAlign: 'right' }}
                  >
                    ניצוצות או ריח לא נעים היוצאים ממכשיר חשמלי, תקע או חיווט
                    חשמלי.
                  </li>
                  <li
                    className="fragment"
                    data-fragment-index={3}
                    style={{ textAlign: 'right' }}
                  >
                    תאורה מהבהבת לסירוגין.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                top: 173,
                height: 'auto',
                justifyContent: 'center'
              }}
              data-name="text-9d25b9"
            >
              <div
                className="sl-block-content blockToLiftInMobile"
                style={{
                  zIndex: 11,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <ul
                  style={{
                    width: '80%'
                  }}
                >
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={0}
                    style={{ textAlign: 'justify' }}
                  >
                    בדקו ויזואלית באופן שיגרתי את מכשירי החשמל וכבלי החשמל.
                  </li>
                  <li
                    className="fragment fade-in-then-semi-out"
                    style={{ textAlign: 'justify' }}
                    data-fragment-index={1}
                  >
                    בצעו בדיקה תקופתית למערכת החשמל על ידי חשמלאי מוסמך או איש
                    אחזקה ותדווחו לאיש אחזקה בכל חריג.
                  </li>
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={2}
                    style={{ textAlign: 'justify' }}
                  >
                    תקלות במערכת ובמכשירי חשמל יתוקנו רק על ידי חשמלאי מוסמך/איש
                    אחזקה.
                  </li>
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={3}
                    style={{ textAlign: 'justify' }}
                  >
                    אין להפעיל מכשירי חשמל רבים על שקע חשמלי אחד, למניעת עומס
                    יתר וכשל במערכת החשמל העלול לגרום להתחשמלות או דליקה.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 587,
                height: '253.387px',
                left: '-187.18px',
                top: '577.113px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgba(255, 255, 0,0.4)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 587 253"
                  >
                    <polygon
                      points="587,126.7 293.5,253.4 0,126.7 293.5,0"
                      className="shape-element"
                      fill="rgb(255, 255, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '100%',
                // left: 229,
                top: '58.113px'
              }}
              data-name="text-7cffa0"
            >
              <div
                className="sl-block-content titleToLiftInMobile"
                style={{
                  zIndex: 13,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  בטיחות אש עם מכשירי חשמל
                </h2>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 441,
                height: '536.113px',
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(255, 255, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 441 536"
                  >
                    <polygon
                      points="441,268.1 220.5,536.1 0,268.1 220.5,0"
                      className="shape-element"
                      fill="rgb(255, 255, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 1107, top: '288.613px' }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  opacity: '0.73',
                  filter: 'blur(200px)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(255, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 806,
                left: 101,
                top: '230.113px',
                height: 'auto'
              }}
              data-name="text-9d25b9"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)',
                  backgroundColor: 'rgba(0, 0, 0, 0)'
                }}
              >
                <ul>
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={1}
                    style={{ textAlign: 'right' }}
                  >
                    אין להשאיר מפסקים/שקעים שבורים.
                  </li>
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={2}
                    style={{ textAlign: 'right' }}
                  >
                    שמרו על מכשירי חשמל הרחק מרצפה רטובה ושימו לב למכשירי חשמל
                    באמבטיה ובמטבח.
                  </li>
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={3}
                    style={{ textAlign: 'right' }}
                  >
                    אל תאפשרו לדיירים להתעסק במכשירי חשמל כדוגמת תנורי חימום,
                    מגהצים ומייבשי שיער ללא השגחה.
                  </li>
                  <li
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={4}
                    style={{ textAlign: 'right' }}
                  >
                    בשימוש עם תנורים ומפזרי חום, הרחיקו כל חפץ העלול להתלקח
                    סביבם.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: '100%', top: '41.113px', height: 'auto' }}
              data-name="text-0196fa"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)',
                  width: '100%'
                }}
              >
                <h2 style={{ textAlign: 'center' }}>
                  בטיחות אש עם מכשירי חשמל
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-04d6d6"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{
                  transformOrigin: '626.41px 390.613px 0px',
                  zIndex: 14
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '100%',
                    // left: '203.82px',
                    top: '279.613px'
                  }}
                  data-name="text-a93e78"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 15,
                      color: 'rgb(220, 54, 54)',
                      width: '100%'
                    }}
                  >
                    <h2>
                      <span style={{ fontSize: '2.0em', textAlign: 'center' }}>
                        אין להשאיר מכשירי חשמל דולקים ללא השגחה ובפרט בחדרים
                        סגורים.
                      </span>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 164,
                    height: 168,
                    left: 885,
                    top: '297.113px'
                  }}
                  data-name="shape-a9fb40"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-x-circle"
                    data-shape-fill-color="rgb(204, 65, 37)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgba(204, 65, 37, 0.4)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 164,
                    height: 168,
                    right: 885,
                    bottom: '-80vh'
                  }}
                  data-name="shape-a9fb40"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-x-circle"
                    data-shape-fill-color="rgb(204, 65, 37)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgba(204, 65, 37, 0.4)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 441,
                height: '536.113px',
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(147, 196, 125)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 441 536"
                  >
                    <polygon
                      points="441,268.1 220.5,536.1 0,268.1 220.5,0"
                      className="shape-element"
                      fill="rgb(147, 196, 125)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-9673a5"
              style={{ height: 'fit-content' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  // transformOrigin: '777.5px 53.25px 0px',
                  zIndex: 11,
                  marginTop: '18px'
                }}
              >
                <QuestionSlide
                  key="first-fireAndElectericitySafety-question"
                  handleCorrectAnswer={handleCorrectAnswer}
                  handleWrongAnswer={handleWrongAnswer}
                  {...getQuestionData(globalCoursewares, selectedCourseware, 6)}
                  selectedCourseware={selectedCourseware}
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '87.1415px',
                top: '113.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 0)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  בטיחות אש בזמן בישול במטבח
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(164, 194, 244)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(164, 194, 244)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(213, 166, 189)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(213, 166, 189)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                top: '39.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(255, 0, 0)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
                // data-fragment-index={0}
              >
                <h1 style={{ textAlign: 'center' }}>גז ביתי</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(164, 194, 244)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(164, 194, 244)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(213, 166, 189)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(213, 166, 189)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '100%',
                top: '235.5px'
              }}
              data-name="text-a17556"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, width: '100%' }}
              >
                <ul style={{ width: '80%' }}>
                  <li style={{ textAlign: 'justify' }}>
                    דליפת גז עלולה להיות מסוכנת ביותר ולגרום לנפגעים.
                    <br />
                    &nbsp;
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    יש להקפיד על כיבוי ברז הגז בסיום הבישול.
                    <br />
                    &nbsp;
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    התקנה וטיפול במערכת גז תיעשה רק על ידי טכנאי מורשה.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                top: '7.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content titleToLiftInMobile"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  // letterSpacing: '0.1em',
                  width: '100%',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.7em' }}>
                    הנחיות בטיחות עם גז ביתי
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 454,
                height: 468,
                left: '-160.18px',
                top: '-204.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(164, 194, 244)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 454 468"
                  >
                    <polygon
                      points="454,234 227,468 0,234 227,0"
                      className="shape-element"
                      fill="rgb(164, 194, 244)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 954, top: '424.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(213, 166, 189)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(213, 166, 189)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: '100%',
                width: '100%',
                // left: '104.859px',
                top: '150.5px'
              }}
              data-name="text-a17556"
            >
              <div
                className="sl-block-content blockToLiftInMobile"
                style={{
                  zIndex: 14,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <ul
                  style={{
                    width: '80%'
                  }}
                >
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={0}
                  >
                    ריח של גז בסביבתכם מהווה סימן שישנה דליפת גז במקום.
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={1}
                  >
                    במקרה של דליפת גז, יש לדווח על כך לשירותי הכבאות וההצלה.
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={2}
                  >
                    יש לסגור את אספקת הגז למקור הדליפה.
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={3}
                  >
                    לפתוח חלונות לאוורור המקום והוצאת הגז כלפי חוץ.
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={4}
                  >
                    במידה ומקור הדליפה אינו ידוע או שלא ניתן להפסיקו, יש להתרחק
                    מהמקום.
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={5}
                  >
                    להרחיק כל גורם שיכול לגרום לניצוץ.
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={6}
                  >
                    אין לכבות או להדליק מקורות חשמל ותאורה באזור דליפת גז.
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={7}
                  >
                    יש להרחיק אנשים ועובדים מאזור הדליפה עד להגעת כוחות החרום.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '164.642px',
                top: '38.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(255, 0, 0)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>בישול עם סיר/תנור</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-291.858px',
                top: '-84.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(164, 194, 244)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(164, 194, 244)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(213, 166, 189)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(213, 166, 189)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: '100%', top: 223 }}
              data-name="text-a17556"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <ul
                  style={{
                    width: '80%'
                  }}
                >
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={2}
                  >
                    בזמן הבישול, ודאו שלהבת הכיריים לא חשופה לרוח
                    <br />
                    &nbsp;
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={3}
                  >
                    חל איסור להניח מגבת על גבי הסיר בזמן הבישול או על עגלת האוכל
                    כשהיא חמה.
                    <br />
                    &nbsp;
                  </li>
                  <li
                    style={{ textAlign: 'justify' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={4}
                  >
                    יש להקפיד על ניקיון הכיריים וציוד הבישול מלכלוך ושאריות שמן
                    לאחר הבישול. השמן עלול להידלק עקב חשיפה לחום בזמן הבישול
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                top: 246,
                height: 'auto'
              }}
              data-name="text-6f300b"
            >
              <div
                className="sl-block-content fragment current-visible"
                style={{
                  zIndex: 15,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  // letterSpacing: '-0.1em',
                  width: '100%',
                  color: 'rgb(255, 0, 0)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
                data-fragment-index={0}
              >
                <h3 style={{ textAlign: 'center' }}>
                  אין לכבות שריפת נוזלים (בסיר/מחבת) עם
                  <br />
                  מים!
                </h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                top: 246,
                height: 'auto'
              }}
              data-name="text-8fb54c"
            >
              <div
                className="sl-block-content fragment current-visible"
                style={{
                  zIndex: 16,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  // letterSpacing: '-0.1em',
                  color: 'rgb(255, 0, 0)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
                data-fragment-index={1}
              >
                <h3 style={{ textAlign: 'center' }}>
                  בשריפה בתנור, כבו את מקור החום והשאירו
                  <br />
                  את דלת התנור סגורה
                </h3>
              </div>
            </div>
          </section>
          <section>
            <QuestionSlide
              key="first-fireAndElectericitySafety-question"
              handleCorrectAnswer={handleCorrectAnswer}
              handleWrongAnswer={handleWrongAnswer}
              {...getQuestionData(globalCoursewares, selectedCourseware, 11)}
              selectedCourseware={selectedCourseware}
            />
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '87.1415px',
                top: '113.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 0)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  בטיחות אש בשימוש מייבש כביסה
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(221, 126, 107)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(221, 126, 107)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(230, 145, 56)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(230, 145, 56)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '90.1415px',
                top: '60.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content titleToLiftInMobile"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'center',
                  lineHeight: '1.04',
                  // letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 0)',
                  width: '100%'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h2 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.7em' }}>
                    עצות למניעת שריפה במייבש כביסה:
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(221, 126, 107)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(221, 126, 107)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(230, 145, 56)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(230, 145, 56)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                // left: 121,
                top: 214,
                width: '100%'
              }}
              data-name="text-a21aa8"
            >
              <div
                className="sl-block-content blockToLiftInMobile"
                style={{
                  zIndex: 13,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <ul style={{ width: '80%' }}>
                  <li>
                    שמירה על ניקיון מייבש הכביסה תפחית את סיכוני האש באופן
                    משמעותי. צוות האחזקה ידאג לפירוק המייבש וניקויו באופן יסודי.
                  </li>
                  <li>
                    שמירה על האזור סביב מייבש הכביסה פנוי מחפצים אשר יכולים
                    להתלקח כמו חומרי ניקוי ובגדים.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '68.1415px',
                top: '77.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'center',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(204, 0, 0)',
                  width: '100%'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h2 style={{ textAlign: 'center' }}>
                  מה לעשות במקרה של שריפה במייבש הכביסה?
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)', opacity: '0.66' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(221, 126, 107)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(221, 126, 107)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.35', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(230, 145, 56)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(230, 145, 56)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                top: 325,
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
              data-name="text-a21aa8"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <p>
                  <span style={{ fontSize: '1.2em' }}>
                    סגרו את דלת חדר הכביסה כדי להאט את התפשטות השריפה.
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '1.2em' }}>
                    הוציאו את כולם מהחדר והזעיקו את לוחמי האש בטלפון 102.
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '90.1415px',
                top: '30.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.7em' }}>
                    בטיחות אש בזמן בישול במטבח
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(221, 126, 107)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(221, 126, 107)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(230, 145, 56)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(230, 145, 56)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                top: 214
              }}
              data-name="text-a21aa8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, width: '100%' }}
              >
                <ul
                  style={{
                    width: '80%'
                  }}
                >
                  <li style={{ textAlign: 'justify' }}>
                    <span style={{ fontSize: '1.2em' }}>
                      בזמן הבישול, ודאו שלהבת הכיריים לא חשופה לרוח.
                    </span>
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    <span style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                      חל איסור להניח מגבת על גבי הסיר בזמן הבישול או על עגלת
                      האוכל כשהיא חמה.
                    </span>
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    <span style={{ fontSize: '1.2em' }}>
                      יש להקפיד על ניקיון הכיריים וציוד הבישול מלכלוך ושאריות
                      שמן לאחר הבישול. השמן עלול להידלק עקב חשיפה לחום בזמן
                      הבישול.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '223.14px',
                top: '8.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  width: '100%',
                  textAlign: 'center',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(153, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.9em' }}>
                    בטיחות אש בסביבת דיירים
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(182, 215, 168)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(182, 215, 168)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(201, 218, 248)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(201, 218, 248)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-5f66f0"
              style={{
                height: '100%',
                width: '80%',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{
                  transformOrigin: '581.5px 361px 0px',
                  zIndex: 13,
                  width: '100%'
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '100%',
                    // left: 177,
                    top: '302.5px'
                  }}
                  data-name="text-bdc6e2"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 14,
                      transform: 'rotate(0deg)',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        zIndex: 14,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <p style={{ textAlign: 'right', width: '80%' }}>
                        חל איסור להחזיק מצתים או כל חומר דליק אחר (חומרי חיטוי)
                        בעמדת המטופל, יש להרחיק את כל אלה מהישג ידם של מטופלים
                        לוקים מירידה קוגניטיבית במידה ואין השגחה.
                      </p>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{ width: 19, height: 39, left: 900, top: 8 }}
                      data-name="shape-c7f7a9"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-ribbon"
                        data-shape-fill-color="rgb(224, 102, 102)"
                        data-shape-stretch="true"
                        style={{ zIndex: 15 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 16 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(224, 102, 102)"
                            data-viewbox="0 0 16 32"
                          >
                            <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-5931ad"
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{ transformOrigin: '581.5px 383.5px 0px', zIndex: 16 }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    top: 325
                  }}
                  data-origin-id="b90e311c857fa72d2b50d9fe3fea993d"
                  data-name="text-9a3be7"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 17,
                      transform: 'rotate(0deg)',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{ zIndex: 17, width: '80%' }}
                    >
                      <p style={{ textAlign: 'right' }}>
                        נרות הם אחד הגורמים השכיחים לדליקות – יש להדליקם רק בתוך
                        פמוט המיועד לכך על גבי משטח מוצק ולא דליק, בהשגחה ורחוק
                        מחלון פתוח או וילון.
                      </p>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{ width: 19, height: 39, left: 900, top: 8 }}
                      data-name="shape-c7f7a9"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-ribbon"
                        data-shape-fill-color="rgb(224, 102, 102)"
                        data-shape-stretch="true"
                        style={{ zIndex: 15 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 16 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(224, 102, 102)"
                            data-viewbox="0 0 16 32"
                          >
                            <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-0af9af"
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{ transformOrigin: '581.5px 371.5px 0px', zIndex: 19 }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '100%',
                    // left: 177,
                    top: '347.25px'
                  }}
                  data-origin-id="4e2e1f7d418f518e90037a13b028a32c"
                  data-name="text-f37b94"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 20,
                      transform: 'rotate(0deg)',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        zIndex: 20,
                        width: '80%'
                      }}
                    >
                      <p style={{ textAlign: 'right', width: '100%' }}>
                        אין להשאיר נרות דולקים או כל להבה גלויה ללא השגחה.
                      </p>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 19,
                        height: 39,
                        left: 890,
                        top: 8
                        // top: '356.75px'
                      }}
                      data-origin-id="10baed7f07b5f867563b25516808ddb5"
                      data-name="shape-ef7890"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="symbol-ribbon"
                        data-shape-fill-color="rgb(224, 102, 102)"
                        data-shape-stretch="true"
                        style={{ zIndex: 21 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 16 32"
                        >
                          <g
                            className="shape-element"
                            fill="rgb(224, 102, 102)"
                            data-viewbox="0 0 16 32"
                          >
                            <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-7a6b65"
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{ transformOrigin: '581.5px 408.75px 0px', zIndex: 25 }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '100%',
                    // left: 177,
                    top: '369.75px',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  data-origin-id="628ed510c29f61ab982c9f22bee10880"
                  data-name="text-9bbdf9"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 26,
                      transform: 'rotate(0deg)',
                      width: '80%'
                    }}
                  >
                    <div className="sl-block-content" style={{ zIndex: 26 }}>
                      <p style={{ textAlign: 'right' }}>
                        מכשירי חשמל ומטענים הם גורמים שכיחים לפרוץ דליקות, מומלץ
                        להטעין טלפונים בהשגחת צוות ולא בחדר המטופל.
                      </p>
                    </div>
                  </div>
                  <div
                    className="sl-block"
                    data-block-type="shape"
                    style={{
                      width: 19,
                      height: 39,
                      left: 890,
                      top: 8
                    }}
                    data-origin-id="434105392bbb8250909bae44f68a6a8d"
                    data-name="shape-ae7157"
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="symbol-ribbon"
                      data-shape-fill-color="rgb(224, 102, 102)"
                      data-shape-stretch="true"
                      style={{ zIndex: 27 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 16 32"
                      >
                        <g
                          className="shape-element"
                          fill="rgb(224, 102, 102)"
                          data-viewbox="0 0 16 32"
                        >
                          <path d="M8 20c-1.41 0-2.742-0.289-4-0.736v12.736l4-4 4 4v-12.736c-1.258 0.447-2.59 0.736-4 0.736zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8c0 4.418-3.582 8-8 8-4.418 0-8-3.582-8-8z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '221.14px',
                top: '42.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(241, 194, 50)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.9em', letterSpacing: '0.1rem' }}>
                    בטיחות אש בזמן עישון
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(170, 170, 170)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(170, 170, 170)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 942, top: '194.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(234, 153, 153)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(234, 153, 153)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '100%',
                // left: 292,
                top: '356.5px'
              }}
              data-name="text-2a9ded"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <p>
                  בשריפות הנגרמות מחומרי עישון, החומרים שלרוב נדלקים הם מזרונים,
                  מצעים וריפוד הרהיטים.
                </p>
              </div>
            </div>
          </section>

          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '351.14px',
                top: '32.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  // letterSpacing: '-0.1em',
                  color: 'rgb(241, 194, 50)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h3 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.9em' }}>
                    בטיחות אש בזמן עישון
                  </span>
                </h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-320.18px',
                top: '-255px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(170, 170, 170)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(170, 170, 170)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 436, height: 531, left: 812, top: 246 }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(234, 153, 153)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 436 531"
                  >
                    <polygon
                      points="436,265.5 372.1,453.2 218,531 63.9,453.2 0,265.5 63.9,77.8 218,0 372.1,77.8"
                      className="shape-element"
                      fill="rgb(234, 153, 153)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-501737"
              style={{ height: 'auto', marginRight: '20px' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{ transformOrigin: '512.686px 336px 0px', zIndex: 13 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-name="group-82b5ea"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '410.802px 230.5px 0px',
                      zIndex: 25
                    }}
                  >
                    <div
                      className="sl-block sl-block-group"
                      data-block-type="group"
                      data-name="group-c78573"
                      style={{ height: 'auto' }}
                    >
                      <div
                        className="sl-block-content sl-block-group-content"
                        style={{
                          transformOrigin: '410.802px 230.5px 0px',
                          zIndex: 26
                        }}
                      >
                        <div
                          className="sl-block"
                          data-block-type="shape"
                          style={{
                            width: '166.396px',
                            height: 203,
                            left: '327.604px',
                            top: 129
                          }}
                          data-origin-id="5e720f8cf9df27d6e243dc9a5d8fafc7"
                          data-name="shape-beaff2"
                        >
                          <div
                            className="sl-block-style"
                            style={{ zIndex: 27, opacity: '0.62' }}
                          >
                            <div
                              className="sl-block-content"
                              data-shape-type="symbol-denied"
                              data-shape-fill-color="rgb(234, 153, 153)"
                              data-shape-stretch="false"
                              style={{ zIndex: 27 }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid"
                                viewBox="0 0 32 32"
                              >
                                <g
                                  className="shape-element"
                                  fill="rgb(234, 153, 153)"
                                  data-viewbox="0 0 32 32"
                                >
                                  <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM16 4c2.59 0 4.973 0.844 6.934 2.242l-16.696 16.688c-1.398-1.961-2.238-4.344-2.238-6.93 0-6.617 5.383-12 12-12zM16 28c-2.59 0-4.973-0.844-6.934-2.242l16.696-16.688c1.398 1.961 2.238 4.344 2.238 6.93 0 6.617-5.383 12-12 12z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sl-block"
                          data-block-type="text"
                          style={{
                            height: 'auto',
                            width: 'fit-content',
                            left: '330.649px',
                            top: '181.5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          data-origin-id="59d048153f92613b2dee64aa38a484b6"
                          data-name="text-7c2f7d"
                        >
                          <div
                            className="sl-block-content"
                            style={{ zIndex: 28 }}
                          >
                            <p>
                              כיבוי סיגריה
                              <br />
                              לא במאפרה
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-name="group-141028"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '616.249px 230.5px 0px',
                      zIndex: 21
                    }}
                  >
                    <div
                      className="sl-block sl-block-group"
                      data-block-type="group"
                      data-name="group-558381"
                      style={{ height: 'auto' }}
                    >
                      <div
                        className="sl-block-content sl-block-group-content"
                        style={{
                          transformOrigin: '616.249px 230.5px 0px',
                          zIndex: 22
                        }}
                      >
                        <div
                          className="sl-block"
                          data-block-type="shape"
                          style={{
                            width: '166.396px',
                            height: 203,
                            left: '533.051px',
                            top: 129
                          }}
                          data-origin-id="e8593d97166c447e9daeaab41819e76d"
                          data-name="shape-f33ad7"
                        >
                          <div
                            className="sl-block-style"
                            style={{ zIndex: 23, opacity: '0.62' }}
                          >
                            <div
                              className="sl-block-content"
                              data-shape-type="symbol-denied"
                              data-shape-fill-color="rgb(234, 153, 153)"
                              data-shape-stretch="false"
                              style={{ zIndex: 23 }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid"
                                viewBox="0 0 32 32"
                              >
                                <g
                                  className="shape-element"
                                  fill="rgb(234, 153, 153)"
                                  data-viewbox="0 0 32 32"
                                >
                                  <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM16 4c2.59 0 4.973 0.844 6.934 2.242l-16.696 16.688c-1.398-1.961-2.238-4.344-2.238-6.93 0-6.617 5.383-12 12-12zM16 28c-2.59 0-4.973-0.844-6.934-2.242l16.696-16.688c1.398 1.961 2.238 4.344 2.238 6.93 0 6.617-5.383 12-12 12z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sl-block"
                          data-block-type="text"
                          style={{
                            height: 'auto',
                            width: 'fit-content',
                            left: '529.544px',
                            top: 178,
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                          data-origin-id="1a6ffd5a0c437ac0c741dff10a62b939"
                          data-name="text-f9bc41"
                        >
                          <div
                            className="sl-block-content"
                            style={{ zIndex: 24 }}
                          >
                            <p>
                              החזקת מצית
                              <br />
                              בעמדת מטופל
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-name="group-b764c3"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '409.122px 441.5px 0px',
                      zIndex: 18
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '166.396px',
                        height: 203,
                        left: '325.924px',
                        top: 340
                      }}
                      data-origin-id="8f724435bea80c8c0ce41b1e14366ca4"
                      data-name="shape-a5ee3c"
                    >
                      <div
                        className="sl-block-style"
                        style={{ zIndex: 19, opacity: '0.62' }}
                      >
                        <div
                          className="sl-block-content"
                          data-shape-type="symbol-denied"
                          data-shape-fill-color="rgb(234, 153, 153)"
                          data-shape-stretch="false"
                          style={{ zIndex: 19 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid"
                            viewBox="0 0 32 32"
                          >
                            <g
                              className="shape-element"
                              fill="rgb(234, 153, 153)"
                              data-viewbox="0 0 32 32"
                            >
                              <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM16 4c2.59 0 4.973 0.844 6.934 2.242l-16.696 16.688c-1.398-1.961-2.238-4.344-2.238-6.93 0-6.617 5.383-12 12-12zM16 28c-2.59 0-4.973-0.844-6.934-2.242l16.696-16.688c1.398 1.961 2.238 4.344 2.238 6.93 0 6.617-5.383 12-12 12z" />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        height: 'auto',
                        width: 'fit-content',
                        left: '331.969px',
                        top: 383,
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      data-origin-id="073ab9d0295f33dd63c2dd8f9181ce7a"
                      data-name="text-436683"
                    >
                      <div className="sl-block-content" style={{ zIndex: 20 }}>
                        <p style={{ textAlign: 'center' }}>
                          החזקת חומר
                          <br />
                          דליק בעמדת
                          <br />
                          מטופל
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-2c3bfa"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '615.197px 441.5px 0px',
                      zIndex: 14
                    }}
                  >
                    <div
                      className="sl-block sl-block-group"
                      data-block-type="group"
                      data-name="group-f555b8"
                      style={{ height: 'auto' }}
                    >
                      <div
                        className="sl-block-content sl-block-group-content"
                        style={{
                          transformOrigin: '615.197px 441.5px 0px',
                          zIndex: 15
                        }}
                      >
                        <div
                          className="sl-block"
                          data-block-type="shape"
                          style={{
                            width: '166.396px',
                            height: 203,
                            left: '531.999px',
                            top: 340
                          }}
                          data-name="shape-e389c3"
                        >
                          <div
                            className="sl-block-style"
                            style={{ zIndex: 16, opacity: '0.62' }}
                          >
                            <div
                              className="sl-block-content"
                              data-shape-type="symbol-denied"
                              data-shape-fill-color="rgb(234, 153, 153)"
                              data-shape-stretch="false"
                              style={{ zIndex: 16 }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid"
                                viewBox="0 0 32 32"
                              >
                                <g
                                  className="shape-element"
                                  fill="rgb(234, 153, 153)"
                                  data-viewbox="0 0 32 32"
                                >
                                  <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM16 4c2.59 0 4.973 0.844 6.934 2.242l-16.696 16.688c-1.398-1.961-2.238-4.344-2.238-6.93 0-6.617 5.383-12 12-12zM16 28c-2.59 0-4.973-0.844-6.934-2.242l16.696-16.688c1.398 1.961 2.238 4.344 2.238 6.93 0 6.617-5.383 12-12 12z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sl-block"
                          data-block-type="text"
                          style={{
                            height: 'auto',
                            width: 'fit-content',
                            left: '530.947px',
                            top: 385,
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                          data-name="text-8a2918"
                        >
                          <div
                            className="sl-block-content"
                            style={{ zIndex: 17 }}
                          >
                            <p style={{ textAlign: 'center' }}>
                              עישון במיטה
                              <br />
                              או במקום לא
                              <br />
                              מיועד
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-d204c7"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-up"
                style={{ transformOrigin: '517px 351.5px 0px', zIndex: 29 }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-29a3d7"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '740.886px 351.5px 0px',
                      zIndex: 30
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '163.728px',
                        height: 180,
                        left: '659.022px',
                        top: '261.5px'
                      }}
                      data-name="shape-9a8ab6"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgba(0, 0, 0, 0)"
                        data-shape-stretch="true"
                        style={{ zIndex: 31 }}
                        data-shape-stroke-color="#6aa84f"
                        data-shape-stroke-width="29px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 164 180"
                        >
                          <defs>
                            <clipPath id="shape-mask-10-1696410026253">
                              <ellipse
                                rx="81.864"
                                ry={90}
                                cx="81.864"
                                cy={90}
                                fill="rgba(0, 0, 0, 0)"
                                stroke="#6aa84f"
                                strokeWidth={58}
                              />
                            </clipPath>
                          </defs>
                          <ellipse
                            rx="81.864"
                            ry={90}
                            cx="81.864"
                            cy={90}
                            className="shape-element"
                            fill="rgba(0, 0, 0, 0)"
                            stroke="#6aa84f"
                            strokeWidth={58}
                            clipPath="url(#shape-mask-10-1696410026253)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        height: 'auto',
                        width: 'fit-content',
                        left: '698.395px',
                        top: '321.5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      data-name="text-52dc21"
                    >
                      <div className="sl-block-content" style={{ zIndex: 32 }}>
                        <p>מאפרה</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-name="group-15ae81"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '293.114px 351.5px 0px',
                      zIndex: 33
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '163.728px',
                        height: 180,
                        left: '211.25px',
                        top: '261.5px'
                      }}
                      data-origin-id="5e5cdaeb2417439515de86579fd1cf58"
                      data-name="shape-ef23d2"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgba(0, 0, 0, 0)"
                        data-shape-stretch="true"
                        style={{ zIndex: 34 }}
                        data-shape-stroke-color="#6aa84f"
                        data-shape-stroke-width="29px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 164 180"
                        >
                          <defs>
                            <clipPath id="shape-mask-11-1696410026253">
                              <ellipse
                                rx="81.864"
                                ry={90}
                                cx="81.864"
                                cy={90}
                                fill="rgba(0, 0, 0, 0)"
                                stroke="#6aa84f"
                                strokeWidth={58}
                              />
                            </clipPath>
                          </defs>
                          <ellipse
                            rx="81.864"
                            ry={90}
                            cx="81.864"
                            cy={90}
                            className="shape-element"
                            fill="rgba(0, 0, 0, 0)"
                            stroke="#6aa84f"
                            strokeWidth={58}
                            clipPath="url(#shape-mask-11-1696410026253)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        height: 'auto',
                        width: '95.9216px',
                        left: '245.153px',
                        top: 312
                      }}
                      data-origin-id="1eec26d8750731e95af5784bb4d17ae2"
                      data-name="text-348cbe"
                    >
                      <div className="sl-block-content" style={{ zIndex: 35 }}>
                        <p style={{ textAlign: 'center' }}>גלאי עשן</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-name="group-36c678"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '522.168px 351.5px 0px',
                      zIndex: 36
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '163.728px',
                        height: 180,
                        left: '440.304px',
                        top: '261.5px'
                      }}
                      data-origin-id="5e5cdaeb2417439515de86579fd1cf58"
                      data-name="shape-4f3587"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgba(0, 0, 0, 0)"
                        data-shape-stretch="true"
                        style={{ zIndex: 37 }}
                        data-shape-stroke-color="#6aa84f"
                        data-shape-stroke-width="29px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 164 180"
                        >
                          <defs>
                            <clipPath id="shape-mask-12-1696410026253">
                              <ellipse
                                rx="81.864"
                                ry={90}
                                cx="81.864"
                                cy={90}
                                fill="rgba(0, 0, 0, 0)"
                                stroke="#6aa84f"
                                strokeWidth={58}
                              />
                            </clipPath>
                          </defs>
                          <ellipse
                            rx="81.864"
                            ry={90}
                            cx="81.864"
                            cy={90}
                            className="shape-element"
                            fill="rgba(0, 0, 0, 0)"
                            stroke="#6aa84f"
                            strokeWidth={58}
                            clipPath="url(#shape-mask-12-1696410026253)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        height: 'auto',
                        width: '115.767px',
                        left: '464.285px',
                        top: '288.5px',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      data-origin-id="1eec26d8750731e95af5784bb4d17ae2"
                      data-name="text-65fe62"
                    >
                      <div className="sl-block-content" style={{ zIndex: 38 }}>
                        <p style={{ textAlign: 'center' }}>
                          {/* <span
                            style={{ fontSize: '0.9em', textAlign: 'center' }}
                          > */}
                          כיבוי סיגריות
                          {/* </span> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <QuestionSlide
              key="first-fireAndElectericitySafety-question"
              handleCorrectAnswer={handleCorrectAnswer}
              handleWrongAnswer={handleWrongAnswer}
              {...getQuestionData(globalCoursewares, selectedCourseware, 19)}
              selectedCourseware={selectedCourseware}
            />
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '87.1415px',
                top: '113.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  width: '100%',
                  color: 'rgb(255, 0, 0)',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>שימוש במטפה כיבוי אש</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(255, 255, 255)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(255, 255, 255)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 1004, top: '201.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.38', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(255, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '160.142px',
                top: '6.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 0)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.65em' }}>
                    שימוש במטפה כיבוי אש
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(255, 255, 255)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(255, 255, 255)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 1004, top: '201.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(255, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '100%',
                height: '237.759px',
                // left: '191.481px',
                top: '234.121px'
              }}
              data-name="image-eca717"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  alt="fireAndElectericitySafety"
                  className=""
                  data-natural-width={641}
                  data-natural-height={219}
                  style={{
                    maxWidth: 700,
                    minWidth: 300
                  }}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10704215/Screenshot_2023-08-27_121932.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '183.142px',
                top: '8.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  width: '100%',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>מתזי מים (ספרנקלרים)</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)', opacity: '0.81' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(201, 218, 248)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(201, 218, 248)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 1004, top: '201.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.73', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(255, 229, 153)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(255, 229, 153)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 804,
                left: 137,
                top: '334.613px'
              }}
              data-name="text-84c6db"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 13 }}>
                  <p style={{ textAlign: 'justify' }}>
                    מערכת מתזי מים אוטומטית (ספרינקלרים) היא אחת השיטות היעילות
                    לשליטה או דיכוי שריפה.
                    <br />
                    מערכת ספרינקלרים תוכל לעצור את השריפה כבר בתחילתה, לפני
                    שתתפשט לשאר הבניין.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '711.717px',
                left: '183.142px',
                top: '25.5px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(34, 34, 34)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>גלאי עשן</h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)', opacity: '0.81' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(170, 170, 170)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(170, 170, 170)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 1004, top: '201.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.58', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(120, 63, 4)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(120, 63, 4)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '100%',
                // left: '493.001px',
                top: '262.5px',
                zIndex: 99
              }}
              data-name="text-84c6db"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 13 }}>
                  <p style={{ textAlign: 'justify' }}>
                    גלאי עשן עובד באמצעות חיישן הקולט עשן ומפעיל אזעקה.
                    <br />
                    במידה וקיימת סכנת שריפה, הגלאי יתריע על כך ויאפשר לכם זמן
                    יקר להציל חיים.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '376.532px',
                height: 230,
                left: '10.554px',
                top: 400,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cfc141"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  alt="fireAndElectericitySafety"
                  className=""
                  data-natural-width={406}
                  data-natural-height={248}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10704218/Screenshot_2023-08-27_122309.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '130.571px',
                top: 120,
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 255)',
                  width: '100%'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  אם הגעת עד לכאן
                  <br />
                  סימן שהצלחת בגדול
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 506,
                height: 595,
                left: '-296.18px',
                top: '-83.5px'
              }}
              data-name="shape-11f619"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, filter: 'blur(61px)', opacity: '0.81' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="diamond"
                  data-shape-fill-color="rgb(170, 170, 170)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 506 595"
                  >
                    <polygon
                      points="506,297.5 253,595 0,297.5 253,0"
                      className="shape-element"
                      fill="rgb(170, 170, 170)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-f2d706"
              style={{ width: 276, height: 578, left: 1004, top: '201.613px' }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 11, opacity: '0.58', filter: 'blur(200px)' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="octagon"
                  data-shape-fill-color="rgb(120, 63, 4)"
                  data-shape-stretch="true"
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 276 578"
                  >
                    <polygon
                      points="276,289 235.6,493.4 138,578 40.4,493.4 0,289 40.4,84.6 138,0 235.6,84.6"
                      className="shape-element"
                      fill="rgb(120, 63, 4)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '317.571px',
                top: 405,
                height: 'auto'
              }}
              data-name="text-083afe"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '0.1em',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h3 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '0.7em' }}>
                    לחץ על חתימה כדי לשמור את ההישג
                  </span>
                </h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: '100%', bottom: 100 }}
              data-name="button-f3216ab"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <Button
                  variant="contained"
                  onClick={() => handleSetViewToSignCourseware()}
                  sx={{ minWidth: 140 }}
                  // disabled={!didReachTheEnd}
                >
                  חתימה על סיום הלומדה
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FireAndElectericitySafety;
