import { Box, Card, Chip, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CodeRecievingProcess from 'src/components/MohCode/CodeRecievingProcess';
import i18n from 'src/i18n';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import handleGTag from 'src/utils/handleGTag';
// import '../components/MohCode/programs.scss';
import ReadMoreSuggestions from 'src/components/shared/ReadMoreSuggestions/ReadMoreSuggestions';
import mohBranches from '../components/MohBranches/programsData';

const packages = [
  {
    title: 'all',
    label: i18n.t('mohCode.allAreas')
  },
  {
    title: 'north',
    label: i18n.t('mohCode.north')
  },
  {
    title: 'south',
    label: i18n.t('mohCode.south')
  },
  {
    title: 'center',
    label: i18n.t('mohCode.center')
  }
];

const MohCode = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mohCode'
  });
  const [displayedData, setDisplayedData] = useState(mohBranches);
  const [active, setActive] = useState(1);

  useEffect(() => {
    if (active > 1) {
      const temp = mohBranches.filter(
        (el) => el.district === packages[active - 1].title
      );
      setDisplayedData(temp);
    } else {
      setDisplayedData(mohBranches);
    }
  }, [active]);

  return (
    <>
      <Helmet>
        <title>{t('mohCode')}</title>
        <meta
          name="description"
          content="מגדלור ייעוץ והכוונה - מנוע החיפוש וההשוואה של בתי האבות והדיור המוגן בישראל"
        />
        <meta name="googlebot" content="index, follow" />
        <meta name="robots" content="index, follow" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
        <link rel="canonical" href="https://www.mgdalor.com/mohCode" />
      </Helmet>

      <section
        style={{
          background: theme.palette.background.default
        }}
      >
        <Box height="12.5rem" width="100%" />
        <Card
          sx={{
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: theme.shadows[6]
          }}
        >
          <Grid
            sx={{ py: 2 }}
            container
            spacing={2}
            component="section"
            id="programs"
          >
            <Grid item xs={12}>
              <Stack
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <Chip
                  color="success"
                  label="אנחנו נלווה אתכם"
                  sx={{ fontSize: '1.2rem' }}
                />
                <Typography
                  variant="h1"
                  sx={{ fontSize: '2.5rem', fontWeight: 600 }}
                >
                  תהליך קבלת קוד
                </Typography>
                <Stack sx={{ textAlign: 'center' }}>
                  <Typography>{`${t('theProcessIsTwoStages')}.`}</Typography>
                  <Typography component="span">
                    {/* <Typography> */}
                    {t('theRequiredDocsAreFoundIn')}
                    &nbsp;
                    {t('hospitlizationCodePage')}
                    &nbsp;
                    <Typography
                      component="a"
                      target="_blank"
                      href={
                        process.env.REACT_APP_DEV === 'true'
                          ? `http://localhost:3000/${routesConst.forms}`
                          : `https://www.mgdalor.com/${routesConst.forms}`
                      }
                      style={{
                        zIndex: 1,
                        position: 'relative'
                      }}
                    >
                      {t('inInitialDocumentsSubmitting')}
                    </Typography>
                    {/* </Typography> */}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <CodeRecievingProcess />
            </Grid>
          </Grid>
          <Grid container>
            <Grid sx={{ mx: 'auto' }} item xs={12} md={10}>
              <ReadMoreSuggestions key="read-more-moh-code-page" />
            </Grid>
          </Grid>
        </Card>
      </section>
    </>
  );
};

export default MohCode;
