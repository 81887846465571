const { default: getUserDetails } = require('./getUserDetails');

const getEmployeeDetails = async (employee_id, employeesList) => {
  //   console.log(employeesList);
  if (employeesList) {
    const indexInEmployees = employeesList.findIndex(
      (el) =>
        el.org_specific_details &&
        el.org_specific_details.employee_ref === employee_id
    );
    if (indexInEmployees > -1) {
      return employeesList[indexInEmployees];
    }
    const details = await getUserDetails(employee_id);
    return details;
  }
};

export default getEmployeeDetails;
