const getTransitRoute = async () =>
  fetch(
    `https://maps.googleapis.com/maps/api/directions/json?origin="העמקים 15, קריית ביאליק, ישראל"&destination="ישראלאק ייצור ושיווק בע"מ, Yosef Levi Street, קריית ביאליק, Israel"&mode=transit&key=${process.env.REACT_APP_APIKEY}`
  )
    .then(async (response) => response.json())
    .then((data) => data)
    .catch((err) => {
      console.log(err);
      return {};
    });

export default getTransitRoute;
