import { Chip, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const TagsCompare = ({ StyledTableCell, list, userscrolledhorizontally }) => {
  TagsCompare.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.object,
    userscrolledhorizontally: PropTypes.bool
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });
  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="departments" />
        {t('suitableFor')}
      </StyledTableCell>
      {list && list.tags
        ? list.tags.map((orgTags) => (
            <StyledTableCell lengthOfList={list.names.length} align="center">
              <Grid
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}
                container
                spacing={2}
              >
                {orgTags.map((tag) => (
                  <Grid item xs={6}>
                    <Chip
                      // clickable
                      sx={{ mx: 1 }}
                      variant="outlined"
                      color="secondary"
                      label={`${t(tag)}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </StyledTableCell>
          ))
        : null}
    </>
  );
};

export default TagsCompare;
