const getUserDepartmentsWithSpecificPermission = (
  permission,
  selectedOrg,
  currentUser
) => {
  const userDepartmentsWithSpecificPermission = [];
  const indexOfSelectedOrg = currentUser.employee_work_places.findIndex(
    (el) => el.org_ref === selectedOrg
  );
  if (indexOfSelectedOrg > -1) {
    const rbacOfSelectedOrg =
      currentUser.employee_work_places[indexOfSelectedOrg].org_specific_details
        .rbac;
    const { status } =
      currentUser.employee_work_places[indexOfSelectedOrg].org_specific_details;
    if (status === 'approved') {
      if (rbacOfSelectedOrg && rbacOfSelectedOrg.length > 0) {
        rbacOfSelectedOrg.forEach((rbacOfOrg) => {
          if (
            rbacOfOrg.role_ref &&
            rbacOfOrg.role &&
            rbacOfOrg.role.permissions.includes(permission) &&
            rbacOfOrg.department_id
          ) {
            userDepartmentsWithSpecificPermission.push(rbacOfOrg);
          }
        });
        //   console.log(userDepartmentsWithSpecificPermission);
        return userDepartmentsWithSpecificPermission;
      }
      return [];
    }
    return [];
  }
  return [];
};

export default getUserDepartmentsWithSpecificPermission;
