import { collection, getDocs, query, where } from '@firebase/firestore';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMap } from 'react-map-gl';
import { db } from 'src/_firebase/firebase';
// import ActiveOrgsAutoComplete from 'src/components/CompareOrgs/ActiveOrgAutoCompleteField';
import ComparisonTable from 'src/components/Home/ComparisonTable/ComparisonTable';
import ComparisonTableEmptyState from 'src/components/Home/ComparisonTable/ComparisonTableEmptyState';
import InteractiveBackgroundMap from 'src/components/shared/InteractiveBackgroundMap/InteractiveBackgroundMap';
import theme from 'src/theme';
import handleAdoptionForCompareTable from 'src/utils/handleAdoptionForCompareTable';
import handleGTag from 'src/utils/handleGTag';

const ActiveOrgsAutoComplete = lazy(() =>
  import('src/components/CompareOrgs/ActiveOrgAutoCompleteField')
);
const CompareOrgsPage = () => {
  const { bgMap } = useMap({
    id: 'bgMap'
  });
  const [orgsToCompare, setOrgsToCompare] = useState([]);
  const [normalizedList, setNormalizedList] = useState();
  const [orgsPictures, setOrgsPictures] = useState();
  const [approvedOrgs, setApprovedOrgs] = useState();
  const [bgIsBlurred, setBgIsBlurred] = useState(true);

  const handleRetrieveOrgs = async () => {
    const orgsRef = collection(db, 'users');
    const q = query(orgsRef, where('org_status', '==', 'approved'));
    const orgsDocs = await getDocs(q)
      .then((orgs) => {
        if (orgs.empty) {
          return [];
        }
        return orgs.docs.map((org) => {
          const { _geoloc } = org.data();
          // console.log(_geoloc);
          // const {_lat, _long} = _geoloc;
          return {
            ...org.data(),
            _geoloc: {
              lat: _geoloc.lat || _geoloc._lat,
              lng: _geoloc.lng || _geoloc._long
            }
          };
        });
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
    // console.log(orgsDocs);
    setApprovedOrgs(orgsDocs);
  };
  const getFirstGeoLoc = () => {
    // console.log(approvedOrgs);
    if (approvedOrgs.length > 0 && approvedOrgs.find((el) => el._geoloc)) {
      const first = approvedOrgs.find((el) => el._geoloc)._geoloc;
      return first;
    }
  };

  useEffect(() => {
    if (!approvedOrgs) {
      handleRetrieveOrgs();
    }
    // else {
    //   const firstGeoLoc = getFirstGeoLoc();

    //   if (firstGeoLoc) {
    //     bgMap.easeTo({
    //       center: firstGeoLoc,
    //       zoom: 10,
    //       duration: 650
    //     });
    //   } else {
    //     bgMap.easeTo({
    //       center: {
    //         lng: 35.2907,
    //         lat: 32.8637696436946
    //       },
    //       zoom: 10,
    //       duration: 650
    //     });
    //   }
    // }
  }, [approvedOrgs]);

  const handleNormalizeData = async () => {
    const nomralized = await handleAdoptionForCompareTable(orgsToCompare);
    setNormalizedList(nomralized);
  };

  useEffect(() => {
    if (orgsToCompare && orgsToCompare.length > 0) {
      handleNormalizeData();
    }
  }, [orgsToCompare]);

  const handleChangeOrgs = (newOrgsToCompareValue) => {
    setOrgsToCompare(newOrgsToCompareValue);
    // in order to retrieve the pics again we need to clear orgsPictures
    setOrgsPictures();
  };
  // console.log(bgMap);
  useEffect(() => {
    if (bgMap) {
      if (!bgIsBlurred && approvedOrgs) {
        const firstGeoLoc = getFirstGeoLoc();
        if (firstGeoLoc) {
          bgMap.easeTo({
            pitch: 10,
            center: firstGeoLoc,
            zoom: 6,
            duration: 1700
          });
        }
      } else if (bgIsBlurred) {
        // console.log(111);
        bgMap.easeTo({
          center: {
            lng: 35.2907,
            lat: 32.8637696436946
          },
          zoom: 10,
          duration: 650
        });
      }
    }
  }, [bgIsBlurred, approvedOrgs, bgMap]);

  return (
    <>
      <Helmet>
        <title>השוואת בתי אבות</title>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
        <link rel="canonical" href="https://www.mgdalor.com/compare" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
      </Helmet>
      <Paper id="main-con-paper" sx={{ minHeight: '100vh' }}>
        <Grid
          sx={{ px: 2, position: 'relative', py: 12 }}
          container
          spacing={2}
        >
          <Grid item xs={12} sx={{ pr: '10% !important', zIndex: 1 }}>
            <Typography
              variant="h1"
              sx={{ fontSize: '4rem', maxWidth: 650, fontWeight: 600 }}
              component="span"
            >
              הדברים לא נראים אותו דבר,
              <Typography
                sx={{
                  fontSize: '4rem',
                  maxWidth: 650,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  textAlign: 'justify'
                }}
              >
                כשמסתכלים מלמעלה
              </Typography>
            </Typography>
            <Typography
              sx={{ maxWidth: 700, textAlign: 'justify' }}
              variant="h4"
            >
              למצוא בית אבות שמתאים לכם בדיוק זו משימה לא פשוטה, לכן הכנו כלי
              שבאמצעותו תוכלו להשוות בין בתי האבות שמעניינים אתכם
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              zIndex: 8
            }}
          >
            <ActiveOrgsAutoComplete
              approvedOrgs={approvedOrgs}
              setApprovedOrgs={setApprovedOrgs}
              orgsToCompare={orgsToCompare}
              setOrgsPictures={setOrgsPictures}
              setBgIsBlurred={setBgIsBlurred}
              handleChangeOrgs={handleChangeOrgs}
              placeholder="בין איזה ארגונים תרצה להשוות?"
              label="סמן ארגונים שתרצה להשוות ביניהם"
            />
          </Grid>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 0,
              position: 'absolute'
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 1,
                position: 'absolute',
                background: bgIsBlurred
                  ? 'linear-gradient(to left, rgba(248, 248, 248, 0.65) 0%, rgba(248, 248, 248, 0.95) 50%)'
                  : 'linear-gradient(to left, rgba(248, 248, 248, 0.45) 0%, rgba(248, 248, 248, 0.95) 50%)',
                transition: 'all 0.5s',
                backdropFilter: bgIsBlurred && 'blur(2px)',
                WebkitBackdropFilter: bgIsBlurred && 'blur(2px)'
              }}
            />
            <InteractiveBackgroundMap
              customMapCenter={{
                lng: 35.11,
                lat: 32.71
              }}
              markers={approvedOrgs}
            />
          </Box>
        </Grid>
        <Box sx={{ width: '100%', p: 2 }}>
          {orgsToCompare.length > 0 && normalizedList ? (
            <ComparisonTable
              key="comparison-preview-table"
              list={normalizedList}
              orgsPictures={orgsPictures}
              setOrgsPictures={setOrgsPictures}
              open={Boolean(true)}
            />
          ) : (
            <ComparisonTableEmptyState
              loading={!normalizedList && orgsToCompare.length > 0}
            />
          )}
        </Box>
      </Paper>
    </>
  );
};

export default CompareOrgsPage;
