import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  List,
  Stack,
  Typography
} from '@mui/material';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import noSavedProducts from 'src/assets/lottie/org-page-empty.json';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import SavedProductListItemButton from './SavedProductListItemButton';

const SavedProductsDialog = ({
  open,
  onClose,
  savedProducts,
  handleOpenVerifyPurchaseRequest,
  handleBookmarkProduct
}) => {
  SavedProductsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleOpenVerifyPurchaseRequest: PropTypes.func,
    handleBookmarkProduct: PropTypes.func,
    savedProducts: PropTypes.array
  };
  const { snackbarState, setSnackbarState } = useSnackbar();
  const [isLoading, setLoading] = useState();
  const { currentUser } = useCurrentUserContext();

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography component="span" variant="inherit">
          רשימת פרטים שמורים
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {!savedProducts ? (
            <LinearProgress />
          ) : savedProducts && savedProducts.length === 0 ? (
            <Stack>
              <Lottie
                style={{
                  //   width: matchDownSm ? '40vw !important' : '15vw !important',
                  height: '15vw',
                  minHeight: '180px',
                  minWidth: '180px !important',
                  width: '100%',
                  opacity: 0.8,
                  margin: 'auto'
                }}
                autoplay
                animationData={noSavedProducts}
                loop
              />
              <Typography textAlign="center">
                פריטים שתשמור יופיעו כאן.
              </Typography>
            </Stack>
          ) : (
            <List sx={{ width: '100%' }} aria-label="saved products">
              {savedProducts.map((saved) => (
                <SavedProductListItemButton
                  handleOpenVerifyPurchaseRequest={
                    handleOpenVerifyPurchaseRequest
                  }
                  handleBookmarkProduct={handleBookmarkProduct}
                  key={`savedProductListItemButton-${saved.product_id}`}
                  saved={saved}
                />
              ))}
            </List>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          סגירה
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SavedProductsDialog;
