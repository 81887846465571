import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import globalCoursewaresSlides from './globalCoursewaresSlides';

const GlobalCoursewaresContext = createContext();

export const GlobalCoursewaresProvider = ({ children }) => {
  GlobalCoursewaresProvider.propTypes = {
    children: PropTypes.any
  };
  const [globalCoursewares, setGlobalCoursewares] = useState();

  const currentGlobal = useMemo(
    () => ({ globalCoursewares, setGlobalCoursewares }),
    [globalCoursewares, setGlobalCoursewares]
  );

  useEffect(() => {
    if (!globalCoursewares) {
      const sorted = orderBy(globalCoursewaresSlides, 'order', 'asc');
      setGlobalCoursewares(sorted);
    }
  }, []);

  return (
    <GlobalCoursewaresContext.Provider value={currentGlobal}>
      {children}
    </GlobalCoursewaresContext.Provider>
  );
};
export const useGlobalCoursewares = () => useContext(GlobalCoursewaresContext);
