import { SearchOutlined } from '@mui/icons-material';
import { Button, Stack, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/styles/useTheme';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

const SearchIconButtons = ({
  setGeocodingError,
  setAdvancedSettingsExpanded,
  isAdvancedSettingsExpanded,
  setOpenAdvancedSearchMobileDialog,
  performSearch
}) => {
  SearchIconButtons.propTypes = {
    setGeocodingError: PropType.func,
    setAdvancedSettingsExpanded: PropType.func,
    isAdvancedSettingsExpanded: PropType.bool,
    setOpenAdvancedSearchMobileDialog: PropType.func,
    performSearch: PropType.func
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults'
  });
  const theme = useTheme();

  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenAdvancedSearchMobileDialog = () => {
    setOpenAdvancedSearchMobileDialog(true);
  };

  const handleExandingAdvancedSearchAreaInSmUp = () => {
    setAdvancedSettingsExpanded(!isAdvancedSettingsExpanded);
  };

  return (
    <>
      {matchDownSm ? (
        <Stack
          direction="row"
          justifyContent="center"
          gap={2}
          sx={{
            py: 2,
            height: 'max-content',
            alignItems: 'center'
          }}
        >
          <Button
            onClick={() => performSearch()}
            sx={{
              border: `1px solid ${alpha(
                theme.palette.primary.light,
                0.5
              )} !important`,
              borderRadius: '8px !important',
              height: '100%',
              fontSize: 18
            }}
            id="search-btn"
            // tabIndex={0}
          >
            <SearchOutlined />
            {t('search')}
          </Button>
          <Button
            onClick={() => handleOpenAdvancedSearchMobileDialog()}
            sx={{
              border: `1px solid ${alpha(
                theme.palette.primary.light,
                0.5
              )}  !important`,
              borderRadius: '8px !important',
              height: '100%',
              fontSize: 18
            }}
          >
            {t('advanced')}
          </Button>
        </Stack>
      ) : (
        <>
          <Button
            tabIndex={0}
            onClick={() => performSearch()}
            sx={{ flex: 0.05 }}
          >
            <SearchOutlined fontSize="large" />
          </Button>
          <Button
            onClick={() => handleExandingAdvancedSearchAreaInSmUp()}
            variant={isAdvancedSettingsExpanded ? 'contained' : 'outlined'}
            sx={{
              flex: 0.05,
              bgcolor: isAdvancedSettingsExpanded
                ? theme.palette.primary.light
                : 'inherit',
              fontSize: 18
            }}
          >
            חיפוש מתקדם
          </Button>
        </>
      )}
    </>
  );
};

export default SearchIconButtons;
