import { Avatar, Stack, Typography } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../home.css';
import StyledTableCell from './StyledTableCell';

const StyledComparisonTableHead = ({ userscrolledhorizontally, list }) => {
  StyledComparisonTableHead.propTypes = {
    userscrolledhorizontally: PropTypes.bool,
    list: PropTypes.object
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });
  return (
    <TableHead
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 2,
        bgcolor: 'AppWorkspace'
      }}
    >
      <TableRow>
        <StyledTableCell userscrolledhorizontally={Boolean(userscrolledhorizontally)}>
          {t('nameOfOrg')}
        </StyledTableCell>
        {list.names.map((orgHeader, index) => (
          <StyledTableCell
            key={orgHeader}
            lengthOfList={list.names.length}
            align="center"
            sx={{
              textAlign: 'center',
              verticalAlign: 'center',
              margin: 'auto'
            }}
          >
            <Stack
              direction="row"
              sx={{
                p: 0,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2
              }}
            >
              {list.avatars && (
                <Avatar
                  alt="org-avatar"
                  src={list.avatars[index] || orgHeader[0]}
                  sx={{ zIndex: 2 }}
                />
              )}
              <Typography
                fontFamily="inherit"
                fontWeight="bold"
                fontSize="1em"
              >
                {orgHeader}
              </Typography>
              {/* </Typography> */}
            </Stack>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default StyledComparisonTableHead;
