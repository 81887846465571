import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

const OrgPageLoader = ({ active }) => {
  OrgPageLoader.propTypes = {
    active: PropTypes.bool
  };

  return (
    <Box component="section">
      <Container>
        {/* <Grid container justifyContent="center" mx="auto"> */}
        <Grid
          sx={{ position: 'relative' }}
          container
          justifyContent="center"
          py={6}
        >
          <Grid item xs={12} md={10} mx={{ xs: 'auto', sm: 3, md: 1 }}>
            <Stack
              alignItems="flex-start"
              justifyContent="space-between"
              direction="row"
            >
              <Stack>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                  mb={1}
                >
                  <Skeleton animation="wave" height={20} width="124px" />
                </Box>
                <Grid container spacing={3} mb={1}>
                  <Grid item>
                    <Skeleton animation="wave" height={15} width="50px" />
                  </Grid>
                </Grid>
                <Stack sx={{ mt: 2 }} gap={2} direction="row">
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={25}
                    width="50px"
                    sx={{ mt: 2, borderRadius: 2 }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={25}
                    width="50px"
                    sx={{ mt: 2, borderRadius: 2 }}
                  />
                </Stack>
              </Stack>
              <Skeleton
                sx={{
                  width: 200,
                  height: 120
                }}
                animation="wave"
                variant="rounded"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={10} py={3} mx={{ xs: 'auto', sm: 3, md: 1 }}>
            <Typography variant="body1" fontWeight="light" color="text">
              <Skeleton width="80%" />
              <Skeleton width="50%" />
              <Skeleton width="35%" />
            </Typography>
          </Grid>
          <Grid
            item
            pt={6}
            xs={12}
            md={7}
            sx={{ display: 'flex', justifyContent: 'space-around' }}
            mx={{ xs: 'auto', sm: 6, md: 1 }}
          >
            <Stack gap={1} direction="column" alignItems="center">
              <Skeleton
                sx={{ width: '40px', height: '40px' }}
                variant="rounded"
              />
              <Typography sx={{ width: '50px' }} variant="subtitle2">
                <Skeleton />
              </Typography>
            </Stack>
            <Stack gap={1} direction="column" alignItems="center">
              <Skeleton
                sx={{ width: '40px', height: '40px' }}
                variant="rounded"
              />
              <Typography sx={{ width: '50px' }} variant="subtitle2">
                <Skeleton />
              </Typography>
            </Stack>
            <Stack gap={1} direction="column" alignItems="center">
              <Skeleton
                sx={{ width: '40px', height: '40px' }}
                variant="rounded"
              />
              <Typography sx={{ width: '50px' }} variant="subtitle2">
                <Skeleton />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton width="100%" height="100%">
              <div style={{ height: '50vh' }} />
            </Skeleton>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Container>
    </Box>
  );
};

export default OrgPageLoader;
