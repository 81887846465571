import {
  BadgeOutlined,
  BusinessOutlined,
  HomeOutlined,
  InfoOutlined,
  SearchOutlined
} from '@mui/icons-material';
import { Icon } from '@mui/material';
import i18n from 'src/i18n';
import routesConst from 'src/routesConst';

const navbarRoutes = [
  {
    name: i18n.t('navbar.about'),
    icon: (
      <Icon>
        <BusinessOutlined color="disabled" />
      </Icon>
    ),
    columns: 2,
    rowsPerColumn: 1,
    collapse: [
      {
        name: i18n.t('navbar.company'),
        collapse: [
          {
            name: i18n.t('navbar.ourValues'),
            route: `/${routesConst.vision}`
            // component: <Home />
          },
          {
            name: i18n.t('navbar.ourVision'),
            route: '/vision'
            // component: <Home />
          },
          {
            name: i18n.t('navbar.contact'),
            route: `/${routesConst.contact}`
            // component: <Home />
          }
        ]
      },
      {
        name: i18n.t('navbar.account'),
        collapse: [
          {
            name: i18n.t('navbar.login'),
            route: '/login'
            // component: <Home />
          },
          {
            name: i18n.t('navbar.register'),
            route: '/register'
            // component: <Home />
          }
        ]
      }
    ]
  },
  {
    name: i18n.t('navbar.professionalKnowledge'),
    columns: 3,
    rowsPerColumn: 1,
    icon: (
      <Icon>
        <InfoOutlined color="disabled" />
      </Icon>
    ),
    collapse: [
      {
        name: i18n.t('navbar.moh'),
        collapse: [
          {
            name: i18n.t('navbar.mohCode'),
            // description: 'All about moh, license and contents',
            route: '/mohCode'
          },
          {
            name: i18n.t('navbar.mohReports'),
            route: '/mohReports'
          },
          {
            name: i18n.t('navbar.mohBranches'),
            route: '/mohBranches'
          }
        ]
      },
      {
        name: i18n.t('navbar.usefulLinks'),
        collapse: [
          {
            name: i18n.t('navbar.compare'),
            route: '/compare'
          },
          {
            name: i18n.t('navbar.blog'),
            route: '/blog'
          },
          {
            name: i18n.t('navbar.forms'),
            route: '/forms'
          },
          {
            name: i18n.t('navbar.guide'),
            route: '/userGuide'
          },
          {
            name: i18n.t('navbar.ourSystem'),
            route: process.env.REACT_APP_ENV === 'dev' ? '/ourSystem' : '/'
          }
        ]
      },
      {
        name: 'טיפולים קהילתיים',
        collapse: [
          {
            name: i18n.t('navbar.medicalAssessment'),
            route: `${routesConst.medicalAssessment}`
          },
          {
            name: i18n.t('navbar.privateNursing'),
            route: `${routesConst.medicalAssessment}?type=nurse`
          }
        ]
      }
    ]
  },
  {
    name: i18n.t('navbar.familyLogin'),
    route: '/family-login',
    icon: (
      <Icon>
        <HomeOutlined color="primary" />
      </Icon>
    )
    // component: <Home />
  },
  {
    name: i18n.t('navbar.findAHome'),
    icon: (
      <Icon>
        <SearchOutlined color="primary" />
      </Icon>
    ),
    route: '/search'
  },
  {
    name: i18n.t('navbar.professional'),
    icon: (
      <Icon>
        <BadgeOutlined color="primary" />
      </Icon>
    ),
    route: '/login'
  }
];

export default navbarRoutes;
