const marketplaceCategories = [
  {
    value: 'coursewares',
    label: 'מצגות ולומדות'
  },
  {
    value: 'exams',
    label: 'בחינות'
  },
  {
    value: 'learning',
    label: 'חומר לימודי'
  },
  {
    value: 'other',
    label: 'אחר'
  }
];
export default marketplaceCategories;
