import i18n from 'src/i18n';

const orgsSizes = [
  { name: 'lessThan100', label: i18n.t('register.orgSizes.lessThan100') },
  {
    name: 'between100And250',
    label: i18n.t('register.orgSizes.between100And250')
  },
  {
    name: 'between250And500',
    label: i18n.t('register.orgSizes.between250And500')
  },
  { name: 'moreThan500', label: i18n.t('register.orgSizes.moreThan500') }
];

export default orgsSizes;
