import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocale from './locale/en/translations.json';
import heLocale from './locale/he/translations.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'he',
  lng: localStorage.getItem('lang') || 'he',
  resources: {
    en: {
      translation: enLocale
    },
    he: {
      translation: heLocale
    }
  }
});

export default i18n;
