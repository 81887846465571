import {
  collection,
  doc,
  getCountFromServer,
  getDoc
} from '@firebase/firestore';
import moment from 'moment';
import { db } from 'src/_firebase/firebase';
import getUserSignature from 'src/components/Practices/getUserSignature';
import getOrgRef from 'src/utils/getOrgRef';
import handleRetrieveAuthorDetails from './handleRetrieveAuthorDetails';

const userIsAuthor = (user_id, author_id) => user_id === author_id;

const getDetailsData = (authorDetails, currentUser) => {
  const { first_name, last_name, org_name, avatar, type } = authorDetails;
  if (userIsAuthor && org_name) {
    return { name: org_name, avatar };
  }
  if (userIsAuthor && first_name) {
    return { name: `${first_name} ${last_name}`, avatar };
  }
  if (authorDetails.first_name && authorDetails.last_name) {
    return {
      name: `${authorDetails.first_name} ${authorDetails.last_name}`,
      avatar
    };
  }
  if (authorDetails.org_name) {
    return { name: authorDetails.org_name, avatar };
  }
  return { name: 'אין מידע', avatar };
};

const handleGetFavoriteStateOfPost = async (org_ref, currentUser, post) => {
  const { post_id } = post;
  if (post_id) {
    const { user_doc_id } = currentUser;
    const likesRef = doc(
      db,
      `orgs/${org_ref}/community/${post_id}/likes/${user_doc_id}`
    );
    return getDoc(likesRef)
      .then((res) => ({ ...res.data() }))
      .catch((err) => {
        console.log(err);
        return {};
      });
  }
  return {};
};

const getSignCount = async (orgRef, postId) => {
  const signsRef = collection(db, `orgs/${orgRef}/community/${postId}/signs`);
  const count = await getCountFromServer(signsRef)
    .then((res) => res.data().count)
    .catch((err) => {
      console.log(err);
      return 0;
    });
  return count;
};

const getUserSignedPhrase = (userSignature, currentUser) => {
  if (userSignature && userSignature.user_id === currentUser.user_doc_id) {
    const { signed_at } = userSignature;
    if (signed_at) {
      return `חתמת בתאריך ${moment(signed_at.seconds * 1000).format(
        'DD/MM/YYYY'
      )}`;
    }
  }
};

const handleRetrievePostDetails = async (
  currentUser,
  employeesData,
  post,
  selectedOrg,
  postDetails
) => {
  const author = userIsAuthor(currentUser.user_doc_id, post.author_ref)
    ? currentUser
    : await handleRetrieveAuthorDetails(post.author_ref, employeesData);
  const details = getDetailsData(author, currentUser);
  const org_ref = getOrgRef(currentUser, selectedOrg);
  const likeState = await handleGetFavoriteStateOfPost(
    org_ref,
    currentUser,
    post
  );
  const signCount = await getSignCount(org_ref, post.post_id);
  const userSignature = await getUserSignature(
    `orgs/${org_ref}/community/${post.post_id}/signs`,
    currentUser.user_doc_id
  );
  const userSignedPhrase = getUserSignedPhrase(userSignature, currentUser);
  // setPostDetails({
  //   ...postDetails,
  //   author: details,
  //   favorite: likeState,
  //   signCount,
  //   userSignature
  // });
  return {
    ...postDetails,
    ...post,
    author: details,
    favorite: likeState,
    signCount,
    userSignature,
    userSignedPhrase
  };
};

export default handleRetrievePostDetails;
