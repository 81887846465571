import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

const PracticesContext = createContext();

export const PracticesProvider = ({ children }) => {
  PracticesProvider.propTypes = {
    children: PropTypes.any
  };
  const [practices, setPractices] = useState();
  const [foldersOptions, setFoldersOptions] = useState();

  const practicesData = useMemo(
    () => ({ practices, setPractices, foldersOptions, setFoldersOptions }),
    [practices, setPractices, foldersOptions, setFoldersOptions]
  );

  return (
    <PracticesContext.Provider value={practicesData}>
      {children}
    </PracticesContext.Provider>
  );
};
export const usePractices = () => useContext(PracticesContext);
