const getQuestionData = (globalCoursewares, courseware, indexOfSlide) => {
  let data = {};
  const indexOfCourseware = globalCoursewares.findIndex(
    (el) => el.courseware_id === courseware.courseware_id
  );
  if (indexOfCourseware > -1) {
    if (globalCoursewares[indexOfCourseware].questionsSlides) {
      const indexOfQuestion = globalCoursewares[
        indexOfCourseware
      ].questionsSlides.findIndex(
        (question) => question.slideIndex === indexOfSlide
      );
      if (indexOfQuestion > -1) {
        const { questionId, questionTitle, answers, showNumbering } =
          globalCoursewares[indexOfCourseware].questionsSlides[indexOfQuestion];
        data = {
          title: questionTitle,
          answers,
          questionId,
          showNumbering
        };
      }
    }
  }
  return data;
};

export default getQuestionData;
