import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  EditOutlined
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import cardPic from 'src/assets/moreToRead/process.webp';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import marketplaceCategories from './marketplaceCategories';

const ProductCard = ({
  product,
  handleOpenVerifyPurchaseRequest,
  handleOpenAddProduct,
  handleBookmarkProduct,
  marketplaceView
}) => {
  ProductCard.propTypes = {
    product: PropTypes.object,
    handleOpenVerifyPurchaseRequest: PropTypes.func,
    handleOpenAddProduct: PropTypes.func,
    handleBookmarkProduct: PropTypes.func,
    marketplaceView: PropTypes.string
  };
  const { currentUser } = useCurrentUserContext();

  const [isLabelExpanded, setIsLabelExpanded] = useState(false);

  const getLabelOfMarketplaceProduct = () => {
    const { category } = product;
    let productLabel;
    if (category.length > 0) {
      const [first] = category;
      const index = marketplaceCategories.findIndex(
        (el) => el.value === first.value
      );
      if (index > -1) {
        productLabel = marketplaceCategories[index].label;
      } else {
        // console.log(first);
        if (first.inputValue) {
          productLabel = first.inputValue;
        } else {
          productLabel = first.value;
        }
      }
      return category.length > 1
        ? `${productLabel} + ${category.length - 1}`
        : `${productLabel}`;
    }
  };

  const getAllLabelsOfProduct = () => {
    const { category } = product;
    const mapped = category.map((cat) => {
      const index = marketplaceCategories.findIndex(
        (el) => el.value === cat.value
      );

      if (index > -1) {
        return marketplaceCategories[index].label;
      }
      if (cat.inputValue) {
        return cat.inputValue;
      }
      return cat.value;
    });
    const joined = mapped.join(',');
    return joined;
  };
  const isMyProduct = currentUser.user_doc_id === product.seller_id;
  const purchaseDisabled = () =>
    !currentUser ||
    (currentUser &&
      Number(product.product_price) > Number(currentUser.diamonds));

  const getBorderStatus = () => {
    if (isMyProduct && marketplaceView === 'myProducts') {
      const { published } = product;
      if (published) {
        return `1px solid ${theme.palette.success.light}`;
      }
      return `1px solid ${theme.palette.warning.light}`;
    }
    return 'none';
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      xl={3}
      key={`${product.seller_id}-product${product.product_id}`}
    >
      <Card
        elevation={isMyProduct ? 4 : 2}
        sx={{
          position: 'relative',
          borderTop: getBorderStatus()
          // outline: isMyProduct && `2px solid ${theme.palette.success.light}`
        }}
      >
        {product.category && product.category.length > 0 && (
          <Chip
            sx={{
              position: 'absolute',
              right: 10,
              top: 140,
              minWidth: 100,
              '& .MuiChip-label': {
                textOverflow: !isLabelExpanded && 'ellipsis',
                width: '100%',
                overflow: 'hidden',
                whiteSpace: !isLabelExpanded ? 'nowrap' : 'normal',
                wordWrap: 'break-word',
                textAlign: 'center'
              }
            }}
            clickable
            onClick={() => setIsLabelExpanded(!isLabelExpanded)}
            variant="filled"
            color="warning"
            label={
              isLabelExpanded
                ? getAllLabelsOfProduct()
                : getLabelOfMarketplaceProduct()
            }
          />
        )}
        <Tooltip title="שמירת פריט">
          <IconButton
            onClick={() => handleBookmarkProduct(product.product_id)}
            sx={{ position: 'absolute', zIndex: 2, left: 5, top: 5 }}
          >
            {product.bookmarked ? (
              <BookmarkOutlined color="error" />
            ) : (
              <BookmarkBorderOutlined color="action" />
            )}
          </IconButton>
        </Tooltip>
        <CardMedia
          sx={{ height: 140, backgroundSize: 'contain', my: 1 }}
          image={product.preview || cardPic} // TODO:: handle preview
          title={product.product_name}
        />
        <Divider />
        <CardContent>
          <Tooltip title={product.product_name}>
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordWrap: 'break-word'
              }}
              gutterBottom
              variant="h5"
              component="div"
            >
              {product.product_name}
            </Typography>
          </Tooltip>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              height: 50,
              textOverflow: 'ellipsis',
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              wordWrap: 'break-word'
            }}
          >
            {product.description}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack sx={{ alignItems: 'center' }}>
              <Typography variant="h6" color="primary" fontWeight={600}>
                {product.product_price}
              </Typography>
              <Typography color="primary" variant="subtitle2" fontWeight={600}>
                יהלומים
              </Typography>
            </Stack>
            <Stack sx={{ alignItems: 'center' }}>
              <Typography color="primary" variant="h6">
                {product.num_of_purchases || 0}
              </Typography>
              <Typography color="primary" variant="subtitle2">
                רכשו
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {isMyProduct ? (
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{ maxWidth: 160 }}
              onClick={() => handleOpenAddProduct(product)}
              endIcon={<EditOutlined />}
            >
              עריכה
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={Boolean(purchaseDisabled())}
              sx={{ maxWidth: 160 }}
              onClick={() => handleOpenVerifyPurchaseRequest(product)}
            >
              רכישה
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ProductCard;
