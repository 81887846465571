import caregiverDefaultOnboarding from './caregiverDefaultOnboarding';
import dietitianDefaultOnboarding from './dietitian';
import doctorDefaultOnboarding from './doctorDefaultOnboarding';
import nursesDefaultOnboarding from './nursesDefaultOnboarding';
import occupationalTherapist from './occupationalTherapist';
import physicalTherapist from './physio';
import socialWorkerDefaultOnboarding from './socialWorker';

const defaultProcesses = {
  nurse: nursesDefaultOnboarding,
  doctor: doctorDefaultOnboarding,
  physicalTherapist,
  socialWorker: socialWorkerDefaultOnboarding,
  dietitian: dietitianDefaultOnboarding,
  occupationalTherapist,
  caregiver: caregiverDefaultOnboarding
};

export default defaultProcesses;
