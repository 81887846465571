import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// import marketing from 'src/assets/icons/services/marketing.webp';
import { uniqueId } from 'lodash';
import Lottie from 'lottie-react';
import consultation from 'src/assets/services/consultation-flat.json';
import heart from 'src/assets/services/heart-beat.json';
import lighthouse from 'src/assets/services/lighthouse.json';
import marketing from 'src/assets/services/marketing.json';
import suitcase from 'src/assets/services/suitcase.json';
import i18n from 'src/i18n';
import routesConst from 'src/routesConst';
import theme from 'src/theme';
import './home.css';

const servicesData = [
  {
    icon: consultation,
    id: 1,
    name: i18n.t('mainPage.services.firstService'),
    text: i18n.t('mainPage.services.firstServiceDescription'),
    textBg: '#4ab3fc',
    btn: (
      <Button
        sx={{
          backgroundImage: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
          color: theme.palette.primary.contrastText,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            px: 2,
            boxShadow: theme.shadows[4],
            transition: 'all 0.3s'
          },
          minWidth: 120,
          fontSize: '1.2rem'
        }}
        href="tel:0733752320"
        variant="outlined"
      >
        ייעוץ ללא עלות
      </Button>
    )
  },
  {
    icon: heart,
    id: 2,
    name: i18n.t('mainPage.services.secondService'),
    text: i18n.t('mainPage.services.secondServiceDescription'),
    textBg: '#d09ae3',
    btn: (
      <Button
        sx={{
          backgroundImage: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
          color: theme.palette.primary.contrastText,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            px: 2,
            boxShadow: theme.shadows[4],
            transition: 'all 0.3s'
          },
          minWidth: 120,
          fontSize: '1.2rem'
        }}
        href={`${routesConst.medicalAssessment}`}
        variant="outlined"
      >
        לצוות המומחים שלנו
      </Button>
    )
  },
  {
    icon: suitcase,
    id: 3,
    name: i18n.t('mainPage.services.thirdService'),
    text: i18n.t('mainPage.services.thirdServiceDescription'),
    textBg: '#ffc638',
    btn: (
      <Button
        sx={{
          backgroundImage: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
          color: theme.palette.primary.contrastText,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            px: 2,
            boxShadow: theme.shadows[4],
            transition: 'all 0.3s'
          },
          minWidth: 120,
          fontSize: '1.2rem'
        }}
        href={`${routesConst.register}?type=personal`}
        variant="outlined"
      >
        הצטרף עכשיו
      </Button>
    )
  },
  {
    icon: marketing,
    id: 4,
    name: i18n.t('mainPage.services.fourthService'),
    text: i18n.t('mainPage.services.fourthServiceDescription'),
    textBg: '#9dbe41',
    btn: (
      <Button
        sx={{
          backgroundImage: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
          color: theme.palette.primary.contrastText,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            px: 2,
            boxShadow: theme.shadows[4],
            transition: 'all 0.3s'
          },
          minWidth: 120,
          fontSize: '1.2rem'
        }}
        variant="outlined"
        href={`https://api.whatsapp.com/send/?phone=972557111106&text=${encodeURIComponent(
          'שלום, אשמח לקבל עזרה והמלצות אודות חיפוש בית אבות ודיור מוגן מתאים ומקצועי'
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        התחל ייעוץ ארגוני
      </Button>
    )
  },
  {
    icon: lighthouse,
    id: 5,
    name: i18n.t('mainPage.services.fifthService'),
    text: i18n.t('mainPage.services.fifthServiceDescription'),
    textBg: '#c24650',
    btn: (
      <Button
        sx={{
          backgroundImage: 'linear-gradient(195deg, #49a3f1, #1A73E8)',
          color: theme.palette.primary.contrastText,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            px: 2,
            boxShadow: theme.shadows[4],
            transition: 'all 0.3s'
          },
          minWidth: 120,
          fontSize: '1.2rem'
        }}
        href={`${routesConst.contact}`}
        variant="outlined"
      >
        נסו וגלו
      </Button>
    )
  }
];

const Services = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage.services'
  });

  return (
    <Stack>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          flexDirection: 'column',
          alignItems: 'center',
          pb: 2,
          '&::after': {
            position: 'absolute',
            height: '4px',
            bgcolor: theme.palette.primary.dark,
            width: '60px',
            // marginLeft: '-45px',
            // left: '50%',
            mt: theme.breakpoints.down('md') && -3,
            content: "''"
          }
        }}
        item
        xs={12}
      >
        <Typography
          variant="h1"
          // className="subTitle"
          sx={{
            display: 'flex'
            // px: 0
          }}
        >
          {t('services')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ pt: 3, fontFamily: 'inherit', fontSize: '1.2rem', px: 2 }}
        >
          {t('servicesThatWeProvide')}
        </Typography>
      </Grid>
      <Grid
        sx={{ flexWrap: 'wrap', justifyContent: 'center', p: 3 }}
        container
        spacing={3}
      >
        {servicesData.map((service, i) => (
          <Slide
            key={`${service.id}-${uniqueId('service')}`}
            in
            direction={i % 2 === 0 ? 'left' : 'right'}
            timeout={1000}
          >
            <Grid
              sx={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
              item
              xs={12}
              md={5}
              key={service.id}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  // transform: 'translate(10px,10%)',
                  position: 'relative',
                  zIndex: 2,
                  py: 1,
                  px: 2,
                  color: theme.palette.primary.contrastText,
                  borderRadius: 1,
                  boxShadow: theme.shadows[2],
                  background: service.textBg
                }}
                variant="h6"
                component={service.href ? 'a' : 'span'}
                // href={service.href && service.href}
              >
                {service.name}
              </Typography>
              <Card
                elevation={0}
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  bgcolor: theme.palette.background.default,
                  '&:hover': {
                    boxShadow: theme.shadows[16],
                    transition: 'all 0.15s linear',
                    transform: 'scaleY(1.05)'
                  },
                  border: 'unset',
                  // boxShadow: theme.shadows[8],
                  overflow: 'auto'
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Lottie
                    style={{
                      width: '60% !important',
                      height: '70px',
                      minWidth: '60px',
                      margin: 'auto',
                      marginBottom: 0,
                      marginTop: '24px'
                    }}
                    autoplay
                    animationData={service.icon}
                    loop={i === servicesData.length - 1 || 5}
                  />
                  <Typography
                    variant="h6"
                    className="description"
                    sx={{
                      textAlign: 'justify',
                      fontFamily: 'inherit',
                      fontSize: '20px',
                      color: 'inherit'
                    }}
                  >
                    {service.text}
                  </Typography>
                </CardContent>
                {service.btn && (
                  <CardActions sx={{ justifyContent: 'center', width: '100%' }}>
                    {service.btn}
                  </CardActions>
                )}
              </Card>
            </Grid>
          </Slide>
        ))}
      </Grid>
    </Stack>
  );
};
export default Services;
