import {
  ArrowBackOutlined,
  BusinessOutlined,
  InfoOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  Icon,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import useTheme from '@mui/styles/useTheme';
import { useEffect, useState } from 'react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import OrgRegisterForm from 'src/components/Register/OrgRegisterForm';
import PersonalRegisterForm from 'src/components/Register/PersonalRegisterForm';
import VerifyUser from 'src/components/Register/VerifyUser/VerifyUserDialog';
import sendVerificationSMS from 'src/utils/sendVerificationSMS';
// import { sendVerificationSMS } from 'src/utils/sendVerificationSMS';
// import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { auth } from 'src/_firebase/firebase';

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const types = [
  { title: 'org', icon: BusinessOutlined },
  { title: 'personal', icon: PersonOutlineOutlined }
];
const Register = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const theme = useTheme();
  const [userType, setUserType] = useState();
  const location = useLocation();
  const initialView = 'firstStep';
  const [activeView, setActiveView] = useState(initialView);
  const [createUserWithEmailAndPassword, user, loading] =
    useCreateUserWithEmailAndPassword(auth);
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [newUserValues, setNewUserValues] = useState();

  const [minimalMapAutoCompleteOpen, setMinimalMapAutoCompleteOpen] =
    useState(false);
  // TODO:: validate user and org id that it does not exist

  const handleOpenVerifyCodeDialog = async (values, setLoading) => {
    setNewUserValues(values);
    const { phone } = values;
    await sendVerificationSMS(`+972${phone}`)
      .then(() => {
        setVerifyDialogOpen(true);
      })
      .catch((err) => {
        console.log('err while sending verification sms', err);
        setVerifyDialogOpen(true);
      });
    // this disables the register btn for personal
    // if (setLoading) {
    //   setLoading(false);
    // }
  };
  const handleCloseVerifyCodeDialog = () => {
    setVerifyDialogOpen(false);
  };

  /**
   *
   * @param {} type : Object that cantains title field
   */
  const handleChangeUserType = (type) => {
    const { title } = type;
    setUserType(title);
    if (title === 'org') {
      setActiveView(initialView);
    }
  };

  useEffect(() => {
    if (location.pathname) {
      const splitted = location.pathname.split('/');
      // console.log(splitted);
      if (splitted.length > 2) {
        const referralDocRaw = splitted[2];
        const referralIdRaw = splitted[4];
        const referralDoc = referralDocRaw.split('=')[1];
        const referralId = referralIdRaw.split('=')[1];
        const referralData = JSON.stringify({
          referralDoc,
          referralId
        });
        window.sessionStorage.setItem('referralData', referralData);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (searchParams) {
      const typeTitle = searchParams.get('type');
      // console.log(typeTitle);
      handleChangeUserType({ title: typeTitle });
    }
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>{t('register')}</title>
        <meta
          name="description"
          content="הרשמה למערכת מגדלור ייעוץ והכוונה - מנוע החיפוש וההשוואה של בתי האבות והדיור המוגן בישראל"
        />
        <meta name="robots" content="index" />
        <meta name="googlebot" content="index, follow" />
        <link rel="canonical" href="https://www.mgdalor.com/register" />
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: 'max-height',
          justifyContent: 'center',
          pt: '64px'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            hieght: !userType ? '60vh' : '100vh',
            my: '64px',
            pb: '64px',
            boxShadow: (defaultTheme) => defaultTheme.shadows[6],
            bgColor: (dtheme) => dtheme.palette.divider,
            borderRadius: 1,
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <Stack justifyContent="space-between" direction="row">
            <Box sx={{ mt: 4 }}>
              <Typography color="textPrimary" variant="h2">
                {t('createYourAccount')}
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                {t('useYourDetails')}
              </Typography>
            </Box>
            {userType === 'org' && activeView === 'secondStep' && (
              <Box
                sx={{
                  mt: 4,
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography color="textPrimary" variant="h2">
                  {`${t('step')} 2/2`}
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  component={Link}
                  variant="body2"
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1
                  }}
                  onClick={() => setActiveView('firstStep')}
                >
                  {t('back')}
                  <ArrowBackOutlined fontSize="small" />
                </Typography>
              </Box>
            )}
          </Stack>
          <Grid
            container
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              margin: 'auto',
              mb: 4
            }}
            spacing={2}
          >
            {types.map((type) => (
              <Grid key={type.title} item xs={4}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 1,
                    boxShadow:
                      userType === type.title
                        ? theme.shadows[14]
                        : theme.shadows[6],
                    pb: 4,
                    backgroundColor:
                      userType === type.title
                        ? theme.palette.primary.main
                        : theme.palette.background.default,
                    border:
                      userType === type.title
                        ? `1px solid ${theme.palette.primary.main}`
                        : 'none',
                    color:
                      userType === type.title
                        ? theme.palette.primary.contrastText
                        : theme.palette.primary.main,
                    transition: 'all ease-in 0.3s',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: theme.shadows[12]
                    }
                  }}
                  onClick={() => handleChangeUserType(type)}
                >
                  <CardHeader
                    sx={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                      width: '100%'
                    }}
                    action={
                      <Tooltip
                        title={t(
                          `infoAbout${
                            type.title.charAt(0).toUpperCase() +
                            type.title.slice(1)
                          }`
                        )}
                      >
                        <Icon>
                          <InfoOutlined />
                        </Icon>
                      </Tooltip>
                    }
                  />
                  <Typography pb={1} variant="h5">
                    {t(type.title)}
                  </Typography>
                  <Icon fontSize="large">
                    {/* <BusinessOutlined fontSize="inherit" /> */}
                    <type.icon />
                  </Icon>
                </Card>
              </Grid>
            ))}
          </Grid>
          {userType && userType === 'personal' ? (
            <PersonalRegisterForm
              loading={loading}
              handleOpenVerifyCodeDialog={handleOpenVerifyCodeDialog}
              // handleCreateNewPersonalAccount={handleCreateNewPersonalAccount}
            />
          ) : userType && userType === 'org' ? (
            <OrgRegisterForm
              loading={loading}
              activeView={activeView}
              setActiveView={setActiveView}
              handleOpenVerifyCodeDialog={handleOpenVerifyCodeDialog}
              minimalMapAutoCompleteOpen={minimalMapAutoCompleteOpen}
              setMinimalMapAutoCompleteOpen={setMinimalMapAutoCompleteOpen}
            />
          ) : null}
          {verifyDialogOpen && (
            <VerifyUser
              closeDialog={handleCloseVerifyCodeDialog}
              isOpen={verifyDialogOpen}
              newUserValues={newUserValues}
              userType={userType}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Register;
