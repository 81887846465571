import {
  Backdrop,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grow,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper
} from '@mui/material';
import useTheme from '@mui/styles/useTheme';
import PropTypes from 'prop-types';
import { useState } from 'react';
import israelIcon from 'src/assets/israel.png';
import usaIcon from 'src/assets/usa.png';
import i18n from 'src/i18n';

const LanguagePopperMenu = ({ languageMenuAnchor, setLanguageMenuAnchor }) => {
  LanguagePopperMenu.propTypes = {
    languageMenuAnchor: PropTypes.any,
    setLanguageMenuAnchor: PropTypes.func
  };
  const [isLoading, setLoading] = useState();
  const theme = useTheme();
  const hanleClickAwayOfLanguagePopper = () => {
    setLanguageMenuAnchor(null);
  };

  const changeLanguage = (lng) => {
    setLoading(true);
    localStorage.setItem('lang', lng);
    i18n
      .changeLanguage(lng)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    if (lng === 'he' || lng === 'ar') {
      theme.direction = 'rtl';
      document.dir = 'rtl';
    } else {
      theme.direction = 'ltr';
      document.dir = 'ltr';
    }
    hanleClickAwayOfLanguagePopper();
    // window.location.reload();
  };
  return (
    <>
      <Popper
        anchorEl={languageMenuAnchor}
        popperRef={null}
        open={Boolean(languageMenuAnchor)}
        placement="right-start"
        transition
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={hanleClickAwayOfLanguagePopper}>
            <Grow
              {...TransitionProps}
              sx={{
                borderRadius: 2,
                background: 'white',
                boxShadow: theme.shadows[10]
              }}
            >
              <List>
                <ListItemButton
                  sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                  py={1.5}
                  onClick={() => changeLanguage('he')}
                >
                  <ListItemText primary="עברית" />
                  <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    {/* <Icon> */}
                    <img
                      src={israelIcon}
                      loading="lazy"
                      height={20}
                      alt="hebrew lang icon"
                    />
                    {/* </Icon> */}
                  </ListItemIcon>
                </ListItemButton>
                <Divider />

                <ListItemButton
                  sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                  py={1.5}
                  onClick={() => changeLanguage('en')}
                >
                  <ListItemText primary="English" />
                  <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <img
                      src={usaIcon}
                      loading="lazy"
                      alt="English lang icon"
                      height={20}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </List>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
      {isLoading && (
        <Backdrop in={isLoading} open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default LanguagePopperMenu;
