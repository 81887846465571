import getVisibileSidebarChildrenForEmployee from 'src/utils/getVisibileSidebarChildrenForEmployee';
import isAuthorized from 'src/utils/isAuthorized';
import renderOrgNavItems from 'src/utils/renderOrgNavItems';
import renderPersonalNavItems from 'src/utils/renderPersonalNavItems';

const getSidebarItems = (user, selectedOrg) => {
  if (user && user.type === 'org') {
    const orgItems = renderOrgNavItems();
    return orgItems;
  }
  if (user && user.type === 'personal' && selectedOrg) {
    const visibleEmployeesChildren = getVisibileSidebarChildrenForEmployee(
      user,
      selectedOrg
    );
    const ticketVisibile = isAuthorized(user, 'tickets:read', selectedOrg);
    const personalItems = renderPersonalNavItems(
      visibleEmployeesChildren,
      ticketVisibile
    );
    return personalItems;
  }
  if (user && user.type === 'personal' && !selectedOrg) {
    const personalItems = renderPersonalNavItems();
    return personalItems;
  }
};

export default getSidebarItems;
