import { doc, getDoc } from '@firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { db, functions } from 'src/_firebase/firebase';
import commonConfig from 'src/common/config';
import getOrgRef from 'src/utils/getOrgRef';
import { useSelectedOrg } from '../useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const StorageContext = createContext();

export const StorageProvider = ({ children }) => {
  StorageProvider.propTypes = {
    children: PropTypes.any
  };
  const [userStorage, setUserStorage] = useState();
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  // const { globalCoursewares } = useGlobalCoursewares();

  const calcUserStorage = async (user_doc_id) => {
    const calculateStorageUsage = httpsCallable(
      functions,
      'calculateStorageUsage'
    );
    return calculateStorageUsage({ user_doc_id })
      .then((res) => {
        const { storage_usage, max_storage } = res.data;
        if (storage_usage) {
          // const { usage } = storage_usage;
          // setUserStorage(storage_usage);
          return { storage_usage, max_storage };
        }
        return {
          storage_usage: commonConfig.maxStorage,
          max_storage: commonConfig.maxStorage
        };
      })
      .catch((err) => {
        console.log(err);
        return {
          storage_usage: commonConfig.maxStorage,
          max_storage: commonConfig.maxStorage
        };
      });
  };

  const getOrgStorage = async (user_doc_id) => {
    if (user_doc_id) {
      const orgRef = doc(db, `orgs/${user_doc_id}`);
      getDoc(orgRef)
        .then(async (orgData) => {
          if (orgData.exists()) {
            const { storage_usage, max_storage } = orgData.data();
            if (storage_usage && max_storage) {
              setUserStorage({ ...userStorage, storage_usage, max_storage });
            }
            if (!max_storage || !storage_usage) {
              const calculated_storage = await calcUserStorage(user_doc_id);
              // then save it to db
              console.log(calculated_storage);
              setUserStorage(calculated_storage);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // setUserStorage(11111110);
        });
    }
  };

  useEffect(() => {
    if (currentUser && !userStorage) {
      const org_ref = getOrgRef(currentUser, selectedOrg);
      getOrgStorage(org_ref);
    }
  }, [currentUser, selectedOrg, userStorage]);

  const currentUserStorage = useMemo(
    () => ({ userStorage, setUserStorage }),
    [userStorage, setUserStorage]
  );
  return (
    <StorageContext.Provider value={currentUserStorage}>
      {children}
    </StorageContext.Provider>
  );
};
export const useUserStorage = () => useContext(StorageContext);
