const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
const apiKey = process.env.REACT_APP_GMAPS_APIKEY;

const reverseGeocode = async (url, lat, lng) =>
  fetch(url)
    .then((response) => response.json())
    .then((location) => {
      const place = location.results[0];
      return place;
    });

const getCityAndDistrictOfOption = async (home) => {
  const {
    _geoloc: { lng, lat }
  } = home;

  let localizedUrl;
  const selectedLang = localStorage.getItem('lang');

  if (selectedLang === 'en') {
    localizedUrl = `${geocodeJson}?key=${apiKey}&language=en&latlng=${lat},${lng}`;
  } else {
    localizedUrl = `${geocodeJson}?key=${apiKey}&language=he&latlng=${lat},${lng}`;
  }

  const localizedCityAndDistrict = await reverseGeocode(localizedUrl, lat, lng)
    .then((geocodedPlace) => geocodedPlace.formatted_address)
    .catch((err) => console.log(err));
  // console.log(localizedCityAndDistrict);
  return localizedCityAndDistrict;
};

export default getCityAndDistrictOfOption;
