import { Box, CardMedia, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import flower from 'src/assets/orgPage/ffflower.svg';
import theme from 'src/theme';

const OrgImgArc = ({ imgUrl }) => {
  OrgImgArc.propTypes = {
    imgUrl: PropTypes.string.isRequired
  };
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box sx={{ position: 'relative', width: 'fit-content', m: 'auto' }}>
      <CardMedia
        className="img"
        sx={{
          zIndex: '2 !important',
          objectFit: 'cover',
          borderRadius: '25%',
          width: '40vw',
          maxWidth: '400px',
          minWidth: '250px',
          height: matchDownMd ? '300px' : '500px',
          boxShadow: theme.shadows[12],
          border: `2px dashed ${theme.palette.grey[400]}`,
          mx: 'auto',
          position: 'relative'
        }}
        src={imgUrl}
        component="img"
        alt="circular img pic"
      />
      <CardMedia
        component="img"
        src={flower}
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: -3,
          left: -3,
          width: '60px',
          height: '60px',
          filter: 'opacity(0.8)',
          transform: 'scaleX(-1) rotate(-15deg)'
          //   mx: 'auto'
        }}
      />
    </Box>
  );
};

export default OrgImgArc;
