import {
    ContactPhoneOutlined,
    EditOutlined,
    Person2Outlined
} from '@mui/icons-material';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PersonalAssessmentRequestSummary = ({ setActiveStep, requestValues }) => {
  PersonalAssessmentRequestSummary.propTypes = {
    setActiveStep: PropTypes.func,
    requestValues: PropTypes.object
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });

  return (
    <Grid item xs={12} spacing={2} container>
      <Grid item xs={12}>
        <Card elevation={6}>
          <CardHeader
            title="פרטים כלליים"
            action={
              <Button
                variant="outlined"
                color="warning"
                size="small"
                endIcon={<EditOutlined />}
                onClick={() => setActiveStep(0)}
              >
                עריכה
              </Button>
            }
          />
          <CardContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Person2Outlined color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={`${requestValues.patient_first_name} ${requestValues.patient_last_name}`}
                  secondary="שם פרטי ושם משפחה"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ContactPhoneOutlined color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={requestValues.phone}
                  secondary="טלפון איש קשר"
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={6}>
          <CardHeader
            title="תיאור מצב המטופל"
            action={
              <Button
                variant="outlined"
                color="warning"
                size="small"
                endIcon={<EditOutlined />}
                onClick={() => setActiveStep(1)}
              >
                עריכה
              </Button>
            }
          />
          <CardContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Person2Outlined color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={requestValues.description}
                  secondary="תיאור הפנייה"
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PersonalAssessmentRequestSummary;
