import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PersonalAssessmentRequestDetails = ({ handleChange, requestValues }) => {
  PersonalAssessmentRequestDetails.propTypes = {
    handleChange: PropTypes.func,
    requestValues: PropTypes.object
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });
  return (
    <>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          fullWidth
          label={t('patientFirstName')}
          name="patient_first_name"
          required
          onChange={(e) => {
            handleChange(e);
          }}
          value={requestValues.patient_first_name}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          fullWidth
          required
          label={t('patientLastName')}
          name="patient_last_name"
          onChange={(e) => {
            handleChange(e);
          }}
          value={requestValues.patient_last_name}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          required
          label={t('contactPhone')}
          name="phone"
          onChange={(e) => {
            const replaced = e.target.value.replace(/[^\d]+/g, '');
            // console.log(replaced);
            if (replaced) {
              handleChange(e);
            }
          }}
          type="phone"
          value={requestValues.phone}
          variant="outlined"
        />
      </Grid>
    </>
  );
};

export default PersonalAssessmentRequestDetails;
