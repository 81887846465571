import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import kosherOptions from 'src/common/kosherOptions';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const KosherCompare = ({ StyledTableCell, list, userscrolledhorizontally }) => {
  KosherCompare.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.object,
    userscrolledhorizontally: PropTypes.bool
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="kosher" />
        {t('kosher')}
      </StyledTableCell>
      {list.names && (
        <>
          {list.kosher
            ? list.kosher.map((kosherType, i) => {
                if (kosherType) {
                  // console.log(kosherType, kosherOptions);
                  const indexOfKosherInCommon = kosherOptions.findIndex(
                    (el) => el.name === kosherType
                  );
                  if (indexOfKosherInCommon > -1 && kosherType) {
                    const index = kosherOptions.findIndex(
                      (el) => el.name === kosherType
                    );
                    // console.log(kosherOptions[index].label);
                    return (
                      <StyledTableCell
                        key={`kosher-${kosherType}-${uniqueId()}`}
                        align="center"
                      >
                        {kosherOptions[index].label}
                      </StyledTableCell>
                    );
                  }
                  return (
                    <StyledTableCell
                      key={`no-data-${list.names[i]}-${uniqueId()}`}
                      align="center"
                    >
                      {t('noData')}
                    </StyledTableCell>
                  );
                }
                return (
                  <StyledTableCell
                    key={`no-data-${list.names[i]}-${uniqueId()}`}
                    align="center"
                  >
                    {t('noData')}
                  </StyledTableCell>
                );
              })
            : list.names.map((name) => (
                <StyledTableCell key={`no-data-${name}`} align="center">
                  {t('noData')}
                </StyledTableCell>
              ))}
        </>
      )}
    </>
  );
};

export default KosherCompare;
