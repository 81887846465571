import ViewSDKClient from './ViewSDKClient';

const loadPdf = async (
  fileUrl,
  fileContainerId = 'pdfPracticeViewer',
  additionalConfig = {},
  embedMode
) => {
  //   console.log(additionalConfig);
  const viewSDKClient = new ViewSDKClient();
  return viewSDKClient
    .ready()
    .then(() => {
      viewSDKClient.previewFile(
        fileContainerId,
        {
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: true,
          showDownloadPDF: true,
          showPrintPDF: false,
          commentsPanel: false,
          showDisabledSaveButton: false,
          ...additionalConfig
        },
        fileUrl,
        embedMode
      );
      return 'viewed';
    })
    .catch((err) => {
      console.log(err);
      return 'error';
    });
};

export default loadPdf;
