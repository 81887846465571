import routesConst from 'src/routesConst';
import isAuthorized from './isAuthorized';

const getVisibileSidebarChildrenForEmployee = (userValues, selectedOrg) => {
  // console.log(userValues.employee_work_places); // available
  const visibleEmployeesChildren = [
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.onboarding}`,
      title: 'הגדרת תהליך קליטה',
      isVisible: isAuthorized(
        userValues,
        'employees:onboarding-management',
        selectedOrg
      ),
      isChild: true
    },
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.departments}`,
      title: 'ניהול מחלקות',
      isVisible: isAuthorized(userValues, 'departments:read', selectedOrg),
      isChild: true
    },
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.roles}`,
      title: 'ניהול הרשאות',
      isVisible: isAuthorized(userValues, 'roles:read', selectedOrg),
      isChild: true
    },
    {
      href: `/${routesConst.app}/${routesConst.user}/${routesConst.employees}/${routesConst.evaluation}`,
      title: 'ניהול משובי עובדים',
      isVisible: isAuthorized(userValues, 'evaluations:read', selectedOrg),
      isChild: true
    }
  ];
  const tabsToShow = visibleEmployeesChildren.filter(
    (child) => child.isVisible
  );
  return tabsToShow.length > 0 ? tabsToShow : null;
};

export default getVisibileSidebarChildrenForEmployee;
