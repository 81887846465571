export default {
  h1: {
    fontWeight: 500,
    fontSize: 40,
    letterSpacing: '-0.24px',
    fontFamily: 'inherit'
  },
  h2: {
    fontWeight: 500,
    fontSize: 36,
    letterSpacing: '-0.24px',
    fontFamily: 'inherit'
  },
  h3: {
    fontWeight: 500,
    fontSize: 32,
    letterSpacing: '-0.06px',
    fontFamily: 'inherit'
  },
  h4: {
    fontWeight: 500,
    fontSize: 28,
    letterSpacing: '-0.06px',
    fontFamily: 'inherit'
  },
  h5: {
    fontWeight: 500,
    fontSize: 25,
    letterSpacing: '-0.05px',
    fontFamily: 'inherit'
  },
  h6: {
    fontWeight: 500,
    fontSize: 21,
    letterSpacing: '-0.05px',
    fontFamily: 'inherit'
  },
  overline: {
    fontWeight: 500,
    fontFamily: 'inherit'
  },
  '*': {
    fontFamily: 'inherit'
  },
  caption: {
    fontFamily: 'inherit'
  },
  body1: {
    fontFamily: 'inherit'
  },
  body2: {
    fontFamily: 'inherit'
  },
  subtitle1: {
    fontFamily: 'inherit'
  },
  subtitle2: {
    fontFamily: 'inherit'
  },
  inherit: {
    fontFamily: 'inherit'
  },
  button: {
    fontFamily: 'inherit'
  }
};
