import PropTypes from 'prop-types';
import './gradientButton.scss';

const GradientButton = ({ mainLabel }) => {
  GradientButton.propTypes = {
    mainLabel: PropTypes.string
  };

  return (
    <div className="contactContainer">
      <a href="#contact" className="btn">
        <svg width="277" height="62">
          <defs>
            <linearGradient id="grad1">
              <stop offset="0%" stopColor="#FF8282" />
              <stop offset="100%" stopColor="#E178ED" />
            </linearGradient>
          </defs>
          <rect
            x="5"
            y="5"
            rx="25"
            fill="none"
            stroke="url(#grad1)"
            width="266"
            height="50"
          />
        </svg>
        <span>{mainLabel || 'דברו איתנו'}</span>
      </a>
    </div>
  );
};

export default GradientButton;
