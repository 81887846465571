import { DeleteForeverOutlined } from '@mui/icons-material';
import { Divider, Icon, Stack, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  width: 'fit-content'
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  flex: '1 1 100%',
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};

const UploadInput = ({
  files,
  setFiles,
  multiple,
  accept,
  previewThumbs,
  secondary,
  callbackFunc
}) => {
  UploadInput.propTypes = {
    files: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setFiles: PropTypes.func,
    multiple: PropTypes.bool,
    accept: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    previewThumbs: PropTypes.bool,
    secondary: PropTypes.string,
    callbackFunc: PropTypes.func
  };
  UploadInput.defaultProps = {
    multiple: true,
    accept: {
      'image/*': []
    },
    previewThumbs: true
  };
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: true,
    onDrop: (acceptedFiles) => {
      if (multiple) {
        if (files) {
          setFiles([
            ...files,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                id: uniqueId('org-img-to-upload')
              })
            )
          ]);
        } else {
          setFiles([
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                id: uniqueId('org-img-to-upload')
              })
            )
          ]);
        }
        if (callbackFunc) {
          callbackFunc(files);
        }
      } else {
        setFiles(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
            id: uniqueId('file-to-upload'),
            file: acceptedFiles[0]
          })
        );

        if (callbackFunc) {
          callbackFunc(files);
        }
      }
    },
    maxFiles: 20 * 1024 * 1024
  });

  const thumbs =
    previewThumbs &&
    files &&
    files.length > 0 &&
    files.map((file) => (
      <div style={thumb} key={`${file.id}-${uniqueId()}`}>
        <div
          style={{
            ...thumbInner,
            backgroundImage: `url(${file.preview})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Icon
            sx={{
              display: 'inline-flex',
              bgcolor: theme.palette.action.disabledBackground,
              cursor: 'pointer',
              borderRadius: '4px',
              '&:hover': {
                transform: 'scale(1.05)'
              }
            }}
            onClick={() => {
              const index = files.findIndex((el) => el.id === file.id);
              const temp = [...files];
              if (index === 0) {
                temp.shift();
              } else if (index === temp.length - 1) {
                temp.pop();
              } else {
                temp.splice(index, 1);
              }
              setFiles(temp);
            }}
          >
            <DeleteForeverOutlined color="info" size="small" />
          </Icon>
        </div>
      </div>
    ));

  useEffect(
    () => {
      if (multiple && files) {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      }
    },
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    []
  );

  return (
    <>
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={{
          width: '100%',
          height: '150px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: `1px dashed ${theme.palette.primary.main}`,
          boxShadow: theme.shadows[8],
          cursor: 'pointer'
        }}
      >
        <input {...getInputProps()} />
        <Stack>
          <Typography variant="caption">{t('dragNDrop')}</Typography>
          <Typography variant="caption" component="p">
            {secondary && ` (${secondary}) `}
          </Typography>
        </Stack>
      </div>
      {previewThumbs && (
        <>
          {files && files.length > 0 && (
            <>
              <Divider sx={{ mt: 2 }} />
              <Typography variant="h6">תמונות להעלאה</Typography>
            </>
          )}
          <aside style={thumbsContainer}>{thumbs}</aside>
        </>
      )}
    </>
  );
};

export default UploadInput;
