const questions = {
  cyber: [
    {
      slideIndex: 7,
      questionId: 'T33dL5', // required
      questionTitle: 'מה זה סיכון סייבר?', // required
      answers: [
        {
          title: 'פוטנציאל לנזק, העשוי להתממש באמצעות אירוע.',
          isAnswer: true
        },
        { title: 'תקיפה של מערכות מחשוב.' },
        { title: 'מערכת הגנה בפני התקפות של מערכות מחשוב.' },
        { title: 'פוטנציאל להתרחשות נזקים בטיפול תרופתי בלבד.' }
      ] // required
    },
    {
      slideIndex: 17,
      questionId: 'TpaPcx', // required
      questionTitle: 'מתי מותר למסור מידע רפואי לאחר?', // required
      answers: [
        { title: 'אם המטופל הסכים לכך.', isAnswer: true },
        { title: 'מסירת מידע לקרוב משפחה מותרת ללא תנאים.' },
        { title: 'אם המידע לא חיוני ולא גורם נזק.' },
        { title: 'אין למסור מידע לשום גורם.' }
      ] // required
    }
  ],
  sexualHarassment: [
    {
      slideIndex: 6,
      questionId: 'x6WOil', // required
      questionTitle:
        'קיים סטראוטיפ לפיו נשים מנצלות את החוק למניעת הטרדה מינית על מנת להתנכל לגברים. מה לדעתך אחוז התלונות השווא המדווחות בנושא הטרדה מינית?',
      answers: [
        { title: '2% - 5%.', isAnswer: true },
        { title: '8% - 10%.' },
        { title: '7% - 11%.' },
        { title: '15% - 25%.' }
      ] // required
    }
  ],
  fireAndElectericitySafety: [
    {
      slideIndex: 6,
      questionId: 'LQJWIG', // required
      questionTitle: 'ההתנהגות הלא נכונה בזמן שריפת מכשיר חשמלי היא?', // required
      answers: [
        { title: 'לנתק אספקת החשמל ולא לנתק את המכשיר החשמלי מהתקע.' },
        { title: 'לחנוק את האש באמצעות מטלית או מטף כיבוי אש.' },
        { title: 'יש לשפוך מים אפילו במקום יש חשמל חי.', isAnswer: true },
        { title: 'הזעקת מכבי האש בטלפון 102.' }
      ] // required
    },
    {
      slideIndex: 11,
      questionId: '3RqN2k', // required
      questionTitle: 'מה תעשה במידה ונלכדת במבנה בוער?', // required
      answers: [
        { title: 'מזעיק את מכבה האש בטלפון 101.' },
        {
          title:
            'יש ללכת בהליכה שפופה או לזחול מתחת לשכבת העשן כי העשן עולה למעלה.',
          isAnswer: true
        },
        {
          title: 'נכנס לחדר קרוב שהעשן עוד לא הגיע אליו ולהשאיר את הדלת פתוחה.'
        },
        { title: 'יוצא מהבניין דרך המעלית.' }
      ] // required
    },
    {
      slideIndex: 19,
      questionId: 'T6ztMI', // required
      questionTitle: 'כל הנ"ל נחשב כציוד עזר לכיבוי אש מלבד:', // required
      answers: [
        { title: 'גלאי אש.' },
        { title: 'מתיז מים (ספרנקלרים).' },
        { title: 'מטפה כיבוי אש.' },
        { title: 'AED.', isAnswer: true }
      ] // required
    }
  ],
  safetyAndEmergency: [
    {
      slideIndex: 7,
      questionId: 'WLXUy2', // required
      questionTitle: 'מה הוא הציוד המומלץ למרחב המוגן?', // required
      answers: [
        { title: 'אמצעי תקשורת שיעזרו לנו להישאר מעודכנים.' },
        { title: 'העתקי מסמכים חשובים (מסמכים רפואיים).' },
        { title: 'מטף לכיבוי אש.' },
        { title: 'כל התשובות נכונות.', isAnswer: true }
      ] // required
    },
    {
      slideIndex: 11,
      questionId: '2uS0d2', // required
      questionTitle: 'איך מתנהגים בעת הישמע אזעקה?', // required
      answers: [
        {
          title: 'נכנסים מיד לממ"ד וסוגרים את חלון ודלת הממ"ד.',
          isAnswer: true
        },
        { title: 'יוצאים לשטח פתוח.' },
        { title: 'אין לעצור במידה והייתם נוסעים ברכב בעת הישמע אזעקה.' },
        { title: 'עולים לקומה האחרונה במבנה.' }
      ] // required
    },
    {
      slideIndex: 14,
      questionId: 'XUy2uS', // required
      questionTitle: 'האם מותר להשתמש במעלית בשעת חירום?', // required
      answers: [{ title: 'כן.' }, { title: 'לא.', isAnswer: true }] // required
    },
    {
      slideIndex: 16,
      questionId: 'CQpoHA', // required
      questionTitle: 'מה נכלל בהיערכות של אנשים הלוקים מירידה קוגניטיבית?', // required
      answers: [
        {
          title: 'הכנת תרופות, עזרים נדרשים וציוד אישי חשוב.'
        },
        { title: 'לבוש רזרבי ומצעים.' },
        {
          title: 'יצירת אווירה מרגיעה במרחב המוגן ולדאוג לעיסוק מהנה ומרגיע.'
        },
        { title: 'כל התשובות נכונות.', isAnswer: true }
      ] // required
    },
    {
      slideIndex: 19,
      questionId: 'XW5kaD', // required
      questionTitle: 'איך תנחה אנשים שנקלעו לשיטפון?', // required
      answers: [
        {
          title: 'השתדלו להגיע למקום גבוה ולהתרחק ככל הניתן מהמקום.',
          isAnswer: true
        },
        { title: 'השתדלו לרדת למקום תת קרקעי.' },
        {
          title: 'תשתמשו במעלית לצורך יציאה מהירה מהבניין.'
        },
        { title: 'כנסו למקלט (ממ"ד/ממ"ק).' }
      ] // required
    },
    {
      slideIndex: 20,
      questionId: '2k2CT6', // required
      questionTitle: 'מה נכלל בתיק חירום?', // required
      answers: [
        {
          title: 'תרופות, מזון באריזות, ערכת מיגון, חומר רפואי.'
        },
        {
          title:
            'ת.ז, רשימת אבחנות ותרופות, צו אפוטרופוס, ידון זיהוי, דף מידע לפינוי מטופל.',
          isAnswer: true
        },
        {
          title: 'רשימת אבחנות ותרופות, בקבוקי מים, ציוד אישי.'
        },
        { title: 'ת.ז, רשימת אבחנות ותרופות, תמונת מטופל.' }
      ] // required
    },
    {
      slideIndex: 21,
      questionId: '7I7xUr', // required
      questionTitle: 'באילו מצבים מהנ"ל ניתן להשתמש במעלית?', // required
      answers: [
        {
          title: 'רעידת אדמה.'
        },
        {
          title: 'נפילת טילים.'
        },
        {
          title: 'שיטפון.'
        },
        { title: 'אף תשובה לא נכונה.', isAnswer: true }
      ] // required
    },
    {
      slideIndex: 22,
      questionId: 'LTXbdw', // required
      questionTitle: 'באיזה דרך מתבצע פינוי מטופלים מהבניין בזמן חירום?', // required
      answers: [
        {
          title: 'מעלית.'
        },
        {
          title: 'מרפסת.'
        },
        {
          title: 'חלון.'
        },
        { title: 'מדרגות ויציאת חירום.', isAnswer: true }
      ] // required
    },
    {
      slideIndex: 23,
      questionId: 'DRCTNF', // required
      questionTitle: 'ההתנהגות המתאימה ביותר בשעת חירום היא?', // required
      answers: [
        {
          title: 'העברת מטופל למקום בטוח.',
          isAnswer: true
        },
        {
          title: 'לקיחת תיק חירום למקלט.'
        },
        {
          title: 'הכנת תרופות דחופות.'
        },
        { title: 'הכנת עגלת החייאה.' }
      ] // required
    }
  ],
  medicationTherapyManagement: [
    {
      slideIndex: 4,
      questionId: 'aDRCTN', // required
      questionTitle:
        'לפי הנוהל ניהול הטיפול התרופתי, מה נכון לגבי אחסון תרופות במחלקה?', // required
      answers: [
        {
          title:
            'אין חובה להחזיק את התרופות באריזות מקוריות, ישנן אריזות שמותר לגזור אותן.'
        },
        { title: 'במוסד גריאטרי לא ניתן לאחסן חלק מהתרופות בעגלת התרופות.' },
        { title: 'תוספי מזון ניתן לאחסן אותם בכל מקרר.' },
        {
          title: 'חומרי חיטוי לא יאוחסנו במקום בו מאוחסנות תרופות.',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 9,
      questionId: 'AbXW5k', // required
      questionTitle:
        'על אמפולה רשום AMP. POTASSIUM CHLORID 15%/2MEQ/1ML - כמה ML של תרופה יש לשאוב, על מנת לתת SOL. KCL 1GR ?', // required
      answers: [
        {
          title: '5.2ML'
        },
        { title: '6.6ML', isAnswer: true },
        { title: '8ML' },
        {
          title: '10ML'
        }
      ] // required
    },
    {
      slideIndex: 19,
      questionId: 'HAAS5k', // required
      questionTitle:
        'במוסד שבו אתה עובד, משתמשים באמפולות FUSID 20MG/2ML. כמה אמפולות תצטרך להזמין מבית המרקחת על מנת לתת את התרופה לפי הוראה במשך שלושה ימים: IM. FUSID 40MG*2/D', // required
      answers: [
        {
          title: '10 אמפולות'
        },
        { title: '16 אמפולות' },
        { title: '12 אמפולות', isAnswer: true },
        {
          title: '6 אמפולות'
        }
      ] // required
    },
    {
      slideIndex: 26,
      questionId: 'MDA83D', // required
      questionTitle: 'מה נכון לגבי חלוקת תרופות?', // required
      answers: [
        {
          title: 'הכנת תרופות יש לבצע 3 עד 4 שעות לפני החלוקה'
        },
        {
          title:
            'האחות תתן טיפול תרופתי לפי שיקול דעתה ולא לפי הוראה שניתנה על ידי רופא'
        },
        {
          title: 'אין צורך לאמת את זהות המטופל בזמן חלוקת תרופות'
        },
        {
          title: 'אין להשאיר את התרופות ליד המטופל',
          isAnswer: true
        }
      ] // required
    }
  ],
  wounds: [
    {
      slideIndex: 22,
      questionId: '1IvyWuf', // required
      questionTitle: 'מה נכון לגבי פצע לחץ דרגה 1 ?', // required
      answers: [
        {
          title:
            'ירידה בעובי העור, יראה כפצע שטחי בעל גוון ורדרד. מבטא מצב של פגיעה באפידרמיס ובדרמיס.'
        },
        {
          title:
            'אודם שאינו חולף לאחר הקלה בלחץ, יש מעורבות של אפידרמיס, אין פגיעה בשלמות העור.',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 23,
      questionId: 'I7xUrD', // required
      questionTitle: 'מה נכון לגבי פצע לחץ דרגה 2 ?', // required
      answers: [
        {
          title:
            'אובדן עור מוחלט כמעט, ופגיעה גם ברקמות עמוקות יותר. עם זאת, אין חשיפה של גידים, עצמות או שרירים.',
          isAnswer: true
        },
        {
          title:
            'ירידה בעובי העור, יראה כפצע שטחי בעל גוון ורדרד. מבטא מצב של פגיעה באפידרמיס ובדרמיס.'
        }
      ] // required
    },
    {
      slideIndex: 25,
      questionId: 'wjfeG1S', // required
      questionTitle: 'מה נכון לגבי פצע לחץ דרגה 3 ?', // required
      answers: [
        {
          title:
            'אובדן עור מוחלט כמעט, ופגיעה גם ברקמות עמוקות יותר. עם זאת, אין חשיפה של גידים, עצמות או שרירים.',
          isAnswer: true
        },
        {
          title:
            'ירידה בעובי העור, יראה כפצע שטחי בעל גוון ורדרד. מבטא מצב של פגיעה באפידרמיס ובדרמיס.'
        }
      ] // required
    },
    {
      slideIndex: 27,
      questionId: 'fI97I7xZ', // required
      questionTitle: 'מה נכון לגבי פצע לחץ דרגה 4 ?', // required
      answers: [
        {
          title:
            'אובדן עור כמעט מוחלט, ופגיעה גם ברקמות עמוקות יותר. עם זאת, אין חשיפה של גידים, עצמות או שרירים.'
        },
        {
          title:
            'אובדן עור מוחלט עם חשיפה של גידים, עצמות או שרירים. באזור הפצע יתפתח גלד, והעצמות החשופות תעבורנה הרס.',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 29,
      questionId: 'SMO93Ae', // required
      questionTitle: 'לפי התמונה, איזה דרגה הפצע?', // required
      answers: [
        {
          title: 'פצע לחץ דרגה - 1'
        },
        {
          title: 'פצע לחץ דרגה - 2',
          isAnswer: true
        },
        {
          title: 'פצע לחץ דרגה - 3'
        },
        {
          title: 'פצע לחץ דרגה - 4'
        }
      ] // required
    },
    {
      slideIndex: 30,
      questionId: 'OKEu73e', // required
      questionTitle: 'לפי התמונה, איזה דרגה הפצע?', // required
      answers: [
        {
          title: 'פצע לחץ דרגה - 1'
        },
        {
          title: 'פצע לחץ דרגה - 2'
        },
        {
          title: 'פצע לחץ דרגה - 3'
        },
        {
          title: 'פצע לחץ דרגה - 4',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 31,
      questionId: 'OKEu73e', // required
      questionTitle: 'לפי התמונה, איזה דרגה הפצע?', // required
      answers: [
        {
          title: 'פצע לחץ דרגה - 1'
        },
        {
          title: 'פצע לחץ דרגה - 2'
        },
        {
          title: 'פצע לחץ דרגה - 3',
          isAnswer: true
        },
        {
          title: 'פצע לחץ דרגה - 4'
        }
      ] // required
    },
    {
      slideIndex: 32,
      questionId: 'K9Eu73e', // required
      questionTitle: 'לפי התמונה, איזה דרגה הפצע?', // required
      answers: [
        {
          title: 'פצע לחץ דרגה - 1',
          isAnswer: true
        },
        {
          title: 'פצע לחץ דרגה - 2'
        },
        {
          title: 'פצע לחץ דרגה - 3'
        },
        {
          title: 'פצע לחץ דרגה - 4'
        }
      ] // required
    },
    {
      slideIndex: 34,
      questionId: 'FEZX73e', // required
      questionTitle: 'מה לא יכלול סולם נורטון?', // required
      answers: [
        {
          title: 'ניידות'
        },
        {
          title: 'מצב מנטלי'
        },
        {
          title: 'יותר מאבחנה פעילה אחת',
          isAnswer: true
        },
        {
          title: 'מצב פיזי'
        },
        {
          title: 'פעילות יומיומית'
        }
      ] // required
    },
    {
      slideIndex: 43,
      questionId: 'OIT3ic', // required
      questionTitle: 'מה לא נצפה לראות בפצע מזוהם?', // required
      answers: [
        {
          title: 'אודם מקומי, בצקת וריח רע.'
        },
        {
          title: 'חום מערכתי סיסטמי, חום מקומי והפרשה מוגלתית.'
        },
        {
          title: 'חום נמוך וירידה בלויקוציטים.',
          isAnswer: true
        },
        {
          title: 'ברק מקומי בעור והגבלה בתנועה.'
        },
        {
          title: 'במעבדה - שקיעת דם מוחשת, עלייה בלויקוציטים.'
        }
      ] // required
    },
    {
      slideIndex: 75,
      questionId: 'OIT3ic', // required
      questionTitle:
        'בן 90 עבר מספר אירועים מוחיים שגרמו לו לריתוק למיטתו ולכיסא גלגלים. הוא התקשה לבלוע והרבה להשתעל בעת האכילה ובעיקר לאחר שתיית נוזלים. המטופל פיתח פצע לחץ בירך שמאל, במה היית מטפל בפצע שלפניך?', // required
      answers: [
        {
          title: 'DERMAGRAN GEL',
          isAnswer: true
        },
        {
          title: 'PROMOGRAN'
        },
        {
          title: 'GRANUFLEX'
        },
        {
          title: 'FLAMINAL',
          isAnswer: true
        }
      ] // required
    }
  ],
  preventingConstipation: [
    {
      slideIndex: 6,
      questionId: 'YmF4Az', // required
      questionTitle:
        'מאבחנים עצירות כאשר מתקיימים שניים או יותר משש תופעות במשך שלושה חודשים ברציפות במהלך חצי שנה שקדמה לבדיקה - חלק מתופעות אלו הם:', // required
      answers: [
        {
          title: 'פעם אחת באופן חד פעמי מהיציאות, יש תחושה של התרוקנות לא מלאה.'
        },
        {
          title:
            'פחות משלוש יציאות בשבוע. לפחות ברבע מהיציאות יש צורך להפעיל מאמץ. לפחות ברבע מהיציאות הצואה היא קשה או גושית.',
          isAnswer: true
        },
        {
          title: 'קיימת תחושה של מלאות. אין צורך בעזרה ידנית להשגת התרוקנות.'
        },
        {
          title: 'פחות מארבע יציאות בשבוע.'
        }
      ] // required
    },
    {
      slideIndex: 15,
      questionId: 'PMToXXE', // required
      questionTitle: 'מה הם הסיבות שיכולות לגרום לעצירות?', // required
      answers: [
        {
          title: 'כלכלה עשירת תאית. תרופות שונות.'
        },
        {
          title: 'גיל מבוגר. כלכלה דלת סיבים. שתיה מרובה.'
        },
        {
          title:
            'כלכלה דלת סיבים, צריכה מועטה של נוזלים, דיאטה חריפה, היעדר פעילות גופנית. גיל מבוגר.',
          isAnswer: true
        },
        {
          title: 'כלכלה דלת תאית. אין קשר לגיל.'
        }
      ] // required
    },
    {
      slideIndex: 16,
      questionId: 'XEuAQ5X', // required
      questionTitle: 'מה הסימנים שיכולים להעיד על עצירות?', // required
      answers: [
        {
          title:
            'נפיחות בבטן, כאבי בטן ואי-נוחות בבטן, צורך במאמץ מוגבר להפעלת המעיים.',
          isAnswer: true
        },
        {
          title: 'בטן רכה, תיאבון שמור, דימום או כאב בזמן פעולת המעיים.'
        },
        {
          title: 'תחושה של התרוקנות לא מלאה לאחר מתן צואה, בטן רכה.'
        },
        {
          title: 'אי שקט, תיאבון שמור, בטן קשה.'
        }
      ] // required
    },
    {
      slideIndex: 24,
      questionId: 'XbdwPMT', // required
      questionTitle: 'מה הם שלבי הטיפול בעצירות?', // required
      answers: [
        {
          title:
            '1. שינויים בהרגלי האכילה. 2. שינויים באורח החיים 3. תכשירים להגדלת נפח הצואה למשל בנפייבר ופסיליום ולהקפיד על שתיית מים בכמות גדולה. 4. תכשירים אוסמוטיים.',
          isAnswer: true
        },
        {
          title:
            '1. שינויים באורח חיים. 2. תכשירים להגדלת נפח הצואה. 3. שינויים בהרגלי האכילה.4. תכשירים אוסמוטיים.'
        },
        {
          title:
            '1. שינויים בהרגלי האכילה. 2. תכשירים אוסמוטיים. 3. תכשרים להגדלת נפח הצואה.4. שינויים באורח חיים.'
        },
        {
          title: 'אין שלבי טיפול , העיקר למנוע עצירות.'
        }
      ] // required
    },
    {
      slideIndex: 27,
      questionId: 'PMT21SX', // required
      questionTitle: 'עצירות מה היא ?', // required
      answers: [
        {
          title:
            'עצירות מוגדרת "כרונית" כאשר היא מופיעה לפחות ב־25% מהזמן במשך שלושה חודשים. תדירות יציאות פעם בשבוע.'
        },
        {
          title:
            'תדירות יציאות של פחות מפעמיים בשבוע. צואה קשה או כמות קטנה מהרגיל.'
        },
        {
          title:
            'עצירות היא שינוי לרעה בהרגלי היציאות. השינוי הזה עלול לכלול צואה קשה יותר או בכמות קטנה יותר מהרגיל. תדירות יציאות של פחות משלוש פעמים בשבוע.'
        },
        {
          title: 'כל התשובות נכונות.',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 29,
      questionId: 'Rfc5uI', // required
      questionTitle:
        'כאשר קיימת בעיה של התרוקנות או שישנה הפרעה בתפקוד רצפת האגן, ניתן להיעזר בנרות שמוחדרים דרך פי הטבעת, למשל נרות גליצרין.', // required
      answers: [
        {
          title: 'נכון.',
          isAnswer: true
        },
        {
          title: 'לא נכון.'
        }
      ] // required
    },
    {
      slideIndex: 32,
      questionId: 'oi3D2Sa', // required
      questionTitle: 'איזה סיבוכים יכולים להיווצר כתוצאה מעצירות?', // required
      answers: [
        {
          title:
            'א- היווצרות של פיסורה - קרע כואב בפי הטבעת. דימום מפי הטבעת לאחר יציאות נוקשות. היווצרות של בקעים בדופן הבטן כתוצאה מהמאמץ. דימומים בלחמית כתוצאה מהמאמץ.',
          isAnswer: true
        },
        {
          title:
            'ב- חוסר שליטה על השתן במצב שבו הרקטום מלא בצואה. המצב לרוב משתפר לאחר התרוקנות החלחולת. אי שליטה על שני הסוגרים.'
        },
        {
          title:
            'ג- אי שליטה על סוגר יציאות. היווצרות של בקעים בדופן הבטן כתוצאה מהמאמץ. דימומים בלחמית כתוצאה מהמאמץ.'
        },
        {
          title: 'ד- א+ב נכונים.'
        }
      ] // required
    },
    {
      slideIndex: 33,
      questionId: 'x9tSduP', // required
      questionTitle: 'כשמדברים על עצירות, מתכוונים ל:', // required
      answers: [
        {
          title: 'עצירות שקשורה לאוכל והרגלי חיים.'
        },
        {
          title:
            'עצירות תפקודית שנובעת מהפרעה בתפקוד של המעי הגס. ועצירות משנית שנובעת ממחלות אחרות, מתרופות ומסגנון חיים.'
        },
        {
          title: 'כשמדברים על עצירות- אין סוגים.'
        },
        {
          title: 'א+ב נכונים.',
          isAnswer: true
        }
      ], // required
      showNumbering: true
    }
  ],
  teethAndMouthHealth: [
    {
      slideIndex: 18,
      questionId: 'AYJO9lw', // required
      questionTitle: 'מי מבצע אומדן פה ؟',
      answers: [
        {
          title:
            'האחות המופקדת על הטיפול בחולה או סייעת בהתאם לשיקול דעתה של האחות אחראית המשמרת.',
          isAnswer: true
        },
        { title: 'המשפחה של המטופל, המטופל והמטפל.' },
        { title: 'על ידי המטפל במחלקה.' },
        { title: 'לבקש מהמטופל עצמו שיבציע את האומדן.' }
      ] // required
    },
    {
      slideIndex: 25,
      questionId: 'ZY1S9lw', // required
      questionTitle: 'מה תפקיד משחת השיניים?',
      answers: [
        {
          title: 'שיפור ריח הפה, עזרה בניקוי חלל הפה, סיוע במניעת עששת.',
          isAnswer: true
        },
        { title: 'הגנה על מברשת השיניים.' },
        { title: 'הריגת חיידקי חלל הפה.' },
        { title: 'כל התשובות נכונות.' }
      ] // required
    },
    {
      slideIndex: 31,
      questionId: 'O2Tfz4', // required
      questionTitle: 'בחרו בתשובה הלא נכונה?',
      answers: [
        {
          title: 'יש לדרבן את המטופל להשתמש בתותבות ככל האפשר.'
        },
        {
          title:
            'יש לבצע את הטיפול בתותבות במצבים בהם המטופל לא יוכל לשרת את עצמו.'
        },
        {
          title: 'מותר לא לדווח לאחות המופקדת אודות הטיפול הנעשה בפה ובשיניים.',
          isAnswer: true
        },
        { title: 'יש לספק לכל מטופל כלי מתאים המסומן בשם המטופל.' }
      ] // required
    },
    {
      slideIndex: 33,
      questionId: 'f8SWz1', // required
      questionTitle: 'ניקוי כימי של שיניים תותבות, מתבצע:',
      answers: [
        {
          title: 'אחת לעשרה ימים.'
        },
        {
          title: 'אחת לארבעה ימים.'
        },
        {
          title: 'אחת לשלושה ימים.',
          isAnswer: true
        },
        { title: 'אין צורך לעשות ניקוי כימי.' }
      ] // required
    },
    {
      slideIndex: 36,
      questionId: 'SW7IzL', // required
      questionTitle: 'מותר לישון עם שיניים תותבות?',
      answers: [
        {
          title: 'כן.'
        },
        {
          title: 'לא.',
          isAnswer: true
        }
      ] // required
    }
  ],
  infectionsPrevention: [
    {
      slideIndex: 47,
      questionId: 'GgVCvi2', // required
      questionTitle:
        'מה האמצעי המרכזי בניתוק שרשרת העברת זיהומים במוסד גריאטרי?', // required
      answers: [
        {
          title: 'בידודים מסוגים שונים.'
        },
        {
          title: 'רחצת ידיים.',
          isAnswer: true
        },
        {
          title: 'החלפת כפפות תכופה.'
        },
        { title: 'השמדת חיידקים בעזרת תמיסת כלור מרוכזת.' }
      ] // required
    },
    {
      slideIndex: 48,
      questionId: 'pvRPkex', // required
      questionTitle:
        'השיטה מאפשרת להסיר גורמי זיהום מידי הצוות במהלך רצף הטיפול נקראת?', // required
      answers: [
        {
          title: '5 שיטות לניקוי ידיים.'
        },
        {
          title: '5 רגעים לחיטוי ידיים.',
          isAnswer: true
        },
        {
          title: '5 מצבים להיגיינת ידיים.'
        },
        { title: '5 מצבים מונעים זיהום ידיים.' }
      ] // required
    },
    {
      slideIndex: 49,
      questionId: 'kexG5aG', // required
      questionTitle: 'מה הוא החומר הכי יעיל בהרג מחוללים?', // required
      answers: [
        {
          title: 'SEPTOL'
        },
        {
          title: 'CHLOR'
        },
        {
          title: 'SEPTAL SCRUB',
          isAnswer: true
        },
        { title: 'סבון קוסמטי' }
      ] // required
    },
    {
      slideIndex: 50,
      questionId: 'aGgVCvi', // required
      questionTitle: 'יתרונות חיטוי הידיים בתכשיר אלכוהולי כוללים, פרט ל-', // required
      answers: [
        {
          title: 'ידידותי יותר לעור ידיים.'
        },
        {
          title: 'יעילות בהשמדת מיקרואורגניזמים.'
        },
        {
          title: 'חסכון בזמן עבודה יקר.'
        },
        {
          title: 'חסכון כספי עקב הפחתת השימוש בסבון אנטי בקטריאלי.',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 51,
      questionId: 'vRPkexGS', // required
      questionTitle:
        'שיטת 5 רגעים להיגיינת ידיים כוללת כל המצבים הבאים, פרט ל-', // required
      answers: [
        {
          title: 'לפני פעולה אספטית.'
        },
        {
          title: 'לאחר הקלדה במחשב.',
          isAnswer: true
        },
        {
          title: 'לאחר מגע בסביבת המטופל.'
        },
        {
          title: 'לפני מגע במטופל.'
        }
      ] // required
    },
    {
      slideIndex: 52,
      questionId: 'VPrR1ji', // required
      questionTitle: 'נוהל משרד הבריאות בנוהל "היגיינת ידיים" קובע ש:', // required
      answers: [
        {
          title: 'עדיפות שימוש בתכשיר אלכוהול לחיטוי ידיים על פני סבון.'
        },
        {
          title: 'איסור מוחלט לענידת טבעות פרט לטבעת נישואין חלקה.'
        },
        {
          title: 'אורך ציפורניים לא מעבר 0.5 ס"מ.'
        },
        {
          title: 'כל התשובות נכונות.',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 53,
      questionId: '9lwFoR2', // required
      questionTitle: 'אמצעי זהירות שגרתיים יינקטו', // required
      answers: [
        {
          title: 'בכל טיפול בחולה נשאי לחיידקים עמידים.'
        },
        {
          title: 'בכל טיפול, בכל החולים, ללא התייחסות לאבחנתם ומצבם.',
          isAnswer: true
        },
        {
          title: 'בכל טיפול במטופל חולה במחלה זיהומית.'
        },
        {
          title: 'בכל פעולה חדרנית.'
        }
      ] // required
    },
    {
      slideIndex: 54,
      questionId: '9SR9oR2', // required
      questionTitle: 'במסגרת אמצעי זהירות שגרתיים נשתמש ב', // required
      answers: [
        {
          title: 'חלוק חד פעמי.'
        },
        {
          title: 'משקפי מגן.'
        },
        {
          title: 'כפפות.'
        },
        {
          title: 'כל התשובות נכונות.',
          isAnswer: true
        }
      ] // required
    },
    {
      slideIndex: 55,
      questionId: '63EF3H', // required
      questionTitle: 'בגמר השימוש במחט יש:', // required
      answers: [
        {
          title: 'לזרוק למיכל חפצים חדים.',
          isAnswer: true
        },
        {
          title: 'לכסות את המחט בשיטת יד אחד.'
        },
        {
          title: 'ניתן להסיר מחט ממזרק בזהירות ביד אחת.'
        },
        {
          title: 'להכניס אותה לפקק גומי של בקבוק נוזלים ולזרוק אותם ביחד.'
        }
      ] // required
    },
    {
      slideIndex: 56,
      questionId: 'YI93WDE', // required
      questionTitle: 'כל העובדות על שימוש בכפפות נכונות, פרט ל:', // required
      answers: [
        {
          title: 'יש לבצע היגיינת ידיים לפני עטית הכפפות.'
        },
        {
          title: 'ניתן לעטות כפפות במקום רחצת ידיים.',
          isAnswer: true
        },
        {
          title: 'יש לבצע היגיינת ידיים לאחר הסרת הכפפות.'
        },
        {
          title: 'יש להשתמש בכפפות כאשר יש סיכון גבוה להעברת מזהמים.'
        }
      ] // required
    },
    {
      slideIndex: 57,
      questionId: 'IUQW23X', // required
      questionTitle: 'אמצעי זהירות ייחודיים:', // required
      answers: [
        {
          title:
            'אמצעי זהירות אלו מחליפים אמצעי זהירות שגרתיים בעת טיפול במטופל חולה במחלה זיהומית.'
        },
        {
          title:
            'ינקטו בטיפול בחולים מאובחנים או חשודים להיות מזוהמים במחוללים מדבקים, לפי דרך העברת המחוללים.'
        },
        {
          title: 'ינקטו בעת טיפול בכל חולה ללא התייחסות לאבחנתו ומצבו.',
          isAnswer: true
        },
        {
          title: 'ינקטו מרגע שהוחלט על הכנסת מטופל לבידוד.'
        }
      ] // required
    },
    {
      slideIndex: 58,
      questionId: 'ZOWP012', // required
      questionTitle: 'פתוגניים אלה מחייבים בידוד טיפתי, פרט ל:', // required
      answers: [
        {
          title: 'שפעת.'
        },
        {
          title: 'MRSA באף וגרון.',
          isAnswer: true
        },
        {
          title: 'אדמת.'
        },
        {
          title: 'שעלת.'
        }
      ] // required
    },
    {
      slideIndex: 59,
      questionId: 'ER8SO0W', // required
      questionTitle: 'CLOSTRIDIUM DEFFICILE מתאפיין בכל הנאמר, פרט ל:', // required
      answers: [
        {
          title: 'בחום גבוה והקאות.',
          isAnswer: true
        },
        {
          title: 'שלשול ללא ריר ודם עם ריח אופייני.'
        },
        {
          title: 'בסיבוך שנקרא COLITIS PSEUDOMEMBRANOS.'
        },
        {
          title: 'שכיחות גבוהה בין קשישים מרבים בטיפול אנטיביוטי.'
        }
      ] // required
    },
    {
      slideIndex: 60,
      questionId: 'IT1E3R4', // required
      questionTitle: 'מרכיב מרכזי באמצעי הזהירות השגרתיים?', // required
      answers: [
        {
          title: 'רחצת ידיים.',
          isAnswer: true
        },
        {
          title: 'החלפת כפפות.'
        },
        {
          title: 'הורדת חלוק.'
        },
        {
          title: 'מקלחת.'
        }
      ] // required
    },
    {
      slideIndex: 61,
      questionId: 'GI938DE', // required
      questionTitle:
        'אמצעים בסיסיים במניעת זיהומים, אשר נועדו לצמצם סיכון הדבקה במיקרואורגניזמים שונים ממקורות ידועים ובלתי ידועים.', // required
      answers: [
        {
          title: 'כן.',
          isAnswer: true
        },
        {
          title: 'לא.'
        }
      ] // required
    },
    {
      slideIndex: 62,
      questionId: 'XE32DER', // required
      questionTitle: 'כל אלה גורמים למחלות, פרט ל:', // required
      answers: [
        {
          title: 'חיידקים.'
        },
        {
          title: 'פטריות.'
        },
        {
          title: 'יתושים.',
          isAnswer: true
        },
        {
          title: 'וירוסים.'
        },
        {
          title: 'טפילים.'
        }
      ] // required
    }
  ],
  palliativeCare: [
    {
      slideIndex: 9,
      questionId: '9Zf42jS', // required
      questionTitle: 'למי לא מתאימה הגישה הפליאטיבית :', // required
      answers: [
        {
          title: 'סרטן עם גרורות למוח מתקדם.'
        },
        { title: 'מחלות לב וריאה כרוניות בשלב מתקדם.' },
        { title: 'מחלת כליות כרונית בשלבים סופניים.' },
        { title: 'מחלות נוירולוגיות מתקדמות.' },
        { title: 'דמנציה מתקדמת.' },
        { title: 'מחלת כלי דם פריפרית.', isAnswer: true },
        { title: 'מחלות קונגניטליות מתקדמות.' }
      ] // required
    }
  ],
  pain: [
    {
      slideIndex: 8,
      questionId: '27fXx9', // required
      questionTitle: 'מה היא ההגדרה של כאב ?', // required
      answers: [
        {
          title:
            'חוויה סנסורית ומוטורית לא נעימה הנגרמת מנזק ממשי/ פוטנציאלי לרקמה.',
          isAnswer: true
        },
        { title: 'תחושה שגורמת לאדם לברוח/ לזוז ממקום הפגיעה בו.' },
        { title: 'נזק לרקמות הגורם להפעלת הרצפטורים ולתחושת תסכול.' },
        { title: 'מעבר של גירוי דרך המסילות העולות אל התלמוס וחזרה לפריפריה.' }
      ] // required
    },
    {
      slideIndex: 9,
      questionId: 'E8mmgW', // required
      questionTitle: 'איזה השפעה יש לכאב חריף (ACUTE) על מערכת הגוף:', // required
      answers: [
        { title: 'ירידה בקצב חילוף החומרים.' },
        { title: 'ירידה בתפוקת הלב.' },
        { title: 'עלייה בתגובת הגוף לאינסולין.' },
        { title: 'עלייה בייצור קורטיזול בגוף.', isAnswer: true }
      ] // required
    },
    {
      slideIndex: 10,
      questionId: 'f42jN2', // required
      questionTitle: 'מה מבין הרשומים מטה הוא כאב כרוני שפיר:', // required
      answers: [
        { title: 'מיגרניה.' },
        { title: 'כאב בטן בסרטן לבלב.' },
        { title: 'כאב גב.', isAnswer: true },
        { title: 'בעקבות טראומה.' }
      ] // required
    },
    {
      slideIndex: 15,
      questionId: 'UM38sq', // required
      questionTitle: 'תיעוד אומדן כאב ברשומות המטופל כולל את הבאים, פרט ל:', // required
      answers: [
        { title: 'ממצאי האומדן.' },
        { title: 'תאריך ושעה.' },
        { title: 'שם האחות.' },
        { title: 'שם המחלקה.', isAnswer: true }
      ] // required
    },
    {
      slideIndex: 24,
      questionId: 'Pfc5XM', // required
      questionTitle: 'העיתוי היעיל ביותר למתן תרופות משככות כאבים הוא כאשר:', // required
      answers: [
        { title: 'הכאב בעוצמתו הגבוהה ביותר.' },
        { title: 'המטופל מדווח על כאב עז.' },
        { title: 'לפני שהכאב מתרחש.', isAnswer: true },
        { title: 'תוך 30 דקות מהופעת הכאב.' }
      ] // required
    },
    {
      slideIndex: 31,
      questionId: 'Zvm51E', // required
      questionTitle:
        'אומדן כאב למטופלים שתקשורתם תקינה יבוצע באמצעות סרגל VAS:', // required
      answers: [{ title: 'נכון.', isAnswer: true }, { title: 'לא נכון.' }] // required
    },
    {
      slideIndex: 32,
      questionId: 'I5SCE2', // required
      questionTitle:
        'אומדן כאב למטופלים לקויי תקשורת יבוצע באמצעות סרגל PAINAD:', // required
      answers: [{ title: 'נכון.', isAnswer: true }, { title: 'לא נכון.' }] // required
    },
    {
      slideIndex: 43,
      questionId: 'XM3MPX', // required
      questionTitle: 'כאב פנטום הוא כאב באיבר:', // required
      answers: [
        { title: 'קיים.' },
        { title: 'המרוחק ממקום הפגיעה.' },
        { title: 'פנימי.' },
        { title: 'שאינו קיים.', isAnswer: true }
      ] // required
    },
    {
      slideIndex: 53,
      questionId: 'S35fMui', // required
      questionTitle:
        'על פי סולם האנלגטיקה של ארגון הבריאות העולמי, ההתערבות בכאב קל (1-3) תהיה:', // required
      answers: [
        { title: 'MORPHINE.' },
        { title: 'Ibuprofen.', isAnswer: true },
        { title: 'Fentanyl.' },
        { title: 'OXYCODONE.' }
      ] // required
    }
  ]
};
export default questions;
