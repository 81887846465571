// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Material Kit 2 React components
import { Typography } from '@mui/material';

// Images
import { Telegram } from '@mui/icons-material';
import logoCT from 'src/assets/mgdalorFull.webp';
import i18n from './i18n';

const date = new Date().getFullYear();

export default {
  brand: {
    name: 'Mgdalor',
    image: logoCT,
    route: '/'
  },
  socials: [
    {
      icon: <Telegram />,
      link: 'https://www.telegram.com'
    },
    {
      icon: <FacebookIcon />,
      link: 'https://www.facebook.com/profile.php?id=100083054424959'
    },
    {
      icon: <TwitterIcon />,
      link: 'https://twitter.com/'
    },
    {
      icon: <YouTubeIcon />,
      link: 'https://www.youtube.com'
    }
  ],
  menus: [
    {
      name: i18n.t('mainPage.footer.company'),
      items: [
        {
          name: i18n.t('navbar.aboutUs'),
          route: '/vision'
        },
        {
          name: i18n.t('navbar.ourValues'),
          route: '/vision'
        },
        {
          name: i18n.t('navbar.ourVision'),
          route: '/vision'
        }
      ]
    },
    {
      name: i18n.t('mainPage.footer.findAHome'),
      items: [
        {
          name: i18n.t('mainPage.footer.search'),
          route: '/search'
        },
        {
          name: i18n.t('mainPage.footer.compare'),
          route: '/compare'
        }
      ]
    },
    {
      name: i18n.t('mainPage.footer.helpAndSupport'),
      items: [
        {
          name: i18n.t('mainPage.footer.contactUs'),
          route: '/contact'
        }
      ]
    },
    {
      name: i18n.t('mainPage.footer.legal'),
      items: [
        {
          name: i18n.t('mainPage.footer.accessibility'),
          route: '/accessibility'
        },
        {
          name: i18n.t('mainPage.footer.privacy'),
          route: '/privacy'
        }
      ]
    }
  ],
  copyright: (
    <Typography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy;
      {date}
      &nbsp;Mgdalor
      <br />
      <Typography
        component="a"
        href="https://www.microspec.dev"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Powered By Microspec.
      </Typography>
    </Typography>
  )
};
