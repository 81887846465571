import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from '@firebase/firestore';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery
} from '@mui/material';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import diamondLoadingLottie from 'src/assets/lottie/diamondLoadingLottie.json';
import redeemCoupon from 'src/assets/personalDashboard/coupon.png';
import dog from 'src/assets/personalDashboard/dog.svg';
import promotion from 'src/assets/personalDashboard/promotion.png';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import GiftCard from './GiftCard';
import VerifySelectedCardDialog from './VerifySelectedCardDialog';

const RedeemDiamonds = () => {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [giftCards, setGiftCards] = useState();
  const [isRedeemDiamondsVisible, setRedeemDiamondsVisible] = useState(false);
  const [isVerifySelectedCouponOpen, setVerifySelectedCouponOpen] = useState();
  const { currentUser } = useCurrentUserContext();

  const retrieveGiftCards = async () => {
    const giftCardsRef = collection(db, 'giftCards');
    const q = query(giftCardsRef, where('published', '==', true));
    getDocs(q).then((snapshot) => {
      if (snapshot.empty) {
        setGiftCards([]);
      } else {
        const giftCardsData = snapshot.docs.map((card) => ({
          ...card.data(),
          card_id: card.id
        }));
        setGiftCards(giftCardsData);
      }
    });
  };
  const getRedeemDiamondsStatus = async () => {
    const pagesVisibilityRef = doc(db, 'visibilePages/app$$redeem');
    getDoc(pagesVisibilityRef).then((val) => {
      if (val.exists()) {
        const { published } = val.data();
        setRedeemDiamondsVisible(published);
      } else {
        setRedeemDiamondsVisible(false);
      }
    });
  };
  useEffect(() => {
    getRedeemDiamondsStatus();
  }, [giftCards]);

  useEffect(() => {
    if (!giftCards) {
      retrieveGiftCards();
    }
  }, [giftCards]);
  const handleVerifyGiftCardRequest = (selectedCard) => {
    if (selectedCard.diamonds <= currentUser.diamonds) {
      setVerifySelectedCouponOpen(selectedCard);
    }
  };
  const handleCloseVerifySelectedCouponDialog = () => {
    setVerifySelectedCouponOpen();
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          container
          spacing={2}
          xs={12}
          sx={{
            backgroundColor: '#14b195',
            p: 2
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Lottie
                style={{
                  width: '120px !important',
                  height: '100px !important',
                  maxWidth: '150px',
                  maxHeight: '150px',
                  zIndex: 1
                }}
                width="100px"
                autoplay
                animationData={diamondLoadingLottie}
                loop
              />
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontSize: '2.5rem',
                color: theme.palette.primary.contrastText
              }}
            >
              את היהלומים שצברת, תוכל להמיר לכסף אמיתי
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.primary.contrastText,
                fontSize: '1.1rem',
                my: 2
              }}
            >
              היהלומים שצברת דרך המערכת שלנו, הם לא רק מספר אלא כסף אמיתי שאיתו
              תוכל להמיר לשובר קנייה
            </Typography>
            <Box
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="success"
                sx={{ borderRadius: 3, maxWidth: 300, fontSize: '1.25rem' }}
                fullWidth
                href="#coupons"
              >
                להמרה לשובר
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Box
              sx={{
                height: matchMdDown ? 350 : 500,
                width: '100%',
                maxWidth: matchMdDown ? 350 : 500,
                marginRight: matchMdDown && '50px'
              }}
              alt="redeem coupon"
              src={redeemCoupon}
              component="img"
            />
          </Grid>
        </Grid>
        <Grid
          sx={{
            background: theme.palette.background.paper,
            color: '#f02e5e',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            pb: '120px !important',
            pt: '80px !important',
            position: 'relative',
            '&:after': {
              display: 'block',
              position: 'absolute',
              // mx: 'auto',
              height: 116,
              width: '100%',
              bottom: -24,
              zIndex: 1,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              content: '""',
              backgroundImage: `url(${dog})`
            }
          }}
          item
          xs={12}
        >
          <Typography variant="h3" fontSize="2.2rem">
            רק תבחרו מתנה מהמתנות שברשימה
          </Typography>
          <Typography variant="h5" fontSize="1.5rem">
            בוחרים שובר מתנה ומקבלים אותו למייל שמעודכן במערכת מגדלור, כבר עם
            קבלת המייל תוכל לקנות עם הכרטיס
          </Typography>
        </Grid>
        {isRedeemDiamondsVisible ? (
          <Grid id="coupons" item container sx={{ px: 2 }}>
            {giftCards && giftCards.length > 0 ? (
              <Grid
                item
                xs={12}
                sx={{
                  background: theme.palette.background.default,
                  display: 'flex',
                  justifyContent: 'center',
                  py: 6
                }}
                container
                spacing={2}
              >
                {giftCards.map((coupon) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={coupon.card_id}>
                    <GiftCard
                      coupon={coupon}
                      handleVerifyGiftCardRequest={handleVerifyGiftCardRequest}
                      userDiamonds={currentUser ? currentUser.diamonds : 0}
                      couponImg={coupon.image || promotion}
                      key={`component${coupon.card_id}`}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : giftCards && giftCards.length === 0 ? (
              <Grid sx={{ py: 4 }} item xs={12}>
                <Typography variant="h5">
                  אין קופונים, נא לבדוק מאוחר יותר
                </Typography>
              </Grid>
            ) : (
              <LinearProgress />
            )}
          </Grid>
        ) : (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 6
            }}
            item
            container
            xs={12}
          >
            <Typography>
              העמוד יפתח במועד מאוחר יותר, נא לעקוב כדי שתוכל להמיר את היהלומים
              שלך
            </Typography>
          </Grid>
        )}
      </Grid>
      {isVerifySelectedCouponOpen && (
        <VerifySelectedCardDialog
          selectedCard={isVerifySelectedCouponOpen}
          onClose={handleCloseVerifySelectedCouponDialog}
        />
      )}
    </>
  );
};

export default RedeemDiamonds;
