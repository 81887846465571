import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
  where
} from '@firebase/firestore';
import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import AddProductToMarketplaceDialog from 'src/components/KnowledgeCommunity/MgdalorMarketPlace/AddProductToMarketplaceDialog';
import CommunityMarketPlaceToolbar from 'src/components/KnowledgeCommunity/MgdalorMarketPlace/CommunityMarketPlaceToolbar';
import ProductCard from 'src/components/KnowledgeCommunity/MgdalorMarketPlace/ProductCard';
import PurchaseHistoryDialog from 'src/components/KnowledgeCommunity/MgdalorMarketPlace/PurchaseHistoryDialog';
import SavedProductsDialog from 'src/components/KnowledgeCommunity/MgdalorMarketPlace/SavedProductsDialog';
import VerifyPurchaseDialog from 'src/components/KnowledgeCommunity/MgdalorMarketPlace/VerifyPurchaseDialog';
import diamonds from 'src/components/PersonalCompensation/components/images/day5.svg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';

const MgdalorCommunityMarketplace = () => {
  const { currentUser } = useCurrentUserContext();
  const [isAddProductOpen, setAddProductOpen] = useState(false);
  const [marketplaceProducts, setMarketplaceProducts] = useState();
  const [isVerifyPurchaseOpen, setVerifyPurchaseOpen] = useState(false);
  const [savedProducts, setSavedProducts] = useState();
  const [isPurchasesDialogOpen, setPurchasesDialogOpen] = useState(false);
  const [isSavedProductsDialogOpen, setSavedProductDialogOpen] =
    useState(false);
  const [marketplaceView, setMarketplaceView] = useState('all');

  const handleOpenAddProduct = (productToEdit) => {
    if (productToEdit) {
      setAddProductOpen(productToEdit);
    } else {
      setAddProductOpen(!isAddProductOpen);
    }
  };
  const handleOpenVerifyPurchaseRequest = (productToVerify) => {
    setVerifyPurchaseOpen(productToVerify);
  };
  const handleCloseVerifyPurchaseDialog = () => {
    setVerifyPurchaseOpen(false);
  };

  const retrieveUserSavedProducts = async () => {
    const savedProductsRef = collection(
      db,
      `users/${currentUser.user_doc_id}/saved_products`
    );
    const q = query(savedProductsRef, where('bookmarked', '==', true));
    return getDocs(q).then((savedProductsSnap) => {
      if (savedProductsSnap.empty) {
        // setSavedProducts([]);
        return [];
      }
      const saved = savedProductsSnap.docs.map((savedProduct) => ({
        ...savedProduct.data()
      }));
      // setSavedProducts(saved);
      return saved;
    });
  };

  const getBookmarked = (list) =>
    list.map((product) => {
      const indexInSaved = savedProducts.findIndex(
        (el) => el.product_id === product.id
      );
      let bookmarked;
      if (indexInSaved > -1) {
        bookmarked = savedProducts[indexInSaved].bookmarked;
      } else {
        bookmarked = false;
      }
      return {
        ...product.data(),
        product_id: product.id,
        bookmarked
      };
    });

  const getUserProducts = async () => {
    const marketplaceRef = collection(db, 'marketplace');
    const myProducts = query(
      marketplaceRef,
      where('seller_id', '==', currentUser.user_doc_id)
    );
    return getDocs(myProducts)
      .then((userProducts) => {
        if (userProducts.empty) {
          return [];
        }
        const temp = getBookmarked(userProducts.docs);
        return temp;
        // userProducts.docs.map((snap) => ({ ...snap.data() }));
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };

  const getAllMarketplaceProducts = async () => {
    const marketplaceRef = collection(db, 'marketplace');
    const q = query(marketplaceRef, where('published', '==', true), limit(100));

    return getDocs(q)
      .then(async (productsDocs) => {
        if (productsDocs.empty) {
          // setMarketplaceProducts([]);
          return [];
        }
        const temp = getBookmarked(productsDocs.docs);
        return temp;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };

  const retrieveMarketplaceProducts = async () => {
    // const userProducts = await getUserProducts();
    const allProducts = await getAllMarketplaceProducts();
    setMarketplaceProducts(allProducts);
  };

  const handleCloseAddProduct = () => {
    setAddProductOpen(false);
  };

  const handleUpdateLocalSavedProductsState = (newState, product_id) => {
    const tempSaved = [...savedProducts];
    const indexInSaved = tempSaved.findIndex(
      (el) => el.product_id === product_id
    );
    if (newState) {
      if (indexInSaved === -1) {
        tempSaved.push({
          bookmarked: true,
          product_id,
          saved_at: {
            seconds: moment().unix()
          }
        });
      }
    } else {
      if (indexInSaved === 0) {
        tempSaved.shift();
      } else if (indexInSaved === tempSaved.length - 1) {
        tempSaved.pop();
      } else if (indexInSaved > 0 && indexInSaved < tempSaved.length - 1) {
        tempSaved.splice(indexInSaved, 1);
      }
      setSavedProducts(tempSaved);
    }
  };

  const handleBookmarkProduct = async (product_id) => {
    const temp = [...marketplaceProducts];
    const index = temp.findIndex((el) => el.product_id === product_id);
    if (index > -1) {
      temp[index].bookmarked = !temp[index].bookmarked;
      const filtered = temp.filter((el) => el.bookmarked);
      setSavedProducts(filtered);
      handleUpdateLocalSavedProductsState(temp[index].bookmarked, product_id);
      setMarketplaceProducts(temp);

      const { user_doc_id } = currentUser;
      const savedProductsRef = doc(
        db,
        `users/${user_doc_id}/saved_products/${product_id}`
      );
      setDoc(savedProductsRef, {
        saved_at: serverTimestamp(),
        product_id,
        bookmarked: temp[index].bookmarked
      }).catch((err) => console.log(err));
    }
  };
  const handleOpenSavedProductsList = () => {
    setSavedProductDialogOpen(true);
  };

  const handleCloseSavedProductsDialog = () => {
    setSavedProductDialogOpen(false);
  };
  const handleOpenPurchasesDialog = () => {
    setPurchasesDialogOpen(true);
  };

  const handleClosePurchasesDialog = () => {
    setPurchasesDialogOpen(false);
  };

  const getSaved = async () => {
    const saved = await retrieveUserSavedProducts();
    setSavedProducts(saved);
  };

  useEffect(() => {
    if (!savedProducts && currentUser) {
      getSaved();
    }
  }, [savedProducts, currentUser]);

  useEffect(() => {
    if (!marketplaceProducts && currentUser && savedProducts) {
      retrieveMarketplaceProducts();
    }
  }, [marketplaceProducts, currentUser, savedProducts]);

  const retrieveThisUserProducts = async () => {
    const userProducts = await getUserProducts();
    setMarketplaceProducts(userProducts);
  };

  const retrieveAllProducts = async () => {
    const othersProducts = await getAllMarketplaceProducts();
    setMarketplaceProducts(othersProducts);
  };

  const changeMarketplaceProductsView = (newView) => {
    setMarketplaceView(newView);
    if (newView === 'myProducts') {
      retrieveThisUserProducts();
    } else {
      retrieveAllProducts();
    }
  };

  return (
    <>
      <Paper sx={{ p: 3 }}>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'center' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CommunityMarketPlaceToolbar
              openSavedProductsList={handleOpenSavedProductsList}
              savedProducts={savedProducts}
              handleOpenPurchasesDialog={handleOpenPurchasesDialog}
              handleOpenAddProduct={handleOpenAddProduct}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 3, maxWidth: 1200 }}>
            <Box
              sx={{ p: 2, width: '100%', height: '100%', position: 'relative' }}
            >
              <Card
                elevation={0}
                sx={{
                  background: '#1010ff',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  p: 3
                }}
              >
                <Box
                  sx={{
                    fontSize: '28px',
                    textAlign: 'center',
                    p: 2,
                    width: '100%',
                    maxWidth: 400,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                    zIndex: 2,
                    position: 'relative',
                    userSelect: 'none'
                  }}
                >
                  <Typography
                    sx={{
                      background: '#333131',
                      color: 'white',
                      transform: 'rotate(10deg)',
                      fontSize: 'inherit',
                      maxWidth: 240,
                      width: '100%'
                    }}
                  >
                    קנה עכשיו
                  </Typography>
                  <Typography
                    sx={{
                      background: 'white',
                      color: '#1010ff',
                      transform: 'rotate(5deg)',
                      fontSize: 'inherit',
                      px: 2,
                      maxWidth: 240,
                      width: '100%'
                    }}
                  >
                    שלם עם יהלומים
                  </Typography>
                </Box>
              </Card>
              <Box
                sx={{
                  position: 'absolute',
                  right: '15vw',
                  top: 20,
                  width: 150,
                  transform: 'scale(1.6) rotate(25deg)',
                  zIndex: 1,
                  userSelect: 'none'
                }}
                component="img"
                src={diamonds}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Typography variant="h3">
              {marketplaceView === 'all' ? 'כל הפריטים' : 'הפרטים שאני הוספתי'}
            </Typography>
            {marketplaceView === 'all' ? (
              <Typography
                sx={{
                  cursor: 'pointer'
                }}
                variant="body2"
                component="a"
                onClick={() => changeMarketplaceProductsView('myProducts')}
              >
                לפריטים שאני הוספתי
              </Typography>
            ) : (
              <Typography
                sx={{
                  cursor: 'pointer'
                }}
                variant="body2"
                component="a"
                onClick={() => changeMarketplaceProductsView('all')}
              >
                לכל הפריטים
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container spacing={2} xs={12}>
            {marketplaceProducts && marketplaceProducts.length > 0 ? (
              <>
                {marketplaceProducts.map((product) => (
                  <ProductCard
                    product={product}
                    handleOpenVerifyPurchaseRequest={
                      handleOpenVerifyPurchaseRequest
                    }
                    handleOpenAddProduct={handleOpenAddProduct}
                    handleBookmarkProduct={handleBookmarkProduct}
                    marketplaceView={marketplaceView}
                    key={`product-card-${product.seller_id}-${uniqueId(
                      product.product_name
                    )}`}
                  />
                ))}
              </>
            ) : marketplaceProducts && marketplaceProducts.length === 0 ? (
              <Grid item xs={12}>
                <Typography>טרם נוספו מוצרים</Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography>נא להמתין...</Typography>
                </Grid>
                <Backdrop
                  sx={{ zIndex: 4 }}
                  open={!marketplaceProducts}
                  in={!marketplaceProducts}
                >
                  <CircularProgress />
                </Backdrop>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      {isAddProductOpen && (
        <AddProductToMarketplaceDialog
          marketplaceProducts={marketplaceProducts}
          setMarketplaceProducts={setMarketplaceProducts}
          open={isAddProductOpen}
          onClose={handleCloseAddProduct}
        />
      )}
      {Boolean(isVerifyPurchaseOpen) && (
        <VerifyPurchaseDialog
          marketplaceProducts={marketplaceProducts}
          setMarketplaceProducts={setMarketplaceProducts}
          open={Boolean(isVerifyPurchaseOpen)}
          onClose={handleCloseVerifyPurchaseDialog}
          product={isVerifyPurchaseOpen}
        />
      )}
      {isSavedProductsDialogOpen && (
        <SavedProductsDialog
          open={isSavedProductsDialogOpen}
          handleBookmarkProduct={handleBookmarkProduct}
          onClose={handleCloseSavedProductsDialog}
          savedProducts={savedProducts}
          handleOpenVerifyPurchaseRequest={handleOpenVerifyPurchaseRequest}
        />
      )}

      {isPurchasesDialogOpen && (
        <PurchaseHistoryDialog
          open={isPurchasesDialogOpen}
          onClose={handleClosePurchasesDialog}
        />
      )}
    </>
  );
};

export default MgdalorCommunityMarketplace;
