import algoliasearch from 'algoliasearch';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import getLastSearchFromLS from 'src/utils/getLastSearchFromLS';

const SearchDataContext = createContext();

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_ID,
  process.env.REACT_APP_ALGOLIA_MGDALOR_DEV_KEY
);
const searchIndex = searchClient.initIndex(
  process.env.REACT_APP_ALGOLIA_INDEX_NAME
);

export const SearchDataProvider = ({ children }) => {
  SearchDataProvider.propTypes = {
    children: PropTypes.any
  };

  const [searchQuery, setSearchQuery] = useState({
    text: getLastSearchFromLS() ? getLastSearchFromLS().searchAddress : ''
  });
  const [searchResults, setSearchResults] = useState();
  const [areResultsLoading, setResultsAreLoading] = useState(false);
  const [sliderDistanceValue, setSliderDistanceValue] = useState(
    getLastSearchFromLS() ? getLastSearchFromLS().distanceRadius : 10
  );
  const [isSearchFieldsDisabled, setIsSearchFieldsDisabled] = useState(false);

  useEffect(() => {
    const searchStorage = localStorage.getItem('search');
    if (searchStorage) {
      const parsed = JSON.parse(searchStorage);
      if (parsed && parsed.length > 0) {
        const firstSearch = parsed[0];
        setSearchQuery({ ...searchQuery, text: firstSearch.searchAddress });
      } else {
        setSearchQuery({ ...searchQuery, text: '' });
      }
    }
  }, [window.location.pathname]);

  const searchQueryData = useMemo(
    () => ({
      searchQuery,
      setSearchQuery,
      searchResults,
      setSearchResults,
      searchIndex,
      searchClient,
      sliderDistanceValue,
      setSliderDistanceValue,
      areResultsLoading,
      setResultsAreLoading,
      isSearchFieldsDisabled,
      setIsSearchFieldsDisabled
    }),
    [
      searchQuery,
      setSearchQuery,
      searchResults,
      setSearchResults,
      searchIndex,
      searchClient,
      sliderDistanceValue,
      setSliderDistanceValue,
      areResultsLoading,
      setResultsAreLoading,
      isSearchFieldsDisabled,
      setIsSearchFieldsDisabled
    ]
  );

  return (
    <SearchDataContext.Provider value={searchQueryData}>
      {children}
    </SearchDataContext.Provider>
  );
};
export const useSearchData = () => useContext(SearchDataContext);
