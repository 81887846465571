import emailReminderForPracticeSignature from './emailReminderForPracticeSignature';
import employeeJoinRequestApproved from './employeeJoinRequestApproved';
import employeeJoinRequestRejection from './employeeJoinRequestRejection';
import newAssessmentRequestEmailToMgdalor from './newAssessmentRequestEmailToMgdalor';
import newBranchRequestFromParent from './newBranchRequestFromParent';
import newContactFromOrgPage from './newContactFromOrgPage';
import newContactSendToOrgAfterApprove from './newContactSendToOrgAfterApprove';
import newCustomerPhoneNumber from './newCustomerPhoneNumber';
import passwordResetEmailTemplate from './passwordResetEmail';
import sendReferralEmail from './sendReferralEmail';
import sendSignEvaluationEmailToEmployee from './sendSignEvaluationEmailToEmployee';

const emailTemplates = {
  newCustomerPhoneNumber,
  emailReminderForPracticeSignature,
  passwordResetEmailTemplate,
  newBranchRequestFromParent,
  newContactFromOrgPage,
  newContactSendToOrgAfterApprove,
  sendReferralEmail,
  employeeJoinRequestApproved,
  employeeJoinRequestRejection,
  newAssessmentRequestEmailToMgdalor,
  sendSignEvaluationEmailToEmployee
};

export default emailTemplates;
