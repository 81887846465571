import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import RedeemDiamonds from './RedeemDiamonds';

const RedeemDiamondsDialog = ({ onClose, isRedeemDialogOpened }) => {
  RedeemDiamondsDialog.propTypes = {
    onClose: PropTypes.func,
    isRedeemDialogOpened: PropTypes.bool
  };
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={isRedeemDialogOpened}
      onClose={onClose}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="inherit">המרת יהלומים לשוברים</Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <RedeemDiamonds />
      </DialogContent>
    </Dialog>
  );
};

export default RedeemDiamondsDialog;
