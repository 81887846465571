import { ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Collapse, Icon, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  ...rest
}) {
  const linkComponent = {
    component: 'a',
    href,
    target: '_blank',
    rel: 'noreferrer'
  };

  const routeComponent = {
    component: Link,
    to: route,
    style: {
      '&:visited': {
        color: 'inherit'
      }
    }
  };

  return (
    <>
      <Box
        {...rest}
        mx={2}
        p={1}
        display="flex"
        alignItems="flex-start"
        color={light ? 'white' : 'dark'}
        opacity={light ? 1 : 0.6}
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
          textDecoration: 'none',
          '&:visited': {
            textDecoration: 'none'
          }
        }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        <Typography
          variant="body2"
          lineHeight={1}
          color="inherit"
          sx={{ alignSelf: 'center', '& *': { verticalAlign: 'middle' } }}
        >
          {icon}
        </Typography>
        <Typography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? 'white' : 'dark'}
          sx={{ fontWeight: '100%', ml: 1, mr: 0.25, fontSize: '105%' }}
        >
          {name}
        </Typography>
        <Typography variant="body1" color={light ? 'white' : 'dark'} ml="auto">
          <Icon sx={{ fontWeight: '', verticalAlign: 'middle' }}>
            {collapse && <ExpandMoreOutlined size="small" />}
          </Icon>
        </Typography>
      </Box>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  href: '',
  route: ''
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired
};

export default DefaultNavbarDropdown;
