import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import loadPdf from './loadPdf';
import './viewPractice.css';

const RenderFileComponent = ({ file_name, url }) => {
  RenderFileComponent.propTypes = {
    file_name: PropTypes.string,
    url: PropTypes.string
  };

  useEffect(() => {
    if (url) {
      loadPdf(url);
    }
  }, [url]);

  return (
    <div
      style={{
        height: '100%'
      }}
    >
      <Typography sx={{ position: 'relative', mb: 1 }}>
        אם אינך רואה את הקובץ כראוי&nbsp;
        <Typography
          sx={{ textDecoration: 'underline' }}
          component="a"
          target="_blank"
          rel="noreferrer noopener"
          href={url}
        >
          לחץ כאן
        </Typography>
      </Typography>
      <div
        // className="webviewer"
        id="pdfPracticeViewer"
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default RenderFileComponent;
