import { Box } from '@mui/material';

const BgBlob = () => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: -1,
      width: '50%',
      // maxHeight: '50vh',
      height: '100%',
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'flex-end'
    }}
  >
    <svg
      style={{
        position: 'relative',
        zIndex: -1,
        opacity: 0.2,
        maxWidth: '400px'
      }}
      id="10015.io"
      viewBox="0 0 480 480"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#474bff"
        stopColor="rgb(255,255,0)"
        stopOpacity={1}
        d="M385.5,292Q383,344,339.5,378.5Q296,413,240.5,411.5Q185,410,117,394.5Q49,379,40.5,309.5Q32,240,58.5,183.5Q85,127,132,90Q179,53,234,71.5Q289,90,331.5,116.5Q374,143,381,191.5Q388,240,385.5,292Z"
      />
    </svg>
  </Box>
);

export default BgBlob;
