import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Grow,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import rose from 'src/assets/testimonials/rose.svg';
import theme from 'src/theme';

const TestimonialsSlider = ({ activeSlide, slides, handleChangeSlide }) => {
  TestimonialsSlider.propTypes = {
    activeSlide: PropTypes.number,
    slides: PropTypes.array,
    handleChangeSlide: PropTypes.func
  };
  // const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card
      sx={{
        flex: 1,
        position: 'absolute',
        zIndex: 4,
        display: 'flex',
        // height: 'fit-content',
        width: '90%',
        maxWidth: '900px',
        backgroundColor: alpha(theme.palette.success.light, 0.3),
        mx: 'auto',
        backdropFilter: 'blur(2px)',
        mt: 16,
        // maxHeight: matchDownMd ? 'auto' : '100%',
        overflow: 'auto',
        alignItems: 'center',
        flexDirection: 'column',
        overflowX: 'hidden'
      }}
      elevation={12}
    >
      {slides.map((slide, i) => (
        <CardContent
          key={slide.id}
          sx={{
            display: activeSlide !== i && 'none',
            // height: '400px',
            width: '100%'
          }}
        >
          <Grid
            sx={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            container
            spacing={2}
          >
            <Grow in={activeSlide === i}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '100%',
                  alignItems: 'center',
                  my: 'auto'
                }}
              >
                <Typography
                  sx={{
                    ':before': {
                      content: 'open-quote',
                      fontSize: '3rem'
                    }
                  }}
                />
                <Typography
                  sx={{
                    height: '300px',
                    overflow: 'auto',
                    width: '100%',
                    textAlign: 'justify',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  variant="h3"
                >
                  {slide.content}
                </Typography>
                <Typography
                  sx={{
                    ':after': {
                      content: 'close-quote',
                      fontSize: '3rem'
                    }
                  }}
                />
              </Grid>
            </Grow>
            <Slide direction="right" in={activeSlide === i}>
              <Grid item xs={12} sm={12} md={4}>
                <Box
                  sx={{
                    position: 'relative',
                    width: 'fit-content',
                    m: 'auto'
                  }}
                >
                  <CardMedia
                    className="img"
                    sx={{
                      zIndex: '2 !important',
                      objectFit: 'cover',
                      borderRadius: '25%',
                      width: '80vw',
                      maxWidth: '250px',
                      minWidth: '170px',
                      // height: matchDownMd ? '300px' : '500px',
                      height: '40vh',
                      // maxHeight: '600px',
                      boxShadow: theme.shadows[12],
                      border: `2px dashed ${theme.palette.grey[400]}`,
                      mx: 'auto',
                      position: 'relative'
                    }}
                    src={slide.img}
                    component="img"
                    loading="lazy"
                    alt="circular testimonial"
                  />
                  <CardMedia
                    alt="shapes"
                    component="img"
                    src={rose}
                    loading="lazy"
                    sx={{
                      position: 'absolute',
                      zIndex: 1,
                      top: -3,
                      left: '-22px',
                      width: '60px',
                      height: '60px',
                      filter: 'opacity(0.8)',
                      transform: 'scaleX(-1) rotate(-15deg)'
                      //   mx: 'auto'
                    }}
                  />
                </Box>
              </Grid>
            </Slide>
          </Grid>
        </CardContent>
      ))}
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          // bottom: matchMdDown ? 0 : '-130px',
          gap: 2
        }}
      >
        <Stack direction="row" gap={1}>
          {slides.map((s, i) => {
            const isSelected = activeSlide === i;
            return (
              <Box
                sx={{
                  width: isSelected ? '30px' : '10px',
                  height: '4px',
                  borderRadius: '4px',
                  backgroundColor: isSelected
                    ? theme.palette.success.main
                    : theme.palette.action.disabled,
                  transition: 'all 0.5s'
                }}
                key={uniqueId('slides')}
                onClick={() => handleChangeSlide(i)}
              />
            );
          })}
        </Stack>
        <ButtonGroup sx={{ boxShadow: theme.shadows[12] }} variant="outlined">
          <Button
            onClick={() => handleChangeSlide(1)}
            sx={{
              ':hover': {
                boxShadow: theme.shadows[4]
              }
            }}
          >
            <ChevronRightOutlined />
          </Button>
          <Button
            sx={{
              ':hover': {
                boxShadow: theme.shadows[4]
              }
            }}
            onClick={() => handleChangeSlide(-1)}
          >
            <ChevronLeftOutlined />
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default TestimonialsSlider;
