import {
    collection,
    getDocs,
    limit,
    orderBy,
    query
} from '@firebase/firestore';
import moment from 'moment';
import { db } from 'src/_firebase/firebase';

const checkIfUserClaimedTodaysDiamonds = async (user_doc_id) => {
  const userLoginsCollection = collection(
    db,
    `users/${user_doc_id}/loginPrizes`
  );
  const q = query(
    userLoginsCollection,
    orderBy('login_date', 'desc'),
    limit(1)
  );

  try {
    const loginsDocs = await getDocs(q);
    if (loginsDocs.empty) {
      return false;
    }

    const { login_date } = loginsDocs.docs[0].data();
    const lastLoginDate = moment(login_date.seconds * 1000);
    const today = moment();

    const isSameDay = lastLoginDate.isSame(today, 'day');
    // console.log('Last Login:', lastLoginDate.format('YYYY-MM-DD'));
    // console.log('Today:', today.format('YYYY-MM-DD'));

    return isSameDay;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export default checkIfUserClaimedTodaysDiamonds;
