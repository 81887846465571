import { isArray, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const CCTVCompare = ({ StyledTableCell, list, userscrolledhorizontally }) => {
  CCTVCompare.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.object,
    userscrolledhorizontally: PropTypes.bool
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  const handleCctv = (cctv) => {
    if (isArray(cctv)) {
      const labels = cctv.map((c) => i18n.t(`cctv.${c.trim()}`)).join(', ');
      return labels;
    }
    return t('weDontHave');
  };

  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="cctv" />
        {t('cctv')}
      </StyledTableCell>
      {list.names && (
        <>
          {list.cctv
            ? list.cctv.map((cctvExists) => (
                <StyledTableCell
                  key={`cctvExists-treatment-${uniqueId(cctvExists)}`}
                  align="center"
                >
                  {handleCctv(cctvExists)}
                </StyledTableCell>
              ))
            : list.names.map((name) => (
                <StyledTableCell key={`no-data-${name}`} align="center">
                  {t('noData')}
                </StyledTableCell>
              ))}
        </>
      )}
    </>
  );
};

export default CCTVCompare;
