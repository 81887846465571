import {
  collection,
  getDocs,
  orderBy,
  query,
  where
} from '@firebase/firestore';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import getOrgRef from 'src/utils/getOrgRef';
import { useSelectedOrg } from '../useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const OrgTicketsContext = createContext();

export const OrgTicketsProvider = ({ children }) => {
  OrgTicketsProvider.propTypes = {
    children: PropTypes.any
  };
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const [orgTickets, setOrgTickets] = useState();
  const memoOrgTickets = useMemo(
    () => ({ orgTickets, setOrgTickets }),
    [orgTickets, setOrgTickets]
  );

  const handleRetrieveOrgTickets = async () => {
    // const { user_doc_id } = currentUser;
    // let org_ref;
    // if (currentUser.type === 'org') {
    //   org_ref = user_doc_id;
    // } else if (currentUser.type === 'personal') {
    //   if () {
    //     org_ref = selectedOrg;
    //   }
    // }
    const org_ref = getOrgRef(currentUser, selectedOrg);
    // console.log(org_ref);
    const ticketsRef = collection(db, `contactTickets/${org_ref}/tickets`);
    const q = query(
      ticketsRef,
      orderBy('sent_at', 'desc'),
      where('status', 'in', [
        'sent',
        'approved',
        'patient_was_accepted',
        'patient_was_rejected'
      ]) // to forbid from retrieving tickets that still did not reach the org
    );
    await getDocs(q)
      .then((approvedTickets) => {
        if (approvedTickets.empty) {
          setOrgTickets([]);
        } else {
          const temp = [];
          approvedTickets.docs.forEach((approvedTicket) => {
            temp.push({
              ...approvedTicket.data(),
              ticket_id: approvedTicket.id
            });
          });
          setOrgTickets(temp);
          // setTicketToExamine(temp[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        setOrgTickets([]);
      });
  };

  useEffect(() => {
    if (currentUser && !orgTickets) {
      const { type } = currentUser;
      // console.log(type);
      if (type === 'org') {
        handleRetrieveOrgTickets();
      } else {
        if (selectedOrg) {
          handleRetrieveOrgTickets();
        }
      }
    }
  }, [currentUser, orgTickets]);
  return (
    <OrgTicketsContext.Provider value={memoOrgTickets}>
      {children}
    </OrgTicketsContext.Provider>
  );
};
export const useOrgTickets = () => useContext(OrgTicketsContext);
