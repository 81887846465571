import { collection, getDocs, query, where } from '@firebase/firestore';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import calcOnBoardingProgress from 'src/utils/calcOnBoardingProgress';
import getOrgRef from 'src/utils/getOrgRef';
import { useCompensationEngine } from '../useCompensationEngine/useCompensationEngine';
import { useNotifications } from '../useNotificationsEngine/useNotificationsEngine';
import { useSelectedOrg } from '../useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const EmployeesContext = createContext();

export const EmployeesDataProvider = ({ children }) => {
  EmployeesDataProvider.propTypes = {
    children: PropTypes.any
  };
  const { compensationEngine, setCompensationEngine } = useCompensationEngine();
  const { notifications, setNotifications } = useNotifications();
  const { selectedOrg } = useSelectedOrg();
  const { currentUser } = useCurrentUserContext();
  const [employeesData, setEmployeesData] = useState();
  const [employeesJoinRequests, setEmployeesJoinRequests] = useState();
  const memoEmployeesData = useMemo(
    () => ({
      employeesData,
      setEmployeesData,
      employeesJoinRequests,
      setEmployeesJoinRequests
    }),
    [
      employeesData,
      setEmployeesData,
      employeesJoinRequests,
      setEmployeesJoinRequests
    ]
  );

  useEffect(() => {
    if (employeesData && employeesData.length > 0) {
      const employeesOnboardingProgress = employeesData.map((emp) => ({
        progress:
          emp && emp.org_specific_details && emp.org_specific_details.onboarding
            ? calcOnBoardingProgress(emp.org_specific_details.onboarding)
            : 0,
        employee_id: emp.id,
        employee_ref: emp.doc_id
      }));
      if (employeesOnboardingProgress.length > 0) {
        setCompensationEngine({
          ...compensationEngine,
          employeesWithUnfinishedOnboarding: employeesOnboardingProgress.filter(
            (el) => el.progress < 100
          )
        });
      } else {
        setCompensationEngine({
          ...compensationEngine,
          employeesWithUnfinishedOnboarding: employeesData.length
        });
      }
    }
  }, [employeesData]);

  const checkEmployeesJoinRequests = async () => {
    if (currentUser && currentUser.user_doc_id) {
      const org_ref = getOrgRef(currentUser, selectedOrg);
      if (
        (currentUser.type === 'personal' &&
          selectedOrg &&
          (selectedOrg !== '' || selectedOrg !== 'null')) ||
        currentUser.type === 'org'
      ) {
        const dbRef = collection(db, `orgs/${org_ref}/employees`);
        const q = query(dbRef, where('status', '==', 'employee_requested'));
        await getDocs(q).then((res) => {
          if (res.empty) {
            setEmployeesJoinRequests([]);
          } else {
            const requestsData = res.docs.map((requestDoc) => ({
              ...requestDoc.data()
            }));
            setEmployeesJoinRequests(requestsData);
            setNotifications({
              ...notifications,
              employees: {
                employees_requests: res.size
              }
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      checkEmployeesJoinRequests();
    }
  }, [currentUser]);

  return (
    <EmployeesContext.Provider value={memoEmployeesData}>
      {children}
    </EmployeesContext.Provider>
  );
};
export const useEmployeesData = () => useContext(EmployeesContext);
