const getOrgRef = (user, selectedOrg) => {
  if (user && user.type === 'org') {
    return user.user_doc_id;
  }
  if (user && user.type === 'personal') {
    return selectedOrg;
  }
};

export default getOrgRef;
