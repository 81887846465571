const getUserJobTitle = (userData, org) => {
  const { employee_work_places } = userData;
  if (employee_work_places) {
    const index = employee_work_places.findIndex((el) => el.org_ref === org);
    if (index > -1) {
      const { org_specific_details } = employee_work_places[index];
      return org_specific_details.job_title;
    }
    return null;
  }
  return null;
};

export default getUserJobTitle;
