const handleAdoptionForCompareTable = async (listOfOrgs) => {
  const tempDataToCompare = {
    names: [],
    tags: [],
    pricing: [],
    available_funds: [],
    kosher: [],
    docs: [],
    avatars: [],
    orgs_pictures: [],
    iv_treatment: [],
    kitchen: [],
    cctv: [],
    fall_prevention: [],
    phones: {}
  };

  await listOfOrgs.forEach(async (eachOrg) => {
    tempDataToCompare.names.push(eachOrg.org_name);
    tempDataToCompare.tags.push(eachOrg.tags);
    tempDataToCompare.available_funds.push(eachOrg.available_funds);
    tempDataToCompare.pricing.push(eachOrg.pricing);
    tempDataToCompare.kosher.push(eachOrg.kosher);
    tempDataToCompare.avatars.push(eachOrg.avatar);
    tempDataToCompare.iv_treatment.push(eachOrg.iv_treatment);
    tempDataToCompare.kitchen.push(eachOrg.kitchen);
    // console.log(eachOrg.cctv);
    tempDataToCompare.cctv.push(eachOrg.cctv ? eachOrg.cctv.sort() : []);
    tempDataToCompare.fall_prevention.push(eachOrg.fall_prevention);
    tempDataToCompare.docs.push(eachOrg.user_doc_id);
    tempDataToCompare.phones = {
      ...tempDataToCompare.phones,
      [eachOrg.org_name]: eachOrg.internal_phone
    };
  });
  // console.log(dataToCompare);
  return tempDataToCompare;
};

export default handleAdoptionForCompareTable;
