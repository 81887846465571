const passwordResetEmailTemplate = (
  link
) => `<table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
        style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
        <tr>
            <td>
                <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                    align="center" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                          <a href="https://www.mgdalor.com" title="logo" target="_blank">
                            <img width="120" src="https://www.mgdalor.com/static/media/mgdalorFull.6d3bd1d8daf7de804ec8.png" title="logo" alt="logo">
                          </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                              
                    <tr>
                        <td style="text-align:center;">
                          <a href="https://www.mgdalor.com" title="logo" target="_blank">
                            <img width="120" src="https://firebasestorage.googleapis.com/v0/b/mgdalor-72876/o/assets%2Fsheild.png?alt=media&token=1df33769-9bb7-47a7-83ac-630429162269" title="logo" alt="logo">
                          </a>
                        </td>
                    </tr>
                                <tr>
                                    <td style="padding:0 35px;">
                                        <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">ביקשת לשחזר את הסיסמה שלך</h1>
                                        <span
                                            style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                        <p style="color:#455056; font-size:15px;line-height:24px; margin:0;text-align:justify;direction:rtl;">
                                           כדי שתוכל לשנות את הסיסמה, יצרנו עבורך קישור שהשימוש בו מוגבל בזמן, לכן נא לשחזר את הסיסמה במהלך ה-30 דקות הקרובות.
                                           לחץ על כפתור שינוי סיסמה ולאחר מכן, הכנס את הסיסמה החדשה לפי ההנחיות המופיעות באתר ולאחר שמירת הסיסמה החדשה תוכל להשתמש בה כדי להיכנס לחשבון האישי שלך במערכת מגדלור
                                        </p>
                                        <a href='${link}'
                                            style="background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">לשינוי הסיסמה</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>www.mgdalor.com</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>`;

export default passwordResetEmailTemplate;
