import { doc, increment, updateDoc } from '@firebase/firestore';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { auth, db, functions } from 'src/_firebase/firebase';
import commonConfig from 'src/common/config';
import diamonds from 'src/common/diamonds';
import handleCreateNewOrg from 'src/utils/handleCreateNewOrg';
import handleCreateNewPersonalAccount from 'src/utils/handleCreateNewPersonalAccount';

const VerifyUser = ({ isOpen, closeDialog, newUserValues, userType }) => {
  VerifyUser.propTypes = {
    isOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    newUserValues: PropTypes.object,
    userType: PropTypes.string
  };
  const [createUserWithEmailAndPassword] =
    useCreateUserWithEmailAndPassword(auth);
  const { phone } = newUserValues;
  const { t } = useTranslation('translation', {
    keyPrefix: 'register.verifyDialog'
  });
  const [verifyCodeInput, setVerifyCodeInput] = useState();
  const [verifyCodeError, setVerifyCodeError] = useState();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheckCode = (e) => {
    setLoading(true);
    // check if code is correct, if not then display error, if correct register the user using the type specific function
    const checkVerifyCode = httpsCallable(functions, 'checkVerifyCode');
    checkVerifyCode({
      verify_code_input: verifyCodeInput,
      phone: `+972${phone}`
    })
      .then(async (res) => {
        const { data } = res;
        if (data.codeIsCorrect) {
          // then handle creating
          if (userType === 'personal') {
            const referralData = window.sessionStorage.getItem('referralData');
            if (referralData) {
              const parsed = JSON.parse(referralData);
              const { referralDoc } = parsed;
              newUserValues.referred_by = referralDoc;
              const referralRef = doc(db, `users/${referralDoc}`);
              updateDoc(referralRef, {
                diamonds: increment(diamonds.friendReferral)
              }).catch((err) => console.log(err));
            }
            // console.log(newUserValues, 'correct');
            await handleCreateNewPersonalAccount(
              newUserValues,
              createUserWithEmailAndPassword,
              userType
            ).catch((err) => {
              setLoading(false);
              console.log('err while creating new personal account', err);
            });
          } else {
            const { password, ...rest } = newUserValues;
            handleCreateNewOrg(rest, e)
              .then(async () => {
                await createUserWithEmailAndPassword(
                  `${newUserValues.id}@${commonConfig.emailAuthSuffix}`,
                  password
                ).catch((err) => {
                  setLoading(false);
                  console.log('err creating user', err);
                });
              })
              .catch((err) => {
                setLoading(false);
                console.log('err', err);
              });
            // handleCreateNewOrg(newUserValues, e);
          }
        } else {
          setVerifyCodeError('הקוד שגוי, נא לנסות שוב');
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err while checking verify code', err);
        setLoading(false);
        setVerifyCodeError('נא לנסות שוב');
      });
    // if (userType === 'personal') {
    //   handleCreateNewPersonalAccount();
    // } else {
    //   handleCreateNewOrg();
    // }
  };
  const handleVerifyCodeChange = (e) => {
    setVerifyCodeInput(e.target.value);
  };
  return (
    <>
      <Dialog maxWidth="xs" fullWidth open={Boolean(true)}>
        <DialogTitle>{t('chooseYourPreferance')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('aCodeWasSentToYourPhone')}</DialogContentText>
          <DialogContentText>{`${t('sentTo')}: ${phone}`}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="verifyCode"
            label={t('verifyCode')}
            type="text"
            onChange={handleVerifyCodeChange}
            fullWidth
          />
          {verifyCodeError && (
            <Typography
              sx={{
                color: (theme) => theme.palette.error.dark
                // textAlign: 'center'
              }}
            >
              {verifyCodeError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t('cancel')}</Button>
          <Button disabled={loading} onClick={handleCheckCode}>
            {t('verify')}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Backdrop sx={{ zIndex: (theme) => 1800 }} open={loading}>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default VerifyUser;
