import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Slide
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import theme from 'src/theme';

const Slider = ({
  slides,
  showNumbering,
  sliderSx,
  singleSlideSx,
  slideContainerSx
}) => {
  Slider.propTypes = {
    slides: PropTypes.array.isRequired,
    showNumbering: PropTypes.bool,
    sliderSx: PropTypes.object,
    singleSlideSx: PropTypes.object,
    slideContainerSx: PropTypes.object
  };

  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeSlide = (diff) => {
    const active = activeSlide + diff;
    if (active > slides.length - 1) {
      setActiveSlide(0);
    } else if (active < 0) {
      setActiveSlide(slides.length - 1);
    } else {
      setActiveSlide(active);
    }
  };
  return (
    <Card
      sx={{
        minHeight: '170px',
        width: '100%',
        height: '100%',
        background: 'transparent',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        ...sliderSx
      }}
      elevation={0}
    >
      {slides.map((slide, i) => (
        <CardContent
          key={slide.name}
          sx={{
            width: '100%',
            position: 'relative',
            height: '250px',
            p: '0 !important',
            display: activeSlide === i ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            ...slideContainerSx
          }}
        >
          {showNumbering && (
            <Box sx={{ position: 'absolute', left: 0, top: 0 }}>
              {`${i + 1}/${slides.length}`}
            </Box>
          )}
          <Slide
            enter
            appear
            easing={{
              enter: '500ms'
            }}
            direction="right"
            in={activeSlide === i}
          >
            <CardMedia
              className="img"
              sx={{
                objectFit: 'cover',
                width: '100%',
                maxWidth: '250px',
                minWidth: '170px',
                height: '100%',
                //   mx: 'auto',
                display: activeSlide === i ? 'block' : 'unset',
                ...singleSlideSx
              }}
              src={slide.url}
              component="img"
              loading="lazy"
              alt="circular testimonial"
            />
          </Slide>
        </CardContent>
      ))}
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <IconButton
          color="primary"
          onClick={() => handleChangeSlide(1)}
          sx={{
            m: '0 !important',
            background: alpha(theme.palette.grey[100], 0.8)
          }}
        >
          <ChevronRightOutlined />
        </IconButton>
        <IconButton
          color="primary"
          sx={{
            m: '0 !important',
            background: alpha(theme.palette.grey[100], 0.8)
          }}
          onClick={() => handleChangeSlide(-1)}
        >
          <ChevronLeftOutlined />
        </IconButton>
        {/* </ButtonGroup> */}
      </CardActions>
    </Card>
  );
};

Slider.defaultProps = {
  sliderSx: {},
  slideContainerSx: {},
  singleSlideSx: {}
};

export default Slider;
