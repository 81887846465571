import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

const PresentationContext = createContext();

export const PresentationProvider = ({ children }) => {
  PresentationProvider.propTypes = {
    children: PropTypes.any
  };
  const [activeView, setActiveView] = useState();
  const [totalNumOfPages, setTotalNumOfPages] = useState();
  const [activePresentation, setActivePresentation] = useState();

  const presentationData = useMemo(
    () => ({
      activeView,
      setActiveView,
      totalNumOfPages,
      setTotalNumOfPages,
      activePresentation,
      setActivePresentation
    }),
    [
      activeView,
      setActiveView,
      totalNumOfPages,
      setTotalNumOfPages,
      activePresentation,
      setActivePresentation
    ]
  );

  return (
    <PresentationContext.Provider value={presentationData}>
      {children}
    </PresentationContext.Provider>
  );
};
export const usePresentation = () => useContext(PresentationContext);
