import { addDoc, collection, serverTimestamp } from '@firebase/firestore';
import { Alert, Button, Grid, Stack, TextField } from '@mui/material';
import { Formik } from 'formik';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { db } from 'src/_firebase/firebase';
import animationData from 'src/assets/lottie/message-sent.json';
import * as Yup from 'yup';

const ContactMgdalorForm = ({
  // handleSaveNewContactForm,
  yourMsgWasSent,
  setYourMsgWasSent
}) => {
  ContactMgdalorForm.propTypes = {
    // handleSaveNewContactForm: PropTypes.func,
    yourMsgWasSent: PropTypes.bool,
    setYourMsgWasSent: PropTypes.func
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });

  const handleSaveNewContactForm = async (values) => {
    // console.log(values);
    const ticketsRef = collection(db, 'contactTickets');
    addDoc(ticketsRef, {
      type: 'mgdalorContactForm',
      ...values,
      created_at: serverTimestamp()
    })
      .then(() => {
        setYourMsgWasSent('success');
      })
      .catch((err) => {
        console.log(err);
        setYourMsgWasSent('failure');
      });
  };
  return (
    <Formik
      initialValues={{
        email: '',
        phone: '',
        contact_first_name: '',
        contact_last_name: '',
        message: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('validEmail')).max(255),
        contact_last_name: Yup.string()
          .max(255)
          .required(t('lastNameRequired')),
        contact_first_name: Yup.string()
          .max(255)
          .required(t('firstNameRequired')),
        phone: Yup.string().max(60).required(t('phoneRequired')),
        message: Yup.string().max(1000)
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        initialValues,
        setTouched,
        initialErrors,
        initialTouched,
        setValues
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          style={{
            width: '100%'
          }}
        >
          <Grid
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto'
            }}
            container
            spacing={2.5}
          >
            {yourMsgWasSent === 'success' ? (
              <Grid
                item
                xs={12}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                <Stack
                  sx={{
                    width: '100%',
                    minHeight: '40vh',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Lottie
                    style={{
                      width: '70% !important',
                      height: '120px',
                      minWidth: '120px'
                      // marginBottom: 0,
                      // marginTop: '24px'
                    }}
                    autoplay
                    animationData={animationData}
                  />
                  <Alert sx={{ margin: 'auto', mt: 1 }} severity="success">
                    {t('yourMsgWasSent')}
                  </Alert>
                  {/* <Typography>{t('haveAnotherTicketToSubmit')}</Typography> */}
                  <Button
                    sx={{
                      minWidth: 'fit-content',
                      width: 200,
                      margin: 'auto'
                    }}
                    variant="outlined"
                    onClick={() => {
                      setValues(initialValues);
                      setTouched(initialTouched);
                      setYourMsgWasSent('init');
                    }}
                  >
                    {t('sendAgain')}
                  </Button>
                </Stack>
              </Grid>
            ) : yourMsgWasSent === 'failure' ? (
              <Alert sx={{ margin: 'auto', mt: 1 }} severity="error">
                {t('weCouldntSendYourMsg')}
              </Alert>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error={Boolean(
                      touched.contact_first_name && errors.contact_first_name
                    )}
                    fullWidth
                    helperText={
                      touched.contact_first_name && errors.contact_first_name
                    }
                    InputProps={{
                      sx: {
                        bgcolor: 'white'
                      }
                    }}
                    required
                    label={t('firstName')}
                    name="contact_first_name"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.contact_first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    error={Boolean(
                      touched.contact_last_name && errors.contact_last_name
                    )}
                    fullWidth
                    // color="info"
                    // sx={{ bgcolor: 'white' }}
                    InputProps={{
                      sx: {
                        bgcolor: 'white'
                      }
                    }}
                    helperText={
                      touched.contact_last_name && errors.contact_last_name
                    }
                    required
                    label={t('lastName')}
                    name="contact_last_name"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.contact_last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    fullWidth
                    required
                    InputProps={{
                      sx: {
                        bgcolor: 'white'
                      }
                    }}
                    label={t('contactPhone')}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const replaced = e.target.value.replace(/[^\d]+/g, '');
                      // console.log(replaced);
                      if (replaced) {
                        handleChange(e);
                      }
                    }}
                    type="phone"
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t('email')}
                    name="email"
                    InputProps={{
                      sx: {
                        bgcolor: 'white'
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    error={Boolean(touched.message && errors.message)}
                    fullWidth
                    helperText={touched.message && errors.message}
                    label={t('message')}
                    name="message"
                    InputProps={{
                      sx: {
                        bgcolor: 'white'
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    type="text"
                    value={values.message}
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  item
                  xs={12}
                  md={12}
                >
                  <Button
                    color="primary"
                    size="large"
                    // type="submit"
                    onClick={() => handleSaveNewContactForm(values)}
                    variant="contained"
                    sx={{ width: '50%', maxWidth: 250 }}
                  >
                    {t('send')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ContactMgdalorForm;
