import { collectionGroup, getDocs, query, where } from '@firebase/firestore';
import { ArrowBackOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  List,
  Stack,
  Typography
} from '@mui/material';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import noSavedProducts from 'src/assets/lottie/org-page-empty.json';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import '../knowledgeCommunity.css';
import PurchaseListItemButton from './PurchaseListItemButton';

const PurchaseHistoryDialog = ({ open, onClose }) => {
  PurchaseHistoryDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
  };
  const { currentUser } = useCurrentUserContext();
  const [purchaseHistory, setPurchaseHistory] = useState();
  const [activeView, setActiveView] = useState({ view: 'list' });

  const retrievePurchaseHistory = async () => {
    const purchasesRef = collectionGroup(db, 'purchases');
    const q = query(
      purchasesRef,
      where('buyer_id', '==', currentUser.user_doc_id)
    );
    getDocs(q).then((snap) => {
      if (snap.empty) {
        setPurchaseHistory([]);
      } else {
        const purchasesData = snap.docs.map((purchase) => ({
          ...purchase.data(),
          purchase_id: purchase.id
        }));
        setPurchaseHistory(purchasesData);
      }
    });
  };

  useEffect(() => {
    if (currentUser && !purchaseHistory) {
      retrievePurchaseHistory();
    }
  }, [currentUser, purchaseHistory]);

  const handleChangeView = (url, product_name) => {
    // console.log(activeView);
    if (activeView.view === 'item') {
      setActiveView({
        view: 'list'
      });
    } else if (activeView.view !== 'item' && url) {
      setActiveView({
        view: 'item',
        src: url,
        product_name
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography component="span" variant="inherit">
          {activeView.product_name
            ? `הצגת הפריט ${activeView.product_name || ''}`
            : 'היסטוריית רכישות'}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {activeView && activeView.view === 'list' ? (
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {!purchaseHistory ? (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            ) : purchaseHistory && purchaseHistory.length === 0 ? (
              <Stack>
                <Lottie
                  style={{
                    //   width: matchDownSm ? '40vw !important' : '15vw !important',
                    height: '15vw',
                    minHeight: '180px',
                    minWidth: '180px !important',
                    width: '100%',
                    opacity: 0.8,
                    margin: 'auto'
                  }}
                  autoplay
                  animationData={noSavedProducts}
                  loop
                />
                <Typography textAlign="center">
                  זה המקום לעקוב אחרי הרכישות שאתה מבצע.
                </Typography>
              </Stack>
            ) : (
              <List sx={{ width: '100%' }} aria-label="saved products">
                {purchaseHistory.map((purchase) => (
                  <PurchaseListItemButton
                    key={`purchaseListItemButton-${purchase.purchase_id}`}
                    purchase={purchase}
                    handleChangeView={handleChangeView}
                  />
                ))}
              </List>
            )}
          </Grid>
        ) : (
          <Grid item container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                endIcon={<ArrowBackOutlined />}
                color="primary"
                onClick={() => handleChangeView()}
              >
                חזרה לרשימה
              </Button>
            </Grid>
            <Grid item xs={12}>
              <iframe
                src={activeView.src}
                width="100%"
                height="100%"
                onSelect={() => false}
                onMouseDown={() => false}
                className="purchase-preview"
                style={{
                  minHeight: '60vh',
                  userSelect: 'none !important',
                  msUserSelect: 'none !important',
                  MozUserSelect: 'none !important'
                  //   WebkitUserSelect: 'none',
                  //   '& *': {
                  //     userSelect: 'none',
                  //     msUserSelect: 'none',
                  //     MozUserSelect: 'none',
                  //     WebkitUserSelect: 'none'
                  //   }
                }}
                title={activeView.product_name || 'purchase'}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          סגירה
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseHistoryDialog;
