import { HighlightOffOutlined } from '@mui/icons-material';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  useMediaQuery
} from '@mui/material';

import PropTypes from 'prop-types';
import theme from 'src/theme';
import ComparisonTable from './ComparisonTable/ComparisonTable';
import './home.css';

const ComparisonTableDialog = ({
  handleClose,
  open,
  list,
  orgsPictures,
  setOrgsPictures
}) => {
  ComparisonTableDialog.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    list: PropTypes.any,
    orgsPictures: PropTypes.any,
    setOrgsPictures: PropTypes.func
  };
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      sx={{ overflow: 'auto' }}
      maxWidth="lg"
      fullWidth={!matchDownSm}
      fullScreen={matchDownSm}
      onClose={() => {
        handleClose();
        setOrgsPictures();
      }}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent !important'
        }
      }}
    >
      <DialogTitle sx={{ display: 'inline-flex' }}>
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: -2
          }}
          onClick={handleClose}
        >
          <HighlightOffOutlined
            fontSize="medium"
            sx={{ color: theme.palette.common.white }}
          />
        </IconButton>
      </DialogTitle>
      {list ? (
        <ComparisonTable
          setOrgsPictures={setOrgsPictures}
          list={list}
          orgsPictures={orgsPictures}
          open={open}
          key="comparison-list-search-results"
        />
      ) : (
        <Backdrop open={!list || (list && list.length === 0)}>
          <CircularProgress />
        </Backdrop>
      )}
    </Dialog>
  );
};

export default ComparisonTableDialog;
