import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useCurrentUserContext } from '../useUserContext/UserContext';

const SelectedOrgContext = createContext();

export const SelectedOrgProvider = ({ children }) => {
  SelectedOrgProvider.propTypes = {
    children: PropTypes.any
  };
  const { currentUser } = useCurrentUserContext();
  const location = useLocation();
  const [selectedOrg, setSelectedOrg] = useState(
    localStorage.getItem('selectedOrg')
  );

  const selectedOrgData = useMemo(
    () => ({ selectedOrg, setSelectedOrg }),
    [selectedOrg, setSelectedOrg]
  );

  const handleSelectNoOrg = () => {
    localStorage.setItem('selectedOrg', '');
    setSelectedOrg('null');
  };
  const getOrgDetails = (orgRef) => {
    const selectedOrgIndex = currentUser.employee_work_places.findIndex(
      (el) => el.org_ref === orgRef
    );
    if (selectedOrgIndex > -1) {
      const { org_specific_details } =
        currentUser.employee_work_places[selectedOrgIndex];
      if (org_specific_details.status === 'approved') {
        setSelectedOrg(orgRef);
      } else {
        handleSelectNoOrg();
      }
    } else {
      handleSelectNoOrg();
    }
  };

  useEffect(() => {
    const selected = localStorage.getItem('selectedOrg');
    if (selected && (selected !== '' || selected !== 'null') && currentUser) {
      if (
        currentUser.employee_work_places &&
        currentUser.employee_work_places.length > 0
      ) {
        const orgDetails = getOrgDetails(selected);
        setSelectedOrg(selected);
      } else {
        handleSelectNoOrg();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    // if the user logged in and there is no selectedorg then selected the first approved
    if (currentUser && (!selectedOrg || selectedOrg === 'null')) {
      const { employee_work_places } = currentUser;
      if (employee_work_places && employee_work_places.length > 0) {
        const firstApproved = employee_work_places.find(
          (el) =>
            el &&
            el.org_specific_details &&
            el.org_specific_details.status === 'approved' &&
            (el.org_status === 'approved' || el.org_status === 'hidden')
        );
        // console.log(firstApproved);
        if (firstApproved) {
          localStorage.setItem('selectedOrg', firstApproved.org_ref);
          setSelectedOrg(firstApproved.org_ref);
        } else {
          handleSelectNoOrg();
        }
      } else {
        handleSelectNoOrg();
      }
    }
  }, [currentUser]);

  return (
    <SelectedOrgContext.Provider value={selectedOrgData}>
      {children}
    </SelectedOrgContext.Provider>
  );
};
export const useSelectedOrg = () => useContext(SelectedOrgContext);
