import { PropTypes } from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

const NotificationsContext = createContext();

export const NotifcationsProvider = ({ children }) => {
  NotifcationsProvider.propTypes = {
    children: PropTypes.any
  };
  const [notifications, setNotifications] = useState();
  const memoNotifications = useMemo(
    () => ({ notifications, setNotifications }),
    [notifications, setNotifications]
  );

  return (
    <NotificationsContext.Provider value={memoNotifications}>
      {children}
    </NotificationsContext.Provider>
  );
};
export const useNotifications = () => useContext(NotificationsContext);
