import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';

const Violence = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
}) => {
  Violence.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    globalCoursewares: PropTypes.array,
    handleSetViewToSignCourseware: PropTypes.func
  };
  return (
    <div
      className="theme-font-montserrat theme-color-sea-yellow"
      style={{ width: '100%', height: '100%' }}
    >
      <div className="reveal violence">
        <div className="slides">
          <section
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675298/BLUE_GEOMETRIC_FLAT_LIQUID_BACKGROUND_generated.jpg"
            data-background-color="rgb(191, 233, 245)"
            data-background-opacity="0.36"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 122, top: 109, height: 'auto' }}
              data-origin-id="456435c7caedb6dcdf09338b6c898569"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '1.0em' }}>
                    מניעת אלימות בקרב
                    <br />
                    דיירי בית האבות
                  </span>
                </h1>
              </div>
            </div>
          </section>
          <section
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675298/BLUE_GEOMETRIC_FLAT_LIQUID_BACKGROUND_generated.jpg"
            data-background-opacity="0.3"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 122, top: 109, height: 'auto' }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <strong>כיצד נלמד?</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 806,
                left: 122,
                top: '292.657px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    ההתקדמות היא באמצעות החצים &lt; או&nbsp; &gt; במצגת
                  </strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 122, top: 425, height: 'auto' }}
              data-name="text-4bdc77"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    כשמגיעים לשקופית שיש בה שאלה, אי אפשר להתקדם עד שעונים נכון
                    על השאלה
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section
            data-auto-animate=""
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675298/BLUE_GEOMETRIC_FLAT_LIQUID_BACKGROUND_generated.jpg"
            data-background-color="rgb(164, 194, 244)"
            data-background-opacity="0.24"
          >
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '206.893px',
                height: '288.95px',
                left: '829.362px',
                top: '507.899px'
              }}
              data-name="drawing-a004c2"
              data-origin-id="e1ce91f5e1c02577201a2ff14bbb5657"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 23, transform: 'rotate(-29deg)' }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{ zIndex: 23 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-85 -50 150 130"
                  >
                    <path
                      fill="#f1e35e"
                      d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '710.965px',
                left: '124.518px',
                top: 106,
                height: 'auto'
              }}
              data-name="text-bad4e5"
              data-origin-id="ed4c49f7336c0dfa693071da7055e18f"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 24,
                  padding: 5,
                  lineHeight: '1.17',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'justify' }}>
                  ברוכים הבאים ללומדה העוסקת במניעת התעללות בקשישים.
                </h2>
                <h2 style={{ textAlign: 'justify' }}>
                  בלומדה זו נסקור את התופעה בפירוט, נלמד כיצד לזהות, לדווח,
                  ולטפל במקרים בהם אנו חושדים שהקשיש חווה/ סובל מאלימות.
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-1a08d3"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '13.0538px 236.879px 0px',
                  zIndex: 10
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '-6.45279px',
                    top: '167.508px'
                  }}
                  data-name="shape-710ec0"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 11 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '18.7025px',
                    top: '167.508px'
                  }}
                  data-name="shape-813a71"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 12 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '-6.45283px',
                    top: '192.663px'
                  }}
                  data-name="shape-7661fc"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '18.7025px',
                    top: '192.663px'
                  }}
                  data-name="shape-c99058"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 14 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '-6.45283px',
                    top: '217.521px'
                  }}
                  data-name="shape-1c490f"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 15 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '18.7025px',
                    top: '217.521px'
                  }}
                  data-name="shape-ac74aa"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '-6.45283px',
                    top: '242.379px'
                  }}
                  data-name="shape-563ec3"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 17 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '18.7024px',
                    top: '242.379px'
                  }}
                  data-name="shape-229606"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 18 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '-6.45287px',
                    top: '267.534px'
                  }}
                  data-name="shape-a0290a"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '18.7024px',
                    top: '267.534px'
                  }}
                  data-name="shape-a740ff"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '-6.45287px',
                    top: '292.392px'
                  }}
                  data-name="shape-71ecf7"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 21 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '13.858px',
                    height: '13.8583px',
                    left: '18.7024px',
                    top: '292.392px'
                  }}
                  data-name="shape-e3e1c6"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(162, 196, 201)"
                    data-shape-stretch="true"
                    style={{ zIndex: 22 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 14 14"
                    >
                      <ellipse
                        rx="6.929"
                        ry="6.92915"
                        cx="6.929"
                        cy="6.92915"
                        className="shape-element"
                        fill="rgb(162, 196, 201)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-c6bd9c"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '911.694px 653.168px 0px',
                  zIndex: 25
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: 899,
                    top: 610
                  }}
                  data-origin-id="a4b6fa85ade919572e9bd2f02b33af6f"
                  data-name="shape-1e7028"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 26,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 26 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '915.37px',
                    top: 610
                  }}
                  data-origin-id="e391c5ca82c55a4e46fdc32e76e6f9a2"
                  data-name="shape-94b037"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 27,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 27 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: 899,
                    top: '625.653px'
                  }}
                  data-origin-id="04e6b1f068999f86508a8920c9336740"
                  data-name="shape-bbdd29"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 28,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 28 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '915.37px',
                    top: '625.653px'
                  }}
                  data-origin-id="aef2d4ecf5b897dfbea747bab1c6ac7a"
                  data-name="shape-f80045"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 29,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 29 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: 899,
                    top: '641.122px'
                  }}
                  data-origin-id="93f784e462d505f12eaa73a77e88d549"
                  data-name="shape-af82ee"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 30,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 30 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '915.37px',
                    top: '641.122px'
                  }}
                  data-origin-id="0c903c471859b32f89f9e43cdd357402"
                  data-name="shape-931bc8"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 31,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 31 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: 899,
                    top: '656.59px'
                  }}
                  data-origin-id="c887dbff9c5e4d803a5a1506566a4c83"
                  data-name="shape-5f42b5"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 32,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 32 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '915.37px',
                    top: '656.59px'
                  }}
                  data-origin-id="12966892d645ab328b6b0cb7916a73dc"
                  data-name="shape-811ad7"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 33,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 33 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: 899,
                    top: '672.243px'
                  }}
                  data-origin-id="ba64005cb0b30686d4515ba6933a4cb5"
                  data-name="shape-619c07"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 34,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 34 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '915.37px',
                    top: '672.243px'
                  }}
                  data-origin-id="221d174743145103d8a754063b04283b"
                  data-name="shape-c5791f"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 35,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 35 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: 899,
                    top: '687.712px'
                  }}
                  data-origin-id="9556c55f849a0a415fba6f8f0d4f5214"
                  data-name="shape-da153b"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 36,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 36 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '915.37px',
                    top: '687.712px'
                  }}
                  data-origin-id="4f4eb49328f7cad481e7ee3279e128af"
                  data-name="shape-864f6b"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 37,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 37 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-e65a05"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '943.306px 653.374px 0px',
                  zIndex: 38
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '930.612px',
                    top: '610.206px'
                  }}
                  data-origin-id="ae90d3db9053ead1b62b365472acfed7"
                  data-name="shape-a03e77"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 39,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 39 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '946.982px',
                    top: '610.206px'
                  }}
                  data-origin-id="7c777b020efcf709cd2395828285976f"
                  data-name="shape-dbf5b5"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 40,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 40 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '930.612px',
                    top: '625.859px'
                  }}
                  data-origin-id="4b43d176e278ec2dd12f15ce2d251d28"
                  data-name="shape-040eb8"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 41,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 41 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '946.982px',
                    top: '625.859px'
                  }}
                  data-origin-id="474107102091ec092de075d7203b0d52"
                  data-name="shape-a44f23"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 42,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 42 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '930.612px',
                    top: '641.328px'
                  }}
                  data-origin-id="19c9fbba53db8c41f94a60018134348a"
                  data-name="shape-4527fa"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 43,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 43 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '946.982px',
                    top: '641.328px'
                  }}
                  data-origin-id="efdd8c6ef9926238c19f7edb5abca2ca"
                  data-name="shape-06c633"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 44,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 44 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '930.612px',
                    top: '656.796px'
                  }}
                  data-origin-id="f688073d81a9c578a762e87e0256cbca"
                  data-name="shape-c97dd1"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 45,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 45 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '946.982px',
                    top: '656.796px'
                  }}
                  data-origin-id="6abc8d3e307e4b6c26a2ba6374bef902"
                  data-name="shape-b97c7a"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 46,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 46 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '930.612px',
                    top: '672.449px'
                  }}
                  data-origin-id="66c103288089ee80f08bf05efe24d204"
                  data-name="shape-9f2db5"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 47,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 47 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '946.982px',
                    top: '672.449px'
                  }}
                  data-origin-id="60133d416b3f74631777cf93e78a4007"
                  data-name="shape-5b4022"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 48,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 48 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '930.612px',
                    top: '687.918px'
                  }}
                  data-origin-id="e37beefccdf30f4bad732ecb75252af9"
                  data-name="shape-ca8eac"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 49,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 49 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '9.01789px',
                    height: '8.62362px',
                    left: '946.982px',
                    top: '687.918px'
                  }}
                  data-origin-id="26380b3c25721462bce98ca176cc9f63"
                  data-name="shape-a5b25e"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 50,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-shape-type="circle"
                      data-shape-fill-color="rgb(194, 123, 160)"
                      data-shape-stretch="true"
                      style={{ zIndex: 50 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 9 9"
                      >
                        <ellipse
                          rx="4.508945"
                          ry="4.31181"
                          cx="4.508945"
                          cy="4.31181"
                          className="shape-element"
                          fill="rgb(194, 123, 160)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            data-auto-animate=""
            data-transition="default"
            data-background-transition="fade"
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-color="rgba(231, 244, 255, 0.2)"
            data-background-opacity="0.12"
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-e9512d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '815.298px 179.967px 0px',
                  zIndex: 10
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  data-name="drawing-0caac3"
                  style={{
                    width: '61.1563px',
                    height: '97.4954px',
                    left: '749.221px',
                    top: '136.051px'
                  }}
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 11,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 20px) opacity(100%)',
                      transform: 'rotate(-11deg)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                      data-timestamp={1638263356635}
                      style={{ zIndex: 11 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                      >
                        <path
                          fill="rgb(255, 255, 255)"
                          d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '61.1563px',
                    height: '97.4954px',
                    left: '817.384px',
                    top: '127.702px'
                  }}
                  data-name="drawing-e245c3"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 12,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 20px) opacity(100%)',
                      transform: 'rotate(-15deg)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                      data-timestamp={1638263356635}
                      style={{ zIndex: 12 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                      >
                        <path
                          fill="rgb(255, 255, 255)"
                          d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-b738ce"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '153.741px 484.036px 0px',
                  zIndex: 13
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '61.1563px',
                    height: '97.4954px',
                    left: '87.1821px',
                    top: '435.288px'
                  }}
                  data-origin-id="fb9b8c22fde91cae7dfe14e5d4d3c008"
                  data-name="drawing-e867f6"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 14,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 20px) opacity(100%)',
                      transform: 'rotate(167deg)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                      data-timestamp={1638263356635}
                      style={{ zIndex: 14 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                      >
                        <path
                          fill="rgb(255, 255, 255)"
                          d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '61.1563px',
                    height: '97.4954px',
                    left: '160.586px',
                    top: '435.288px'
                  }}
                  data-origin-id="22c684d3adae3bf40469cd183be7cb89"
                  data-name="drawing-36a8f7"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      zIndex: 15,
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 20px) opacity(100%)',
                      transform: 'rotate(169deg)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                      data-timestamp={1638263356635}
                      style={{ zIndex: 15 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                      >
                        <path
                          fill="rgb(255, 255, 255)"
                          d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: 225, height: 'auto' }}
              data-name="text-84c7d3"
            >
              <div
                className="sl-block-content fragment fade-out"
                style={{
                  zIndex: 16,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
                data-fragment-index={0}
              >
                <h1 style={{ textAlign: 'center' }}>
                  <strong>כמה דעות נפוצות על אלימות</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 806,
                left: '63.3742px',
                top: '6.11205px',
                height: 'auto'
              }}
              data-name="text-01dcb0"
            >
              <div
                className="sl-block-content fragment"
                style={{
                  zIndex: 17,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
                data-fragment-index={0}
              >
                <h1 style={{ textAlign: 'center' }}>
                  <strong>כמה דעות נפוצות על אלימות</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-ad6042"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{ transformOrigin: '396.691px 409px 0px', zIndex: 21 }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  style={{ height: 'auto' }}
                  data-name="group-0953ec"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '372.555px 409px 0px',
                      zIndex: 23
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        height: 'auto',
                        width: '509.999px',
                        left: '117.555px',
                        top: '389.5px'
                      }}
                      data-origin-id="674d443903045a6be279fe284808b5c8"
                      data-name="text-0104a4"
                    >
                      <div className="sl-block-content" style={{ zIndex: 24 }}>
                        <p>תכלס אני היחיד שדואג לה...כך אני דואג לה</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '30.6281px',
                    height: '31.4506px',
                    left: '645.198px',
                    top: '397.049px'
                  }}
                  data-name="shape-d3a2b9"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-denied"
                    data-shape-fill-color="rgb(220, 54, 54)"
                    data-shape-stretch="true"
                    style={{ zIndex: 22 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(220, 54, 54)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM16 4c2.59 0 4.973 0.844 6.934 2.242l-16.696 16.688c-1.398-1.961-2.238-4.344-2.238-6.93 0-6.617 5.383-12 12-12zM16 28c-2.59 0-4.973-0.844-6.934-2.242l16.696-16.688c1.398 1.961 2.238 4.344 2.238 6.93 0 6.617-5.383 12-12 12z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-2d55e0"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '513.746px 300.113px 0px',
                  zIndex: 18
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '30.6281px',
                    height: '31.4506px',
                    left: '645.403px',
                    top: '288.162px'
                  }}
                  data-name="shape-39c6d6"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-denied"
                    data-shape-fill-color="rgb(220, 54, 54)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(220, 54, 54)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM16 4c2.59 0 4.973 0.844 6.934 2.242l-16.696 16.688c-1.398-1.961-2.238-4.344-2.238-6.93 0-6.617 5.383-12 12-12zM16 28c-2.59 0-4.973-0.844-6.934-2.242l16.696-16.688c1.398 1.961 2.238 4.344 2.238 6.93 0 6.617-5.383 12-12 12z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '293.943px',
                    left: '351.46px',
                    top: '280.613px'
                  }}
                  data-name="text-187f9c"
                >
                  <div className="sl-block-content" style={{ zIndex: 20 }}>
                    <p>כך אני מפגין אהבה..</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-a21d67"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{ transformOrigin: '480px 518.222px 0px', zIndex: 25 }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '30.6281px',
                    height: '31.4506px',
                    left: '645.198px',
                    top: '502.497px'
                  }}
                  data-origin-id="a1874da8a90cb6c065ad07a2f862fb20"
                  data-name="shape-9f6c64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-denied"
                    data-shape-fill-color="rgb(220, 54, 54)"
                    data-shape-stretch="true"
                    style={{ zIndex: 26 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(220, 54, 54)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM16 4c2.59 0 4.973 0.844 6.934 2.242l-16.696 16.688c-1.398-1.961-2.238-4.344-2.238-6.93 0-6.617 5.383-12 12-12zM16 28c-2.59 0-4.973-0.844-6.934-2.242l16.696-16.688c1.398 1.961 2.238 4.344 2.238 6.93 0 6.617-5.383 12-12 12z" />
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '350.543px',
                    left: '284.174px',
                    top: '498.722px'
                  }}
                  data-origin-id="8fe984d6da1880de7cb823f548cf9400"
                  data-name="text-6ca7c9"
                >
                  <div className="sl-block-content" style={{ zIndex: 27 }}>
                    <p>אני? אני אף פעם לא מרים יד</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            data-auto-animate=""
            data-background-image="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675320/SL-010221-39450-10.jpg"
            data-background-color="rgba(204, 207, 210, 0.33)"
            data-background-opacity="0.1"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 822,
                left: '78.2451px',
                top: '159.613px',
                height: 'auto',
                zIndex: 9999
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  textAlign: 'left',
                  fontSize: '160%',
                  color: 'rgb(152, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'right', zIndex: 9999 }}>
                  לפני הכל, בואו נבין מה היא ההגדרה של אלימות והתעללות..
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '367.922px',
                height: '378.314px',
                left: '132.213px',
                top: '317.493px'
              }}
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 14, transform: 'rotate(-112deg)' }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{ zIndex: 14 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-63 -60 125 130"
                  >
                    <path
                      fill="rgb(242, 227, 93)"
                      d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-e8aa49"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '78.2451px 130.447px 0px',
                  zIndex: 10
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '312.849px',
                    height: '321.686px',
                    left: '-78.1794px',
                    top: '-30.396px'
                  }}
                  data-name="drawing-a004c2"
                >
                  <div
                    className="sl-block-content"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 11 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-85 -50 150 130"
                    >
                      <path
                        fill="#75aafe"
                        d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  data-name="drawing-0caac3"
                  style={{
                    width: '67.8254px',
                    height: '97.4954px',
                    left: '4.05271px',
                    top: '75.259px'
                  }}
                >
                  <div
                    className="sl-block-content"
                    data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                    data-timestamp={1638263356635}
                    style={{ zIndex: 12 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                    >
                      <path
                        fill="rgb(255, 255, 255)"
                        d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '67.8254px',
                    height: '97.4954px',
                    left: '85.4621px',
                    top: '75.259px'
                  }}
                  data-name="drawing-e245c3"
                >
                  <div
                    className="sl-block-content"
                    data-points="503.39 597.2,501.01 596.92,499.18 595.41,497.64 592.53,497.33 589.28,498.44 586.44,500.82 585.25,507.41 583.49,511.74 583.49,517.74 583.49,522.83 584,524.95 586.13,527.67 592.92,528.2 598.82,527.02 615.2,522.87 622.31,512.74 635.03,506.54 641.24,497.36 648.72,492.79 651.77,486.19 654.43,481.11 654.94,477.19 654.62"
                    data-timestamp={1638263356635}
                    style={{ zIndex: 13 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="477.076171875 574.3663330078125 59.94580078125 86.16876220703125"
                    >
                      <path
                        fill="rgb(255, 255, 255)"
                        d="M 501.67771532321746 606.0504384038137 Q 501.68 606.05 499.95 605.55 498.23 605.05 495.51 603.26 492.79 601.47 491.05 598.6 489.3 595.73 488.94 592.21 488.57 588.7 490 585.04 491.43 581.37 494.48 578.94 497.54 576.51 502.35 575.32 507.17 574.12 513.8 574.43 520.43 574.73 526.67 578.82 532.9 582.91 535.35 591.93 537.8 600.95 536.66 608.83 535.51 616.71 532.46 622.68 529.41 628.64 523.27 635.74 517.13 642.84 509.11 649.79 501.1 656.74 494.21 659.26 487.32 661.79 481.58 659.3 475.85 656.81 477.41 650.76 478.97 644.7 485.2 645.3 491.42 645.9 491.81 652.14 492.19 658.38 486.09 659.73 479.98 661.09 477.69 655.27 475.39 649.46 482.52 645.62 489.65 641.77 496.47 636.36 503.3 630.94 509.12 623.52 514.95 616.1 517.33 607.18 519.72 598.27 514.51 595.96 509.31 593.65 506.45 591.58 503.59 589.51 503.83 588.87 504.06 588.23 504.32 588.26 504.58 588.29 505.93 588.69 507.28 589.09 508.43 589.89 509.59 590.69 510.45 591.81 511.3 592.93 511.77 594.25 512.23 595.58 512.27 596.99 512.3 598.39 511.9 599.74 511.5 601.09 510.7 602.24 509.9 603.4 508.78 604.26 507.66 605.11 506.34 605.58 505.01 606.04 503.6 606.08 502.2 606.11 501.94 606.08 Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 822,
                left: '78.2451px',
                top: 350,
                height: 'auto'
              }}
              data-name="text-da1006"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  textAlign: 'left',
                  fontSize: '160%',
                  color: 'rgb(152, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '0.75em' }}>
                    אלימות והתעללות בקרב זקנים הינה תופעה שלרוב מתרחשת בבתים
                    ובמוסדות לטיפול ממושך - בתי אבות והיא באה לידי ביטוי בטיפול
                    לקוי, אי מילוי של צרכים בסיסים, פגיעה פיזית, פגיעה נפשית,
                    ניצול כלכלי והפרת זכויות.
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-auto-animate="" data-auto-animate-easing="linear">
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f42613"
              style={{ height: 'auto' }}
              data-origin-id="1d498449c4e8d702ab6764db2995a33e"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '740.391px 543.917px 0px',
                  zIndex: 10
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '147.557px',
                    left: '666.612px',
                    top: '491.917px',
                    height: 'auto'
                  }}
                  data-name="text-2daff8"
                  data-origin-id="a32ffadaf8ab818f5300011cd27b4ee1"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 13, fontSize: '70%' }}
                  >
                    <h2>הפרת זכויות</h2>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-a9d8a0"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '740.39px 530.074px 0px',
                      zIndex: 11
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="drawing"
                      style={{
                        width: '89.7785px',
                        height: '76.3143px',
                        left: '695.501px',
                        top: '491.917px'
                      }}
                      data-name="drawing-728825"
                      data-origin-id="f95f723d5d19a9fd74b04f9ad6f95398"
                    >
                      <div
                        className="sl-block-content"
                        data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                        data-timestamp={1638195786739}
                        style={{ zIndex: 12 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          preserveAspectRatio="none"
                          width="100%"
                          height="100%"
                          viewBox="-63 -60 125 130"
                        >
                          <path
                            fill="rgb(255, 60, 112)"
                            d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-91d6cc"
              data-origin-id="bff16d36736c1c098847501c70b72260"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '719.849px 411.959px 0px',
                  zIndex: 14
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '147.557px',
                    left: '639.23px',
                    top: '359.959px',
                    height: 'auto'
                  }}
                  data-origin-id="e334b1a26626aee482188429b8135636"
                  data-name="text-31b040"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 16, fontSize: '70%' }}
                  >
                    <h2>פגיעה פיזית</h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '89.7785px',
                    height: '74.8143px',
                    left: '665.928px',
                    top: '374.552px'
                  }}
                  data-name="drawing-a004c2"
                  data-origin-id="182f90c73527ac196f17e7a2ff979a3b"
                >
                  <div
                    className="sl-block-content"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 15 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-85 -50 150 130"
                    >
                      <path
                        fill="rgb(242, 227, 93)"
                        d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-0b74a5"
              data-origin-id="655cb1b831b0562eca632946fbbd1beb"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '495.001px 255.959px 0px',
                  zIndex: 17
                }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '132.557px',
                    left: '419.056px',
                    top: '203.959px',
                    height: 'auto'
                  }}
                  data-origin-id="419d2a559900af714711f7633276e877"
                  data-name="text-9b139e"
                >
                  <div
                    className="sl-block-content"
                    style={{ textAlign: 'center', zIndex: 20, fontSize: '70%' }}
                  >
                    <h2>ניצול כלכלי</h2>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-0370d7"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '491.85px 255.959px 0px',
                      zIndex: 18
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="drawing"
                      style={{
                        width: '89.7785px',
                        height: '92.3143px',
                        left: '446.961px',
                        top: '209.802px'
                      }}
                      data-name="drawing-92b8e6"
                      data-origin-id="f307679b04955fc48dc2bba1b6d846e3"
                    >
                      <div
                        className="sl-block-content"
                        data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                        data-timestamp={1638195786739}
                        style={{ zIndex: 19 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          preserveAspectRatio="none"
                          width="100%"
                          height="100%"
                          viewBox="-85 -50 150 130"
                        >
                          <path
                            fill="rgb(242, 227, 93)"
                            d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cfaa80"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '0px 0px 0px', zIndex: 24 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d74862"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{ transformOrigin: '0px 0px 0px', zIndex: 25 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '280.898px',
                height: '280.898px',
                left: '376.829px',
                top: '351.468px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-7be89d"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 29, transform: 'rotate(-11deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 29 }}>
                  <img
                    alt="violence"
                    className=""
                    data-natural-width={500}
                    data-natural-height={500}
                    data-lazy-loaded=""
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10675328/Parkinson-pana.svg"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-aad1eb"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  transformOrigin: '610.817px 307.959px 0px',
                  zIndex: 21
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '147.557px',
                    left: '532.817px',
                    top: '255.959px',
                    height: 'auto'
                  }}
                  data-origin-id="70ff4c07c44e270fa064f6990b3b16a6"
                  data-name="text-d79ee2"
                >
                  <div
                    className="sl-block-content fragment fade-in-then-semi-out"
                    style={{ textAlign: 'center', zIndex: 23, fontSize: '70%' }}
                    data-fragment-index={0}
                  >
                    <h2>פגיעה נפשית</h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '89.7785px',
                    height: '76.3143px',
                    left: '565.928px',
                    top: '269.802px'
                  }}
                  data-origin-id="304911e249a605d433afadb0c92f0846"
                  data-name="drawing-4a4692"
                >
                  <div
                    className="sl-block-content fragment"
                    data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                    data-timestamp={1638195786739}
                    style={{ zIndex: 22 }}
                    data-fragment-index={0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      preserveAspectRatio="none"
                      width="100%"
                      height="100%"
                      viewBox="-63 -60 125 130"
                    >
                      <path
                        fill="rgb(255, 60, 112)"
                        d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-ffccb2"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '800.467px 208.49px 0px',
                  zIndex: 26
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  data-name="drawing-b36bc4"
                  style={{
                    width: '187.991px',
                    height: '325.53px',
                    left: '726.471px',
                    top: '35.725px'
                  }}
                  data-origin-id="1093c802e99ff7a4dff57184876c5315"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 27, transform: 'rotate(36deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                      data-timestamp={1638195786739}
                      style={{ zIndex: 27 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="-63 -60 125 130"
                      >
                        <path
                          fill="rgba(220, 54, 54, 0.71)"
                          d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '148.952px',
                    left: '736.816px',
                    top: '126.581px'
                  }}
                  data-name="text-f00417"
                  data-origin-id="a94fd17db39a9e34de7a078215fdfbec"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 28, transform: 'rotate(-42deg)' }}
                  >
                    <div className="sl-block-content" style={{ zIndex: 28 }}>
                      <h1>פוגע</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fdb0fd"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '200.671px 269.802px 0px',
                  zIndex: 30
                }}
                data-fragment-index={4}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '361.451px',
                    left: '20.3992px',
                    top: '172.303px'
                  }}
                  data-name="text-ee9355"
                >
                  <div className="sl-block-content" style={{ zIndex: 32 }}>
                    <p style={{ textAlign: 'justify' }}>
                      אלימות יכולה להתבטא בכמה פגיעות מצד הפוגע, בלומדה הזו אנו
                      נדון על כמה סוגים שמתרחשים לרוב בתדירות גבוה בקרב דיירים
                      בבתי אבות.
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="drawing"
                  style={{
                    width: '376.662px',
                    height: '472.767px',
                    left: '12.3403px',
                    top: '33.4185px'
                  }}
                  data-name="drawing-0c9277"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 31, transform: 'rotate(67deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                      data-timestamp={1638195786739}
                      style={{ zIndex: 31 }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        preserveAspectRatio="none"
                        width="100%"
                        height="100%"
                        viewBox="-63 -60 125 130"
                      >
                        <path
                          fill="rgba(204, 207, 210, 0.43)"
                          d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-auto-animate="" data-auto-animate-easing="linear">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 888, left: 36, top: '119.877px', height: 'auto' }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 12 }}
                >
                  <h1 style={{ textAlign: 'center' }}>
                    נפרט קצת אודות 4 סוגי ההתעללות שהוצגו
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '369.463px',
                height: '207.724px',
                left: '17.691px',
                top: '334.799px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-9b5996"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  alt="violence"
                  className=""
                  data-natural-width={233}
                  data-natural-height={131}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10678256/Screenshot_2023-08-15_121820.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '336.452px',
                height: '221.142px',
                left: '286.274px',
                top: '402.429px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-ef62c1"
            >
              <div className="sl-block-content" style={{ zIndex: 15 }}>
                <img
                  alt="violence"
                  className=""
                  data-natural-width={213}
                  data-natural-height={140}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10678255/Screenshot_2023-08-15_121814.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '301.083px',
                height: '221.513px',
                left: '554.597px',
                top: '458.576px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-a4a076"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, transformOrigin: '100% 100% 0px' }}
              >
                <img
                  alt="violence"
                  className=""
                  data-natural-width={202}
                  data-natural-height={152}
                  data-crop-x={0}
                  data-crop-y="0.022267"
                  data-crop-width={1}
                  data-crop-height="0.977733"
                  style={{ left: 0, top: '-5px', width: 301, height: 227 }}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10678254/Screenshot_2023-08-15_121807.png"
                />
              </div>
            </div>
          </section>
          <section data-auto-animate-easing="linear" data-auto-animate="">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 888,
                left: '22.281px',
                top: '36.876px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 12 }}
                >
                  <h1 style={{ textAlign: 'right' }}>1. פגיעה פיזית</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '811.393px',
                left: '98.888px',
                top: '134.876px'
              }}
              data-name="text-b4d28c"
            >
              <div
                className="sl-block-content fragment fade-in-then-semi-out"
                style={{ zIndex: 14 }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.2em' }}>
                    גרימת כאב או פגיעה פיזית באמצעות חבלות, מכות, דחיפות,
                    נשיכות, כוויות, זריקת חפצים, אחיזה בכוח או טלטול...
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-2bbb5e"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '467.848px 474.595px 0px',
                  zIndex: 15
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '171.079px',
                    height: '173.175px',
                    left: '382.308px',
                    top: '388.007px'
                  }}
                  data-name="shape-fac12b"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(234, 153, 153)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 171 173"
                    >
                      <ellipse
                        rx="85.5395"
                        ry="86.5875"
                        cx="85.5395"
                        cy="86.5875"
                        className="shape-element"
                        fill="rgb(234, 153, 153)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '88.5079px',
                    left: '415.594px',
                    top: '445.095px'
                  }}
                  data-name="text-be5e3f"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 17, fontSize: '3rem' }}
                  >
                    <p>פיזית</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-be389a"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '467.848px 331.001px 0px',
                  zIndex: 18
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '140.752px',
                    height: '113.131px',
                    left: '397.472px',
                    top: '274.435px'
                  }}
                  data-name="shape-b33fb2"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 141 113"
                    >
                      <ellipse
                        rx="70.376"
                        ry="56.5655"
                        cx="70.376"
                        cy="56.5655"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '416.967px',
                    top: '305.001px'
                  }}
                  data-name="text-0e689d"
                >
                  <div className="sl-block-content" style={{ zIndex: 20 }}>
                    <p>כאב פיזי</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-c70d11"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '625.478px 477.846px 0px',
                  zIndex: 21
                }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '560.196px',
                    top: '438.845px'
                  }}
                  data-origin-id="a3f768324524c75cf99ee1aa8b1394c8"
                  data-name="text-aad9d3"
                >
                  <div className="sl-block-content" style={{ zIndex: 23 }}>
                    <p>פגיעה מינית</p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '140.752px',
                    height: '113.131px',
                    left: '555.102px',
                    top: '421.28px'
                  }}
                  data-name="shape-dad006"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 22 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 141 113"
                    >
                      <ellipse
                        rx="70.376"
                        ry="56.5655"
                        cx="70.376"
                        cy="56.5655"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-301fc0"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '467.848px 617.748px 0px',
                  zIndex: 24
                }}
                data-fragment-index={4}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '119.276px',
                    left: '404.896px',
                    top: '574.748px'
                  }}
                  data-origin-id="07b63aad495ef61b474b89e4b9eaa2da"
                  data-name="text-f6b9a6"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 26, fontSize: '110%' }}
                  >
                    <p>
                      <span style={{ fontSize: '0.75em', textAlign: 'center' }}>
                        פגיעה - יצירת שינוי מסוכן
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '140.752px',
                    height: '113.131px',
                    left: '397.472px',
                    top: '561.182px'
                  }}
                  data-name="shape-133803"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 25 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 141 113"
                    >
                      <ellipse
                        rx="70.376"
                        ry="56.5655"
                        cx="70.376"
                        cy="56.5655"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-5946eb"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '309.932px 474.595px 0px',
                  zIndex: 27
                }}
                data-fragment-index={5}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '242.46px',
                    top: '455.095px'
                  }}
                  data-origin-id="e69d87a6ba85fa7376441f475cc2f97b"
                  data-name="text-7392d4"
                >
                  <div className="sl-block-content" style={{ zIndex: 29 }}>
                    <p>כבילה</p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '140.752px',
                    height: '113.131px',
                    left: '239.556px',
                    top: '418.029px'
                  }}
                  data-name="shape-e22bcd"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 28 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 141 113"
                    >
                      <ellipse
                        rx="70.376"
                        ry="56.5655"
                        cx="70.376"
                        cy="56.5655"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-auto-animate-easing="linear" data-auto-animate="">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 888,
                left: '22.281px',
                top: '36.876px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 12 }}
                >
                  <h1 style={{ textAlign: 'right' }}>איך מזהים פגיעה פיזית?</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '811.393px',
                left: '92.8893px',
                top: '195.668px'
              }}
              data-name="text-b4d28c"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <ul
                  style={{
                    textAlign: 'justify'
                  }}
                >
                  <li style={{ textAlign: 'justify' }}>
                    <p>
                      <span style={{ fontSize: '1.2em' }}>
                        אלימות פיזית יכולה לגרום לכל מיני סימנים שיכולים להראות
                        לעיניים, כמו: פצעים, דקירות, שריטות, כוויות, שטפי דם,
                        נשיכות, נפיחות ממוקדת, חוסר שיער, שיניים שבורות, סימני
                        קשירה פיזיים.
                      </span>
                    </p>
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    <p>
                      <span style={{ fontSize: '1.2em' }}>
                        סימני חבלה בלתי מוסברים באזורים שונים בגוף.
                      </span>
                    </p>
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    <p>
                      <span style={{ fontSize: '1.2em' }}>
                        שברים: לא מוסברים או חוזרים באזורים שונים בגוף.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section data-auto-animate-easing="linear" data-auto-animate="">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 888,
                left: '22.281px',
                top: '36.876px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 15, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 15 }}
                >
                  <h1 style={{ textAlign: 'right' }}>2. פגיעה נפשית</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 14 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 16 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '811.393px',
                left: '109.718px',
                top: '134.876px'
              }}
              data-name="text-b4d28c"
            >
              <div
                className="sl-block-content fragment fade-in-then-semi-out"
                style={{ zIndex: 17 }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '1.2em' }}>
                    גרימת כאב וסבל נפשי-רגשי באמצעות צעקות, איומים, השפלה,
                    פגיעות מילוליות, קריאה בשמות גנאי..
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-2bbb5e"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '465.848px 469.595px 0px',
                  zIndex: 18
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '171.079px',
                    height: '173.175px',
                    left: '380.308px',
                    top: '383.007px'
                  }}
                  data-name="shape-fac12b"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(234, 153, 153)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 171 173"
                    >
                      <ellipse
                        rx="85.5395"
                        ry="86.5875"
                        cx="85.5395"
                        cy="86.5875"
                        className="shape-element"
                        fill="rgb(234, 153, 153)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '88.5079px',
                    left: '421.594px',
                    top: '450.095px'
                  }}
                  data-name="text-be5e3f"
                >
                  <div className="sl-block-content" style={{ zIndex: 20 }}>
                    <p>נפשית</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-92fd20"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '613.001px 455.591px 0px',
                  zIndex: 10
                }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '126.057px',
                    height: '117.581px',
                    left: '549.972px',
                    top: '396.8px'
                  }}
                  data-name="shape-2e7bdf"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 11 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 126 118"
                    >
                      <ellipse
                        rx="63.0285"
                        ry="58.7905"
                        cx="63.0285"
                        cy="58.7905"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '565.433px',
                    top: '411.527px'
                  }}
                  data-name="text-aad9d3"
                >
                  <div className="sl-block-content" style={{ zIndex: 12 }}>
                    <p>
                      <span style={{ fontSize: '0.7em' }}>תחושת אין אונים</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-b1be4f"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '465.848px 324.217px 0px',
                  zIndex: 22
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '126.057px',
                    height: '117.581px',
                    left: '402.819px',
                    top: '265.426px'
                  }}
                  data-name="shape-9e37b5"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 23 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 126 118"
                    >
                      <ellipse
                        rx="63.0285"
                        ry="58.7905"
                        cx="63.0285"
                        cy="58.7905"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '421.594px',
                    top: '304.717px'
                  }}
                  data-name="text-0e689d"
                >
                  <div className="sl-block-content" style={{ zIndex: 24 }}>
                    <p>השפלה</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-a981b1"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '317.28px 446.798px 0px',
                  zIndex: 30
                }}
                data-fragment-index={6}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '126.057px',
                    height: '117.581px',
                    left: '254.251px',
                    top: '388.007px'
                  }}
                  data-name="shape-03e538"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 31 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 126 118"
                    >
                      <ellipse
                        rx="63.0285"
                        ry="58.7905"
                        cx="63.0285"
                        cy="58.7905"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '269.712px',
                    top: '422.794px'
                  }}
                  data-name="text-7392d4"
                >
                  <div className="sl-block-content" style={{ zIndex: 32 }}>
                    <p>פחד</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-a034c6"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '372.989px 584.71px 0px',
                  zIndex: 34
                }}
                data-fragment-index={5}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '326.46px',
                    top: '545.71px'
                  }}
                  data-origin-id="38481787e02ac4d1e3e38df1ab9ac0c9"
                  data-name="text-003c79"
                >
                  <div className="sl-block-content" style={{ zIndex: 36 }}>
                    <p>
                      <span style={{ fontSize: '0.7em' }}>פגיעה בערך עצמי</span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '126.057px',
                    height: '117.581px',
                    left: '309.96px',
                    top: '525.919px'
                  }}
                  data-name="shape-f4eef1"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 35 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 126 118"
                    >
                      <ellipse
                        rx="63.0285"
                        ry="58.7905"
                        cx="63.0285"
                        cy="58.7905"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-b893df"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '543.029px 595.318px 0px',
                  zIndex: 26
                }}
                data-fragment-index={4}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '126.057px',
                    height: '117.581px',
                    left: 480,
                    top: '536.527px'
                  }}
                  data-name="shape-443fec"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(224, 102, 102)"
                    data-shape-stretch="true"
                    style={{ zIndex: 27 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 126 118"
                    >
                      <ellipse
                        rx="63.0285"
                        ry="58.7905"
                        cx="63.0285"
                        cy="58.7905"
                        className="shape-element"
                        fill="rgb(224, 102, 102)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '95.1345px',
                    left: '495.461px',
                    top: '578.859px'
                  }}
                  data-name="text-f6b9a6"
                >
                  <div className="sl-block-content" style={{ zIndex: 28 }}>
                    <p>ייאוש</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-auto-animate-easing="linear" data-auto-animate="">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 888,
                left: '22.281px',
                top: '36.876px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 14 }}
                >
                  <h1 style={{ textAlign: 'right', zIndex: 15 }}>
                    איך מזהים פגיעה נפשית?
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '811.393px',
                left: '92.8893px',
                top: '195.668px'
              }}
              data-name="text-b4d28c"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <p style={{ textAlign: 'right' }}>
                  דיכאון, חרדה, כעס, דאגה, בלבול, פחד.
                  <br />
                  &nbsp;
                </p>
                <p style={{ textAlign: 'right' }}>
                  איבוד עניין בפעולות רגילות וחברתיות.
                </p>
              </div>
            </div>
          </section>
          <section data-auto-animate-easing="linear" data-auto-animate="">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 888, left: '28.5698px', top: 70, height: 'auto' }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 12 }}
                >
                  <h1 style={{ textAlign: 'right' }}>3. ניצול כלכלי</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '811.393px',
                left: '98.888px',
                top: '193.572px'
              }}
              data-name="text-b4d28c"
            >
              <div
                className="sl-block-content fragment fade-in-then-semi-out"
                style={{ zIndex: 14 }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ fontSize: '1.2em' }}>
                    הונאה, גניבה, סחיטה, אכיפת שינויי צוואה, גזלה, זיוף מסמכים
                    וחתימות, ייצוג בלתי הולם בענייני כספים וכל מצב היוצר תלות של
                    הקשיש בגורם המטפל מבחינה כלכלית.
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-auto-animate-easing="linear" data-auto-animate="">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 888, left: '28.5698px', top: 70, height: 'auto' }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 12 }}
                >
                  <h1 style={{ textAlign: 'right' }}>4. הפרת זכויות</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '811.393px',
                left: '98.888px',
                top: '193.572px'
              }}
              data-name="text-b4d28c"
            >
              <div
                className="sl-block-content fragment fade-in-then-semi-out"
                style={{ zIndex: 14 }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'right' }}>
                  <span style={{ fontSize: '1.2em' }}>
                    הכחשה ומניעת זכותו של הקשיש לבחור את סגנון חייו בנושאי
                    מגורים ופעילויות יום-יום, כפייה על הקשיש לעזוב את ביתו,
                    לעבור למוסד או להגביל את תנועותיו.
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section data-auto-animate-easing="linear" data-auto-animate="">
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 888, left: '28.5698px', top: 70, height: 'auto' }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 12 }}
                >
                  <h1 style={{ textAlign: 'right' }}>דוגמאות להפרת זכויות</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '89.7785px',
                height: '92.3143px',
                left: 48,
                top: '23.8428px'
              }}
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-63 -60 125 130"
                >
                  <path
                    fill="rgb(255, 60, 112)"
                    d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '89.7784px',
                height: '92.3141px',
                left: '4.1108px',
                top: 72
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '135.075px',
                height: '132.5px',
                left: '824.925px',
                top: 513
              }}
              data-name="drawing-05f0da"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-90 -65 175 120"
                >
                  <path
                    fill="rgb(118, 172, 255)"
                    d="M45.5,-48.4C61.4,-40.8,78.4,-28.8,80.2,-14.7C82,-0.6,68.7,15.7,56,27.5C43.4,39.3,31.5,46.6,19.2,49.6C7,52.7,-5.7,51.6,-19.4,48.6C-33,45.6,-47.6,40.8,-60.7,29.5C-73.7,18.3,-85.1,0.6,-83.5,-15.9C-82,-32.4,-67.4,-47.7,-51.3,-55.2C-35.2,-62.7,-17.6,-62.3,-1.4,-60.6C14.8,-58.9,29.6,-55.9,45.5,-48.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '811.393px',
                left: '98.888px',
                top: '193.572px'
              }}
              data-name="text-b4d28c"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <ul>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={0}
                  >
                    אי מתן אפשרות לגורמים מטפלים לבקר את הקשיש.
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={1}
                  >
                    בחירת סגנון חיים עבור הקשיש.
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={2}
                  >
                    אי-שיתוף הקשיש בקבלת החלטות.
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={3}
                  >
                    שלילת זכויות.
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={4}
                  >
                    הזנחה.
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={5}
                  >
                    חסך בצרכיו הבסיסיים של הקשיש.
                  </li>
                  <li
                    style={{ textAlign: 'right' }}
                    className="fragment fade-in-then-semi-out"
                    data-fragment-index={6}
                  >
                    הזנחה אקטיבית- החלטה בזדון לא למלא מחויבות טיפולית.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '723.457px',
                left: '156.544px',
                top: 40,
                height: 'auto'
              }}
              data-name="text-ed29ee"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'left',
                    zIndex: 10,
                    color: 'rgb(68, 68, 68)',
                    fontSize: '90%'
                  }}
                >
                  <h2 style={{ textAlign: 'right' }}>
                    <span style={{ fontSize: '1.2em' }}>
                      איך מונעים התרחשות של מקרי אלימות..
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '738.354px',
                left: '141.647px',
                top: '237.216px'
              }}
              data-name="text-06f938"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    העלאת מודעות בקרב הצוותים על מניעת אלימות בקרב דיירי בית
                    האבות ע&quot;י קיום חת&quot;שים, שבוע מניעת אלימות.
                    <br />
                    &nbsp;
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    ריענון נהלים על חשיבות מניעת אלימות והתייחסות מיטיבה
                    לדיירים.
                    <br />
                    &nbsp;
                  </li>
                  <li style={{ textAlign: 'right' }}>
                    טיפול בדיירים מתוך כבוד וחמלה.
                    <br />
                    &nbsp;
                  </li>
                  <li style={{ textAlign: 'right' }}>עבודה עם רגש.</li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '660.81px',
                height: '877.314px',
                left: '-191.405px',
                top: '-88.657px'
              }}
              data-name="drawing-a004c2"
              data-origin-id="0a1af5f340bd3b7b904c8a20a31f9553"
            >
              <div
                className="sl-block-content"
                data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                data-timestamp={1638195786739}
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="none"
                  width="100%"
                  height="100%"
                  viewBox="-85 -50 150 130"
                >
                  <path
                    fill="rgb(242, 227, 93)"
                    d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '202.246px',
                height: '233.259px',
                left: '858.877px',
                top: '-38.63px'
              }}
              data-origin-id="c4345a4bf8f9b32ec89a8a69b8ad9962"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  transform: 'rotate(0deg)',
                  filter: 'blur(4px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-63 -60 125 130"
                  >
                    <path
                      fill="rgb(255, 60, 112)"
                      d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-26b7c0"
              style={{ height: 'auto' }}
              data-origin-id="acf5ac094b5763433cb3c83fdc90a698"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '471.171px 292.462px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '882.342px',
                    left: 30,
                    top: '98.9623px',
                    height: 'auto'
                  }}
                  data-name="text-d18e72"
                  data-origin-id="24ac29740d95dfa2feddaf57f441b55b"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 13,
                      textAlign: 'left',
                      padding: 5,
                      fontSize: '124%',
                      color: 'rgb(0, 0, 0)'
                    }}
                  >
                    <h1 style={{ textAlign: 'center' }}>
                      <strong>זכרו!</strong>
                    </h1>
                    <h1 style={{ textAlign: 'center' }}>
                      <strong>
                        לעיתים קרובות אלימות
                        <br />
                        נסתרת מעין הציבור!
                      </strong>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '458.519px',
                height: '557.631px',
                left: '-135.853px',
                top: '194.629px'
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.48', filter: 'blur(23px)' }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-85 -50 150 130"
                  >
                    <path
                      fill="rgb(242, 227, 93)"
                      d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              data-name="drawing-b36bc4"
              style={{
                width: '202.246px',
                height: '233.259px',
                left: '858.877px',
                top: '-38.63px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  transform: 'rotate(0deg)',
                  filter: 'blur(4px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{ zIndex: 11 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-63 -60 125 130"
                  >
                    <path
                      fill="rgb(255, 60, 112)"
                      d="M57.5,-35.2C65.4,-19.5,56.4,3.9,44,24.7C31.6,45.6,15.8,63.8,-3,65.6C-21.7,67.3,-43.4,52.4,-54.1,32.6C-64.8,12.7,-64.5,-12.1,-53.6,-29.5C-42.8,-46.8,-21.4,-56.7,1.7,-57.7C24.8,-58.6,49.6,-50.8,57.5,-35.2Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-26b7c0"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 140.835px 0px', zIndex: 12 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '882.342px',
                    left: '38.829px',
                    top: '43.8347px',
                    height: 'auto'
                  }}
                  data-name="text-d18e72"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 13,
                      textAlign: 'left',
                      padding: 5,
                      fontSize: '124%',
                      color: 'rgb(0, 0, 0)'
                    }}
                  >
                    <h2 style={{ textAlign: 'right' }}>
                      <strong>
                        במידה והתרחש מקרה של אלימות במוסדנו, למי לדווח?
                      </strong>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-421dae"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 332.992px 0px', zIndex: 14 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '882.342px',
                    left: '38.829px',
                    top: '264.992px',
                    height: 'auto'
                  }}
                  data-origin-id="fb7466119af6937a3f1afb36ec918d42"
                  data-name="text-a7a5ee"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 15,
                      textAlign: 'left',
                      padding: 5,
                      fontSize: '135%',
                      color: '#fefefe'
                    }}
                  >
                    <h3 style={{ textAlign: 'justify' }}>
                      אם קיימת וועדה למניעת אלימות אז ישר לפנות לאחד או יותר
                      מחברי הוועדה.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-af5327"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '480px 537.492px 0px', zIndex: 16 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '882.342px',
                    left: 39,
                    top: '437.992px',
                    height: 'auto'
                  }}
                  data-origin-id="6719ed5d54b9a58855346436aa290f28"
                  data-name="text-5672db"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 17,
                      textAlign: 'left',
                      padding: 5,
                      fontSize: '135%',
                      color: '#fefefe'
                    }}
                  >
                    <h3 style={{ textAlign: 'justify' }}>
                      אם אין, אז אפשר לפנות למנהל הרפואי של הארגון, מנהל הסיעוד
                      או לעובדת סוציאלית שבארגון.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 800,
                left: '77.9037px',
                top: '344.851px',
                height: 'auto'
              }}
              data-name="text-8a8f77"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  fontSize: '160%',
                  textAlign: 'center',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p>לחתימה על ביצוע הלומדה לחץ למטה</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="drawing"
              style={{
                width: '454.12px',
                height: '416.314px',
                left: '252.94px',
                top: '80.843px'
              }}
              data-name="drawing-a004c2"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.75' }}
              >
                <div
                  className="sl-block-content"
                  data-points="332.29 860.6,334.52 860.6,336.75 860.6,338.62 859.79,340.68 859.28,342.23 857.73,343.26 855.92,343.78 853.86,344.04 851.79,344.04 849.73,344.04 847.66,344.04 845.54,343.26 843.48,342.23 841.67,340.94 840.12,339.65 838.57,337.84 837.54,335.78 837.28,333.71 837.28,331.65 837.8,329.79 838.6,327.98 839.89,326.38 841.21,324.83 842.5,323.79 844.57,322.76 846.63,321.99 848.7,321.47 850.76,320.7 852.83,319.92 854.89,319.41 856.95,319.15 859.02,320.18 861.34,321.24 863.2,322.27 865.27,324.08 866.56,326.14 867.08,328.21 867.59,329.5 865.73,329.5 863.55,329.5 861.49,329.5 859.42"
                  data-timestamp={1638195786739}
                  style={{ zIndex: 10 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="-85 -50 150 130"
                  >
                    <path
                      fill="rgb(242, 227, 93)"
                      d="M42,-29.4C54.1,-18.3,63.5,-0.8,62.2,18.1C61,37,49.2,57.4,31.2,66.8C13.2,76.2,-11,74.7,-33.1,65.3C-55.3,56,-75.4,38.9,-78.5,19.5C-81.7,0.1,-68,-21.6,-52.1,-33.5C-36.2,-45.5,-18.1,-47.8,-1.6,-46.5C14.9,-45.3,29.9,-40.5,42,-29.4Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 800,
                left: '67.9037px',
                top: '222.851px',
                height: 'auto'
              }}
              data-name="text-369138"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  fontSize: '160%',
                  textAlign: 'center',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p>
                  <span style={{ fontSize: '1.2em' }}>
                    יפה! סיימנו את הלומדה!
                  </span>
                </p>
              </div>

              <div
                className="sl-block"
                data-block-type="text"
                style={{ height: 'auto', width: '100%', top: 340 }}
                data-name="button-f3216ab"
              >
                <div className="sl-block-content" style={{ zIndex: 14 }}>
                  <Button
                    variant="contained"
                    onClick={() => handleSetViewToSignCourseware()}
                    sx={{ minWidth: 140 }}
                    // disabled={!didReachTheEnd}
                  >
                    חתימה על סיום הלומדה
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Violence;
