import { doc, getDoc } from '@firebase/firestore';
import {
  ContactMailOutlined,
  LanguageOutlined,
  MapOutlined,
  VolunteerActivismOutlined
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import femaleNurse from 'src/assets/medicalAssessment/femaleNurse.webp';
import maleNurse from 'src/assets/medicalAssessment/maleNurse.webp';
import socialWorker from 'src/assets/medicalAssessment/social-worker.png';
import unspecefiedAvatar from 'src/assets/medicalAssessment/unspecefiedAvatar.png';

const ServiceProviderRow = ({
  providerType,
  providerName,
  providerCustomDescription,
  provider,
  setServiceRequestDialogOpen
}) => {
  ServiceProviderRow.propTypes = {
    providerType: PropTypes.string,
    providerName: PropTypes.string,
    providerCustomDescription: PropTypes.string,
    provider: PropTypes.object,
    setServiceRequestDialogOpen: PropTypes.func
  };

  const [providerDetails, setProviderDetails] = useState();

  const getUserDetails = async (user_doc_id) => {
    const userRef = doc(db, `users/${user_doc_id}`);
    getDoc(userRef)
      .then((user) => {
        if (user.exists()) {
          setProviderDetails({ ...user.data(), user_doc_id: user.id });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (provider && !providerDetails) {
      const { user_ref } = provider;
      if (user_ref) {
        getUserDetails(user_ref);
      }
    }
  }, [providerDetails, provider]);

  const getListItemValue = (val, fieldToReturn = 'title') => {
    if (isArray(val)) {
      const flattened = val.map((el) => el[fieldToReturn]).join(', ');
      return flattened;
    }
    return val;
  };
  const handleOpenSendServiceRequest = () => {
    setServiceRequestDialogOpen(provider);
  };

  const getAvatarOfProvider = () => {
    const { type, gender } = provider;
    if (type === 'social-worker') {
      return 'social-worker';
    }
    if (providerDetails && providerDetails.avatar_published) {
      return 'self-avatar';
    }
    if (gender === 'male') {
      return 'male';
    }
    if (gender === 'female') {
      return 'female';
    }
    return 'unspecefied';
  };
  return (
    <Grid item xs={12}>
      <Card elevation={2} sx={{ maxWidth: 1200, overflow: 'auto' }}>
        <CardHeader
          title={
            <Stack sx={{ pl: 1 }} gap={2} direction="row">
              <Avatar
                src={
                  getAvatarOfProvider() === 'self-avatar'
                    ? providerDetails.avatar
                    : getAvatarOfProvider() === 'female'
                    ? femaleNurse
                    : getAvatarOfProvider() === 'male'
                    ? maleNurse
                    : getAvatarOfProvider() === 'social-worker'
                    ? socialWorker
                    : unspecefiedAvatar
                }
                alt="service provider"
                sx={{
                  width: '50px',
                  height: '50px',
                  '& img': {
                    objectFit: 'contain'
                  }
                }}
                variant={
                  getAvatarOfProvider() === 'social-worker' ||
                  getAvatarOfProvider() === 'unspecefied'
                    ? 'rounded'
                    : 'circular'
                }
              />
              <Typography
                sx={{
                  fontSize: '1.8rem',
                  lineHeight: 2,
                  verticalAlign: 'middle'
                }}
              >
                {providerType === 'doctor'
                  ? `ד"ר ${
                      providerDetails
                        ? providerDetails.first_name
                        : provider.first_name
                        ? provider.first_name
                        : ''
                    } ${
                      providerDetails
                        ? providerDetails.last_name
                        : provider.last_name
                        ? provider.last_name
                        : ''
                    }`
                  : `${
                      providerDetails
                        ? providerDetails.first_name
                        : provider.first_name
                        ? provider.first_name
                        : ''
                    } ${
                      providerDetails
                        ? providerDetails.last_name
                        : provider.last_name
                        ? provider.last_name
                        : ''
                    }`}
              </Typography>
            </Stack>
          }
          titleTypographyProps={{
            fontSize: '1.8rem'
            // fontWeight: 600
          }}
        />
        <Divider />
        <CardContent>
          {provider ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={10}>
                <List>
                  {provider.description !== '' && (
                    <Typography
                      sx={{
                        fontSize: '1.25rem',
                        pl: '70px'
                      }}
                      variant="h3"
                    >
                      {provider.description}
                    </Typography>
                  )}
                  {[
                    {
                      id: 'serviceFields',
                      value: provider.serviceFields,
                      label: 'תחומי שירות',
                      icon: <VolunteerActivismOutlined color="error" />
                    },
                    {
                      id: 'areaOfWork',
                      value: provider.areaOfWork,
                      label: 'אזורי פעילות',
                      icon: <MapOutlined color="info" />,
                      fieldToReturn: 'city_label'
                    },
                    {
                      id: 'languages',
                      value: provider.languages,
                      label: 'שפות שירות',
                      icon: <LanguageOutlined color="secondary" />,
                      fieldToReturn: 'label'
                    }
                  ].map((details) => (
                    <ListItem key={details.id}>
                      <ListItemIcon>
                        <Icon>{details.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={getListItemValue(
                          details.value,
                          details.fieldToReturn
                        )}
                        secondary={details.label}
                        secondaryTypographyProps={{
                          fontWeight: 600
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
                sm={2}
              >
                <Button
                  sx={{ maxHeight: 100 }}
                  variant="outlined"
                  endIcon={<ContactMailOutlined />}
                  disabled={!setServiceRequestDialogOpen}
                  onClick={() => {
                    if (setServiceRequestDialogOpen) {
                      handleOpenSendServiceRequest(provider);
                    }
                  }}
                >
                  שליחת פנייה
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <Skeleton variant="circular" width={42} height={42} />
              </Grid>
              <Grid item xs={4}>
                <List>
                  <Typography
                    sx={{
                      fontSize: '1.25rem'
                    }}
                    variant="h3"
                  >
                    {providerCustomDescription}
                  </Typography>
                  {[1, 2, 3].map((details) => (
                    <ListItem key={details}>
                      <Skeleton
                        variant="rounded"
                        sx={{ width: '100%', minHeight: 34 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ServiceProviderRow;
