import { doc, GeoPoint, updateDoc } from '@firebase/firestore';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import useTheme from '@mui/styles/useTheme';
import { uniqueId } from 'lodash';
import { workerClass } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropType from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, {
  FullscreenControl,
  Marker,
  NavigationControl,
  ScaleControl,
  useMap
} from 'react-map-gl';
import { useLocation, useNavigate } from 'react-router';
import { db } from 'src/_firebase/firebase';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import routesConst from 'src/routesConst';
import getCityAndDistrictOfOption from 'src/utils/getCityAndDistrictOfOption';
import handleSearchByRadius from 'src/utils/handleSearchByRadius';
import workerLoader from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import './map.css';
import OrgAvatarPin from './OrgAvatarPin';
import OrgBouncyPin from './OrgBouncyPin';
import Pin from './Pin';
import PlacesAutoComplete from './PlacesAutoComplete';

workerClass = workerLoader;

const kmToPixels = (km, latitude, zoom) => {
  // const zoom = map.getZoom();
  const metersPerPixel =
    (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / 2 ** zoom;
  return (km * 1000) / metersPerPixel;
};

const SearchMarker = () => {
  const [lastSearchParams, setLastSearchParams] = useState({
    radius: 0,
    lngLat: {
      lng: 0,
      lat: 0
    }
  });
  const { mainMap } = useMap({ id: 'mainMap' });

  useEffect(() => {
    const searchLS = localStorage.getItem('search');
    // const t = mainMap.getMap().getLayer('settlment-major-label');
    // console.log(t);
    if (searchLS) {
      const parsed = JSON.parse(searchLS)[0];
      const { center, distanceRadius } = parsed;
      if (mainMap && center && distanceRadius) {
        const zoom = mainMap.getZoom();
        const pixeledDistance = kmToPixels(distanceRadius, center[1], zoom);

        setLastSearchParams({
          radius: pixeledDistance,
          lngLat: {
            lng: center[0],
            lat: center[1]
          }
        });
      }
    }
  }, [localStorage.getItem('search'), mainMap]);

  return (
    <Marker
      longitude={lastSearchParams.lngLat.lng}
      latitude={lastSearchParams.lngLat.lat}
      offsetTop={-lastSearchParams.radius / 2}
      offsetLeft={-lastSearchParams.radius / 2}
    >
      <Box
        sx={{
          width: '7px',
          height: '7px',
          borderRadius: '100%',
          backgroundColor: '#ff4122',
          position: 'absolute',
          zIndex: 2
        }}
      />
      <Box
        sx={{
          width: '15px',
          height: '15px',
          borderRadius: '100%',
          // backgroundColor: 'blue',
          transform: 'translate(-25%,-25%)',
          zIndex: 1,
          border: '1px dashed blue'
        }}
      />
    </Marker>
  );
};

const MainMap = ({
  markers,
  selectedToExamine,
  mapInOrgDetailsPage,
  setNewPinLocation,
  newPinLocation,
  newPinAddressSuggestion,
  setResultCollapseOpen,
  isResultCollapseOpen,
  hideControls
}) => {
  MainMap.propTypes = {
    markers: PropType.array,
    selectedToExamine: PropType.any,
    mapInOrgDetailsPage: PropType.bool,
    hideControls: PropType.bool,
    setNewPinLocation: PropType.func,
    newPinLocation: PropType.any,
    newPinAddressSuggestion: PropType.any,
    setResultCollapseOpen: PropType.func,
    isResultCollapseOpen: PropType.object
  };

  const initMapCenter = {
    lng: 35.11,
    lat: 32.71
  };
  const { currentUser, setCurrentUser } = useCurrentUserContext();
  const mapRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [lng, setLng] = useState(35.11);
  const [lat, setLat] = useState(32.71);
  const [zoom, setZoom] = useState(2);
  const [mapCenter, setMapCenter] = useState([
    initMapCenter.lng,
    initMapCenter.lat
  ]);
  const [popupFormattedAddress, setPopupFormattedAddress] = useState();
  const { t } = useTranslation('translation', { keyPrefix: 'map' });
  const [
    pinLocationChangeConfirmationDialogOpen,
    setPinLocationChangeConfirmationDialog
  ] = useState(false);
  const [tempPinLocation, setTempPinLocation] = useState();
  const { mainMap } = useMap({ id: 'mainMap' });

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    searchIndex,
    setSearchResults,
    sliderDistanceValue,
    setResultsAreLoading,
    searchQuery
  } = useSearchData();

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setLat(pos.coords.latitude);
          setLng(pos.coords.longitude);
          setZoom(20);
        },
        (errCallback) => {
          if (
            errCallback.PERMISSION_DENIED ||
            errCallback.POSITION_UNAVAILABLE
          ) {
            setLng(34.85);
            setLat(31.04);
            setZoom(9);
            // TODO:: according to the ip we need to find the city
            // const ipgeolocation_api_key = '8dbc43df3baf4bc7966730935824f008';
            // const url = `https://ipgeolocation.abstractapi.com/v1/?api_key=${ipgeolocation_api_key}`;
            // httpGetAsync(url, handleIPGeolocation);
          }
        }
      );
    } else {
      setLng(34.85);
      setLat(31.04);
      setZoom(9);
    }
  });

  const onSelectCity = useCallback(({ longitude, latitude }) => {
    mapRef.current.flyTo({ center: [longitude, latitude], duration: 2000 });
  }, []);

  const getFormattedSelectedAddress = async (geoloc) => {
    // console.log(geoloc);
    const formatted = await getCityAndDistrictOfOption({
      _geoloc: { ...geoloc }
    });
    setPopupFormattedAddress(formatted);
  };

  useEffect(async () => {
    setPopupFormattedAddress();
    if (selectedToExamine) {
      const {
        address: { geoLocation }
      } = selectedToExamine;
      onSelectCity({
        longitude: geoLocation // temp: newer field got only _geoloc
          ? geoLocation.lng
          : selectedToExamine._geoloc.lng,
        latitude: geoLocation ? geoLocation.lat : selectedToExamine._geoloc.lat
      });
      setZoom(12);
      const {
        _rankingInfo: {
          matchedGeoLocation: { distance }
        }
      } = selectedToExamine;
      const formatted = await getFormattedSelectedAddress(
        selectedToExamine._geoloc
      );
      const searchFromLS = JSON.parse(localStorage.getItem('search'));
      if (searchFromLS && searchFromLS[0] && searchFromLS[0].distanceRadius) {
        setPopupInfo({
          ...selectedToExamine,
          distance,
          searchRadius: searchFromLS[0].distanceRadius
        });
      } else {
        setPopupInfo({ ...selectedToExamine, distance });
      }
    } else {
      setLng(initMapCenter.lng);
      setLat(initMapCenter.lat);
      setZoom(9);
    }
  }, [selectedToExamine]);

  const pins = useMemo(() => {
    if (markers) {
      // console.log(markers);
      const pinsMap = markers.map((pinLocation) => (
        <Marker
          key={`marker-${pinLocation.user_doc_id}-${uniqueId()}`}
          longitude={pinLocation._geoloc.lng}
          latitude={pinLocation._geoloc.lat}
          offset={[0, -50 / 2]}
          anchor="bottom"
          onClick={async (e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            if (
              isResultCollapseOpen.state &&
              isResultCollapseOpen.parent === pinLocation.user_doc_id
            ) {
              setResultCollapseOpen({ state: false });
            } else {
              setResultCollapseOpen({
                state: true,
                parent: pinLocation.user_doc_id
              });
            }
          }}
        >
          {/* org logo or mgdalor logo */}
          <OrgAvatarPin
            isSelected={
              isResultCollapseOpen.state &&
              isResultCollapseOpen.parent === pinLocation.user_doc_id
            }
            src={pinLocation.avatar}
          />
        </Marker>
      ));
      return pinsMap;
    }
  }, [markers, popupInfo]);

  const saveNewPinLocationToDb = async (newLocation) => {
    const orgRef = doc(db, `users/${currentUser.user_doc_id}`);
    let hash;
    if (newLocation) {
      hash = new GeoPoint(newLocation.lat, newLocation.lng);
      await updateDoc(orgRef, {
        _geoloc: hash // this is for algolia also
      })
        .then(() => {
          setCurrentUser({ ...currentUser, _geoloc: { ...newLocation } });
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSaveNewPinLocation = async () => {
    if (tempPinLocation) {
      return saveNewPinLocationToDb(tempPinLocation).then(() => {
        setNewPinLocation(tempPinLocation);
        setPinLocationChangeConfirmationDialog(false);
        return (
          <Marker
            key="marker-new-org-location"
            longitude={tempPinLocation.lng}
            latitude={tempPinLocation.lat}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
            }}
          >
            <Pin size="35" key="new-org-pin" />
          </Marker>
        );
      });
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.address.geolocation) {
      setNewPinLocation(currentUser.address.geolocation);
    }
  }, []);

  useEffect(() => {
    if (tempPinLocation) {
      // if we have tempPinLocation then it means user changed the location and still did not agree to the change
      // after the user agrees it goes to newPinLocation which will update currentUser state
      setPinLocationChangeConfirmationDialog(true);
    }
  }, [tempPinLocation]);

  useEffect(() => {
    if (newPinAddressSuggestion) {
      if (mainMap) {
        mainMap.flyTo({
          center: [newPinAddressSuggestion.lng, newPinAddressSuggestion.lat],
          duration: 2000,
          zoom: 15
        });
      }
    }
  }, [newPinAddressSuggestion]);

  const performNewInitialSearch = () => {
    const searchFromLS = localStorage.getItem('search');
    // console.log(1);
    if (searchFromLS) {
      const parsed = JSON.parse(searchFromLS)[0];
      if (parsed) {
        handleSearchByRadius(
          searchQuery,
          mainMap,
          searchIndex,
          setSearchResults,
          sliderDistanceValue,
          navigate,
          setResultsAreLoading,
          null,
          null,
          location.state.initialSearch
        );
      }
    }
  };

  useEffect(() => {
    // console.log(location);
    if (
      location.pathname === `/${routesConst.results}` &&
      location.state &&
      location.state.initialSearch &&
      mainMap
    ) {
      // this means the user came from home page and this is his first search
      performNewInitialSearch();
    }
  }, [location.pathname, mainMap]);

  return (
    <>
      <Map
        ref={mapRef}
        cooperativeGestures
        id="mainMap"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom
        }}
        onLoad={(e) => {
          e.target.addSource('cities', {
            type: 'geojson',
            data: 'mapbox://data/moradabed/moradabed.clks39djv00we2as0aqv8slql-5c2e6'
          });
          e.target.addLayer({
            id: 'city-names',
            type: 'symbol',
            source: 'cities',
            layout: {
              'text-field': ['get', 'name'],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-size': 14,
              'text-anchor': 'top',
              'text-offset': [0, 1]
            }
          });
        }}
        scrollZoom="true"
        center={mapCenter}
        onZoomStart={(e) => {
          setMapCenter({
            lng: e.viewState.longitude,
            lat: e.viewState.latitude
          });
          setZoom(e.viewState.zoom.toFixed(2));
        }}
        maxBounds={[
          [34.0654333839, 29.5013261988],
          [36.056, 33.436]
        ]}
        style={{
          width: '100%',
          height: '100%',
          minHeight: '60vh',
          borderRadius: '8px'
        }}
        onClick={(mapEvent) => {
          if (mapInOrgDetailsPage) {
            setTempPinLocation(mapEvent.lngLat);
          }
        }}
        mapboxAccessToken={process.env.REACT_APP_MAPBOXGL_ACCESSTOKEN}
        mapStyle="mapbox://styles/moradabed/cldexw2ei001d01o92a1ijqd5"
      >
        {mapInOrgDetailsPage && (
          <>
            <PlacesAutoComplete mapRef={mapRef} setZoom={setZoom} />
            {currentUser && currentUser._geoloc && (
              <Marker
                key="marker-org-map-location"
                longitude={currentUser._geoloc.lng}
                latitude={currentUser._geoloc.lat}
                offset={[0, -50 / 2]}
                anchor="bottom"
              >
                <OrgAvatarPin src={currentUser.avatar} />
              </Marker>
            )}
            {newPinAddressSuggestion &&
              newPinAddressSuggestion.lng &&
              newPinAddressSuggestion.lat && (
                <Marker
                  key="newPinAddressSuggestion-org-map-location"
                  longitude={newPinAddressSuggestion.lng}
                  latitude={newPinAddressSuggestion.lat}
                  offset={[0, -50 / 2]}
                  anchor="bottom"
                >
                  <OrgBouncyPin />
                </Marker>
              )}
          </>
        )}
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <SearchMarker latitude={32.1212} longitude={30.959} radius={100} />
        <ScaleControl />
        {markers && pins}
        {tempPinLocation && (
          <Marker
            key="marker-new-org-location"
            longitude={tempPinLocation.lng}
            latitude={tempPinLocation.lat}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
            }}
          >
            {/* <Avatar /> */}
            <OrgBouncyPin />
          </Marker>
        )}
      </Map>
      {pinLocationChangeConfirmationDialogOpen && (
        <Dialog
          sx={{ zIndex: theme.zIndex.tooltip }}
          open={pinLocationChangeConfirmationDialogOpen}
        >
          <DialogTitle>{t('changingThePinLocation')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('theNewPinLocationIsWhatWillBeSeenForCustomers')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveNewPinLocation} variant="contained">
              {t('agree')}
            </Button>
            <Button
              onClick={() => {
                setPinLocationChangeConfirmationDialog(false);
                setTempPinLocation();
              }}
              variant="outlined"
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MainMap;
