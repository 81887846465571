import { createStyles, makeStyles } from '@mui/styles';
import belleFair from 'src/assets/font/Bellefair/Bellefair-Regular.ttf';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        styleOverrides: `
        @font-face {
          font-family: belleFair;
          src: url(${belleFair}) format('ttf');
          font-display: swap;
        }`
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%'
      },
      a: {
        textDecoration: 'none'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      borderRadius: 8
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
