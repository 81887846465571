import { doc, updateDoc } from '@firebase/firestore';
import { InfoOutlined } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { isUndefined, uniqueId } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import jobsTitles from 'src/common/jobsTitles';
import { useEmployeesData } from 'src/hooks/useEmployeesData/useEmployeesData';
import { useRbacDetails } from 'src/hooks/useRBAC/useRBAC';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import compareRolesOrders from 'src/utils/compareRolesOrders';
import containsSomeLabels from 'src/utils/containsSomeLabels';
import getUserJobTitle from 'src/utils/getUserJobTitle';
import handleRetrievePostDetails from '../utils/handleRetrievePostDetails';
import AttachedFileList from './AttachedFileList';
import CommunityPostItemMoreMenu from './CommunityPostItem/CommunityPostItemMoreMenu';
import FavoritePostButton from './FavoritePostButton';

const CommunityPostItemCard = ({
  post,
  handleFavoriteItem,
  handleViewCommunityAttachedFile,
  setEditPostOpen,
  setDeletePostOpen,
  handleOpenPostSignaturesList
}) => {
  CommunityPostItemCard.propTypes = {
    post: PropTypes.object,
    handleFavoriteItem: PropTypes.func,
    handleViewCommunityAttachedFile: PropTypes.func,
    setEditPostOpen: PropTypes.func,
    setDeletePostOpen: PropTypes.func,
    handleOpenPostSignaturesList: PropTypes.func
  };
  const { employeesData } = useEmployeesData();
  const { selectedOrg } = useSelectedOrg();
  const { currentUser } = useCurrentUserContext();
  const { rbacDetails } = useRbacDetails();
  const { snackbarState, setSnackbarState } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPublished, setIsPublished] = useState(false);

  const isMyPost =
    currentUser && post && currentUser.user_doc_id === post.author_ref;
  const isOrg = currentUser && currentUser.type === 'org';

  const [postDetails, setPostDetails] = useState();
  const [signaturePhrase, setSignaturePhrase] = useState();

  const handleRetrieveDetails = async () => {
    const data = await handleRetrievePostDetails(
      currentUser,
      employeesData,
      post,
      selectedOrg,
      postDetails
    );
    // console.log(data);
    const { userSignedPhrase, ...rest } = data;
    setSignaturePhrase(userSignedPhrase);
    setPostDetails(rest);
  };

  useEffect(() => {
    if (post && employeesData && currentUser && !postDetails) {
      handleRetrieveDetails();
    }
  }, [post, employeesData, currentUser, postDetails]);

  const getAvatarOfAuthor = () => {
    // console.log(postDetails);
    if (postDetails && postDetails.author && postDetails.author.avatar) {
      return postDetails.author.avatar;
    }
    return null;
  };

  const getTimeOfPost = (timeInSec) => {
    if (!timeInSec) {
      return 'הרגע';
    }
    const isSameMinute = moment(moment(timeInSec * 1000).toDate()).isSame(
      moment().toDate(),
      'minutes'
    );
    if (isSameMinute) {
      return moment(post.created_at.seconds * 1000)
        .startOf('m')
        .fromNow();
    }
    const isSameHour = moment(moment(timeInSec * 1000).toDate()).isSame(
      moment().toDate(),
      'hour'
    );
    if (isSameHour) {
      return moment(post.created_at.seconds * 1000)
        .startOf('mm')
        .fromNow();
    }
    const isSameDay = moment(moment(timeInSec * 1000).toDate()).isSame(
      moment().toDate(),
      'day'
    );
    if (isSameDay) {
      moment(post.created_at.seconds * 1000)
        .startOf('day')
        .fromNow();
    }
    return moment(post.created_at.seconds * 1000).format('DD/MM/YYYY');
  };
  const handleFavoritePostItem = (postToFavorite) => {
    handleFavoriteItem(postToFavorite);
    const temp = { ...postDetails };
    temp.favorite = {
      state: temp.favorite ? !temp.favorite.state : true, // if it does not exist then its the first time user likes this post
      last_modified: {
        seconds: moment().unix()
      }
    };
    setPostDetails(temp);
  };

  const getSignCountPhrase = () => {
    if (postDetails && postDetails.signCount) {
      if (postDetails.signCount === 1) {
        return 'חתימה אחת';
      }
      if (postDetails.signCount === 2) {
        return 'שתי חתימות';
      }
      if (postDetails.signCount > 2) {
        // console.log(postDetails.signCount);
        return `${postDetails.signCount} חתימות`;
      }
      return 'אין חתימות';
    }
    return 'אין חתימות';
  };

  const isUserRequiredToSign = () => {
    const { employee_work_places, type } = currentUser;
    // console.log(type);
    if (postDetails) {
      if (postDetails.labels) {
        if (currentUser && type === 'personal') {
          if (employee_work_places) {
            const index = employee_work_places.findIndex(
              (el) => el.org_ref === postDetails.org_ref
            );
            if (index > -1) {
              const userJobTitle = getUserJobTitle(currentUser, selectedOrg);
              const labels = userJobTitle ? [userJobTitle] : [];
              const doesContain = containsSomeLabels(
                postDetails.labels || jobsTitles,
                labels
              );
              return doesContain;
            }
            return false;
          }
          return false;
        }
        if (currentUser && type === 'org') {
          return false;
        }
      }

      return true;
    }
  };

  const handleOpenEditDialog = (postToEdit) => {
    setEditPostOpen(postToEdit);
  };

  const handleOpenDeletePostDialog = (postToDelete) => {
    setDeletePostOpen(postToDelete);
  };

  const compareRolesOrdersOfAnyOfTheDepartments = () => {
    const { author_ref } = post;
    const { user_doc_id } = currentUser;

    if (employeesData) {
      const indexOfAuthorInEmployees = employeesData.findIndex(
        (el) => el.doc_id === author_ref
      );
      const indexOfCurrentUserInEmployees = employeesData.findIndex(
        (el) => el.doc_id === user_doc_id
      );
      if (indexOfAuthorInEmployees > -1 && indexOfCurrentUserInEmployees > -1) {
        const currentEmployee = employeesData[indexOfCurrentUserInEmployees];
        const authorEmployee = employeesData[indexOfAuthorInEmployees];
        // console.log(currentEmployee);
        if (
          currentEmployee.org_specific_details &&
          currentEmployee.org_specific_details.rbac
        ) {
          const isHigherInAnyDepartment =
            currentEmployee.org_specific_details.rbac.map((dept) =>
              compareRolesOrders(
                currentEmployee.org_specific_details.rbac,
                authorEmployee.org_specific_details.rbac,
                dept.department_id,
                rbacDetails
              )
            );
          console.log(isHigherInAnyDepartment);
          return isHigherInAnyDepartment.some((el) => el);
        }
        return false;
      }
      return false;
    }
    return false;
  };

  const getJobLabel = (jobName) => {
    const index = jobsTitles.findIndex((el) => el.name === jobName);
    if (index > -1) {
      return jobsTitles[index].label;
    }
    return jobName;
  };

  const handleChangePublishState = async (newState) => {
    const { post_id, org_ref } = post;
    const postDocRef = doc(db, `orgs/${org_ref}/community/${post_id}`);
    updateDoc(postDocRef, {
      post_published: newState
    })
      .then(() => {
        setIsPublished(newState);
      })
      .catch((err) => {
        console.error(err);
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'error',
          msg: 'לא הצלחנו לשנות את הסטטוס של עדכון זה'
        });
      });
  };

  useEffect(() => {
    if (post) {
      const { post_published } = post;
      const postDefaultStatus = isUndefined(post_published)
        ? true
        : post_published;
      setIsPublished(postDefaultStatus);
    }
  }, [post]);

  return (
    <>
      {(isUndefined(post.post_published) || post.post_published) &&
      (isMyPost || isOrg) ? (
        <Grid
          key={uniqueId(`${post ? post.author_ref : 'skeleton'}-Grid-post`)}
          item
          xs={12}
        >
          <Card
            key={uniqueId(`${post ? post.author_ref : 'skeleton'}-post`)}
            elevation={
              post && post.author_ref === currentUser.user_doc_id ? 3 : 0
            }
          >
            <CardHeader
              avatar={
                postDetails && postDetails.author ? (
                  <Avatar
                    aria-label={`post-${post ? post.author_ref : 'skeleton'}-${
                      post.post_id
                    }`}
                    src={getAvatarOfAuthor()}
                  >
                    {postDetails &&
                    postDetails.author &&
                    postDetails.author &&
                    postDetails.author.name
                      ? postDetails.author.name
                      : post.author_ref}
                  </Avatar>
                ) : (
                  <Skeleton
                    variant="rounded"
                    sx={{ width: '100%', height: '100%' }}
                  />
                )
              }
              action={
                <>
                  {(currentUser && currentUser.type === 'org') || isMyPost ? (
                    <CommunityPostItemMoreMenu
                      post={post}
                      key={`${uniqueId('more-menu')}-${post.post_id}-component`}
                      anchorEl={anchorEl}
                      handleOpenEditDialog={handleOpenEditDialog}
                      handleOpenDeletePostDialog={handleOpenDeletePostDialog}
                      handleChangePublishState={handleChangePublishState}
                      isPublished={isPublished}
                    />
                  ) : null}
                </>
              }
              title={
                postDetails && postDetails.author && postDetails.author.name ? (
                  <Typography
                    component="span"
                    variant="inherit"
                    fontSize="inherit"
                  >
                    {`${postDetails.author.name} `}
                    {post && post.author_ref === currentUser.user_doc_id && (
                      <Typography
                        component="span"
                        variant="inherit"
                        fontSize="0.75rem"
                        color={theme.palette.success.main}
                      >
                        (אתה פרסמת)
                      </Typography>
                    )}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width={100} />
                )
              }
              titleTypographyProps={{
                fontWeight: 600
              }}
              subheader={
                <Typography variant="inherit" fontSize="inherit">
                  {getTimeOfPost(post.created_at.seconds)}
                </Typography>
              }
            />
            <CardContent sx={{ pb: 0, pt: 0.5 }}>
              {post ? (
                <Typography variant="body1" color="text">
                  {post.description}
                </Typography>
              ) : (
                <Skeleton variant="text" width={180} />
              )}
              {post ? (
                <AttachedFileList
                  infoVisible={false}
                  secondaryPhrase={signaturePhrase}
                  deleteIconVisible={false}
                  contentEditable={false}
                  key={`attached-list-${post.post_id}`}
                  attachedFiles={{
                    name: post.file && post.file.file_name,
                    type: post.file && post.file.type
                  }}
                />
              ) : (
                <Stack direction="row" gap={1}>
                  <Avatar>
                    <Skeleton
                      variant="rounded"
                      sx={{ width: '100%', height: '100%', minWidth: '48px' }}
                    />
                  </Avatar>
                  <Stack>
                    <Skeleton variant="text" width={90} />
                    <Skeleton variant="text" width={130} />
                  </Stack>
                </Stack>
              )}
            </CardContent>
            <CardActions
              sx={{
                position: 'relative',
                pt: '0 !important',
                justifyContent: 'space-between'
              }}
              disableSpacing
            >
              <Stack direction="row" gap={1} alignItems="center">
                <FavoritePostButton
                  post={{ ...post, postDetails }}
                  key={`favorite-btn-${post.post_id}`}
                  handleFavoriteItem={handleFavoritePostItem}
                />
                {post.signature_required && (
                  <>
                    <Divider
                      orientation="vertical"
                      sx={{ minHeight: 30, height: '100%' }}
                    />
                    <Stack direction="row" gap={0.5}>
                      {postDetails && !isUndefined(postDetails.signCount) ? (
                        <Typography
                          variant="body2"
                          sx={{
                            lineHeight: 3,
                            userSelect: 'none',
                            cursor: (isMyPost || isOrg) && 'pointer'
                          }}
                          onClick={() => {
                            const iRankHigher =
                              compareRolesOrdersOfAnyOfTheDepartments();
                            console.log(iRankHigher);
                            if (isMyPost || isOrg || iRankHigher) {
                              handleOpenPostSignaturesList(post);
                            }
                          }}
                        >
                          {getSignCountPhrase()}
                        </Typography>
                      ) : (
                        <Skeleton variant="text" width={60} />
                      )}
                    </Stack>
                  </>
                )}
              </Stack>
              {post.signature_required && (
                <>
                  {isUserRequiredToSign() ? (
                    <Button
                      key={`sign-btn-${post.post_id}`}
                      variant="outlined"
                      color="success"
                      fullWidth
                      sx={{
                        maxWidth: 120
                      }}
                      size="small"
                      onClick={() =>
                        handleViewCommunityAttachedFile({
                          ...post,
                          postDetails
                        })
                      }
                    >
                      צפיה וחתימה
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        handleViewCommunityAttachedFile(
                          { ...post, postDetails },
                          false
                        )
                      }
                      fullWidth
                      sx={{
                        maxWidth: 120
                      }}
                      size="small"
                      endIcon={
                        <Tooltip
                          title={
                            <Stack gap={1}>
                              <Typography variant="body1">
                                מי נדרש לחתום?
                              </Typography>
                              <Divider />
                              <Stack gap={1} direction="row" flexWrap="wrap">
                                {post.labels.map((postLabel) => (
                                  <Chip
                                    key={postLabel}
                                    color="warning"
                                    label={getJobLabel(postLabel)}
                                  />
                                ))}
                              </Stack>
                            </Stack>
                          }
                          sx={{ maxWidth: 350 }}
                        >
                          <InfoOutlined />
                        </Tooltip>
                      }
                    >
                      אינך נדרש לחתום
                    </Button>
                  )}
                </>
              )}
            </CardActions>
          </Card>
        </Grid>
      ) : null}
    </>
  );
};

export default CommunityPostItemCard;
