import { InfoOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';

const SignPostDialog = ({ open, onClose, post, handleSignCommunityPost }) => {
  SignPostDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    post: PropTypes.object,
    handleSignCommunityPost: PropTypes.func
  };
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const [verifyInput, setVerifyInput] = useState({ id: '' });

  const getSelectedOrgName = () => {
    if (selectedOrg && currentUser && currentUser.employee_work_places) {
      const selectedOrgObject = currentUser.employee_work_places.find(
        (org) => org.org_ref === selectedOrg
      );
      return selectedOrgObject.org_name;
    }
    if (currentUser && currentUser.type === 'org') {
      return currentUser.org_name;
    }
  };

  // const handleDownloadUrl =async (postToGet) => {
  //   const {org_ref, post_id} = postToGet;
  //   const storageRef = ref(storage, `orgs/${org_ref}/community/${post_id}`);
  //   getDownloadURL(storageRef).then((postUrl) => {
  //     if(post.file){
  //       post.file.file_url
  //     }
  //   })
  // }

  // useEffect(() => {
  //   if(post){
  //     handleDownloadUrl(post)
  //   }
  // }, [post])
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>חתימה על פרסום קהילה</DialogTitle>
      <DialogContent>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="שם הארגון"
              name="org_name"
              disabled
              value={getSelectedOrgName()}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="תאריך פרסום"
              name="date"
              disabled
              value={moment(post.created_at.seconds * 1000).format(
                'DD/MM/YYYY'
              )}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ת.ז"
              name="id"
              autoFocus
              value={verifyInput.id}
              onChange={(e) => setVerifyInput({ id: e.target.value })}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <Tooltip
                    sx={{ cursor: 'default' }}
                    title="נא להזין ת.ז לאימות"
                  >
                    <InfoOutlined color="action" />
                  </Tooltip>
                )
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verifyInput.id || verifyInput.id !== currentUser.id}
          variant="contained"
          color="primary"
          onClick={() => handleSignCommunityPost(post, verifyInput.id)}
        >
          חתימה
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose}>
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignPostDialog;
