import { doc, getDoc } from '@firebase/firestore';
import { db } from 'src/_firebase/firebase';

const getUserDetails = async (user_id) => {
  const userDoc = doc(db, `users/${user_id}`);
  return getDoc(userDoc)
    .then((res) => {
      if (!res.exists()) {
        return {};
      }
      return { ...res.data(), user_doc_id: res.id };
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
};

export default getUserDetails;
