import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';
import QuestionSlide from '../../sharedPartials/QuestionSlide';
import getQuestionData from '../getQuestionData';
import './palliative.css';

/* eslint-disable */
const PalliativeCare = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
}) => {
  PalliativeCare.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    handleSetViewToSignCourseware: PropTypes.func,
    globalCoursewares: PropTypes.array
  };
  // const { globalCoursewares } = useGlobalCoursewares();
  // const questionsSlides = [1];palliativeCare
  // console.log(test);

  return (
    <div
      className="theme-font-montserrat theme-color-grey-blue"
      style={{ width: '100%', height: '100%' }}
    >
      <div className="reveal palliativeCare">
        <div className="slides">
          <section
          // data-auto-animate
          // data-auto-animate-duration="0.7"
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-e9e335"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '774.354px 360px 0px',
                  '-ms-transform-origin': '774.354px 360px 0px',
                  'transform-origin': '774.354px 360px 0px',
                  'z-index': '10'
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  data-name="ai-gradient-block"
                  style={{
                    width: '328px',
                    height: '313px',
                    left: '610.354px',
                    top: '203.5px'
                  }}
                >
                  <div
                    className="sl-block-content ai-gradient-svg"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(0, 0, 0, 0)"
                    data-shape-stretch="true"
                    style={{ 'z-index': '11' }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 328 313"
                    >
                      <rect
                        width={328}
                        height={313}
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgba(0, 0, 0, 0)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '850.068px',
                left: '21.6463px',
                top: '227.688px'
              }}
              data-name="text-3cd81c"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '13',
                  '-webkit-transform': 'rotate(0deg)',
                  '-ms-transform': 'rotate(0deg)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    'z-index': '13',
                    color: 'rgb(255, 255, 255)',
                    'letter-spacing': '0.01em',
                    fontSize: '110%'
                  }}
                  data-has-letter-spacing
                >
                  <h1
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    <strong>&nbsp;&nbsp;טיפול &nbsp;&nbsp;פליאטיבי</strong>
                  </h1>
                  <h1
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    <strong>PALLIATIVE&nbsp; CARE</strong>
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 80, top: 536, height: 'auto' }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '130%'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  מערך ההדרכה - מגדלור ייעוץ והכוונה
                </p>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate
          // data-auto-animate-duration="0.7"
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '960px',
                height: '720px',
                left: '0px',
                top: '0px'
              }}
              // data-auto-animate-duration={0}
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '10' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 960 720"
                >
                  <rect
                    width={960}
                    height={720}
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '806px',
                left: '77px',
                top: '63.6421px',
                height: 'auto'
              }}
              data-name="text-855285"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '13',
                  fontSize: '160%',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  'line-height': '1.04',
                  'letter-spacing': '-0.1em',
                  color: 'rgb(255, 255, 255)'
                }}
                data-has-line-height
                data-has-letter-spacing
              >
                <h1
                  style={{
                    '-webkit-text-align': 'center',
                    'text-align': 'center'
                  }}
                >
                  <strong>כיצד נלמד?</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '806px',
                left: '77px',
                top: '260.738px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '14',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  padding: '8px',
                  fontSize: '110%',
                  color: 'rgb(255, 255, 255)'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'center',
                    'text-align': 'center'
                  }}
                >
                  <strong>ההתקדמות היא באמצעות החצים &lt; או&nbsp; &gt;</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '806px',
                left: '77px',
                top: '430.78px',
                height: 'auto'
              }}
              data-name="text-4bdc77"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '15',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  padding: '8px',
                  fontSize: '110%',
                  color: 'rgb(255, 255, 255)'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'center',
                    'text-align': 'center'
                  }}
                >
                  <strong>
                    כשמגיעים לשקופית שמכילה שאלה, אי אפשר להתקדם עד שעונים נכון
                    עליה
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          // data-auto-animate-duration="0.7"
          // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '618.376px',
                height: '619.469px',
                left: '341.624px',
                top: '38.4066px'
              }}
              data-name="shape-c54e0a"
              // data-auto-animate-duration="1.1"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '10', opacity: '0.2' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 618 619"
                  >
                    <rect
                      width="618.376"
                      height="619.469"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '572.621px',
                height: '573.633px',
                left: '364.502px',
                top: '61.3246px'
              }}
              data-name="shape-b5dd8f"
              // data-auto-animate-duration={1}
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '11', opacity: '0.4' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '11' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 573 574"
                  >
                    <rect
                      width="572.621"
                      height="573.633"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '524.737px',
                height: '525.665px',
                left: '388.444px',
                top: '85.3086px'
              }}
              data-name="shape-c4268d"
              // data-auto-animate-duration="0.9"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '12', opacity: '0.6' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '12' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 525 526"
                  >
                    <rect
                      width="524.737"
                      height="525.665"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '477.918px',
                height: '478.763px',
                left: '411.853px',
                top: '108.76px'
              }}
              data-name="shape-22dffa"
              // data-auto-animate-duration="0.8"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '13', opacity: '0.8' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '13' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 478 479"
                  >
                    <rect
                      width="477.918"
                      height="478.763"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '434.291px',
                height: '435.059px',
                left: '433.667px',
                top: '130.611px'
              }}
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '14' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 434 435"
                >
                  <rect
                    width="434.291"
                    height="435.059"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '739.39px',
                left: '110.305px',
                top: '185.141px'
              }}
              data-name="text-53485c1"
              // data-auto-animate-delay="0.1"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '17',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '107%',
                  'line-height': '2.028'
                }}
                data-has-line-height
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  <strong>
                    גישה טיפולית המשפרת את איכות החיים של חולים ובני משפחותיהם
                    המתמודדים עם בעיות הקשורות למחלות המאיימות על החיים, דרך
                    מניעה והקלה של הסבל ע"י גילוי מוקדם ואומדן מקצועי של כאב
                    וסימפטומים נוספים, גופניים פסיכו-סוציאלים ורוחניים (WHO
                    2022).
                  </strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '389px',
                left: '456.313px',
                top: '66.2601px'
              }}
              data-name="text-632c65"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '16', fontSize: '219%' }}
              >
                <p>טיפול פליאטיבי</p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '673.315px',
                left: '143.343px',
                top: '142.252px'
              }}
              data-name="text-cb33f6"
            >
              <div
                className="sl-block-content fragment fade-in-then-semi-out"
                style={{
                  'z-index': '10',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '150%'
                }}
                data-fragment-index={0}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  טיפול תומך מקבל את המוות כחלק טבעי במעגל החיים
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-75fd67"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '480px 79px 0px',
                  '-ms-transform-origin': '480px 79px 0px',
                  'transform-origin': '480px 79px 0px',
                  'z-index': '11'
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  data-name="ai-gradient-block"
                  style={{
                    width: '499.248px',
                    height: '78.248px',
                    left: '230.376px',
                    top: '39.876px'
                  }}
                >
                  <div
                    className="sl-block-content ai-gradient-svg"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(0, 0, 0, 0)"
                    data-shape-stretch="true"
                    style={{ 'z-index': '12' }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 499 78"
                    >
                      <rect
                        width="499.248"
                        height="78.248"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgba(0, 0, 0, 0)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '244px',
                    left: '358px',
                    top: '38.5px'
                  }}
                  data-name="text-c17bd6"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      'z-index': '13',
                      '-webkit-text-align': 'left',
                      'text-align': 'left',
                      fontSize: '200%',
                      'letter-spacing': '0.11em'
                    }}
                    data-has-letter-spacing
                  >
                    <p>
                      <strong>תזכרו!</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '684px',
                left: '121px',
                top: '297.5px'
              }}
              data-name="text-a0180d"
            >
              <div
                className="sl-block-content fragment"
                style={{
                  'z-index': '14',
                  fontSize: '138%',
                  '-webkit-text-align': 'justify',
                  'text-align': 'justify'
                }}
                data-fragment-index={1}
              >
                <blockquote style={{ direction: 'ltr' }}>
                  <p>
                    You matter because you are you.
                    <br />
                    You matter to the last moment of your life, and we will do
                    all we can not only to help you die peacefully, but also to
                    live until you die
                  </p>
                </blockquote>
                <blockquote>
                  {/* <p>&nbsp;</p> */}
                  <caption style={{ textAlign: 'center', fontSize: '85%' }}>
                    Dr. Cecily Saunders
                  </caption>
                </blockquote>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          // data-auto-animate-duration="0.7"
          // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '107.885px',
                height: '105.481px',
                left: '842.412px',
                top: '566.593px'
              }}
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '14' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 108 105"
                >
                  <rect
                    width="107.885"
                    height="105.481"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-bd6299"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '896.352px 620.317px 0px',
                  '-ms-transform-origin': '896.352px 620.317px 0px',
                  'transform-origin': '896.352px 620.317px 0px',
                  'z-index': '15'
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  data-name="image-231b4f"
                  style={{
                    width: '56.8px',
                    height: '56.8px',
                    left: '867.952px',
                    top: '591.917px',
                    'min-width': '1px',
                    'min-height': '1px'
                  }}
                >
                  <div
                    className="sl-block-content"
                    style={{
                      'z-index': '17',
                      '--svg-color': 'rgb(255, 255, 255)'
                    }}
                    data-inline-svg="true"
                  >
                    <img
                      className
                      data-natural-width={132}
                      data-natural-height={132}
                      style={{}}
                      data-lazy-loaded
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/32388/images/10476123/paste-from-clipboard.svg"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={132}
                      height={132}
                      viewBox="0 0 132 132"
                      fill="none"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path
                        d="M59.5082 21.1603L62.8795 40.2217C65.8948 57.2702 80.2427 70.6323 98.5489 73.4405L119.016 76.5802L98.5489 79.7198C80.2427 82.528 65.8948 95.8901 62.8795 112.939L59.5082 132L56.1369 112.939C53.1216 95.8901 38.7737 82.528 20.4675 79.7198L0 76.5802L20.4676 73.4405C38.7737 70.6323 53.1216 57.2702 56.1369 40.2216L59.5082 21.1603Z"
                        fill="black"
                        style={{ fill: 'var(--svg-color, rgb(0, 0, 0))' }}
                      />
                      <path
                        d="M104.951 0L106.483 8.66427C107.854 16.4136 114.376 22.4873 122.697 23.7637L132 25.1908L122.697 26.618C114.376 27.8944 107.854 33.9681 106.483 41.7174L104.951 50.3817L103.418 41.7174C102.048 33.9681 95.5261 27.8944 87.205 26.618L77.9016 25.1908L87.2051 23.7637C95.5261 22.4873 102.048 16.4136 103.418 8.66423L104.951 0Z"
                        fill="black"
                        style={{ fill: 'var(--svg-color, rgb(0, 0, 0))' }}
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '107.885px',
                height: '105.481px',
                left: '841.212px',
                top: '567.577px'
              }}
              data-name="shape-22dffa"
              // data-auto-animate-delay="0.1"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '13', opacity: '0.8' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '13' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 108 105"
                  >
                    <rect
                      width="107.885"
                      height="105.481"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '107.885px',
                height: '105.481px',
                left: '841.212px',
                top: '567.577px'
              }}
              data-name="shape-c4268d"
              // data-auto-animate-delay="0.2"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '12', opacity: '0.6' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '12' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 108 105"
                  >
                    <rect
                      width="107.885"
                      height="105.481"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '107.885px',
                height: '105.481px',
                left: '841.212px',
                top: '567.577px'
              }}
              data-name="shape-b5dd8f"
              // data-auto-animate-delay="0.3"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '11', opacity: '0.4' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '11' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 108 105"
                  >
                    <rect
                      width="107.885"
                      height="105.481"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '107.885px',
                height: '107.448px',
                left: '841.212px',
                top: '566.593px'
              }}
              data-name="shape-c54e0a"
              // data-auto-animate-delay="0.4"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '10', opacity: '0.2' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 108 107"
                  >
                    <rect
                      width="107.885"
                      height="107.448"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '600px',
                left: '180px',
                top: '73.6713px'
              }}
              data-name="text-cf5267"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '20',
                  color: 'rgb(255, 255, 255)',
                  fontSize: '180%'
                }}
              >
                <p>
                  <strong>מאפייני הטיפול התומך</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '741px',
                left: '116.178px',
                top: '168.5px'
              }}
              data-name="text-d92b97"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '21',
                  color: 'rgb(255, 255, 255)',
                  'line-height': '1.599'
                }}
                data-has-line-height
              >
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <span style={{ fontSize: '1.2em' }}>
                    * לתמוך בחיים ולהתייחס למוות כאל תהליך טבעי.
                  </span>
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <span style={{ fontSize: '1.2em' }}>
                    * אינו מקצר ואינו מאריך את טווח החיים.
                  </span>
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <span style={{ fontSize: '1.2em' }}>
                    * להקל על כאבים וסימפטומים אחרים.
                  </span>
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <span style={{ fontSize: '1.2em' }}>
                    * לשלב היבטים פסיכולוגיים ורוחניים.
                  </span>
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <span style={{ fontSize: '1.2em' }}>
                    * להציע תמיכה בחיים פעילים.
                  </span>
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <span style={{ fontSize: '1.2em' }}>
                    * תמיכה במשפחה בתקופת המחלה והאבל.
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '800px',
                left: '80px',
                top: '115.424px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  '-webkit-text-align': 'center',
                  'text-align': 'center',
                  'z-index': '11',
                  color: 'rgb(17, 17, 17)',
                  'line-height': '1.17'
                }}
                data-has-line-height
              >
                <h2>
                  <strong>טיפול תומך מול מרפא</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="table"
              style={{
                height: 'auto',
                'min-width': '120px',
                width: '800px',
                left: '86.431px',
                top: '287.739px'
              }}
              data-name="table-72c1f5"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '12',
                  '-webkit-text-align': 'center',
                  'text-align': 'center',
                  color: 'rgb(17, 17, 17)'
                }}
                data-table-cols={2}
                data-table-rows={5}
                data-table-border-width="2px"
                data-table-has-header="true"
              >
                <table>
                  <tbody>
                    <tr>
                      <th style={{ 'border-width': '2px' }} />
                      <th style={{ 'border-width': '2px' }} />
                    </tr>
                    <tr>
                      <td style={{ 'border-width': '2px' }}>תוחלת חיים</td>
                      <td style={{ 'border-width': '2px' }}>
                        איכות חיים
                        <br type="_moz" />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ 'border-width': '2px' }}>טיפול פולשני</td>
                      <td style={{ 'border-width': '2px' }}>טיפול לא פולשני</td>
                    </tr>
                    <tr>
                      <td style={{ 'border-width': '2px' }}>
                        התמקדות במחלה ובאבחונה
                      </td>
                      <td style={{ 'border-width': '2px' }}>
                        התמקדות במטופל ומשפחתו
                      </td>
                    </tr>
                    <tr>
                      <td style={{ 'border-width': '2px' }}>
                        עבודת צוות רב מקצועית
                      </td>
                      <td style={{ 'border-width': '2px' }}>
                        עבודת צוות בין-מקצועית
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '400px',
                left: '486.431px',
                top: '286.739px'
              }}
              data-name="text-14c693"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '13',
                  fontSize: '127%',
                  color: 'rgb(147, 196, 125)',
                  'background-color': 'rgb(217, 234, 211)',
                  'border-style': 'solid',
                  'border-width': '2px'
                }}
              >
                <p>
                  <span style={{ color: '#006400' }}>
                    <strong>טיפול מרפא</strong>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '400px',
                left: '86.431px',
                top: '286.739px'
              }}
              data-name="text-77e02a"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '14',
                  fontSize: '127%',
                  color: 'rgb(106, 168, 79)',
                  'background-color': 'rgb(217, 234, 211)',
                  'border-style': 'solid',
                  'border-width': '2px'
                }}
              >
                <p>
                  <span style={{ color: '#006400' }}>
                    <strong>טיפול תומך</strong>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '864.61px',
                height: '612.336px',
                left: '47.695px',
                top: '53.832px'
              }}
              data-name="shape-87884b"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(0deg)',
                  '-ms-transform': 'rotate(0deg)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(255, 255, 255)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 865 612"
                  >
                    <rect
                      width="864.61"
                      height="612.336"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgb(255, 255, 255)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate
          // data-auto-animate-duration="0.7"
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '779.462px',
                left: '90.269px',
                top: '44.674px'
              }}
              data-name="text-6fc27b"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '11',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '200%'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'center',
                    'text-align': 'center'
                  }}
                >
                  <span style={{ fontSize: '1.0em' }}>
                    <strong>
                      תרשים זרימה בהגדרת מטופל המתאים לטיפול פליאטיבי
                    </strong>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '713.347px',
                height: '730.888px',
                left: '388.345px',
                top: '66.056px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 713 731"
                  >
                    <rect
                      width="713.347"
                      height="730.888"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '228px',
                left: '69.3539px',
                top: '402.893px'
              }}
              data-name="text-408df4"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '12',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment"
                  style={{
                    'z-index': '12',
                    color: 'rgb(0, 0, 255)',
                    'background-color': 'rgb(255, 255, 255)',
                    padding: '16px',
                    'border-style': 'solid',
                    'border-radius': '20px'
                  }}
                  data-fragment-index={2}
                >
                  <p>
                    בדיקה ע"י מנהל
                    <br />
                    רפואי/רופא מטעמו
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-a73bbb"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  '-webkit-transform-origin': '725.725px 308.772px 0px',
                  '-ms-transform-origin': '725.725px 308.772px 0px',
                  'transform-origin': '725.725px 308.772px 0px',
                  'z-index': '19'
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '228px',
                    left: '662.646px',
                    top: '230.752px'
                  }}
                  data-name="text-af1425"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '20',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '20',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(255, 255, 255)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p>
                        זיהוי פוטנציאלי
                        <br />
                        ע"י צוות רב
                        <br />
                        מקצועי
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d889ac"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      '-webkit-transform-origin': '591.996px 339.396px 0px',
                      '-ms-transform-origin': '591.996px 339.396px 0px',
                      'transform-origin': '591.996px 339.396px 0px',
                      'z-index': '21'
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="drawing"
                      style={{
                        width: '99.4648px',
                        height: '74.2404px',
                        left: '600.479px',
                        top: '294.155px'
                      }}
                      data-name="drawing-224539"
                    >
                      <div
                        className="sl-block-content"
                        data-points="845 351.78,843 352.78,839 353.78,834 356.78,819 366.78,803 377.78,783 391.78,774 400.78,769 403.78,767 406.78,765 408.78,761 410.78,759 413.78,756 415.78,752 418.78,751 421.78,748 422.78"
                        data-timestamp={1693763158904}
                        style={{ 'z-index': '22' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          preserveAspectRatio="none"
                          width="100%"
                          height="100%"
                          viewBox="749.2556762695312 348.0594787597656 99.46484375 74.24041748046875"
                        >
                          <path
                            fill="rgb(147, 196, 125)"
                            d="M 846.2440472392334 355.37094105175964 Q 846.24 355.37 845.05 355.89 843.85 356.4 839.92 358.16 836 359.91 827.21 365.08 818.42 370.25 810.11 375.98 801.8 381.7 793.25 387.96 784.7 394.23 780.19 398.24 775.69 402.26 772.94 404.39 770.19 406.52 767.95 409 765.72 411.48 762.82 414.06 759.93 416.64 757.06 419.41 754.2 422.19 751.94 422.2 749.68 422.21 749.31 419.98 748.94 417.75 751.09 417.04 753.24 416.33 754.28 418.33 755.32 420.34 753.49 421.68 751.67 423.03 750.06 421.44 748.45 419.85 751.89 416.22 755.33 412.59 758.6 410.25 761.88 407.92 764.53 405.24 767.17 402.57 769.8 400.52 772.43 398.48 777.07 394.36 781.7 390.24 790.33 383.91 798.96 377.58 807.3 371.84 815.64 366.09 822.58 360.88 829.51 355.67 833.3 353.19 837.09 350.71 838.94 350.05 840.79 349.4 841.84 349.01 842.88 348.63 843.1 348.52 843.32 348.41 843.88 348.23 844.44 348.05 845.03 348.06 845.62 348.06 846.18 348.25 846.74 348.44 847.21 348.79 847.69 349.14 848.03 349.62 848.37 350.1 848.55 350.66 848.73 351.22 848.72 351.81 848.72 352.4 848.53 352.96 848.34 353.52 847.99 353.99 847.64 354.47 847.16 354.81 846.68 355.15 846.46 355.26 Z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="drawing"
                      style={{
                        width: '28.6657px',
                        height: '43.5931px',
                        left: '580.804px',
                        top: '343.199px'
                      }}
                      data-name="drawing-43b522"
                    >
                      <div
                        className="sl-block-content"
                        data-points="750 400.78,746 406.78,744 409.78,736 422.78,731 430.78,728 433.78,728 435.78,731 436.78,735 436.78,737 436.78,747 437.78,751 437.78,756 437.78,759 437.78"
                        data-timestamp={1693763159575}
                        style={{ 'z-index': 22 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          preserveAspectRatio="none"
                          width="100%"
                          height="100%"
                          viewBox="728.5812377929688 397.102783203125 28.6656494140625 43.593109130859375"
                        >
                          <path
                            fill="rgb(147, 196, 125)"
                            d="M 752.2237010964441 404.15830073096265 Q 752.22 404.16 749.6 407.91 746.97 411.66 742.45 418.12 737.94 424.59 735.53 428.26 733.12 431.94 731.79 433.26 730.46 434.59 730.96 434.01 731.46 433.42 735.25 433.51 739.04 433.59 743.69 434.04 748.34 434.48 751.46 434.4 754.58 434.32 756.26 435.78 757.94 437.24 756.76 439.12 755.58 441.01 753.53 440.14 751.49 439.27 752.03 437.11 752.57 434.96 754.78 435.15 756.99 435.35 757.15 437.56 757.3 439.78 752.59 440.34 747.88 440.89 742.49 440.59 737.1 440.3 732.74 439.21 728.39 438.13 728.59 434.32 728.79 430.52 731.14 426.18 733.5 421.84 737.4 414.89 741.3 407.93 743.66 403.98 746.03 400.03 746.46 399.37 746.9 398.71 747.3 398.29 747.69 397.86 748.2 397.57 748.71 397.29 749.28 397.17 749.85 397.06 750.43 397.13 751.01 397.19 751.54 397.44 752.07 397.68 752.49 398.08 752.92 398.47 753.21 398.98 753.49 399.49 753.61 400.06 753.72 400.63 753.65 401.21 753.59 401.79 753.34 402.32 753.1 402.85 752.66 403.5 Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-09f95e"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  '-webkit-transform-origin': '440.777px 410.149px 0px',
                  '-ms-transform-origin': '440.777px 410.149px 0px',
                  'transform-origin': '440.777px 410.149px 0px',
                  'z-index': '13'
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '228px',
                    left: '367.634px',
                    top: '322.225px'
                  }}
                  data-name="text-7a8399"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '14',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '14',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(255, 255, 255)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p>
                        הצגת המקרה
                        <br />
                        בפני נציג ועדת טיפול תומך
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-3b4ef4"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      '-webkit-transform-origin': '304px 470.147px 0px',
                      '-ms-transform-origin': '304px 470.147px 0px',
                      'transform-origin': '304px 470.147px 0px',
                      'z-index': '15'
                    }}
                  >
                    <div
                      className="sl-block sl-block-group"
                      data-block-type="group"
                      data-name="group-951eef"
                    >
                      <div
                        className="sl-block-content sl-block-group-content"
                        style={{
                          '-webkit-transform-origin': '277.52px 462.005px 0px',
                          '-ms-transform-origin': '277.52px 462.005px 0px',
                          'transform-origin': '277.52px 462.005px 0px',
                          'z-index': '16'
                        }}
                      >
                        <div
                          className="sl-block"
                          data-block-type="drawing"
                          style={{
                            width: '67.3191px',
                            height: '31.285px',
                            left: '312.518px',
                            top: '444.377px'
                          }}
                          data-name="drawing-79a4e5"
                        >
                          <div
                            className="sl-block-content"
                            data-points="458 474.78,456 474.78,450 476.78,437 482.78,427 486.78,417 490.78,409 495.78,405 496.78,403 497.78,399 499.78,396 500.78,393 500.78"
                            data-timestamp={1693763161314}
                            style={{ 'z-index': '17' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              preserveAspectRatio="none"
                              width="100%"
                              height="100%"
                              viewBox="394.4035339355469 471.010009765625 67.319091796875 31.285003662109375"
                            >
                              <path
                                fill="rgb(147, 196, 125)"
                                d="M 457.5763514315333 478.54561121259457 Q 457.58 478.55 456.39 478.76 455.21 478.97 452.06 480.08 448.91 481.19 443.07 483.34 437.24 485.48 432.07 487.49 426.9 489.5 422.14 491.6 417.39 493.7 413.8 495.72 410.21 497.74 407.67 498.88 405.13 500.02 401.58 501.42 398.02 502.82 396.01 501.98 393.99 501.13 394.51 499.01 395.03 496.89 397.21 497.07 399.39 497.25 399.55 499.43 399.71 501.61 397.58 502.11 395.46 502.61 394.63 500.59 393.8 498.57 397.1 497.05 400.4 495.54 403.8 494.48 407.21 493.42 411.28 491.28 415.35 489.15 420.18 487.01 425.02 484.87 430.05 482.74 435.08 480.61 440.85 477.61 446.63 474.6 450.59 473.03 454.56 471.47 456.05 471.24 457.55 471.01 457.77 471.01 457.99 471.01 458.57 471.1 459.15 471.19 459.68 471.46 460.2 471.73 460.62 472.14 461.04 472.56 461.31 473.08 461.58 473.6 461.67 474.18 461.77 474.77 461.68 475.35 461.59 475.93 461.32 476.46 461.05 476.98 460.64 477.4 460.22 477.82 459.7 478.09 459.18 478.36 458.6 478.45 458.01 478.55 457.8 478.55 Z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          className="sl-block"
                          data-block-type="drawing"
                          style={{
                            width: '37.9152px',
                            height: '52.6459px',
                            left: '285.919px',
                            top: '445.427px'
                          }}
                          data-name="drawing-3d4945"
                        >
                          <div
                            className="sl-block-content"
                            data-points="402 475.78,400 476.78,398 478.78,394 481.78,379 488.78,373 491.78,371 495.78,369 495.78,369 497.78,371 499.78,374 502.78,377 505.78,380 508.78,384 511.78,387 514.78,390 517.78,391 519.78,393 520.78,395 522.78,396 524.78"
                            data-timestamp={1693763162232}
                            style={{ 'z-index': '18' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              preserveAspectRatio="none"
                              width="100%"
                              height="100%"
                              viewBox="367.8048095703125 472.05999755859375 37.915191650390625 52.6458740234375"
                            >
                              <path
                                fill="rgb(147, 196, 125)"
                                d="M 403.25764065633865 479.36266540064145 Q 403.26 479.36 401.99 480.26 400.72 481.15 396.94 483.52 393.16 485.89 386.77 488.46 380.37 491.03 377.39 492.76 374.4 494.5 373.55 496.16 372.69 497.81 375.16 499.58 377.63 501.35 380.78 504.28 383.93 507.22 387.15 510.14 390.37 513.07 393.3 516.02 396.22 518.98 396.29 521.56 396.37 524.15 393.82 524.63 391.28 525.1 390.41 522.66 389.54 520.23 391.81 518.99 394.09 517.75 395.66 519.8 397.24 521.85 395.47 523.73 393.69 525.61 390.76 523.31 387.83 521.01 385.21 518.36 382.6 515.72 380.31 513.76 378.02 511.79 375.28 509.09 372.55 506.38 369.93 503.04 367.31 499.69 367.92 497.04 368.53 494.4 370.17 492.21 371.81 490.02 374.93 488.21 378.04 486.4 383.57 483.25 389.11 480.1 391.93 478.22 394.76 476.33 396.27 475.1 397.78 473.87 398.82 473.25 399.87 472.63 400.09 472.53 400.3 472.42 400.87 472.24 401.43 472.06 402.02 472.06 402.61 472.06 403.17 472.25 403.73 472.43 404.2 472.78 404.68 473.13 405.02 473.61 405.36 474.08 405.54 474.65 405.72 475.21 405.72 475.8 405.72 476.39 405.53 476.95 405.35 477.51 405 477.98 404.65 478.46 404.17 478.8 403.7 479.14 403.48 479.25 Z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          // data-auto-animate-duration="0.7"
          // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '713.347px',
                height: '730.888px',
                left: '390.345px',
                top: '66.056px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 713 731"
                  >
                    <rect
                      width="713.347"
                      height="730.888"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '440px',
                left: '260px',
                top: '211.25px'
              }}
              data-name="text-af1425"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '11',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    'z-index': '11',
                    color: 'rgb(0, 0, 255)',
                    'background-color': 'rgb(249, 203, 156)',
                    padding: '16px',
                    'border-style': 'solid',
                    'border-radius': '20px'
                  }}
                >
                  <p>החולה לא מתאים להגדרה?</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '70px',
                height: '102px',
                left: '445px',
                top: '308.75px'
              }}
              data-name="shape-5e09b6"
            >
              <div
                className="sl-block-content"
                data-shape-type="arrow-down"
                data-shape-fill-color="rgb(255, 255, 255)"
                data-shape-stretch="true"
                style={{ 'z-index': '12' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 70 102"
                >
                  <polygon
                    points="35,102 70,51 49,51 49,0 21,0 21,51 0,51 35,102"
                    className="shape-element"
                    fill="rgb(255, 255, 255)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '440px',
                left: '260px',
                top: '437.75px'
              }}
              data-name="text-97bc53"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '13',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment fade-up"
                  style={{
                    'z-index': '13',
                    color: 'rgb(0, 0, 255)',
                    'background-color': 'rgb(217, 234, 211)',
                    padding: '16px',
                    'border-style': 'solid',
                    'border-radius': '20px'
                  }}
                  data-fragment-index={0}
                >
                  <p>דיון אחרי 3 חודשים</p>
                </div>
              </div>
            </div>
          </section>
          <section
            data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            data-auto-animate-duration="0.7"
            data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '713.347px',
                height: '730.888px',
                left: '1032.73px',
                top: '65.351px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 713 731"
                  >
                    <rect
                      width="713.347"
                      height="730.888"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '426px',
                left: '270.219px',
                top: '312.525px'
              }}
              data-name="text-af1425"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '11',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment current-visible"
                  style={{
                    'z-index': '11',
                    color: 'rgb(0, 0, 255)',
                    'background-color': 'rgb(249, 203, 156)',
                    padding: '16px',
                    'border-style': 'solid',
                    'border-radius': '20px',
                    'font-size': '153%'
                  }}
                  data-fragment-index={0}
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    זימון משפחה לישיבה עם ועדה לטיפול תומך
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '427.179px',
                left: '269.04px',
                top: '312.176px'
              }}
              data-name="text-93d63e"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '12',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment current-visible"
                  style={{
                    'z-index': '12',
                    color: 'rgb(0, 0, 255)',
                    'background-color': 'rgb(249, 203, 156)',
                    padding: '16px',
                    'border-style': 'solid',
                    'border-radius': '20px',
                    'font-size': '153%'
                  }}
                  data-fragment-index={1}
                >
                  <p>
                    תיעוד העדפות
                    <br />
                    מטופל/משפחה
                  </p>
                  <p>ועדה לטיפול תומך</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '228px',
                left: '26.4437px',
                top: '472.721px'
              }}
              data-name="text-408df4"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '13',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment"
                  style={{
                    'z-index': '13',
                    color: 'rgb(0, 0, 255)',
                    'background-color': 'rgb(255, 255, 255)',
                    padding: '16px',
                    'border-style': 'solid',
                    'border-radius': '20px'
                  }}
                  data-fragment-index={4}
                >
                  <p>ליווי ופיקוח</p>
                  <p>נאמן נושא</p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-a73bbb"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  '-webkit-transform-origin': '729.286px 393.623px 0px',
                  '-ms-transform-origin': '729.286px 393.623px 0px',
                  'transform-origin': '729.286px 393.623px 0px',
                  'z-index': '20'
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '228px',
                    left: '683.995px',
                    top: '314.525px'
                  }}
                  data-origin-id="58ac6de83314b2cd3c95c27fc964e403"
                  data-name="text-fd0dc7"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '21',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '21',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(255, 255, 255)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p>
                        קיום ישיבת
                        <br />
                        צוות רב
                        <br />
                        מקצועית
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d889ac"
                  data-origin-id="a74bed1965501458afea8efce49a2d42"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      '-webkit-transform-origin': '611.606px 325.477px 0px',
                      '-ms-transform-origin': '611.606px 325.477px 0px',
                      'transform-origin': '611.606px 325.477px 0px',
                      'z-index': '22'
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="drawing"
                      style={{
                        width: '99.4648px',
                        height: '74.2404px',
                        left: '567.251px',
                        top: '380.084px'
                      }}
                      data-name="drawing-224539"
                      data-origin-id="6cc28eafeb5bbe23b5da0645878a294b"
                    >
                      <div
                        className="sl-block-content"
                        data-points="845 351.78,843 352.78,839 353.78,834 356.78,819 366.78,803 377.78,783 391.78,774 400.78,769 403.78,767 406.78,765 408.78,761 410.78,759 413.78,756 415.78,752 418.78,751 421.78,748 422.78"
                        data-timestamp={1693763158904}
                        style={{ 'z-index': '23' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          preserveAspectRatio="none"
                          width="100%"
                          height="100%"
                          viewBox="749.2556762695312 348.0594787597656 99.46484375 74.24041748046875"
                        >
                          <path
                            fill="rgb(147, 196, 125)"
                            d="M 846.2440472392334 355.37094105175964 Q 846.24 355.37 845.05 355.89 843.85 356.4 839.92 358.16 836 359.91 827.21 365.08 818.42 370.25 810.11 375.98 801.8 381.7 793.25 387.96 784.7 394.23 780.19 398.24 775.69 402.26 772.94 404.39 770.19 406.52 767.95 409 765.72 411.48 762.82 414.06 759.93 416.64 757.06 419.41 754.2 422.19 751.94 422.2 749.68 422.21 749.31 419.98 748.94 417.75 751.09 417.04 753.24 416.33 754.28 418.33 755.32 420.34 753.49 421.68 751.67 423.03 750.06 421.44 748.45 419.85 751.89 416.22 755.33 412.59 758.6 410.25 761.88 407.92 764.53 405.24 767.17 402.57 769.8 400.52 772.43 398.48 777.07 394.36 781.7 390.24 790.33 383.91 798.96 377.58 807.3 371.84 815.64 366.09 822.58 360.88 829.51 355.67 833.3 353.19 837.09 350.71 838.94 350.05 840.79 349.4 841.84 349.01 842.88 348.63 843.1 348.52 843.32 348.41 843.88 348.23 844.44 348.05 845.03 348.06 845.62 348.06 846.18 348.25 846.74 348.44 847.21 348.79 847.69 349.14 848.03 349.62 848.37 350.1 848.55 350.66 848.73 351.22 848.72 351.81 848.72 352.4 848.53 352.96 848.34 353.52 847.99 353.99 847.64 354.47 847.16 354.81 846.68 355.15 846.46 355.26 Z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="drawing"
                      style={{
                        width: '28.6657px',
                        height: '43.5931px',
                        left: '546.576px',
                        top: '429.128px'
                      }}
                      data-name="drawing-43b522"
                      data-origin-id="baaa8687ad9408ae1c15fbf2a890dc7b"
                    >
                      <div
                        className="sl-block-content"
                        data-points="750 400.78,746 406.78,744 409.78,736 422.78,731 430.78,728 433.78,728 435.78,731 436.78,735 436.78,737 436.78,747 437.78,751 437.78,756 437.78,759 437.78"
                        data-timestamp={1693763159575}
                        style={{ 'z-index': '24' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          preserveAspectRatio="none"
                          width="100%"
                          height="100%"
                          viewBox="728.5812377929688 397.102783203125 28.6656494140625 43.593109130859375"
                        >
                          <path
                            fill="rgb(147, 196, 125)"
                            d="M 752.2237010964441 404.15830073096265 Q 752.22 404.16 749.6 407.91 746.97 411.66 742.45 418.12 737.94 424.59 735.53 428.26 733.12 431.94 731.79 433.26 730.46 434.59 730.96 434.01 731.46 433.42 735.25 433.51 739.04 433.59 743.69 434.04 748.34 434.48 751.46 434.4 754.58 434.32 756.26 435.78 757.94 437.24 756.76 439.12 755.58 441.01 753.53 440.14 751.49 439.27 752.03 437.11 752.57 434.96 754.78 435.15 756.99 435.35 757.15 437.56 757.3 439.78 752.59 440.34 747.88 440.89 742.49 440.59 737.1 440.3 732.74 439.21 728.39 438.13 728.59 434.32 728.79 430.52 731.14 426.18 733.5 421.84 737.4 414.89 741.3 407.93 743.66 403.98 746.03 400.03 746.46 399.37 746.9 398.71 747.3 398.29 747.69 397.86 748.2 397.57 748.71 397.29 749.28 397.17 749.85 397.06 750.43 397.13 751.01 397.19 751.54 397.44 752.07 397.68 752.49 398.08 752.92 398.47 753.21 398.98 753.49 399.49 753.61 400.06 753.72 400.63 753.65 401.21 753.59 401.79 753.34 402.32 753.1 402.85 752.66 403.5 Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-09f95e"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-in-then-semi-out"
                style={{
                  '-webkit-transform-origin': '395.943px 470.279px 0px',
                  '-ms-transform-origin': '395.943px 470.279px 0px',
                  'transform-origin': '395.943px 470.279px 0px',
                  'z-index': '14'
                }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '228px',
                    left: '336.04px',
                    top: '386.426px'
                  }}
                  data-name="text-7a8399"
                  data-origin-id="f2865ed101d031210ff843562b688efa"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '15',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '15',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(255, 255, 255)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p>
                        קביעת תכנית
                        <br />
                        טיפול צוות רב
                        <br />
                        מקצועי
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-3b4ef4"
                  data-origin-id="1063bba982c373a209c11d55ad0c35b7"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      '-webkit-transform-origin': '279.764px 426.358px 0px',
                      '-ms-transform-origin': '279.764px 426.358px 0px',
                      'transform-origin': '279.764px 426.358px 0px',
                      'z-index': '16'
                    }}
                  >
                    <div
                      className="sl-block sl-block-group"
                      data-block-type="group"
                      data-name="group-951eef"
                      data-origin-id="6b687d1a4191542c3c55ba90d5fcc03e"
                    >
                      <div
                        className="sl-block-content sl-block-group-content"
                        style={{
                          '-webkit-transform-origin': '279.764px 426.358px 0px',
                          '-ms-transform-origin': '279.764px 426.358px 0px',
                          'transform-origin': '279.764px 426.358px 0px',
                          'z-index': '17'
                        }}
                      >
                        <div
                          className="sl-block"
                          data-block-type="drawing"
                          style={{
                            width: '67.3191px',
                            height: '31.285px',
                            left: '254.444px',
                            top: '500.436px'
                          }}
                          data-name="drawing-79a4e5"
                          data-origin-id="65754da31ba3d4ee5a26cd3328565b9b"
                        >
                          <div
                            className="sl-block-content"
                            data-points="458 474.78,456 474.78,450 476.78,437 482.78,427 486.78,417 490.78,409 495.78,405 496.78,403 497.78,399 499.78,396 500.78,393 500.78"
                            data-timestamp={1693763161314}
                            style={{ 'z-index': '18' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              preserveAspectRatio="none"
                              width="100%"
                              height="100%"
                              viewBox="394.4035339355469 471.010009765625 67.319091796875 31.285003662109375"
                            >
                              <path
                                fill="rgb(147, 196, 125)"
                                d="M 457.5763514315333 478.54561121259457 Q 457.58 478.55 456.39 478.76 455.21 478.97 452.06 480.08 448.91 481.19 443.07 483.34 437.24 485.48 432.07 487.49 426.9 489.5 422.14 491.6 417.39 493.7 413.8 495.72 410.21 497.74 407.67 498.88 405.13 500.02 401.58 501.42 398.02 502.82 396.01 501.98 393.99 501.13 394.51 499.01 395.03 496.89 397.21 497.07 399.39 497.25 399.55 499.43 399.71 501.61 397.58 502.11 395.46 502.61 394.63 500.59 393.8 498.57 397.1 497.05 400.4 495.54 403.8 494.48 407.21 493.42 411.28 491.28 415.35 489.15 420.18 487.01 425.02 484.87 430.05 482.74 435.08 480.61 440.85 477.61 446.63 474.6 450.59 473.03 454.56 471.47 456.05 471.24 457.55 471.01 457.77 471.01 457.99 471.01 458.57 471.1 459.15 471.19 459.68 471.46 460.2 471.73 460.62 472.14 461.04 472.56 461.31 473.08 461.58 473.6 461.67 474.18 461.77 474.77 461.68 475.35 461.59 475.93 461.32 476.46 461.05 476.98 460.64 477.4 460.22 477.82 459.7 478.09 459.18 478.36 458.6 478.45 458.01 478.55 457.8 478.55 Z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          className="sl-block"
                          data-block-type="drawing"
                          style={{
                            width: '37.9152px',
                            height: '52.6459px',
                            left: '227.845px',
                            top: '501.486px'
                          }}
                          data-name="drawing-3d4945"
                          data-origin-id="15ef675a0b7d1a41b6557a6cba6ad900"
                        >
                          <div
                            className="sl-block-content"
                            data-points="402 475.78,400 476.78,398 478.78,394 481.78,379 488.78,373 491.78,371 495.78,369 495.78,369 497.78,371 499.78,374 502.78,377 505.78,380 508.78,384 511.78,387 514.78,390 517.78,391 519.78,393 520.78,395 522.78,396 524.78"
                            data-timestamp={1693763162232}
                            style={{ 'z-index': '19' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              preserveAspectRatio="none"
                              width="100%"
                              height="100%"
                              viewBox="367.8048095703125 472.05999755859375 37.915191650390625 52.6458740234375"
                            >
                              <path
                                fill="rgb(147, 196, 125)"
                                d="M 403.25764065633865 479.36266540064145 Q 403.26 479.36 401.99 480.26 400.72 481.15 396.94 483.52 393.16 485.89 386.77 488.46 380.37 491.03 377.39 492.76 374.4 494.5 373.55 496.16 372.69 497.81 375.16 499.58 377.63 501.35 380.78 504.28 383.93 507.22 387.15 510.14 390.37 513.07 393.3 516.02 396.22 518.98 396.29 521.56 396.37 524.15 393.82 524.63 391.28 525.1 390.41 522.66 389.54 520.23 391.81 518.99 394.09 517.75 395.66 519.8 397.24 521.85 395.47 523.73 393.69 525.61 390.76 523.31 387.83 521.01 385.21 518.36 382.6 515.72 380.31 513.76 378.02 511.79 375.28 509.09 372.55 506.38 369.93 503.04 367.31 499.69 367.92 497.04 368.53 494.4 370.17 492.21 371.81 490.02 374.93 488.21 378.04 486.4 383.57 483.25 389.11 480.1 391.93 478.22 394.76 476.33 396.27 475.1 397.78 473.87 398.82 473.25 399.87 472.63 400.09 472.53 400.3 472.42 400.87 472.24 401.43 472.06 402.02 472.06 402.61 472.06 403.17 472.25 403.73 472.43 404.2 472.78 404.68 473.13 405.02 473.61 405.36 474.08 405.54 474.65 405.72 475.21 405.72 475.8 405.72 476.39 405.53 476.95 405.35 477.51 405 477.98 404.65 478.46 404.17 478.8 403.7 479.14 403.48 479.25 Z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '440px',
                left: '267.962px',
                top: '127.16px'
              }}
              data-name="text-6f1810"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '25',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    'z-index': '25',
                    color: 'rgb(0, 0, 255)',
                    'background-color': 'rgb(249, 203, 156)',
                    padding: '16px',
                    'border-style': 'solid',
                    'border-radius': '20px'
                  }}
                >
                  <p>
                    החולה <u>כן</u> מתאים להגדרה?
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '70px',
                height: '102px',
                left: '452.962px',
                top: '204.176px'
              }}
              data-name="shape-5e09b6"
            >
              <div
                className="sl-block-content"
                data-shape-type="arrow-down"
                data-shape-fill-color="rgb(255, 255, 255)"
                data-shape-stretch="true"
                style={{ 'z-index': '26' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 70 102"
                >
                  <polygon
                    points="35,102 70,51 49,51 49,0 21,0 21,51 0,51 35,102"
                    className="shape-element"
                    fill="rgb(255, 255, 255)"
                  />
                </svg>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '132px',
                left: '754.75px',
                top: '30.5px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  '-webkit-text-align': 'center',
                  'text-align': 'center',
                  'z-index': '10',
                  color: 'rgb(255, 255, 255)',
                  fontSize: '120%',
                  'line-height': '1.17'
                }}
                data-has-line-height
              >
                <p>שאלה</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '108.5px',
                left: '2.75px',
                top: '15.5px',
                height: 'auto'
              }}
              data-name="text-3f789d"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '12',
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    'z-index': '12',
                    '-webkit-text-align': 'left',
                    'text-align': 'left',
                    fontSize: '44%',
                    color: 'rgb(28, 235, 123)',
                    padding: '5px',
                    'background-color': 'rgba(0, 0, 0, 0)'
                  }}
                >
                  <pre># שאלה</pre>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="line"
              data-name="line-49cd6b"
              style={{
                width: 'auto',
                height: 'auto',
                left: '77.8293px',
                top: '28.5px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '11',
                  filter: 'drop-shadow(rgba(28, 235, 123, 0.5) 0px 0px 5px)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-line-x1={0}
                  data-line-y1={0}
                  data-line-x2="845.5"
                  data-line-y2={0}
                  data-line-color="#1ceb7b"
                  data-line-start-type="none"
                  data-line-end-type="none"
                  style={{ 'z-index': '11' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="xMidYMid"
                    width="845.5"
                    height={1}
                    viewBox="0 0 845.5 1"
                  >
                    <line
                      stroke="rgba(0,0,0,0)"
                      strokeWidth={15}
                      x1={0}
                      y1={0}
                      x2="845.5"
                      y2={0}
                    />
                    <line
                      className="line-element"
                      stroke="#1ceb7b"
                      strokeWidth={2}
                      x1={0}
                      y1={0}
                      x2="845.5"
                      y2={0}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: '50px'
              }}
            >
              <QuestionSlide
                key="palliativeCare-9"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                answersCustomSx={{
                  maxHeight: '110px'
                }}
                {...getQuestionData(globalCoursewares, selectedCourseware, 9)}
              />
            </div>
          </section>
          <section
            data-auto-animate
            data-auto-animate-duration="0.7"
            data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '299.181px',
                height: '310.495px',
                left: '-70.5603px',
                top: '229.947px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(-45deg)',
                  '-ms-transform': 'rotate(-45deg)',
                  transform: 'rotate(-45deg)',
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 12px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 299 310"
                  >
                    <rect
                      width="299.181"
                      height="310.495"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '887.72px',
                left: '72.28px',
                top: '64.5005px'
              }}
              data-name="text-590b25"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '12',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '150%'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  הטיפול בחולה המוגדר כמטופל פליאטיבי זהו מכלול של עבודה משותפת
                  בין מקצועית-
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '600px',
                left: '319.844px',
                top: '218.554px'
              }}
              data-name="text-f873af"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    רפואה.
                  </li>
                  <li
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    סיעוד.
                  </li>
                  <li
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    תזונה.
                  </li>
                  <li
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    פיזיותרפיה.
                  </li>
                  <li
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    ריפוי בעיסוק.
                  </li>
                  <li
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    עבודה סוציאלית.
                  </li>
                  <li
                    style={{
                      '-webkit-text-align': 'right',
                      'text-align': 'right'
                    }}
                  >
                    ליווי רוחני.
                    <br />
                    <br />
                    והכל סביב החולה, רווחתו ואיכות חייו.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '812.753px',
                left: '114.681px',
                top: '117.88px'
              }}
              data-name="text-cb33f6"
              data-auto-animate-delay="0.4"
              data-auto-animate-easing="cubic-bezier(0.230, 1.000, 0.320, 1.000)"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '15',
                  '-webkit-text-align': 'justfiy',
                  'text-align': 'justfiy',
                  fontSize: '120%'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  עקרונות הטיפול הפליאטיבי על פי ארגון הבריאות העולמי, כוללים
                  מתן טיפול להקלה מכאבים ומסימנים אחרים של המחלה, שילוב בין
                  המרכיבים הפיזיים, החברתיים, הנפשיים והרוחניים בטיפול בחולה,
                  מתן משמעות לחיים והתייחסות אל המוות כאל תהליך טבעי, מבלי לזרז
                  או להאריך את תהליך המיתה.
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  &nbsp;
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  כמו כן, בין העקרונות החשובים של הטיפול הפליאטיבי נכללים אספקת
                  מערכת תמיכה שנועדה להקל על בני המשפחה להתמודד עם הטפול בחולה
                  במהלך מחלתו, ולנחם אותם לאחר מותו.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '877.885px',
                height: '630.88px',
                left: '82.1148px',
                top: '80.0001px'
              }}
              // data-auto-animate-delay="0.4"
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '14' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 878 631"
                >
                  <rect
                    width="877.885"
                    height="630.88"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '877.885px',
                height: '630.88px',
                left: '62.1148px',
                top: '60.0001px'
              }}
              data-name="shape-22dffa"
              // data-auto-animate-delay="0.3"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '13', opacity: '0.8' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '13' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 878 631"
                  >
                    <rect
                      width="877.885"
                      height="630.88"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '877.885px',
                height: '630.88px',
                left: '42.1148px',
                top: '40.0001px'
              }}
              data-name="shape-c4268d"
              // data-auto-animate-delay="0.2"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '12', opacity: '0.6' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '12' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 878 631"
                  >
                    <rect
                      width="877.885"
                      height="630.88"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '877.885px',
                height: '630.88px',
                left: '22.1148px',
                top: '20.0001px'
              }}
              data-name="shape-b5dd8f"
              // data-auto-animate-delay="0.1"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '11', opacity: '0.4' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '11' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 878 631"
                  >
                    <rect
                      width="877.885"
                      height="630.88"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '877.885px',
                height: '630.88px',
                left: '2.1148px',
                top: '0px'
              }}
              data-name="shape-c54e0a"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '10', opacity: '0.2' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 878 631"
                  >
                    <rect
                      width="877.885"
                      height="630.88"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '378px',
                left: '404.498px',
                top: '554.754px'
              }}
              data-name="text-f47ec0"
              // data-auto-animate-easing="cubic-bezier(0.755, 0.050, 0.855, 0.060)"
              // data-auto-animate-duration="0.4"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '22', opacity: '0' }}
              >
                <div
                  className="sl-block-content slide-up-intro"
                  style={{
                    'z-index': '22',
                    color: 'rgb(255, 255, 255)',
                    padding: '28px',
                    border: '4px solid rgb(255, 255, 255)',
                    'background-color': 'rgba(0, 0, 0, 0)',
                    fontSize: '80%'
                  }}
                >
                  <h3>
                    <strong>SIGN UP FOR FREE</strong>
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate
            // data-auto-animate-duration="0.7"
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            data-background-image="https://images.unsplash.com/photo-1490818387583-1baba5e638af?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDEzfHxGT09EfGVufDB8fHx8MTY5Mzc3NTI5OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.18"
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '862.912px',
                left: '68.935px',
                top: '197.531px'
              }}
              data-name="text-cb33f6"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '11',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '160%'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  מטרתו של המזון המנחם בטיפול פליאטיבי היא לענות על צורך רגשי של
                  המטופל. האכילה המנחמת מיטיבה עם מצבו הנפשי של המטופל ויכולה
                  לענות על רצונות מיוחדים של המטופל.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '499.248px',
                height: '79.248px',
                left: '432.599px',
                top: '62.4075px'
              }}
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '10' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 499 79"
                >
                  <rect
                    width="499.248"
                    height="79.248"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '493.124px',
                left: '414.599px',
                top: '77.0315px'
              }}
              data-name="text-4c9c41"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '127%' }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <strong>אוכל מנחם בחולה פליאטיבי</strong>
                </p>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-image="https://images.unsplash.com/photo-1490818387583-1baba5e638af?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDEzfHxGT09EfGVufDB8fHx8MTY5Mzc3NTI5OXww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.18"
            // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '763.355px',
                left: '158.789px',
                top: '208.267px'
              }}
              data-name="text-cb33f6"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '11',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '115%'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  אין צורך לדאוג לכך שהאוכל המנחם יהיה מזין, או אותו אוכל אינו
                  בריא ולא ממלא את צרכיו התזונתיים של המטופל, אלא רצוי לחשוב על
                  גיוון, טעם וצבע, המעודדים הנאה ואכילה.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '499.248px',
                height: '79.248px',
                left: '422.896px',
                top: '65.9541px'
              }}
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '10' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 499 79"
                >
                  <rect
                    width="499.248"
                    height="79.248"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '493.124px',
                left: '404.896px',
                top: '80.5781px'
              }}
              data-name="text-4c9c41"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '127%' }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <strong>אוכל מנחם בחולה פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '763.355px',
                left: '158.789px',
                top: '424.063px'
              }}
              data-name="text-0321e0"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '13',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '118%'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  צריך לתת למטופל שהוגדר כמטופל אשר זקוק לטיפול פליאטיבי מה שהוא
                  אוהב ומוכן לאכול ללא סבל.
                </p>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            // data-auto-animate
            data-background-opacity="0.45"
            data-background-image="https://images.unsplash.com/photo-1587556930720-58ec521056a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE3fHxudXJzZXxlbnwwfHx8fDE2OTM3NzYyMzd8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-34.66px',
                top: '-278.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '548.74px',
                left: '205.63px',
                top: '49.8123px'
              }}
              data-name="text-1e9eba"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '11', fontSize: '204%' }}
              >
                <p>
                  <strong>פיזיותרפיה עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '929px',
                left: '15.5px',
                top: '275.688px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '160%' }}
              >
                <p>
                  תוכנית מותאמת אישית לפי המצב הפיזי והתפקודי של המטופל, התאמת
                  ציוד לפי הצורך, דגש על שימור תפקודי ומניעת התדרדרות.
                </p>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-image="https://images.unsplash.com/photo-1587556930720-58ec521056a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE3fHxudXJzZXxlbnwwfHx8fDE2OTM3NzYyMzd8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.45"
            // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-20.66px',
                top: '-278.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '529.335px',
                left: '215.333px',
                top: '37.3438px'
              }}
              data-name="text-1e9eba"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '11', fontSize: '204%' }}
              >
                <p>
                  <strong>ריפוי בעיסוק עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '884.798px',
                left: '37.6015px',
                top: '220.688px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '160%' }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  תוכנית מותאמת אישית לפי המצב הקוגניטיבי הפיזי והתפקודי של
                  המטופל, התאמת ציוד לפי הצורך, דגש על שימור תפקודי ומניעת
                  התדרדרות, שימוש באביזרים לגירוי חושים, ריחות, מוזיקה,
                  ארומתרפיה ועוד, הכל תלוי במצב המטופל.
                </p>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-image="https://images.unsplash.com/photo-1587556930720-58ec521056a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE3fHxudXJzZXxlbnwwfHx8fDE2OTM3NzYyMzd8MA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
            data-background-opacity="0.45"
            // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-20.66px',
                top: '-278.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '616.66px',
                left: '185.67px',
                top: '41.6562px'
              }}
              data-name="text-1e9eba"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '11', fontSize: '204%' }}
              >
                <p>
                  <strong>עבודה סוציאלית עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '929px',
                left: '29.5px',
                top: '236.5px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '160%' }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  תפקיד עיקרי בליווי אישי של החולה ומשפחתו, סגירת מעגלים, העדפות
                  סוף חיים.
                </p>
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  ליווי לאורך כל הדרך עד לאחר המוות.
                </p>
              </div>
            </div>
          </section>
          <section
            data-background-opacity="0.3"
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-34.6595px',
                top: '-293.487px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0,0,0,0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0,0,0,0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '432.307px',
                left: '263.847px',
                top: '65.5302px'
              }}
              data-name="text-1e9eba"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '11', 'font-size': '200%' }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'center',
                    textAlign: 'center'
                  }}
                >
                  <strong>תפקיד האחות עם דייר פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f12547"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '480.001px 402.602px 0px',
                  '-ms-transform-origin': '480.001px 402.602px 0px',
                  'transform-origin': '480.001px 402.602px 0px',
                  'z-index': '12'
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '234.085px',
                    left: '492.365px',
                    top: '386.765px'
                  }}
                  data-name="text-af1425"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '13',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '13',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(249, 203, 156)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        Doing For
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '234.085px',
                    left: '492.365px',
                    top: '481.765px'
                  }}
                  data-name="text-a78798"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '14',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '14',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(249, 203, 156)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        Connecting
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '234px',
                    left: '233.552px',
                    top: '481.765px'
                  }}
                  data-name="text-87aba1"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '15',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '15',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(249, 203, 156)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        Valuing
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '234px',
                    left: '233.552px',
                    top: '386.765px'
                  }}
                  data-name="text-50eab7"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '16',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '16',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(249, 203, 156)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        Empowering
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: '120px',
                    width: '234px',
                    left: '233.551px',
                    top: '252.438px'
                  }}
                  data-name="text-97e1d6"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '17',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '17',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(249, 203, 156)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px',
                        textAlign: 'center'
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        Finding <br />
                        Meaning
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: '120px',
                    width: '234px',
                    left: '492.449px',
                    top: '252.438px'
                  }}
                  data-name="text-c0339a"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '18',
                      filter:
                        'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '18',
                        color: 'rgb(0, 0, 255)',
                        'background-color': 'rgb(249, 203, 156)',
                        padding: '16px',
                        'border-style': 'solid',
                        'border-radius': '20px'
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        Preserving Integrity
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.3"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-16.66px',
                top: '-275.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '718px',
                left: '139px',
                top: '37.5px'
              }}
              data-name="text-1e9eba"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '11', fontSize: '204%' }}
              >
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div> */}
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '402.393px',
                left: '493.081px',
                top: '47.1247px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '865.803px',
                left: '47.0985px',
                top: '107.749px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '140%' }}
              >
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <u>
                      <span style={{ color: '#f0f8ff' }}>
                        Preserving Integrity
                      </span>
                    </u>
                    <br />
                    <span style={{ fontSize: '0.9em' }}>
                      מרכיב השמירה על היושרה והשלמות הוא מרכיב ליבה. מרכיב זה
                      מתייחס ליושרה של המטפל, המטופל ובני המשפחה. עבור המטפל,
                      האחות, מרכיב זה כולל את היכולת לשמור על הערכה עצמית גבוהה
                      כדי להמשיך ולתת טיפול איכותי.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.29"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-13.66px',
                top: '-282.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '402.393px',
                left: '493.081px',
                top: '47.1247px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '838.851px',
                left: '54.623px',
                top: '107.344px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '140%' }}
              >
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <u>Finding Meaning</u>
                    <br />
                    <span style={{ fontSize: '0.7em' }}>
                      מרכיב זה דורש הכוונה לחולה ומשפחתו למצוא משמעות בחיים,
                      ולעשות את המיטב במצב שבו הם נמצאים, להקטין את מרכיב האי
                      ודאות בסיטואציה כדי לקדם ולשמר את נושא התקווה, לשוחח על
                      המוות ולדבר על הצרכים הרוחניים, כגון: אמונות, תפילות
                      והייעוד שלו כאדם. לכל אדם יש סיפור אישי שיש להעצים באמצעות
                      שימוש במיומנויות הקשבה גבוהות. יש לציין שהתחום של הטיפול
                      הרוחני מתפתח ומהווה תחום עשייה חשוב בטיפול תומך.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.3"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-13.66px',
                top: '-282.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '397.003px',
                left: '522.907px',
                top: '138.465px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '879.819px',
                left: '40.0905px',
                top: '191px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '140%' }}
              >
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <p>
                      <u>Doing for</u>
                      <br />
                      <span style={{ fontSize: '0.9em' }}>
                        מרכיב זה עוסק באיזון כאב ותסמינים אחרים שעלולים לפגוע
                        באיכות חייו של המטופל, תיאום הטיפול בין הגורמים השונים
                        הן בקהילה והן במערכות האשפוז, ולבסוף וידוא שהחולה
                        ומשפחתו יקבלו את הזכויות שלהם – תפקיד הסניגור. ליישום
                        מרכיב זה נדרש:
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.3"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-11.66px',
                top: '-282.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '404.549px',
                left: '525.359px',
                top: '105.044px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '841px',
                left: '82.5px',
                top: '160.5px'
              }}
              data-name="text-ba9a9a"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '12',
                  fontSize: '110%',
                  'line-height': '1.43'
                }}
                data-has-line-height
              >
                <ul>
                  <li>
                    <u>Doing for - (המשך)</u>
                    <ul>
                      <li
                        className="fade-in-then-semi-out fragment"
                        data-fragment-index={0}
                      >
                        שימוש בכלי אומדן מתוקפים שיאפשרו אומדן מקיף לכל
                        התסמינים.
                      </li>
                      <li
                        className="fade-in-then-semi-out fragment"
                        data-fragment-index={1}
                      >
                        ניהול הטיפול בכאב על פי סטנדרטים והנחיות ארגון הבריאות
                        העולמי לאיזון כאב.
                      </li>
                      <li
                        className="fade-in-then-semi-out fragment"
                        data-fragment-index={2}
                        style={{
                          '-webkit-text-align': 'justify',
                          'text-align': 'justify'
                        }}
                      >
                        גיבוש ובנייה של תוכנית טיפול בין-מקצועית, שבה ישתתפו כל
                        אנשי הצוות ביישום התוכנית תוך כדי שימור עיקרון
                        האוטונומיה של המטופל ושיתוף בני המשפחה.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.3"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-13.66px',
                top: '-282.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '401.315px',
                left: '520.751px',
                top: '122.294px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '884.131px',
                left: '37.9345px',
                top: '184.5px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '110%' }}
              >
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <p>
                      <u>Empowering</u>
                      <br />
                      <span style={{ fontSize: '0.9em' }}>
                        המרכיב של העצמה כרוך בעזרה לאדם ולמשפחה לחשוף ולבנות את
                        נקודות החוזק שלהם. המרכיבים של ההעצמה כוללים אומדן
                        המשפחה, אומדן מערכות התמיכה של המטופל, הכרת סגנון
                        ההתמודדות של משפחה, סיוע בקבלת ההחלטות ותמיכה בהחלטות
                        שנעשו על ידי המשפחה, סיוע למשפחה להתמודד עם דילמות
                        אתיות, לאפשר למשפחה לפרוק תסכולים, לסייע בריפוי ובשיפור
                        מערכות היחסים בתוך המשפחה, ולבסוף לתת מידע על האספקטים
                        השונים של הטיפול.
                      </span>
                      <br type="_moz" />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.3"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-13.66px',
                top: '-282.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '466px',
                left: '494px',
                top: '38.2028px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '839.929px',
                left: '88.9009px',
                top: '124.108px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '120%' }}
              >
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <p>
                      <u>Connecting</u>
                    </p>
                    <p>
                      מדובר בתהליך חשוב של בניית תהליך אימון בין המטפל לחולה
                      ומשפחתו. התהליך מתחלק לשלושה שלבים שלא ניתן להפרידם:
                    </p>
                    <p>
                      <span style={{ color: '#ffd700' }}>
                        <strong>יצירת הקשר.</strong>
                      </span>
                    </p>
                    <p>
                      <span style={{ color: '#ffd700' }}>
                        <strong>הידוק הקשר.</strong>
                      </span>
                    </p>
                    <p>
                      <span style={{ color: '#ffd700' }}>
                        <strong>ניתוק הקשר לאחר הפטירה.</strong>
                      </span>{' '}
                      (בחלק מהמקומות נעשה תהליך של מעקב אבל).
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.3"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-24.441px',
                top: '-273.535px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '466px',
                left: '502.719px',
                top: '36.0466px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '931.567px',
                left: '0px',
                top: '126.5px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '12',
                  fontSize: '120%',
                  'line-height': '1.56'
                }}
                data-has-line-height
              >
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <p>
                      <u>Connecting (המשך)</u>
                    </p>
                    <p>
                      שלב ההתקשרות כולל:
                      <br />
                      * זיהוי עמדות המטופל: תחושותיו וערכיו כלפי נושא המוות.
                      <br />
                      * יצירת אווירה המקדמת אמון, ושימוש במיומנויות תקשורת
                      רגישות.
                      <br />
                      * הקשבה פעילה, ככלי תקשורת חשוב להבנת ולזיהוי צורכי
                      המטופל.
                      <br />* שימוש בתקשורת מילולית ולא מילולית, הקשבה ונוכחות.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
            // data-auto-animate-duration="0.7"
            data-background-opacity="0.3"
            // data-auto-animate
            data-background-image="https://images.unsplash.com/photo-1584432810601-6c7f27d2362b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDF8fG51cnNlfGVufDB8fHx8MTY5Mzc3NjIzN3ww&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
          >
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '1029.32px',
                height: '1029.32px',
                left: '-16.8943px',
                top: '-282.16px'
              }}
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  '-webkit-transform': 'rotate(45deg)',
                  '-ms-transform': 'rotate(45deg)',
                  transform: 'rotate(45deg)',
                  opacity: '0.77'
                }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1029 1029"
                  >
                    <rect
                      width="1029.32"
                      height="1029.32"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '466px',
                left: '513.5px',
                top: '28.5px'
              }}
              data-name="text-1e9eba"
            >
              <div className="sl-block-content" style={{ 'z-index': '11' }}>
                <p>
                  <strong>תפקיד האחות עם מטופל פליאטיבי</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '907.849px',
                left: '33.9181px',
                top: '117px'
              }}
              data-name="text-46c7a1"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '12', fontSize: '120%' }}
              >
                <ul>
                  <li
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <p>
                      <u>Valuing</u>
                    </p>
                    <p>
                      ממד שמספק את הקשר לטיפול התומך, מכיל בתוכו את כבוד האדם
                      כזכות בסיסית אנושית.
                      <br />
                      בזכות הזו נדרשת מהמטפלים ההכרה בייחודיות האדם על יכולותיו,
                      אמונותיו, ערכיו ועל מרכיביו האישיותיים. יש לציין שValuing-
                      היא בעיקר גישה ולא פעולה.
                      <br />
                      הטיפול ייעשה בצורה רגישה, מכבדת ובמיקוד להשגת איכות חיים
                      אופטימלית של המטופל תוך התחשבות ברצונותיו של המטופל לגבי
                      מקום המוות.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '768.44px',
                left: '95.78px',
                top: '256.5px',
                height: 'auto'
              }}
              data-name="text-65278a"
              // data-auto-animate-easing="cubic-bezier(0.230, 1.000, 0.320, 1.000)"
              // data-auto-animate-delay="0.4"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '17',
                  '-webkit-transform': 'rotate(0deg)',
                  '-ms-transform': 'rotate(0deg)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  className="sl-block-content fragment fade-out"
                  style={{
                    '-webkit-text-align': 'left',
                    'text-align': 'left',
                    'z-index': '17'
                  }}
                  data-fragment-index={0}
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    * ניהול הטיפול על ידי אומדן מקיף של התסמינים לפי מערכות:
                    <br />
                    <strong>לב וכלי דם </strong>- קשיי נשימה, שיעול, בצקת וכו'.
                    <br />
                    <strong>מערכת העיכול </strong>- בחילה והקאה, מוקוסיטיס,
                    שלשולים, עצירות וכו'.
                  </p>
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <strong>תסמינים קוגניטיביים </strong>- דיליריום, בלבול,
                    דיכאון, הלוצינציות וכו'.
                    <br />
                    <strong>תסמינים פסיכולוגיים </strong>- חרדה, פחד, כעס, אי
                    ודאות ואבל.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-76ca2d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '854.678px 147.526px 0px',
                  '-ms-transform-origin': '854.678px 147.526px 0px',
                  'transform-origin': '854.678px 147.526px 0px',
                  'z-index': '10'
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-de890c"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      '-webkit-transform-origin': '839.162px 142.007px 0px',
                      '-ms-transform-origin': '839.162px 142.007px 0px',
                      'transform-origin': '839.162px 142.007px 0px',
                      'z-index': '15'
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      data-name="ai-gradient-block"
                      style={{
                        width: '140.072px',
                        height: '140.191px',
                        left: '769.126px',
                        top: '71.9118px'
                      }}
                    >
                      <div
                        className="sl-block-content ai-gradient-svg"
                        data-shape-type="rect"
                        data-shape-fill-color="rgba(0, 0, 0, 0)"
                        data-shape-stretch="true"
                        style={{ 'z-index': '16' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 140 140"
                        >
                          <rect
                            width="140.072"
                            height="140.191"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgba(0, 0, 0, 0)"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '132.102px',
                    height: '131.975px',
                    left: '792.498px',
                    top: '81.5385px'
                  }}
                  data-name="shape-c54e0a"
                  // data-auto-animate-duration="1.1"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '11',
                      opacity: '0.2',
                      '-webkit-transform': 'rotate(16deg)',
                      '-ms-transform': 'rotate(16deg)',
                      transform: 'rotate(16deg)'
                    }}
                  >
                    <div
                      className="sl-block-content ai-gradient-svg"
                      data-shape-type="rect"
                      data-shape-fill-color="rgba(0, 0, 0, 0)"
                      data-shape-stretch="true"
                      style={{ 'z-index': '11' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 132 132"
                      >
                        <rect
                          width="132.102"
                          height="131.975"
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgba(0, 0, 0, 0)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '132.096px',
                    height: '129.565px',
                    left: '789.108px',
                    top: '80.4865px'
                  }}
                  data-name="shape-b5dd8f"
                  // data-auto-animate-duration={1}
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '12',
                      opacity: '0.4',
                      '-webkit-transform': 'rotate(12deg)',
                      '-ms-transform': 'rotate(12deg)',
                      transform: 'rotate(12deg)'
                    }}
                  >
                    <div
                      className="sl-block-content ai-gradient-svg"
                      data-shape-type="rect"
                      data-shape-fill-color="rgba(0, 0, 0, 0)"
                      data-shape-stretch="true"
                      style={{ 'z-index': '12' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 132 130"
                      >
                        <rect
                          width="132.096"
                          height="129.565"
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgba(0, 0, 0, 0)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '132.092px',
                    height: '129.569px',
                    left: '785.738px',
                    top: '78.2207px'
                  }}
                  data-name="shape-c4268d"
                  // data-auto-animate-duration="0.9"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '13',
                      opacity: '0.6',
                      '-webkit-transform': 'rotate(8deg)',
                      '-ms-transform': 'rotate(8deg)',
                      transform: 'rotate(8deg)'
                    }}
                  >
                    <div
                      className="sl-block-content ai-gradient-svg"
                      data-shape-type="rect"
                      data-shape-fill-color="rgba(0, 0, 0, 0)"
                      data-shape-stretch="true"
                      style={{ 'z-index': '13' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 132 130"
                      >
                        <rect
                          width="132.092"
                          height="129.569"
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgba(0, 0, 0, 0)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '132.086px',
                    height: '129.574px',
                    left: '782.367px',
                    top: '75.9506px'
                  }}
                  data-name="shape-22dffa"
                  // data-auto-animate-duration="0.8"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '14',
                      opacity: '0.8',
                      '-webkit-transform': 'rotate(4deg)',
                      '-ms-transform': 'rotate(4deg)',
                      transform: 'rotate(4deg)'
                    }}
                  >
                    <div
                      className="sl-block-content ai-gradient-svg"
                      data-shape-type="rect"
                      data-shape-fill-color="rgba(0, 0, 0, 0)"
                      data-shape-stretch="true"
                      style={{ 'z-index': '14' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        viewBox="0 0 132 130"
                      >
                        <rect
                          width="132.086"
                          height="129.574"
                          rx={0}
                          ry={0}
                          className="shape-element"
                          fill="rgba(0, 0, 0, 0)"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '131.543px',
                left: '769.126px',
                top: '109.026px'
              }}
              data-name="text-1401c0"
            >
              <div
                className="sl-block-content"
                style={{ 'z-index': '18', fontSize: '120%' }}
              >
                <p>
                  <span style={{ fontSize: '1.2em' }}>
                    <strong>טיפול</strong>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '723.413px',
                left: '45.713px',
                top: '123.505px'
              }}
              data-origin-id="f5bbbcf6fa504e7c318e0ef1e24c0c8e"
              data-name="text-ff97ee"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '19',
                  '-webkit-transform': 'rotate(0deg)',
                  '-ms-transform': 'rotate(0deg)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div className="sl-block-content" style={{ 'z-index': '19' }}>
                  <p>
                    בתסמינים ובתופעות לוואי עם דגש על בעיות קיימות או צפויות.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '750.112px',
                left: '114.108px',
                top: '256.5px',
                height: 'auto'
              }}
              // data-auto-animate-easing="cubic-bezier(0.230, 1.000, 0.320, 1.000)"
              // data-auto-animate-delay="0.4"
              data-name="text-daccf2"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '20',
                  '-webkit-transform': 'rotate(0deg)',
                  '-ms-transform': 'rotate(0deg)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  className="sl-block-content fragment current-visible"
                  style={{
                    '-webkit-text-align': 'left',
                    'text-align': 'left',
                    'z-index': '20'
                  }}
                  data-fragment-index={0}
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    * <strong>תיאום הטיפול בין הגורמים </strong>הוא אתגר מקצועי
                    להובלה ולמנהיגות עבור האחות המומחית בטיפול התומך.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '768.44px',
                left: '95.78px',
                top: '256.5px',
                height: 'auto'
              }}
              // data-auto-animate-easing="cubic-bezier(0.230, 1.000, 0.320, 1.000)"
              // data-auto-animate-delay="0.4"
              data-name="text-3621f4"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '21',
                  '-webkit-transform': 'rotate(0deg)',
                  '-ms-transform': 'rotate(0deg)',
                  transform: 'rotate(0deg)'
                }}
              >
                <div
                  className="sl-block-content fragment fade-up"
                  style={{
                    '-webkit-text-align': 'left',
                    'text-align': 'left',
                    'z-index': '21'
                  }}
                  data-fragment-index={1}
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    * <strong>Advocating </strong>:הדרכת המטפלים האחרים לשיפור
                    השירות, זמינות משאבים ועזרה בהשגת אבזרים נדרשים, הדרכת
                    המטופל להשגת איכות חיים בהתאם למצבו.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          // data-auto-animate-duration="0.7"
          // data-auto-animate
          >
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-75fd67"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '480.001px 76.251px 0px',
                  '-ms-transform-origin': '480.001px 76.251px 0px',
                  'transform-origin': '480.001px 76.251px 0px',
                  'z-index': '10'
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-f7a921"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      '-webkit-transform-origin': '582.915px 64.9849px 0px',
                      '-ms-transform-origin': '582.915px 64.9849px 0px',
                      'transform-origin': '582.915px 64.9849px 0px',
                      'z-index': '11'
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      data-name="ai-gradient-block"
                      style={{
                        width: '729.959px',
                        height: '78.248px',
                        left: '115.021px',
                        top: '37.127px'
                      }}
                    >
                      <div
                        className="sl-block-content ai-gradient-svg"
                        data-shape-type="rect"
                        data-shape-fill-color="rgba(0, 0, 0, 0)"
                        data-shape-stretch="true"
                        style={{ 'z-index': '12' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 730 78"
                        >
                          <rect
                            width="729.959"
                            height="78.248"
                            rx={0}
                            ry={0}
                            className="shape-element"
                            fill="rgba(0, 0, 0, 0)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        height: 'auto',
                        width: '691.148px',
                        left: '134.427px',
                        top: '51.251px'
                      }}
                      data-name="text-c17bd6"
                    >
                      <div
                        className="sl-block-content"
                        style={{
                          'z-index': '13',
                          '-webkit-text-align': 'left',
                          'text-align': 'left',
                          fontSize: '128%',
                          'letter-spacing': '0.11em'
                        }}
                        data-has-letter-spacing
                      >
                        <p
                          style={{
                            '-webkit-text-align': 'right',
                            'text-align': 'right'
                          }}
                        >
                          היבטים נוספים שיש להתייחס אליהם:
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '233.451px',
                left: '403.852px',
                top: '199.625px'
              }}
              data-name="text-32d804"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '17',
                  filter:
                    'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment current-visible"
                  style={{
                    'z-index': '17',
                    'background-color': 'rgb(207, 226, 243)',
                    color: 'rgb(7, 47, 84)',
                    padding: '20px',
                    'border-style': 'solid',
                    'border-radius': '8px',
                    fontSize: '93%',
                    'line-height': '1.326'
                  }}
                  data-fragment-index={0}
                  data-has-line-height
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    תמיכה בהחלטת החולה ומשפחתו על הפסקה או המשך הטיפול, כמו כן
                    עזרה למשפחה בפתירת סוגיות אתיות.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '233.451px',
                left: '303.583px',
                top: '200px'
              }}
              data-name="text-9f26e3"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '18',
                  filter:
                    'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment current-visible"
                  style={{
                    'z-index': '18',
                    'background-color': 'rgb(207, 226, 243)',
                    color: 'rgb(7, 47, 84)',
                    padding: '20px',
                    'border-style': 'solid',
                    'border-radius': '8px',
                    fontSize: '96%'
                  }}
                  data-fragment-index={1}
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    מתן מידע רלוונטי על התקדמות המחלה, אופציות טיפוליות, זמינות
                    משאבים, אומדן וטיפול בתסמינים, יעילות הטיפול.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '683.993px',
                left: '132.373px',
                top: '244.5px'
              }}
              data-name="text-bee46e"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '22',
                  filter:
                    'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content fragment current-visible"
                  style={{
                    'z-index': '22',
                    'background-color': 'rgb(207, 226, 243)',
                    color: 'rgb(7, 47, 84)',
                    padding: '20px',
                    'border-style': 'solid',
                    'border-radius': '8px',
                    'line-height': '1.456'
                  }}
                  data-has-line-height
                  data-fragment-index={3}
                >
                  <p>
                    הדרכה לגבי גסיסה ומוות: זיהוי סימנים ותסמינים הקשורים למוות
                    המתקרב, לספק נוחות בשעות האחרונות.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-7c793d"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-out"
                style={{
                  '-webkit-transform-origin': '699.641px 332px 0px',
                  '-ms-transform-origin': '699.641px 332px 0px',
                  'transform-origin': '699.641px 332px 0px',
                  'z-index': '14'
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '233.451px',
                    left: '582.915px',
                    top: '156.375px'
                  }}
                  data-name="text-bdc102"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '15',
                      filter:
                        'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '15',
                        'background-color': 'rgb(207, 226, 243)',
                        color: 'rgb(7, 47, 84)',
                        padding: '20px',
                        'border-style': 'solid',
                        'border-radius': '8px',
                        'line-height': '1.885'
                      }}
                      data-has-line-height
                    >
                      <p>תמיכה לשיפור הדימוי העצמי.</p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '233.451px',
                    left: '582.915px',
                    top: '350.625px'
                  }}
                  data-name="text-325547"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '16',
                      filter:
                        'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '16',
                        'background-color': 'rgb(207, 226, 243)',
                        color: 'rgb(7, 47, 84)',
                        padding: '20px',
                        'border-style': 'solid',
                        'border-radius': '8px'
                      }}
                    >
                      <p>אומדן צרכים אישיים ואינטימיים.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-9fec37"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{
                  '-webkit-transform-origin': '249.243px 332px 0px',
                  '-ms-transform-origin': '249.243px 332px 0px',
                  'transform-origin': '249.243px 332px 0px',
                  'z-index': '19'
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '234.529px',
                    left: '131.583px',
                    top: '156.375px'
                  }}
                  data-name="text-c61cbc"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '20',
                      filter:
                        'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '20',
                        'background-color': 'rgb(207, 226, 243)',
                        color: 'rgb(7, 47, 84)',
                        padding: '20px',
                        'border-style': 'solid',
                        'border-radius': '8px'
                      }}
                    >
                      <p>אבל ואובדן: תגובות שכיחות ודרכי התמודדות.</p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '234.529px',
                    left: '132.373px',
                    top: '350.625px'
                  }}
                  data-name="text-26501d"
                >
                  <div
                    className="sl-block-style"
                    style={{
                      'z-index': '21',
                      filter:
                        'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                    }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        'z-index': '21',
                        'background-color': 'rgb(207, 226, 243)',
                        color: 'rgb(7, 47, 84)',
                        padding: '20px',
                        'border-style': 'solid',
                        'border-radius': '8px'
                      }}
                    >
                      <p>זיהוי מצבים דחופים בטיפול תומך.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          // data-auto-animate-duration="0.7"
          // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '917.548px',
                left: '21.226px',
                top: '100.5px'
              }}
              data-name="text-bee46e"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '31',
                  filter:
                    'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    'z-index': '31',
                    'background-color': 'rgb(207, 226, 243)',
                    color: 'rgb(7, 47, 84)',
                    padding: '20px',
                    'border-style': 'solid',
                    'border-radius': '8px',
                    'line-height': '1.43'
                  }}
                  data-has-line-height
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <span style={{ fontSize: '1.0em' }}>
                      מדובר בתהליך חשוב של בניית תהליך אימון בין המטפל למטופל
                      ומשפחתו.
                      <br />
                      התהליך מתחלק לשלושה שלבים שלא ניתן להפרידם: יצירת הקשר,
                      הידוק הקשר וניתוק הקשר לאחר הפטירה (בחלק מהמקומות נעשה
                      תהליך של מעקב אבל).
                    </span>
                  </p>
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    <br />
                    <span style={{ fontSize: '1.0em' }}>
                      שלב ההתקשרות כולל:
                      <br />
                      * זיהוי עמדות המטופל: תחושותיו וערכיו כלפי נושא המוות.
                      <br />
                      * יצירת אווירה המקדמת אמון, ושימוש במיומנויות תקשורת
                      רגישות.
                      <br />
                      * הקשבה פעילה, ככלי תקשורת חשוב להבנת ולזיהוי צורכי
                      המטופל.
                      <br />
                      * שימוש בתקשורת מילולית ולא מילולית, הקשבה ונוכחות.
                      <br />* זיהוי ותגובה למצוקות של המטופל ושל משפחתו.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          // data-auto-animate-duration="0.7"
          // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '946.249px',
                left: '6.8755px',
                top: '125.5px'
              }}
              data-name="text-bee46e"
            >
              <div
                className="sl-block-style"
                style={{
                  'z-index': '10',
                  filter:
                    'drop-shadow(rgba(222, 222, 222, 0.19) 4px 4px 14px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    'z-index': '10',
                    'background-color': 'rgb(207, 226, 243)',
                    color: 'rgb(7, 47, 84)',
                    padding: '20px',
                    'border-style': 'solid',
                    'border-radius': '8px',
                    'line-height': '1.43'
                  }}
                  data-has-line-height
                >
                  <p
                    style={{
                      '-webkit-text-align': 'justify',
                      'text-align': 'justify'
                    }}
                  >
                    * הצגת הגישה הפליאטיבית, כולל עבודת צוות עם דגש על תפקיד
                    האחות בצוות.
                    <br />
                    * לאמוד השפעת המרכיבים התרבותיים, הרוחניים, הפסיכולוגיים של
                    המטופל ובני משפחתו לגבי תפיסת החולי וחוויית החולי.
                    <br />
                    * מתן לגיטימציה להבעת פחדים וחרדות לנוכח אי הוודאות שבה נמצא
                    המטופל.
                    <br />
                    * שמירת פרטיות המטופל: להיות איש סודו של המטופל אם הוא מבקש
                    זאת.
                    <br />
                    * גישור על הפערים בדרישות שבין המטופל ובני משפחתו, ועזרה
                    בבניית סדר עדיפויות כלפי הצרכים והדרישות שעולים מניהול
                    המקרה.
                    <br />
                    * הערכה מתמשכת של תוכנית הטיפול ובדיקת הצורך בשינוי.
                    <br />* הכנת המטופל ומשפחתו לתהליך הגסיסה והמוות.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section
          // data-auto-animate-easing="cubic-bezier(0.860, 0.000, 0.070, 1.000)"
          // data-auto-animate-duration="0.7"
          // data-auto-animate
          >
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '618.376px',
                height: '619.469px',
                left: '341.624px',
                top: '50.2655px'
              }}
              data-name="shape-c54e0a"
              // data-auto-animate-duration="1.1"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '10', opacity: '0.2' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 618 619"
                  >
                    <rect
                      width="618.376"
                      height="619.469"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '572.621px',
                height: '573.633px',
                left: '364.502px',
                top: '73.1835px'
              }}
              data-name="shape-b5dd8f"
              // data-auto-animate-duration={1}
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '11', opacity: '0.4' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '11' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 573 574"
                  >
                    <rect
                      width="572.621"
                      height="573.633"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '524.737px',
                height: '525.665px',
                left: '388.444px',
                top: '97.1675px'
              }}
              data-name="shape-c4268d"
              // data-auto-animate-duration="0.9"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '12', opacity: '0.6' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '12' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 525 526"
                  >
                    <rect
                      width="524.737"
                      height="525.665"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '477.918px',
                height: '478.763px',
                left: '411.853px',
                top: '120.619px'
              }}
              data-name="shape-22dffa"
              // data-auto-animate-duration="0.8"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '13', opacity: '0.8' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '13' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 478 479"
                  >
                    <rect
                      width="477.918"
                      height="478.763"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '434.291px',
                height: '435.059px',
                left: '433.667px',
                top: '142.47px'
              }}
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '14' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 434 435"
                >
                  <rect
                    width="434.291"
                    height="435.059"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '771.547px',
                left: '94.2265px',
                top: '200.501px'
              }}
              data-name="text-53485c1"
              // data-auto-animate-delay="0.1"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '16',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  fontSize: '120%',
                  'line-height': '1.846'
                }}
                data-has-line-height
              >
                <p
                  style={{
                    '-webkit-text-align': 'justify',
                    'text-align': 'justify'
                  }}
                >
                  <strong>
                    <span style={{ fontSize: '1.2em' }}>
                      אנו מקווים שלומדה זו עזרה להבין ולהמחיש את הנושא של טיפול
                      פליאטיבי ותעזור לכם בהתמודדות היומיומית מול האוכלוסייה
                      הסובבת, ואימוץ הגישה ביעילות.
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '582px',
                left: '52px',
                top: '145.5px',
                height: 'auto'
              }}
              data-name="text-260be3"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '13',
                  fontSize: '110%',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  'line-height': '1.17'
                }}
                data-has-line-height
              >
                <h1
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  <strong>תודה רבה!</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '489.846px',
                left: '144.165px',
                top: '293.5px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  'z-index': '14',
                  '-webkit-text-align': 'left',
                  'text-align': 'left',
                  padding: '8px',
                  fontSize: '120%'
                }}
              >
                <p
                  style={{
                    '-webkit-text-align': 'right',
                    'text-align': 'right'
                  }}
                >
                  לחתימה על ביצוע הלומדה לחץ למטה
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-661a01"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '818.446px 169.185px 0px',
                  '-ms-transform-origin': '818.446px 169.185px 0px',
                  'transform-origin': '818.446px 169.185px 0px',
                  'z-index': '15',
                  '-webkit-transition-duration': '0.6s',
                  'transition-duration': '0.6s',
                  '-webkit-transition-delay': '0.5s',
                  'transition-delay': '0.5s'
                }}
                data-animation-type="slide-left"
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '254px',
                    height: '312.637px',
                    left: '691.446px',
                    top: '12.8665px'
                  }}
                  data-name="shape-98daa6"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ 'z-index': '16' }}
                    href="mailto:email@example.com"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 254 313"
                    >
                      <rect
                        width={254}
                        height="312.637"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '94px',
                    height: '127.119px',
                    left: '771.445px',
                    top: '102.122px'
                  }}
                  data-name="shape-671cfc"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="symbol-heart-fill"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="false"
                    style={{ 'z-index': '17' }}
                    href="mailto:email@example.com"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 1.999999761581421 32 28"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        data-viewbox="0 1.999999761581421 32 28"
                      >
                        <path d="M16 5.844c-1.613-2.266-4.129-3.844-7.113-3.844-4.903 0-8.887 3.992-8.887 8.891v0.734l16.008 18.375 15.992-18.375v-0.734c0-4.899-3.984-8.891-8.887-8.891-2.984 0-5.5 1.578-7.113 3.844z" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cc7324"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  '-webkit-transform-origin': '69px 634.43px 0px',
                  '-ms-transform-origin': '69px 634.43px 0px',
                  'transform-origin': '69px 634.43px 0px',
                  'z-index': '18',
                  '-webkit-transition-duration': '0.6s',
                  'transition-duration': '0.6s',
                  '-webkit-transition-delay': '0.4s',
                  'transition-delay': '0.4s'
                }}
                data-animation-type="slide-left"
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '94px',
                    height: '89.8594px',
                    left: '22px',
                    top: '589.5px'
                  }}
                  data-name="shape-e46ddb"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="symbol-sun"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="false"
                    style={{ 'z-index': '19' }}
                    href="https://twitter.com/slides"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16.001 8c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.418 0 7.999-3.582 7.999-8s-3.581-8-7.999-8v0zM14 2c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM4 6c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM2 14c1.105 0 2 0.895 2 2 0 1.107-0.895 2-2 2s-2-0.893-2-2c0-1.105 0.895-2 2-2zM4 26c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM14 30c0-1.109 0.895-2 2-2 1.108 0 2 0.891 2 2 0 1.102-0.892 2-2 2-1.105 0-2-0.898-2-2zM24 26c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM30 18c-1.104 0-2-0.896-2-2 0-1.107 0.896-2 2-2s2 0.893 2 2c0 1.104-0.896 2-2 2zM24 6c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2z" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '237px',
                height: '8px',
                left: '69px',
                top: '270.952px'
              }}
              data-name="shape-225e18"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 255, 255)"
                data-shape-stretch="true"
                style={{ 'z-index': '23' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 237 8"
                >
                  <rect
                    width={237}
                    height={8}
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgb(255, 255, 255)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '960px',
                height: '720px',
                left: '0px',
                top: '0px'
              }}
              data-name="shape-c4268d"
              // data-auto-animate-duration="0.9"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '10', opacity: '0.6' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '10' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 960 720"
                  >
                    <rect
                      width={960}
                      height={720}
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '894.196px',
                height: '654.237px',
                left: '36.116px',
                top: '35.0375px'
              }}
              data-name="shape-22dffa"
              // data-auto-animate-duration="0.8"
            >
              <div
                className="sl-block-style"
                style={{ 'z-index': '11', opacity: '0.8' }}
              >
                <div
                  className="sl-block-content ai-gradient-svg"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ 'z-index': '11' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 894 654"
                  >
                    <rect
                      width="894.196"
                      height="654.237"
                      rx={0}
                      ry={0}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="ai-gradient-block"
              style={{
                width: '930.892px',
                height: '682.267px',
                left: '14.554px',
                top: '12.8665px'
              }}
            >
              <div
                className="sl-block-content ai-gradient-svg"
                data-shape-type="rect"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ 'z-index': '12' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 931 682"
                >
                  <rect
                    width="930.892"
                    height="682.267"
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: '100%', bottom: 150 }}
              data-name="button-f3216ab"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleSetViewToSignCourseware()}
                  sx={{ minWidth: 160, fontSize: '1.15rem' }}
                  // disabled={!didReachTheEnd}
                >
                  חתימה על סיום הלומדה
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PalliativeCare;
