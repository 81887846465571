import { TableBody, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import meditationLottie from 'src/assets/lottie/meditation.json';
import theme from 'src/theme';
import '../../home.css';
import CCTVCompare from '../CCTVCompare';
import FallPreventionCompare from '../FallPreventionTechnologyCompare';
import FundsCompare from '../FundsCompare';
import IVCompare from '../IVCompare';
import KitchenCompare from '../KitchenCompare';
import KosherCompare from '../KosherCompare';
import OrgsPicturesSliders from '../OrgsPicturesSliders';
import OtherActionsCompare from '../OtherActionsCompare';
import PricesCompare from '../PricesCompare';
import TagsCompare from '../TagsCompare';
import StyledTableCell from './StyledTableCell';
import StyledTableRow from './StyledTableRow';

const StyledComparisonTableBody = ({
  userscrolledhorizontally,
  list,
  orgsPictures
}) => {
  StyledComparisonTableBody.propTypes = {
    userscrolledhorizontally: PropTypes.bool,
    orgsPictures: PropTypes.array,
    list: PropTypes.object
  };
  return (
    <TableBody sx={{ position: 'relative' }}>
      <StyledTableRow key="OrgsPicturesSliders-compare-row">
        <OrgsPicturesSliders
          key="OrgsPicturesSliders-compare-component"
          StyledTableCell={StyledTableCell}
          list={list || {}}
          orgsPictures={orgsPictures}
          userscrolledhorizontally={userscrolledhorizontally}
        />
      </StyledTableRow>
      <StyledTableRow key="tags-compare-row">
        <TagsCompare
          key="tags-compare-component"
          StyledTableCell={StyledTableCell}
          list={list || {}}
          userscrolledhorizontally={userscrolledhorizontally}
        />
      </StyledTableRow>
      <StyledTableRow key="funds-compare-row">
        <FundsCompare
          key="funds-compare-component"
          StyledTableCell={StyledTableCell}
          userscrolledhorizontally={userscrolledhorizontally}
          list={list || {}}
        />
      </StyledTableRow>
      <StyledTableRow key="pricing-compare-row">
        <PricesCompare
          key="pricing-compare-component"
          StyledTableCell={StyledTableCell}
          userscrolledhorizontally={userscrolledhorizontally}
          list={list || {}}
        />
      </StyledTableRow>
      <StyledTableRow key="iv-compare-row">
        <IVCompare
          key="iv-compare-component"
          StyledTableCell={StyledTableCell}
          userscrolledhorizontally={userscrolledhorizontally}
          list={list || {}}
        />
      </StyledTableRow>
      <StyledTableRow key="kitchen-compare-row">
        <KitchenCompare
          key="kitchen-compare-component"
          userscrolledhorizontally={userscrolledhorizontally}
          StyledTableCell={StyledTableCell}
          list={list || {}}
        />
      </StyledTableRow>
      <StyledTableRow key="kosher-compare-row">
        <KosherCompare
          key="kosher-compare-component"
          userscrolledhorizontally={userscrolledhorizontally}
          StyledTableCell={StyledTableCell}
          list={list || {}}
        />
      </StyledTableRow>
      <StyledTableRow key="cctv-compare-row">
        <CCTVCompare
          key="cctv-compare-component"
          userscrolledhorizontally={userscrolledhorizontally}
          StyledTableCell={StyledTableCell}
          list={list || {}}
        />
      </StyledTableRow>
      <StyledTableRow key="FallPreventionCompare-compare-row">
        <FallPreventionCompare
          key="FallPreventionCompare-compare-component"
          userscrolledhorizontally={userscrolledhorizontally}
          StyledTableCell={StyledTableCell}
          list={list || {}}
        />
      </StyledTableRow>
      <StyledTableRow key="otherActionsCompare-compare-row">
        <OtherActionsCompare
          key="OtherActionsCompare-compare-component"
          userscrolledhorizontally={userscrolledhorizontally}
          StyledTableCell={StyledTableCell}
          list={list || {}}
        />
      </StyledTableRow>
      {!list && (
        <StyledTableRow
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.15) !important',
            textAlign: 'center !important'
          }}
        >
          <Lottie
            style={{
              width: '40vw !important',
              height: '30vh',
              minWidth: '150px',
              minHeight: '150px'
            }}
            autoPlay
            animationData={meditationLottie}
          />
          <Typography
            component="span"
            sx={{
              textAlign: 'center',
              fontSize: '2.5rem',
              width: '100%',
              color: theme.palette.primary.main
            }}
            variant="h3"
          >
            בחר ארגונים כדי להתחיל
          </Typography>
        </StyledTableRow>
      )}
    </TableBody>
  );
};
export default StyledComparisonTableBody;
