import { Slider } from '@mui/material';
import styled from '@mui/styles/styled';
import PropType from 'prop-types';
import { useMap } from 'react-map-gl';
import { useNavigate } from 'react-router';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import routesConst from 'src/routesConst';

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    borderRadius: '0 8px 8px 0 '
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    transform: 'translate(25%, -50%)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 'fit-content',
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(25%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -50%) rotate(225deg) scale(1)',
      zIndex: 9999
    },
    '& > *': {
      transform: 'rotate(135deg)'
    }
  }
});

const CustomizedSlider = ({
  sliderSx,
  handleSliderValueChange,
  sliderValue,
  performSearch
}) => {
  CustomizedSlider.propTypes = {
    sliderSx: PropType.object,
    handleSliderValueChange: PropType.func,
    performSearch: PropType.func,
    sliderValue: PropType.number
  };
  const { searchQuery, searchIndex, setSearchResults, setResultsAreLoading } =
    useSearchData();
  const { mainMap } = useMap({ id: 'mainMap' });
  const navigate = useNavigate();

  return (
    <PrettoSlider
      valueLabelDisplay="off"
      aria-label="pretto slider"
      tabIndex={-1}
      sx={{ ...sliderSx, mx: 2 }}
      value={sliderValue}
      onChange={(e, newVal) => {
        if (newVal > 0) handleSliderValueChange(e, newVal);
      }}
      onChangeCommitted={(e, newDistance) => {
        // console.log(window.location.pathname);
        if (
          window.location.pathname === `/${routesConst.results}` ||
          window.location.pathname === `/${routesConst.search}`
        ) {
          performSearch();
        }
      }}
      max={100}
    />
  );
};

export default CustomizedSlider;
