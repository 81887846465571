import { Stack, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import routesConst from 'src/routesConst';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const OtherActionsCompare = ({
  StyledTableCell,
  list,
  userscrolledhorizontally
}) => {
  OtherActionsCompare.propTypes = {
    StyledTableCell: PropTypes.any,
    list: PropTypes.any,
    userscrolledhorizontally: PropTypes.bool
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="other" />
        {t('otherActions')}
      </StyledTableCell>
      {list.names &&
        list.names.map((name, i) => (
          <StyledTableCell
            key={`otherActions--${name}-${uniqueId()}`}
            align="center"
          >
            <Stack>
              <Typography
                href={`/${routesConst.org}/${name}&uid=${list.docs[i]}`}
                component="a"
                target="_blank"
                rel="noreferrer noopener"
              >
                {`הצג דף הארגון של  ${name}`}
              </Typography>
              {list.phones[name] && (
                <Typography href={`tel:${list.phones[name]}`} component="a">
                  {`התקשר ל- ${name}`}
                </Typography>
              )}
            </Stack>
          </StyledTableCell>
        ))}
    </>
  );
};

export default OtherActionsCompare;
