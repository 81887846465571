import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const FallPreventionCompare = ({
  StyledTableCell,
  list,
  userscrolledhorizontally
}) => {
  FallPreventionCompare.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.any,
    userscrolledhorizontally: PropTypes.bool
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="fall" />
        {t('fallPreventionTechnology')}
      </StyledTableCell>
      {list.names && (
        <>
          {list.fall_prevention
            ? list.fall_prevention.map((fall, index) => (
                <StyledTableCell
                  key={`fall-prevention-${fall}-${list.names[index]}`}
                  align="center"
                >
                  {!fall ? t('weDontHave') : t('weHave')}
                </StyledTableCell>
              ))
            : list.names.map((name) => (
                <StyledTableCell key={`no-data-${name}`} align="center">
                  {t('noData')}
                </StyledTableCell>
              ))}
        </>
      )}
    </>
  );
};

export default FallPreventionCompare;
