import { Alert, Snackbar } from '@mui/material';

import Slide from '@mui/material/Slide';

import PropType from 'prop-types';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" style={{ direction: 'rtl' }} />;
}

const CustomizedSnackBar = ({ open, msg, severity, setOpen }) => {
  CustomizedSnackBar.propTypes = {
    open: PropType.bool,

    msg: PropType.string,

    severity: PropType.oneOf(['error', 'info', 'success', 'warning']),

    setOpen: PropType.func
  };

  CustomizedSnackBar.defaultProps = {
    severity: 'success'
  };

  return (
    <Snackbar
      style={{ dir: 'rtl', zIndex: 1900 }}
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      message={msg}
      autoHideDuration={4000}
      onClose={setOpen}
      TransitionComponent={TransitionLeft}
    >
      <Alert severity={severity}>{msg}</Alert>
    </Snackbar>
  );
};

export default CustomizedSnackBar;
