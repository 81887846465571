import { LinearProgress, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'src/components/shared/Slider/Slider';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const OrgsPicturesSliders = ({
  StyledTableCell,
  list,
  userscrolledhorizontally,
  orgsPictures
}) => {
  OrgsPicturesSliders.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.object,
    userscrolledhorizontally: PropTypes.bool,
    orgsPictures: PropTypes.array
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });
  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="home" />
        {t('picturesOfOrg')}
      </StyledTableCell>
      {orgsPictures && orgsPictures.length > 0 ? (
        orgsPictures.map((org) => (
          <StyledTableCell
            key={org.docId}
            lengthOfList={list.names.length}
            align="center"
            style={{ height: '200px' }}
          >
            <>
              {!org.pictures ? (
                <LinearProgress />
              ) : org.pictures && org.pictures.length === 0 ? (
                t('theOrgHavntProvidedAny')
              ) : (
                  <Slider
                    slides={org.pictures}
                    showNumbering={Boolean(true)}
                    sliderSx={{
                      width: '100%',
                      maxHeight: '250px',
                      maxWidth: '400px'
                    }}
                    singleSlideSx={{
                      textAlign: 'center',
                      fontSize: '18px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      objectFit: 'contain'
                    }}
                  />
              )}
            </>
          </StyledTableCell>
        ))
      ) : !orgsPictures && list && list.length > 0 ? (
        <StyledTableCell
          key="skeleton-table-cell"
          lengthOfList={list.names.length}
          align="center"
          style={{ height: '200px' }}
        >
          <Skeleton
            sx={{
              height: '100%',
              minHeight: 200,
              width: 'calc(100% - 32px)',
              display: 'flex',
              flex: 1,
              minWidth: 200,
              mx: 'auto'
            }}
          />
        </StyledTableCell>
      ) : null}
    </>
  );
};

export default OrgsPicturesSliders;
