import {
  Box,
  Button,
  Grid,
  Hidden,
  Stack,
  Typography,
  // alpha,
  useMediaQuery
  // useTheme
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/styles/useTheme';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import contactUsCharacter from 'src/assets/contactUsCharacter.svg';
import giftBox from 'src/assets/lottie/giftbox.json';
import ContactFormAndDetailsSection from 'src/components/ContactUs/ContactFormAndDetailsSection';
import CopounTicket from 'src/components/ContactUs/CopounTicket';
import handleGTag from 'src/utils/handleGTag';
import './contact.css';

const ContactUs = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'contactUs'
  });
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [yourMsgWasSent, setYourMsgWasSent] = useState();

  return (
    <>
      <Helmet>
        <title>{t('contactUs')}</title>
        <meta
          name="description"
          content="מגדלור ייעוץ והכוונה - מנוע החיפוש וההשוואה של בתי האבות והדיור המוגן בישראל"
        />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
        <link rel="canonical" href="https://www.mgdalor.com/contact" />
      </Helmet>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          sx={{
            minHeight: '100vh',
            backgroundImage:
              'linear-gradient(-140deg, rgb(237, 241, 247) 10%, rgb(255, 255, 255) 100%)',
            backgroundSize: '100%',
            backgroundPosition: 'center'
          }}
        >
          <Grid
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              px: 2
            }}
            item
            xs={12}
            md={6}
          >
            <Stack sx={{ width: '100%', paddingInlineStart: '3rem' }}>
              <Typography
                sx={{ color: 'rgb(30, 32, 34)' }}
                variant="h1"
                fontSize="5rem"
              >
                יצירת קשר
              </Typography>
              <Typography
                sx={{ maxWidth: 500, textAlign: 'justify' }}
                variant="h3"
                color="GrayText"
                fontSize="2.5rem"
              >
                הצוות המקצועי שלנו יהפוך את תהליך מציאת בית אבות לקל ומהר יותר
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: '100%',
                alignContent: 'center',
                py: 2,
                paddingInlineStart: '3rem'
              }}
            >
              <Button
                sx={{ maxWidth: 300, minWidth: 150 }}
                variant="contained"
                href="#form"
              >
                ליצירת קשר
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                MozBoxPack: 'center'
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${contactUsCharacter})`,
                  backgroundPosition: 'center',
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '100%',
                  maxWidth: 500
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            minHeight: 300,
            backgroundImage:
              'linear-gradient(-40deg, rgba(237, 241, 247, 0.7) 10%, rgb(255, 255, 255) 100%)',
            py: 2,
            px: matchDownMd ? 1 : 2
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgb(55, 125, 255)',
              width: 'calc(100% - 50px)',
              mx: 'auto',
              borderRadius: 2,
              boxShadow: theme.shadows[8],
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 4,
              position: 'relative'
            }}
          >
            <Typography
              color={theme.palette.primary.contrastText}
              variant="h1"
              fontSize="3rem"
              sx={{ textAlign: 'center' }}
            >
              מגיעה לך הטבה ממגדלור!
            </Typography>
            <Hidden mdDown>
              <Lottie
                style={{
                  width: '70% !important',
                  height: '120px',
                  minWidth: '150px',
                  transform: 'scale(1.5)',
                  position: 'absolute',
                  bottom: 0,
                  left: 0
                }}
                autoplay
                animationData={giftBox}
              />
            </Hidden>
            <Hidden mdDown>
              <CopounTicket />
            </Hidden>
            <Hidden mdUp>
              <Stack
                direction={{ sm: 'row', xs: 'column-reverse' }}
                sx={{
                  position: 'relative',
                  minHeight: 100,
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.3rem',
                      color: alpha(theme.palette.primary.contrastText, 0.7)
                    }}
                  >
                    ממשפחת מגדלור, תוענק לך מתנה אחרי הקליטה לבית אבות
                  </Typography>
                </Box>
                <Box>
                  <Lottie
                    style={{
                      width: '70% !important',
                      height: '120px',
                      minWidth: '150px',
                      transform: 'scale(1.5)'
                    }}
                    autoplay
                    animationData={giftBox}
                  />
                </Box>
              </Stack>
            </Hidden>
            <Box sx={{ width: '100%' }}>
              <Typography
                variant="caption"
                fontSize="1rem"
                sx={{ px: 1, textAlign: 'start' }}
              >
                * המתנה ניתנת לאחר הקליטה לאחד מבתי האבות השותפים של מגדלור כפוף
                לתנאי האתר
              </Typography>
            </Box>
          </Box>
        </Grid>
        <ContactFormAndDetailsSection
          yourMsgWasSent={yourMsgWasSent}
          setYourMsgWasSent={setYourMsgWasSent}
        />
      </Grid>
    </>
  );
};

export default ContactUs;
