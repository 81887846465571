import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';
import QuestionSlide from '../../sharedPartials/QuestionSlide';
import getQuestionData from '../getQuestionData';
import painLift from './painLift.webp';
import painRuler from './painRuler.svg';
import stickers from './stickers.webp';

/* eslint-disable */
const PainCourseware = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
}) => {
  PainCourseware.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    globalCoursewares: PropTypes.array,
    handleSetViewToSignCourseware: PropTypes.func
  };

  return (
    <div
      className="theme-font-montserrat theme-color-white-blue"
      style={{ width: '100%', height: '100%' }}
    >
      <div className="reveal pain">
        <div className="slides">
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '808.402px',
                top: '221.029px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '208.201px',
                height: '80.2563px',
                left: '375.9px',
                top: '85.1969px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-f31526"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={179}
                  data-natural-height={69}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967514/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '493.09px',
                left: '233.455px',
                top: '223.267px'
              }}
              data-name="text-1b9e0e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) -3px 5px 4px) opacity(100%)'
                }}
              >
                <div className="sl-block-content" style={{ zIndex: 15 }}>
                  <h1 style={{ textAlign: 'center' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span
                                style={{
                                  color: 'rgb(0, 0, 255)'
                                }}
                              >
                                לומדה
                              </span>
                            </span>{' '}
                            <span>
                              <span
                                style={{
                                  color: 'rgb(0, 0, 255)'
                                }}
                              >
                                בנושא
                              </span>
                            </span>{' '}
                            <span>
                              <span
                                style={{
                                  color: 'rgb(0, 0, 255)'
                                }}
                              >
                                טיפול
                              </span>
                            </span>{' '}
                            <span>
                              <span
                                style={{
                                  color: 'rgb(0, 0, 255)'
                                }}
                              >
                                בכאב
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgba(0, 0, 0, 0)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-stroke-color="#4a86e8"
                data-shape-stroke-width="3px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 120 120"
                >
                  <defs>
                    <clipPath id="shape-mask-1-1701468561679">
                      <ellipse
                        rx={60}
                        ry={60}
                        cx={60}
                        cy={60}
                        fill="rgba(0, 0, 0, 0)"
                        stroke="#4a86e8"
                        strokeWidth={6}
                      />
                    </clipPath>
                  </defs>
                  <ellipse
                    rx={60}
                    ry={60}
                    cx={60}
                    cy={60}
                    className="shape-element"
                    fill="rgba(0, 0, 0, 0)"
                    stroke="#4a86e8"
                    strokeWidth={6}
                    clipPath="url(#shape-mask-1-1701468561679)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '786.213px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 17,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 17 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-2-1701468603489">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-2-1701468603489)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 80, top: 536, height: 'auto' }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '130%'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  מערך ההדרכה - מגדלור ייעוץ והכוונה
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: '108.981px', height: 'auto' }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  fontSize: '160%',
                  textAlign: 'left',
                  lineHeight: '1.04',
                  letterSpacing: '-0.1em',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
                data-has-letter-spacing=""
              >
                <h1 style={{ textAlign: 'center' }}>
                  <strong>כיצד נלמד?</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: '289.921px', height: 'auto' }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>ההתקדמות היא באמצעות החצים &lt; או&nbsp; &gt;</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 806, left: 77, top: '434.647px', height: 'auto' }}
              data-name="text-4bdc77"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  padding: 8,
                  fontSize: '110%',
                  color: 'rgb(34, 34, 34)'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <strong>
                    שמגיעים לשקופית שמכילה שאלה, אי אפשר להתקדם עד שעונים נכון
                    עליה
                  </strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '-47.1628px',
                top: '60.7921px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '82.0406px',
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '808.401px',
                top: '258.45px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 15 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '909.698px',
                top: '536.647px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '180.744px',
                left: '736.562px',
                top: '62.176px'
              }}
              data-name="text-9ccf34"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(225, 32, 32)' }}
              >
                <p>מה זה כאב?</p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f8b626"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '580.622px 201px 0px', zIndex: 12 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '640.645px',
                    height: '116.576px',
                    left: '260.299px',
                    top: '142.712px'
                  }}
                  data-name="shape-868753"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(223, 37, 37)"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                    data-shape-corner-radius="12px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 641 117"
                    >
                      <rect
                        width="640.645"
                        height="116.576"
                        rx={12}
                        ry={12}
                        className="shape-element"
                        fill="#f94c66"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d386c2"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '322.877px 201px 0px',
                      zIndex: 14
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 100,
                        height: 100,
                        left: '272.877px',
                        top: 151
                      }}
                      data-name="shape-3d8362"
                      data-origin-id="db93c21a52adc62c833290222ed48248"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgb(204, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 15 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 100 100"
                        >
                          <ellipse
                            rx={50}
                            ry={50}
                            cx={50}
                            cy={50}
                            className="shape-element"
                            fill="rgb(204, 204, 204)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 80,
                        height: 80,
                        left: '282.877px',
                        top: 161
                      }}
                      data-name="shape-fd75c1"
                    >
                      <div
                        className="sl-block-style"
                        style={{
                          zIndex: 16,
                          filter:
                            'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                        }}
                      >
                        <div
                          className="sl-block-content"
                          data-shape-type="circle"
                          data-shape-fill-color="rgb(204, 204, 204)"
                          data-shape-stretch="true"
                          style={{ zIndex: 16 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="none"
                            viewBox="0 0 80 80"
                          >
                            <ellipse
                              rx={40}
                              ry={40}
                              cx={40}
                              cy={40}
                              className="shape-element"
                              fill="rgb(204, 204, 204)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '34.8716px',
                    left: '305.441px',
                    top: '181.5px'
                  }}
                  data-name="text-b9ed4a"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 17, color: 'rgb(223, 37, 37)' }}
                  >
                    <p>
                      <strong>01</strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '494.138px',
                    left: '389.787px',
                    top: 161
                  }}
                  data-name="text-fd65e9"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 18, color: 'rgb(238, 238, 238)' }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span style={{ fontSize: '0.9em' }}>
                        אחת התחושות הבסיסיות, שעליה מגיב גופינו בתגובה מידית -
                        תגובת "ההגנה על הגוף".
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '215.852px',
                height: '91.5734px',
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-07e895"
            >
              <div className="sl-block-content" style={{ zIndex: 33 }}>
                <img
                  className=""
                  data-natural-width={231}
                  data-natural-height={98}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967532/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '122.632px',
                height: 269,
                left: '855.99px',
                top: '189.869px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cbc121"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={93}
                  data-natural-height={204}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967533/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-c8f7a3"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-left"
                style={{ transformOrigin: '389.787px 367px 0px', zIndex: 19 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '640.645px',
                    height: '116.576px',
                    left: '69.4645px',
                    top: '308.712px'
                  }}
                  data-name="shape-a3ec68"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(106, 168, 79)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                    data-shape-corner-radius="12px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 641 117"
                    >
                      <rect
                        width="640.645"
                        height="116.576"
                        rx={12}
                        ry={12}
                        className="shape-element"
                        fill="#53bf9d"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-05aa1d"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '132.043px 367px 0px',
                      zIndex: 21
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 100,
                        height: 100,
                        left: '82.0425px',
                        top: 317
                      }}
                      data-origin-id="8c85e02f3c7bf5e2445dd9c094970755"
                      data-name="shape-d07575"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgb(204, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 22 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 100 100"
                        >
                          <ellipse
                            rx={50}
                            ry={50}
                            cx={50}
                            cy={50}
                            className="shape-element"
                            fill="rgb(204, 204, 204)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 80,
                        height: 80,
                        left: '92.0425px',
                        top: 327
                      }}
                      data-origin-id="f8f299bb5c82093082b98e647f691ae8"
                      data-name="shape-f59f8c"
                    >
                      <div
                        className="sl-block-style"
                        style={{
                          zIndex: 23,
                          filter:
                            'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                        }}
                      >
                        <div
                          className="sl-block-content"
                          data-shape-type="circle"
                          data-shape-fill-color="rgb(204, 204, 204)"
                          data-shape-stretch="true"
                          style={{ zIndex: 23 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="none"
                            viewBox="0 0 80 80"
                          >
                            <ellipse
                              rx={40}
                              ry={40}
                              cx={40}
                              cy={40}
                              className="shape-element"
                              fill="rgb(204, 204, 204)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '34.8716px',
                    left: '114.606px',
                    top: '347.5px'
                  }}
                  data-name="text-97b54a"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 24, color: 'rgb(106, 168, 79)' }}
                  >
                    <p>
                      <strong>02</strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '494.138px',
                    left: '198.952px',
                    top: 327
                  }}
                  data-name="text-f1fcd6"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 25, color: 'rgb(238, 238, 238)' }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>מחוש</span>
                                </span>{' '}
                                <span>
                                  <span>גופני</span>
                                </span>{' '}
                                <span>
                                  <span>קשה</span>
                                </span>{' '}
                                <span>
                                  <span>הכרוך</span>
                                </span>{' '}
                                <span>
                                  <span>בצער</span>
                                </span>
                                <span>
                                  <span>, </span>
                                </span>
                                <span>
                                  <span>סבל</span>
                                </span>{' '}
                                <span>
                                  <span>גופני</span>
                                </span>{' '}
                                <span>
                                  <span>מחמת</span>
                                </span>{' '}
                                <span>
                                  <span>מכה</span>
                                </span>
                                <span>
                                  <span>, </span>
                                </span>
                                <span>
                                  <span>פצע</span>
                                </span>
                                <span>
                                  <span>, </span>
                                </span>
                                <span>
                                  <span>או</span>
                                </span>{' '}
                                <span>
                                  <span>מחלה</span>
                                </span>
                                <span>
                                  <span>.</span>
                                </span>{' '}
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-c50e55"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-right"
                style={{
                  transformOrigin: '580.622px 536.733px 0px',
                  zIndex: 26
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '640.645px',
                    height: '123.913px',
                    left: '260.299px',
                    top: '474.776px'
                  }}
                  data-name="shape-f6108d"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(166, 77, 121)"
                    data-shape-stretch="true"
                    style={{ zIndex: 27 }}
                    data-shape-corner-radius="12px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 641 124"
                    >
                      <rect
                        width="640.645"
                        height="123.913"
                        rx={12}
                        ry={12}
                        className="shape-element"
                        fill="#bd4291"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e18e02"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '322.877px 533.064px 0px',
                      zIndex: 28
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 100,
                        height: 100,
                        left: '272.877px',
                        top: '483.064px'
                      }}
                      data-origin-id="106449e567c24bf4f39c7630f3d4338a"
                      data-name="shape-065077"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgb(204, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 29 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 100 100"
                        >
                          <ellipse
                            rx={50}
                            ry={50}
                            cx={50}
                            cy={50}
                            className="shape-element"
                            fill="rgb(204, 204, 204)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 80,
                        height: 80,
                        left: '282.877px',
                        top: '493.064px'
                      }}
                      data-origin-id="469c56305174b3ec6f6053c257b7b414"
                      data-name="shape-cbdfaf"
                    >
                      <div
                        className="sl-block-style"
                        style={{
                          zIndex: 30,
                          filter:
                            'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                        }}
                      >
                        <div
                          className="sl-block-content"
                          data-shape-type="circle"
                          data-shape-fill-color="rgb(204, 204, 204)"
                          data-shape-stretch="true"
                          style={{ zIndex: 30 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="none"
                            viewBox="0 0 80 80"
                          >
                            <ellipse
                              rx={40}
                              ry={40}
                              cx={40}
                              cy={40}
                              className="shape-element"
                              fill="rgb(204, 204, 204)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '34.8716px',
                    left: '305.441px',
                    top: '513.564px'
                  }}
                  data-name="text-c88c0a"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 31, color: 'rgb(166, 77, 121)' }}
                  >
                    <p>
                      <strong>03</strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '494.138px',
                    left: '389.787px',
                    top: '486.233px'
                  }}
                  data-name="text-c377e7"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 32,
                      color: 'rgb(238, 238, 238)',
                      fontSize: '86%'
                    }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>התנסות</span>
                                </span>{' '}
                                <span>
                                  <span>סנסורית</span>
                                </span>{' '}
                                <span>
                                  <span>ורגשית</span>
                                </span>
                                <span>
                                  <span>, </span>
                                </span>
                                <span>
                                  <span>סובייקטיבית</span>
                                </span>
                                <span>
                                  <span>, </span>
                                </span>
                                <span>
                                  <span>בלתי</span>
                                </span>{' '}
                                <span>
                                  <span>נעימה</span>
                                </span>
                                <span>
                                  <span>, </span>
                                </span>
                                <span>
                                  <span>הקשורה</span>
                                </span>{' '}
                                <span>
                                  <span>בהרס</span>
                                </span>{' '}
                                <span>
                                  <span>רקמות</span>
                                </span>{' '}
                                <span>
                                  <span>אמיתי</span>
                                </span>{' '}
                                <span>
                                  <span>או</span>
                                </span>{' '}
                                <span>
                                  <span>פוטנציאלי</span>
                                </span>
                                <span>
                                  <span>, </span>
                                </span>
                                <span>
                                  <span>או</span>
                                </span>{' '}
                                <span>
                                  <span>מתוארת</span>
                                </span>{' '}
                                <span>
                                  <span>במושגים</span>
                                </span>{' '}
                                <span>
                                  <span>של</span>
                                </span>{' '}
                                <span>
                                  <span>הרס</span>
                                </span>{' '}
                                <span>
                                  <span>כזה</span>
                                </span>
                                <span>
                                  <span>.</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '180.744px',
                left: '736.562px',
                top: '62.176px'
              }}
              data-name="text-9ccf34"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, color: 'rgb(225, 32, 32)' }}
              >
                <p>מה זה כאב?</p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f8b626"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '580.622px 201px 0px', zIndex: 12 }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '640.645px',
                    height: '116.576px',
                    left: '260.299px',
                    top: '142.712px'
                  }}
                  data-name="shape-868753"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(230, 145, 56)"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                    data-shape-corner-radius="12px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 641 117"
                    >
                      <rect
                        width="640.645"
                        height="116.576"
                        rx={12}
                        ry={12}
                        className="shape-element"
                        fill="rgb(230, 145, 56)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-d386c2"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '322.877px 201px 0px',
                      zIndex: 14
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 100,
                        height: 100,
                        left: '272.877px',
                        top: 151
                      }}
                      data-name="shape-3d8362"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgb(204, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 15 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 100 100"
                        >
                          <ellipse
                            rx={50}
                            ry={50}
                            cx={50}
                            cy={50}
                            className="shape-element"
                            fill="rgb(204, 204, 204)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 80,
                        height: 80,
                        left: '282.877px',
                        top: 161
                      }}
                      data-name="shape-fd75c1"
                    >
                      <div
                        className="sl-block-style"
                        style={{
                          zIndex: 16,
                          filter:
                            'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                        }}
                      >
                        <div
                          className="sl-block-content"
                          data-shape-type="circle"
                          data-shape-fill-color="rgb(204, 204, 204)"
                          data-shape-stretch="true"
                          style={{ zIndex: 16 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="none"
                            viewBox="0 0 80 80"
                          >
                            <ellipse
                              rx={40}
                              ry={40}
                              cx={40}
                              cy={40}
                              className="shape-element"
                              fill="rgb(204, 204, 204)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '34.8716px',
                    left: '305.441px',
                    top: '181.5px'
                  }}
                  data-name="text-b9ed4a"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 17, color: 'rgb(230, 145, 56)' }}
                  >
                    <p>
                      <strong>04</strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '494.138px',
                    left: '386.643px',
                    top: '181.5px'
                  }}
                  data-name="text-fd65e9"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 18, color: 'rgb(238, 238, 238)' }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>אומדן</span>
                                </span>{' '}
                                <span>
                                  <span>כאב</span>
                                </span>{' '}
                                <span>
                                  <span>הוא</span>
                                </span>{' '}
                                <span>
                                  <span>שלב</span>
                                </span>{' '}
                                <span>
                                  <span>ראשון</span>
                                </span>{' '}
                                <span>
                                  <span>לטיפול</span>
                                </span>{' '}
                                <span>
                                  <span>בכאב</span>
                                </span>
                                <span>
                                  <span>.</span>
                                </span>{' '}
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '215.852px',
                height: '91.5734px',
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-07e895"
            >
              <div className="sl-block-content" style={{ zIndex: 19 }}>
                <img
                  className=""
                  data-natural-width={231}
                  data-natural-height={98}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967532/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '122.632px',
                height: 269,
                left: '855.99px',
                top: '189.869px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cbc121"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={93}
                  data-natural-height={204}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967533/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-307b0c"
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-up"
                style={{
                  transformOrigin: '428.249px 382.657px 0px',
                  zIndex: 20
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '640.645px',
                    height: '116.576px',
                    left: '107.926px',
                    top: '324.369px'
                  }}
                  data-origin-id="f462af26fdb87b49e7b660fe97c28c5f"
                  data-name="shape-d8916e"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(60, 120, 216)"
                    data-shape-stretch="true"
                    style={{ zIndex: 21 }}
                    data-shape-corner-radius="12px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 641 117"
                    >
                      <rect
                        width="640.645"
                        height="116.576"
                        rx={12}
                        ry={12}
                        className="shape-element"
                        fill="rgb(60, 120, 216)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-origin-id="e071059e805e7b2598ef9133e2b78268"
                  data-name="group-f476e2"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '170.504px 382.657px 0px',
                      zIndex: 22
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 100,
                        height: 100,
                        left: '120.504px',
                        top: '332.657px'
                      }}
                      data-origin-id="9727776067e8938c0557ff282e1255b6"
                      data-name="shape-954751"
                    >
                      <div
                        className="sl-block-content"
                        data-shape-type="circle"
                        data-shape-fill-color="rgb(204, 204, 204)"
                        data-shape-stretch="true"
                        style={{ zIndex: 23 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 100 100"
                        >
                          <ellipse
                            rx={50}
                            ry={50}
                            cx={50}
                            cy={50}
                            className="shape-element"
                            fill="rgb(204, 204, 204)"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 80,
                        height: 80,
                        left: '130.504px',
                        top: '342.657px'
                      }}
                      data-origin-id="117e3811322b94a64e520f8a81b03f37"
                      data-name="shape-66f02a"
                    >
                      <div
                        className="sl-block-style"
                        style={{
                          zIndex: 24,
                          filter:
                            'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)'
                        }}
                      >
                        <div
                          className="sl-block-content"
                          data-shape-type="circle"
                          data-shape-fill-color="rgb(204, 204, 204)"
                          data-shape-stretch="true"
                          style={{ zIndex: 24 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="none"
                            viewBox="0 0 80 80"
                          >
                            <ellipse
                              rx={40}
                              ry={40}
                              cx={40}
                              cy={40}
                              className="shape-element"
                              fill="rgb(204, 204, 204)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '34.8716px',
                    left: '153.068px',
                    top: '363.157px'
                  }}
                  data-origin-id="80be1cce8a8df7bd2d43dab6d36325f6"
                  data-name="text-0a6c60"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 25, color: 'rgb(60, 120, 216)' }}
                  >
                    <p>
                      <strong>05</strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '494.138px',
                    left: '234.27px',
                    top: '363.157px'
                  }}
                  data-origin-id="24bb9f687e638717437b24c8e275e5ac"
                  data-name="text-ba2d42"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 26, color: 'rgb(238, 238, 238)' }}
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>סימן</span>
                                </span>{' '}
                                <span>
                                  <span>חיוני</span>
                                </span>{' '}
                                <span>
                                  <span>חמישי.</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '215.852px',
                height: '91.5734px',
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-07e895"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={231}
                  data-natural-height={98}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967532/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '122.632px',
                height: 269,
                left: '855.99px',
                top: '189.869px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cbc121"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={93}
                  data-natural-height={204}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967533/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '656.208px',
                height: 518,
                left: '151.896px',
                top: 91
              }}
              data-name="image-e2de96"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={584}
                  data-natural-height={461}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967538/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '79.065px',
                top: '627.949px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(180deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 13 }}>
                  <img
                    className=""
                    data-natural-width={103}
                    data-natural-height={32}
                    data-lazy-loaded=""
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-16-1701470249931">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-16-1701470249931)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-17-1701470249931">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-17-1701470249931)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '77.7814px',
                height: '77.7814px',
                left: '711.918px',
                top: '81.6086px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-6a12d4"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <img
                  className=""
                  data-natural-width={74}
                  data-natural-height={74}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967541/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '337.965px',
                left: '346.654px',
                top: '100.999px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 17 }}>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>כאב</span>
                          </span>{' '}
                          <span>
                            <span>חריף</span>
                          </span>
                          <span>
                            <span> - ACUTE PAIN </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f183ed"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '360.619px 390.335px 0px',
                  zIndex: 18
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 39,
                    height: 31,
                    left: '253.183px',
                    top: '310.717px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-f6159f"
                >
                  <div className="sl-block-content" style={{ zIndex: 24 }}>
                    <img
                      className=""
                      data-natural-width={39}
                      data-natural-height={31}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967543/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '192.974px',
                    height: '70.6261px',
                    left: '278.055px',
                    top: '290.951px'
                  }}
                  data-origin-id="3bff8308b5fd12596381f12f226094a9"
                  data-name="shape-9b32aa"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                    data-shape-stroke-color="#9900ff"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 193 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-18-1701470249931">
                          <rect
                            width="192.974"
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#9900ff"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width="192.974"
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#9900ff"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-18-1701470249931)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '234.283px',
                    top: '290.951px'
                  }}
                  data-origin-id="07c91be984b770998269a7699c3e2c54"
                  data-name="shape-8fc7d5"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                    data-shape-stroke-color="#9900ff"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-19-1701470249931">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#9900ff"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#9900ff"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-19-1701470249931)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '92.7005px',
                    left: '333.192px',
                    top: '304.718px'
                  }}
                  data-origin-id="7f5a6de81443c09e6da6f9a3183fc98a"
                  data-name="text-c8b8fa"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 21, transform: 'rotate(0deg)' }}
                  >
                    <div className="sl-block-content" style={{ zIndex: 21 }}>
                      <p>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>חולף</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: 300,
                    left: '210.619px',
                    top: '454.718px'
                  }}
                  data-origin-id="89a6d2b2b715a24fed8eafcbb64a3125"
                  data-name="text-e7f3d8"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 22, fontSize: '90%' }}
                  >
                    <p>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>חולף תוך</span>
                                </span>{' '}
                                <span>
                                  <span>מתן</span>
                                </span>{' '}
                                <span>
                                  <span>משככי</span>
                                </span>{' '}
                                <span>
                                  <span>כאבים</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '45.3021px',
                    height: '70.031px',
                    left: '340.703px',
                    top: '374.954px'
                  }}
                  data-origin-id="2ec212ec9ba6f38a90455b497a036af3"
                  data-name="shape-5bb456"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-thin-arrow-down"
                    data-shape-fill-color="rgb(153, 0, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 23 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(153, 0, 255)"
                        data-viewbox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                      >
                        <path d="M4.586 19.414l10 10c0.781 0.781 2.047 0.781 2.828 0l10-10c0.781-0.781 0.781-2.047 0-2.828s-2.047-0.781-2.828 0l-6.586 6.586v-19.172c0-1.105-0.895-2-2-2s-2 0.895-2 2v19.172l-6.586-6.586c-0.391-0.39-0.902-0.586-1.414-0.586s-1.024 0.195-1.414 0.586c-0.781 0.781-0.781 2.047 0 2.828z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-340070"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment semi-fade-out"
                style={{
                  transformOrigin: '684.619px 329.951px 0px',
                  zIndex: 25
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '192.974px',
                    height: '70.6261px',
                    left: '602.055px',
                    top: '228.567px'
                  }}
                  data-name="shape-055693"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 26 }}
                    data-shape-stroke-color="#f1c232"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 193 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-20-1701470249933">
                          <rect
                            width="192.974"
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#f1c232"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width="192.974"
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#f1c232"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-20-1701470249933)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '558.283px',
                    top: '228.567px'
                  }}
                  data-name="shape-fb6421"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 27 }}
                    data-shape-stroke-color="#f1c232"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-21-1701470249933">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#f1c232"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#f1c232"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-21-1701470249933)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 34,
                    height: 35,
                    left: '579.684px',
                    top: '246.334px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-e1ba55"
                >
                  <div className="sl-block-content" style={{ zIndex: 28 }}>
                    <img
                      className=""
                      data-natural-width={34}
                      data-natural-height={35}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967542/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '92.7005px',
                    left: '657.192px',
                    top: '242.334px'
                  }}
                  data-name="text-cd870f"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 29, transform: 'rotate(0deg)' }}
                  >
                    <div className="sl-block-content" style={{ zIndex: 29 }}>
                      <p>
                        <span>
                          <span>זמני</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: 300,
                    left: '534.619px',
                    top: '392.334px'
                  }}
                  data-name="text-e107e7"
                >
                  <div className="sl-block-content" style={{ zIndex: 30 }}>
                    <p>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>הופיע</span>
                              </span>{' '}
                              <span>
                                <span>לפני</span>
                              </span>{' '}
                              <span>
                                <span>זמן</span>
                              </span>{' '}
                              <span>
                                <span>יחסית</span>
                              </span>{' '}
                              <span>
                                <span>קצר</span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '45.3021px',
                    height: '70.031px',
                    left: '664.703px',
                    top: '312.57px'
                  }}
                  data-name="shape-bf6aee"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-thin-arrow-down"
                    data-shape-fill-color="rgb(241, 194, 50)"
                    data-shape-stretch="true"
                    style={{ zIndex: 31 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(241, 194, 50)"
                        data-viewbox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                      >
                        <path d="M4.586 19.414l10 10c0.781 0.781 2.047 0.781 2.828 0l10-10c0.781-0.781 0.781-2.047 0-2.828s-2.047-0.781-2.828 0l-6.586 6.586v-19.172c0-1.105-0.895-2-2-2s-2 0.895-2 2v19.172l-6.586-6.586c-0.391-0.39-0.902-0.586-1.414-0.586s-1.024 0.195-1.414 0.586c-0.781 0.781-0.781 2.047 0 2.828z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-22-1701470265294">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-22-1701470265294)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-23-1701470265294">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-23-1701470265294)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '77.7814px',
                height: '77.7814px',
                left: '711.918px',
                top: '81.6086px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-6a12d4"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <img
                  className=""
                  data-natural-width={74}
                  data-natural-height={74}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967541/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '337.965px',
                left: '346.654px',
                top: '100.999px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 17 }}>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>כאב</span>
                          </span>{' '}
                          <span>
                            <span>חריף</span>
                          </span>
                          <span>
                            <span> - ACUTE PAIN </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-6e98f8"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{
                  transformOrigin: '360.619px 407.835px 0px',
                  zIndex: 18
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 43,
                    height: 33,
                    left: '251.183px',
                    top: '310.718px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-78fa41"
                >
                  <div className="sl-block-content" style={{ zIndex: 24 }}>
                    <img
                      className=""
                      data-natural-width={43}
                      data-natural-height={33}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967546/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '192.974px',
                    height: '70.6261px',
                    left: '278.055px',
                    top: '290.951px'
                  }}
                  data-name="shape-9b32aa"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                    data-shape-stroke-color="#a61c00"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 193 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-24-1701470265295">
                          <rect
                            width="192.974"
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#a61c00"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width="192.974"
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#a61c00"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-24-1701470265295)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '234.283px',
                    top: '290.951px'
                  }}
                  data-name="shape-8fc7d5"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                    data-shape-stroke-color="#a61c00"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-25-1701470265295">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#a61c00"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#a61c00"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-25-1701470265295)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '92.7005px',
                    left: '333.192px',
                    top: '304.718px'
                  }}
                  data-name="text-c8b8fa"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 21, transform: 'rotate(0deg)' }}
                  >
                    <div className="sl-block-content" style={{ zIndex: 21 }}>
                      <p>שינויים</p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: 300,
                    left: '210.619px',
                    top: '454.718px'
                  }}
                  data-name="text-e7f3d8"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 22, fontSize: '90%' }}
                  >
                    <p style={{ textAlign: 'center' }}>
                      גורם לשינויים בקצב הנשימה והדופק
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '45.3021px',
                    height: '70.031px',
                    left: '340.703px',
                    top: '374.954px'
                  }}
                  data-name="shape-5bb456"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-thin-arrow-down"
                    data-shape-fill-color="rgb(166, 28, 0)"
                    data-shape-stretch="true"
                    style={{ zIndex: 23 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(166, 28, 0)"
                        data-viewbox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                      >
                        <path d="M4.586 19.414l10 10c0.781 0.781 2.047 0.781 2.828 0l10-10c0.781-0.781 0.781-2.047 0-2.828s-2.047-0.781-2.828 0l-6.586 6.586v-19.172c0-1.105-0.895-2-2-2s-2 0.895-2 2v19.172l-6.586-6.586c-0.391-0.39-0.902-0.586-1.414-0.586s-1.024 0.195-1.414 0.586c-0.781 0.781-0.781 2.047 0 2.828z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-197b54"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment semi-fade-out"
                style={{
                  transformOrigin: '684.619px 349.451px 0px',
                  zIndex: 25
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 35,
                    height: 35,
                    left: '579.183px',
                    top: '246.38px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-76cb2a"
                >
                  <div className="sl-block-content" style={{ zIndex: 31 }}>
                    <img
                      className=""
                      data-natural-width={35}
                      data-natural-height={35}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967544/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '192.974px',
                    height: '70.6261px',
                    left: '602.055px',
                    top: '228.567px'
                  }}
                  data-name="shape-055693"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 26 }}
                    data-shape-stroke-color="#6aa84f"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 193 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-26-1701470265295">
                          <rect
                            width="192.974"
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#6aa84f"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width="192.974"
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#6aa84f"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-26-1701470265295)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '558.283px',
                    top: '228.567px'
                  }}
                  data-name="shape-fb6421"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 27 }}
                    data-shape-stroke-color="#6aa84f"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-27-1701470265295">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#6aa84f"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#6aa84f"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-27-1701470265295)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '104.23px',
                    left: '657.192px',
                    top: '242.334px'
                  }}
                  data-name="text-cd870f"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 28, transform: 'rotate(0deg)' }}
                  >
                    <div className="sl-block-content" style={{ zIndex: 28 }}>
                      <p>טראומה</p>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: 300,
                    left: '534.619px',
                    top: '392.334px'
                  }}
                  data-name="text-e107e7"
                >
                  <div className="sl-block-content" style={{ zIndex: 29 }}>
                    <p style={{ textAlign: 'center' }}>
                      נגרם על ידי טראומה או פעולה כירורגית
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '45.3021px',
                    height: '70.031px',
                    left: '664.703px',
                    top: '312.57px'
                  }}
                  data-name="shape-bf6aee"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-thin-arrow-down"
                    data-shape-fill-color="rgb(106, 168, 79)"
                    data-shape-stretch="true"
                    style={{ zIndex: 30 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(106, 168, 79)"
                        data-viewbox="4.000251770019531 1.9999980926513672 23.999500274658203 27.999752044677734"
                      >
                        <path d="M4.586 19.414l10 10c0.781 0.781 2.047 0.781 2.828 0l10-10c0.781-0.781 0.781-2.047 0-2.828s-2.047-0.781-2.828 0l-6.586 6.586v-19.172c0-1.105-0.895-2-2-2s-2 0.895-2 2v19.172l-6.586-6.586c-0.391-0.39-0.902-0.586-1.414-0.586s-1.024 0.195-1.414 0.586c-0.781 0.781-0.781 2.047 0 2.828z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-29-1701700692267">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-29-1701700692267)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-30-1701700692267">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-30-1701700692267)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '77.7814px',
                height: '77.7814px',
                left: '711.918px',
                top: '81.6086px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-6a12d4"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <img
                  className=""
                  data-natural-width={74}
                  data-natural-height={74}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967541/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '379.734px',
                left: '304.885px',
                top: '100.999px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 17 }}>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>כאב</span>
                          </span>{' '}
                          <span>
                            <span>כרוני </span>
                          </span>
                          <span>
                            <span>- CHRONIC PAIN </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f183ed"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-right"
                style={{
                  transformOrigin: '456.77px 292.042px 0px',
                  zIndex: 18
                }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 39,
                    height: 31,
                    left: '323.784px',
                    top: '276.495px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-f6159f"
                >
                  <div className="sl-block-content" style={{ zIndex: 22 }}>
                    <img
                      className=""
                      data-natural-width={39}
                      data-natural-height={31}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967543/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 260,
                    height: '70.6261px',
                    left: '348.656px',
                    top: '256.729px'
                  }}
                  data-name="shape-9b32aa"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                    data-shape-stroke-color="#9900ff"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 260 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-31-1701700692268">
                          <rect
                            width={260}
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#9900ff"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={260}
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#9900ff"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-31-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '304.884px',
                    top: '256.729px'
                  }}
                  data-name="shape-8fc7d5"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                    data-shape-stroke-color="#9900ff"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-32-1701700692268">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#9900ff"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#9900ff"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-32-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '211.546px',
                    left: '389.139px',
                    top: '260.495px'
                  }}
                  data-name="text-c8b8fa"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 21, transform: 'rotate(0deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        zIndex: 21,
                        fontSize: '90%',
                        lineHeight: '1.17'
                      }}
                      data-has-line-height=""
                    >
                      <p style={{ textAlign: 'justify' }}>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>ניתן</span>
                                </span>{' '}
                                <span>
                                  <span>להקלה</span>
                                </span>{' '}
                                <span>
                                  <span>עם</span>
                                </span>{' '}
                                <span>
                                  <span>טיפול</span>
                                </span>{' '}
                                <span>
                                  <span>אנלגטי.</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-340070"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '564.885px 206.382px 0px',
                  zIndex: 23
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 260,
                    height: '70.6261px',
                    left: '456.77px',
                    top: '170.126px'
                  }}
                  data-name="shape-055693"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 24 }}
                    data-shape-stroke-color="#f1c232"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 260 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-33-1701700692268">
                          <rect
                            width={260}
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#f1c232"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={260}
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#f1c232"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-33-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '412.999px',
                    top: '170.126px'
                  }}
                  data-name="shape-fb6421"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 25 }}
                    data-shape-stroke-color="#f1c232"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-34-1701700692268">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#f1c232"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#f1c232"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-34-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 34,
                    height: 35,
                    left: '434.4px',
                    top: '187.893px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-e1ba55"
                >
                  <div className="sl-block-content" style={{ zIndex: 26 }}>
                    <img
                      className=""
                      data-natural-width={34}
                      data-natural-height={35}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967542/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '221.855px',
                    left: '482.559px',
                    top: '172.638px'
                  }}
                  data-name="text-cd870f"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 27, transform: 'rotate(0deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      style={{ zIndex: 27, fontSize: '90%' }}
                    >
                      <p style={{ textAlign: 'right' }}>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>נמשך</span>
                                </span>{' '}
                                <span>
                                  <span>שלושה</span>
                                </span>{' '}
                                <span>
                                  <span>חודשים</span>
                                </span>{' '}
                                <span>
                                  <span>או</span>
                                </span>{' '}
                                <span>
                                  <span>יותר.</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-6e98f8"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-right"
                style={{
                  transformOrigin: '456.771px 461.481px 0px',
                  zIndex: 28
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 43,
                    height: 33,
                    left: '321.785px',
                    top: '445.935px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-78fa41"
                  data-origin-id="4453728b181cd3af7fd4d1e291150300"
                >
                  <div className="sl-block-content" style={{ zIndex: 32 }}>
                    <img
                      className=""
                      data-natural-width={43}
                      data-natural-height={33}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967546/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 260,
                    height: '70.6261px',
                    left: '348.657px',
                    top: '426.168px'
                  }}
                  data-origin-id="59c05d0a7a207f0615e46d5a7196f3d0"
                  data-name="shape-7f4d75"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 29 }}
                    data-shape-stroke-color="#a61c00"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 260 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-35-1701700692268">
                          <rect
                            width={260}
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#a61c00"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={260}
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#a61c00"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-35-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '304.885px',
                    top: '426.168px'
                  }}
                  data-origin-id="944218c632200433f5f54d27cd76903b"
                  data-name="shape-e3fbb6"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 30 }}
                    data-shape-stroke-color="#a61c00"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-36-1701700692268">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#a61c00"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#a61c00"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-36-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '217.942px',
                    left: '381.685px',
                    top: '427.435px'
                  }}
                  data-origin-id="47fe294abc00f49206f85aa6b83d222b"
                  data-name="text-17d87c"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 31, transform: 'rotate(0deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        zIndex: 31,
                        fontSize: '90%',
                        lineHeight: '1.196'
                      }}
                      data-has-line-height=""
                    >
                      <p style={{ textAlign: 'right' }}>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>אין</span>
                                </span>{' '}
                                <span>
                                  <span>שינויים</span>
                                </span>{' '}
                                <span>
                                  <span>בדופק</span>
                                </span>{' '}
                                <span>
                                  <span>ובנשימה.</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-197b54"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-left"
                style={{
                  transformOrigin: '564.885px 376.668px 0px',
                  zIndex: 33
                }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 35,
                    height: 35,
                    left: '433.899px',
                    top: '359.168px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-76cb2a"
                  data-origin-id="752bf54182cf0f73d0d0e1253369b7a3"
                >
                  <div className="sl-block-content" style={{ zIndex: 37 }}>
                    <img
                      className=""
                      data-natural-width={35}
                      data-natural-height={35}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967544/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 260,
                    height: '70.6261px',
                    left: '456.771px',
                    top: '341.355px'
                  }}
                  data-origin-id="0c8450c21b93aaefa6734567cb1ce3b0"
                  data-name="shape-33754c"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 34 }}
                    data-shape-stroke-color="#6aa84f"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 260 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-37-1701700692268">
                          <rect
                            width={260}
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#6aa84f"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={260}
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#6aa84f"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-37-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '412.999px',
                    top: '341.355px'
                  }}
                  data-origin-id="419e4aace268de36fec9d8d06b58bfa7"
                  data-name="shape-a8000f"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 35 }}
                    data-shape-stroke-color="#6aa84f"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-38-1701700692268">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#6aa84f"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#6aa84f"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-38-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '220.935px',
                    left: '486.806px',
                    top: '344.121px'
                  }}
                  data-origin-id="3c8bc6662cfd31d2554a03c4eb5a71fc"
                  data-name="text-c47b48"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 36, transform: 'rotate(0deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        zIndex: 36,
                        fontSize: '90%',
                        lineHeight: '1.196'
                      }}
                      data-has-line-height=""
                    >
                      <p style={{ textAlign: 'right' }}>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>לעיתים</span>
                                </span>
                                <span>
                                  <span> </span>
                                </span>
                                <span>
                                  <span>קשה</span>
                                </span>{' '}
                                <span>
                                  <span>לזהות</span>
                                </span>{' '}
                                <span>
                                  <span>את</span>
                                </span>{' '}
                                <span>
                                  <span>מקור</span>
                                </span>{' '}
                                <span>
                                  <span>הכאב.</span>
                                </span>{' '}
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-ed55b1"
              style={{ width: 'auto', height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-right"
                style={{
                  transformOrigin: '564.885px 546.107px 0px',
                  zIndex: 38
                }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 34,
                    height: 35,
                    left: '434.899px',
                    top: '528.061px',
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-730753"
                >
                  <div className="sl-block-content" style={{ zIndex: 42 }}>
                    <img
                      className=""
                      data-natural-width={34}
                      data-natural-height={35}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967542/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '76.8px',
                    height: '70.5321px',
                    left: '412.999px',
                    top: '510.794px'
                  }}
                  data-origin-id="d8c4bb143b6b03c8977b551e0e968141"
                  data-name="shape-04bc51"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 40 }}
                    data-shape-stroke-color="#ffff00"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 77 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-39-1701700692268">
                          <ellipse
                            rx="38.4"
                            ry="35.26605"
                            cx="38.4"
                            cy="35.26605"
                            fill="rgb(255, 255, 255)"
                            stroke="#ffff00"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <ellipse
                        rx="38.4"
                        ry="35.26605"
                        cx="38.4"
                        cy="35.26605"
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#ffff00"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-39-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 260,
                    height: '70.6261px',
                    left: '456.771px',
                    top: '510.794px'
                  }}
                  data-origin-id="7cd7f5f06a953b14c9c54df0c43f44d0"
                  data-name="shape-6d6ae8"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 39 }}
                    data-shape-stroke-color="#ffff00"
                    data-shape-stroke-width="4px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 260 71"
                    >
                      <defs>
                        <clipPath id="shape-mask-40-1701700692268">
                          <rect
                            width={260}
                            height="70.6261"
                            rx={0}
                            ry={0}
                            fill="rgb(255, 255, 255)"
                            stroke="#ffff00"
                            strokeWidth={8}
                          />
                        </clipPath>
                      </defs>
                      <rect
                        width={260}
                        height="70.6261"
                        rx={0}
                        ry={0}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        stroke="#ffff00"
                        strokeWidth={8}
                        clipPath="url(#shape-mask-40-1701700692268)"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    height: 'auto',
                    width: '217.942px',
                    left: '489.799px',
                    top: '512.061px'
                  }}
                  data-origin-id="60612ffea5256815a4e9bc9bd4d53022"
                  data-name="text-4d8514"
                >
                  <div
                    className="sl-block-style"
                    style={{ zIndex: 41, transform: 'rotate(0deg)' }}
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        zIndex: 41,
                        fontSize: '90%',
                        lineHeight: '1.196'
                      }}
                      data-has-line-height=""
                    >
                      <p style={{ textAlign: 'right' }}>בא והולך.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-39-1701470920022">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-39-1701470920022)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-38-1701470920021">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-38-1701470920021)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-8"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 8)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-67-1701472129859">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-67-1701472129859)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-66-1701472129858">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-66-1701472129858)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-9"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 9)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-69-1701472139179">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-69-1701472139179)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-68-1701472139178">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-68-1701472139178)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-10"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 10)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '-35.6367px',
                top: '558.731px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '747.893px',
                top: '646.792px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '906.213px',
                top: '334.265px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '760.724px',
                top: '25.1969px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-71-1701472149942">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-71-1701472149942)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '24.5571px',
                top: '190.646px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-70-1701472149941">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-70-1701472149941)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '471.895px',
                left: '244.053px',
                top: '95.4595px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 16, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ zIndex: 16, color: 'rgb(106, 168, 79)' }}
                >
                  <p style={{ textAlign: 'center' }}>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>הנחיות</span>
                            </u>
                            <u> </u>
                            <u>
                              <span>מקצועיות</span>
                            </u>
                            <u>
                              <span> – </span>
                            </u>
                            <u>
                              <span>אומדן</span>
                            </u>
                            <u> </u>
                            <u>
                              <span>כאב</span>
                            </u>
                            <u> </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: '180.001px',
                top: '182.5px'
              }}
              data-name="text-f54472"
            >
              <div className="sl-block-content" style={{ zIndex: 17 }}>
                <ul>
                  <li>
                    <p style={{ textAlign: 'justify' }}>
                      איתור כאב בשלביו המוקדמים ועוד בטרם יוגדר בתלונה של
                      המטופל, הוא שלב ראשוני.
                    </p>{' '}
                  </li>
                  <li>
                    <p style={{ textAlign: 'justify' }}>
                      הבאת החולה לנוחות - מהמטרות החשובות בעבודת האחות.
                    </p>
                  </li>
                  <li>
                    <p style={{ textAlign: 'justify' }}>
                      ההנחיה לאומדן כאב כפעולה שגרתית מאפשרת התערבות מכוונת
                      לקראת הבאת החולה לנוחות ורווחה ביישום מטרה זו.
                    </p>
                  </li>
                </ul>
                <p style={{ textAlign: 'justify' }}>&nbsp;</p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-73-1701472221163">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-73-1701472221163)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-72-1701472221162">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-72-1701472221162)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '613.353px',
                left: '173.324px',
                top: '198.5px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ color: '#0000ff' }}>
                    <u>סמכות</u>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  •אומדן כאב למטופלים יבוצע ע"י אחות מוסמכת.
                  <br />
                  &nbsp;
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ display: 'none' }}>&nbsp;</span>
                  <span style={{ color: '#0000ff' }}>
                    <u>
                      פירוט<u> </u>ההנחיה{' '}
                    </u>
                  </span>
                  <span style={{ display: 'none' }}>&nbsp;</span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  •יש להגדיר את אומדן הכאב כמדידה חיונית מחייבת.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  •יש למדוד כאב במקביל למדידה שגרתית של סימנים חיוניים.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-75-1701472270344">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-75-1701472270344)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-74-1701472270342">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-74-1701472270342)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '613.353px',
                left: '173.324px',
                top: '198.5px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'justify' }}>
                  <span style={{ color: '#0000ff' }}>תדירות לאומדן שיגרתי</span>
                  <br />
                  &nbsp;
                </p>
                <p style={{ textAlign: 'justify' }}>
                  • לכל מטופל באשפוז ובמוסד סיעודי יתבצע אומדן כאב, תוך 12 שעות
                  מקבלתו, על פי הצורך ולפחות אחת ביממה.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '666.61px',
                top: '101.783px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '137.043px',
                top: '114.911px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-78-1701472638586">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-78-1701472638586)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-79-1701472638586">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-79-1701472638586)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '613.353px',
                left: '170.324px',
                top: '237.5px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content fragment fade-out"
                style={{ zIndex: 16 }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>מאפייני</span>
                            </u>
                            <u> </u>
                            <u>
                              <span>כלי</span>
                            </u>
                            <u> </u>
                            <u>
                              <span>האומדן</span>
                            </u>
                            <u> </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>מבחין</span> <span>בהקלה</span>{' '}
                            <span>או</span> <span>החמרה</span> <span>של</span>{' '}
                            <span>עוצמת</span> <span>הכאב</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>רגיש</span> <span>לשינויים</span>{' '}
                            <span>בתחושת</span> <span>הכאב</span>{' '}
                            <span>בעקבות</span> <span>תנועה</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>מתאים</span> <span>לביטויים</span>{' '}
                            <span>מילוליים</span> <span>ובלתי</span>{' '}
                            <span>מילוליים</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>&nbsp;</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '613.353px',
                left: '170.324px',
                top: '237.5px'
              }}
              data-name="text-8a0d76"
            >
              <div
                className="sl-block-content fragment"
                style={{ zIndex: 17 }}
                data-fragment-index={0}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <u>
                              <span>תיעוד</span>
                            </u>
                            <u> </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  •אומדן הכאב של המטופל יתועד ברשומות המטופל הממוחשבת.
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •
                            <u>
                              <span>התיעוד</span>
                            </u>
                            <u> </u>
                            <u>
                              <span>כולל</span>
                            </u>
                            <u>
                              <span>: </span>
                            </u>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  •ממצאי האומדן, תאריך, שעה, שם האחות.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-81-1701472668934">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-81-1701472668934)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-80-1701472668934">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-80-1701472668934)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-15"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 15)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
              data-origin-id="5f96c148c924b5c93774e0d9f3e9704a"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(217, 210, 233)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(217, 210, 233)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '456.862px',
                left: '439.186px',
                top: '78.6565px',
                height: 'auto'
              }}
              data-name="text-bad4e5"
              data-origin-id="ed4c49f7336c0dfa693071da7055e18f"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 15,
                  padding: 5,
                  lineHeight: '1.17',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>כאב</span>
                          </span>{' '}
                          <span>
                            <span>כרוני </span>
                          </span>
                          <span>
                            <span>בקשישים</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '832.096px',
                left: '63.952px',
                top: '227.203px',
                height: 'auto'
              }}
              data-name="text-d18e72"
              data-origin-id="189a4db71547b15ad2c3838c85b753df"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  textAlign: 'justify',
                  padding: 5,
                  color: 'rgb(69, 129, 172)'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  כאב כרוני לא מאוזן, במיוחד בקשישים, מוביל לעתים קרובות, לפגיעה
                  ביכולת ביצוע תפקודי היומיום הבסיסיים (רחצה, לבוש וניידות)
                  והמורכבים (בישול, קניות, נהיגה).
                </p>
                <p style={{ textAlign: 'justify' }}>
                  סיבוכים נוספים של כאב לא מאוזן כוללים: דיכאון, הפרעות שינה,
                  בדידות, ירידה תזונתית וליקוי קוגניטיבי.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  כל אלה גורמים לירידה תפקודית ולפגיעה קשה באיכות החיים של האדם
                  המבוגר.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(217, 210, 233)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(217, 210, 233)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '537.569px',
                left: '358.479px',
                top: '78.6565px',
                height: 'auto'
              }}
              data-name="text-bad4e5"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 13,
                  padding: 5,
                  lineHeight: '1.17',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>אומדן</span>
                          </span>{' '}
                          <span>
                            <span>כאב</span>
                          </span>{' '}
                          <span>
                            <span>בגיל</span>
                          </span>{' '}
                          <span>
                            <span>המבוגר</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '832.096px',
                left: '63.952px',
                top: '227.203px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  padding: 5,
                  color: 'rgb(69, 129, 142)'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>הערכת</span> <span>הכאב</span>{' '}
                            <span>חייבת</span> <span>להיות</span>{' '}
                            <span>מלווה</span> <span>בהערכה</span>{' '}
                            <span>קוגניטיבית</span> <span>בסיסית</span>
                            <span>, </span>
                            <span>תוך</span> <span>שימוש</span>{' '}
                            <span>במילים</span> <span>פשוטות</span>{' '}
                            <span>וברורות</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>בכל</span> <span>מקרה</span>
                            <span>, </span>
                            <span>תלונות</span> <span>המטופל</span>{' '}
                            <span>והבדיקה</span> <span>הגופנית</span>{' '}
                            <span>הם</span> <span>שמכוונים</span>{' '}
                            <span>את</span> <span>המשך</span>{' '}
                            <span>הבירור</span> <span>והטיפול</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>גם</span> <span>בהעדר</span> <span>גורם</span>{' '}
                            <span>כאב</span> <span>ברור</span>
                            <span>, </span>
                            <span>כל</span> <span>קשיש</span> <span>זכאי</span>{' '}
                            <span>להקלה</span> <span>על</span>{' '}
                            <span>כאביו</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '12.5707px',
                top: '12.6911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '835.338px',
                top: '597.203px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-0804a7"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 800,
                left: '78.428px',
                top: '44.43px',
                height: 'auto'
              }}
              data-name="text-c29943"
              data-origin-id="07fa5b8629e1ca213f57abd4370cb213"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 12,
                  color: 'rgb(60, 120, 216)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>הסיבות</span>
                          </span>{' '}
                          <span>
                            <span>לכאב</span>
                          </span>{' '}
                          <span>
                            <span>בקשישים</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '806.4px',
                height: '430.602px',
                left: '-171.609px',
                top: '-33.602px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-50129f"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={412}
                  data-natural-height={220}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967566/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '806.4px',
                height: 378,
                left: '304.8px',
                top: 350,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-2c57fb"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={416}
                  data-natural-height={195}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967570/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '829.888px',
                height: '556.311px',
                left: '63.484px',
                top: '119.569px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-efbb91"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-natural-width={969}
                  data-natural-height={705}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967571/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '1245.86px',
                height: '906.534px',
                left: '-356px',
                top: '-103.267px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-da5386"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 10 }}>
                  <img
                    className=""
                    data-natural-width={1487}
                    data-natural-height={1082}
                    data-lazy-loaded=""
                    data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967573/pasted-from-clipboard.png"
                  />
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '800.771px',
                top: '14.7873px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '80.1707px',
                height: '79.1226px',
                left: '833.517px',
                top: '607.252px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 13 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 80 79"
                  >
                    <defs>
                      <clipPath id="shape-mask-82-1701473121037">
                        <ellipse
                          rx="40.08535"
                          ry="39.5613"
                          cx="40.08535"
                          cy="39.5613"
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx="40.08535"
                      ry="39.5613"
                      cx="40.08535"
                      cy="39.5613"
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-82-1701473121037)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '365.621px',
                left: '297.19px',
                top: '56.9443px',
                height: 'auto'
              }}
              data-name="text-c29943"
              data-origin-id="8f204a81d934b632f4596bd2662b6a76"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 26,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h1 style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>הערכת</span> <span>כאב</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
              data-origin-id="049110264b840f9a38c7092cab9b9696"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(20, 96, 206)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgba(20, 96, 206, 0.5)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '764.054px',
                left: '97.9735px',
                top: 170,
                height: 'auto'
              }}
              data-name="text-b21b2c"
              data-origin-id="828871fd7a09c5825842242cb2d72ea0"
            >
              <div
                className="sl-block-content"
                style={{ textAlign: 'center', zIndex: 21, fontSize: '110%' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  •כאב מוגדר כתחושה סובייקטיבית, לא נעימה, מטרידה, לעיתים
                  מאיימת, ושונה מאדם לאדם.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  •הערכת הכאב יכולה להתבצע ע"י המטופל עצמו, ו/או ע"י הערכת הצוות
                  המטפל.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  •הערכת כאב היא חובתו של הצוות הסיעודי והרפואי, ומבוססת על
                  תחושות האדם והרגשתו ועל התבוננות בהתנהגותו והתרשמות מביטויי
                  כאב – הבעות פנים ותנועות גוף.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '365.621px',
                left: '297.19px',
                top: '56.9443px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 12,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>הדרכים</span> <span>העיקריות</span>{' '}
                          <span>להערכת</span> <span>כאב</span>{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(111, 168, 220)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(111, 168, 220)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '764.054px',
                left: '97.9735px',
                top: 128,
                height: 'auto'
              }}
              data-name="text-b21b2c"
            >
              <div
                className="sl-block-content"
                style={{ textAlign: 'center', zIndex: 11, fontSize: '110%' }}
              >
                <p style={{ textAlign: 'right', fontWeight: 600 }}>
                  א. הערכת כאב עצמית:
                </p>
                <p style={{ textAlign: 'right' }}>•להאמין לחולה.</p>
                <p style={{ textAlign: 'right' }}>
                  •אנמנזה של כאב – היסטוריה, משמעות, ניסיון קודם.
                </p>
                <p style={{ textAlign: 'right' }}>
                  •דיון עם החולה על אופי, עוצמה, משך, תדירות הכאב.
                </p>
                <p style={{ textAlign: 'right' }}>
                  •בדיקה גופנית, לבדוק מצב נפשי של החולה.
                </p>
                <p style={{ textAlign: 'right' }}>
                  •תכנון טיפול למניעת הכאב וקביעת מטרות יחד עם החולה ובהתאם
                  לאומדן.
                </p>
                <p style={{ textAlign: 'right' }}>•מעקב אחרי תוצאות הטיפול.</p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '365.621px',
                left: '297.19px',
                top: '56.9443px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 12,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>הדרכים</span> <span>העיקריות</span>{' '}
                          <span>להערכת</span> <span>כאב</span>{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(111, 168, 220)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(111, 168, 220)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '764.054px',
                left: '97.973px',
                top: '136.679px',
                height: 'auto'
              }}
              data-name="text-b21b2c"
            >
              <div
                className="sl-block-content"
                style={{ textAlign: 'center', zIndex: 11, fontSize: '110%' }}
              >
                <p style={{ textAlign: 'right', fontWeight: 600 }}>
                  ב. אומדן והערכת כאב על ידי התבוננות מותאם לאלו שלא מסוגלים
                  לבצע הערכה עצמית:
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '764.054px',
                left: '97.973px',
                top: '281.322px',
                height: 'auto'
              }}
              data-name="text-9db550"
            >
              <div
                className="sl-block-content"
                style={{ textAlign: 'center', zIndex: 13, fontSize: '110%' }}
              >
                <p style={{ textAlign: 'right' }}>
                  •תנועות והבעות הפנים "פרצוף כאוב".
                </p>
                <p style={{ textAlign: 'right' }}>• גוף מתוח ותנועות גפיים.</p>
                <p style={{ textAlign: 'right' }}>
                  •התנהגות כללית. הקשישים יכולים להיראות יותר חולים בזמן כאב,
                  לגלות אי רצון לאכול או לישון ולעתים להביע אי שקט קיצוני.
                </p>
                <p style={{ textAlign: 'right' }}>&nbsp;</p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '800.771px',
                top: '14.7873px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '80.1707px',
                height: '79.1226px',
                left: '833.517px',
                top: '607.252px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 13 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 80 79"
                  >
                    <defs>
                      <clipPath id="shape-mask-83-1701473533129">
                        <ellipse
                          rx="40.08535"
                          ry="39.5613"
                          cx="40.08535"
                          cy="39.5613"
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx="40.08535"
                      ry="39.5613"
                      cx="40.08535"
                      cy="39.5613"
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-83-1701473533129)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 1246,
                height: '906.639px',
                left: '-356px',
                top: '-103px'
              }}
              data-name="image-3c1f36"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <img
                  className=""
                  data-natural-width={1487}
                  data-natural-height={1082}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967581/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-85-1701473608488">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-85-1701473608488)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-84-1701473608488">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-84-1701473608488)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-24"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 24)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-36dca6"
              data-locked="true"
              data-origin-id="f21b2ab418e22a2594717f49b6071db7"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(238, 238, 238)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(238, 238, 238)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '281.138px',
                left: '76.9791px',
                top: '229.946px',
                height: 'auto'
              }}
              data-name="text-d18e72"
              data-origin-id="4910629c4f4bfe34eea50d40bf3a8446"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, textAlign: 'left', lineHeight: '1.69' }}
                data-has-line-height=""
              >
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>:PAIN</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>מיקום</span>
                          <span>&nbsp; - </span>
                          <span>P</span>
                          <span>lace</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>כמות</span>
                          <span> - </span>
                          <span>A</span>
                          <span>mount</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>שילובים</span>
                          <span> - </span>
                          <span>I</span>
                          <span>nteraction</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>משככים</span>
                          <span> - </span>
                          <span>N</span>
                          <span>eutralizer</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '177.372px',
                left: '66.4977px',
                top: '152.979px',
                height: 'auto'
              }}
              data-name="text-c29943"
              data-origin-id="d9ba68aef74d27e1ef505da40ab322fa"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 12,
                  lineHeight: '1.17',
                  padding: 9
                }}
                data-has-line-height=""
              >
                <h2>
                  <strong>PAIN</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="line"
              style={{ width: 'auto', height: 'auto', left: 297, top: '-58px' }}
              data-name="line-0bf2f6"
              data-origin-id="8d7b4c9119fc2d3c3e5c088c307ab016"
            >
              <div
                className="sl-block-content"
                data-line-x1={0}
                data-line-y1={817}
                data-line-x2={322}
                data-line-y2={0}
                data-line-color="#000000"
                data-line-start-type="none"
                data-line-end-type="none"
                style={{ zIndex: 14 }}
                data-line-width="4px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  preserveAspectRatio="xMidYMid"
                  width={322}
                  height={817}
                  viewBox="0 0 322 817"
                >
                  <line
                    stroke="rgba(0,0,0,0)"
                    strokeWidth={15}
                    x1={0}
                    y1={817}
                    x2={322}
                    y2={0}
                  />
                  <line
                    className="line-element"
                    stroke="#000000"
                    strokeWidth={4}
                    x1={0}
                    y1={817}
                    x2={322}
                    y2={0}
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 381,
                left: '504.411px',
                top: '179.5px',
                height: 'auto'
              }}
              data-name="text-0491eb"
              data-origin-id="e6777d7edf482e612ce58bd10bff497b"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  textAlign: 'left',
                  lineHeight: '1.69',
                  color: 'rgb(68, 68, 68)'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <u>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>אופי</span> <span>הכאב</span>
                            <span>:</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </u>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>מיקום</span>
                          <span>, </span>
                          <span>משך</span>
                          <span>, </span>
                          <span>תדירות</span>
                          <span>, </span>
                          <span>עוצמה</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <u>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>איכות</span> <span>חיים</span>
                            <span>:</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </u>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>שינה</span>
                          <span>, </span>
                          <span>מנוחה</span>
                          <span>, </span>
                          <span>תפקוד</span>
                          <span>, </span>
                          <span>מצב-רוח</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <u>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>טיפול</span> <span>קודם</span>{' '}
                            <span>והשפעתו</span>
                            <span>:</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </u>
                </p>
                <p style={{ textAlign: 'right' }}>
                  •מינון, דרך מתן, יעילות, תופעות לוואי.
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-3b4d47"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '458.001px 50.6912px 0px',
                  zIndex: 16
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '278.283px',
                    left: '284.79px',
                    top: '20.6912px',
                    height: 'auto'
                  }}
                  data-name="text-f7b4ca"
                  data-origin-id="d5591170ea486fe8e498d650e227cf66"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 17,
                      color: 'rgb(0, 0, 255)',
                      lineHeight: '1.17',
                      padding: 9,
                      fontSize: '120%'
                    }}
                    data-has-line-height=""
                  >
                    <p style={{ textAlign: 'left' }}>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>שיטות</span> <span>לאומדן</span>{' '}
                              <span>כאב</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 54,
                    height: 54,
                    left: '577.211px',
                    top: '20.6912px'
                  }}
                  data-name="shape-c95d75"
                  data-origin-id="c92e1ac7efb5301f0a53f866de94ee37"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="symbol-minus-circle"
                    data-shape-fill-color="rgb(0, 0, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 18 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(0, 0, 255)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-16v-4h16v4z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 180,
                left: '243.175px',
                top: '-29.7516px',
                height: 'auto'
              }}
              data-name="text-1440c2"
              data-origin-id="cc9cfc9972ef3f9a271942cd471ae89e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 11,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px -4px 4px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'left',
                    zIndex: 11,
                    fontSize: '500%',
                    color: 'rgb(255, 255, 255)'
                  }}
                >
                  <h1>〞</h1>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '742.352px',
                left: '108.824px',
                top: '141.572px',
                height: 'auto'
              }}
              data-name="text-d18e72"
              data-origin-id="0224727da356834f678dd8c11386303c"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, textAlign: 'left', fontSize: '110%' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>מחקרים</span> <span>הראו</span>{' '}
                          <span>שהערכת</span> <span>כאב</span>{' '}
                          <span>באמצעות</span> <span>סרגלי</span>{' '}
                          <span>הערכה</span>
                          <span>, </span>
                          <span>מאפשרת</span> <span>לאמוד</span>{' '}
                          <span>נכון</span> <span>את</span> <span>דרגת</span>{' '}
                          <span>הכאב</span> <span>שחווה</span>{' '}
                          <span>המטופל</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>לעיתים</span> <span>הערכת</span>{' '}
                          <span>הכאב</span> <span>אותה</span> <span>יביע</span>{' '}
                          <span>המטופל</span>
                          <span>, </span>
                          <span>תהיה</span> <span>בדרגה</span>{' '}
                          <span>גבוהה</span> <span>מזו</span>{' '}
                          <span>ה"נראית</span> <span>לעין</span>
                          <span>" </span>
                          <span>על</span> <span>ידי</span> <span>הצוות</span>
                          <span>. </span>
                          <span>תופעה</span> <span>זו</span> <span>מקורה</span>{' '}
                          <span>לעיתים</span> <span>בחרדה</span>{' '}
                          <span>שבה</span> <span>נמצא</span> <span>האדם</span>
                          <span>. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>במקרים</span> <span>בהם</span>{' '}
                          <span>קיים</span> <span>פער</span> <span>בין</span>{' '}
                          <span>הכאב</span> <span>אותו</span> <span>הביע</span>{' '}
                          <span>המטופל</span>
                          <span>, </span>
                          <span>באמצעות</span> <span>הסרגל</span>
                          <span>, </span>
                          <span>לבין</span> <span>הכאב</span>{' '}
                          <span>המוערך</span> <span>על</span> <span>ידי</span>{' '}
                          <span>הצוות</span> <span>המטפל</span>
                          <span>, </span>
                          <span>מומלץ</span> <span>לחזור</span> <span>על</span>{' '}
                          <span>המדידה</span> <span>מספר</span>{' '}
                          <span>דקות</span> <span>לאחר</span>{' '}
                          <span>המדידה</span> <span>הראשונה</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>&nbsp;</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
              data-origin-id="1f7fa4c3571b40ead00cbbe77ffa61a1"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(242, 237, 232)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(242, 237, 232)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '229.336px',
                left: '365.332px',
                top: '57.9838px'
              }}
              data-name="text-3fb333"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  color: 'rgb(168, 39, 107)',
                  fontSize: '130%'
                }}
              >
                <p>
                  <strong>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>סרגלי</span> <span>הערכה</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '500.804px',
                left: '229.598px',
                top: '113.248px'
              }}
              data-name="text-3fb333"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(168, 39, 107)',
                  fontSize: '130%'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>אומדן</span> <span>הכאב</span> <span>אצל</span>{' '}
                          <span>קשישים</span> <span>עם</span>{' '}
                          <span>קוגניציה</span> <span>שמורה</span>{' '}
                          <span>או</span> <span>דמנציה</span> <span>קלה</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '293.845px',
                height: '85.8425px',
                left: '446.46px',
                top: '277.029px'
              }}
              data-name="image-625a72"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={356}
                  data-natural-height={104}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967587/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 294,
                height: '85.0618px',
                left: '176.373px',
                top: '381.747px'
              }}
              data-name="image-2d3788"
            >
              <div
                className="sl-block-content fragment fade-left"
                style={{ zIndex: 13 }}
                data-fragment-index={0}
              >
                <img
                  className=""
                  data-natural-width={356}
                  data-natural-height={103}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967588/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 294,
                height: '92.4944px',
                left: '452.555px',
                top: '485.809px'
              }}
              data-name="image-d75d80"
            >
              <div
                className="sl-block-content fragment fade-left"
                style={{ zIndex: 14 }}
                data-fragment-index={1}
              >
                <img
                  className=""
                  data-natural-width={356}
                  data-natural-height={112}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967589/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '500.804px',
                left: '229.598px',
                top: '113.248px'
              }}
              data-name="text-3fb333"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  color: 'rgb(168, 39, 107)',
                  fontSize: '130%'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>P A I N A D SCALE</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: '193.626px',
                top: '197.387px'
              }}
              data-name="text-8c3476"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <p>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>כלי</span>
                          </span>{' '}
                          <span>
                            <span>לאומדן</span>
                          </span>{' '}
                          <span>
                            <span>כאב</span>
                          </span>{' '}
                          <span>
                            <span>אצל</span>
                          </span>{' '}
                          <span>
                            <span>קשישים</span>
                          </span>{' '}
                          <span>
                            <span>דמנטיים</span>
                          </span>
                          <span>
                            <span>, </span>
                          </span>
                          <span>
                            <span>שיהיה</span>
                          </span>
                          <span>
                            <span>:</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 294,
                height: '85.252px',
                left: '530.144px',
                top: '316.374px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-1c2884"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={369}
                  data-natural-height={107}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967592/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 294,
                height: '85.0618px',
                left: '340.17px',
                top: '431.885px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-71a54d"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={356}
                  data-natural-height={103}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967593/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 294,
                height: '85.0618px',
                left: '135.856px',
                top: '316.564px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-afce55"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={356}
                  data-natural-height={103}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967595/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
              data-origin-id="2a5b207ca9529431681d5611fa3d47ea"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(20, 96, 206)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(20, 96, 206)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '652.633px',
                height: '647.97px',
                left: '153.684px',
                top: '26.015px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-221862"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-natural-width={764}
                  data-natural-height={1095}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967597/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-87-1701474318468">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-87-1701474318468)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-86-1701474318467">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-86-1701474318467)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '746.584px',
                height: 518,
                left: '106.708px',
                top: 91
              }}
              data-name="image-305e69"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <img
                  className=""
                  data-natural-width={810}
                  data-natural-height={562}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967599/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-89-1701474355718">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-89-1701474355718)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-88-1701474355717">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-88-1701474355717)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-31"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 31)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-91-1701474362408">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-91-1701474362408)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-90-1701474362407">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-90-1701474362407)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-32"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 32)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(217, 210, 233)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(217, 210, 233)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '403.407px',
                left: '463.293px',
                top: '88.5005px',
                height: 'auto'
              }}
              data-name="text-bad4e5"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 11,
                  padding: 5,
                  lineHeight: '1.17',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
              >
                <h3 style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>גישת</span> <span>הטיפול</span>{' '}
                          <span>בכאב</span> <span>כרוני</span>{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </h3>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '832.096px',
                left: '47.2449px',
                top: '158.499px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'left',
                  padding: 5,
                  color: 'rgb(12, 52, 61)'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>כאב</span> <span>כרוני</span>
                            <span> = </span>
                            <span>מחלה</span> <span>כרונית</span>
                            <span> - </span>
                            <span>טיפול</span> <span>מניעתי</span>{' '}
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>התאמת</span> <span>הטיפול</span>{' '}
                            <span>לחולה</span>
                            <span>, </span>
                            <span>על</span> <span>פי</span> <span>הסולם</span>{' '}
                            <span>האנלגטי</span> <span>של</span>{' '}
                            <span>ארגון</span> <span>הבריאות</span>{' '}
                            <span>העולמי</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>טיפול</span> <span>בכאב</span>{' '}
                            <span>במקביל</span> <span>לבירור</span>{' '}
                            <span>מקור</span> <span>הכאב</span>
                            <span> (</span>
                            <span>יתכן</span> <span>שלחולה</span>{' '}
                            <span>יותר</span> <span>מסוג</span> <span>כאב</span>{' '}
                            <span>אחד</span>
                            <span>).</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>תכשיר</span> <span>ארוך</span>{' '}
                            <span>טווח</span>
                            <span> + SOS + </span>
                            <span>שילובים</span> <span>תרופתיים</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>אומדן</span> <span>כאב</span>{' '}
                            <span>בתדירות-פעיל</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>טיפול</span> <span>לאורך</span>{' '}
                            <span>זמן</span> <span>ובהתאם</span>{' '}
                            <span>לנדרש</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>דיווח</span>
                            <span>, </span>
                            <span>רישום</span> <span>ומעקב</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>עבודת</span> <span>צוות</span>{' '}
                            <span>רב</span> <span>תחומי</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 4px) opacity(100%)'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#c9daf8"
                  data-shape-stroke-width="19px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 1002 1002"
                  >
                    <defs>
                      <clipPath id="shape-mask-92-1701474486606">
                        <ellipse
                          rx={501}
                          ry={501}
                          cx={501}
                          cy={501}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#c9daf8"
                          strokeWidth={38}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={501}
                      ry={501}
                      cx={501}
                      cy={501}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#c9daf8"
                      strokeWidth={38}
                      clipPath="url(#shape-mask-92-1701474486606)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '454.766px',
                left: '390.172px',
                top: '63.3452px',
                height: 'auto'
              }}
              data-name="text-bad4e5"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 11,
                  padding: 5,
                  lineHeight: '1.17',
                  color: 'rgb(0, 0, 255)'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'center' }}>
                  מעלית הטיפול התרופתי בנוגדי כאב
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '702.623px',
                height: 518,
                left: '142.315px',
                top: '130.829px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-34f5a1"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={666}
                  data-natural-height={491}
                  data-lazy-loaded=""
                  data-src={painLift}
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-94-1701474564580">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-94-1701474564580)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-93-1701474564579">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-93-1701474564579)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '415.296px',
                left: '434.403px',
                top: '54.582px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(255, 153, 0)' }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>תרופתי</span>{' '}
                          <span>באמצעות</span> <span>סרגל</span>{' '}
                          <span>חישה:</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '806.4px',
                height: '470.542px',
                left: '76.8px',
                top: '145.496px'
              }}
              data-name="image-298caa"
            >
              <div className="sl-block-content" style={{ zIndex: 17 }}>
                <img
                  className=""
                  data-natural-width={946}
                  data-natural-height={552}
                  data-lazy-loaded=""
                  data-src={painRuler}
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 15 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 120, height: 120, left: '-25.6744px', top: 350 }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 13 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-96-1701474629330">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-96-1701474629330)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 17,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 17 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-95-1701474629329">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-95-1701474629329)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '415.296px',
                left: '447.24px',
                top: '62.5698px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 18, color: 'rgb(255, 153, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>כאב</span> <span>נוציספטיבי</span>
                            <span> – </span>
                            <span>מקור</span> <span>וביטוי</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.982px',
                left: '116.554px',
                top: '131.73px'
              }}
              data-name="text-708e8d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 19, color: 'rgb(76, 17, 48)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>Nociceptive Pain</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>Nociceptors - </span>
                            <span>חיישני</span> <span>עצב</span> <span>תת</span>{' '}
                            <span>עוריים</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>Nociception </span>
                            <span>&nbsp;- </span>
                            <span>תחושת</span> <span>כאב</span>{' '}
                            <span>שנגרמת</span> <span>כתוצאה</span>{' '}
                            <span>מ</span>
                            <span>הרס</span> <span>רקמות</span>
                            <span> / </span>
                            <span>דלקת</span>
                            <span>, </span>
                            <span>גירוי</span> <span>קצות</span>{' '}
                            <span>העצבים</span> <span>ויצירת</span>{' '}
                            <span>סיגנלים</span> <span>של</span>{' '}
                            <span>כאב</span>
                            <span>, </span>
                            <span>באמצעות</span> <span>הנוציספטורים</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>אופי</span> <span>הכאב</span>
                            <span> - </span>
                            <span>פועם</span>
                            <span>, </span>
                            <span>חד</span>
                            <span>, </span>
                            <span>ממוקם</span> <span>היטב</span>
                            <span>, </span>
                            <span>מתגבר</span> <span>בתנועה</span>{' '}
                            <span>ופעילות</span>
                            <span>.</span>
                            <br />
                            <span>לרוב</span>
                            <span>, </span>
                            <span>מגיב</span> <span>לטיפול</span>{' '}
                            <span>תרופתי</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-e09358"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '101.127px 519.517px 0px',
                  zIndex: 10
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: '132.059px',
                    height: '213.033px',
                    left: '35.0976px',
                    top: 413,
                    minWidth: 1,
                    minHeight: 1
                  }}
                  data-name="image-cd7936"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 11 }}
                    data-maintain-ar="false"
                  >
                    <img
                      className=""
                      data-natural-width={98}
                      data-natural-height={230}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: '107.665px',
                    height: '173.944px',
                    left: '46.9272px',
                    top: '432.545px'
                  }}
                  data-name="image-03a654"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 12 }}
                    data-maintain-ar="false"
                  >
                    <img
                      className=""
                      data-natural-width={136}
                      data-natural-height={179}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967602/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 120, height: 120, left: '-25.6744px', top: 350 }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-98-1701476044885">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-98-1701476044885)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 14,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 14 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-97-1701476044885">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-97-1701476044885)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '243.402px',
                left: '619.134px',
                top: '53.73px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(153, 0, 255)' }}
              >
                <p style={{ textAlign: 'center' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>כאב</span> <span>פנימי</span>
                          <span>
                            {' '}
                            - ויסצרלי
                            <br />
                            <span>VISCERAL PAIN </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.982px',
                left: '116.554px',
                top: '163.174px'
              }}
              data-name="text-708e8d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(76, 17, 48)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>כאב</span> <span>פנימי</span> <span>עמוק</span>
                          <span>, </span>
                          <span>לוחץ</span>
                          <span>, </span>
                          <span>התכווצות</span>
                          <span>, </span>
                          <span>לעיתים</span> <span>קרובות</span>{' '}
                          <span>מלווה</span> <span>בבחילות</span>
                          <span>, </span>
                          <span>הקאות</span> <span>והזעה</span>
                          <span> (</span>
                          <span>חסימת</span> <span>מעיים</span>
                          <span>, </span>
                          <span>אוטם</span> <span>שריר</span> <span>הלב</span>
                          <span>).</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>הפעלת</span> <span>קולטנים</span>{' '}
                          <span>נוציספטיבים</span> <span>כתוצאה</span>{' '}
                          <span>מהסננה</span>
                          <span>, </span>
                          <span>לחץ</span>
                          <span>, </span>
                          <span>התרחבות</span> <span>או</span>{' '}
                          <span>מתיחה</span> <span>באיברים</span>{' '}
                          <span>פנימיים</span>
                          <span>, </span>
                          <span>בבית</span> <span>החזה</span>
                          <span>, </span>
                          <span>בבטן</span> <span>או</span> <span>באגן</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>מופיע</span> <span>במערכת</span>{' '}
                          <span>העיכול</span>
                          <span>, </span>
                          <span>כבד</span>
                          <span>, </span>
                          <span>דרכי</span> <span>השתן</span>
                          <span>, </span>
                          <span>בקרום</span> <span>הריאה</span>{' '}
                          <span>ועוד</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>לא</span> <span>תמיד</span>{' '}
                            <span>ממוקם</span> <span>היטב</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-42b59d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '101.127px 519.517px 0px',
                  zIndex: 17
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e09358"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '101.127px 519.517px 0px',
                      zIndex: 18
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="image"
                      style={{
                        width: '132.059px',
                        height: '213.033px',
                        left: '35.0976px',
                        top: 413,
                        minWidth: 1,
                        minHeight: 1
                      }}
                      data-name="image-cd7936"
                    >
                      <div
                        className="sl-block-content"
                        style={{ zIndex: 19 }}
                        data-maintain-ar="false"
                      >
                        <img
                          className=""
                          data-natural-width={98}
                          data-natural-height={230}
                          data-lazy-loaded=""
                          data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="image"
                  style={{
                    width: 108,
                    height: 174,
                    left: '47.1271px',
                    top: '432.517px'
                  }}
                  data-name="image-deb15a"
                >
                  <div
                    className="sl-block-content"
                    style={{ zIndex: 20 }}
                    data-maintain-ar="false"
                  >
                    <img
                      className=""
                      data-natural-width={159}
                      data-natural-height={148}
                      data-lazy-loaded=""
                      data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967608/pasted-from-clipboard.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 120, height: 120, left: '-25.6744px', top: 350 }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-100-1701476131581">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-100-1701476131581)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 14,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 14 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-99-1701476131579">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-99-1701476131579)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '243.402px',
                left: '619.134px',
                top: '53.73px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(153, 0, 255)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>כאב</span> <span>איסכמי</span>
                        </span>
                      </span>
                    </span>
                    <br />
                    Ischemic pain
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.982px',
                left: '116.554px',
                top: '163.174px'
              }}
              data-name="text-708e8d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(76, 17, 48)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>מתפתח</span> <span>עקב</span>{' '}
                            <span>חסימת</span> <span>כלי</span> <span>דם.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>כתוצאה</span> <span>מחסימת</span>{' '}
                            <span>ניקוז</span> <span>ורידי</span>{' '}
                            <span>או</span> <span>לימפתי</span>{' '}
                            <span>יתפתחו</span> <span>גודש</span>{' '}
                            <span>ובצקת</span> <span>בגפה</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  ￭ הגפה תהיה קרה, נפוחה וכואבת.
                </p>
                <p style={{ textAlign: 'right' }}>￭ הכאב מתגבר בתנועה.</p>
                <p style={{ textAlign: 'right' }}>
                  ￭ אחד הכאבים החזקים ביותר והקשים ביותר לטיפול.
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-42b59d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '101.127px 519.517px 0px',
                  zIndex: 17
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e09358"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '101.127px 519.517px 0px',
                      zIndex: 18
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="image"
                      style={{
                        width: '132.059px',
                        height: '213.033px',
                        left: '35.0976px',
                        top: 413,
                        minWidth: 1,
                        minHeight: 1
                      }}
                      data-name="image-cd7936"
                    >
                      <div
                        className="sl-block-content"
                        style={{ zIndex: 19 }}
                        data-maintain-ar="false"
                      >
                        <img
                          className=""
                          data-natural-width={98}
                          data-natural-height={230}
                          data-lazy-loaded=""
                          data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: 108,
                height: 174,
                left: '47.1271px',
                top: '432.517px'
              }}
              data-name="image-5d7ffa"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 21 }}
                data-maintain-ar="false"
              >
                <img
                  className=""
                  data-natural-width={139}
                  data-natural-height={261}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967609/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '79.3923px',
                top: '482.144px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-102-1701476809231">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-102-1701476809231)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 14,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 14 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-101-1701476809230">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-101-1701476809230)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '319.916px',
                left: '542.62px',
                top: '53.73px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(153, 0, 255)' }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>כאב</span> <span>נוריופתי</span>
                          <span>- </span>
                          <span>מקור</span> <span>וביטוי</span>
                        </span>
                      </span>
                    </span>
                    <br />
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>NEUROPATHIC PAIN</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.982px',
                left: '116.554px',
                top: 170
              }}
              data-name="text-708e8d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(76, 17, 48)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>פגיעה</span> <span>ישירה</span>{' '}
                            <span>במערכת</span> <span>העצבים</span>{' '}
                            <span>המרכזית</span>
                            <span>, </span>
                            <span>בעצבים</span> <span>הפריפריים</span>{' '}
                            <span>או</span> <span>בחוט</span> <span>השדרה</span>
                            <span>, </span>
                            <span>או</span> <span>כתוצאה</span>{' '}
                            <span>מהסננה</span>
                            <span>, </span>
                            <span>לחץ</span> <span>או</span> <span>פגיעה</span>{' '}
                            <span>משנית</span>
                            <span>, </span>
                            <span>מטיפול</span> <span>כימותרפי</span>{' '}
                            <span>או</span> <span>לאחר</span>{' '}
                            <span>טיפולי</span> <span>קרינה</span>
                            <span>, </span>
                            <span>נוירופתיה</span> <span>פריפרית</span>
                            <span> (</span>
                            <span>בסוכרת</span>
                            <span>) </span>
                            <span>וכאב</span> <span>מתמשך</span>{' '}
                            <span>לאחר</span> <span>שלבקת</span>{' '}
                            <span>חוגרת</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>מאופיין</span> <span>ככאב</span>{' '}
                            <span>חד</span>
                            <span>, </span>
                            <span>שורף</span>
                            <span>, </span>
                            <span>תחושת</span>
                            <span> "</span>
                            <span>זרמים</span>
                            <span>", </span>
                            <span>נימול</span>&nbsp; <span>ותחושת</span>
                            <span> "</span>
                            <span>רדימות</span>
                            <span>".</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>לא</span> <span>תמיד</span>{' '}
                            <span>ממוקם</span> <span>היטב</span>
                            <span>, </span>
                            <span>לעיתים</span> <span>מוקרן</span>{' '}
                            <span>לאזורים</span> <span>אחרים</span>{' '}
                            <span>בגוף</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-42b59d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '13.3628px 616.993px 0px',
                  zIndex: 17
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e09358"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '34.3256px 274.251px 0px',
                      zIndex: 20
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="image"
                      style={{
                        width: '132.059px',
                        height: '213.033px',
                        left: '-52.6667px',
                        top: '510.476px',
                        minWidth: 1,
                        minHeight: 1
                      }}
                      data-name="image-cd7936"
                    >
                      <div
                        className="sl-block-content"
                        style={{ zIndex: 22 }}
                        data-maintain-ar="false"
                      >
                        <img
                          className=""
                          data-natural-width={98}
                          data-natural-height={230}
                          data-lazy-loaded=""
                          data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '79.3923px',
                top: '482.144px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-104-1701476862363">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-104-1701476862363)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 14,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 14 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-103-1701476862362">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-103-1701476862362)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '319.916px',
                left: '542.62px',
                top: '53.73px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(0, 0, 255)' }}
              >
                <p style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>כאב</span> <span>גרמי</span>
                          <span> – (</span>
                          <span>עצמות</span> <span>השלד</span>
                          <span>)</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.982px',
                left: '116.554px',
                top: '206.382px'
              }}
              data-name="text-708e8d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <u>מאפייני הכאב-</u>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>לרוב</span>
                            <span>, </span>
                            <span>נגרם</span> <span>מנזק</span>{' '}
                            <span>לרקמת</span> <span>עצם</span>
                            <span> - </span>
                            <span>חדירת</span> <span>גידול</span>{' '}
                            <span>מעלה</span> <span>את</span> <span>הלחץ</span>{' '}
                            <span>על</span> <span>העצם</span>{' '}
                            <span>וגורמת</span> <span>להרס</span>{' '}
                            <span>הרקמה</span> <span>ולשחרור</span>{' '}
                            <span>נוירוטרנסמיטרים</span>
                            <span> - </span>
                            <span>מתווכים</span> <span>כימיים</span>{' '}
                            <span>(</span>
                            <span>פרוסטגלנדינים</span>
                            <span>).</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-42b59d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '13.3628px 616.993px 0px',
                  zIndex: 17
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e09358"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '13.3628px 616.993px 0px',
                      zIndex: 18
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="image"
                      style={{
                        width: '132.059px',
                        height: '213.033px',
                        left: '-52.6667px',
                        top: '510.476px',
                        minWidth: 1,
                        minHeight: 1
                      }}
                      data-name="image-cd7936"
                    >
                      <div
                        className="sl-block-content"
                        style={{ zIndex: 19 }}
                        data-maintain-ar="false"
                      >
                        <img
                          className=""
                          data-natural-width={98}
                          data-natural-height={230}
                          data-lazy-loaded=""
                          data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '448.837px',
                left: '404.154px',
                top: '109.404px'
              }}
              data-name="text-6e4d59"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 20, color: 'rgb(0, 0, 255)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>גורם</span>
                          </span>{' '}
                          <span>
                            <span>שכיח</span>
                          </span>{' '}
                          <span>
                            <span>לכאב</span>
                          </span>{' '}
                          <span>
                            <span>קשה</span>
                          </span>{' '}
                          <span>
                            <span>אצל</span>
                          </span>{' '}
                          <span>
                            <span>חולי</span>
                          </span>{' '}
                          <span>
                            <span>סרטן</span>
                          </span>
                          <span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '79.3923px',
                top: '482.144px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-106-1701476942321">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-106-1701476942321)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 14,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 14 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-105-1701476942320">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-105-1701476942320)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '319.916px',
                left: '542.62px',
                top: '53.73px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(0, 0, 255)' }}
              >
                <p style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>כאב</span> <span>גרמי</span>
                          <span> – (</span>
                          <span>עצמות</span> <span>השלד</span>
                          <span>)</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.982px',
                left: '116.554px',
                top: '206.382px'
              }}
              data-name="text-708e8d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>&nbsp;</p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>לעיתים</span>
                            <span>, </span>
                            <span>נובע</span> <span>הכאב</span>{' '}
                            <span>משבר</span> <span>בעצם</span>
                            <span>, </span>
                            <span>עקב</span> <span>דילול</span>{' '}
                            <span>רקמת</span> <span>העצם</span>{' '}
                            <span>והיחלשות</span> <span>המבנה</span>{' '}
                            <span>הגרמי</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>הכאב</span> <span>בד"כ</span>{' '}
                            <span>ממוקם</span>
                            <span>, </span>
                            <span>אך</span> <span>יכול</span>{' '}
                            <span>להופיע</span> <span>גם</span>{' '}
                            <span>מעבר</span> <span>לאזור</span>{' '}
                            <span>הפגוע</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>מתגבר</span> <span>בתנועה</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>לעיתים</span> <span>ישנה</span>{' '}
                            <span>הקלה</span> <span>במנוחה</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-42b59d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '13.3628px 616.993px 0px',
                  zIndex: 17
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e09358"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '13.3628px 616.993px 0px',
                      zIndex: 20
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="image"
                      style={{
                        width: '132.059px',
                        height: '213.033px',
                        left: '-52.6667px',
                        top: '510.476px',
                        minWidth: 1,
                        minHeight: 1
                      }}
                      data-name="image-cd7936"
                    >
                      <div
                        className="sl-block-content"
                        style={{ zIndex: 22 }}
                        data-maintain-ar="false"
                      >
                        <img
                          className=""
                          data-natural-width={98}
                          data-natural-height={230}
                          data-lazy-loaded=""
                          data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '448.837px',
                left: '404.154px',
                top: '109.404px'
              }}
              data-name="text-6e4d59"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 23, color: 'rgb(0, 0, 255)' }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>גורם</span>
                          </span>{' '}
                          <span>
                            <span>שכיח</span>
                          </span>{' '}
                          <span>
                            <span>לכאב</span>
                          </span>{' '}
                          <span>
                            <span>קשה</span>
                          </span>{' '}
                          <span>
                            <span>אצל</span>
                          </span>{' '}
                          <span>
                            <span>חולי</span>
                          </span>{' '}
                          <span>
                            <span>סרטן</span>
                          </span>
                          <span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '862.536px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '79.3923px',
                top: '482.144px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-108-1701476954560">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-108-1701476954560)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 14,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 14 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-107-1701476954560">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-107-1701476954560)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '319.916px',
                left: '542.62px',
                top: '65.6969px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, color: 'rgb(0, 0, 255)' }}
              >
                <p style={{ textAlign: 'right' }}>כאב פנטום:</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '745.982px',
                left: '116.554px',
                top: '147.5px'
              }}
              data-name="text-708e8d"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'justify' }}>•מופיע לרוב אחרי ניתוח.</p>
                <p style={{ textAlign: 'justify' }}>
                  •אופייני לאחר כריתת איבר ויכול להימשך שנים.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  •לאחר כריתת גפה - לאחר כריתת שד - נימול, רגישות ולעיתים אובדן
                  תחושה.
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-42b59d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '13.3628px 616.993px 0px',
                  zIndex: 17
                }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-e09358"
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '13.3628px 616.993px 0px',
                      zIndex: 20
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="image"
                      style={{
                        width: '132.059px',
                        height: '213.033px',
                        left: '-52.6667px',
                        top: '510.476px',
                        minWidth: 1,
                        minHeight: 1
                      }}
                      data-name="image-cd7936"
                    >
                      <div
                        className="sl-block-content"
                        style={{ zIndex: 22 }}
                        data-maintain-ar="false"
                      >
                        <img
                          className=""
                          data-natural-width={98}
                          data-natural-height={230}
                          data-lazy-loaded=""
                          data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-110-1701476982996">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-110-1701476982996)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-109-1701476982996">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-109-1701476982996)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-43"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 43)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 286,
                left: 337,
                top: '30.8042px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 11,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>בכאב</span>
                          <span> </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '764.755px 171.745px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 160,
                    left: '687.755px',
                    top: '181.178px',
                    height: 'auto'
                  }}
                  data-name="text-96a5c5"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 14,
                      textAlign: 'center',
                      fontSize: '80%',
                      color: '#ff0000'
                    }}
                  >
                    {/* <p style={{ color: '#ff0000' }}> */}
                    <u>כאב קל – תרופות לא אופידיאדיות</u>
                    {/* </p> */}
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 192,
                    left: '669.755px',
                    top: '91.1299px',
                    height: 'auto'
                  }}
                  data-name="text-b45c7e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 15,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <h2>
                      <strong>
                        <span style={{ fontSize: '0.5em' }}>שלב </span>1
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 194,
                    height: 194,
                    left: '667.755px',
                    top: '74.7451px'
                  }}
                  data-name="shape-436f64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 194 194"
                    >
                      <ellipse
                        rx={97}
                        ry={97}
                        cx={97}
                        cy={97}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(20, 96, 206)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(20, 96, 206)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '507.764px',
                left: '129.969px',
                top: 148
              }}
              data-name="text-bde2cf"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  color: 'rgb(238, 238, 238)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>Paracetamol, Dipyrone</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>נוגדי</span> <span>דלקת</span> <span>לא</span>{' '}
                            <span>סטרואידיים</span>
                            <span> - NSAIDs </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '804.387px',
                left: '93.0044px',
                top: '277.13px'
              }}
              data-name="text-d5f115"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 17,
                  color: 'rgb(255, 255, 255)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>פועלים</span> <span>בפריפריה</span>{' '}
                            <span>באזור</span> <span>הגירוי</span>{' '}
                            <span>המכאיב</span>
                            <span>. </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>לא</span> <span>משפיעים</span>{' '}
                            <span>על</span> <span>רמת</span> <span>ההכרה</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>בעלי</span> <span>אפקט</span>{' '}
                            <span>תקרה</span>
                            <span> (Ceiling Effect) - </span>
                            <span>בטוחים</span> <span>במינון</span>{' '}
                            <span>נמוך</span>
                            <span>, </span>
                            <span>אך</span> <span>במינון</span>{' '}
                            <span>גבוה</span>
                            <span>, </span>
                            <span>עלולים</span> <span>לגרום</span>{' '}
                            <span>לנזק</span> <span>בלתי</span>{' '}
                            <span>הפיך</span> <span>בכבד</span>
                            <span> (Paracetamol) </span>
                            <span>או</span> <span>בכליות</span>
                            <span> (NSAIDs).</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>בעלי</span> <span>תכונות</span>{' '}
                            <span>אנטיפירטיות</span>
                            <span> (</span>
                            <span>מורידי</span> <span>חום</span>
                            <span>) </span>
                            <span>ואנטי</span> <span>אינפלמטוריות</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>אינם</span> <span>גורמים</span>{' '}
                            <span>לסבילות</span> <span>או</span>{' '}
                            <span>לתלות</span> <span>פיזית</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 286,
                left: 337,
                top: '30.8042px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 11,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>בכאב</span>
                          <span> </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '759.68px 186.911px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '80.8972px',
                    left: '719.231px',
                    top: '148.055px',
                    height: 'auto'
                  }}
                  data-name="text-b45c7e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 18,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%',
                      lineHeight: '0.325'
                    }}
                    data-has-line-height=""
                  >
                    <h2>
                      <strong>
                        <span style={{ fontSize: '0.5em' }}>
                          <span style={{ fontSize: '1.0em' }}>1</span>
                          <br />
                          שלב
                        </span>
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 100,
                    height: 100,
                    left: '709.68px',
                    top: '136.911px'
                  }}
                  data-name="shape-436f64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 100 100"
                    >
                      <ellipse
                        rx={50}
                        ry={50}
                        cx={50}
                        cy={50}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(20, 96, 206)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(20, 96, 206)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '507.764px',
                left: '164.558px',
                top: '118.411px'
              }}
              data-name="text-bde2cf"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 19,
                  color: 'rgb(238, 238, 238)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>אקמול</span>
                          </span>
                          <span>
                            <span> – ACETAMINOPHEN -PARACETAMOL</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>אופטלגין</span>
                          </span>
                          <span>
                            <span>&nbsp; - DYPIRONE</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '804.387px',
                left: '57.368px',
                top: '290.756px'
              }}
              data-name="text-d5f115"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 20,
                  color: 'rgb(255, 255, 255)',
                  fontSize: '110%',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  • לכאבים וחום, לא נוגדי דלקת.
                </p>
                <p style={{ textAlign: 'right' }}>
                  • קיימים בכדורים, סירופ, נרות, IV.
                </p>
                <p style={{ textAlign: 'right' }}>
                  • אין פגיעה בכליות, במערכת העיכול, קרישה.
                </p>
                <p style={{ textAlign: 'right' }}>
                  • ספיגה – מצוינת דרך הפה, רקטלית - חלקית.
                </p>
                <p style={{ textAlign: 'right' }}>• משך פעילות – 4-6 שעות.</p>
                <p style={{ textAlign: 'right' }}>
                  • מטבוליזם - מפורק בכבד, מופרש בשתן.
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span
                    style={{
                      fontWeight: 600
                    }}
                  >
                    המלצה
                  </span>{' '}
                  - לא לעבור מינון של 4 גרם/יממה למבוגר בטיפול כרוני
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 286,
                left: 337,
                top: '30.8042px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 11,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>בכאב</span>
                          <span> </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '759.68px 186.911px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '80.8972px',
                    left: '719.231px',
                    top: '148.055px',
                    height: 'auto'
                  }}
                  data-name="text-b45c7e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 16,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%',
                      lineHeight: '0.325'
                    }}
                    data-has-line-height=""
                  >
                    <h2>
                      <strong>
                        <span style={{ fontSize: '0.5em' }}>
                          <span style={{ fontSize: '1.0em' }}>1</span>
                          <br />
                          שלב
                        </span>
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 100,
                    height: 100,
                    left: '709.68px',
                    top: '136.911px'
                  }}
                  data-name="shape-436f64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 15 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 100 100"
                    >
                      <ellipse
                        rx={50}
                        ry={50}
                        cx={50}
                        cy={50}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(20, 96, 206)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(20, 96, 206)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '412.383px',
                left: '262.035px',
                top: '139.911px'
              }}
              data-name="text-bde2cf"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 17,
                  color: 'rgb(238, 238, 238)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>NSAIDS - Non Steroidal </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>Anti Inflammatory Drugs</span>{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '804.387px',
                left: '57.368px',
                top: '290.756px'
              }}
              data-name="text-d5f115"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 18,
                  color: 'rgb(255, 255, 255)',
                  fontSize: '110%',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>נוגדי</span> <span>דלקת</span>
                            <span>, </span>
                            <span>כאבים</span> <span>וחום</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>מנגנון</span> <span>פעולה</span>
                            <span> –</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            &nbsp;<span>עיכוב</span> <span>האנזים</span>
                            <span> CYCLOXYGENASE &nbsp;&nbsp; (COX) - </span>
                            <span>הגורם</span> <span>ליצור</span>{' '}
                            <span>פרוסטגלנדינים</span> <span>(</span>
                            <span>אחראים</span> <span>על</span>{' '}
                            <span>הפעלת</span> <span>תגובה</span>{' '}
                            <span>דלקתית</span>
                            <span>, </span>
                            <span>תחושת</span> <span>כאב</span>
                            <span>, </span>
                            <span>הגדלת</span> <span>חדירות</span>{' '}
                            <span>הרקמה</span>
                            <span>, </span>
                            <span>כיווץ</span> <span>הסמפונות</span>{' '}
                            <span>והצמדות</span> <span>טסיות</span>{' '}
                            <span>הדם</span>
                            <span>).</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •<span>מטבוליזם</span> <span>בכבד</span>
                            <span>, </span>
                            <span>הפרשה</span> <span>דרך</span>{' '}
                            <span>השתן</span>
                            <span>.</span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 286,
                left: 337,
                top: '30.8042px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 11,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>בכאב</span>
                          <span> </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '759.68px 186.911px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '80.8972px',
                    left: '719.231px',
                    top: '148.055px',
                    height: 'auto'
                  }}
                  data-name="text-b45c7e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 14,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%',
                      lineHeight: '0.325'
                    }}
                    data-has-line-height=""
                  >
                    <h2>
                      <strong>
                        <span style={{ fontSize: '0.5em' }}>
                          <span style={{ fontSize: '1.0em' }}>1</span>
                          <br />
                          שלב
                        </span>
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 100,
                    height: 100,
                    left: '709.68px',
                    top: '136.911px'
                  }}
                  data-name="shape-436f64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 100 100"
                    >
                      <ellipse
                        rx={50}
                        ry={50}
                        cx={50}
                        cy={50}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(20, 96, 206)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(20, 96, 206)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '412.383px',
                left: '262.035px',
                top: '139.911px'
              }}
              data-name="text-bde2cf"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  color: 'rgb(238, 238, 238)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>אופיאטים</span>
                          <span> - </span>
                          <span>מנגנון</span> <span>פעילות</span>{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '553.882px',
                left: '314.162px',
                top: '275.911px'
              }}
              data-name="text-d5f115"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  color: 'rgb(255, 255, 255)',
                  fontSize: '110%',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>במוח</span> <span>ובחוט</span>{' '}
                          <span>השדרה</span> <span>קיימים</span>{' '}
                          <span>קולטנים</span> <span>לאופיאטים</span>
                          <span>:</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>מופעלים</span> <span>באופן</span>{' '}
                          <span>אנדוגני</span> <span>על</span> <span>ידי</span>{' '}
                          <span>אנדורפינים</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>אחראים</span> <span>על</span>{' '}
                          <span>שיכוך</span> <span>כאב</span>{' '}
                          <span>ותחושה</span> <span>טובה</span>
                          <span>, </span>
                          <span>גם</span> <span>באופן</span>{' '}
                          <span>פיזיולוגי</span>
                          <span>. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>עוברים</span> <span>מטבוליזם</span>{' '}
                          <span>בכבד</span>
                          <span>. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>מופרשים</span> <span>דרך</span>{' '}
                          <span>הכליה</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '224.712px',
                height: '266.845px',
                left: '51.219px',
                top: '275.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-858613"
            >
              <div className="sl-block-content" style={{ zIndex: 17 }}>
                <img
                  className=""
                  data-natural-width={336}
                  data-natural-height={399}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967622/pasted-from-clipboard.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 286,
                left: 337,
                top: '30.8042px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 11,
                  color: 'rgb(0, 0, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>בכאב</span>
                          <span> </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '764.755px 171.745px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 145,
                    left: '687.755px',
                    top: '171.745px',
                    height: 'auto'
                  }}
                  data-name="text-96a5c5"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 17,
                      textAlign: 'center',
                      fontSize: '70%',
                      color: 'rgb(223, 37, 37)',
                      lineHeight: 1
                    }}
                  >
                    <p>
                      <u>
                        <span style={{ color: '#ff0000' }}>
                          כאב בינוני - אופיואידים חלשים או חזקים במינון נמוך.
                        </span>
                      </u>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 192,
                    left: '669.755px',
                    top: '91.1299px',
                    height: 'auto'
                  }}
                  data-name="text-b45c7e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 18,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <h2>
                      <strong>
                        <span style={{ fontSize: '0.5em' }}>שלב </span>2
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 194,
                    height: 194,
                    left: '667.755px',
                    top: '74.7451px'
                  }}
                  data-name="shape-436f64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 194 194"
                    >
                      <ellipse
                        rx={97}
                        ry={97}
                        cx={97}
                        cy={97}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(244, 204, 204)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(244, 204, 204)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '498.331px',
                left: '147.75px',
                top: '148.209px'
              }}
              data-name="text-d5f115"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 20,
                  color: 'rgb(0, 0, 255)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •
                          <u>
                            <span>Tramadol</span>
                          </u>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Tramadex</span>
                          <span> (100mg). </span>
                          <span>Tramadex</span> <span>flashtab</span>
                          <span> 50mg. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Tramal</span>
                          <span> sol. </span>
                          <span>Tramal</span>
                          <span> Amp (IV)</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •
                          <u>
                            <span>Oxycodone</span>
                          </u>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •
                          <span>
                            Percocet 5 (Oxycodone 5mg + Paracetamol 325mg).
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Oxycontin 5mg, </span>
                          <span>Targin</span>{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Syr. </span>
                          <span>Oxycod</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •
                          <u>
                            <span>Morphine</span>
                          </u>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 286,
                left: 337,
                top: '30.8042px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 11,
                  color: 'rgb(0, 0, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>בכאב</span>
                          <span> </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '767.017px 166px 0px', zIndex: 12 }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '80.8972px',
                    left: '726.568px',
                    top: '127.144px',
                    height: 'auto'
                  }}
                  data-name="text-b45c7e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 16,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%',
                      lineHeight: '0.325'
                    }}
                    data-has-line-height=""
                  >
                    <h2>
                      <strong>
                        <span style={{ fontSize: '0.5em' }}>
                          2<br />
                          שלב
                        </span>
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 100,
                    height: 100,
                    left: '717.017px',
                    top: 116
                  }}
                  data-name="shape-436f64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 15 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 100 100"
                    >
                      <ellipse
                        rx={50}
                        ry={50}
                        cx={50}
                        cy={50}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(244, 204, 204)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(244, 204, 204)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '437.538px',
                left: '255.747px',
                top: 119
              }}
              data-name="text-bde2cf"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 17,
                  color: 'rgb(0, 0, 255)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>בוטרנס BuTrans &nbsp;</span>{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>Buprenorphine Hydrochloride</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '822.206px',
                left: '45.3139px',
                top: '242.296px'
              }}
              data-name="text-d5f115"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 18,
                  color: 'rgb(0, 0, 255)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  •בופרנורפין - אופיואיד אנלגטי המיועד לטיפול בכאב כרוני בינוני
                  וחזק המצריך מתן אופיואידים, לאחר שנכשל הטיפול במשככי כאבים
                  קלים שאינם אופיואידים.
                </p>
                <p style={{ textAlign: 'right' }}>
                  •מדבקות בוטרנס - 5mg, 10mg, 20mg- (מדבקה של בופרנורפין 5 מ"ג
                  משחררת 5 מק"ג Buprenorphine לשעה).
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>בחירת</span> <span>מקום</span>{' '}
                          <span>ההדבקה</span>
                          <span>: </span>
                          <span>בפלג</span> <span>גוף</span> <span>עליון</span>
                          <span>, </span>
                          <span>במקום</span> <span>יבש</span>
                          <span>, </span>
                          <span>נקי</span>
                          <span>, </span>
                          <span>לא</span> <span>מגורה</span> <span>ולא</span>{' '}
                          <span>פצוע</span>
                          <span>, </span>
                          <span>ללא</span> <span>צלקות</span>
                          <span>.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>לנקות</span> <span>את</span> <span>המקום</span>{' '}
                          <span>המיועד</span> <span>להדבקה</span>{' '}
                          <span>במים</span> <span>בלבד</span>
                          <span>. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>אין</span> <span>להשתמש</span>{' '}
                          <span>בסבון</span>
                          <span>, </span>
                          <span>אלכוהול</span>
                          <span>, </span>
                          <span>שמנים</span> <span>וחומרים</span>{' '}
                          <span>שעלולים</span> <span>למנוע</span>{' '}
                          <span>מהמדבקה</span> <span>להידבק</span>{' '}
                          <span>כראוי</span>
                          <span>. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 286,
                left: 337,
                top: '30.8042px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 11,
                  color: 'rgb(91, 15, 0)',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'left' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>טיפול</span> <span>בכאב</span>
                          <span> </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-fb20c8"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '764.755px 171.745px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 194,
                    left: '667.755px',
                    top: '181.178px',
                    height: 'auto'
                  }}
                  data-name="text-96a5c5"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 17,
                      textAlign: 'center',
                      fontSize: '60%',
                      color: 'rgb(255, 0, 0)'
                    }}
                  >
                    <p>
                      <u>כאב חזק - אופיואידים במינון גבוה</u>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 192,
                    left: '669.755px',
                    top: '91.1299px',
                    height: 'auto'
                  }}
                  data-name="text-b45c7e"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'center',
                      zIndex: 18,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <h2>
                      <strong>
                        <span style={{ fontSize: '0.5em' }}>שלב </span>3
                      </strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 194,
                    height: 194,
                    left: '667.755px',
                    top: '74.7451px'
                  }}
                  data-name="shape-436f64"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 194 194"
                    >
                      <ellipse
                        rx={97}
                        ry={97}
                        cx={97}
                        cy={97}
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(221, 126, 107)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(221, 126, 107)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '507.764px',
                left: '129.969px',
                top: 148
              }}
              data-name="text-bde2cf"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 19,
                  color: 'rgb(91, 15, 0)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>Oxycodone</span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '611.529px',
                left: '285.862px',
                top: '277.13px'
              }}
              data-name="text-d5f115"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 20,
                  color: 'rgb(91, 15, 0)',
                  fontSize: '110%'
                }}
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Oxycontin 5, 10, 20, 40, 80 mg</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Targin</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Oxycod</span>
                          <span> syrup</span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <br />
                  &nbsp;
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Morphine</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>&nbsp;</p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>Methadone</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '788.143px',
                left: '85.9285px',
                top: '116.5px',
                height: 'auto'
              }}
              data-origin-id="dac14457ce9f34b1a723b69ce844bda3"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'center',
                  borderWidth: 1,
                  lineHeight: '1.56',
                  fontSize: '110%'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>אופיואיד</span> <span>סינתטי</span>{' '}
                          <span>חזק</span>
                          <span>, </span>
                          <span>חודר</span> <span>לרקמות</span>{' '}
                          <span>בקלות</span>
                          <span>, </span>
                          <span>יעיל</span> <span>לטיפול</span>{' '}
                          <span>בכאב</span> <span>חריף</span>{' '}
                          <span>וכרוני</span>
                          <span>. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>בשימוש</span> <span>נרחב</span>
                          <span> - </span>
                          <span>להרדמה</span>
                          <span>, </span>
                          <span>לשיכוך</span> <span>כאב</span>{' '}
                          <span>לטווח</span> <span>קצר</span> <span>וארוך</span>{' '}
                          <span>ולסדציה</span>
                          <span>. </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>זמן</span> <span>התחלת</span>{' '}
                          <span>פעולה</span> <span>קצר</span>
                          <span> (</span>
                          <span>תוך</span>
                          <span> 10-5 </span>
                          <span>דקות</span>
                          <span>). </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •
                          <span>
                            TTS&nbsp; - Transdermal Therapeutic System -{' '}
                          </span>
                          <span>מדבקות</span> <span>למתן</span> <span>דרך</span>{' '}
                          <span>העור</span>
                          <span> – </span>
                          <span>ספיגה</span> <span>איטית</span>{' '}
                          <span>יותר</span>
                          <span> – </span>
                          <span>זמן</span> <span>התחלת</span> <span>פעולה</span>{' '}
                          <span>תוך</span>
                          <span> 12-18 ש'.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'right' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          •<span>דרכי</span> <span>מתן</span>{' '}
                          <span>מקובלות</span> <span>נוספות</span>
                          <span>: IV, SC.</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 776, left: 92, top: '49.5px', height: 'auto' }}
              data-name="text-c29943"
              data-origin-id="046d9a9d745d25aea50e32ca4ecace4e"
            >
              <div
                className="sl-block-content"
                style={{ textAlign: 'center', zIndex: 12, lineHeight: '1.17' }}
                data-has-line-height=""
              >
                <h2>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>פנטניל</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
              data-origin-id="259be4bf59ef16ab37f1298e1887744d"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(242, 237, 232)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(242, 237, 232)"
                  />
                </svg>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-112-1701478323774">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-112-1701478323774)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-111-1701478323774">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-111-1701478323774)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '556.795px',
                left: '201.603px',
                top: '120.39px'
              }}
              data-name="text-ac14d8"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 16, color: 'rgb(28, 69, 135)' }}
              >
                <p style={{ textAlign: 'center' }}>
                  האם ניתן לחתוך את המדבקות למתן מינון נמוך?
                  <br />
                  באלו מקרים יש לשקול מתן פנטניל במינון חלקי?
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '806.4px',
                height: '305.974px',
                left: '76.8px',
                top: '295.5px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5b81fe"
            >
              <div className="sl-block-content" style={{ zIndex: 17 }}>
                <img
                  className=""
                  data-lazy-loaded=""
                  data-natural-width={846}
                  data-natural-height={321}
                  data-src={stickers}
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '404.814px',
                left: '277.593px',
                top: '223.5px'
              }}
              data-name="text-fbaf88"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 18, color: 'rgb(0, 0, 0)' }}
              >
                <p style={{ textAlign: 'center' }}>
                  את המדבקה כדאי לחצות באלכסון
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: 0,
                top: '159.39px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-cd7936"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '145.662px',
                height: '45.2542px',
                left: '94.3256px',
                top: '39.9427px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-75ad13"
            >
              <div className="sl-block-content" style={{ zIndex: 12 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '101.297px',
                height: '31.4709px',
                left: '880.724px',
                top: '174.911px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-d351fb"
            >
              <div className="sl-block-content" style={{ zIndex: 13 }}>
                <img
                  className=""
                  data-natural-width={103}
                  data-natural-height={32}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967513/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '94.3256px',
                height: '221.377px',
                left: '859.05px',
                top: '380.767px',
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-5a4b70"
            >
              <div className="sl-block-content" style={{ zIndex: 14 }}>
                <img
                  className=""
                  data-natural-width={98}
                  data-natural-height={230}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10967512/pasted-from-clipboard.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '34.3256px',
                top: '320.767px'
              }}
              data-name="shape-6aff46"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, opacity: '0.24' }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-114-1701478446319">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-114-1701478446319)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 120,
                height: 120,
                left: '789.699px',
                top: '542.144px'
              }}
              data-name="shape-0f9d6e"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 15,
                  filter:
                    'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 5px) opacity(100%)',
                  opacity: '0.32'
                }}
              >
                <div
                  className="sl-block-content"
                  data-shape-type="circle"
                  data-shape-fill-color="rgba(0, 0, 0, 0)"
                  data-shape-stretch="true"
                  style={{ zIndex: 15 }}
                  data-shape-stroke-color="#4a86e8"
                  data-shape-stroke-width="3px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 120 120"
                  >
                    <defs>
                      <clipPath id="shape-mask-113-1701478446318">
                        <ellipse
                          rx={60}
                          ry={60}
                          cx={60}
                          cy={60}
                          fill="rgba(0, 0, 0, 0)"
                          stroke="#4a86e8"
                          strokeWidth={6}
                        />
                      </clipPath>
                    </defs>
                    <ellipse
                      rx={60}
                      ry={60}
                      cx={60}
                      cy={60}
                      className="shape-element"
                      fill="rgba(0, 0, 0, 0)"
                      stroke="#4a86e8"
                      strokeWidth={6}
                      clipPath="url(#shape-mask-113-1701478446318)"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '168.983px',
                left: '726.313px',
                top: '46.1969px'
              }}
              data-name="text-ac14d8"
            >
              <div className="sl-block-content" style={{ zIndex: 16 }}>
                <p style={{ textAlign: 'center' }}>שאלה</p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="pain-53"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 53)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-locked="true"
              data-name="shape-07a35c"
              data-origin-id="70dd1f8a1ea91371b80c5b2cd4b41e51"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(20, 96, 206)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(20, 96, 206)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 402, left: 279, top: '41.536px', height: 'auto' }}
              data-name="text-bad4e5"
              data-origin-id="f46594f437b0fe21e24244b106b3b11a"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 13,
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '1.04',
                  padding: 10
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'center' }}>סיכום</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '701.768px',
                left: '129.116px',
                top: '120.536px',
                height: 'auto'
              }}
              data-name="text-d18e72"
              data-origin-id="24ac29740d95dfa2feddaf57f441b55b"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  color: 'rgb(255, 255, 255)',
                  padding: 10
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •
                            <span>
                              <span>חובתנו</span>
                            </span>{' '}
                            <span>
                              <span>המקצועית</span>
                            </span>{' '}
                            <span>
                              <span>כאחים ואחיות - לסייע</span>
                            </span>{' '}
                            <span>
                              <span>לחולים</span>
                            </span>{' '}
                            <span>
                              <span>ככל</span>
                            </span>{' '}
                            <span>
                              <span>האפשר</span>
                            </span>{' '}
                            <span>
                              <span>בשיכוך</span>
                            </span>{' '}
                            <span>
                              <span>כאב.</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •
                            <span>
                              <span>זכותו</span>
                            </span>{' '}
                            <span>
                              <span>של</span>
                            </span>{' '}
                            <span>
                              <span>המטופל</span>
                            </span>{' '}
                            <span>
                              <span>לקבל</span>
                            </span>{' '}
                            <span>
                              <span>את</span>
                            </span>{' '}
                            <span>
                              <span>הטיפול</span>
                            </span>{' '}
                            <span>
                              <span>המתאים</span>
                            </span>{' '}
                            <span>
                              <span>להקלת</span>
                            </span>{' '}
                            <span>
                              <span>כאבים.</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  •מניעה והקלה על כאב הם חלק בלתי נפרד מהטיפול.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •
                            <span>
                              <span>יש</span>
                            </span>{' '}
                            <span>
                              <span>להאמין</span>
                            </span>{' '}
                            <span>
                              <span>לחולה</span>
                            </span>{' '}
                            <span>
                              <span>ולהתייחס</span>
                            </span>{' '}
                            <span>
                              <span>להרגשתו.</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •
                            <span>
                              <span>אומדן</span>
                            </span>{' '}
                            <span>
                              <span>שגרתי</span>
                            </span>{' '}
                            <span>
                              <span>של</span>
                            </span>{' '}
                            <span>
                              <span>עוצמת</span>
                            </span>{' '}
                            <span>
                              <span>הכאב</span>
                            </span>{' '}
                            <span>
                              <span>הוא</span>
                            </span>{' '}
                            <span>
                              <span>מרכיב</span>
                            </span>{' '}
                            <span>
                              <span>המשפיע</span>
                            </span>{' '}
                            <span>
                              <span>על</span>
                            </span>{' '}
                            <span>
                              <span>הצלחת</span>
                            </span>{' '}
                            <span>
                              <span>הטיפול.</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •
                            <span>
                              <span>בקבלת</span>
                            </span>{' '}
                            <span>
                              <span>כל</span>
                            </span>{' '}
                            <span>
                              <span>מטופל</span>
                            </span>{' '}
                            <span>
                              <span>יש</span>
                            </span>{' '}
                            <span>
                              <span>לערוך</span>
                            </span>{' '}
                            <span>
                              <span>תוכנית</span>
                            </span>{' '}
                            <span>
                              <span>טיפול</span>
                            </span>{' '}
                            <span>
                              <span>לאומדן</span>
                            </span>{' '}
                            <span>
                              <span>כאב</span>
                            </span>{' '}
                            <span>
                              <span>גם</span>
                            </span>{' '}
                            <span>
                              <span>אם</span>
                            </span>{' '}
                            <span>
                              <span>ברמה</span>
                            </span>{' '}
                            <span>
                              <span>פוטינציאל</span>
                            </span>
                            <span>
                              <span>, </span>
                            </span>
                            <span>
                              <span>מעקב</span>
                            </span>{' '}
                            <span>
                              <span>יומיומי</span>
                            </span>{' '}
                            <span>
                              <span>לכלל</span>
                            </span>{' '}
                            <span>
                              <span>המטופלים</span>
                            </span>
                            <span>
                              <span>.</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'justify' }}>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            •
                            <span>
                              <span>הדרכת</span>
                            </span>{' '}
                            <span>
                              <span>מטופל-יש</span>
                            </span>{' '}
                            <span>
                              <span>להדריך</span>
                            </span>{' '}
                            <span>
                              <span>את</span>
                            </span>{' '}
                            <span>
                              <span>המטופל</span>
                            </span>{' '}
                            <span>
                              <span>שבכל</span>
                            </span>{' '}
                            <span>
                              <span>מקרה</span>
                            </span>{' '}
                            <span>
                              <span>שבו</span>
                            </span>{' '}
                            <span>
                              <span>הוא</span>
                            </span>{' '}
                            <span>
                              <span>חש</span>
                            </span>{' '}
                            <span>
                              <span>כאב</span>
                            </span>{' '}
                            <span>
                              <span>יש</span>
                            </span>{' '}
                            <span>
                              <span>לדווח</span>
                            </span>{' '}
                            <span>
                              <span>לצוות</span>
                            </span>{' '}
                            <span>
                              <span>ונעשה</span>
                            </span>{' '}
                            <span>
                              <span>כל</span>
                            </span>{' '}
                            <span>
                              <span>מאמץ</span>
                            </span>{' '}
                            <span>
                              <span>למנוע</span>
                            </span>{' '}
                            <span>
                              <span>ולהקל</span>
                            </span>
                            <span>
                              <span>.</span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '534.455px',
                left: '212.773px',
                top: '65.4967px',
                height: 'auto'
              }}
              data-name="text-260be3"
              data-origin-id="b2f5646253665e7c9444cae127fd9ebb"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, fontSize: '160%', textAlign: 'center' }}
              >
                <h1>
                  <strong>תודה רבה!</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 1002,
                height: 1002,
                left: '-21px',
                top: '-151px'
              }}
              data-name="shape-3d8362"
              data-locked="true"
              data-origin-id="9fb8a5ce0b8a49bf4769e897ebac020c"
            >
              <div
                className="sl-block-content"
                data-shape-type="circle"
                data-shape-fill-color="rgb(238, 238, 238)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 1002 1002"
                >
                  <ellipse
                    rx={501}
                    ry={501}
                    cx={501}
                    cy={501}
                    className="shape-element"
                    fill="rgb(238, 238, 238)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-91e4d8"
              style={{ height: 'auto' }}
              data-origin-id="e01dbe159b9644b4fda56deff39aeb6d"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '480.001px 329.009px 0px',
                  zIndex: 15
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '280.619px',
                    left: '339.692px',
                    top: '281.182px',
                    height: 'auto'
                  }}
                  data-name="text-924ce8"
                  data-origin-id="8fb1b821b7f2e26625323a2b74332e3d"
                >
                  <a
                    className="sl-block-style"
                    style={{ zIndex: 17 }}
                    href="https://twitter.com/slides"
                    target="_blank"
                  >
                    <div
                      className="sl-block-content"
                      style={{
                        zIndex: 17,
                        textAlign: 'center',
                        padding: 8,
                        fontSize: '110%',
                        color: 'rgb(255, 255, 255)'
                      }}
                    >
                      <p style={{ textAlign: 'center' }}>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>אם</span>
                                </span>{' '}
                                <span>
                                  <span>הגעת</span>
                                </span>{' '}
                                <span>
                                  <span>עד</span>
                                </span>{' '}
                                <span>
                                  <span>לכאן</span>
                                </span>{' '}
                                <span>
                                  <span>סימן</span>
                                </span>{' '}
                                <span>
                                  <span>שהצלחת</span>
                                </span>{' '}
                                <span>
                                  <span>בגדול</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '361.326px',
                    height: '199.52px',
                    left: '299.338px',
                    top: '229.249px'
                  }}
                  data-name="shape-10cb72"
                  data-origin-id="bf71505db6dd4337874405e307571387"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="circle"
                    data-shape-fill-color="rgb(20, 96, 206)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                    href="https://twitter.com/slides"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 361 200"
                    >
                      <ellipse
                        rx="180.663"
                        ry="99.76"
                        cx="180.663"
                        cy="99.76"
                        className="shape-element"
                        fill="rgb(20, 96, 206)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: '100%', bottom: 150 }}
              data-name="button-f3216ab"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSetViewToSignCourseware()}
                  sx={{ minWidth: 160, fontSize: '1.15rem' }}
                  // disabled={!didReachTheEnd}
                >
                  חתימה על סיום הלומדה
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PainCourseware;
