import { Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import serviceProvidersTabsLabel from './serviceProvidersTabsLabel';

const AssessmentProfessionalsTableTabs = ({ value, setValue }) => {
  AssessmentProfessionalsTableTabs.propTypes = {
    value: PropTypes.number,
    setValue: PropTypes.func
  };
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <AppBar position="static" sx={{ maxWidth: 750 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          allowScrollButtonsMobile
          aria-label="full width tabs"
          sx={{ backgroundColor: theme.palette.common.white }}
        >
          {serviceProvidersTabsLabel.map((tabLabel, i) => {
            const isSelected = value === i;
            return (
              <Tab
                sx={() => ({
                  backgroundColor:
                    isSelected && theme.palette.background.default,
                  fontSize: '1.1rem',
                  boxShadow: isSelected && theme.shadows[8]
                })}
                key={tabLabel.value}
                label={tabLabel.label}
                value={i}
                id={`full-width-tab-${i}`}
                aria-controls={`full-width-tabpanel-${i}`}
              />
            );
          })}
        </Tabs>
        <Divider />
      </AppBar>
    </Box>
  );
};

export default AssessmentProfessionalsTableTabs;
