import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from '@mui/material';
import PropTypes from 'prop-types';
import UploadInput from 'src/components/shared/Upload/UploadInput';
import AttachedFileList from './AttachedFileList';

const AttachNewFileToPost = ({
  open,
  onClose,
  newPostValues,
  setNewPostValues,
  attachedFiles,
  setAttachedFiles,
  handleRemoveAttachedFile
}) => {
  AttachNewFileToPost.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    setNewPostValues: PropTypes.func,
    setAttachedFiles: PropTypes.func,
    handleRemoveAttachedFile: PropTypes.func,
    attachedFiles: PropTypes.any,
    newPostValues: PropTypes.object
  };

  const handleAddAttachedFile = () => {
    if (attachedFiles) {
      setNewPostValues({ ...newPostValues, file: attachedFiles });
    }
    onClose();
  };
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>צירוף קובץ</DialogTitle>
      <Divider />
      <DialogContent>
        <UploadInput
          accept={[
            'image/*', // All image types
            'application/pdf' // PDF files
          ]}
          multiple={false}
          secondary="אפשר להעלות תמונות או קבצי PDF"
          //   callbackFunc={handleFilesWereUploaded}
          files={attachedFiles}
          setFiles={setAttachedFiles}
        />
        <AttachedFileList
          attachedFiles={attachedFiles}
          handleRemoveAttachedFile={handleRemoveAttachedFile}
          setAttachedFiles={setAttachedFiles}
          setNewPostValues={setNewPostValues}
          newPostValues={newPostValues}
          key="attached-files-list"
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!attachedFiles}
          onClick={handleAddAttachedFile}
          variant="contained"
        >
          צירוף
        </Button>
        <Button onClick={onClose} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachNewFileToPost;
