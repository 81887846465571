const { uniqueId } = require('lodash');
const { default: i18n } = require('src/i18n');

const orgQuestions = [
  {
    name: 'aboutDoctors',
    label: i18n.t('commonFaq.aboutDoctors'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.aboutDoctorsPlaceholder')
  },
  {
    name: 'activities',
    label: i18n.t('commonFaq.activities'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.activitiesPlaceholder')
  },
  {
    name: 'familyMeetingsRoom',
    label: i18n.t('commonFaq.familyMeetingsRoom'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.familyMeetingsRoomPlaceholder')
  },
  {
    name: 'firstEmpressionTour',
    label: i18n.t('commonFaq.firstEmpressionTour'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.firstEmpressionTourPlaceholder')
  },
  {
    name: 'medicalStaffWorkingHours',
    label: i18n.t('commonFaq.medicalStaffWorkingHours'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.medicalStaffWorkingHoursPlaceholder')
  },
  {
    name: 'isThereATV',
    label: i18n.t('commonFaq.isThereATV'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.isThereATVPlaceholder')
  },
  {
    name: 'physiotherapy',
    label: i18n.t('commonFaq.physiotherapy'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.physiotherapyPlaceholder')
  },
  {
    name: 'parking',
    label: i18n.t('commonFaq.parking'),
    id: uniqueId('faq'),
    placeholder: i18n.t('commonFaq.parkingPlaceholder')
  }
];

export default orgQuestions;
