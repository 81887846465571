import i18n from 'src/i18n';

const relations = [
  {
    label: i18n.t('relations.daughter'),
    name: 'daughter'
  },
  {
    label: i18n.t('relations.cousin'),
    name: 'cousin'
  },
  {
    label: i18n.t('relations.sister'),
    name: 'sister  '
  },
  {
    label: i18n.t('relations.spouse'),
    name: 'spouse'
  },
  {
    label: i18n.t('relations.me'),
    name: 'me'
  },
  {
    label: i18n.t('relations.other'),
    name: 'other'
  }
];

export default relations;
