import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { uniqueId } from 'lodash';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import correctAnswer from 'src/assets/lottie/correctAnswer.json';
import wrongAnswer from 'src/assets/lottie/wrongAnswer.json';
import commonConfig from 'src/common/config';
import Confetti from 'src/components/shared/Confetti/Confetti';
import { useCompensationEngine } from 'src/hooks/useCompensationEngine/useCompensationEngine';
import { usePresentation } from 'src/hooks/usePresentation/usePresentation';
import theme from 'src/theme';

const colors = ['red', 'pink', 'yellow', 'aqua'];

const QuestionSlide = ({
  title,
  handleCorrectAnswer,
  handleWrongAnswer,
  answers,
  questionId,
  selectedCourseware,
  answersCustomSx,
  showNumbering,
  titleCustomSx
}) => {
  QuestionSlide.propTypes = {
    title: PropTypes.string,
    handleWrongAnswer: PropTypes.func,
    handleCorrectAnswer: PropTypes.func,
    answers: PropTypes.array,
    questionId: PropTypes.string.isRequired,
    selectedCourseware: PropTypes.object,
    answersCustomSx: PropTypes.object,
    titleCustomSx: PropTypes.object,
    showNumbering: PropTypes.bool
    // isWrongAnswerActive: PropTypes.bool
  };
  // console.log('data ==> ', title, questionId, answers);
  const [isWrongAnswerActive, setWrongAnswerActive] = useState(false);
  const [isCorrectAnswerActive, setCorrectAnswerActive] = useState(false);
  const { activePresentation } = usePresentation();
  const { compensationEngine } = useCompensationEngine();

  useEffect(() => {
    if (selectedCourseware && activePresentation) {
      const indexOfCourseware =
        compensationEngine.completedCoursewares.findIndex(
          (el) => el.courseware_id === selectedCourseware.courseware_id
        );
      if (
        compensationEngine &&
        compensationEngine.completedCoursewares &&
        indexOfCourseware > -1
      ) {
        const thisQuestionFromEngine =
          compensationEngine.completedCoursewares[indexOfCourseware][
            `question-${questionId}`
          ];
        if (thisQuestionFromEngine && thisQuestionFromEngine.answered_correct) {
          activePresentation.configure({
            ...commonConfig.revealConfig,
            controls: true,
            touch: true
          });
        }
      }
    }
  }, [selectedCourseware, compensationEngine]);

  const showCorrectAnswerAnimation = () => {
    setCorrectAnswerActive(true);
    setTimeout(() => {
      handleCorrectAnswer(questionId);
      setCorrectAnswerActive(false);
    }, 2000);
  };

  const showWrongAnswerAnimation = () => {
    setWrongAnswerActive(true);
    setTimeout(() => {
      setWrongAnswerActive(false);
    }, 2000);
  };
  const questionTitle = (
    <>
      <Typography sx={{ mt: -5 }} variant="inherit">
        {title}
      </Typography>
    </>
  );
  return (
    <>
      <Stack
        sx={{
          color: 'inherit',
          fontSize: 'inherit',
          height: '100%',
          alignItems: 'center',
          justifyItems: 'center',
          zIndex: 99999,
          gap: 4,
          ...titleCustomSx
        }}
      >
        {questionTitle}
        {/* <Typography sx={{ mt: -5 }} variant="inherit">{`${title}`}</Typography> */}
        <Grid
          container
          sx={{
            height: '100%',
            alignContent: 'center',
            justifyContent: 'center'
          }}
          spacing={2}
        >
          {answers &&
            answers.map((answer, index) => (
              <Grid
                key={`${uniqueId(answer.title)}`}
                item
                xs={12}
                sm={6}
                sx={{
                  p: 2,
                  zIndex: 999,
                  position: 'relative'
                }}
              >
                {showNumbering && (
                  <Card
                    elevation={2}
                    sx={{
                      position: 'absolute',
                      left: 6,
                      top: '40%',
                      p: '6px !important',
                      backgroundColor: lighten(
                        theme.palette.success.light,
                        0.45
                      )
                    }}
                  >
                    <CardContent sx={{ pb: '0 !important' }}>
                      <Typography variant="h4">
                        {index === 0
                          ? "א'"
                          : index === 1
                          ? "ב'"
                          : index === 2
                          ? "ג'"
                          : "ד'"}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                <Card
                  elevation={6}
                  sx={{
                    border: `3px solid ${colors[index]}`,
                    height: '160px',
                    overflow: 'auto',
                    zIndex: 1000,
                    '&:hover': {
                      boxShadow: theme.shadows[12],
                      cursor: 'pointer'
                    },
                    ...answersCustomSx
                  }}
                  onClick={() => {
                    if (answer.isAnswer) {
                      // handleCorrectAnswer();
                      showCorrectAnswerAnimation();
                    } else {
                      handleWrongAnswer(questionId);
                      showWrongAnswerAnimation();
                    }
                  }}
                >
                  <CardContent
                    sx={{
                      height: '100%',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      overflow: 'auto',
                      pb: '0 !important',
                      position: 'relative'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '20px',
                        userSelect: 'none'
                      }}
                      component="span"
                    >
                      {answer.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Stack>
      {isWrongAnswerActive && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 999
          }}
        >
          <Lottie
            style={{
              width: '60% !important',
              height: '50%',
              minWidth: '160px',
              zIndex: 999
              // margin: 'auto'
              // marginBottom: 0,
              // marginTop: '24px'
            }}
            autoplay
            animationData={wrongAnswer}
          />
        </Box>
      )}
      {isCorrectAnswerActive && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 999
          }}
        >
          <Confetti
            customStyle={{
              width: '70% !important',
              height: '100%'
            }}
            active={Boolean(isCorrectAnswerActive)}
          />
          <Lottie
            style={{
              width: '60% !important',
              height: '50%',
              minWidth: '160px',
              zIndex: 999,
              position: 'absolute'
              // margin: 'auto'
              // marginBottom: 0,
              // marginTop: '24px'
            }}
            autoplay
            animationData={correctAnswer}
          />
        </Box>
      )}
    </>
  );
};

QuestionSlide.defaultProps = {
  answersCustomSx: {},
  titleCustomSx: {},
  showNumbering: false
};
export default QuestionSlide;
