import {
  CheckOutlined,
  CloseOutlined,
  LocationOnOutlined,
  SearchOutlined
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MinimalOrgPageMap from 'src/components/map/MinimalOrgPageMap/MinimalOrgPageMap';

const PickAddressDialog = ({
  open,
  onClose,
  handleAddressPinChanged,
  defaultLocation
}) => {
  PickAddressDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleAddressPinChanged: PropTypes.func,
    defaultLocation: PropTypes.any
  };

  const initMapCenter = {
    lng: 35.11,
    lat: 32.71
  };
  const [selectedAddress, setSelectedAddress] = useState(initMapCenter);
  const [addressSuggestions, setAddressSuggestions] = useState();
  const { t } = useTranslation('translation', { keyPrefix: 'register' });
  const apiKey = process.env.REACT_APP_GMAPS_APIKEY;
  const handleSearchAddress = (addressToGeocode) => {
    const encodedSearch = encodeURIComponent(addressToGeocode);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?language=he&address=${encodedSearch}&key=${apiKey}&components=country:il`;
    fetch(url)
      .then(async (res) => {
        const json = await res.json();
        if (json.status === 'OK') {
          if (json && json.results.length > 0) {
            setAddressSuggestions(json.results);
          } else {
            setAddressSuggestions([]);
            console.log('forBetterResultsSearchUsingStreetCity');
          }
        } else {
          setAddressSuggestions([]);
          console.log(json);
          console.log('forBetterResultsSearchUsingStreetCity');
        }
      })
      .catch((err) => console.log('err while geocoding', err));
  };

  return (
    <Dialog
      key="org-address-dialog"
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography sx={{ width: 'fit-content' }}>
          {t('findYourAddress')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          fullWidth
          label="מצא את הכתובת על המפה"
          placeholder="לדוגמא: שם רחוב, מס בית, עיר"
          onChange={(e) => handleSearchAddress(e.target.value)}
        />
        <Typography>בחר בכתובת המתאימה כדי לראות אותה על המפה</Typography>
        {!addressSuggestions ? (
          <Stack
            width="100%"
            sx={{ justifyContent: 'center', alignItems: 'center', mt: 2 }}
          >
            <Icon sx={{ opacity: 0.4 }}>
              <SearchOutlined />
            </Icon>
            <Typography>נא להקליד כדי לחפש</Typography>
          </Stack>
        ) : addressSuggestions && addressSuggestions.length === 0 ? (
          <p>try another address</p>
        ) : (
          <Grid sx={{ my: 2 }} container spacing={2}>
            {addressSuggestions.map((suggestion) => (
              <Grid item xs={12} sm={6} lg={4} key={suggestion.place_id}>
                <ListItem
                  sx={{
                    boxShadow: (theme) => theme.shadows[6],
                    height: '100px',
                    borderRadius: 1
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      handleAddressPinChanged({
                        lng: suggestion.geometry.location.lng,
                        lat: suggestion.geometry.location.lat
                      });
                      setSelectedAddress({
                        formatted_address: suggestion.formatted_address,
                        place_id: suggestion.place_id,
                        lng: suggestion.geometry.location.lng,
                        lat: suggestion.geometry.location.lat
                      });
                    }}
                  >
                    <ListItemIcon>
                      <LocationOnOutlined />
                    </ListItemIcon>
                    <ListItemText primary={suggestion.formatted_address} />
                  </ListItemButton>
                  <IconButton
                    onClick={() => {
                      handleAddressPinChanged({
                        lng: suggestion.geometry.location.lng,
                        lat: suggestion.geometry.location.lat
                      });
                      setSelectedAddress({
                        formatted_address: suggestion.formatted_address,
                        place_id: suggestion.place_id,
                        lng: suggestion.geometry.location.lng,
                        lat: suggestion.geometry.location.lat
                      });
                    }}
                  >
                    <CheckOutlined />
                  </IconButton>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid
          sx={{ justifyContent: 'center', alignItems: 'center', my: 2, gap: 2 }}
        >
          <Stack
            direction="row"
            sx={{
              width: '100%',
              justifyContent: 'center',
              gap: 2,
              alignItems: 'center'
            }}
          >
            <Divider width="25%" />
            <Typography textAlign="center" variant="h4">
              או
            </Typography>
            <Divider width="25%" />
          </Stack>
          <Typography
            sx={{
              mt: 2
            }}
            textAlign="center"
            variant="body1"
          >
            כדי למקם את הארגון שלך אפשר לגרור את הסיכה את לחיצה כפולה על המיקום
            הרצוי
          </Typography>
        </Grid>
        <MinimalOrgPageMap
          key="map-in-pick-address-dialog"
          selectedOrg={defaultLocation || selectedAddress}
          markerDraggable={Boolean(true)}
          handlePinDragEnd={handleAddressPinChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>שמירה</Button>
        <Button onClick={onClose}>ביטול</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickAddressDialog;
