import { addDoc, collection, serverTimestamp } from '@firebase/firestore';
import { CloseOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { alpha, experimentalStyled } from '@mui/material/styles';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { lazy, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { Outlet, useLocation } from 'react-router-dom';
import { db } from 'src/_firebase/firebase';
import lottieData from 'src/assets/lottie/help.json';
import animationData from 'src/assets/lottie/message-sent.json';
import commonConfig from 'src/common/config';
import footerRoutes from 'src/footer.routes';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import routesConst from 'src/routesConst';
import DefaultNavbar from './MainNavbar';
import navbarRoutes from './navbar/navbarRoutes';

const MgdalorFooter = lazy(() => import('./footer/NewFooter'));
const AccessibilityComponent = lazy(() =>
  import('./shared/AccessibilityComponent/AccessibilityComponent')
);

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
  flexDirection: 'column'
}));

const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
  // paddingTop: 64
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = (props) => {
  MainLayout.propTypes = {
    loading: PropTypes.any,
    user: PropTypes.any
  };

  const [isHovering, setIsHovering] = useState(false);
  const [contactOpened, setContactOpen] = useState(false);
  const [msgWasSent, setMsgWasSent] = useState({ state: false });
  const [anchorEl, setAnchorEl] = useState();
  const { user, loading } = props;
  const { searchClient } = useSearchData();
  const initValues = {
    name: '',
    phone: '',
    message: ''
  };
  const [isChecked, setIsChecked] = useState(true);
  const [contactCardValues, setContactCardValues] = useState(initValues);
  const { t } = useTranslation();
  const location = useLocation();

  const handleClose = () => {
    setContactOpen(false);
    setAnchorEl();
  };

  const handleChange = (e) => {
    setContactCardValues({
      ...contactCardValues,
      [e.target.name]: e.target.value
    });
  };
  const handleSendingNote = async () => {
    const ticketsRef = collection(db, 'contactTickets');
    const created_at = serverTimestamp();
    if (
      contactCardValues &&
      contactCardValues.name &&
      contactCardValues.name !== '' &&
      contactCardValues.phone !== '' &&
      contactCardValues.phone
    ) {
      await addDoc(ticketsRef, {
        ...contactCardValues,
        created_at,
        location: location.pathname,
        source_component: 'contactFab'
      })
        .then(() => {
          setContactCardValues(initValues);
          const emailRef = collection(db, 'mail');
          addDoc(emailRef, {
            to: commonConfig.supportMail,
            message: {
              subject: `פנייה חדשה התקבלה במערכת מגדלור - ${contactCardValues.name}`,
              html: `<div>
              <h2>קיימת פנייה חדשה שמתעניין השאיר דרך אתר מגדלור</h2>
              <h6>שם לקוח: ${contactCardValues.name}</h6>
              <h6>מס טלפון: ${contactCardValues.phone}</h6>
              <caption>${contactCardValues.message}</caption>
            </div>`
            }
          });
          setMsgWasSent({ state: true, error: false });
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch(() => setMsgWasSent({ state: false, error: true }));
    }
  };

  if (user && !loading) {
    window.location.href = '/app/dashboard';
  } else {
    return (
      <MainLayoutRoot>
        <Helmet>
          <html lang="he" />
        </Helmet>
        <DefaultNavbar
          navbarRoutes={navbarRoutes}
          action={{
            type: 'external',
            route: `${routesConst.contact}`,
            label: t('navbar.helpCTA'),
            color: 'info'
          }}
          brand="Mgdalor"
          sticky
        />
        <MainLayoutWrapper>
          <MainLayoutContainer>
            {/* <HelpFloatingCTA /> */}
            <MainLayoutContent>
              <InstantSearch
                searchClient={searchClient}
                indexName={process.env.REACT_APP_ALGOLIA_INDEX_NAME}
              >
                <Outlet />
              </InstantSearch>
              {/* <DefaultFooter content={footerRoutes} /> */}
              <MgdalorFooter content={footerRoutes} />
            </MainLayoutContent>
          </MainLayoutContainer>
        </MainLayoutWrapper>
        {contactOpened && (
          <ClickAwayListener onClickAway={handleClose}>
            <Slide direction="left" in={contactOpened}>
              <Card
                sx={{
                  position: 'absolute',
                  width: '20vw',
                  minWidth: '190px',
                  left: 15,
                  bottom: 65,
                  backdropFilter: 'saturate(500%) blur(50px)',
                  backgroundColor: (theme) =>
                    alpha(theme.palette.common.white, 0.65),
                  zIndex: 1900
                }}
                elevation={10}
              >
                <CardHeader
                  action={
                    <IconButton onClick={handleClose}>
                      <CloseOutlined />
                    </IconButton>
                  }
                  sx={{ py: 0 }}
                  title={
                    <Typography variant="h6">{t('leaveUsANote')}</Typography>
                  }
                />
                <Divider />
                <CardContent sx={{ pb: 0 }}>
                  {msgWasSent.state && !msgWasSent.error ? (
                    <Stack>
                      <Lottie
                        style={{
                          width: '60% !important',
                          height: '70px',
                          minWidth: '60px',
                          margin: 'auto',
                          marginBottom: 0,
                          marginTop: '24px'
                        }}
                        autoplay
                        animationData={animationData}
                      />
                      ההודעה שלך נשלחה בהצלחה
                    </Stack>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          onChange={handleChange}
                          fullWidth
                          value={contactCardValues.name}
                          name="name"
                          required
                          label={t('name')}
                          placeholder="נשמח להכיר"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          onChange={handleChange}
                          fullWidth
                          value={contactCardValues.phone}
                          name="phone"
                          label={t('phone')}
                          // sx={{ mb: 1 }}
                          required
                          placeholder="אולי יש לנו הצעות"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          rows={2}
                          // sx={{ overflow: 'auto' }}
                          onChange={handleChange}
                          fullWidth
                          value={contactCardValues.message}
                          name="message"
                          label={t('message')}
                          placeholder={t('WeWouldLoveToHearFromYou')}
                        />
                      </Grid>
                      <Grid sx={{ pt: 0 }} item xs={12}>
                        <Stack
                          direction="row"
                          sx={{ alignItems: 'center', pb: 0 }}
                        >
                          <Checkbox
                            sx={{ py: 1 }}
                            onChange={() => setIsChecked(!isChecked)}
                            checked={isChecked}
                          />
                          <Typography variant="caption">
                            * הנני מאשר/ת לקבל מידע פרסומי
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
                {!msgWasSent.state && msgWasSent.error && (
                  <Typography variant="body2" sx={{ color: 'tomato' }}>
                    סליחה, לא הצלחנו לשלוח את ההודעה שלך, נסה שוב
                  </Typography>
                )}
                <CardActions sx={{ justifyContent: 'flex-end', pt: 0 }}>
                  <Button
                    onClick={() => handleSendingNote()}
                    variant="contained"
                  >
                    {t('send')}
                  </Button>
                </CardActions>
              </Card>
            </Slide>
          </ClickAwayListener>
        )}
        <Fab
          color="primary"
          sx={{
            position: 'absolute',
            bottom: 15,
            left: 15,
            width: 'fit-content',
            transform: isHovering && 'scaleX(1.05)',
            transition: isHovering && 'all 0.5s'
          }}
          onClick={(e) => {
            setContactOpen(true);
            setAnchorEl(e.currentTarget);
          }}
          variant="extended"
          onMouseLeave={() => setIsHovering(false)}
          onMouseEnter={() => setIsHovering(true)}
        >
          <Lottie
            style={{
              width: '60px'
            }}
            autoplay
            animationData={lottieData}
            loop
          />
        </Fab>
        <AccessibilityComponent />
      </MainLayoutRoot>
    );
  }
};
export default MainLayout;
