import { collection, getDocs, orderBy, query } from '@firebase/firestore';
import { getDownloadURL, list, ref } from 'firebase/storage';
import { db, storage } from 'src/_firebase/firebase';

const retrieveReports = async (org_id) => {
  const reportsRef = collection(db, `orgs/${org_id}/reports`);
  const queryDocs = getDocs(query(reportsRef, orderBy('issued_at', 'desc')));
  return queryDocs
    .then(async (reportsOfOrg) => {
      if (!reportsOfOrg.empty) {
        // storage of folder will orgs/org-id/reports/FILEDOCID/filename.ext
        const filesPromise = reportsOfOrg.docs.map(async (report) => {
          const storage_reference = ref(
            storage,
            `orgs/${org_id}/reports/${report.id}`
          );
          return list(storage_reference)
            .then(async (storageFile) => {
              const downloadURLPromises = storageFile.items.map(
                async (item) => {
                  const downloadUrl = await getDownloadURL(item).catch((err) =>
                    console.log(err)
                  );
                  return {
                    report_url: downloadUrl,
                    report_data: {
                      ...report.data(),
                      id: report.id
                    }
                  };
                }
              );
              return Promise.all(downloadURLPromises).then((res) => ({
                ...report.data(),
                ...res[0],
                full_path: report.ref.path
              }));
            })
            .catch((err) => {
              console.log(err);
              return [];
            });
        });
        const resolved = Promise.all(filesPromise)
          .then((allFiles) => {
            if (allFiles.length > 0) {
              return allFiles;
            }
            return [];
          })
          .catch((err) => {
            console.log('err in catch reports promise.all', err);
            return [];
          });
        return resolved;
      }
      return [];
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export default retrieveReports;
