import { Button, Typography } from '@mui/material';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchData } from 'src/hooks/useSearchData/useSearchData';
import CustomizedSlider from '../CustomizedSlider/CustomizedSlider';

const RadiusSlider = ({ handleSliderValueChange, performSearch }) => {
  RadiusSlider.propTypes = {
    handleSliderValueChange: PropType.func,
    performSearch: PropType.func
  };
  const { t } = useTranslation('translation', { keyPrefix: 'mainPage' });
  const { sliderDistanceValue } = useSearchData();
  return (
    <Button disableRipple sx={{ flex: 0.45, flexDirection: 'column' }}>
      <Typography
        sx={{
          textAlign: 'start',
          width: '100%',
          margin: 0,
          fontWeight: 600,
          fontSize: 16,
          mt: -1.2,
          // mb: 1.1,
          // lineHeight: 2.66,
          // letterSpacing: '0.08333em',
          letterSpacing: '0.05em',
          textTransform: 'uppercase',
          color: '#6b778c',
          transform: 'translateY(25%)'
        }}
      >
        {sliderDistanceValue < 100
          ? `${t('distance')} (עד ${sliderDistanceValue} ${t('km')})`
          : `${t('distance')} (${sliderDistanceValue} ${t('km')}+)`}
      </Typography>
      <CustomizedSlider
        key="customized-slider-home-page"
        handleSliderValueChange={handleSliderValueChange}
        sliderValue={sliderDistanceValue}
        performSearch={performSearch}
      />
    </Button>
  );
};

export default RadiusSlider;
