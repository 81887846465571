import { isDate } from 'lodash';
import moment from 'moment';

const getFormattedDates = (loginsDocs) =>
  loginsDocs.map((login) => {
    if (isDate(login.login_date)) {
      return moment(login.login_date).toDate();
    }
    if (login.login_date && login.login_date.seconds) {
      return moment(login.login_date.seconds * 1000).toDate();
    }
    return null;
  });

export default getFormattedDates;
