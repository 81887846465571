import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ComparisonTableLottieSign from './ComparisonTableLottieSign';

const IVCompare = ({ StyledTableCell, list, userscrolledhorizontally }) => {
  IVCompare.propTypes = {
    StyledTableCell: PropTypes.object,
    list: PropTypes.object,
    userscrolledhorizontally: PropTypes.bool
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'searchResults.comparisonDialog'
  });

  return (
    <>
      <StyledTableCell userscrolledhorizontally={userscrolledhorizontally}>
        <ComparisonTableLottieSign lottie="ivTreatment" />
        {t('ivCompare')}
      </StyledTableCell>
      {list.names && (
        <>
          {list.iv_treatment
            ? list.iv_treatment.map((ivTreatment, index) => (
                <StyledTableCell
                  key={`iv-treatment-${list.names[index]}`}
                  align="center"
                >
                  {!ivTreatment ? t('weDontHave') : t('weHave')}
                </StyledTableCell>
              ))
            : list.names.map((name) => (
                <StyledTableCell key={`no-data-${name}`} align="center">
                  {t('noData')}
                </StyledTableCell>
              ))}
        </>
      )}
    </>
  );
};

export default IVCompare;
