import {
  EmailOutlined,
  FaxOutlined,
  LocationOnOutlined,
  ScheduleOutlined,
  TravelExploreOutlined
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Icon,
  Stack,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReadMoreSuggestions from 'src/components/shared/ReadMoreSuggestions/ReadMoreSuggestions';
import i18n from 'src/i18n';
import theme from 'src/theme';
import handleGTag from 'src/utils/handleGTag';
import '../components/MohBranches/programs.scss';
import mohBranches from '../components/MohBranches/programsData';

const packages = [
  {
    title: 'all',
    label: i18n.t('mohBranches.allAreas')
  },
  {
    title: 'north',
    label: i18n.t('mohBranches.north')
  },
  {
    title: 'south',
    label: i18n.t('mohBranches.south')
  },
  {
    title: 'center',
    label: i18n.t('mohBranches.center')
  }
];

const MohBranches = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'mohBranches'
  });
  //   const [mohBranches, setMohBranches] = useState(mohBranches);
  const [displayedData, setDisplayedData] = useState(mohBranches);
  const [active, setActive] = useState(1);

  useEffect(() => {
    if (active > 1 && mohBranches) {
      const temp = mohBranches.filter(
        (el) => el.district === packages[active - 1].title
      );
      setDisplayedData(temp);
    } else {
      setDisplayedData(mohBranches);
    }
  }, [active, mohBranches]);

  const retrieveMohBranches = async () => {
    const recs = await fetch(
      'https://data.gov.il/api/3/action/datastore_search?resource_id=e01d86f2-e89f-4305-90e8-74cb88866092'
    )
      .then(async (res) => res.json())
      .then((data) => {
        console.log(data);
        return data;
      });
    console.log(recs);
  };
  useEffect(() => {
    if (!mohBranches) {
      retrieveMohBranches();
    }
  }, [mohBranches]);

  return (
    <>
      <Helmet>
        <title>{t('mohBranches')}</title>
        <meta
          name="description"
          content="מגדלור ייעוץ והכוונה - מנוע החיפוש וההשוואה של בתי האבות והדיור המוגן בישראל"
        />
        <meta name="googlebot" content="index, follow" />
        <meta name="robots" content="index, follow" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
        />
        <script>{handleGTag()}</script>
        <link rel="canonical" href="https://www.mgdalor.com/mohBranches" />
      </Helmet>

      <section
        style={{
          background: theme.palette.background.default
        }}
      >
        <Box height="12.5rem" width="100%" />
        <Card
          sx={{
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: theme.shadows[6]
          }}
        >
          {/* <Grid item xs={12}> */}
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              mt: 2
            }}
          >
            <Chip
              color="success"
              label="לשכות בריאות מחוזיות ונפתיות"
              sx={{ fontSize: '1.2rem' }}
            />
            <Typography
              variant="h1"
              sx={{ fontSize: '2.5rem', fontWeight: 600 }}
            >
              לשכות הבריאות
            </Typography>
            <Stack sx={{ textAlign: 'center' }}>
              <Typography component="span">
                יש לוודא את שעות קבלת הקהל במחלקה המבוקשת לפני ההגעה.
                <br />
                מידע נוסף ושעות קבלת קהל בדף הלשכה&nbsp;
                <Typography
                  component="a"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.gov.il/he/departments/units/health-bureaus-u/govil-landing-page"
                >
                  באתר משרד הבריאות
                </Typography>
                .
              </Typography>
            </Stack>
          </Stack>
          {/* </Grid> */}
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ pb: 0, mt: 4 }}
            textAlign="center"
          >
            לשכות הבריאות - שעות קבלת קהל במחלקה למחלות ממושכות
          </Typography>
          <div className="packages">
            <ul>
              {packages.map((pkg, index) => (
                <li
                  className={active === index + 1 ? 'active' : ''}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setActive(index + 1)}
                  key={`${pkg.title}-${uniqueId('title')}`}
                >
                  {pkg.label}
                </li>
              ))}
            </ul>
          </div>
          <CardContent>
            <Grid
              container
              sx={{ placeContent: 'center', placeItems: 'ce' }}
              spacing={2}
            >
              {displayedData &&
                displayedData.map((branch) => (
                  <Grid
                    key={`${branch.id}-${uniqueId('branch')}`}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Card
                      elevation={6}
                      sx={{
                        '&:hover': {
                          boxShadow: theme.shadows[14]
                        },
                        height: '350px',
                        overflowY: 'auto'
                      }}
                      key={branch.id}
                    >
                      <CardHeader
                        title={branch.branch}
                        action={
                          <Icon fontSize="large">
                            <LocationOnOutlined
                              fontSize="large"
                              color={
                                branch.district_branch ? 'success' : 'warning'
                              }
                            />
                          </Icon>
                        }
                        sx={{ pb: 0 }}
                        subheader={`${branch.address.city_label}, ${
                          branch.address.street
                        }, ${
                          branch.address && branch.address.num
                            ? branch.address.num
                            : 1
                        }`}
                      />
                      <CardContent sx={{ px: 4, py: 0 }}>
                        <Stack sx={{ mt: 1 }}>
                          <Stack
                            sx={{ mb: 1 }}
                            direction="row"
                            gap={1}
                            alignItems="center"
                          >
                            <Icon>
                              <ScheduleOutlined color="action" />
                            </Icon>
                            <Typography variant="h6">
                              {t('work_days')}
                            </Typography>
                          </Stack>
                          <Grid container gap={1}>
                            {branch.work_days.map((day) => (
                              <Grid item>
                                <Chip
                                  key={`${branch.id}-${day.day}`}
                                  variant="filled"
                                  color="primary"
                                  sx={{
                                    minWidth: 'fit-content',
                                    height: 'fit-content',
                                    p: 1
                                  }}
                                  label={
                                    <Stack>
                                      <Typography variant="body2">
                                        {day.day_label}
                                      </Typography>
                                      <Typography variant="overline">
                                        {`${day.work_hours.from} - ${day.work_hours.to}`}
                                      </Typography>
                                    </Stack>
                                  }
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Stack>
                        <Stack gap={1}>
                          {branch.address.zip_code && (
                            <Stack
                              sx={{ mt: 1 }}
                              direction="row"
                              gap={1}
                              alignItems="center"
                            >
                              <Icon>
                                <TravelExploreOutlined color="action" />
                              </Icon>
                              <Typography variant="body2">
                                {`מיקוד - ${branch.address.zip_code}`}
                              </Typography>
                            </Stack>
                          )}
                          {branch.address.fax && (
                            <Stack
                              sx={{ my: 1 }}
                              direction="row"
                              gap={1}
                              alignItems="center"
                            >
                              <Icon>
                                <FaxOutlined color="action" />
                              </Icon>
                              <Typography variant="body2">
                                {`פקס - ${branch.address.fax}`}
                              </Typography>
                            </Stack>
                          )}
                          {branch.address.description && (
                            <Typography sx={{ mt: 1 }} variant="body2">
                              {`* ${branch.address.description}`}
                            </Typography>
                          )}
                        </Stack>
                        {branch.email && (
                          <Stack
                            sx={{ mb: 1 }}
                            direction="row"
                            gap={1}
                            alignItems="center"
                          >
                            <Icon>
                              <EmailOutlined color="action" />
                            </Icon>
                            <Typography variant="body2">
                              {branch.email}
                            </Typography>
                          </Stack>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
          <Grid container>
            <Grid sx={{ mx: 'auto' }} item xs={12} md={10}>
              <ReadMoreSuggestions key="read-more-moh-code-page" />
            </Grid>
          </Grid>
        </Card>
      </section>
    </>
  );
};

export default MohBranches;
