/* eslint-disable */

class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    }).catch((err) => {
      console.log(err);
    });
    this.adobeDCView = undefined;
  }
  ready() {
    return this.readyPromise;
  }

  previewFile(divId, viewerConfig, url, embedMode, file_name) {
    const config = {
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID ///enter lient id here
    };
    if (divId) {
      config.divId = divId;
    }
    this.adobeDCView = new window.AdobeDC.View(config);
    // this.registerUserProfile(profile);
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: url
          }
        },
        metaData: {
          fileName: file_name || '  ',
          id: process.env.REACT_APP_ADOBE_CLIENT_ID
        }
      },
      (viewerConfig = {
        ...viewerConfig,
        embedMode
      })
    );
    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId, filePromise, fileName) {
    this.adobeDCView = new window.AdobeDC.View({
      clientId: process.env.REACT_APP_CLIENT_ID, //enter Client id here
      divId
    });
    this.adobeDCView.previewFile(
      {
        content: {
          promise: filePromise
        },
        metaData: {
          fileName: fileName
        }
      },
      {}
    );
  }

  // registerSaveApiHandler() {
  //   const saveApiHandler = (metaData, content, options) => {
  //     // console.log(metaData, content, options);
  //     return new Promise((resolve) => {
  //       setTimeout(() => {
  //         const response = {
  //           code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
  //           data: {
  //             metaData: Object.assign(metaData, {
  //               updatedAt: new Date().getTime()
  //             })
  //           }
  //         };
  //         resolve(response);
  //       }, 2000);
  //     });
  //   };
  //   this.adobeDCView.registerCallback(
  //     window.AdobeDC.View.Enum.CallbackType.SAVE_API,
  //     saveApiHandler,
  //     {}
  //   );
  // }

  // registerEventsHandler() {
  //   const profile = {
  //     userProfile: {
  //       name: 'Morad Abed',
  //       firstName: 'Morad',
  //       lastName: 'Abed',
  //       email: 'microspective.dev@gmail.com'
  //     }
  //   };

  //   this.adobeDCView.registerCallback(
  //     window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
  //     (event) => {
  //       return new Promise((resolve, reject) => {
  //         resolve({
  //           code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
  //           data: profile
  //         });
  //       });
  //     }
  //   );
  // }
}
export default ViewSDKClient;
