import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  Stack,
  Typography
} from '@mui/material';
import { uniqueId } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import jobsTitles from 'src/common/jobsTitles';
import { useEmployeesData } from 'src/hooks/useEmployeesData/useEmployeesData';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import containsSomeLabels from 'src/utils/containsSomeLabels';
import getUserJobTitle from 'src/utils/getUserJobTitle';
import handleRetrievePostDetails from '../../utils/handleRetrievePostDetails';

const RecentUpdateItem = ({ update, handleViewCommunityAttachedFile }) => {
  RecentUpdateItem.propTypes = {
    update: PropTypes.object,
    handleViewCommunityAttachedFile: PropTypes.func
    // i: PropTypes.number,
    // totalLength: PropTypes.number,
  };

  const { employeesData } = useEmployeesData();
  const { selectedOrg } = useSelectedOrg();
  const { currentUser } = useCurrentUserContext();
  const [postDetails, setPostDetails] = useState();

  const handleRetrieveDetails = async () => {
    const data = await handleRetrievePostDetails(
      currentUser,
      employeesData,
      update,
      selectedOrg,
      postDetails
    );
    const { userSignedPhrase, ...rest } = data;
    // setSignaturePhrase(userSignedPhrase);
    setPostDetails(rest);
  };

  useEffect(() => {
    if (update && employeesData && currentUser && !postDetails) {
      handleRetrieveDetails();
    }
  }, [update, employeesData, currentUser, postDetails]);

  return (
    <Fragment key={`${uniqueId('recent')}-${update.post_id}`}>
      <ListItem
        sx={{
          display: 'flex',
          gap: 1
        }}
        key={update.post_id}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              width: 54,
              height: 54
            }}
            variant="rounded"
            src={
              postDetails &&
              postDetails.author &&
              postDetails.author.avatar &&
              postDetails.author.avatar
            }
          >
            {postDetails &&
              postDetails.author &&
              postDetails.author.name &&
              postDetails.author.name[0]}
          </Avatar>
        </ListItemAvatar>
        <Stack gap={0.5} sx={{ width: '100%', position: 'relative' }}>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.8rem' }}
            color="text.secondary"
          >
            {moment(update.created_at.seconds * 1000).format('DD/MM/YYYY')}
          </Typography>
          <Typography
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
              maxWidth: 190,
              wordWrap: 'break-word'
            }}
            variant="body1"
            color="text"
          >
            {update.description}
          </Typography>
          {update.signature_required && (
            <Button
              variant="text"
              sx={{ position: 'absolute', right: -10, top: '20%' }}
              onClick={() =>
                handleViewCommunityAttachedFile(
                  { ...update, postDetails },
                  containsSomeLabels(
                    update.labels || jobsTitles.map((el) => el.name),
                    [getUserJobTitle(currentUser, update.org_ref)]
                  )
                )
              }
            >
              צפיה
            </Button>
          )}
        </Stack>
      </ListItem>
    </Fragment>
  );
};

export default RecentUpdateItem;
