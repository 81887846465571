import { CloseOutlined } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import getTransitRoute from 'src/utils/getTransitRoute';

const OrgDirectionsDialog = ({ open, onClose, selectedHome }) => {
  OrgDirectionsDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    selectedHome: PropTypes.any
  };

  const [route, setRoute] = useState();

  const handleRetrieveDirectionsForThisHome = async () => {
    const retrievedRoute = await getTransitRoute();
    console.log(retrievedRoute);
    setRoute(retrievedRoute);
  };

  useEffect(() => {
    if (selectedHome) {
      handleRetrieveDirectionsForThisHome();
    }
  }, [selectedHome]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="inherit">הוראות הגעה</Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {JSON.stringify(selectedHome)}
        <Button onClick={() => handleRetrieveDirectionsForThisHome()}>
          route
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default OrgDirectionsDialog;
