/**
 * Password validator for login pages
 */
// import value from 'src/assets/scss/_themes-vars.module.scss';

// has number
const hasNumber = (number) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
const hasMixed = (number) =>
  new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars
const hasSpecial = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

// set color based on password strength
export const strengthColor = (count, themePalette) => {
  if (count < 2) return { label: 'חלשה מאוד', color: themePalette.error.main };
  if (count < 3) return { label: 'חלשה', color: themePalette.warning.dark };
  if (count < 4) return { label: 'רגילה', color: themePalette.warning.main };
  if (count < 5) return { label: 'טובה', color: themePalette.success.light };
  if (count < 6) return { label: 'חזקה', color: themePalette.success.dark };
  return { label: 'Poor', color: themePalette.errorMain };
};

// password strength indicator
export const strengthIndicator = (number) => {
  let strengths = 0;
  if (number.length > 5) strengths += 1;
  if (number.length > 7) strengths += 1;
  if (hasNumber(number)) strengths += 1;
  if (hasSpecial(number)) strengths += 1;
  if (hasMixed(number)) strengths += 1;
  return strengths;
};
