const getLastSearchFromLS = () => {
  const recentSearch = localStorage.getItem('search');
  const parsed = JSON.parse(recentSearch);
  if (parsed && parsed.length > 0) {
    return parsed[0];
  }
  return null;
};

export default getLastSearchFromLS;
