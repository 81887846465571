import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import PropType from 'prop-types';

export default function StepsCard({ info }) {
  StepsCard.propTypes = {
    info: PropType.object
  };
  return (
    <Card
      sx={{
        // width: { xs: '80vw', sm: '40vw' },
        pb: 2,
        overflow: 'unset',
        borderRadius: 1,
        flex: 1,
        maxWidth: '600px',
        mx: 2
      }}
    >
      <CardMedia
        component="img"
        loading="lazy"
        alt="green iguana"
        height="160"
        sx={{ objectFit: 'cover', borderRadius: 1 }}
        image={info.img}
      />
      <CardContent>
        {/* <Typography gutterBottom variant="h5" component="div"> */}
        {info.title}
        {/* </Typography> */}
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        {info.btn}
      </CardActions>
    </Card>
  );
}
