import {
  AccountBalanceWalletOutlined,
  AssuredWorkloadOutlined,
  HealthAndSafetyOutlined,
  RequestQuoteOutlined,
  VolunteerActivismOutlined
} from '@mui/icons-material';
import i18n from 'src/i18n';

const available_funds = [
  {
    name: 'private',
    label: i18n.t('availableFunds.private'),
    icon: <AccountBalanceWalletOutlined />,
    order: 1
  },
  {
    name: 'health_ministry',
    label: i18n.t('availableFunds.healthMinistry'),
    icon: <HealthAndSafetyOutlined />,
    order: 2
  },
  {
    name: 'welfare_ministry',
    label: i18n.t('availableFunds.welfareMinistry'),
    icon: <VolunteerActivismOutlined />,
    order: 3
  },
  {
    name: 'pre_moh_code',
    label: i18n.t('availableFunds.preMOHCode'),
    icon: <RequestQuoteOutlined />,
    order: 4
  },
  {
    name: 'mod_code',
    label: i18n.t('availableFunds.MODCode'),
    icon: <AssuredWorkloadOutlined />,
    order: 4
  }
];

export default available_funds;
