const caregiverDefaultOnboarding = [
  {
    id: 'major-caregiver-0',
    major_task: 'הכרת המבנה',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description:
          'סיור במחלקה: תחנת אחיות, שירותים לצוות, לאורחים, חדרי דיירים, מחסנים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-0'
      },
      {
        description:
          'סיור בבניין: דלפק, שעון נוכחות, מטבח, חדר אשפה, חדר כביסה, מלתחות, מכון פיזיותרפיה, בית מרקחת',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-1'
      }
    ]
  },
  {
    id: 'major-caregiver-18',
    major_task: 'אדמניסטרציה',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'חוזה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-18'
      },
      {
        description: 'הכנת כרטיס עובד',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-19'
      },
      {
        description: 'כרטיס החתמת שעון',
        responsibiltyOf: 'משאבי אנוש',
        id: 'sub-task664'
      }
    ]
  },
  {
    id: 'major-caregiver-2',
    major_task: 'דרכי תקשורת',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description:
          'טלפון מחלקה, טלפון אחראי מחלקה, דלפק ודרכי קשר עם מחלקות אחרות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-2'
      }
    ]
  },
  {
    id: 'major-caregiver-3',
    major_task: 'נהלי עבודה',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'נוהל סידור עבודה ובקשות, נוהל החתמת שעון',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-3'
      },
      {
        description: 'נוהל קבלת משמרת',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-4'
      },
      {
        description: 'נוהל העברת משמרת',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-5'
      },
      {
        description: 'נוהל סודיות רפואית',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-6'
      },
      {
        description: 'נוהל שפה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-7'
      },
      {
        description: 'נוהל ענידת תג שם והופעה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-8'
      },
      {
        description: 'נוהל דיווח',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-9'
      },
      {
        description: 'נוהל שטיפת ידיים ומניעת זיהומים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-10'
      },
      {
        description: 'נוהל שימוש והיענות לפעמונים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-11'
      }
    ]
  },
  {
    id: 'major-caregiver-12',
    major_task: 'תפקידים, הכרת צוות',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'תפקיד מטפלים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-12'
      },
      {
        description: 'תפקיד אחות משמרת',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-13'
      },
      {
        description: 'תפקיד אחראי מחלקה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-14'
      },
      {
        description: 'תפקיד אם בית',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-15'
      }
    ]
  },
  {
    id: 'major-caregiver-16',
    major_task: 'סדר יום',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'סדר יום מחלקתי למטפלים והפסקות, עישון והתנהגות נאותה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-16'
      },
      {
        description: 'סדר יום לדיירים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-17'
      }
    ]
  },
  {
    id: 'major-caregiver-20',
    major_task: 'תיאום ציפיות',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'לבוש והופעה חיצונית, הפסקות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-20'
      },
      {
        description: 'סידור עבודה (ימי ושעות עבודה), מתן בקשות לסידור',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-21'
      },
      {
        description: 'קריאת נהלי עבודה (כללי התנהגות, נהלים כללים ופרטניים)',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-22'
      },
      {
        description: 'השתתפות בישיבות צוות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-23'
      },
      {
        description: 'השתתפות בחת"ש',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-24'
      }
    ]
  },
  {
    id: 'major-caregiver-25',
    major_task: 'דיווח שוטף',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'הסתכלות ודיווח על חריגים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-25'
      },
      {
        description: 'דיווח על נפילות, חבלות או תאונות אחרות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-26'
      },
      {
        description: 'זיהוי פצעים וסימני עור',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-27'
      }
    ]
  },
  {
    id: 'major-caregiver-28',
    major_task: 'טיפול בדייר',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'היגיינת הפה ושיניים תותבות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-28'
      },
      {
        description: 'הפעלה/ תעסוקה, צפייה בטלויזיה, משחק',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-29'
      },
      {
        description: 'ליווי בתוך ומחוץ למוסד',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-30'
      }
    ]
  },
  {
    id: 'major-caregiver-31',
    major_task: 'טיפול ביחידת הדייר',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'איוורור החדרים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-31'
      },
      {
        description: 'ניקיון וסידור היחידה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-32'
      },
      {
        description: 'ניקיון יסודי של החדר',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-33'
      },
      {
        description: 'ניקיון וסדר ארון בגדים וארון אישי',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-34'
      },
      {
        description: 'ניקיון וסדר מיטת הדייר',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-35'
      },
      {
        description: 'שמירת חפצי דייר ומאכליו',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-36'
      },
      {
        description: 'ניקיון יסודי של אמצעי העזר: עמודים, כסאות, עגלות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-37'
      },
      {
        description: 'מיון כביסה וסידורה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-38'
      },
      {
        description: 'סימון כביסה (לרבות בידוד)',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-39'
      },
      {
        description: 'טיפול בכביסה מלוכלכת',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-40'
      },
      {
        description: 'טיפול בפסולת',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-41'
      }
    ]
  },
  {
    id: 'major-caregiver-42',
    major_task: 'התייחסות לקשישים',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'גישה לקשישים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-42'
      },
      {
        description: 'מחלות קשישים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-43'
      },
      {
        description: 'מגבלות קשישים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-44'
      },
      {
        description: 'שימור יכולת תפקודית',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-45'
      },
      {
        description: 'שיתוף פעולה עם צוות רב מיקצועי',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-46'
      },
      {
        description: 'שימוש במכשיר עזר ( כולל טיפול במכשיר שמיעה )',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-47'
      }
    ]
  },
  {
    id: 'major-caregiver-48',
    major_task: 'הפרשות שתן',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'אי שליטה',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-48'
      },
      {
        description: 'עצירות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-49'
      },
      {
        description: 'קטטר',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-50'
      },
      {
        description: 'פנרוס',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-51'
      },
      {
        description: 'טיפול בשקית שתן',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-52'
      },
      {
        description: 'הגשת סיר ובקבוק',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-53'
      },
      {
        description: 'חיטוי סיר ובקבוק',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-54'
      },
      {
        description: 'שתן - צבע, איכות וכמות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-55'
      },
      {
        description: 'דיווח על חריגים',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-56'
      },
      {
        description: 'הסתכלות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-57'
      }
    ]
  },
  {
    id: 'major-caregiver-58',
    major_task: 'זיהומים',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        description: 'מניעת זיהומים, הגיינת ידיים והחלפת כפפות',
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-58'
      }
    ]
  },
  {
    id: 'major-caregiver-59',
    major_task: 'הדרכות',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-59',
        description: 'הדרכת נאמן נושא מניעת זיהומים'
      },
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-60',
        description: 'הדרכת בטיחות'
      },
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-61',
        description: 'הדרכת פיזיותרפיסט/ית'
      },
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-62',
        description: 'הדרכת עובד/ת סוציאלי/ת'
      },
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-63',
        description: 'הדרכת תזונאית'
      },
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-64',
        description: 'הדרכת מרפא בעיסוק'
      },
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-65',
        description: 'הדרכה על ידי אם בית'
      },
      {
        description: 'הדרכה בטופס ייעודי על ידי נאמן סיכונים',
        responsibiltyOf: 'נאמן ניהול סיכונים',
        id: 'sub-task667'
      },
      {
        description: 'הדרכה בטופס ייעודי על ידי נאמן מניעת זיהומים',
        responsibiltyOf: 'נאמן מניעת זיהומים',
        id: 'sub-task668'
      },
      {
        description: 'הדרכה בנושא מניעת אלימות',
        responsibiltyOf: 'עו"ס המחלקה',
        id: 'sub-task666'
      }
    ]
  },
  {
    id: 'major-caregiver-66',
    major_task: 'ערכת הכנסה ונהלים',
    responsibilty_of: 'caregiver',
    sub_tasks: [
      {
        responsibiltyOf: 'caregiver',
        id: 'sub-caregiver-66',
        description: 'חתימה על קריאת ערכת הכנסה ונהלים'
      }
    ]
  },
  {
    id: 'major-task304',
    major_task: 'הגדרת תפקיד העובד המקצועי',
    responsibilty_of: 'אחות אחראית',
    sub_tasks: [
      {
        description: 'היכרות עם הצוות ודיירי המחלקה',
        responsibilty_of: 'אחות אחראית',
        id: 'sub-task620'
      }
    ]
  },
  {
    id: 'major-task306',
    major_task: 'מפגש עם הצוות הרב מקצועי',
    responsibilty_of: 'אחות מחלקה',
    sub_tasks: [
      {
        description: 'היכרות והצגת התפקידים',
        responsibiltyOf: 'אחות מחלקה',
        id: 'sub-task665'
      }
    ]
  }
];
export default caregiverDefaultOnboarding;
