import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.css';
import QuestionSlide from '../../sharedPartials/QuestionSlide';
import getQuestionData from '../getQuestionData';
import preventingConstipationTest from './preventingConstipationTest.png';

/* eslint-disable */
const PreventingConstipation = ({
  handleCorrectAnswer,
  handleWrongAnswer,
  selectedCourseware,
  globalCoursewares,
  handleSetViewToSignCourseware
}) => {
  PreventingConstipation.propTypes = {
    handleCorrectAnswer: PropTypes.func,
    handleWrongAnswer: PropTypes.func,
    selectedCourseware: PropTypes.object,
    globalCoursewares: PropTypes.array,
    handleSetViewToSignCourseware: PropTypes.func
  };

  return (
    <div
      className="theme-font-montserrat theme-color-sea-yellow"
      style={{ width: '100%', height: '100%' }}
    >
      <div className="reveal preventingConstipation">
        <div className="slides">
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '497.52px',
                left: '41.74px',
                top: '137.75px',
                height: 'auto'
              }}
              data-origin-id="4ac11f9b8fef5224b8abce5d0245b5c6"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)',
                  padding: 16,
                  fontSize: '129%'
                }}
              >
                <h2>
                  <strong>לומדה בנושא:</strong>
                </h2>
                <h2>
                  <strong>מניעת עצירות</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 718, left: 242, top: '574.75px', height: 'auto' }}
              data-name="text-d18e72"
              data-origin-id="ba57120fd98c9bf1e26f1e188a5c1057"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'center',
                  padding: 16,
                  fontSize: '70%'
                }}
              >
                <h2>מגדלור ייעוץ והכוונה</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 581, height: '513.5px', left: 0, top: 0 }}
              data-name="shape-a85c0a"
              data-origin-id="cef06b973548d0848b621da4edcba639"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(27, 174, 225)"
                data-shape-stretch="true"
                style={{ zIndex: 11 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 581 514"
                >
                  <rect
                    width={581}
                    height="513.5"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(27, 174, 225)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 718,
                height: '166.5px',
                left: 242,
                top: '533.5px'
              }}
              data-name="shape-f3b59e"
              data-origin-id="1ca6e150cc503f05b00cefe47b8375a0"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 100, 66)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 718 167"
                >
                  <rect
                    width={718}
                    height="166.5"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(255, 100, 66)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 222, height: '166.5px', left: 0, top: '533.5px' }}
              data-origin-id="5a90a10f388fe0f5e4a7cbbe59bf02f3"
              data-name="shape-c5d015"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 18 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 222 167"
                >
                  <rect
                    width={222}
                    height="166.5"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 359, height: '513.5px', left: 601, top: 0 }}
              data-name="shape-b7e817"
              data-origin-id="2e029a9401a7b293ea3a24234a9e8b8d"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 15 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 359 514"
                >
                  <rect
                    width={359}
                    height="513.5"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '402.91px',
                left: '278.545px',
                top: '19.7662px',
                height: 'auto'
              }}
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)',
                  padding: 16,
                  fontSize: '129%'
                }}
              >
                <h2>כיצד נלמד ?</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 359,
                left: '300.5px',
                top: '574.75px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'center',
                  padding: 16,
                  fontSize: '70%'
                }}
              >
                <h2>מגדלור ייעוץ והכוונה</h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: 960,
                height: '501.256px',
                left: 0,
                top: '0.0004px'
              }}
              data-name="shape-a85c0a"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(27, 174, 225)"
                data-shape-stretch="true"
                style={{ zIndex: 11 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 960 501"
                >
                  <rect
                    width={960}
                    height="501.256"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(27, 174, 225)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 960, height: '166.5px', left: 0, top: '533.5px' }}
              data-name="shape-f3b59e"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 100, 66)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 960 167"
                >
                  <rect
                    width={960}
                    height="166.5"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(255, 100, 66)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '698.983px',
                left: '130.509px',
                top: '170.028px',
                height: 'auto'
              }}
              data-name="text-d9a7e8"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)',
                  padding: 16,
                  fontSize: '129%'
                }}
              >
                <p>ההתקדמות לאורך הלומדה היא באמצעות החצים.</p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '698.983px',
                left: '130.509px',
                top: '283.5px',
                height: 'auto'
              }}
              data-name="text-310559"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 15,
                  textAlign: 'center',
                  color: 'rgb(0, 0, 0)',
                  padding: 16,
                  fontSize: '129%'
                }}
              >
                <p>
                  כשמגיעים לשקופית שמכילה שאלה, אי אפשר להתקדם עד שעונים נכון
                  עליה.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 307, height: 699, left: 0, top: 1 }}
              data-name="shape-b7e817"
              data-origin-id="b303c35dcf5e99b6fb72014ccb4c63d9"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(27, 166, 225)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 307 699"
                >
                  <rect
                    width={307}
                    height={699}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(27, 166, 225)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '255.729px',
                left: '25.6355px',
                top: '165.794px',
                height: 'auto'
              }}
              data-name="text-e1c329"
              data-origin-id="eb0b99de563a24bb67e4cc2a9c1ce5db"
            >
              <a
                className="sl-block-style"
                style={{ zIndex: 17, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'center',
                    zIndex: 17,
                    color: 'rgb(0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    lineHeight: '1.586',
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '109%'
                  }}
                  data-has-line-height=""
                >
                  <h2>
                    <strong>עצירות, מה היא ?</strong>
                  </h2>
                </div>
              </a>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-b54905"
              data-origin-id="1e2c0e26f5bfff93d1b3137b0cc7c9c3"
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{ transformOrigin: '481.5px 349px 0px', zIndex: 18 }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 307,
                    height: 221,
                    left: '328.5px',
                    top: '238.5px'
                  }}
                  data-origin-id="1dd09dbdfea43e3ea52b7968331ca7d3"
                  data-name="shape-a80ec9"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 19 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 307 221"
                    >
                      <rect
                        width={307}
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 308,
                    left: '327.5px',
                    top: 299,
                    height: 'auto'
                  }}
                  data-origin-id="d9caa93d30892a61fd3e95e88947b262"
                  data-name="text-b0eaad"
                >
                  <a className="sl-block-style" style={{ zIndex: 20 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 20,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)'
                      }}
                    >
                      <p>הטלת צואה בשכיחות נמוכה.</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-20df29"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{ transformOrigin: '481px 112.5px 0px', zIndex: 14 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 307, height: 221, left: '327.5px', top: 2 }}
                  data-name="shape-ad52d9"
                  data-origin-id="bc8fe7842bba0ebf8bb962b90bf5c60b"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 15 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 307 221"
                    >
                      <rect
                        width={307}
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 307,
                    left: '327.5px',
                    top: '76.5px',
                    height: 'auto'
                  }}
                  data-name="text-45906b"
                  data-origin-id="20dcc8c73552a0343baf73de1eb77321"
                >
                  <a className="sl-block-style" style={{ zIndex: 16 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 16,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        lineHeight: '1.17',
                        borderColor: 'rgb(0, 0, 0)'
                      }}
                      data-has-line-height=""
                    >
                      <p>צואה קשה.</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-da813a"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{ transformOrigin: '806.5px 112.5px 0px', zIndex: 21 }}
                data-fragment-index={1}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 307, height: 221, left: 653, top: 2 }}
                  data-origin-id="3974276ac4c63801b036a0d2df3592bd"
                  data-name="shape-334abe"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 22 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 307 221"
                    >
                      <rect
                        width={307}
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 307,
                    left: 653,
                    top: '76.5px',
                    height: 'auto'
                  }}
                  data-origin-id="05e7fde43a151f68b1ccab1ed2db0ed8"
                  data-name="text-44842c"
                >
                  <a className="sl-block-style" style={{ zIndex: 23 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 23,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)'
                      }}
                    >
                      <p>היציאות הן פחות מ 2-3 פעמים בשבוע</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-00f2c7"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{ transformOrigin: '806.5px 349.5px 0px', zIndex: 11 }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 307, height: 222, left: 653, top: '238.5px' }}
                  data-origin-id="e9120dac5a2b2477cf5206dcf1736354"
                  data-name="shape-340321"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 12 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 307 222"
                    >
                      <rect
                        width={307}
                        height={222}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{ width: 307, left: 653, top: 300, height: 'auto' }}
                  data-name="text-58151f"
                >
                  <a className="sl-block-style" style={{ zIndex: 13 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 13,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        lineHeight: '1.17',
                        borderColor: 'rgb(0, 0, 0)'
                      }}
                      data-has-line-height=""
                    >
                      <p>מרווחי זמן ארוכים בין פעולות המעיים.</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-b74ae2"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment"
                style={{ transformOrigin: '643.75px 589.5px 0px', zIndex: 24 }}
                data-fragment-index={4}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '632.5px',
                    height: 221,
                    left: '327.5px',
                    top: 479
                  }}
                  data-origin-id="feb6ba5644bc9fbf89ee52bbf3bf0063"
                  data-name="shape-db2dbc"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 25 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 633 221"
                    >
                      <rect
                        width="632.5"
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '601.091px',
                    left: '344.761px',
                    top: '495.5px',
                    height: 'auto'
                  }}
                  data-name="text-9341a5"
                >
                  <a className="sl-block-style" style={{ zIndex: 26 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 26,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)'
                      }}
                    >
                      <p style={{ textAlign: 'justify' }}>
                        עצירות שנמשכת למעלה מחצי שנה נקראת עצירות כרונית, ועלולה
                        לגרום ל "כליאה" של צואה בחלחולת, להרחבת המעי הגס,
                        להיווצרות סעיפות "diverticulosis".
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section data-background-color="rgb(27, 166, 225)">
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: 180,
                top: '39.9928px'
              }}
              data-name="text-d526fb"
            >
              <div className="sl-block-content" style={{ zIndex: 10 }}>
                <h2>
                  <strong>מה גורם לעצירות?</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 600,
                left: '300.209px',
                top: '124.073px'
              }}
              data-name="text-c67347"
            >
              <div className="sl-block-content" style={{ zIndex: 11 }}>
                <p style={{ textAlign: 'right' }}>
                  עצירות נחשבת לתסמין ולא מחלה.
                  <br />
                  שני סוגים של עצירות:
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-f6a417"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '315.268px 434.351px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 307,
                    left: '161.768px',
                    top: '221.047px',
                    height: 'auto'
                  }}
                  data-origin-id="1fcab4e7546319c2ee00c7ac84e6cda3"
                  data-name="text-45d9a2"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 15,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '140%',
                      padding: 20
                    }}
                  >
                    <h2>
                      <strong>1.</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 307,
                    height: '426.608px',
                    left: '161.768px',
                    top: '221.047px'
                  }}
                  data-origin-id="6c81929541e2f7ab46490e99232b4cd8"
                  data-name="shape-dded40"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="#ffffff"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 307 427"
                    >
                      <rect
                        width={307}
                        height="426.608"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="#ffffff"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 307,
                    left: '161.768px',
                    top: '381.743px',
                    height: 'auto'
                  }}
                  data-origin-id="0a49fc22924c71ffa41d8384fd24161b"
                  data-name="text-b42b25"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 14,
                      textAlign: 'left',
                      fontSize: '60%',
                      color: 'rgb(0, 0, 0)',
                      padding: 20
                    }}
                  >
                    <h2 style={{ textAlign: 'justify' }}>
                      עצירות תפקודית שנובעת מהפרעה בתפקוד של המעי הגס.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              style={{ height: 'auto' }}
              data-name="group-7124d0"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '653.637px 434.351px 0px',
                  zIndex: 16
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 307,
                    left: '500.137px',
                    top: '221.047px',
                    height: 'auto'
                  }}
                  data-origin-id="98dc47ffde3db06ee866e7dd1766396d"
                  data-name="text-f06fde"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 19,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '140%',
                      padding: 20
                    }}
                  >
                    <h2>
                      <strong>2.</strong>
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 307,
                    height: '426.608px',
                    left: '500.137px',
                    top: '221.047px'
                  }}
                  data-origin-id="8e6b7cc05efee7874f1d86ff0c827289"
                  data-name="shape-ff6385"
                >
                  <div
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="#ffffff"
                    data-shape-stretch="true"
                    style={{ zIndex: 17 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 307 427"
                    >
                      <rect
                        width={307}
                        height="426.608"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="#ffffff"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 307,
                    left: '500.137px',
                    top: '381.743px',
                    height: 'auto'
                  }}
                  data-origin-id="9897c9c06496aec7362f7632c2824549"
                  data-name="text-76cf91"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 18,
                      textAlign: 'left',
                      fontSize: '60%',
                      color: 'rgb(0, 0, 0)',
                      padding: 20
                    }}
                  >
                    <h2 style={{ textAlign: 'justify' }}>
                      עצירות משנית שנובעת ממחלות אחרות, מתרופות ומסגנון חיים.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '153.17px',
                height: '960.386px',
                left: '403.223px',
                top: '-403.609px'
              }}
              data-name="shape-b7e817"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(90deg)' }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(27, 166, 225)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 153 960"
                  >
                    <rect
                      width="153.17"
                      height="960.386"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgb(27, 166, 225)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '100%',
                // left: '384.757px',
                top: '17.084px',
                height: 'auto'
              }}
              data-name="text-e1c329"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'right',
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  borderWidth: 4,
                  padding: 16,
                  lineHeight: '1.586',
                  borderColor: 'rgb(0, 0, 0)',
                  fontSize: '143%',
                  width: '100%'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'center', width: '100%' }}>
                  מה גורם לעצירות?
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-3dbe55"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment fade-out"
                style={{ transformOrigin: '479.808px 350px 0px', zIndex: 12 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '632.5px',
                    height: 221,
                    left: '163.558px',
                    top: '239.5px'
                  }}
                  data-name="shape-db2dbc"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 633 221"
                    >
                      <rect
                        width="632.5"
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '601.091px',
                    left: '179.298px',
                    top: '243.5px',
                    height: 'auto'
                  }}
                  data-name="text-9341a5"
                >
                  <a className="sl-block-style" style={{ zIndex: 14 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 14,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)',
                        fontSize: '84%'
                      }}
                    >
                      <p style={{ textAlign: 'justify' }}>
                        עצירות יכולה להיגרם גם בגלל מחלות מערכתיות, או בשל מחלות
                        שרירים או מחלות נוירולוגיות כגון טרשת נפוצה, מחלת
                        פרקינסון ושבץ, גידול הלוחץ על המעיים ועוד. תסמונת המעי
                        הרגיש (IBS למשל, מהווה את אחד הגורמים השכיחים ביותר)
                        לעצירות.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-c62d24"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{ transformOrigin: '480px 306px 0px', zIndex: 15 }}
                data-fragment-index={0}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '632.5px',
                    height: '135.264px',
                    left: '163.75px',
                    top: '238.368px'
                  }}
                  data-origin-id="48ecc16ee477e1aeb603ac5af09ba039"
                  data-name="shape-3e3005"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 16 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 633 135"
                    >
                      <rect
                        width="632.5"
                        height="135.264"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '601.091px',
                    left: '179.49px',
                    top: '242.368px',
                    height: 'auto'
                  }}
                  data-origin-id="a0f3827a5f33fd98261ea33ab7d74b29"
                  data-name="text-bc8494"
                >
                  <a className="sl-block-style" style={{ zIndex: 17 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 17,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)',
                        fontSize: '84%'
                      }}
                    >
                      <p style={{ textAlign: 'justify' }}>
                        עוויתות של המעי הגס מאיטות את מהירות הולכת תוכן המעיים,
                        ובעקבות כך מתפתחת עצירות.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-e7ca28"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{ transformOrigin: '479.808px 306px 0px', zIndex: 18 }}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-466437"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content fragment current-visible"
                    style={{
                      transformOrigin: '836.35px 211.848px 0px',
                      zIndex: 19
                    }}
                    data-fragment-index={1}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: '632.5px',
                        height: '135.264px',
                        left: '163.558px',
                        top: '238.368px'
                      }}
                      data-origin-id="7426d3476077db3e558074a036a348da"
                      data-name="shape-97798c"
                    >
                      <a
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                        data-shape-stretch="true"
                        style={{ zIndex: 20 }}
                        data-shape-corner-radius="30px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 633 135"
                        >
                          <rect
                            width="632.5"
                            height="135.264"
                            rx={30}
                            ry={30}
                            className="shape-element"
                            fill="rgba(255, 255, 255, 0.13)"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: '601.091px',
                        left: '179.298px',
                        top: 257,
                        height: 'auto'
                      }}
                      data-origin-id="dd38892b8ea89d5bc506a9c2ce01fa1c"
                      data-name="text-45c7e8"
                    >
                      <a className="sl-block-style" style={{ zIndex: 21 }}>
                        <div
                          className="sl-block-content"
                          style={{
                            textAlign: 'center',
                            zIndex: 21,
                            color: 'rgb(255, 255, 255)',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderWidth: 4,
                            padding: 16,
                            borderColor: 'rgb(0, 0, 0)',
                            fontSize: '84%'
                          }}
                        >
                          <p style={{ textAlign: 'justify' }}>
                            כמו כן, איבוד מלחים דרך הכליות, בהקאה או בשלשול עלול
                            לגרום לעצירות.
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-5a725b"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{
                  transformOrigin: '479.808px 325.635px 0px',
                  zIndex: 22
                }}
                data-fragment-index={2}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '632.5px',
                    height: '174.533px',
                    left: '163.558px',
                    top: '238.368px'
                  }}
                  data-origin-id="9f1d04a3d1f652a7cd163015c2de0953"
                  data-name="shape-851a47"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 23 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 633 175"
                    >
                      <rect
                        width="632.5"
                        height="174.533"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '601.091px',
                    left: '179.299px',
                    top: '238.368px',
                    height: 'auto'
                  }}
                  data-origin-id="3713f5febbb12e32a46e74a2668e1045"
                  data-name="text-c67f64"
                >
                  <a className="sl-block-style" style={{ zIndex: 24 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 24,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)',
                        fontSize: '84%'
                      }}
                    >
                      <p style={{ textAlign: 'justify' }}>
                        שימוש מוגזם במשלשלים - שימוש קבוע במשלשלים עלול לגרום
                        להתפתחות תלות במשלשלים, במינונים הולכים וגדלים והולכים.
                        בסופו-של-דבר עלול המצב להגיע לכך שהמעיים הופכים בלתי
                        רגישים לחלוטין ואינם מתפקדים כשורה.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-32ac08"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{
                  transformOrigin: '479.808px 288.816px 0px',
                  zIndex: 25
                }}
                data-fragment-index={3}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '632.5px',
                    height: '98.632px',
                    left: '163.558px',
                    top: '239.5px'
                  }}
                  data-origin-id="9f1d04a3d1f652a7cd163015c2de0953"
                  data-name="shape-11b992"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 26 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 633 99"
                    >
                      <rect
                        width="632.5"
                        height="98.632"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '601.091px',
                    left: '179.263px',
                    top: '256.316px',
                    height: 'auto'
                  }}
                  data-origin-id="3713f5febbb12e32a46e74a2668e1045"
                  data-name="text-f559c3"
                >
                  <a className="sl-block-style" style={{ zIndex: 27 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'center',
                        zIndex: 27,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)',
                        fontSize: '84%'
                      }}
                    >
                      <p style={{ textAlign: 'justify' }}>
                        הפרעות הורמונליות כגון תת-פעילות של בלוטת התריס.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-336a3f"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content fragment current-visible"
                style={{
                  transformOrigin: '471.19px 404.506px 0px',
                  zIndex: 28
                }}
                data-fragment-index={4}
              >
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-a74cb7"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '644.836px 282.742px 0px',
                      zIndex: 35
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 307,
                        height: 221,
                        left: '491.336px',
                        top: '172.242px'
                      }}
                      data-name="shape-334abe"
                    >
                      <a
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                        data-shape-stretch="true"
                        style={{ zIndex: 36 }}
                        data-shape-corner-radius="30px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 307 221"
                        >
                          <rect
                            width={307}
                            height={221}
                            rx={30}
                            ry={30}
                            className="shape-element"
                            fill="rgba(255, 255, 255, 0.13)"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 307,
                        left: '491.336px',
                        top: '246.742px',
                        height: 'auto'
                      }}
                      data-name="text-44842c"
                    >
                      <a className="sl-block-style" style={{ zIndex: 37 }}>
                        <div
                          className="sl-block-content"
                          style={{
                            textAlign: 'center',
                            zIndex: 37,
                            color: 'rgb(255, 255, 255)',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderWidth: 4,
                            padding: 16,
                            borderColor: 'rgb(0, 0, 0)'
                          }}
                        >
                          <p>תרופות.</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-9b2e32"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '644.336px 526.269px 0px',
                      zIndex: 32
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 307,
                        height: 221,
                        left: '491.336px',
                        top: '415.769px'
                      }}
                      data-name="shape-a80ec9"
                    >
                      <a
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                        data-shape-stretch="true"
                        style={{ zIndex: 33 }}
                        data-shape-corner-radius="30px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 307 221"
                        >
                          <rect
                            width={307}
                            height={221}
                            rx={30}
                            ry={30}
                            className="shape-element"
                            fill="rgba(255, 255, 255, 0.13)"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 308,
                        left: '490.336px',
                        top: '431.301px',
                        height: 'auto'
                      }}
                      data-name="text-b0eaad"
                    >
                      <a className="sl-block-style" style={{ zIndex: 34 }}>
                        <div
                          className="sl-block-content"
                          style={{
                            textAlign: 'center',
                            zIndex: 34,
                            color: 'rgb(255, 255, 255)',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderWidth: 4,
                            padding: 16,
                            borderColor: 'rgb(0, 0, 0)'
                          }}
                        >
                          <p style={{ textAlign: 'justify' }}>
                            כלכלה דלת סיבים, צריכה מועטה של נוזלים, דיאטה חריפה,
                            היעדר פעילות גופנית.
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-8fc94d"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '297.543px 283.242px 0px',
                      zIndex: 29
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 307,
                        height: 222,
                        left: '144.043px',
                        top: '172.242px'
                      }}
                      data-name="shape-340321"
                    >
                      <a
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                        data-shape-stretch="true"
                        style={{ zIndex: 30 }}
                        data-shape-corner-radius="30px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 307 222"
                        >
                          <rect
                            width={307}
                            height={222}
                            rx={30}
                            ry={30}
                            className="shape-element"
                            fill="rgba(255, 255, 255, 0.13)"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 307,
                        left: '144.043px',
                        top: '233.742px',
                        height: 'auto'
                      }}
                      data-name="text-58151f"
                    >
                      <a className="sl-block-style" style={{ zIndex: 31 }}>
                        <div
                          className="sl-block-content"
                          style={{
                            textAlign: 'center',
                            zIndex: 31,
                            color: 'rgb(255, 255, 255)',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderWidth: 4,
                            padding: 16,
                            lineHeight: '1.17',
                            borderColor: 'rgb(0, 0, 0)'
                          }}
                          data-has-line-height=""
                        >
                          <p>הריון.</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="sl-block sl-block-group"
                  data-block-type="group"
                  data-name="group-447ca5"
                  style={{ height: 'auto' }}
                >
                  <div
                    className="sl-block-content sl-block-group-content"
                    style={{
                      transformOrigin: '297.543px 526.269px 0px',
                      zIndex: 38
                    }}
                  >
                    <div
                      className="sl-block"
                      data-block-type="shape"
                      style={{
                        width: 307,
                        height: 221,
                        left: '144.043px',
                        top: '415.769px'
                      }}
                      data-name="shape-ad52d9"
                    >
                      <a
                        className="sl-block-content"
                        data-shape-type="rect"
                        data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                        data-shape-stretch="true"
                        style={{ zIndex: 39 }}
                        data-shape-corner-radius="30px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="100%"
                          height="100%"
                          preserveAspectRatio="none"
                          viewBox="0 0 307 221"
                        >
                          <rect
                            width={307}
                            height={221}
                            rx={30}
                            ry={30}
                            className="shape-element"
                            fill="rgba(255, 255, 255, 0.13)"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      className="sl-block"
                      data-block-type="text"
                      style={{
                        width: 307,
                        left: '144.043px',
                        top: '493.717px',
                        height: 'auto'
                      }}
                      data-name="text-45906b"
                    >
                      <a className="sl-block-style" style={{ zIndex: 40 }}>
                        <div
                          className="sl-block-content"
                          style={{
                            textAlign: 'center',
                            zIndex: 40,
                            color: 'rgb(255, 255, 255)',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderWidth: 4,
                            padding: 16,
                            lineHeight: '1.17',
                            borderColor: 'rgb(0, 0, 0)'
                          }}
                          data-has-line-height=""
                        >
                          <p>גיל מבוגר, חוסר ניידות.</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '153.17px',
                height: '960.386px',
                left: '403.223px',
                top: '-403.609px'
              }}
              data-name="shape-b7e817"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(90deg)' }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(27, 166, 225)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 153 960"
                  >
                    <rect
                      width="153.17"
                      height="960.386"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgb(27, 166, 225)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '639.469px',
                left: '156.589px',
                top: '17.084px',
                height: 'auto'
              }}
              data-name="text-e1c329"
            >
              <a
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 11,
                    color: 'rgb(0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    lineHeight: '1.586',
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '183%'
                  }}
                  data-has-line-height=""
                >
                  <p style={{ textAlign: 'right' }}>
                    סימנים וסימפטומים לעצירות
                  </p>
                </div>
              </a>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-741a70"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '480.001px 398.463px 0px',
                  zIndex: 12
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '672.521px',
                    height: '417.977px',
                    left: '143.74px',
                    top: '189.474px'
                  }}
                  data-name="shape-11b992"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 13 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 673 418"
                    >
                      <rect
                        width="672.521"
                        height="417.977"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '475.039px',
                    left: '242.481px',
                    top: '231.463px',
                    height: 'auto'
                  }}
                  data-name="text-f559c3"
                >
                  <a className="sl-block-style" style={{ zIndex: 14 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'right',
                        zIndex: 14,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)',
                        fontSize: '84%'
                      }}
                    >
                      <ul>
                        <li style={{ textAlign: 'justify' }}>
                          כאבים ונפיחות בטן
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          דימום בזמן פעילות מעיים
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>בחילות</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>איבוד תאבון</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>יציאות הכרוכות במאמץ</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>יציאות קשות או יבשות</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>הרגשה כללית רעה</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>הרגשה של חוסר התרוקנות לאחר היציאה</p>
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                viewBox="0 0 906 669"
              >
                <rect
                  width="905.737"
                  height="668.75"
                  rx={30}
                  ry={30}
                  className="shape-element"
                  fill="rgba(255, 255, 255, 0.13)"
                />
              </svg>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-6"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 6)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-3c39ef"
              style={{
                width: '909.29px',
                height: 447,
                left: '25.355px',
                top: '141.5px',
                minWidth: 1,
                minHeight: 1
              }}
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, borderStyle: 'solid', borderRadius: 33 }}
              >
                <img
                  data-natural-width={1011}
                  data-natural-height={497}
                  style={{}}
                  data-lazy-loaded=""
                  data-src={preventingConstipationTest}
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '909.29px',
                left: '25.355px',
                top: '36.5px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'left',
                  backgroundColor: 'rgba(255, 255, 255, 0.13)',
                  padding: 30,
                  fontSize: '90%',
                  borderStyle: 'solid',
                  borderRadius: 30
                }}
              >
                <p style={{ textAlign: 'center' }}>אומדן סיכון לעצירות</p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-3c39ef"
              style={{
                width: '909.29px',
                height: '232.819px',
                left: '25.355px',
                top: '192.269px',
                minWidth: 1,
                minHeight: 1
              }}
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 10, borderStyle: 'solid', borderRadius: 33 }}
                data-maintain-ar="false"
              >
                <img
                  data-natural-width={1008}
                  data-natural-height={133}
                  style={{}}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10898717/Screenshot_2023-11-07_101924.png"
                />
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '909.29px',
                left: '25.355px',
                top: '68.9923px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  textAlign: 'left',
                  backgroundColor: 'rgba(255, 255, 255, 0.13)',
                  padding: 30,
                  fontSize: '90%',
                  borderStyle: 'solid',
                  borderRadius: 30
                }}
              >
                <p style={{ textAlign: 'center' }}>אומדן עצירות</p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '840.568px',
                height: '166.5px',
                left: '59.7165px',
                top: '10.749px'
              }}
              data-name="shape-f3b59e"
              data-origin-id="30980e2c50700b4dea09d74b0cf4ae66"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 100, 66,0.85)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 841 167"
                >
                  <rect
                    width="840.568"
                    height="166.5"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 100, 66,0.85)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '640.204px',
                left: '159.899px',
                top: '10.749px',
                height: 'auto'
              }}
              data-name="text-d18e72"
              data-origin-id="3f3f6ff773fa8f8a285489140871179d"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'center',
                  padding: 16,
                  fontSize: '130%'
                }}
              >
                <p>
                  <strong>זיהוי מוקדם למטופל בסיכון לעצירות</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '673.011px',
                left: '143.495px',
                top: '93.749px'
              }}
              data-name="text-0eb6ad"
            >
              <div className="sl-block-content" style={{ zIndex: 15 }}>
                <p style={{ textAlign: 'right' }}>
                  על מנת לבצע זיהוי מוקדם לעצירות יש להכיר את הגורמים לעצירות:
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-933724"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '485.761px 382.463px 0px',
                  zIndex: 11
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '737.521px',
                    height: '385.977px',
                    left: 117,
                    top: '189.474px'
                  }}
                  data-name="shape-11b992"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 12 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 738 386"
                    >
                      <rect
                        width="737.521"
                        height="385.977"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '520.953px',
                    left: '219.525px',
                    top: '226.463px',
                    height: 'auto'
                  }}
                  data-name="text-f559c3"
                >
                  <a className="sl-block-style" style={{ zIndex: 13 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'right',
                        zIndex: 13,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)',
                        fontSize: '84%'
                      }}
                    >
                      <ul>
                        <li style={{ textAlign: 'justify' }}>
                          <p>דיאטות דלות בסיבים תזונתיים.</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>חוסר פעילות גופנית.</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>תרופות.</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>התעלמות מהדחף של פעולת מעיים.</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>הפרעות נוירולוגיות ומטבוליות.</p>
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          <p>
                            בעיות במערכת העיכול או הפרעות תפקודיות במערכת
                            העיכול.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '315.497px',
                left: '117.178px',
                top: '274.124px'
              }}
              data-name="text-833db4"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 16, transform: 'rotate(-22deg)' }}
              >
                <div
                  className="sl-block-content fragment fade-down"
                  style={{
                    zIndex: 16,
                    color: 'rgb(255, 100, 66)',
                    backgroundColor: 'rgb(252, 229, 205)',
                    borderStyle: 'solid',
                    borderWidth: 3,
                    borderRadius: 200,
                    padding: 27
                  }}
                  data-fragment-index={0}
                >
                  <h3>
                    כמעט כל מטופל
                    <br />
                    נמצא בסיכון
                    <br />
                    לעצירות לפי
                    <br />
                    הגורמים
                    <br />
                    האלו!!!
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '737.521px',
                height: '475.977px',
                left: '111.24px',
                top: '94.012px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 738 476"
                >
                  <rect
                    width="737.521"
                    height="475.977"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '696.953px',
                left: '131.524px',
                top: '206.5px',
                height: 'auto'
              }}
              data-name="text-f559c3"
            >
              <a className="sl-block-style" style={{ zIndex: 11 }}>
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 11,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '84%'
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>
                    מחקרים רבים מצביעים על כך, שמקרב כלל הקשישים, לפחות 26%
                    גברים ו-34% נשים סובלים מעצירות.
                  </p>
                  <p style={{ textAlign: 'justify' }}>
                    ישנן מספר סיבות לכך שעצירות נפוצה יותר, דווקא בקרב קשישים.
                  </p>
                  <p>&nbsp;</p>
                  <p style={{ textAlign: 'justify' }}>הסיבה לכך:</p>
                  <p />
                  <p style={{ textAlign: 'justify' }}>
                    עם השנים, מתרחשת ירידה בפעילות הגופנית וחילוף החומרים הופך
                    <br />
                    להיות איטי.
                  </p>
                </div>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: 549,
                left: '205.501px',
                top: '126.5px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(0, 0, 0)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>עצירות נפוצה יותר בקרב קשישים</strong>
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '522.521px',
                height: '475.977px',
                left: '218.24px',
                top: '94.012px'
              }}
              data-name="shape-11b992"
            >
              <div
                className="sl-block-style"
                style={{
                  zIndex: 10,
                  filter:
                    'drop-shadow(rgb(238, 238, 238) 0px 0px 13px) opacity(100%)'
                }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 523 476"
                  >
                    <rect
                      width="522.521"
                      height="475.977"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgba(255, 255, 255, 0.13)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '471.934px',
                height: 436,
                left: '243.534px',
                top: '114.001px'
              }}
              data-name="image-1e2861"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 11, borderStyle: 'solid', borderRadius: 15 }}
              >
                <img
                  className=""
                  data-natural-width={443}
                  data-natural-height={400}
                  data-crop-x={0}
                  data-crop-y={0}
                  data-crop-width="0.977352"
                  data-crop-height={1}
                  style={{ left: 0, top: 0, width: 483, height: 436 }}
                  data-lazy-loaded=""
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10898764/Screenshot_2023-11-07_111356.png"
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              style={{
                width: '466.667px',
                height: 700,
                left: 0,
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-name="image-c5c299"
              data-origin-id="8d92598305a6a1f71ba2e13984c64174"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  borderStyle: 'solid',
                  borderRadius: 30,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.4s'
                }}
                // data-animation-type="slide-up"
              >
                <img
                  data-natural-width={198}
                  data-natural-height={263}
                  style={{ left: 0, top: 0, width: 527, height: 700 }}
                  data-lazy-loaded=""
                  data-crop-x={0}
                  data-crop-y={0}
                  data-crop-width="0.885523"
                  data-crop-height={1}
                  data-src="https://s3.amazonaws.com/media-p.slid.es/uploads/2360976/images/10898768/Screenshot_2023-11-07_111743.png"
                />
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-91b047"
              style={{ height: 'auto' }}
              data-origin-id="ae1683bbc7e6ddf0ef64c00881778345"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '723px 350.5px',
                  zIndex: 10,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.5s'
                }}
                // data-animation-type="slide-up"
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: 433,
                    left: '506.5px',
                    top: 47,
                    height: 'auto'
                  }}
                  data-name="text-bad4e5"
                  data-origin-id="ed4c49f7336c0dfa693071da7055e18f"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 12,
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '99%'
                    }}
                  >
                    <h2 style={{ textAlign: 'justify' }}>
                      מתן יומי קבוע של מנת עגבניות מרוסקות עם שמן, תגרום לריכוך
                      הצואה ולהגברת היציאות - ע"י הוספת סיבים ושמנים טבעיים.
                    </h2>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 474, height: 699, left: 486, top: 1 }}
                  data-name="shape-b7e817"
                  data-origin-id="b63c91718e4c3321af90465b1a8ba580"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgb(216, 222, 220)"
                    data-shape-stretch="true"
                    style={{ zIndex: 11 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 474 699"
                    >
                      <rect
                        width={474}
                        height={699}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgb(216, 222, 220)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '342.5px',
                left: '396.75px',
                top: '16.5px',
                height: 'auto'
              }}
              data-name="text-c29943"
              data-origin-id="a807d38ae8bbf750a47347757adb30d9"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 13, lineHeight: '1.17' }}
                  data-has-line-height=""
                >
                  <h2 style={{ textAlign: 'center' }}>
                    <strong>איך מאבחנים</strong>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="table"
              data-name="table-aae143"
              style={{
                height: 'auto',
                width: '743.215px',
                left: '196.393px',
                top: '294.962px'
              }}
              data-origin-id="f29d57775d3b52e280903fc2fcbd862f"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, textAlign: 'right', fontSize: '95%' }}
                data-table-border-width="4px"
                data-table-padding="8px"
                data-table-rows={6}
                data-table-cols={1}
              >
                <table>
                  <tbody>
                    <tr>
                      <th style={{ padding: 8, borderWidth: 4 }}>
                        <strong>פחות משלוש יציאות בשבוע.</strong>
                      </th>
                    </tr>
                    <tr>
                      <td style={{ padding: 8, borderWidth: 4 }}>
                        לפחות ברבע מהיציאות יש צורך להפעיל מאמץ.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: 8, borderWidth: 4 }}>
                        לפחות ברבע מהיציאות הצואה היא קשה או גושית.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: 8, borderWidth: 4 }}>
                        לפחות ברבע מהיציאות יש תחושה של התרוקנות לא מלאה.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: 8, borderWidth: 4 }}>
                        קיימת תחושה של חסימה או של סתימה.
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: 8, borderWidth: 4 }}>
                        יש צורך בעזרה ידנית להשגת התרוקנות.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 784, height: 451, left: 176, top: 249 }}
              data-name="shape-ba0130"
              data-origin-id="54b2a063db05fa96396a87216bb30b5a"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(27, 173, 225)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 784 451"
                >
                  <rect
                    width={784}
                    height={451}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(27, 173, 225)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 784, height: 229, left: 176, top: 0 }}
              data-name="shape-b4110c"
              data-origin-id="34d198608d870d99f1f40d548b28a5a5"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 100, 66,0.85)"
                data-shape-stretch="true"
                style={{ zIndex: 11 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 784 229"
                >
                  <rect
                    width={784}
                    height={229}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 100, 66,0.85)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 157, height: 451, left: 0, top: 249 }}
              data-name="shape-957728"
              data-origin-id="09241b7412e3ffc144c878406ffbe140"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{
                  zIndex: 10,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-shape-corner-radius="30px"
                // data-animation-type="slide-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 157 451"
                >
                  <rect
                    width={157}
                    height={451}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 157, height: 229, left: 0, top: 0 }}
              data-name="shape-e64327"
              data-origin-id="a90ed22cc029c4575af8c7660ee212ee"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{
                  zIndex: 15,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.4s'
                }}
                data-shape-corner-radius="30px"
                // data-animation-type="slide-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 157 229"
                >
                  <rect
                    width={157}
                    height={229}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '743.215px',
                left: '196.393px',
                top: '96.095px'
              }}
              data-name="text-27bf6d"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 16, transform: 'rotate(0deg)' }}
              >
                <div className="sl-block-content" style={{ zIndex: 16 }}>
                  <p style={{ textAlign: 'justify' }}>
                    הרופא מאבחן עצירות אצל המטופל כאשר מתקיימים שניים או יותר
                    משש התופעות הבאות, במשך שלושה חודשים ברציפות במהלך חצי השנה
                    שקדמה לבדיקה:
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '407.576px',
                left: '188.213px',
                top: 81,
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 13, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{ textAlign: 'left', zIndex: 13, lineHeight: '1.17' }}
                  data-has-line-height=""
                >
                  <h2 style={{ textAlign: 'center' }}>
                    <strong>עצירות - סיבוכים</strong>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 784, height: 451, left: 0, top: 249 }}
              data-name="shape-ba0130"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(27, 173, 225)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 784 451"
                >
                  <rect
                    width={784}
                    height={451}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(27, 173, 225)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 784, height: 229, left: 0, top: 0 }}
              data-name="shape-b4110c"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 100, 66,0.85)"
                data-shape-stretch="true"
                style={{ zIndex: 11 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 784 229"
                >
                  <rect
                    width={784}
                    height={229}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 100, 66,0.85)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 157, height: 451, left: 803, top: 249 }}
              data-name="shape-957728"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{
                  zIndex: 10,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                data-shape-corner-radius="30px"
                // data-animation-type="slide-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 157 451"
                >
                  <rect
                    width={157}
                    height={451}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 157, height: 229, left: 803, top: 0 }}
              data-name="shape-e64327"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{
                  zIndex: 14,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.4s'
                }}
                data-shape-corner-radius="30px"
                // data-animation-type="slide-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 157 229"
                >
                  <rect
                    width={157}
                    height={229}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '672.444px',
                left: '55.779px',
                top: '284.728px'
              }}
              data-name="text-22da90"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 15, fontSize: '120%' }}
              >
                <ul>
                  <li>
                    <p>זיהומים חוזרים בדרכי השתן ואי שליטה.</p>
                  </li>
                  <li>
                    <p>אבני צואה ותחושה רעה.</p>
                  </li>
                  <li>
                    <p>הרחבת והתנקבות מעי גס /כיב בחולחולת.</p>
                  </li>
                  <li>
                    <p>תרופות וניתוחים מיותרים.</p>
                  </li>
                  <li>
                    <p>טחורים.</p>
                  </li>
                  <li>
                    <p>גידולי המעי הגס.</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 906 669"
                >
                  <rect
                    width="905.737"
                    height="668.75"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 12,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-15"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 15)}
              />
            </div>
          </section>

          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 906 669"
                >
                  <rect
                    width="905.737"
                    height="668.75"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-16"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 16)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-6be669"
              style={{
                width: 54,
                height: 54,
                left: '25.2281px',
                top: '31.5px'
              }}
              data-origin-id="76338e5878582577ef511765bf45970b"
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-plus-circle"
                data-shape-fill-color="rgb(0, 0, 0)"
                data-shape-stretch="true"
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(0, 0, 0)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '349.67px',
                left: '553.672px',
                top: 0,
                height: 'auto'
              }}
              data-name="text-e6f20f"
              data-origin-id="9627ce63b0954fcb84ae9b8bb8398b4e"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 12,
                  padding: 25,
                  lineHeight: '1.17',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'right' }}>
                  <strong>דרכי טיפול</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '851.291px',
                left: '63.2782px',
                top: '104.967px',
                height: 'auto'
              }}
              data-name="text-101edc"
              data-origin-id="6c483c4c42e2fc5ae7823d51015dfa05"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  lineHeight: '1.69',
                  padding: 8,
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
              >
                <p style={{ textAlign: 'right' }}>
                  מטרת הטיפול בעצירות היא להגביר את תכולת המים בצואה ולהגביר את
                  ניעות המעי. שינויים באורח החיים ובהרגלי האכילה מקובל לטפל
                  בעצירות בהדרגה.
                  <br />
                  השלב הראשון כולל:
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 960, height: 700, left: 0, top: 0 }}
              data-name="shape-093598"
              data-origin-id="40243e12e65cea559c744c3874f371b6"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 100, 66,0.85)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 960 700"
                >
                  <rect
                    width={960}
                    height={700}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 100, 66,0.85)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '851.291px',
                left: '63.2782px',
                top: '272.967px',
                height: 'auto'
              }}
              data-name="text-d81a70"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  padding: 8,
                  color: 'rgb(0, 0, 0)'
                }}
              >
                <p style={{ textAlign: 'justify' }}>
                  1. שינויים בהרגלי האכילה - למשל, הקפדה על שתייה מספקת, צריכה
                  מוגברת של סיבים תזונתיים ושל מאכלים שיש להם השפעה מיטיבה כמו
                  רסק עגבניות, שזיפים מיובשים ופירות הדר.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  2. שינויים באורח החיים - ניסיונות להעביר את פעולת המעיים לאחר
                  ארוחות, בעיקר לאחר ארוחת הבוקר, שכן אז התנועתיות של המעי עולה
                  באופן טבעי.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  נוסף על כך מומלצת פעילות גופנית אירובית למי שמסוגל לכך.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-6be669"
              style={{
                width: 54,
                height: 54,
                left: '25.2281px',
                top: '31.5px'
              }}
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-plus-circle"
                data-shape-fill-color="rgb(0, 0, 0)"
                data-shape-stretch="true"
                style={{ zIndex: 11 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(0, 0, 0)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: '423.342px', left: 480, top: 0, height: 'auto' }}
              data-name="text-e6f20f"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 12,
                  padding: 25,
                  lineHeight: '1.17',
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'right' }}>
                  <strong>סיבים תזונתיים</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '744.467px',
                left: '107.767px',
                top: '137.5px',
                height: 'auto'
              }}
              data-name="text-101edc"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  lineHeight: '1.69',
                  padding: 8,
                  color: 'rgb(0, 0, 0)'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    הגברת הצריכה של סיבים מסיסים ולא מסיסים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    גורמים להקטנת זמן המעבר במעי, הגברת תנועתיות המעי וריכוך
                    הצואה.
                    <br />
                    <br />
                    ההמלצה היומית:
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    25 גרם לנשים ו- 38 גרם לגברים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    הוספת הסיבים צריכה להיות הדרגתית- למניעת אי נוחות, גזים,
                    נפיחות ושלשול.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 960, height: 700, left: 0, top: 0 }}
              data-name="shape-093598"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 100, 66,0.85)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 960 700"
                >
                  <rect
                    width={960}
                    height={700}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 100, 66,0.85)"
                  />
                </svg>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '153.17px',
                height: '960.386px',
                left: '403.223px',
                top: '-403.609px'
              }}
              data-name="shape-b7e817"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(90deg)' }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(27, 166, 225)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 153 960"
                  >
                    <rect
                      width="153.17"
                      height="960.386"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgb(27, 166, 225)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '547.38px',
                left: '206.118px',
                top: '27.084px',
                height: 'auto'
              }}
              data-name="text-e1c329"
            >
              <a
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 11,
                    color: 'rgb(0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    lineHeight: '1.586',
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '140%'
                  }}
                  data-has-line-height=""
                >
                  <p>מזונות עשירים בסיבים תזונתיים</p>
                </div>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '644.28px',
                height: '417.977px',
                left: '174.456px',
                top: '190.702px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 15 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 644 418"
                >
                  <rect
                    width="644.28"
                    height="417.977"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '554.547px',
                left: '219.323px',
                top: '212.487px',
                height: 'auto'
              }}
              data-name="text-f559c3"
            >
              <a className="sl-block-style" style={{ zIndex: 16 }}>
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 16,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '84%'
                  }}
                >
                  <ul>
                    <li>דגנים מלאים:</li>
                  </ul>
                  <p
                    style={{
                      textAlign: 'justify'
                    }}
                  >
                    לחם מחיטה מלאה, בורגול, אורז מלא, דוחן, קינואה, שיבולת שועל,
                    פסטה מחיטה מלאה.
                    <br />
                    דגני בוקר עם סיבים: ברנפלקס, גרנולה, Fiber One, All Bran,
                    ביופלקס.
                  </p>
                  <ul>
                    <li>
                      <p>קטניות:</p>
                      <p>
                        סויה, אפונה, עדשים, שעועית, חומוס, פול (במידה ואינם
                        גורמים לגזים או נפיחות).
                      </p>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '153.17px',
                height: '960.386px',
                left: '403.223px',
                top: '-403.609px'
              }}
              data-name="shape-b7e817"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(90deg)' }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(27, 166, 225)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 153 960"
                  >
                    <rect
                      width="153.17"
                      height="960.386"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgb(27, 166, 225)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '547.38px',
                left: '206.118px',
                top: '27.084px',
                height: 'auto'
              }}
              data-name="text-e1c329"
            >
              <a
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 11,
                    color: 'rgb(0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    lineHeight: '1.586',
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '140%'
                  }}
                  data-has-line-height=""
                >
                  <p>מזונות עשירים בסיבים תזונתיים</p>
                </div>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '644.28px',
                height: '417.977px',
                left: '174.456px',
                top: '190.702px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 644 418"
                >
                  <rect
                    width="644.28"
                    height="417.977"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '554.547px',
                left: '219.323px',
                top: '251.778px',
                height: 'auto'
              }}
              data-name="text-f559c3"
            >
              <a className="sl-block-style" style={{ zIndex: 13 }}>
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 13,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '84%'
                  }}
                >
                  <ul>
                    <li>
                      <p>
                        פירות וירקות: (במידה ואינם נויטרופנים) רצוי עם הקליפה.
                        <br />
                        <br />
                        &nbsp;
                      </p>
                    </li>
                    <li>פירות יבשים:</li>
                  </ul>
                  <p>שזיפים, תאנים, משמשים, תמרים, שקדים, אגוזים, בוטנים.</p>
                </div>
              </a>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '826.079px',
                height: 700,
                left: '66.9605px',
                top: 0
              }}
              data-name="shape-c138f8"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 255, 255)"
                data-shape-stretch="true"
                style={{ zIndex: 11 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 826 700"
                >
                  <rect
                    width="826.079"
                    height={700}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(255, 255, 255)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 470,
                left: '245.001px',
                top: '76.145px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'center',
                  zIndex: 12,
                  color: 'rgb(0, 0, 0)',
                  padding: 25,
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2>
                  <strong>שתייה מרובה</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-6be669"
              style={{
                width: 54,
                height: 54,
                left: '136.963px',
                top: '30.0314px'
              }}
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-plus-circle"
                data-shape-fill-color="rgb(0, 0, 0)"
                data-shape-stretch="true"
                style={{ zIndex: 13 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(0, 0, 0)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '727.179px',
                left: '88.5045px',
                top: '193.145px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  textAlign: 'left',
                  lineHeight: '1.69',
                  color: 'rgb(0, 0, 0)',
                  padding: 8,
                  fontSize: '125%'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    להרבות בשתיית נוזלים - לפחות 2 ליטר ביום.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    מומלץ להמעיט בשתיית תה וקפה. עדיפות לתה צמחים.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    חשוב להקפיד על השתייה במיוחד בעת צריכת דיאטה עשירה בסיבים,
                    כדי למנוע החמרה בעצירות.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '826.079px',
                height: 700,
                left: '66.9605px',
                top: 0
              }}
              data-name="shape-c138f8"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 255, 255)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 826 700"
                >
                  <rect
                    width="826.079"
                    height={700}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(255, 255, 255)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 470,
                left: '245.001px',
                top: '76.145px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  padding: 25,
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'center' }}>
                  <strong>הרגלי חיים</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-6be669"
              style={{
                width: 54,
                height: 54,
                left: '136.963px',
                top: '30.0314px'
              }}
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-plus-circle"
                data-shape-fill-color="rgb(0, 0, 0)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(0, 0, 0)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '727.179px',
                left: '88.5045px',
                top: 245,
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  lineHeight: '1.69',
                  color: 'rgb(0, 0, 0)',
                  padding: 8,
                  fontSize: '125%'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li>אכילת ארוחות מסודרות בזמנים קבועים.</li>
                  <li>הליכה לשירותים בזמנים קבועים.</li>
                  <li>במידה וניתן, שילוב של פעילות גופנית מתונה.</li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '826.079px',
                height: 700,
                left: '66.9605px',
                top: 0
              }}
              data-name="shape-c138f8"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 255, 255)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 826 700"
                >
                  <rect
                    width="826.079"
                    height={700}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(255, 255, 255)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: 470,
                left: '245.001px',
                top: '47.9043px',
                height: 'auto'
              }}
              data-name="text-c29943"
            >
              <div
                className="sl-block-content"
                style={{
                  textAlign: 'left',
                  zIndex: 11,
                  color: 'rgb(0, 0, 0)',
                  padding: 25,
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h2 style={{ textAlign: 'center' }}>
                  <strong>המלצות נוספות</strong>
                </h2>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              data-name="shape-6be669"
              style={{
                width: 54,
                height: 54,
                left: '136.963px',
                top: '30.0314px'
              }}
            >
              <div
                className="sl-block-content"
                data-shape-type="symbol-plus-circle"
                data-shape-fill-color="rgb(0, 0, 0)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 32 32"
                >
                  <g
                    className="shape-element"
                    fill="rgb(0, 0, 0)"
                    data-viewbox="0 0 32 32"
                  >
                    <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z" />
                  </g>
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '727.179px',
                left: '109.045px',
                top: '164.904px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 13,
                  textAlign: 'left',
                  lineHeight: '1.43',
                  color: 'rgb(0, 0, 0)',
                  padding: 8,
                  fontSize: '125%'
                }}
                data-has-line-height=""
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    הוספת סובין למזונות כגון יוגורט, לבן, מרק ועוד.
                    <br />
                    מינון: כ-2 כפות ליום.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    תוספת זרעי פשתן טחונים למזונות שונים.
                    <br />
                    מינון: כף ביום.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    דייסיבית - אבקה עשירה בסיבים, ניתן לערבב עם נוזלים לפי
                    ההוראות.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    הוספת סיבים כגון פסיליום, Benefiber, לאחר התייעצות עם
                    דיאטנית.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 906 669"
                >
                  <rect
                    width="905.737"
                    height="668.75"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-24"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 24)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '153.17px',
                height: '960.386px',
                left: '403.223px',
                top: '-403.609px'
              }}
              data-name="shape-b7e817"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(90deg)' }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(27, 166, 225)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 153 960"
                  >
                    <rect
                      width="153.17"
                      height="960.386"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgb(27, 166, 225)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '262.517px',
                left: '348.742px',
                top: '27.084px',
                height: 'auto'
              }}
              data-name="text-e1c329"
            >
              <a
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 11,
                    color: 'rgb(0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    lineHeight: '1.586',
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '140%'
                  }}
                  data-has-line-height=""
                >
                  <p style={{ textAlign: 'center' }}>טיפול תרופתי</p>
                </div>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '838.281px',
                height: '516.206px',
                left: '41.8477px',
                top: '171.056px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 838 516"
                >
                  <rect
                    width="838.281"
                    height="516.206"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '793.98px',
                left: '63.9982px',
                top: '189.474px',
                height: 'auto'
              }}
              data-name="text-f559c3"
            >
              <a className="sl-block-style" style={{ zIndex: 13 }}>
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 13,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '90%'
                  }}
                >
                  <p style={{ textAlign: 'justify' }}>משלשלים יוצרי נפח:</p>
                  <ul>
                    <li style={{ textAlign: 'justify' }}>
                      חומרים עתירי שארית מזון שאינם נספגים במעי. חומרים אלה
                      סופגים אליהם מים במעי (hydrophilic colloids). מגדילים נפח
                      צואה, גורמים לריכוך הצואה והגברת ניעות המעי, משלשלים אלה
                      מחליפים את הסיבים התזונתיים. יש להעלות בהדרגה את צריכת
                      הסיבים התזונתיים ולהקפיד על שתיית כמות נוזלים מספקת.
                      <br />
                      תכשירים כמו:
                    </li>
                    <li style={{ textAlign: 'justify' }}>Psyllium seed.</li>
                    <li style={{ textAlign: 'justify' }}>Methylcellulose.</li>
                    <li style={{ textAlign: 'justify' }}>Bran.</li>
                  </ul>
                  <p style={{ textAlign: 'justify' }}>
                    לטיפול בעצירות במיוחד אצל זקנים בעלי הרגלי תזונה&nbsp;
                    לקויים, ובלוקים בחולשת שרירי הבטן.
                  </p>
                </div>
              </a>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '153.17px',
                height: '960.386px',
                left: '403.223px',
                top: '-403.609px'
              }}
              data-name="shape-b7e817"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(90deg)' }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(27, 166, 225)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 153 960"
                  >
                    <rect
                      width="153.17"
                      height="960.386"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgb(27, 166, 225)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '262.517px',
                left: '348.742px',
                top: '27.084px',
                height: 'auto'
              }}
              data-name="text-e1c329"
            >
              <a
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 11,
                    color: 'rgb(0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    lineHeight: '1.586',
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '140%'
                  }}
                  data-has-line-height=""
                >
                  <p style={{ textAlign: 'center' }}>טיפול תרופתי</p>
                </div>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '838.281px',
                height: '516.206px',
                left: '41.8477px',
                top: '171.056px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 838 516"
                >
                  <rect
                    width="838.281"
                    height="516.206"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '793.98px',
                left: '63.9982px',
                top: '190.702px',
                height: 'auto'
              }}
              data-name="text-f559c3"
            >
              <a className="sl-block-style" style={{ zIndex: 13 }}>
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 13,
                    color: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '90%'
                  }}
                >
                  <ul>
                    <li style={{ textAlign: 'justify' }}>
                      <strong>משלשלים אוסמוטיים Osmotic Laxatives-</strong>
                      <br />
                      תמיסות בעלות לחץ אוסמוטי גבוה, ספיגתם ממערכת העיכול היא
                      מזערית ואיטית, גורמים להיווצרות לחץ אוסמוטי גבוה בחלל המעי
                      ומכאן למעבר מים מנוזל הדם לחלל המעי.
                      <br />
                      &nbsp;
                    </li>
                    <li style={{ textAlign: 'justify' }}>
                      Magnesium Sulfate
                      <br />
                      משלשלים אלה עלולים לגרום לאובדן ניכר של נוזלים, יש להקפיד
                      על שתיית נוזלים בכמות מספקת במשך טיפול.
                      <br />
                      רצוי להימנע מלטפל בתרופה זו בחולים הלוקים באי ספיקת כליות,
                      עלול לגרום לחולשת שרירים ודיכוי נשימתי.
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 906 669"
                >
                  <rect
                    width="905.737"
                    height="668.75"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-27"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 27)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '153.17px',
                height: '960.386px',
                left: '403.223px',
                top: '-403.609px'
              }}
              data-name="shape-b7e817"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 10, transform: 'rotate(90deg)' }}
              >
                <a
                  className="sl-block-content"
                  data-shape-type="rect"
                  data-shape-fill-color="rgb(27, 166, 225)"
                  data-shape-stretch="true"
                  style={{ zIndex: 10 }}
                  data-shape-corner-radius="30px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 153 960"
                  >
                    <rect
                      width="153.17"
                      height="960.386"
                      rx={30}
                      ry={30}
                      className="shape-element"
                      fill="rgb(27, 166, 225)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '262.517px',
                left: '348.742px',
                top: '27.084px',
                height: 'auto'
              }}
              data-name="text-e1c329"
            >
              <a
                className="sl-block-style"
                style={{ zIndex: 11, transform: 'rotate(0deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    textAlign: 'right',
                    zIndex: 11,
                    color: 'rgb(0, 0, 0)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 4,
                    padding: 16,
                    lineHeight: '1.586',
                    borderColor: 'rgb(0, 0, 0)',
                    fontSize: '140%'
                  }}
                  data-has-line-height=""
                >
                  <p style={{ textAlign: 'center' }}>טיפול תרופתי</p>
                </div>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '838.281px',
                height: '516.206px',
                left: '41.8477px',
                top: '171.056px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 12 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 838 516"
                >
                  <rect
                    width="838.281"
                    height="516.206"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '721.558px',
                left: '95.278px',
                top: '215.081px'
              }}
              data-name="text-041c04"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 13, textAlign: 'right' }}
              >
                <ul>
                  <li style={{ textAlign: 'right' }}>
                    <strong>משלשלים ממריצים</strong>
                  </li>
                </ul>
                <p>
                  אם אין תגובה יש להוסיף על הטיפול הקודם משלשל ממריץ כמו
                  Bisacodyl כל 3-2 ימים, לפי הצורך או בקביעות.
                </p>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '721.558px',
                left: '95.278px',
                top: '371.385px'
              }}
              data-name="text-33b464"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 14, textAlign: 'right' }}
              >
                <ul>
                  <li>
                    <strong>תרופות חדשות</strong>
                  </li>
                </ul>
                <p style={{ textAlign: 'justify' }}>
                  אם עדיין אין תגובה, מומלץ להפסיק את הטיפול הקודם ולנסות טיפול
                  בתרופות חדשות לעצירות כמו רזולור או אמיטיזה המסייעות להגביר את
                  תנועתיות המעי.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  אם גם לאחר נטילת התרופות האלה המצב אינו משתפר, יש לפנות לייעוץ
                  ולבירור גסטרואנטרולוגי.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 906 669"
                >
                  <rect
                    width="905.737"
                    height="668.75"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-29"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 29)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '737.521px',
                height: '475.977px',
                left: '111.24px',
                top: '94.012px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 738 476"
                >
                  <rect
                    width="737.521"
                    height="475.977"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '708.622px',
                left: '112.69px',
                top: '155.501px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{ zIndex: 12, color: 'rgb(238, 238, 238)' }}
              >
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    במצבים שבהם קיימת הצטברות של גושי צואה נוקשים (אבני צואה),
                    יש צורך להוציאם - פעולה שעושים אחות או רופא. לאחר מכן
                    מתחילים בטיפול קבוע במשלשלים כדי למנוע את היווצרותם החוזרת
                    של הגושים.
                    <br />
                    &nbsp;
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    במקרה של פגיעה בתפקוד של שרירי רצפת האגן ניתן להציע טיפולים
                    בביופידבק (שיטה שמאפשרת ללמוד כיצד להפעיל את השרירים בצורה
                    נכונה) או באמצעות הזרקות מקומיות של בוטוקס (כדי לגרום
                    להרפיית השרירים).
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '840.568px',
                height: '166.5px',
                left: '59.7165px',
                top: '10.749px'
              }}
              data-name="shape-f3b59e"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 100, 66)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 841 167"
                >
                  <rect
                    width="840.568"
                    height="166.5"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(255, 100, 66)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '462.165px',
                left: '248.545px',
                top: '48.499px',
                height: 'auto'
              }}
              data-name="text-d18e72"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 16,
                  textAlign: 'center',
                  padding: 16,
                  fontSize: '150%'
                }}
              >
                <p>
                  <strong>חשוב לשים לב!</strong>
                </p>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-933724"
              style={{ height: 'auto' }}
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '485.761px 382.463px 0px',
                  zIndex: 11
                }}
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: '737.521px',
                    height: '385.977px',
                    left: 117,
                    top: '189.474px'
                  }}
                  data-name="shape-11b992"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 14 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 738 386"
                    >
                      <rect
                        width="737.521"
                        height="385.977"
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '664.612px',
                    left: '153.455px',
                    top: '248.463px',
                    height: 'auto'
                  }}
                  data-name="text-f559c3"
                >
                  <a className="sl-block-style" style={{ zIndex: 15 }}>
                    <div
                      className="sl-block-content"
                      style={{
                        textAlign: 'right',
                        zIndex: 15,
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 4,
                        padding: 16,
                        borderColor: 'rgb(0, 0, 0)'
                      }}
                    >
                      <ul>
                        <li style={{ textAlign: 'justify' }}>
                          מטופל עם שינויי פתאומי בהרגלי פעולת מעיים (מטופל שסובל
                          מעצירות ובאופן פתאומי מתחיל לשלשל, נמצא במצוקה...
                          לבדוק אותו).
                        </li>
                        <li style={{ textAlign: 'justify' }}>
                          לבדוק לקיים מעקב קפדני אחרי פעולות המעיים באופן יומי,
                          לתעד ברישומים ולהתייחס בהתאם לכך במידה ולא נצפתה פעולת
                          מעיים במהלך 3 הימים האחרונים.
                        </li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 906 669"
                >
                  <rect
                    width="905.737"
                    height="668.75"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-32"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 32)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{
                width: '905.737px',
                height: '668.75px',
                left: '30.2017px',
                top: '14.397px'
              }}
              data-name="shape-11b992"
            >
              <a
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 906 669"
                >
                  <rect
                    width="905.737"
                    height="668.75"
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgba(255, 255, 255, 0.13)"
                  />
                </svg>
              </a>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                height: 'auto',
                width: '137.668px',
                left: '759.643px',
                top: '44.2336px'
              }}
              data-name="text-6869e2"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  color: 'rgb(255, 100, 66)',
                  fontSize: '135%'
                }}
              >
                <p>
                  <strong>שאלה</strong>
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '110px'
              }}
            >
              <QuestionSlide
                key="preventingConstipation-33"
                handleCorrectAnswer={handleCorrectAnswer}
                selectedCourseware={selectedCourseware}
                handleWrongAnswer={handleWrongAnswer}
                {...getQuestionData(globalCoursewares, selectedCourseware, 33)}
              />
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="image"
              data-name="image-3c39ef"
              style={{
                width: '283.556px',
                height: 700,
                left: '676.444px',
                top: 0,
                minWidth: 1,
                minHeight: 1
              }}
              data-origin-id="392c3f17b55c4c714accbbb386f1d1d0"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 10,
                  borderStyle: 'solid',
                  borderRadius: 30,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.5s'
                }}
                // data-animation-type="slide-up"
                data-maintain-ar="false"
              >
                <img
                  data-natural-width={1920}
                  data-natural-height={1280}
                  style={{ left: 0, top: 0, width: 638, height: 700 }}
                  data-lazy-loaded=""
                  data-crop-x={0}
                  data-crop-y={0}
                  data-crop-width="0.444445"
                  data-crop-height={1}
                  data-src="https://images.unsplash.com/photo-1552688468-d87e6f7a58f2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDcwNnwwfDF8c2VhcmNofDE0N3x8YWJzdHJhY3R8ZW58MHx8fHwxNjkzMjI2NTIxfDA&ixlib=rb-4.0.3&q=80&w=1920&utm_source=slides&utm_medium=referral&utm_campaign=api-credit"
                />
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-dcc743"
              style={{ height: 'auto' }}
              data-origin-id="e0f56e00be344d0289ed9c6e76c338fd"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '325px 349.5px 0px',
                  zIndex: 11,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.4s'
                }}
                // data-animation-type="slide-up"
              >
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '594.488px',
                    left: '27.756px',
                    top: '17.5922px',
                    height: 'auto'
                  }}
                  data-name="text-bad4e5"
                  data-origin-id="f46594f437b0fe21e24244b106b3b11a"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      textAlign: 'left',
                      zIndex: 13,
                      padding: 10,
                      lineHeight: '1.17',
                      color: 'rgb(0, 0, 0)',
                      fontSize: '110%'
                    }}
                    data-has-line-height=""
                  >
                    <p style={{ textAlign: 'justify' }}>
                      <strong>
                        לסיכום, מספר הצעות שנמצאו מוכחות במניעת עצירות:
                      </strong>
                    </p>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-d18e72"
                  data-origin-id="24ac29740d95dfa2feddaf57f441b55b"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 14,
                      textAlign: 'left',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <ul>
                      <li
                        style={{ textAlign: 'justify' }}
                        className="fragment fade-out"
                        data-fragment-index={0}
                      >
                        להגביר פעילות גופנית או תרגילים פסיביים המתאימים לתוכנית
                        חינוך המעיים מחדש. התרגילים מעוררים את הדחף להפריש
                        ומתניעים את הצואה לרקטום.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 650, height: 699, left: 0, top: 0 }}
                  data-name="shape-b7e817"
                  data-origin-id="16af756ee9c1a364370fe0104ebd7280"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="#a4a5bf"
                    data-shape-stretch="true"
                    style={{ zIndex: 12 }}
                    data-shape-corner-radius="30px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 650 699"
                    >
                      <rect
                        width={650}
                        height={699}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="#a4a5bf"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-e00dca"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 15,
                      textAlign: 'left',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <ul>
                      <li
                        style={{ textAlign: 'justify' }}
                        className="fragment current-visible"
                        data-fragment-index={0}
                      >
                        תנועתיות, פעילות גופנית סדירה תעזור להסדרת פעילות המעי.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-afcf83"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 16,
                      textAlign: 'left',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <ul>
                      <li
                        className="current-visible fragment"
                        data-fragment-index={1}
                        style={{ textAlign: 'justify' }}
                      >
                        מומלץ מאוד להתעמל באופן קבוע: תנועת מעיים של המעי הדק
                        מגורה ע"י תרגול קבוע.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-fcdc1c"
                >
                  <div
                    className="sl-block-content fragment"
                    style={{
                      zIndex: 17,
                      textAlign: 'left',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                    data-fragment-index={6}
                  >
                    <ul>
                      <li style={{ textAlign: 'justify' }}>
                        פעילות גופנית אירובית (הליכה, ריצה, שחיה) בת 30 דקות
                        ביום תורמת ליציאות תקינות.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-af2d73"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 18,
                      textAlign: 'left',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <ul>
                      <li
                        className="fragment current-visible"
                        data-fragment-index={2}
                        style={{ textAlign: 'justify' }}
                      >
                        נשימות באמצעות הסרעפת ותרגול שרירי הבטן. עוזרים לחיזוק
                        השרירים אשר נחוצים לפעולת ההפרשה.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-ff3954"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 19,
                      textAlign: 'left',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <ul>
                      <li
                        className="fragment current-visible"
                        data-fragment-index={3}
                      >
                        בעת הישיבה בשירותים, מומלץ להרים מעט את הרגליים, על ידי
                        שימוש בהדום קטן.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-9a5cad"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 20,
                      textAlign: 'right',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <ul>
                      <li
                        className="fragment current-visible"
                        data-fragment-index={4}
                      >
                        תזונה נכונה עשירה בסיבים.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="sl-block"
                  data-block-type="text"
                  style={{
                    width: '573.304px',
                    left: '27.756px',
                    top: '193.5px',
                    height: 'auto'
                  }}
                  data-name="text-17df15"
                >
                  <div
                    className="sl-block-content"
                    style={{
                      zIndex: 21,
                      textAlign: 'right',
                      padding: 10,
                      color: 'rgb(0, 0, 0)',
                      fontSize: '120%'
                    }}
                  >
                    <ul>
                      <li
                        className="fragment current-visible"
                        data-fragment-index={5}
                      >
                        שתייה מרובה.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ width: 582, left: 52, top: '145.5px', height: 'auto' }}
              data-name="text-260be3"
              data-origin-id="b2f5646253665e7c9444cae127fd9ebb"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 11,
                  fontSize: '110%',
                  textAlign: 'left',
                  lineHeight: '1.17'
                }}
                data-has-line-height=""
              >
                <h1 style={{ textAlign: 'right' }}>
                  <strong>תודה רבה!</strong>
                </h1>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{
                width: '489.846px',
                left: '144.165px',
                top: '293.5px',
                height: 'auto'
              }}
              data-name="text-d18e72"
              data-origin-id="df150e5f8954feabac05b82c0652ae54"
            >
              <div
                className="sl-block-style"
                style={{ zIndex: 12, transform: 'rotate(1deg)' }}
              >
                <div
                  className="sl-block-content"
                  style={{
                    zIndex: 12,
                    textAlign: 'left',
                    padding: 8,
                    fontSize: '120%'
                  }}
                >
                  <p style={{ textAlign: 'right' }}>
                    לחתימה על ביצוע הלומדה לחץ למטה
                  </p>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 686, height: 700, left: 0, top: 0 }}
              data-name="shape-c138f8"
              data-origin-id="f5a7bcfad0a266ebc41ecef9187a7f1e"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(27, 166, 225)"
                data-shape-stretch="true"
                style={{ zIndex: 10 }}
                data-shape-corner-radius="30px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 686 700"
                >
                  <rect
                    width={686}
                    height={700}
                    rx={30}
                    ry={30}
                    className="shape-element"
                    fill="rgb(27, 166, 225)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-661a01"
              data-origin-id="8da9ed8548ab407a490f2f3cd33d1ea1"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '833px 589.5px',
                  zIndex: 13,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.5s'
                }}
                // data-animation-type="slide-left"
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 254, height: 221, left: 706, top: 479 }}
                  data-name="shape-98daa6"
                  data-origin-id="4a8941b7214c783b81b0169b7b6572d7"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 14 }}
                    data-shape-corner-radius="30px"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 254 221"
                    >
                      <rect
                        width={254}
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 94,
                    height: '89.8594px',
                    left: 786,
                    top: '542.093px'
                  }}
                  data-name="shape-671cfc"
                  data-origin-id="378bd698ba05f22101bff72285da9b78"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="symbol-heart-fill"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="false"
                    style={{ zIndex: 15 }}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 1.999999761581421 32 28"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        data-viewbox="0 1.999999761581421 32 28"
                      >
                        <path d="M16 5.844c-1.613-2.266-4.129-3.844-7.113-3.844-4.903 0-8.887 3.992-8.887 8.891v0.734l16.008 18.375 15.992-18.375v-0.734c0-4.899-3.984-8.891-8.887-8.891-2.984 0-5.5 1.578-7.113 3.844z" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-cc7324"
              data-origin-id="22ef6fc48e12df862a2743ae1ea1dceb"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '833px 348.5px',
                  zIndex: 16,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.4s'
                }}
                // data-animation-type="slide-left"
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 254, height: 221, left: 706, top: 238 }}
                  data-origin-id="f1e0625c8fbf5cde23aa5e6b5b09015e"
                  data-name="shape-b7a73e"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 17 }}
                    data-shape-corner-radius="30px"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 254 221"
                    >
                      <rect
                        width={254}
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 94,
                    height: '89.8594px',
                    left: 786,
                    top: '301.093px'
                  }}
                  data-origin-id="97db545f0ee84ac8609d56d683eb8a21"
                  data-name="shape-e46ddb"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="symbol-sun"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="false"
                    style={{ zIndex: 18 }}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M16.001 8c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.418 0 7.999-3.582 7.999-8s-3.581-8-7.999-8v0zM14 2c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM4 6c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM2 14c1.105 0 2 0.895 2 2 0 1.107-0.895 2-2 2s-2-0.893-2-2c0-1.105 0.895-2 2-2zM4 26c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM14 30c0-1.109 0.895-2 2-2 1.108 0 2 0.891 2 2 0 1.102-0.892 2-2 2-1.105 0-2-0.898-2-2zM24 26c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2zM30 18c-1.104 0-2-0.896-2-2 0-1.107 0.896-2 2-2s2 0.893 2 2c0 1.104-0.896 2-2 2zM24 6c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2-1.105 0-2-0.895-2-2z" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block sl-block-group"
              data-block-type="group"
              data-name="group-a86e61"
              data-origin-id="29cb8d12d0ce6d07ff72299a576b75e6"
            >
              <div
                className="sl-block-content sl-block-group-content"
                style={{
                  transformOrigin: '833px 110.5px',
                  zIndex: 19,
                  transitionDuration: '0.6s',
                  transitionDelay: '0.3s'
                }}
                // data-animation-type="slide-left"
              >
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{ width: 254, height: 221, left: 706, top: 0 }}
                  data-origin-id="0cb187cb5ed535c8291e36926b552808"
                  data-name="shape-19e892"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="rect"
                    data-shape-fill-color="rgba(255, 255, 255, 0.13)"
                    data-shape-stretch="true"
                    style={{ zIndex: 20 }}
                    data-shape-corner-radius="30px"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 254 221"
                    >
                      <rect
                        width={254}
                        height={221}
                        rx={30}
                        ry={30}
                        className="shape-element"
                        fill="rgba(255, 255, 255, 0.13)"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="sl-block"
                  data-block-type="shape"
                  style={{
                    width: 94,
                    height: '89.8594px',
                    left: 786,
                    top: '63.093px'
                  }}
                  data-origin-id="b3a778ffc54478753acc5a9bd91f4f1b"
                  data-name="shape-0f6080"
                >
                  <a
                    className="sl-block-content"
                    data-shape-type="symbol-star"
                    data-shape-fill-color="rgb(255, 255, 255)"
                    data-shape-stretch="false"
                    style={{ zIndex: 21 }}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid"
                      viewBox="0 0 32 32"
                    >
                      <g
                        className="shape-element"
                        fill="rgb(255, 255, 255)"
                        data-viewbox="0 0 32 32"
                      >
                        <path d="M22.137 19.625l9.863-7.625h-12l-4-12-4 12h-12l9.875 7.594-3.875 12.406 10.016-7.68 9.992 7.68z" />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="shape"
              style={{ width: 237, height: 8, left: 69, top: '270.952px' }}
              data-name="shape-225e18"
              data-origin-id="ca2c16cfdbd3a0bee532aeb7f574e16a"
            >
              <div
                className="sl-block-content"
                data-shape-type="rect"
                data-shape-fill-color="rgb(255, 255, 255)"
                data-shape-stretch="true"
                style={{ zIndex: 22 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                  viewBox="0 0 237 8"
                >
                  <rect
                    width={237}
                    height={8}
                    rx={0}
                    ry={0}
                    className="shape-element"
                    fill="rgb(255, 255, 255)"
                  />
                </svg>
              </div>
            </div>
            <div
              className="sl-block"
              data-block-type="text"
              style={{ height: 'auto', width: '100%', bottom: 150, left: 25 }}
              data-name="button-f3216ab"
            >
              <div
                className="sl-block-content"
                style={{
                  zIndex: 14,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSetViewToSignCourseware()}
                  sx={{ minWidth: 160, fontSize: '1.15rem' }}
                  // disabled={!didReachTheEnd}
                >
                  חתימה על סיום הלומדה
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PreventingConstipation;
