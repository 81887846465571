import {
  AttachFileOutlined,
  DescriptionOutlined,
  SendOutlined
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import theme from 'src/theme';
import NewPostLabels from './AddNewPost/NewPostLabels';
import AttachNewFileToPost from './AttachNewFileToPost';
import AttachedFileList from './AttachedFileList';

const AddNewPostCard = ({ handleSaveNewPost, existingPostDetails }) => {
  AddNewPostCard.propTypes = {
    handleSaveNewPost: PropTypes.func,
    existingPostDetails: PropTypes.func
  };
  const [newPostValues, setNewPostValues] = useState({
    description: '',
    signature_required: false
  });

  const [attachNewFileOpen, setAttachNewFileOpen] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState();
  useEffect(() => {
    if (existingPostDetails) {
      setNewPostValues(existingPostDetails);
    }
  }, [existingPostDetails]);

  const handleChangeNewPostValues = (e) => {
    setNewPostValues({ ...newPostValues, [e.target.name]: e.target.value });
  };
  const handleOpenAttachNewFileToPost = () => {
    setAttachNewFileOpen(true);
  };
  const handleCloseAttachNewFileToPost = () => {
    setAttachNewFileOpen(false);
  };
  const handleRemoveAttachedFile = () => {
    setAttachedFiles();
    const temp = { ...newPostValues };
    delete temp.file_name;
    delete temp.file;
    setNewPostValues({ ...temp });
  };

  const getTimeOfPost = (post) => {
    const { last_modified, created_at } = post;
    const format = 'HH:mm DD/MM/YYYY';
    if (last_modified) {
      return moment(last_modified.seconds * 1000).format(format);
    }
    if (created_at) {
      return moment(created_at.seconds * 1000).format(format);
    }
    return 'אין מידע';
  };

  return (
    <>
      <Card elevation={0}>
        <CardHeader
          title={!existingPostDetails && 'עדכון חדש'}
          subheader={
            existingPostDetails &&
            `שינוי אחרון היה ב- ${getTimeOfPost(existingPostDetails)}`
          }
          sx={{
            pb: 0
          }}
        />
        <CardContent>
          <TextField
            value={newPostValues.description}
            placeholder="הוסף את התיאור של עדכון זה.."
            variant="standard"
            sx={{
              backgroundColor: theme.palette.background.default,
              maxWidth: theme.breakpoints.values.md,
              borderRadius: 1
            }}
            fullWidth
            multiline
            name="description"
            onChange={handleChangeNewPostValues}
            rows={4}
            autoFocus
            InputProps={{
              startAdornment: (
                <DescriptionOutlined
                  htmlColor={lighten(theme.palette.info.light, 0.35)}
                />
              ),
              disableUnderline: Boolean(true)
            }}
          />
          <TextField
            value={newPostValues.duration}
            placeholder="מה משך עדכון זה? (בשעות)"
            helperText="כמה שעות ייספרו לעובד שעבר על עדכון זה - במסגרת סעיף עדכון ידע מקצועי"
            sx={{ maxWidth: 450, mt: 2 }}
            fullWidth
            name="duration"
            type="number"
            onChange={(e) => {
              handleChangeNewPostValues({
                target: {
                  value: parseFloat(e.target.value),
                  name: e.target.name
                }
              });
            }}
          />
          <NewPostLabels
            newPostValues={newPostValues}
            setNewPostValues={setNewPostValues}
          />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(newPostValues.valid_for_all_employees)}
                    onChange={(e, checked) =>
                      handleChangeNewPostValues({
                        target: {
                          name: 'valid_for_all_employees',
                          value: checked
                        }
                      })
                    }
                    inputProps={{
                      'aria-label':
                        'האם עובדים אחרים חוץ מהקבוצה שנדרשת לחתום מותר להם לצפות בעדכון?'
                    }}
                  />
                }
                label="האם עובדים אחרים חוץ מהקבוצה שנדרשת לחתום מותר להם לצפות בעדכון?"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(newPostValues.signature_required)}
                    onChange={(e, checked) =>
                      handleChangeNewPostValues({
                        target: {
                          name: 'signature_required',
                          value: checked
                        }
                      })
                    }
                    inputProps={{
                      'aria-label': 'האם נדרשת חתימה?'
                    }}
                  />
                }
                label="האם לדרוש חתימה ?"
              />
            </Grid>
          </Grid>
          {newPostValues && newPostValues.file && (
            <AttachedFileList
              attachedFiles={newPostValues.file}
              handleRemoveAttachedFile={handleRemoveAttachedFile}
              setAttachedFiles={setAttachedFiles}
              setNewPostValues={setNewPostValues}
              newPostValues={newPostValues}
            />
          )}
        </CardContent>
        <CardActions
          sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}
        >
          <Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                color: theme.palette.secondary.main,
                boxShadow: theme.shadows[1],
                '&:hover': {
                  backgroundColor: lighten(theme.palette.secondary.light, 0.65)
                }
              }}
              startIcon={<AttachFileOutlined />}
              onClick={handleOpenAttachNewFileToPost}
            >
              צרף קובץ
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <SendOutlined
                sx={{
                  transform: 'rotate(-45deg)'
                }}
              />
            }
            onClick={() => handleSaveNewPost(newPostValues, setNewPostValues)}
          >
            הוספה
          </Button>
        </CardActions>
      </Card>
      <AttachNewFileToPost
        newPostValues={newPostValues}
        setNewPostValues={setNewPostValues}
        open={attachNewFileOpen}
        onClose={handleCloseAttachNewFileToPost}
        handleRemoveAttachedFile={handleRemoveAttachedFile}
        attachedFiles={attachedFiles}
        setAttachedFiles={setAttachedFiles}
      />
    </>
  );
};

export default AddNewPostCard;
