const serviceProvidersTabsLabel = [
  {
    label: 'רופאים',
    value: 'doctor'
  },
  {
    label: 'עו"ס',
    value: 'social-worker'
  },
  {
    label: 'אחים ואחיות',
    value: 'nurse'
  }
];
export default serviceProvidersTabsLabel;
