const getEmployeesInSpecificDepartment = (department_id, employeesData) => {
  //   console.log(employeesData[0]);
  const employees = [];
  employeesData.forEach((emp) => {
    if (emp && emp.org_specific_details && emp.org_specific_details.rbac) {
      const indexOfDepartment = emp.org_specific_details.rbac.findIndex(
        (el) => el.department_id === department_id
      );
      if (indexOfDepartment > -1) {
        employees.push(emp);
      }
    }
  });
//   console.log(employees.flat());
  return employees;
};

export default getEmployeesInSpecificDepartment;
