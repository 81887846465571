import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
// Images
import { uniqueId } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cohen from 'src/assets/testimonials/cohen.webp';
import daniel from 'src/assets/testimonials/daniel.webp';
import esty from 'src/assets/testimonials/esty.webp';
import jacob from 'src/assets/testimonials/jacob.webp';
import theme from 'src/theme';
import BgBlob from './Testimonials/BgBlob';
import TestimonialsSlider from './Testimonials/Testimonials';

const slides = [
  {
    id: uniqueId('testimonial'),
    content:
      'מנאר יקרה תודה רבה עבור התמיכה שנתת לחברה שלי. זה מאד מאד עזר. הוכחת לי שוב שאת אישיות עם המון טוב לב וחמלה. ואני בטוחה שגם הדיירים והמשפחות  בבתי האבות מוכירים את אישותך. מעריכה מאד ענת 🌹🌹🌹',
    owner: 'morad',
    ownerDescription: 'first ownerDescription',
    img: esty
  },
  {
    id: uniqueId('testimonial'),
    content:
      'היה לי קשה למצוא את בית האבות המתאים לאמא שלי, אבל במגדלור עשו את זה הרבה יותר קל. הייתי מסוגל להשוות מחירים ותכונות של מתקנים שונים ובסופו של דבר מצאתי את המתאים ביותר עבורה. אני ממליץ מאוד על השירות הזה לכל מי שזקוק לעזרה.',
    owner: 'morad',
    ownerDescription: 'second ownerDescription',
    img: jacob
  },
  {
    id: uniqueId('testimonial'),
    content:
      'הצלחתי למצוא את הבית אבות המתאים בדיוק לפי הצרכים והתקציב שלי. השירות היה מקצועי והתמיכה הייתה מעולה. אני ממליץ בחום על האתר לכל מי שמחפש בית אבות ברמה גבוהה',
    owner: 'רבקה כהן',
    ownerDescription: 'third ownerDescription',
    img: cohen
  },
  {
    id: uniqueId('testimonial'),
    content:
      'התהליך היה חלק ונעים, ואני מאוד מרוצה מהבית אבות שמצאתי דרך מגדלור שעזרו וחיפשו את הבית המתאים ביותר לאחר שקיבלו ממני את הדרישות כל הדרישות ומאפיינים שרציתי שיהיו בבית שאליו אני עובר',
    owner: 'Daniel',
    ownerDescription: 'fourth ownerDescription',
    img: daniel
  }
];
function Testimonials() {
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('translation', {
    keyPrefix: 'mainPage.testimonials'
  });
  const [activeSlide, setActiveSlide] = useState(0);

  const handleChangeSlide = (diff) => {
    const active = activeSlide + diff;
    if (active > slides.length - 1) {
      setActiveSlide(0);
    } else if (active < 0) {
      setActiveSlide(slides.length - 1);
    } else {
      setActiveSlide(active);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '70vh',
        // flexDirection: 'column',
        // my: theme.breakpoints.down('md') ? 12 : 6,
        ':after': {
          content: 'open-quote',
          fontSize: '15rem',
          opacity: 0.3,
          position: 'relative',
          zIndex: 2,
          mt: 18,
          mb: matchMdDown ? 8 : 2,
          right: 'calc(-50% + 16rem)'
        },
        mt: 10
      }}
      component="section"
      // pb={12}
    >
      <Grid
        key="main-feedback-title"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          flexDirection: 'column',
          alignItems: 'center',
          pb: 2,
          position: 'absolute',
          '&::after': {
            position: 'absolute',
            height: '4px',
            bgcolor: theme.palette.primary.dark,
            width: '60px',
            mt: '8px',
            content: "''"
          }
        }}
        item
        xs={12}
      >
        <Typography
          variant="h1"
          sx={{
            display: 'flex'
            // px: 0
          }}
        >
          {t('feedback')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ pt: 3, fontFamily: 'inherit', fontSize: '1.2rem' }}
        >
          {t('feedbackDescription')}
        </Typography>
      </Grid>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'flex-end',
          position: 'absolute',
          // marginTop: 15,
          left: '17rem',
          bottom: '6rem',
          zIndex: 3,
          transform: 'rotate(90deg)'
        }}
        key="bg-dots-arr"
      >
        {[[...Array(9)], [...Array(9)], [...Array(9)], [...Array(9)]].map(
          (dotsCol, colIndex) => (
            <Stack key={uniqueId('dots')} direction="row">
              {dotsCol.map((dotsRowDot, rowIndex) => (
                <span
                  key={`${uniqueId('dotsrowdot')}-dotsRowDot`}
                  style={{
                    backgroundImage:
                      rowIndex < colIndex
                        ? `linear-gradient(to left, ${theme.palette.primary.light}, ${theme.palette.primary.light})`
                        : rowIndex > colIndex && rowIndex > colIndex * 2.5
                        ? `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
                        : `linear-gradient(to left, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                    width: '5px',
                    height: '5px',
                    borderRadius: '100%',
                    margin: '2px'
                  }}
                />
              ))}
            </Stack>
          )
        )}
      </Stack>
      <BgBlob />
      <TestimonialsSlider
        handleChangeSlide={handleChangeSlide}
        slides={slides}
        activeSlide={activeSlide}
      />
      <Divider sx={{ my: 6 }} />
    </Box>
  );
}

export default Testimonials;
