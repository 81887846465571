import { Avatar } from '@mui/material';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import homeLottie from 'src/assets/home.json';
import departmentsLottie from 'src/components/OrgPage/orgDetailsIcons/bed.json';
import cctvLottie from 'src/components/OrgPage/orgDetailsIcons/cctv.json';
import fallLottie from 'src/components/OrgPage/orgDetailsIcons/fall.json';
import fundsLottie from 'src/components/OrgPage/orgDetailsIcons/funds.json';
import ivTreatmentLottie from 'src/components/OrgPage/orgDetailsIcons/ivdrip.json';
import kitchenLottie from 'src/components/OrgPage/orgDetailsIcons/kitchen.json';
import kosherLottie from 'src/components/OrgPage/orgDetailsIcons/kosher.json';
import otherLottie from 'src/components/OrgPage/orgDetailsIcons/other.json';
import pricesLottie from 'src/components/OrgPage/orgDetailsIcons/prices.json';

const lotties = {
  home: homeLottie,
  departments: departmentsLottie,
  funds: fundsLottie,
  prices: pricesLottie,
  ivTreatment: ivTreatmentLottie,
  kitchen: kitchenLottie,
  kosher: kosherLottie,
  cctv: cctvLottie,
  fall: fallLottie,
  other: otherLottie
};

const ComparisonTableLottieSign = ({ lottie }) => {
  ComparisonTableLottieSign.propTypes = {
    lottie: PropTypes.string.isRequired
  };
  const size = '37px';
  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        backgroundColor: 'rgba(0,0,0,0)',
        margin: 'auto'
      }}
      variant="circular"
      className="entryDetailIcon"
    >
      <Lottie
        style={{
          width: '70% !important',
          height: size,
          minWidth: size
        }}
        autoplay
        // loop={4}
        animationData={lotties[lottie]}
      />
    </Avatar>
  );
};

export default ComparisonTableLottieSign;
