import { Alert, Snackbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" style={{ direction: 'rtl' }} />;
}
const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  SnackbarProvider.propTypes = {
    children: PropTypes.any
  };
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    msg: '',
    duration: 4000,
    severity: 'success'
  });

  const snackbarStateData = useMemo(
    () => ({ snackbarState, setSnackbarState }),
    [snackbarState, setSnackbarState]
  );

  const handleClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <SnackbarContext.Provider value={snackbarStateData}>
      <Snackbar
        style={{ dir: 'rtl' }}
        open={snackbarState.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          marginTop: '82px'
        }}
        message={snackbarState.msg}
        autoHideDuration={snackbarState.duration}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
      >
        <Alert severity={snackbarState.severity}>{snackbarState.msg}</Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};
export const useSnackbar = () => useContext(SnackbarContext);
