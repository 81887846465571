import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ServiceProviderRow from './ServiceProviderRow';

const SocialWorkersList = ({ providersList, setServiceRequestDialogOpen }) => {
  SocialWorkersList.propTypes = {
    providersList: PropTypes.array,
    setServiceRequestDialogOpen: PropTypes.func
  };
  return (
    <Grid container spacing={2}>
      {providersList.map((provider) => (
        <Grid key={provider.provider_id} item xs={12}>
          <ServiceProviderRow
            key={`${provider.provider_id}-social-worker-list-provider-row`}
            providerType="social-worker"
            provider={provider}
            setServiceRequestDialogOpen={setServiceRequestDialogOpen}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default SocialWorkersList;
